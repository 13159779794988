import React, { useEffect, useRef, useState } from 'react';
import { useOnClickOutsideCustom } from '../../hooks/useOnClickOutside';
import useSetting from '../../hooks/useSetting';
import { TopPanelTabs } from '../../panels/top';
import useBarelayout from '../barelayout/hook';

import { TableFieldset } from './field';
import { TableRecordset } from './record';
import { fieldsetsIdx, recordsetsIdx } from '../barelayout/ctx';
import Button from '../../components/Button';
import PopupMenu from '../../components/Popup';
import { Symbol } from '../../components/elements/Icon';
import useDatabase from '../../hooks/useDatabase';
import { callApi } from '../../functions/api';
import { apiRoutes } from '../../helpers/api';
import { showError } from '../../components/towsts/Toasts';
import { RightPanelTabs } from '../../panels/right/main'
import { rightPanelViewStyles } from '../../panels/right/editFaqPanel/FaqEditorPanel'

export function GridView() {
    const wrapperRef = useRef(null);
    const bodyRef = useRef(null);
    const headRef = useRef(null);

    const settingCtx = useSetting();

    const brCtx = useBarelayout();

    const recordsetIdx = brCtx.current_table.recordsetIdx;
    const fieldsetIdx = brCtx.current_table.fieldsetIdx;


    const [waitingForCreateRecord, setWaitingForCreateRecord] = useState(false);
    const [addRecordWasTriggered, setAddRecordWasTriggered] = useState({
        state: false,
        recordId: null,
    });


    useEffect(() => {
        const tableWrapper = document.querySelector(".--table-wrapper");
        const stickyElement = document.querySelector(".--field-type-selector");
        const gridWrapper = document.querySelector(".--grid-wrapper");
        const gridRecords = document.querySelector(".--grid-records");
        let searchBarContainer = document.querySelector(".database-search-field-container");

        console.log("T :: ", tableWrapper, stickyElement, gridWrapper, gridRecords)

        if (!tableWrapper || !stickyElement || !gridWrapper || !gridRecords) return;

        console.log("Adding...")

        const handleScroll = () => {

            const stickyRect = stickyElement.getBoundingClientRect();
            const tableRect = tableWrapper.getBoundingClientRect();

            console.log("stickyRect :: ", stickyRect)

            // Check if the sticky element's left matches -20px relative to the table wrapper
            const isStuck = stickyRect.left === 0;

            if (isStuck) {
                gridWrapper.classList.add("--sticked-first");
                gridRecords.classList.add("--sticked-first");
            } else {
                gridWrapper.classList.remove("--sticked-first");
                gridRecords.classList.remove("--sticked-first");
            }

            // if(isSearchOpenRef.current) {
            //     if(!searchBarContainer) {
            //         searchBarContainer = document.querySelector(".database-search-field-container");
                // }
                // console.log("doing the left :: ", searchBarContainer)
                // if(searchBarContainer) {
                //     searchBarContainer.scrollLeft = tableWrapper.scrollLeft;
                // }
            }
        // };

        // Attach scroll listener to the table-wrapper
        tableWrapper.addEventListener("scroll", handleScroll);

        // Run once on mount to set the initial state
        handleScroll();

        return () => {
            // Cleanup event listener on unmount
            tableWrapper.removeEventListener("scroll", handleScroll);
        };
    }, []);

    // useEffect(() => {
    // brCtx.fieldsets && brCtx.fieldsets.map((fieldset) => {
    //     fieldset.fields && fieldset.fields.map((field) => {

    //     })
    // })
    // setWidthMap(brCtx.fieldsets)
    // }, [brCtx.fieldsets]);

    const handleAddField = (field) => {
        brCtx.addField(field);
    };

    const inHandler = () => {
        // console.log('IN ONLY');
    };

    const outHandler = () => {
        // console.log('OUT ONLY');
        brCtx.setBasic({
            active_cell: null,
        });
        settingCtx.setRightPanel({
            visible: false,
        });
    };

    const inWithOutHandler = () => {
        // console.log('IN-OUT');
    };

    const superOutHandler = () => {
        // console.log('SUPER OUT ONLY');
    };

    const outOfScopeHandler = () => {
        // console.log('OUT OF SCOPE: EXTREME OUT ONLY');
    };

    useEffect(() => {
        // console.log(brCtx.selected_records);
        if (
            brCtx.selected_records &&
            Object.keys(brCtx.selected_records).length > 0
        ) {
            /** there is no case for empty array */
            settingCtx.setTopPanel({
                active: TopPanelTabs.DatabaseFullSelectedRecords,
            });
        } else {
            settingCtx.setTopPanel({
                active: TopPanelTabs.DatabaseFullIdle,
            });
        }
    }, [brCtx.selected_records]);

    useOnClickOutsideCustom(
        [headRef, bodyRef],
        [wrapperRef],
        [],
        inHandler,
        outHandler,
        superOutHandler,
        inWithOutHandler,
        outOfScopeHandler,
        true
    );

    const handleAddRecordset = (e) => {
        e.preventDefault();
        brCtx.addRecordsetFirst();
    };

    useEffect(() => {
        if(addRecordWasTriggered && addRecordWasTriggered.state) {
            brCtx.setBasic({
                active_cell: {
                    cellidx: 2,
                    e: null,
                    fieldidx: 2,
                    recordid: addRecordWasTriggered.recordId,
                    recordsetidx: 0,
                    is_calendar: false
                },
            });
            settingCtx.setRightPanel({
                visible: true,
                active: RightPanelTabs.Recordview,
                force_view_style: rightPanelViewStyles.DEFAULT,
            });
            setAddRecordWasTriggered({ state: false, recordId: null });
        }
    }, [brCtx.recordsets, addRecordWasTriggered]);

    const handleAddRecord = async (e) => {
        e.preventDefault();
        const r = await brCtx.addRecord();
        console.log(r);
        if (r.status === 201 && r.data.record) {
            setAddRecordWasTriggered({
                state: true,
                recordId: r.data.record.uid,
            });
        }
    }

    const handleSearchChange = (field) => (e) => {
        const fieldSearchVal = (e.target.value || "").trim();
        let new_filters = {};
        let remove_it = false;
        if(fieldSearchVal && fieldSearchVal.length > 0) {
            new_filters = {
                [field.id]: fieldSearchVal
            }
        }
        else
        {
            if(brCtx.column_filters[field.id]) {
                remove_it = true;
            }
        }
        let filters = {
            ...(brCtx.column_filters || {}),
            ...new_filters
        };

        console.log("FFFF :: ", filters, remove_it)
        if(remove_it) {

            delete filters[field.id];
        }
        console.log("Fina : ", filters);

        brCtx.setBasic({
            column_filters: filters
        });
    }

    useEffect(() => {
        console.log("X ::: ", settingCtx.top_panel.search_active)
    }, [settingCtx.top_panel.search_active])

    const handelCloseSearch = (e) => {
        settingCtx.setTopPanel({
            search_active: false
        });
    }

    return (
        <>
            <div
                ref={wrapperRef}
                className="--grid-wrapper"
                style={{
                    borderTopLeftRadius: `${0}px`,
                }}>
                <table className="--grid-wrapper-inner">
                    <thead ref={headRef} className="--grid-head">
                    <tr>
                        {brCtx.fieldsets &&
                            brCtx.fieldsets[
                                fieldsetsIdx.SelectorFieldset
                                ] && (
                                <TableFieldset
                                    key={
                                        brCtx.fieldsets[
                                            fieldsetsIdx.SelectorFieldset
                                            ].id
                                    }
                                    fieldset={
                                        brCtx.fieldsets[
                                            fieldsetsIdx.SelectorFieldset
                                            ]
                                    }
                                />
                            )}
                        {brCtx.fieldsets &&
                            brCtx.fieldsets[fieldsetIdx] && (
                                <TableFieldset
                                    key={brCtx.fieldsets[fieldsetIdx].id}
                                    fieldset={brCtx.fieldsets[fieldsetIdx]}
                                />
                            )}
                        {
                            /*this is just a placeholder for the "add new field" button*/
                            <th className="--add-new-field-btn-placeholder"></th>
                        }
                    </tr>
                    </thead>

                    <tbody ref={bodyRef} className="--grid-records">
                    {settingCtx.top_panel && settingCtx.top_panel.search_active && <tr className="--grid-recordset-record --grid-record-search">
                        <td>
                            <div className={
                                '--grid-cell' +
                                ` --grid-cell-type-selector`}>
                                <Button
                                    type={'icon'}
                                    onClick={handelCloseSearch}>
                                    <svg
                                        className={'ui-sym path'}
                                        width="18"
                                        height="18"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
                                            // style={{ fill: "" }}
                                            className="app-icon-fill"
                                        />
                                    </svg>
                                </Button>
                            </div>
                        </td>
                        {brCtx.fieldsets && brCtx.fieldsets[brCtx.current_table.fieldsetIdx] && (
                            <>
                                {brCtx.fieldsets[brCtx.current_table.fieldsetIdx].fields.map((field) => {
                                    if (field.attributes && field.attributes.hidden) {
                                        return (<React.Fragment key={field.id}></React.Fragment>)
                                    }
                                    return (
                                        <td>
                                            <div className={
                                                '--grid-cell' +
                                                ` --grid-cell-type-${field.type} --grid-cell-search`} style={{maxWidth: field.width.normal, width: field.width.normal}}>
                                                <input onChange={handleSearchChange(field)} placeholder={'Search'} />
                                            </div>
                                        </td>
                                    )
                                })}
                            </>
                        )}
                    </tr>}
                    {brCtx.recordsets && brCtx.recordsets[recordsetIdx] ? (
                        <TableRecordset
                            key={brCtx.recordsets[recordsetIdx].id}
                            recordset={brCtx.recordsets[recordsetIdx]}
                            idx={recordsetIdx}
                        />
                    ) : (
                        <tr style={{ height: '100%' }}>
                            <td
                                style={{
                                    height: '100%',
                                    position: 'relative',
                                }}></td>
                        </tr>
                    )}
                    </tbody>
                </table>
                {recordsetIdx === recordsetsIdx.FAQsRecordset &&
                    brCtx.original_recordsets &&
                    brCtx.original_recordsets[recordsetIdx] &&
                    brCtx.original_recordsets[recordsetIdx].records.length ===
                    0 && <NoRecordsExist />}
                {/*{recordsetIdx === recordsetsIdx.FAQsRecordset &&*/}
                {/*    brCtx.original_recordsets &&*/}
                {/*    brCtx.original_recordsets[recordsetIdx] &&*/}
                {/*    brCtx.original_recordsets[recordsetIdx].records.length ===*/}
                {/*        0 && <GenerateFAQsWithAI />}*/}
                <div
                    style={{
                        width: '100%',
                        position: 'absolute',
                        top: 'calc(100% + 20px)',
                    }}>
                    <div
                        style={{
                            width: `calc(100vw - 8px - ${
                                settingCtx.left_panel.visible
                                    ? settingCtx.left_panel.width
                                    : 0
                            }px - ${
                                settingCtx.filter_panel.visible
                                    ? settingCtx.filter_panel.width
                                    : 0
                            }px)`,
                            maxWidth: '1064px',
                            position: 'sticky',
                            left: '4px',
                        }}>
                        <Button
                            type={'secondary'}
                        className={'mr-pd'}
                        onClick={handleAddRecord}>
                            Add
                        </Button>
                    </div>
                </div>
            </div>
        </>
    );
}

// const AddNewQuestionFromSearch = () => {
//     const dbCtx = useDatabase();
//     const brCtx = useBarelayout();
//     const settingCtx = useSetting();

//     const [waitingForCreateRecord, setWaitingForCreateRecord] = useState(false);
//     const [addRecordWasTriggered, setAddRecordWasTriggered] = useState({
//         state: false,
//         recordId: null,
//     });

//     const searchQuery = brCtx.searchOptions.query || '';

//     useEffect(() => {
//         if (brCtx.recordsets && addRecordWasTriggered.state) {
//             brCtx.setBasic({
//                 active_cell: {
//                     cellidx: 2,
//                     e: null,
//                     fieldidx: 2,
//                     recordid: addRecordWasTriggered.recordId,
//                     recordsetidx: 0,
//                     is_calendar: false /** false */,
//                 },
//             });
//             settingCtx.setRightPanel({
//                 visible: true,
//                 active: RightPanelTabs.Recordview,
//                 force_view_style: rightPanelViewStyles.POPUP,
//             });
//             setAddRecordWasTriggered({ state: false, recordId: null });
//         }
//     }, [brCtx.recordsets, addRecordWasTriggered]);

//     const handleAddRecord = async ({useAI=false}) => {
//         if (searchQuery.trim().length === 0) {
//             return;
//         }
//         if (waitingForCreateRecord) {
//             return;
//         }
//         setWaitingForCreateRecord(true);
//         let faqTileId;
//         let m = 1;
//         for (let i = 0; m > 0 && i < dbCtx.fieldsets.length; i++) {
//             for (
//                 let n = 0;
//                 m > 0 && n < dbCtx.fieldsets[i].fields.length;
//                 n++
//             ) {
//                 let field = dbCtx.fieldsets[i].fields[n];
//                 if (field.label === 'FAQ') {
//                     faqTileId = field.uid;
//                     m--;
//                     break;
//                 }
//             }
//         }
//         let recordValues = {};
//         recordValues[faqTileId] = searchQuery;
//         try {
//             let res = await dbCtx.addRecord({
//                 aliasuid: dbCtx.database.aliasuid,
//                 dbId: dbCtx.database.uid,
//                 recordsetuid:
//                     dbCtx.recordsets[brCtx.current_table.recordsetIdx].uid,
//                 attributes: null,
//                 useAI: useAI,
//                 values: recordValues,
//                 noReload: false,
//             });
//             if (res.status === 201 && res.data.record) {
//                 closeSearch();
//                 setAddRecordWasTriggered({
//                     state: true,
//                     recordId: res.data.record.uid,
//                 });
//             } else if (res.status === 400) {
//                 if (res.data.messageCode === 'LIMIT_REACHED') {
//                     settingCtx.setUpgradeProjectDialog({
//                         visible: true,
//                         project_id: dbCtx.database.uid,
//                         msg: 'You are currently on a free project, upgrade it and get unlimited Questions!',
//                     });
//                     /*alert(
//                         'Upgrade to the PRO plan to be able to add more than 8 FAQs!'
//                     );*/
//                 }
//             } else {
//                 showError('Error creating a new FAQ, please try again!');
//             }
//         } catch (err) {}

//         setWaitingForCreateRecord(false);
//     };

//     const closeSearch = () => {
//         brCtx.setBasic({
//             searchOptions: {
//                 ...brCtx.searchOptions,
//                 fields: [],
//                 query: '',
//             },
//         });
//     };

//     if (searchQuery.length === 0) {
//         return <></>;
//     }

//     return (
//         <>
//             <div className="add-question-from-search">
//                 <Text value={searchQuery} />
//                 <div>
//                     <Button
//                         onClick={()=>{handleAddRecord({useAI:true})}}
//                         loading={waitingForCreateRecord}
//                         style={{
//                             background: 'var(--app-primary-200)',
//                             marginRight: '4px',
//                         }}>
//                         <Symbol name={'bot_icon'} />
//                     </Button>
//                     <Button
//                         onClick={handleAddRecord}
//                         loading={waitingForCreateRecord}
//                         style={{
//                             background: 'var(--app-primary-200)',
//                             marginRight: '5px',
//                         }}>
//                         <Symbol name={'add_circle_icon'} />
//                     </Button>
//                 </div>
//             </div>
//         </>
//     );
// };

const NoRecordsExist = () => {
    return (
        <>
            <div
                style={{
                    marginBottom: '30px',
                    marginTop: '-15px',
                    textAlign: 'center',
                }}>
                <p>No Records exist</p>
            </div>
        </>
    );
}

const GenerateFAQsWithAI = () => {
    const generateWithAIPopupRef = useRef(null);
    const projectDescriptionInput = useRef(null);

    const dbCtx = useDatabase();

    const [loading, setLoading] = useState(false);

    async function generateFAQs() {
        if (!projectDescriptionInput.current.value.trim()) {
            projectDescriptionInput.current.focus();
            return;
        }

        setLoading(true);
        const res = await callApi({
            method: 'post',
            path: apiRoutes.AI.generateFAQs
                .replace('{aliasuid}', dbCtx.database.aliasuid)
                .replace('{databaseuid}', dbCtx.database.uid),
            payload: { description: projectDescriptionInput.current.value },
        });
        setLoading(false);

        if (res.status === 200) {
            try {
                document.location.reload();
            } catch (err) {}
        } else {
            showError('Something went wrong, please try again!');
        }
    }

    return (
        <>
            <div
                style={{
                    marginBottom: '30px',
                    marginTop: '-15px',
                    textAlign: 'center',
                }}>
                <PopupMenu
                    ref={generateWithAIPopupRef}
                    modal={true}
                    className={'generate-with-ai-popup'}
                    onOpen={() => {
                        setTimeout(() => {
                            try {
                                projectDescriptionInput.current.focus();
                            } catch (err) {}
                        }, 100);
                    }}
                    trigger={
                        <Button
                            type={'primary'}
                            style={{
                                padding: '14px 20px',
                                fontWeight: '600',
                            }}>
                            Generate with AI
                        </Button>
                    }>
                    <div
                        className="popup-header"
                        style={{ margin: '4px 10px 40px' }}>
                        <Button
                            type={'primary'}
                            loading={loading}
                            onClick={generateFAQs}
                            style={{
                                padding: '14px 20px',
                                fontWeight: '600',
                            }}>
                            Generate
                        </Button>
                        <Button
                            type="icon"
                            disbled={loading}
                            onClick={() => {
                                generateWithAIPopupRef.current.close();
                            }}>
                            <Symbol name="close" />
                        </Button>
                    </div>
                    <textarea
                        ref={projectDescriptionInput}
                        disabled={loading}
                        autoFocus
                        className="description-input"
                        placeholder="Write a description for example: A personal training service, covering fitness assessments, training programs, and scheduling"></textarea>
                </PopupMenu>
            </div>
        </>
    );
};
