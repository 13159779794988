export const TagIcon = ({
    className = null,
    color = undefined,
    color2 = undefined,
}) => {
    return (
        <svg
            className={'ui-sym path' + (className ? ' ' + className : '')}
            width="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M17.8337 11.8743L11.8753 17.8327C11.7087 17.9993 11.5212 18.1243 11.3128 18.2077C11.1045 18.291 10.8962 18.3327 10.6878 18.3327C10.4795 18.3327 10.2712 18.291 10.0628 18.2077C9.85449 18.1243 9.66699 17.9993 9.50033 17.8327L2.14616 10.4785C1.99338 10.3257 1.87533 10.1487 1.79199 9.94727C1.70866 9.74588 1.66699 9.53407 1.66699 9.31185V3.33268C1.66699 2.87435 1.83019 2.48199 2.15658 2.1556C2.48296 1.82921 2.87533 1.66602 3.33366 1.66602H9.31283C9.53505 1.66602 9.75033 1.71115 9.95866 1.80143C10.167 1.89171 10.3475 2.01324 10.5003 2.16602L17.8337 9.52018C18.0003 9.68685 18.1219 9.87435 18.1982 10.0827C18.2746 10.291 18.3128 10.4993 18.3128 10.7077C18.3128 10.916 18.2746 11.1209 18.1982 11.3223C18.1219 11.5237 18.0003 11.7077 17.8337 11.8743ZM5.41699 6.66602C5.76421 6.66602 6.05935 6.54449 6.30241 6.30143C6.54546 6.05838 6.66699 5.76324 6.66699 5.41602C6.66699 5.06879 6.54546 4.77365 6.30241 4.5306C6.05935 4.28754 5.76421 4.16602 5.41699 4.16602C5.06977 4.16602 4.77463 4.28754 4.53158 4.5306C4.28852 4.77365 4.16699 5.06879 4.16699 5.41602C4.16699 5.76324 4.28852 6.05838 4.53158 6.30143C4.77463 6.54449 5.06977 6.66602 5.41699 6.66602Z"
                style={{ fill: color2 }}
                className="app-icon-fill-2"
            />
            <path
                d="M17.8337 11.8743L11.8753 17.8327C11.7087 17.9993 11.5212 18.1243 11.3128 18.2077C11.1045 18.291 10.8962 18.3327 10.6878 18.3327C10.4795 18.3327 10.2712 18.291 10.0628 18.2077C9.85449 18.1243 9.66699 17.9993 9.50033 17.8327L2.14616 10.4785C1.99338 10.3257 1.87533 10.1487 1.79199 9.94727C1.70866 9.74588 1.66699 9.53407 1.66699 9.31185V3.33268C1.66699 2.87435 1.83019 2.48199 2.15658 2.1556C2.48296 1.82921 2.87533 1.66602 3.33366 1.66602H9.31283C9.53505 1.66602 9.75033 1.71115 9.95866 1.80143C10.167 1.89171 10.3475 2.01324 10.5003 2.16602L17.8337 9.52018C18.0003 9.68685 18.1219 9.87435 18.1982 10.0827C18.2746 10.291 18.3128 10.4993 18.3128 10.7077C18.3128 10.916 18.2746 11.1209 18.1982 11.3223C18.1219 11.5237 18.0003 11.7077 17.8337 11.8743ZM10.6878 16.666L16.6462 10.7077L9.29199 3.33268H3.33366V9.29102L10.6878 16.666ZM5.41699 6.66602C5.76421 6.66602 6.05935 6.54449 6.30241 6.30143C6.54546 6.05838 6.66699 5.76324 6.66699 5.41602C6.66699 5.06879 6.54546 4.77365 6.30241 4.5306C6.05935 4.28754 5.76421 4.16602 5.41699 4.16602C5.06977 4.16602 4.77463 4.28754 4.53158 4.5306C4.28852 4.77365 4.16699 5.06879 4.16699 5.41602C4.16699 5.76324 4.28852 6.05838 4.53158 6.30143C4.77463 6.54449 5.06977 6.66602 5.41699 6.66602Z"
                style={{ fill: color }}
                className="app-icon-fill"
            />
        </svg>
    );
};
