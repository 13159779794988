export default function CodeIcon({ className = null, color = undefined }) {
    return (
        <svg
            className={'ui-sym path' + (className ? ' ' + className : '')}
            width="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M5.99468 13.4874L1.49902 8.99175L5.99468 4.49609L7.06239 5.56381L3.61573 9.01048L7.04366 12.4384L5.99468 13.4874ZM11.9889 13.4874L10.9212 12.4197L14.3678 8.97301L10.9399 5.54508L11.9889 4.49609L16.4845 8.99175L11.9889 13.4874Z"
                style={{ fill: color }}
                className="app-icon-fill"
            />
        </svg>
    );
}
