import Footer from '../mainlayout_2/Footer';
import Header from './Header';
import './index.scss';

export default function MainLayout({ children }) {
    return (
        <div className="main-page">
            <div>
                <Header></Header>
                {children}
                <Footer></Footer>
            </div>
        </div>
    );
}
