// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.marqee-elm {
  overflow: hidden;
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
}
.marqee-elm > * {
  flex-shrink: 0;
  flex-grow: 0;
}
.marqee-elm > .marqee-elm-content {
  display: inline-block;
  margin: 0 !important;
  padding: 0 !important;
  animation: 2s 0s linear backwards infinite marqeeAnimation;
}
.marqee-elm.no-animation > .marqee-elm-content {
  animation: none;
}

@keyframes marqeeAnimation {
  0% {
    transform: translate3d(0%, 0px, 0px);
  }
  100% {
    transform: translate3d(-100%, 0px, 0px);
  }
}`, "",{"version":3,"sources":["webpack://./src/components/marqee/marqee.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,mBAAA;EACA,aAAA;EACA,mBAAA;EACA,iBAAA;EACA,mBAAA;EACA,2BAAA;AACJ;AAAI;EACI,cAAA;EACA,YAAA;AAER;AAAI;EACI,qBAAA;EACA,oBAAA;EACA,qBAAA;EACA,0DAAA;AAER;AACQ;EACI,eAAA;AACZ;;AAGA;EACI;IACI,oCAAA;EAAN;EAEE;IACI,uCAAA;EAAN;AACF","sourcesContent":[".marqee-elm {\n    overflow: hidden;\n    white-space: nowrap;\n    display: flex;\n    flex-direction: row;\n    flex-wrap: nowrap;\n    align-items: center;\n    justify-content: flex-start;\n    &>*{\n        flex-shrink: 0;\n        flex-grow: 0;\n    }\n    & > .marqee-elm-content {\n        display: inline-block;\n        margin: 0!important;\n        padding: 0!important;\n        animation: 2s 0s linear backwards infinite marqeeAnimation;\n    }\n    &.no-animation {\n        & > .marqee-elm-content {\n            animation: none;\n        }\n    }\n}\n@keyframes marqeeAnimation {\n    0% {\n        transform: translate3d(0%, 0px, 0px);\n    }\n    100% {\n        transform: translate3d(-100%, 0px, 0px);\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
