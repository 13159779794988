import { useEffect, useRef, useState } from 'react';
import './marqee.scss';

export function Marqee({ animationDuration = 0, children }) {
    const windowResizeTimeoutRef = useRef(null);
    const childrensRef = useRef(null);
    const marqeeRef = useRef(null);

    const [numberOfClones, setNumberOfClones] = useState([]);
    const [animationPaused, setAnimationPaused] = useState(false);

    useEffect(() => {
        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    useEffect(() => {
        if (!marqeeRef.current) {
            return;
        }
        let marqeeElment = marqeeRef.current;
        marqeeElment.addEventListener('mouseenter', handleMouseEnter);
        marqeeElment.addEventListener('mouseleave', handleMouseLeave);
        return () => {
            marqeeElment.removeEventListener('mouseenter', handleMouseEnter);
            marqeeElment.removeEventListener('mouseout', handleMouseLeave);
        };
    }, []);

    useEffect(() => {
        initialize();
    }, []);

    function handleWindowResize(evnt) {
        clearTimeout(windowResizeTimeoutRef.current);
        setTimeout(() => {
            initialize();
        }, 500);
    }

    function handleMouseEnter(evnt) {
        setAnimationPaused(true);
    }
    function handleMouseLeave(evnt) {
        setAnimationPaused(false);
    }

    function initialize() {
        if (!childrensRef.current || !marqeeRef.current) {
            return;
        }
        marqeeRef.current.classList.add('no-animation');
        let marqeeWidth = marqeeRef.current.getBoundingClientRect().width;
        let childrenWidth = childrensRef.current.getBoundingClientRect().width;
        let numberOfClones = Math.round(marqeeWidth / childrenWidth) || 1;
        let clones = [];
        for (let i = 0; i < numberOfClones; i++) {
            clones.push(i);
        }
        setNumberOfClones([...clones]);
        marqeeRef.current.classList.remove('no-animation');
    }

    return (
        <div className="marqee-elm" ref={marqeeRef}>
            <div
                className={'marqee-elm-content'}
                ref={childrensRef}
                style={{
                    animationDuration: `${animationDuration}s`,
                    animationPlayState: `${
                        animationPaused ? 'paused' : 'running'
                    }`,
                }}>
                {children}
            </div>
            {numberOfClones.map((i) => {
                return (
                    <div
                        key={'marqee-elm-00' + i}
                        className={'marqee-elm-content'}
                        style={{
                            animationDuration: `${animationDuration}s`,
                            animationPlayState: `${
                                animationPaused ? 'paused' : 'running'
                            }`,
                        }}>
                        {children}
                    </div>
                );
            })}
        </div>
    );
}
