import React, { useEffect, useState } from 'react';
import { Resizable } from 'react-quick-resizable';
import { Link } from 'react-router-dom';
import Button from '../../components/Button';
import { Symbol } from '../../components/elements/Icon';
import { Text } from '../../components/Text';
import useAlias from '../../hooks/useAlias';
import useSetting from '../../hooks/useSetting';
import { BodyPanelTabs } from '../body';
import { GDialogTabs } from '../dialog/main';
import { TopPanelTabs } from '../top';
import { Filter } from './filterPanel/filter';
import { DatabasesList } from './databasesList';
import useBarelayout from '../../layouts/barelayout/hook';

/** @todo implement tab feature */

const DatabaseItem = ({ item = null, noBorder = false }) => {
    const aliasCtx = useAlias();
    const settingCtx = useSetting();
    // <Link to={item && item.uid || '#'}>

    const handleLinkClick = (e) => {
        settingCtx.setBodyPanel({ active: BodyPanelTabs.Databaseview });
        settingCtx.setTopPanel({ active: TopPanelTabs.DatabaseIdle });
    };

    return (
        <Link
            onClick={handleLinkClick}
            to={`/${aliasCtx.alias.alias}/${item.uid}`}>
            <div className={'--database' + (noBorder ? ' --no-border' : '')}>
                {item && (
                    <>
                        <div
                            className="--database-icon-box mb-8"
                            style={{ background: item['theme'] }}>
                            <Symbol name={item['icon']} />
                        </div>
                        <div>
                            <Text value={item['label']} />
                        </div>
                    </>
                )}
            </div>
        </Link>
    );
};

export const LeftPanelTabs = { DbsList: 0, Filter: 1 };

export const OpenFilterMenuBtn = ({ isActive = false }) => {
    const settingCtx = useSetting();
    return (
        <Button
            className={`no-default-style ${
                settingCtx.left_panel.active === LeftPanelTabs.Filter
                    ? 'selected'
                    : ''
            }`}
            /*style={{
                paddingLeft: 10,
                paddingRight: 14,
            }}*/
            onClick={(evnt) => {
                if (!isActive) {
                    return;
                }
                if (settingCtx.left_panel.active === LeftPanelTabs.Filter) {
                    settingCtx.setLeftPanel({
                        ...settingCtx.left_panel,
                        active: LeftPanelTabs.DbsList,
                    });
                } else {
                    settingCtx.setLeftPanel({
                        ...settingCtx.left_panel,
                        visible: true,
                        active: LeftPanelTabs.Filter,
                    });
                }
            }}>
            <Symbol className={'mr-4'} name={'filter_alt'} />
            <Text value={'Filter'} />
        </Button>
    );
};

export default function LeftPanel() {
    const aliasCtx = useAlias();
    const settingCtx = useSetting();

    const [visible, setVisible] = useState(null);

    useEffect(() => {
        setVisible(settingCtx.left_panel.visible);
    }, [settingCtx.left_panel.visible]);

    const hideLeftPanel = (e) => {
        settingCtx.setLeftPanel({
            ...settingCtx.left_panel,
            visible: false,
        });
    };

    const onResize = (e) => {
        settingCtx.setLeftPanel({
            ...settingCtx.left_panel,
            width: e.width,
        });
    };

    const handleOnAddClick = (e) => {
        settingCtx.setGDialog({
            visible: true,
            active: GDialogTabs.database,
        });
    };

    return (
        <>
            <Resizable
                defaultWidth={220}
                minWidth={54}
                maxWidth={460}
                width={settingCtx.left_panel.width}
                defaultHeight={'100%'}
                height={'100%'}
                directions={{
                    right: true,
                }}
                onResize={onResize}
                className={`panel-left ${
                    visible ? '--visible' : '--no-visible'
                } ${
                    settingCtx.left_panel.width < 70
                        ? 'no-lable no-options'
                        : settingCtx.left_panel.width < 100
                        ? 'no-lable'
                        : ''
                }`}>
                <div className={'--inner scroll-container'}>
                    <div className="--section pt-8 --header">
                        <div
                            className="--item pl-8"
                            style={{
                                height: 52,
                            }}>
                            <Button onClick={hideLeftPanel}>
                                <Symbol name={'close'} />
                                {settingCtx.left_panel.width > 100 && (
                                    <Text value={'Close'} />
                                )}
                            </Button>
                        </div>
                    </div>
                    <div className="--section pb-8 --content">
                        <div className={'dbs-list-container'}>
                            <DatabasesList
                                databases={[...(aliasCtx.databases || []).sort(
                                    (a, b) => a.index - b.index
                                )]}
                            />
                        </div>
                    </div>
                    <div className="--section --footer">
                        <div
                            className="--item"
                            style={{
                                height: 52,
                            }}>
                            <div
                                onClick={() => {
                                    handleOnAddClick();
                                }}
                                className={'add-new-database-btn-container'}>
                                <div
                                    className={'--icon'}
                                    style={{
                                        height: 50,
                                    }}>
                                    <Symbol
                                        name="add_circle_icon"
                                    />
                                </div>
                                <div className={'--text pl-4'}>
                                    <Text
                                        style={{ color: '#A6A6A6' }}
                                        value={'New Project'}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Resizable>
        </>
    );
}
