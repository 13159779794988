export const SortByAlphaIcon = ({ className = null, color = undefined }) => {
    return (
        <svg
            className={className}
            width="15"
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M5 8.125V3.64062L3.39063 5.25L2.5 4.375L5.625 1.25L8.75 4.375L7.85938 5.25L6.25 3.64062V8.125H5ZM9.375 13.75L6.25 10.625L7.14062 9.75L8.75 11.3594V6.875H10V11.3594L11.6094 9.75L12.5 10.625L9.375 13.75Z"
                style={{ fill: color }}
                className="app-icon-fill"
            />
        </svg>
    );
};
