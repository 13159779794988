import { useEffect, useState } from 'react';
import useSetting from '../hooks/useSetting';
import AliasviewTopPanel, { AliasviewSearchPanel } from './top/alias';
import AliasviewFullTopPanel from './top/alias_full';
import DatabaseviewTopPanel, { DatabaseviewSearchPanel } from './top/database';
import DatabaseviewFullTopPanel, {
    DatabaseviewFullSearchPanel,
    DatabaseviewSelectedRecordsTopPanel,
} from './top/database_full';

export const TopPanelTabs = {
    AliasView: 'sdewd3w',
    AliasViewFull: 'erewg',
    AliasSearchTab: 'UUedef',
    DatabaseIdle: 'ie34r43',
    DatabaseSearchTab: 'ewrfw',

    DatabaseFullIdle: 'DIWdwd',
    DatabaseFullSearchTab: 'ewfwe',
    DatabaseFullSelectedRecords: 'er333r',
};

export default function TopPanel() {
    const settingCtx = useSetting();

    const [tab, setTab] = useState(null);

    useEffect(() => {
        setTab(settingCtx.top_panel.active);
    }, [settingCtx.top_panel.active]);

    return (
        <div
            className={
                'panel --panel-top' +
                (tab === TopPanelTabs.AliasViewFull ? ' --gray-bg' : '') +
                (tab === TopPanelTabs.DatabaseFullIdle ||
                tab === TopPanelTabs.DatabaseFullSearchTab ||
                tab === TopPanelTabs.DatabaseFullSelectedRecords
                    ? ' --full-view'
                    : '')
            }>
            <div className="--inner">
                {tab === TopPanelTabs.AliasView ? (
                    <AliasviewTopPanel />
                ) : tab === TopPanelTabs.AliasViewFull ? (
                    <AliasviewFullTopPanel />
                ) : tab === TopPanelTabs.AliasSearchTab ? (
                    <AliasviewSearchPanel />
                ) : tab === TopPanelTabs.DatabaseIdle ? (
                    <DatabaseviewTopPanel />
                ) : tab === TopPanelTabs.DatabaseSearchTab ? (
                    <DatabaseviewSearchPanel />
                ) : tab === null ? (
                    <></>
                ) : tab === TopPanelTabs.DatabaseFullIdle ? (
                    <DatabaseviewFullTopPanel />
                ) : tab === TopPanelTabs.DatabaseFullSearchTab ? (
                    <DatabaseviewFullSearchPanel />
                ) : tab === TopPanelTabs.DatabaseFullSelectedRecords ? (
                    <DatabaseviewSelectedRecordsTopPanel />
                ) : tab === null ? (
                    <></>
                ) : (
                    <></>
                )}
            </div>
        </div>
    );
}
