import React, { useEffect, useRef, useState } from 'react';
import { Resizable } from 'react-quick-resizable';
import Button from '../../components/Button';
import { Symbol } from '../../components/elements/Icon';
import PopupMenu, { PopupButton, PositionTypes } from '../../components/Popup';
import { Text } from '../../components/Text';
import { useOnClickOutsideCustom } from '../../hooks/useOnClickOutside';
import useSetting from '../../hooks/useSetting';
import { TopPanelTabs } from '../../panels/top';
import useBarelayout from '../barelayout/hook';

import { TableFieldset } from './field';
import { TableRecordset } from './record';

import { FieldsData } from './fieldsdata';
import { BrDialogTabs } from '../barelayout/dialog';
import { RightPanelTabs } from '../../panels/right/main';

const getSumTillFieldsetsAndFields = (fieldsets = []) => {
    var sum = 0;

    fieldsets &&
        fieldsets.map((fieldset, x) => {
            fieldset.fields &&
                fieldset.fields.map((field) => {
                    sum += field.width.normal || 160;
                });
        });

    return sum;
};

export function TableTimelineDate({ timeline = null, width = null }) {
    const brCtx = useBarelayout();

    return (
        <div
            className={
                '--grid-fieldset' +
                (timeline && timeline.is_today ? ' --highlight' : '')
            }>
            <div
                className="--grid-field --timeline"
                style={{
                    width: width || 200,
                }}>
                <Text value={(timeline && timeline.label) || null} />

                {/* <PopupMenu
                    contentStyle={{ width: 200, padding: 0 }}
                    offsetX={-10}
                    offsetY={0}
                    trigger={
                        <Button
                            className={'--more-btn'}
                            type={'icon'}>
                            <Symbol name={'more_horiz'} />
                        </Button>
                    }
                    position={PositionTypes.BottomRight}>
                    <PopupButton
                        icon={'title'}
                        label={'Options'}
                    />
                </PopupMenu> */}
            </div>
        </div>
    );
}

export function TimelineView() {
    const settingCtx = useSetting();
    const brCtx = useBarelayout();

    const wrapperRef = useRef(null);
    const bodyRef = useRef(null);
    const headRef = useRef(null);

    const timelineWrapperRef = useRef(null);
    const timelineHeadRef = useRef(null);
    const timelineBodyRef = useRef(null);

    const leftContainerRef = useRef();
    const rightContainerRef = useRef();

    const [addRecordWasTriggered, setAddRecordWasTriggered] = useState(false);

    const [timelineWidth, setTimelineWidth] = useState(200);

    const handleLeftContainerOnScroll = (e) => {};

    const handleRightContainerOnScroll = (e) => {
        leftContainerRef.current.scrollTop = e.target.scrollTop;
    };

    const handleAddField = (field) => {
        brCtx.addField(field);
    };

    const handleAddRecord = (e) => {
        brCtx.addRecord();
        if (brCtx.recordsets.length > 0) {
            setAddRecordWasTriggered(true);
        }
    };

    const handleAddRecordset = (e) => {
        e.preventDefault();
        brCtx.addRecordsetFirst();
    }

    useEffect(() => {
        if (brCtx.recordsets && addRecordWasTriggered) {
            brCtx.setBasic({
                active_cell: {
                    cellidx: null,
                    e: null,
                    fieldidx: null,

                    /** because will be created on last */
                    recordidx:
                        (brCtx.recordsets &&
                            brCtx.recordsets[0] &&
                            brCtx.recordsets[0].records &&
                            brCtx.recordsets[0].records.length - 1) ||
                        0,
                    recordsetidx: 0,
                    is_calendar: false /** false */,
                },
            });
            settingCtx.setRightPanel({
                visible: true,
                active: RightPanelTabs.Recordview,
            });
            setAddRecordWasTriggered(false);
        }
    }, [brCtx.recordsets]);

    const handleQrClick = (e) => {
        brCtx.setBasic({
            dialog: {
                ...(brCtx.dialog || {}),
                visible: true,
                tab: BrDialogTabs.QR_SCANNER,
            },
        });
    };

    useEffect(() => {
        brCtx.buildTimelineFieldsets();
    }, [brCtx.timeline_conf]);

    // useEffect(() => {
    //     console.log(brCtx.recordsets);
    // }, [brCtx.recordsets]);

    useEffect(() => {
        let w = getSumTillFieldsetsAndFields(brCtx.calendar_fieldsets);
        setTimelineWidth(w);
        rightContainerRef.current.scrollTo(
            rightContainerRef.current.getBoundingClientRect().width / 2,
            0
        );
    }, [brCtx.calendar_fieldsets]);

    const inHandler = (event) => {
        // console.log('IN ONLY');
    };

    useEffect(() => {
        if (
            brCtx.selected_records &&
            Object.keys(brCtx.selected_records).length > 0
        ) {
            /** there is no case for empty array */
            settingCtx.setTopPanel({
                active: TopPanelTabs.DatabaseFullSelectedRecords,
            });
        } else {
            settingCtx.setTopPanel({
                active: TopPanelTabs.DatabaseFullIdle,
            });
        }
    }, [brCtx.selected_records]);

    const outHandler = (event) => {
        // console.log('OUT ONLY');
        brCtx.setBasic({
            active_cell: null,
        });
        settingCtx.setRightPanel({
            visible: false,
        });
    };

    const inWithOutHandler = (event) => {
        // console.log('IN-OUT');
    };

    const superOutHandler = (event) => {
        // console.log('SUPER OUT ONLY');
    };

    const outOfScopeHandler = (event) => {
        // console.log('OUT OF SCOPE: EXTREME OUT ONLY');
    };

    useOnClickOutsideCustom(
        [headRef, bodyRef, timelineBodyRef, timelineHeadRef],
        [leftContainerRef, rightContainerRef],
        [],
        inHandler,
        outHandler,
        superOutHandler,
        inWithOutHandler,
        outOfScopeHandler,
        true
    );

    return (
        <>
            <Resizable
                ref={wrapperRef}
                defaultHeight={'100%'}
                height={'100%'}
                defaultWidth={'40%'}
                directions={{
                    right: true,
                }}
                className="--grid-wrapper">
                <div
                    ref={leftContainerRef}
                    className="--grid-wrapper-inner scroll-container"
                    onScroll={handleLeftContainerOnScroll}>
                    <div
                        className="--grid-head"
                        ref={headRef}>
                        {brCtx.fieldsets &&
                            brCtx.fieldsets.map((fieldset, x) => {
                                return (
                                    <TableFieldset
                                        key={fieldset.id}
                                        fieldset={fieldset}
                                    />
                                );
                            })}

                        <div className="--grid-fieldset">
                            <PopupMenu
                                contentStyle={{ width: 200, padding: 0 }}
                                offsetX={-10}
                                offsetY={0}
                                trigger={
                                    <div
                                        className="--grid-field --clickable"
                                        style={{
                                            width: 200,
                                        }}>
                                        <div className="--grid-field-content">
                                            <Symbol
                                                name={'add'}
                                                className={'mr-8'}
                                            />
                                            <Text value={'Add field'} />
                                        </div>
                                    </div>
                                }
                                position={PositionTypes.BottomRight}>
                                {FieldsData.map((field, x) => {
                                    return (
                                        <PopupButton
                                        key={`${field.type}-${x}`}
                                            onClick={() =>
                                                handleAddField(field)
                                            }
                                            icon={field.icon}
                                            label={field.label}
                                        />
                                    );
                                })}
                            </PopupMenu>
                        </div>
                    </div>

                    <div
                        className="--grid-records"
                        ref={bodyRef}>
                        {brCtx.recordsets &&
                            brCtx.recordsets.map((recordset, x) => {
                                return (
                                    <TableRecordset
                                        key={recordset.id}
                                        recordset={recordset}
                                        idx={x}
                                    />
                                );
                            })}
                    </div>
                </div>
            </Resizable>

            <Resizable
                ref={timelineWrapperRef}
                defaultHeight={'100%'}
                height={'100%'}
                defaultWidth={'57.4%'}
                // defaultWidth={'100%'}
                directions={{
                    right: true,
                }}
                className="--grid-wrapper">
                <div
                    ref={rightContainerRef}
                    className="--grid-wrapper-inner scroll-container"
                    onScroll={handleRightContainerOnScroll}>
                    <div
                        className="--grid-head"
                        ref={timelineHeadRef}>
                        {/** @todo dates */}

                        {brCtx.timeline_series &&
                            brCtx.timeline_series.map((timeline, x) => {
                                return (
                                    <TableTimelineDate
                                        key={timeline.uid}
                                        width={timelineWidth}
                                        timeline={timeline}
                                    />
                                );
                            })}
                    </div>

                    {/* {!brCtx.recordsets || (brCtx.recordsets && brCtx.recordsets.length < 1) && <>
                        <div
                            className="--empty-db">
                            <Text className={'--heading'} value={'No records found.'} />
                        </div>
                    </>} */}

                    <div
                        className={'--grid-records'}
                        ref={timelineBodyRef}
                        style={{
                            width: brCtx.timeline_series.length * timelineWidth,
                        }}>
                        {brCtx.recordsets &&
                            brCtx.recordsets.map((recordset, x) => {
                                return (
                                    <TableRecordset
                                        timeline={true}
                                        key={recordset.id}
                                        recordset={recordset}
                                        idx={x}
                                    />
                                );
                            })}
                    </div>

                    {/* {console.log(brCtx.features)} */}

                    <div className="--floating-container">
                        {brCtx.features && brCtx.features.qr_search.enabled && (
                            <Button
                                onClick={handleQrClick}
                                className={'--add-button mr-12'}
                                type={'icon'}>
                                <Symbol name={'qr_scanner'} />
                            </Button>
                        )}
                        {/* <Button
                            onClick={handleQrClick}
                            className={'--add-button mr-12'}
                            type={'icon'}>
                            <Symbol name={'qr_scanner'} />
                        </Button> */}
                        <Button
                            onClick={handleAddRecord}
                            onContextMenu={handleAddRecordset}
                            className={'--add-button'}
                            type={'icon'}>
                            <Symbol name={'add'} />
                        </Button>
                    </div>
                </div>
            </Resizable>
        </>
    );
}
