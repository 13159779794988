import './database-full.scss';
import React, { useEffect, useRef, useState } from 'react';
import Button from '../../components/Button';
import { Symbol } from '../../components/elements/Icon';
import Input from '../../components/Input';
import PopupMenu, { SelectBox } from '../../components/Popup';
import { Text } from '../../components/Text';
import useAuth from '../../hooks/useAuth';
import useDatabase from '../../hooks/useDatabase';
import useSetting from '../../hooks/useSetting';
import useBarelayout from '../../layouts/barelayout/hook';
import { TopPanelTabs } from '../top';
import { fieldsetsIdx, recordsetsIdx } from '../../layouts/barelayout/ctx';
import useHelpPagePreview from '../../hooks/useHelpPagePreview';
import { helpPagePreviewViews } from '../../contexts/HelpPagePreviewCtx';
import { Tooltip } from '../../components/Popup/Tooltip';
import { RightPanelTabs } from '../right/main';
import { rightPanelViewStyles } from '../right/editFaqPanel/FaqEditorPanel';
import { showError } from '../../components/towsts/Toasts';
import avatarIcon from '../../assets/images/avatar.svg';
import { createPortal } from 'react-dom';
import { ShareFAQsPopup } from '../../components/share-faqs-popup/ShareFAQsPopup';
import { projectSettingsPanel } from '../projectSettingsPanel/ProjectSettingsPanel';

export default function DatabaseviewFullTopPanel() {
    const authCtx = useAuth();
    const settingCtx = useSetting();

    const questionWidthHolderRef = useRef(null);
    const dbCtx = useDatabase();

    const brCtx = useBarelayout();

    const [isSearchOpen, setIsSearchOpen] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');

    const isSearchOpenRef = useRef(false);

    const [formWidth, setFormWidth] = useState(0);

    useEffect(() => {
        brCtx.setBasic({
            searchOptions: {
                ...brCtx.searchOptions,
                query: searchQuery,
            },
        });
    }, [searchQuery]);

    useEffect(() => {
        if (brCtx.searchOptions.fields.length === 0 && isSearchOpen) {
            closeSearch();
        }
    }, [brCtx.searchOptions]);

    useEffect(() => {
        isSearchOpenRef.current = isSearchOpen;

        // if(isSearchOpen) {
        //     const tableWrapper = document.querySelector(".--table-wrapper");
        //     const sr = document.querySelector(".database-search-field-container");
        //     sr.scrollLeft = tableWrapper.scrollLeft;
        // }
        settingCtx.setTopPanel({
            search_active: isSearchOpen
        });
    }, [isSearchOpen])

    const openSearch = () => {
        setSearchQuery('');
        setIsSearchOpen(true);
        // brCtx.setBasic({
        //     searchOptions: {
        //         ...brCtx.searchOptions,
        //         fields: brCtx.fieldsets[
        //             brCtx.current_table.fieldsetIdx
        //         ].fields.filter((field) => {
        //             return (
        //                 (brCtx.current_table.fieldsetIdx ===
        //                 fieldsetsIdx.InboxsFieldset
        //                     ? ['question', 'user email']
        //                     : ['faq', 'tags']
        //                 ).indexOf(field.label.toLowerCase()) >= 0
        //             );
        //         }),
        //         query: '',
        //     },
        // });
    };

    const closeSearch = () => {
        setFormWidth(0);
        setSearchQuery('');
        setIsSearchOpen(false);
    };

    return (
        <>
            <>
                <div className="toggle-left-panel-container">
                    <ToggleLeftPanelBtn />
                    {dbCtx.database && (
                        <div
                            style={
                                settingCtx.left_panel.visible
                                    ? {
                                          marginLeft: '0',
                                          display: `${
                                              settingCtx.left_panel.width >= 100
                                                  ? 'none'
                                                  : ''
                                          }`,
                                      }
                                    : {}
                            }
                            className="project-title">
                            {dbCtx.database.label}
                        </div>
                    )}
                </div>
                {/*{isSearchOpen && (*/}
                {/*    <>*/}
                {/*        <div*/}
                {/*            className={'database-search-field-container'}>*/}
                {/*            {brCtx.fieldsets.map((fieldset) => {*/}
                {/*                return (*/}
                {/*                    <React.Fragment key={fieldset.id}>*/}
                {/*                        {fieldset.fields.map((field) => {*/}
                {/*                            if (field.attributes && field.attributes['hidden']) {*/}
                {/*                                return <React.Fragment key={field.id}></React.Fragment>;*/}
                {/*                            }*/}
                {/*                            if (field.type === 'selector') {*/}
                {/*                                return <React.Fragment key={field.id}></React.Fragment>;*/}
                {/*                            }*/}
                {/*                            return (*/}
                {/*                                <div key={field.id} className={'field-search'} style={{minWidth: field.width.normal, width: field.width.normal}}>*/}
                {/*                                    <Input placeholder={'Search'} className={'form-input-box-wide-mobile'} boxClassName={'__desc'}  />*/}
                {/*                                </div>*/}
                {/*                            )*/}
                {/*                        })}*/}
                {/*                    </React.Fragment>*/}
                {/*                )*/}
                {/*            })}*/}
                {/*        </div>*/}
                {/*    </>*/}
                {/*)}*/}

                {/*{dbCtx.database &&*/}
                {/*    authCtx.aliases &&*/}
                {/*    authCtx.aliases[0] &&*/}
                {/*    authCtx.aliases[0].alias['templates'][0] && (*/}
                {/*        <div className={'top-panel-content'}>*/}
                {/*            <Button*/}
                {/*                type="icon"*/}
                {/*                onMouseDown={(evnt) => {*/}
                {/*                    evnt.preventDefault();*/}
                {/*                    evnt.stopPropagation();*/}
                {/*                    if (isSearchOpen) {*/}
                {/*                        if (searchQuery.length === 0) {*/}
                {/*                            closeSearch();*/}
                {/*                        } else {*/}
                {/*                            setFormWidth(0);*/}
                {/*                        }*/}
                {/*                        setSearchQuery('');*/}
                {/*                        return;*/}
                {/*                    }*/}
                {/*                    openSearch();*/}
                {/*                }}>*/}
                {/*                <Symbol*/}
                {/*                    name={isSearchOpen ? 'close' : 'search'}*/}
                {/*                />*/}
                {/*            </Button>*/}
                {/*            {isSearchOpen ? (*/}
                {/*                <>*/}
                {/*                    <span*/}
                {/*                        ref={questionWidthHolderRef}*/}
                {/*                        style={{*/}
                {/*                            position: 'fixed',*/}
                {/*                            top: '-200vh',*/}
                {/*                            left: '0',*/}
                {/*                            visibility: 'hidden',*/}
                {/*                            whiteSpace: 'nowrap',*/}
                {/*                            zIndex: -1,*/}
                {/*                            fontSize: '15px',*/}
                {/*                            padding: '0 16px',*/}
                {/*                        }}></span>*/}
                {/*                    <form*/}
                {/*                        className="--search-input-container"*/}
                {/*                        style={{ width: formWidth + 'px' }}*/}
                {/*                        onSubmit={(evnt) => {*/}
                {/*                            evnt.preventDefault();*/}
                {/*                        }}>*/}
                {/*                        <Input*/}
                {/*                            onBlur={(evnt) => {*/}
                {/*                                if (searchQuery.length === 0) {*/}
                {/*                                    closeSearch();*/}
                {/*                                }*/}
                {/*                            }}*/}
                {/*                            autoFocus={true}*/}
                {/*                            className="--nofill --noborder"*/}
                {/*                            placeholder="Search or add new Question"*/}
                {/*                            type="text"*/}
                {/*                            value={searchQuery}*/}
                {/*                            onChange={(evnt) => {*/}
                {/*                                questionWidthHolderRef.current.innerText =*/}
                {/*                                    evnt.target.value;*/}
                {/*                                let inputScrollWidth =*/}
                {/*                                        questionWidthHolderRef*/}
                {/*                                            .current*/}
                {/*                                            .offsetWidth,*/}
                {/*                                    inputWidth =*/}
                {/*                                        evnt.target.offsetWidth;*/}
                {/*                                let formElement =*/}
                {/*                                    evnt.target.parentElement*/}
                {/*                                        .parentElement*/}
                {/*                                        .parentElement;*/}

                {/*                                setFormWidth(*/}
                {/*                                    formElement.offsetWidth +*/}
                {/*                                        (inputScrollWidth -*/}
                {/*                                            inputWidth)*/}
                {/*                                );*/}

                {/*                                setSearchQuery(*/}
                {/*                                    evnt.target.value*/}
                {/*                                );*/}
                {/*                            }}*/}
                {/*                        />*/}
                {/*                        <input*/}
                {/*                            type="submit"*/}
                {/*                            style={{ display: 'none' }}*/}
                {/*                        />*/}
                {/*                    </form>*/}
                {/*                    {searchQuery.length > 0 &&*/}
                {/*                        brCtx.current_table.fieldsetIdx ===*/}
                {/*                            fieldsetsIdx.FAQsFieldset && (*/}
                {/*                            <AddNewQuestionFromSearch />*/}
                {/*                        )}*/}
                {/*                </>*/}
                {/*            ) : (*/}
                {/*                <>*/}
                {/*                    <Button*/}
                {/*                        className={`add-new-question-btn`}*/}
                {/*                        onClick={() => {*/}
                {/*                            openSearch();*/}
                {/*                        }}>*/}
                {/*                        <Text*/}
                {/*                            style={{*/}
                {/*                                color: 'var(--app-neutral-300)',*/}
                {/*                            }}*/}
                {/*                            value={'Search'}*/}
                {/*                        />*/}
                {/*                    </Button>*/}
                {/*                </>*/}
                {/*            )}*/}
                {/*            {!isSearchOpen && brCtx.fieldsets.length === 4 && (*/}
                {/*                <>*/}
                {/*                    <div className="recordset-selector">*/}
                {/*                        {brCtx.current_table.fieldsetIdx ===*/}
                {/*                        fieldsetsIdx.PageFieldset ? (*/}
                {/*                            <SelectPagePreviewView />*/}
                {/*                        ) : (*/}
                {/*                            <>*/}
                {/*                                <Tooltip*/}
                {/*                                    text={'Questions'}*/}
                {/*                                    trigger={*/}
                {/*                                        <Button*/}
                {/*                                            onClick={() => {*/}
                {/*                                                brCtx.openFAQs();*/}
                {/*                                            }}*/}
                {/*                                            className={`${*/}
                {/*                                                brCtx*/}
                {/*                                                    .current_table*/}
                {/*                                                    .recordsetIdx ===*/}
                {/*                                                recordsetsIdx.FAQsRecordset*/}
                {/*                                                    ? 'recordset-selector--selected-item'*/}
                {/*                                                    : ''*/}
                {/*                                            }`}>*/}
                {/*                                            <Symbol name="columns_icon" />*/}
                {/*                                        </Button>*/}
                {/*                                    }*/}
                {/*                                />*/}
                {/*                                <OpenUsersQuestionsBtn />*/}
                {/*                            </>*/}
                {/*                        )}*/}
                {/*                    </div>*/}
                {/*                    <div*/}
                {/*                        style={{*/}
                {/*                            width: '100%',*/}
                {/*                            flexGrow: '0',*/}
                {/*                            flexShrink: '1',*/}
                {/*                        }}>*/}
                {/*                        /!*this is just a blank space*!/*/}
                {/*                    </div>*/}
                {/*                    <div*/}
                {/*                        style={{*/}
                {/*                            width: '40px',*/}
                {/*                            flexShrink: '0',*/}
                {/*                        }}>*/}
                {/*                        {brCtx.recordsets &&*/}
                {/*                            brCtx.recordsets[*/}
                {/*                                recordsetsIdx.PageRecordset*/}
                {/*                            ] &&*/}
                {/*                            brCtx.recordsets[*/}
                {/*                                recordsetsIdx.PageRecordset*/}
                {/*                            ].records.length > 0 && (*/}
                {/*                                <TogglePagePreview />*/}
                {/*                            )}*/}
                {/*                    </div>*/}
                {/*                    <div*/}
                {/*                        style={{*/}
                {/*                            width: '40px',*/}
                {/*                            flexShrink: '0',*/}
                {/*                        }}>*/}
                {/*                        {brCtx.recordsets &&*/}
                {/*                            brCtx.recordsets[*/}
                {/*                                recordsetsIdx.PageRecordset*/}
                {/*                            ] &&*/}
                {/*                            brCtx.recordsets[*/}
                {/*                                recordsetsIdx.PageRecordset*/}
                {/*                            ].records.length > 0 && (*/}
                {/*                                <OpenSettingsBtn*/}
                {/*                                    isActive={*/}
                {/*                                        brCtx['fieldsets'] &&*/}
                {/*                                        brCtx['fieldsets']*/}
                {/*                                            .length > 1*/}
                {/*                                    }*/}
                {/*                                />*/}
                {/*                            )}*/}
                {/*                    </div>*/}
                {/*                </>*/}
                {/*            )}*/}
                {/*        </div>*/}
                {/*    )}*/}
            </>
            {<div className="top-bar-more-options-container">
                {/*<ShareFAQsBtn />*/}
                {settingCtx.top_panel.search_active ? <></> : <Button onClick={() => {
                    if(settingCtx.top_panel.search_active) {
                        settingCtx.setTopPanel({
                            search_active: false,
                        })
                    }
                    else
                    {
                        settingCtx.setTopPanel({
                            search_active: true,
                        })
                    }
                    // if(isSearchOpenRef.current) {
                    //     closeSearch()
                    // }
                    // else {
                    //     openSearch()
                    // }
                }} className={'with-bg'} type={'icon'}>
                    <Symbol name={settingCtx.top_panel.search_active ? 'close' : 'search'} />
                </Button>}
                <MainMenuBtn />
            </div>}
        </>
    );
}

export function DatabaseviewSelectedRecordsTopPanel() {
    const settingCtx = useSetting();

    const [confirmDeleteVisible, setConfirmDeleteVisible] = useState(false);

    const dbCtx = useDatabase();

    const brCtx = useBarelayout();

    const handleDeleteClick = (e) => {
        if (!confirmDeleteVisible) {
            setConfirmDeleteVisible(true);
        } else {
            deleteSelectedRecords();
            setConfirmDeleteVisible(false);
        }
    };
    const deleteSelectedRecords = (e) => {
        brCtx.deleteSelectedRecords().then(() => {
            settingCtx.setTopPanel({
                active: TopPanelTabs.DatabaseFullIdle,
            });
        });
    };

    const handleCloseClick = (e) => {
        brCtx.setBasic({
            selected_records: {},
        });
    };

    return (
        <>
            <div className="toggle-left-panel-container">
                <ToggleLeftPanelBtn />
                <div
                    style={
                        settingCtx.left_panel.visible
                            ? {
                                  marginLeft: '0',
                                  display: `${
                                      settingCtx.left_panel.width >= 100
                                          ? 'none'
                                          : ''
                                  }`,
                              }
                            : {}
                    }
                    className="project-title">
                    {dbCtx.database.label}
                </div>
            </div>
            <div className={'top-panel-content'}>
                <Button
                    onClick={handleDeleteClick}
                    className={'--outline mr-12'}
                    style={{
                        padding: '0 12px',
                    }}>
                    <Symbol
                        color={confirmDeleteVisible ? '#f00' : undefined}
                        color2={
                            confirmDeleteVisible ? 'transparent' : undefined
                        }
                        name={'delete_alt1'}
                        className={'mr-4'}
                    />
                    <Text
                        style={confirmDeleteVisible ? { color: '#f00' } : {}}
                        value={
                            confirmDeleteVisible ? 'Are you sure?' : 'Remove'
                        }
                    />
                </Button>
                <div
                    className="--panel-section"
                    style={{ width: 'max-content', flexWrap: 'nowrap' }}>
                    <Button onClick={handleCloseClick} type={'icon'}>
                        <Symbol name={'close'} />
                    </Button>
                </div>
            </div>
            <div className="toggle-main-menu-container">
                <MainMenuBtn />
            </div>
        </>
    );
}

export function DatabaseviewFullSearchPanel() {
    const settingCtx = useSetting();

    const brCtx = useBarelayout();
    const inputRef = useRef(null);

    const [fieldOptions, setFieldOptions] = useState([]);

    useEffect(() => {
        var options = [];

        brCtx.fieldsets &&
            brCtx.fieldsets.map((fieldset, x) => {
                if (x > 1) {
                    return null;
                }
                fieldset.fields &&
                    fieldset.fields.map((field, y) => {
                        if (field.label !== 'string') return null;

                        options.push({
                            id: field.id,
                            value: field.id,
                            idx: y,
                            parentIdx: x,
                            label: field.label,
                        });
                    });
            });

        options.push({
            id: 'all-db',
            value: 'all-db',
            label: 'All',
            disabled: false,
            groupe: 'default',
        });

        setFieldOptions(options);
        brCtx.setBasic({
            searchOptions: {
                ...brCtx.searchOptions,
                fields: options.slice(0, options.length - 1),
            },
        });
    }, [brCtx.fieldsets]);

    const handleCloseSearchClick = (e) => {
        settingCtx.setTopPanel({
            active: TopPanelTabs.DatabaseFullIdle,
        });
        brCtx.setBasic({
            searchOptions: {
                ...brCtx.searchOptions,
                query: null,
                fields: [],
            },
        });
        brCtx.setBasic({
            setFieldOptions: {
                query: null,
                fields: [],
            },
        });
    };

    const handleOnChange = (e) => {
        brCtx.setBasic({
            searchOptions: {
                ...brCtx.searchOptions,
                query: e.target.value,
            },
        });
    };

    /*useEffect(() => {
        inputRef.current.value = settingCtx.top_panel.qr_query || '';
    }, [settingCtx.top_panel.qr_query]);*/

    const handleFieldChange = (e) => {
        if (e.option.id === 'all-db') {
            brCtx.setBasic({
                searchOptions: {
                    ...brCtx.searchOptions,
                    fields: fieldOptions.slice(0, fieldOptions.length - 1),
                },
            });
        } else {
            brCtx.setBasic({
                searchOptions: {
                    ...brCtx.searchOptions,
                    fields: [e.option],
                },
            });
        }
    };

    return (
        <>
            {/*<SelectBox
                ref={searchFieldRef}
                onChange={(e) => {
                    handleFieldChange(e);
                }}
                options={fieldOptions}
                triggerStyle={{
                    width: '120px',
                    marginLeft: '8px',
                }}
                defaultValue={
                    fieldOptions.length > 0
                        ? fieldOptions[fieldOptions.length - 1].value
                        : null
                }
            />*/}
            <div className="--search-box-container">
                <Input
                    ref={inputRef}
                    onChange={handleOnChange}
                    placeholder={'Search'}
                    size={'wide-mobile'}
                    autoFocus={true}
                    boxClassName={'f'}
                />
                <Button
                    onClick={handleCloseSearchClick}
                    className={'close-search'}
                    type={'icon'}>
                    <Symbol name={'close'} />
                </Button>
            </div>

            <MainMenuBtn />
        </>
    );
}

export const applySearch = (searchOptions, recordsets, brCtx) => {
    let query = searchOptions.query;
    let searchFields = searchOptions.fields.map((field) => field.id);
    if (!query || query.length < 1) {
        return recordsets;
    }

    let recordsets_found = [];

    const recordsetIdx = brCtx.current_table.recordsetIdx;
    const fieldsetIdx = brCtx.current_table.fieldsetIdx;

    const fieldset = brCtx.fieldsets && brCtx.fieldsets[fieldsetIdx];
    const recordset = recordsets[recordsetIdx];

    let records_found = [];

    recordset &&
        recordset.records &&
        recordset.records.map((record, y) => {
            let recordfound = false;

            let cellset = record.cellsets[fieldset.id];

            fieldset &&
                fieldset.fields &&
                fieldset.fields.map((field, zz) => {
                    if (searchFields.indexOf(field.id) < 0) {
                        return null;
                    }
                    if (
                        !recordfound &&
                        cellset &&
                        cellset.cells[field.id] &&
                        cellset.cells[field.id].value
                    ) {
                        let v = getCellValue_forSearch(
                            record,
                            field,
                            fieldset.id,
                            brCtx
                        );
                        if (field.type === 'list') {
                            let queryWordsList = query
                                .toLowerCase()
                                .replaceAll(',', ' ')
                                .split(' ');
                            for (let i = 0; !recordfound && i < v.length; i++) {
                                for (
                                    let n = 0;
                                    n < queryWordsList.length;
                                    n++
                                ) {
                                    if (queryWordsList[n].trim().length === 0) {
                                        continue;
                                    }
                                    if (
                                        /*queryWordsList[n].includes(
                                            v[i].toLowerCase()
                                        ) ||
                                        v[i]
                                            .toString()
                                            .toLowerCase()
                                            .includes(queryWordsList[n])*/
                                        queryWordsList[n]===v[i].toLowerCase()
                                    ) {
                                        recordfound = true;
                                        break;
                                    }
                                }
                            }
                        } else if (
                            v
                                .toString()
                                .toLowerCase()
                                .includes(query.toLowerCase())
                        ) {
                            recordfound = true;
                        }
                    }
                    return null;
                });
            if (recordfound) {
                records_found.push(record);
            }
            return null;
        });
    recordsets_found = recordsets.map((recordset, idx) => {
        if (idx === recordsetIdx) {
            return {
                ...recordset,
                records: records_found,
            };
        }
        return { ...recordset };
    });

    return recordsets_found;
};

const ToggleLeftPanelBtn = () => {
    const settingCtx = useSetting();

    const toggleLeftPanel = (e) => {
        settingCtx.setLeftPanel({
            ...settingCtx.left_panel,
            visible: !settingCtx.left_panel.visible,
        });
    };

    if (settingCtx.left_panel.visible) {
        return <></>;
    }

    return (
        <Button onClick={toggleLeftPanel} className={'with-bg'} type={'icon'}>
            <Symbol name={'menu'} />
        </Button>
    );
};
export const MainMenuBtn = () => {
    const authCtx = useAuth();
    const settingCtx = useSetting();

    const userAvatar =
        ((authCtx.account || {}).profile || {}).profilePicture || avatarIcon;

    const openAccountOptionsPanel = () => {
        settingCtx.setBasic({
            right_account_options_panel: {
                visible: true,
            },
        });
    };

    // return (
    //     <Button type={'icon'} onClick={openAccountOptionsPanel}>
    //         <div
    //             className="main-menu-btn"
    //             style={{ backgroundImage: `url(${userAvatar})` }}></div>
    //     </Button>
    // );
    return (
        <Button onClick={openAccountOptionsPanel} className={'with-bg'} type={'icon'}>
            <Symbol name={'more_horiz'} />
        </Button>
    )
};

const TogglePagePreview = () => {
    const brCtx = useBarelayout();
    const pagePreviewVisibilityRef = useRef(false);
    const lastSelectedTable = useRef(null);

    useEffect(() => {
        pagePreviewVisibilityRef.current =
            brCtx.current_table.fieldsetIdx === fieldsetsIdx.PageFieldset;
    }, [brCtx]);

    function togglePagePreview() {
        if (pagePreviewVisibilityRef.current) {
            if (lastSelectedTable.current === fieldsetsIdx.InboxsFieldset) {
                lastSelectedTable.current = fieldsetsIdx.InboxsFieldset;
                brCtx.openInbox();
            } else {
                lastSelectedTable.current = fieldsetsIdx.FAQsFieldset;
                brCtx.openFAQs();
            }
        } else {
            lastSelectedTable.current = brCtx.current_table.fieldsetIdx;
            brCtx.openPagePreview();
        }
    }

    return (
        <Tooltip
            text={'Toggle help page preview'}
            trigger={
                <Button
                    type={'icon'}
                    onClick={togglePagePreview}
                    className={'no-default-style'}
                    style={{ width: 40, height: 40 }}>
                    <Symbol
                        name={
                            brCtx.current_table.fieldsetIdx ===
                            fieldsetsIdx.PageFieldset
                                ? 'visibility_off_icon'
                                : 'visibility_icon'
                        }
                    />
                </Button>
            }
        />
    );
};

const OpenSettingsBtn = ({ isActive = false }) => {
    const openSettings = () => {
        if (!isActive) {
            return;
        }
        try {
            projectSettingsPanel.open();
        } catch (err) {}
    };
    return (
        <Tooltip
            text={'Toggle project settings'}
            trigger={
                <Button
                    type={'icon'}
                    onClick={openSettings}
                    className={'no-default-style'}
                    style={{ width: 40, height: 40 }}>
                    <Symbol name={'gear_icon'} />
                </Button>
            }
        />
    );
};

const OpenUsersQuestionsBtn = () => {
    const dbCtx = useDatabase();
    const brCtx = useBarelayout();

    const questionsCount = Math.min(
        (
            (
                (dbCtx.recordsets &&
                    dbCtx.recordsets[recordsetsIdx.InboxsRecordset]) ||
                {}
            ).records || []
        ).filter((record) => record.cells.length > 0).length,
        99
    );

    return (
        <>
            <Tooltip
                text={'Inbox'}
                trigger={
                    <Button
                        onClick={() => {
                            brCtx.openInbox();
                        }}
                        className={`no-default-style --questions-inbox-icon ${
                            ''
                            /*questionsCount > 0 ? '' : '--questions-inbox-icon-empty'
                             */
                        } ${
                            brCtx.current_table.recordsetIdx ===
                            recordsetsIdx.InboxsRecordset
                                ? 'recordset-selector--selected-item'
                                : ''
                        }`}>
                        <span
                            data-count={questionsCount}
                            className={`counter ${
                                questionsCount > 0
                                    ? ''
                                    : '--questions-inbox-icon-empty'
                            }`}></span>
                        <Symbol name={'inbox_icon'} />
                    </Button>
                }
            />
        </>
    );
};

export const applySortRules = (dbCtx, brCtx, sortRulesList, recordsets) => {
    recordsets[brCtx.current_table.recordsetIdx].records = recordsets[
        brCtx.current_table.recordsetIdx
    ].records
        .sort((record1, record2) =>
            compare(record1, record2, sortRulesList, brCtx, dbCtx)
        )
        .slice(0);
    return recordsets.slice(0);
};

export const getCellValue_forSort = (record, field, cellset, brCtx, dbCtx) => {
    let cell = record['cellsets'][cellset]['cells'][field.id];
    let cellValue = null;
    if (cell) {
        cellValue = cell.value;
    } /* else {
        let field_ = brCtx.fieldsets[1]['fields'].filter(
            (elm) => elm['id'] === field.id
        );
        cellValue = field_[0].value || null;
    }*/
    if (field.type === 'select') {
        let selectOptions = brCtx.select_fields[field.id].options;
        for (let i = 0; i < selectOptions.length; i++) {
            if (selectOptions[i].id === cellValue) {
                cellValue = selectOptions[i].label;
            }
        }
    } else if (field.type === 'checkbox') {
        cellValue =
            cellValue === 'true' ? 1 : cellValue === 'false' ? 0 : cellValue;
    } else if (field.type === 'integer') {
        cellValue = 1 * cellValue;
    } else if (field.id === 'date_of_creation') {
        let createdAt = new Date();
        try {
            let recordId = record.id;
            let dbRecord =
                (dbCtx.recordsets[
                    brCtx.current_table.recordsetIdx
                ].records.filter((record) => record.uid === recordId) ||
                    [])[0] || {};
            for (let i = 0; i < dbRecord.cells.length; i++) {
                let cell = dbRecord.cells[i];
                if (cell.uid.split('-')[0] === 'createdAt') {
                    createdAt = new Date(cell.value);
                    break;
                }
            }
        } catch (err) {}
        cellValue = createdAt.getTime();
    } else if (field.label === 'LAST UPDATE' && field.type === 'date') {
        let updatedAt = new Date();
        try {
            let recordId = record.id;
            let dbRecord =
                (dbCtx.recordsets[
                    brCtx.current_table.recordsetIdx
                ].records.filter((record) => record.uid === recordId) ||
                    [])[0] || {};
            for (let i = 0; i < dbRecord.cells.length; i++) {
                let cell = dbRecord.cells[i];
                if (cell.uid.split('-')[0] === 'updatedAt') {
                    updatedAt = new Date(cell.value);
                    break;
                }
            }
        } catch (err) {
            console.warn(err);
        }
        cellValue = updatedAt.getTime();
    }
    if (field.type === 'string') {
        cellValue = cellValue ?? '';
    }
    return cellValue;
};
export const getCellValue_forSearch = (
    record,
    field,
    cellset,
    brCtx,
    dbCtx
) => {
    let cell = record['cellsets'][cellset]['cells'][field.id];
    let cellValue = null;
    if (cell) {
        cellValue = cell.value;
    } /* else {
        let field_ = brCtx.fieldsets[1]['fields'].filter(
            (elm) => elm['id'] === field.id
        );
        cellValue = field_[0].value || null;
    }*/
    if (field.type === 'list') {
        const initialValue = '';
        cellValue = (cellValue || []).map((tag) => {
            return tag.label;
        });
    } else if (field.type === 'select') {
        let selectOptions = brCtx.select_fields[field.id].options;
        for (let i = 0; i < selectOptions.length; i++) {
            if (selectOptions[i].id === cellValue) {
                cellValue = selectOptions[i].label;
            }
        }
    } else if (field.type === 'checkbox') {
        cellValue =
            cellValue === 'true' ? 1 : cellValue === 'false' ? 0 : cellValue;
    } else if (field.type === 'integer') {
        cellValue = 1 * cellValue;
    } else if (field.id === 'date_of_creation') {
        let createdAt = new Date();
        try {
            let recordId = record.id;
            let dbRecord =
                (dbCtx.recordsets[
                    brCtx.current_table.recordsetIdx
                ].records.filter((record) => record.uid === recordId) ||
                    [])[0] || {};
            for (let i = 0; i < dbRecord.cells.length; i++) {
                let cell = dbRecord.cells[i];
                if (cell.uid.split('-')[0] === 'createdAt') {
                    createdAt = new Date(cell.value);
                    break;
                }
            }
        } catch (err) {}
        cellValue = createdAt.getTime();
    } else if (field.label === 'LAST UPDATE' && field.type === 'date') {
        let updatedAt = new Date();
        try {
            let recordId = record.id;
            let dbRecord =
                (dbCtx.recordsets[
                    brCtx.current_table.recordsetIdx
                ].records.filter((record) => record.uid === recordId) ||
                    [])[0] || {};
            for (let i = 0; i < dbRecord.cells.length; i++) {
                let cell = dbRecord.cells[i];
                if (cell.uid.split('-')[0] === 'updatedAt') {
                    updatedAt = new Date(cell.value);
                    break;
                }
            }
        } catch (err) {
            console.warn(err);
        }
        cellValue = updatedAt.getTime();
    }
    return cellValue;
};

export const compare = (record1, record2, sortRulesList, brCtx, dbCtx) => {
    let cellset = Object.keys(record1['cellsets']);
    for (let i = 0; i < sortRulesList.length; i++) {
        if (!sortRulesList[i].field || !sortRulesList[i].rule) {
            continue;
        }
        let sortRuleField = sortRulesList[i].field;
        let cell1Value = getCellValue_forSort(
                record1,
                sortRuleField,
                cellset[brCtx.current_table.fieldsetIdx - 1],
                brCtx,
                dbCtx
            ),
            cell2Value = getCellValue_forSort(
                record2,
                sortRuleField,
                cellset[brCtx.current_table.fieldsetIdx - 1],
                brCtx,
                dbCtx
            );
        if (cell1Value < cell2Value) {
            return sortRulesList[i]['rule'] === 'ascending' ? -1 : 1;
        } else if (cell1Value > cell2Value) {
            return sortRulesList[i]['rule'] === 'ascending' ? 1 : -1;
        }
    }
    return 0;
};

export const SortUtility = ({ open, setOpen, trigger, isActive = false }) => {
    const sortingFieldRef = useRef(null);

    const [fieldOptions, setFieldOptions] = useState(null);

    const brCtx = useBarelayout();

    useEffect(() => {
        var options = [];

        let isCreationDateOptionHidden =
            (
                brCtx.sortRulesList.filter(
                    (elm) => elm.field.id === 'date_of_creation'
                ) || []
            ).length > 0;

        options.push({
            id: 'date_of_creation',
            value: 'date_of_creation',
            idx: 0,
            parentIdx: -1,
            label: 'Creation Date',
            hidden: isCreationDateOptionHidden,
        });

        brCtx.fieldsets &&
            brCtx.fieldsets[brCtx.current_table.fieldsetIdx] &&
            brCtx.fieldsets[brCtx.current_table.fieldsetIdx].fields.map(
                (field, y) => {
                    if (
                        field.type === 'selector' ||
                        field.type === 'image' ||
                        field.type === 'rich_text' ||
                        field.label === 'Tags'
                    )
                        return null;

                    let isHidden =
                        (
                            brCtx.sortRulesList.filter(
                                (elm) => elm.field.id === field.id
                            ) || []
                        ).length > 0;

                    options.push({
                        id: field.id,
                        value: field.id,
                        idx: y,
                        parentIdx: brCtx.current_table.fieldsetIdx,
                        label: field.label.toLowerCase(),
                        hidden: isHidden,
                    });
                }
            );

        setFieldOptions(options);
    }, [brCtx.fieldsets, brCtx.sortRulesList]);

    const handleFieldChange = (e, idx) => {
        try {
            let field;
            if (e.option.id === 'date_of_creation') {
                field = [
                    {
                        automatic_value: false,
                        id: 'date_of_creation',
                        label: 'Creation Date',
                        type: 'date',
                        value: null,
                        width: { normal: 310 },
                    },
                ];
            } else {
                field = brCtx.fieldsets[brCtx.current_table.fieldsetIdx][
                    'fields'
                ].filter((elm) => elm.id === e.option.id);
            }
            let newSorlRules = brCtx.sortRulesList.slice(0);
            newSorlRules[idx] = {
                field: JSON.parse(JSON.stringify(field[0])),
                rule: 'ascending',
            };
            brCtx.setBasic({
                sortRulesList: newSorlRules.slice(0),
            });
        } catch (err) {
            console.warn(err);
        }
    };

    return (
        <>
            <PopupMenu
                className={'popout-box'}
                offsetX={0}
                offsetY={-20}
                nested={true}
                disabled={!isActive}
                open={open}
                onClose={() => {
                    setOpen(false);
                }}
                onOpen={() => {
                    setOpen(true);
                }}
                trigger={
                    trigger || (
                        <Button
                            id={'open-sort-btn'}
                            className={`no-default-style ${
                                open ? 'selected' : ''
                            }`}>
                            <Symbol className={'mr-4'} name={'sort_by_alpha'} />
                            <Text value={'Sort'} />
                        </Button>
                    )
                }
                contentStyle={{
                    width: 340,
                }}
                position={'right top'}>
                {brCtx.sortRulesList.map((elm, ruleIndex) => (
                    <div className="--sortrule-item" key={ruleIndex}>
                        <div className="--sortrule-item-field">
                            <SelectBox
                                ref={sortingFieldRef}
                                onChange={(e) => {
                                    handleFieldChange(e, ruleIndex);
                                }}
                                options={fieldOptions}
                                triggerStyle={{
                                    width: '100%',
                                }}
                                defaultValue={
                                    brCtx.sortRulesList[ruleIndex].field !==
                                    null
                                        ? brCtx.sortRulesList[ruleIndex].field
                                              .id
                                        : null
                                }
                            />
                        </div>

                        <div className="--sortrule-item-ruleselector">
                            {elm.field === null ? (
                                <></>
                            ) : (
                                ((fielType) => {
                                    let options = [];
                                    switch (fielType) {
                                        case 'integer':
                                            options = ['0', '9'];
                                            break;
                                        case 'checkbox':
                                            options = [
                                                <Symbol name="checkboxoff" />,
                                                <Symbol name="checkboxon" />,
                                            ];
                                            break;
                                        case 'date':
                                            options = ['1', '9'];
                                            break;
                                        default:
                                            options = ['A', 'Z'];
                                            break;
                                    }
                                    return (
                                        <>
                                            <div
                                                className={`${
                                                    elm.rule === 'ascending'
                                                        ? '--selected'
                                                        : ''
                                                }`}
                                                onClick={() => {
                                                    let newSorlRules =
                                                        brCtx.sortRulesList.slice(
                                                            0
                                                        );
                                                    newSorlRules[ruleIndex] = {
                                                        ...newSorlRules[
                                                            ruleIndex
                                                        ],
                                                        rule: 'ascending',
                                                    };
                                                    brCtx.setBasic({
                                                        sortRulesList:
                                                            newSorlRules.slice(
                                                                0
                                                            ),
                                                    });
                                                }}>
                                                <span>{options[0]}</span>
                                                <Symbol
                                                    name="arrow_left"
                                                    color="#000"
                                                />
                                                <span>{options[1]}</span>
                                            </div>
                                            <div
                                                className={`${
                                                    elm.rule === 'descending'
                                                        ? '--selected'
                                                        : ''
                                                }`}
                                                onClick={() => {
                                                    let newSorlRules =
                                                        brCtx.sortRulesList.slice(
                                                            0
                                                        );
                                                    newSorlRules[ruleIndex] = {
                                                        ...newSorlRules[
                                                            ruleIndex
                                                        ],
                                                        rule: 'descending',
                                                    };
                                                    brCtx.setBasic({
                                                        sortRulesList:
                                                            newSorlRules.slice(
                                                                0
                                                            ),
                                                    });
                                                }}>
                                                <span>{options[1]}</span>
                                                <Symbol
                                                    name="arrow_left"
                                                    color="#000"
                                                />
                                                <span>{options[0]}</span>
                                            </div>
                                        </>
                                    );
                                })(elm.field.type)
                            )}
                        </div>
                        <div
                            className={`--sortrule-item-delete ${
                                ruleIndex === 0 ? '--hidden' : ''
                            }`}
                            onClick={() => {
                                if (ruleIndex === 0) {
                                    return;
                                }
                                let newSorlRules = brCtx.sortRulesList.slice(0);
                                newSorlRules.splice(ruleIndex, 1);
                                brCtx.setBasic({
                                    sortRulesList: newSorlRules.slice(0),
                                });
                            }}>
                            <Symbol />
                        </div>
                        <div className="--sortrule-item-draghandel">
                            <Symbol name="drag_indicator" />
                        </div>
                    </div>
                ))}
                {brCtx.fieldsets[brCtx.current_table.fieldsetIdx] &&
                    brCtx.sortRulesList.length <
                        brCtx.fieldsets[brCtx.current_table.fieldsetIdx][
                            'fields'
                        ].length +
                            1 && (
                        <>
                            <div className="--sortrule-item">
                                <div className="--sortrule-item-field">
                                    <SelectBox
                                        ref={sortingFieldRef}
                                        forceDefaultValue={true}
                                        onChange={(e) => {
                                            if (
                                                brCtx.sortRulesList.length <
                                                brCtx.fieldsets[
                                                    brCtx.current_table
                                                        .fieldsetIdx
                                                ]['fields'].length +
                                                    1
                                            ) {
                                                try {
                                                    let field;
                                                    if (
                                                        e.option.id ===
                                                        'date_of_creation'
                                                    ) {
                                                        field = [
                                                            {
                                                                automatic_value: false,
                                                                id: 'date_of_creation',
                                                                label: 'Creation Date',
                                                                type: 'date',
                                                                value: null,
                                                                width: {
                                                                    normal: 310,
                                                                },
                                                            },
                                                        ];
                                                    } else {
                                                        field = brCtx.fieldsets[
                                                            brCtx.current_table
                                                                .fieldsetIdx
                                                        ]['fields'].filter(
                                                            (elm) =>
                                                                elm.id ===
                                                                e.option.id
                                                        );
                                                    }
                                                    brCtx.setBasic({
                                                        sortRulesList: [
                                                            ...brCtx.sortRulesList,
                                                            {
                                                                field: JSON.parse(
                                                                    JSON.stringify(
                                                                        field[0]
                                                                    )
                                                                ),
                                                                rule: 'ascending',
                                                            },
                                                        ],
                                                    });
                                                } catch (e) {
                                                    console.error(e);
                                                }
                                            }
                                        }}
                                        options={fieldOptions}
                                        triggerStyle={{
                                            width: '100%',
                                        }}
                                        defaultValue={null}
                                        placeholder={'Choice'}
                                    />
                                </div>

                                <div className="--sortrule-item-ruleselector"></div>
                                <div
                                    className="--sortrule-item-delete"
                                    style={{ cursor: 'default' }}></div>
                                <div className="--sortrule-item-draghandel">
                                    <Symbol name="drag_indicator" />
                                </div>
                            </div>
                        </>
                    )}
            </PopupMenu>
        </>
    );
};

const SelectPagePreviewView = () => {
    const HelpPagePreviewCtx = useHelpPagePreview();

    return (
        <>
            <Tooltip
                text={'Desktop'}
                trigger={
                    <Button
                        onClick={() => {
                            HelpPagePreviewCtx.methods.setView({
                                view: helpPagePreviewViews.desktop,
                            });
                        }}
                        className={`${
                            HelpPagePreviewCtx.state.view !==
                            helpPagePreviewViews.mobile
                                ? 'recordset-selector--selected-item'
                                : ''
                        }`}>
                        <Symbol name="desktop_icon" />
                    </Button>
                }
            />
            <Tooltip
                text={'Mobile'}
                trigger={
                    <Button
                        onClick={() => {
                            HelpPagePreviewCtx.methods.setView({
                                view: helpPagePreviewViews.mobile,
                            });
                        }}
                        className={`${
                            HelpPagePreviewCtx.state.view ===
                            helpPagePreviewViews.mobile
                                ? 'recordset-selector--selected-item'
                                : ''
                        }`}>
                        <Symbol name="mobile_icon" />
                    </Button>
                }
            />
        </>
    );
};

const AddNewQuestionFromSearch = () => {
    const dbCtx = useDatabase();
    const brCtx = useBarelayout();
    const settingCtx = useSetting();

    const [waitingForCreateRecord, setWaitingForCreateRecord] = useState(false);
    const [addRecordWasTriggered, setAddRecordWasTriggered] = useState({
        state: false,
        recordId: null,
    });

    const searchQuery = brCtx.searchOptions.query || '';

    useEffect(() => {
        if (brCtx.recordsets && addRecordWasTriggered.state) {
            brCtx.setBasic({
                active_cell: {
                    cellidx: 2,
                    e: null,
                    fieldidx: 2,
                    recordid: addRecordWasTriggered.recordId,
                    recordsetidx: 0,
                    is_calendar: false /** false */,
                },
            });
            settingCtx.setRightPanel({
                visible: true,
                active: RightPanelTabs.Recordview,
                force_view_style: rightPanelViewStyles.POPUP,
            });
            setAddRecordWasTriggered({ state: false, recordId: null });
        }
    }, [brCtx.recordsets, addRecordWasTriggered]);

    const handleAddRecord = async ({ useAI = false }) => {
        if (searchQuery.trim().length === 0) {
            return;
        }
        if (waitingForCreateRecord) {
            return;
        }
        setWaitingForCreateRecord(true);
        let faqTileId;
        let m = 1;
        for (let i = 0; m > 0 && i < dbCtx.fieldsets.length; i++) {
            for (
                let n = 0;
                m > 0 && n < dbCtx.fieldsets[i].fields.length;
                n++
            ) {
                let field = dbCtx.fieldsets[i].fields[n];
                if (field.label === 'FAQ') {
                    faqTileId = field.uid;
                    m--;
                    break;
                }
            }
        }
        let recordValues = {};
        recordValues[faqTileId] = searchQuery;
        try {
            let res = await dbCtx.addRecord({
                aliasuid: dbCtx.database.aliasuid,
                dbId: dbCtx.database.uid,
                recordsetuid:
                    dbCtx.recordsets[brCtx.current_table.recordsetIdx].uid,
                attributes: null,
                useAI: useAI,
                values: recordValues,
                noReload: false,
            });
            if (res.status === 201 && res.data.record) {
                closeSearch();
                setAddRecordWasTriggered({
                    state: true,
                    recordId: res.data.record.uid,
                });
            } else if (res.status === 400) {
                if (res.data.messageCode === 'LIMIT_REACHED') {
                    settingCtx.setUpgradeProjectDialog({
                        visible: true,
                        project_id: dbCtx.database.uid,
                        msg: 'You are currently on a free project, upgrade it and get unlimited Questions!',
                    });
                    showError(
                        'Upgrade to the PRO plan to be able to add more than 8 FAQs!'
                    );
                }
            } else {
                showError('Error creating a new FAQ, please try again!');
            }
        } catch (err) {}

        setWaitingForCreateRecord(false);
    };

    const closeSearch = () => {
        brCtx.setBasic({
            searchOptions: {
                ...brCtx.searchOptions,
                fields: [],
                query: '',
            },
        });
    };

    if (searchQuery.length === 0) {
        return <></>;
    }

    return (
        <>
            <div className="add-question-from-search">
                <Tooltip
                    text={'Generate with AI'}
                    trigger={
                        <Button
                            onClick={() => {
                                handleAddRecord({ useAI: true });
                            }}
                            loading={waitingForCreateRecord}
                            style={{
                                background: 'var(--app-primary-200)',
                                marginRight: '4px',
                            }}>
                            <Symbol name={'bot_icon'} />
                        </Button>
                    }
                />
                <Tooltip
                    text={'Add new Question'}
                    trigger={
                        <Button
                            onClick={handleAddRecord}
                            loading={waitingForCreateRecord}
                            style={{
                                background: 'var(--app-primary-200)',
                                marginRight: '5px',
                            }}>
                            <Symbol name={'add_circle_icon'} />
                        </Button>
                    }
                />
            </div>
        </>
    );
};

const ShareFAQsBtn = () => {
    const dbCtx = useDatabase();
    const authCtx = useAuth();
    const [sharePopupVisible, setSharPopupVisible] = useState(false);

    return (
        <>
            {/*{dbCtx.database &&*/}
            {/*    authCtx.aliases &&*/}
            {/*    authCtx.aliases[0] &&*/}
            {/*    authCtx.aliases[0].alias['templates'][0] && (*/}
            {/*        <Button*/}
            {/*            type="primary"*/}
            {/*            className={'openShareFAQsBtn'}*/}
            {/*            onClick={() => {*/}
            {/*                setSharPopupVisible(true);*/}
            {/*            }}>*/}
            {/*            <Symbol name="share" color={'#fff'} />*/}
            {/*            <Text style={{ marginLeft: '4px' }} value="Share" />*/}
            {/*        </Button>*/}
            {/*    )}*/}
            {sharePopupVisible &&
                createPortal(
                    <ShareFAQsPopup
                        isOpen={sharePopupVisible}
                        onClose={() => {
                            setSharPopupVisible(false);
                        }}
                    />,
                    document.body
                )}
        </>
    );
};
