export const MsgIcon = ({ className = null, color = undefined }) => {
    return (
        <svg
            className={'ui-sym path' + (className ? ' ' + className : '')}
            width="18"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M3.99967 9.33301H11.9997V7.99967H3.99967V9.33301ZM3.99967 7.33301H11.9997V5.99967H3.99967V7.33301ZM3.99967 5.33301H11.9997V3.99967H3.99967V5.33301ZM14.6663 14.6663L11.9997 11.9997H2.66634C2.29967 11.9997 1.98579 11.8691 1.72467 11.608C1.46356 11.3469 1.33301 11.033 1.33301 10.6663V2.66634C1.33301 2.29967 1.46356 1.98579 1.72467 1.72467C1.98579 1.46356 2.29967 1.33301 2.66634 1.33301H13.333C13.6997 1.33301 14.0136 1.46356 14.2747 1.72467C14.5358 1.98579 14.6663 2.29967 14.6663 2.66634V14.6663ZM2.66634 10.6663H12.5663L13.333 11.4163V2.66634H2.66634V10.6663Z"
                style={{ fill: color }}
                className="app-icon-fill"
            />
        </svg>
    );
};
