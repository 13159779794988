import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const propTypes = {
    children: PropTypes.node,
    tag: PropTypes.elementType,
    color: PropTypes.string,
    size: PropTypes.string,
    loading: PropTypes.bool,
    wide: PropTypes.bool,
    wideMobile: PropTypes.bool,
    disabled: PropTypes.bool,
    type: PropTypes.string,
};

const defaultProps = {
    children: null,
    tag: 'button',
    color: '',
    size: '',
    loading: false,
    wide: false,
    wideMobile: false,
    disabled: false,
    type: null,
    isSubmit: true
};

const Button = forwardRef(
    (
        {
            children,
            className,
            tag,
            color,
            size,
            loading,
            wide,
            wideMobile,
            disabled,
            type,
            isSubmit,
            ...props
        },
        ref
    ) => {
        const classes = classNames(
            'button',
            color && `button-${color}`,
            size && `button-${size}`,
            loading && 'is-loading',
            wide && 'button-block',
            wideMobile && 'button-wide-mobile',
            type && `button-${type}`,
            className
        );

        const Component = tag;
        return (
            <Component
                ref={ref}
                {...props}
                type={isSubmit?'':'button'}
                className={classes}
                disabled={disabled}>
                {children}
            </Component>
        );
    }
);

Button.propTypes = propTypes;
Button.defaultProps = defaultProps;

export default Button;
