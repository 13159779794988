import React, { useEffect, useState } from 'react';
import Page from '../../components/Page';
import { AliasProvider } from '../../contexts/AliasCtx';
import { useAliasUrl } from '../../hooks/useAliasUrl';
import useAuth from '../../hooks/useAuth';
import { QuestionsNotifCtxProvider } from '../../contexts/QuestionsNotifCtx';
import { HelpPagePreviewCtxProvider } from '../../contexts/HelpPagePreviewCtx';

const showAliasOverviewPage = false;

export default function AliasPage({ children }) {
    const [aliasAddr, databaseuid] = useAliasUrl();
    const authCtx = useAuth();
    const [alias, setAlias] = useState(null);

    const [round, setRound] = useState(0);

    useEffect(() => {
        if (!showAliasOverviewPage) {
            if (authCtx.aliases && authCtx.aliases.length > 0) {
                setAlias(authCtx.aliases[0]);
            }
        } else {
            const findAliasByAddr = (addr) => {
                var _alias = null;

                authCtx.aliases &&
                    authCtx.aliases.map((a) => {
                        if (a.alias.alias === addr) {
                            _alias = a;
                            return;
                        }
                    });

                setRound((oldRound) => oldRound + 1);

                return _alias;
            };

            console.log('[alias.page] mounting.');

            setAlias(findAliasByAddr(aliasAddr));

            return () => {
                console.log('[alias.page] unmounting.');
            };
        }
    }, [aliasAddr, authCtx.initialized]);

    useEffect(() => {
        if (!alias) return;
        if (!showAliasOverviewPage) {
            let pathName = `/${alias.alias.alias}`;
            if (!alias.alias['templates'][0]) {
                pathName += `/no_dbs`;
            } else {
                let lastOpenTemplateId = null;
                let firstTemplateId = alias.alias['templates'][0]['uid'];
                try {
                    lastOpenTemplateId = localStorage.getItem(
                        'faqnation_last_open_prj_id'
                    );
                } catch (err) {}
                try {
                    let sortedTemplates = alias.alias['templates'].sort(
                        (elm1, elm2) => {
                            let elm1Order = elm1['order'];
                            let elm2Order = elm2['order'];
                            return elm1Order - elm2Order;
                        }
                    );
                    firstTemplateId = sortedTemplates[0]['uid'];
                } catch (e) {}
                pathName += `/${lastOpenTemplateId || firstTemplateId}`;
            }
            if (!databaseuid) window.location.assign(pathName);
        } else {
            document.title = `FAQ Nation`;
        }
    }, [alias, databaseuid]);

    if (!showAliasOverviewPage) {
        return (
            <>
                {databaseuid && (
                    <Page title={'FAQ Nation'}>
                        <AliasProvider offline={true} alias={alias}>
                            <HelpPagePreviewCtxProvider>
                                <QuestionsNotifCtxProvider>
                                    {children}
                                </QuestionsNotifCtxProvider>
                            </HelpPagePreviewCtxProvider>
                        </AliasProvider>
                    </Page>
                )}
            </>
        );
    }

    return (
        <Page title={'FAQ Nation'}>
            <AliasProvider offline={true} alias={alias}>
                <HelpPagePreviewCtxProvider>
                    <QuestionsNotifCtxProvider>
                        {children}
                    </QuestionsNotifCtxProvider>
                </HelpPagePreviewCtxProvider>
            </AliasProvider>
        </Page>
    );
}
