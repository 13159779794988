export const Calendar31Icon = ({ className = null, color = undefined }) => {
    return (
        <svg
            className={'ui-sym path' + (className ? ' ' + className : '')}
            width="18"
            height="18"
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M20.057 3.92069H19.057V2.92069C19.057 2.37069 18.607 1.92069 18.057 1.92069C17.507 1.92069 17.057 2.37069 17.057 2.92069V3.92069H7.057V2.92069C7.057 2.37069 6.607 1.92069 6.057 1.92069C5.507 1.92069 5.057 2.37069 5.057 2.92069V3.92069H4.057C2.957 3.92069 2.057 4.82069 2.057 5.92069V21.9207C2.057 23.0207 2.957 23.9207 4.057 23.9207H20.057C21.157 23.9207 22.057 23.0207 22.057 21.9207V5.92069C22.057 4.82069 21.157 3.92069 20.057 3.92069ZM19.057 21.9207H5.057C4.507 21.9207 4.057 21.4707 4.057 20.9207V8.92069H20.057V20.9207C20.057 21.4707 19.607 21.9207 19.057 21.9207Z"
                style={{ fill: color }}
            />
            <path
                d="M10.1614 18.0733C9.75233 18.0733 9.38869 18.0033 9.07051 17.8631C8.75422 17.7229 8.50422 17.5279 8.32051 17.2779C8.1368 17.0279 8.03926 16.739 8.0279 16.4114H9.09608C9.10555 16.5686 9.15763 16.7059 9.25233 16.8233C9.34703 16.9389 9.47297 17.0288 9.63017 17.0932C9.78737 17.1576 9.9635 17.1898 10.1586 17.1898C10.3669 17.1898 10.5516 17.1538 10.7126 17.0819C10.8735 17.008 10.9995 16.9057 11.0904 16.775C11.1813 16.6444 11.2258 16.4938 11.2239 16.3233C11.2258 16.1472 11.1804 15.9919 11.0876 15.8574C10.9948 15.7229 10.8603 15.6178 10.6841 15.5421C10.5099 15.4663 10.2997 15.4284 10.0535 15.4284H9.53926V14.6159H10.0535C10.2561 14.6159 10.4332 14.5809 10.5847 14.5108C10.7381 14.4408 10.8584 14.3423 10.9455 14.2154C11.0326 14.0866 11.0752 13.9379 11.0734 13.7694C11.0752 13.6046 11.0383 13.4616 10.9626 13.3404C10.8887 13.2173 10.7836 13.1216 10.6472 13.0534C10.5127 12.9853 10.3546 12.9512 10.1728 12.9512C9.99475 12.9512 9.82998 12.9834 9.67847 13.0478C9.52695 13.1122 9.40479 13.204 9.31199 13.3233C9.21919 13.4408 9.16994 13.5809 9.16426 13.7438H8.15006C8.15763 13.418 8.25138 13.132 8.43131 12.8858C8.61313 12.6377 8.85555 12.4445 9.15858 12.3063C9.46161 12.1661 9.80157 12.0961 10.1785 12.0961C10.5667 12.0961 10.9038 12.169 11.1898 12.3148C11.4777 12.4587 11.7002 12.6529 11.8574 12.8972C12.0146 13.1415 12.0932 13.4114 12.0932 13.7069C12.0951 14.0345 11.9985 14.3091 11.8035 14.5307C11.6103 14.7523 11.3565 14.8972 11.0421 14.9654V15.0108C11.4512 15.0676 11.7646 15.2192 11.9824 15.4654C12.2021 15.7097 12.311 16.0137 12.3091 16.3773C12.3091 16.7031 12.2163 16.9947 12.0307 17.2523C11.847 17.508 11.5932 17.7087 11.2694 17.8546C10.9474 18.0004 10.5781 18.0733 10.1614 18.0733ZM15.6508 12.1756V17.9938H14.5968V13.2012H14.5627L13.2019 14.0705V13.1046L14.6479 12.1756H15.6508Z"
                style={{ fill: color }}
            />
        </svg>
    );
};

export const Calendar01Icon = ({ className = null, color = undefined }) => {
    return (
        <svg
            className={'ui-sym path' + (className ? ' ' + className : '')}
            width="18"
            height="18"
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M20 3.92069H19V2.92069C19 2.37069 18.55 1.92069 18 1.92069C17.45 1.92069 17 2.37069 17 2.92069V3.92069H7V2.92069C7 2.37069 6.55 1.92069 6 1.92069C5.45 1.92069 5 2.37069 5 2.92069V3.92069H4C2.9 3.92069 2 4.82069 2 5.92069V21.9207C2 23.0207 2.9 23.9207 4 23.9207H20C21.1 23.9207 22 23.0207 22 21.9207V5.92069C22 4.82069 21.1 3.92069 20 3.92069ZM19 21.9207H5C4.45 21.9207 4 21.4707 4 20.9207V8.92069H20V20.9207C20 21.4707 19.55 21.9207 19 21.9207Z"
                style={{ fill: color }}
            />
            <path
                d="M10.1044 18.1046C9.63662 18.1046 9.2351 17.9862 8.89988 17.7495C8.56654 17.5108 8.30991 17.1671 8.12999 16.7182C7.95196 16.2675 7.86294 15.7248 7.86294 15.0904C7.86484 14.4559 7.9548 13.9161 8.13283 13.4711C8.31276 13.0241 8.56938 12.6832 8.90272 12.4483C9.23794 12.2135 9.63851 12.0961 10.1044 12.0961C10.5703 12.0961 10.9709 12.2135 11.3061 12.4483C11.6414 12.6832 11.898 13.0241 12.076 13.4711C12.2559 13.918 12.3459 14.4578 12.3459 15.0904C12.3459 15.7267 12.2559 16.2703 12.076 16.7211C11.898 17.1699 11.6414 17.5127 11.3061 17.7495C10.9728 17.9862 10.5722 18.1046 10.1044 18.1046ZM10.1044 17.2154C10.4681 17.2154 10.755 17.0364 10.9652 16.6784C11.1773 16.3186 11.2834 15.7892 11.2834 15.0904C11.2834 14.6283 11.2351 14.24 11.1385 13.9256C11.0419 13.6112 10.9056 13.3745 10.7294 13.2154C10.5533 13.0544 10.345 12.9739 10.1044 12.9739C9.74268 12.9739 9.45669 13.1538 9.24647 13.5137C9.03624 13.8716 8.93018 14.3972 8.92829 15.0904C8.92639 15.5544 8.97279 15.9445 9.06749 16.2608C9.16408 16.5771 9.30044 16.8158 9.47658 16.9767C9.65272 17.1358 9.862 17.2154 10.1044 17.2154ZM15.6563 12.1756V17.9938H14.6023V13.2012H14.5682L13.2074 14.0705V13.1046L14.6534 12.1756H15.6563Z"
                style={{ fill: color }}
            />
        </svg>
    );
};
