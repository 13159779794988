import { showInfo } from '../components/towsts/Toasts';

export function getExpirationTime(day = 28) {
    var mo4 = new Date();
    var today = new Date();
    mo4.setTime(today.getTime() + day * 24 * 60 * 60 * 1000);
    return mo4;
}

export function getBearerTokenFormat(tk) {
    return `Bearer ${tk}`;
}
export function formatDate(date, dateFormat) {
    let d = '' + date.getDate();
    let m = '' + (date.getMonth() + 1);
    let y = '' + date.getFullYear();
    switch (dateFormat) {
        case 'YYYY-MM-DD':
            return `${y}-${m.length > 1 ? m : '0' + m}-${
                d.length > 1 ? d : '0' + d
            }`;
        case 'YYYY.MM.DD':
            return `${y}.${m.length > 1 ? m : '0' + m}.${
                d.length > 1 ? d : '0' + d
            }`;
        case 'YYYY/D/M':
            return `${y}.${d}.${m}`;
        case 'DD.MM.YY':
            return `${d.length > 1 ? d : '0' + d}.${
                m.length > 1 ? m : '0' + m
            }.${y.substring(2)}`;
        case 'DD.MM.YYYY':
            return `${d.length > 1 ? d : '0' + d}.${
                m.length > 1 ? m : '0' + m
            }.${y}`;
        case 'DD/MM/YYYY':
            return `${d.length > 1 ? d : '0' + d}/${
                m.length > 1 ? m : '0' + m
            }/${y}`;
        case 'D/M/YY':
            return `${d}/${m}/${y.substring(2)}`;
        case 'M/D/YY':
            return `${m}/${d}/${y.substring(2)}`;
        default:
            return `${y}-${m}-${d}`;
    }
}

export function formatTime(date, dateFormat) {
    let h = '' + date.getHours();
    let m = '' + date.getMinutes();
    let p;
    switch (dateFormat) {
        case '24h':
            return `${h.length > 1 ? h : '0' + h}:${
                m.length > 1 ? m : '0' + m
            }`;
        case '12h':
            h = date.getHours();
            p = h >= 12 ? ' PM' : ' AM';
            h = '' + (h % 12 || 12); // Handle 12-hour format properly
            return `${h.length > 1 ? h : '0' + h}:${
                m.length > 1 ? m : '0' + m
            }${p}`;
        default:
            return `${h}:${m}`;
    }
}
export function parseRBGClr(RRGGBB_clr) {
    let clr_int = parseInt(`0x${RRGGBB_clr.substring(1)}`);
    return [
        (clr_int & 0xff0000) >> 16,
        (clr_int & 0xff00) >> 8,
        clr_int & 0xff,
    ];
}

export function RGBToHSL(r, g, b) {
    r /= 255;
    g /= 255;
    b /= 255;
    const l = Math.max(r, g, b);
    const s = l - Math.min(r, g, b);
    const h = s
        ? l === r
            ? (g - b) / s
            : l === g
            ? 2 + (b - r) / s
            : 4 + (r - g) / s
        : 0;
    return [
        60 * h < 0 ? 60 * h + 360 : 60 * h,
        100 * (s ? (l <= 0.5 ? s / (2 * l - s) : s / (2 - (2 * l - s))) : 0),
        (100 * (2 * l - s)) / 2,
    ];
}

export function HSLToRGB(h, s, l) {
    s /= 100;
    l /= 100;
    const k = (n) => (n + h / 30) % 12;
    const a = s * Math.min(l, 1 - l);
    const f = (n) =>
        l - a * Math.max(-1, Math.min(k(n) - 3, Math.min(9 - k(n), 1)));
    return [255 * f(0), 255 * f(8), 255 * f(4)];
}

export function copy_text(text) {
    let input = document.createElement('input');
    input.setAttribute('type', 'text');
    input.value = text;
    input.select();
    input.setSelectionRange(0, text.length * 2); // For mobile devices

    // Copy the text inside the text field
    navigator.clipboard.writeText(input.value);
    showInfo('Text copied to clipboard!');
}

function generateRandomChar() {
    let chars = [
        'a',
        'b',
        'c',
        'd',
        'e',
        'f',
        'g',
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '0',
        '_',
        '-',
        'z',
        'z',
        'x',
        'y',
        'z',
    ];
    return chars[Math.floor(Math.random() * (chars.length - 1))];
}

export function generateRandomeId(prefix = '') {
    let id = `${prefix}-${generateRandomChar()}${generateRandomChar()}${generateRandomChar()}${Math.floor(
        Math.random() * 9999
    )}`;
    while (document.getElementById(id)) {
        id = `${prefix}-${Math.floor(Math.random() * 9999)}`;
    }
    return id;
}
