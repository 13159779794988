export function APIIcon({ className = null, color = undefined }) {
    return (
        <svg
            className={'ui-sym path' + (className ? ' ' + className : '')}
            width="25"
            height="25"
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <rect
                x="5.54297"
                y="5.22461"
                width="14.8105"
                height="6.2832"
                style={{ fill: color }}
                className="app-icon-fill-2"
            />
            <rect
                x="5.54199"
                y="14.291"
                width="14.8105"
                height="6.2832"
                style={{ fill: color }}
                className="app-icon-fill-2"
            />
            <path
                d="M8.89486 7.06337C8.51958 7.06337 8.20059 7.19472 7.93789 7.45742C7.67519 7.72012 7.54384 8.03911 7.54384 8.41439C7.54384 8.78968 7.67519 9.10867 7.93789 9.37137C8.20059 9.63407 8.51958 9.76542 8.89486 9.76542C9.27015 9.76542 9.58914 9.63407 9.85184 9.37137C10.1145 9.10867 10.2459 8.78968 10.2459 8.41439C10.2459 8.03911 10.1145 7.72012 9.85184 7.45742C9.58914 7.19472 9.27015 7.06337 8.89486 7.06337ZM8.89486 16.0702C8.51958 16.0702 8.20059 16.2015 7.93789 16.4642C7.67519 16.7269 7.54384 17.0459 7.54384 17.4212C7.54384 17.7965 7.67519 18.1155 7.93789 18.3782C8.20059 18.6409 8.51958 18.7722 8.89486 18.7722C9.27015 18.7722 9.58914 18.6409 9.85184 18.3782C10.1145 18.1155 10.2459 17.7965 10.2459 17.4212C10.2459 17.0459 10.1145 16.7269 9.85184 16.4642C9.58914 16.2015 9.27015 16.0702 8.89486 16.0702ZM5.74248 4.36133H20.1534C20.4086 4.36133 20.6225 4.44764 20.7951 4.62027C20.9677 4.7929 21.0541 5.00682 21.0541 5.26201V11.5668C21.0541 11.822 20.9677 12.0359 20.7951 12.2085C20.6225 12.3811 20.4086 12.4675 20.1534 12.4675H5.74248C5.48729 12.4675 5.27337 12.3811 5.10074 12.2085C4.92811 12.0359 4.8418 11.822 4.8418 11.5668V5.26201C4.8418 5.00682 4.92811 4.7929 5.10074 4.62027C5.27337 4.44764 5.48729 4.36133 5.74248 4.36133ZM6.64316 6.16269V10.6661H19.2527V6.16269H6.64316ZM5.74248 13.3681H20.1534C20.4086 13.3681 20.6225 13.4545 20.7951 13.6271C20.9677 13.7997 21.0541 14.0136 21.0541 14.2688V20.5736C21.0541 20.8288 20.9677 21.0427 20.7951 21.2153C20.6225 21.388 20.4086 21.4743 20.1534 21.4743H5.74248C5.48729 21.4743 5.27337 21.388 5.10074 21.2153C4.92811 21.0427 4.8418 20.8288 4.8418 20.5736V14.2688C4.8418 14.0136 4.92811 13.7997 5.10074 13.6271C5.27337 13.4545 5.48729 13.3681 5.74248 13.3681ZM6.64316 15.1695V19.6729H19.2527V15.1695H6.64316Z"
                style={{ fill: color }}
                className="app-icon-fill"
            />
        </svg>
    );
}
