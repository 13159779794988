export const FilterAltIcon = ({ className = null, color = undefined }) => {
    return (
        <svg
            className={'ui-sym path' + (className ? ' ' + className : '')}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M6.99999 6H17L11.99 12.3L6.99999 6ZM4.24999 5.61C6.26999 8.2 9.99999 13 9.99999 13V19C9.99999 19.55 10.45 20 11 20H13C13.55 20 14 19.55 14 19V13C14 13 17.72 8.2 19.74 5.61C20.25 4.95 19.78 4 18.95 4H5.03999C4.20999 4 3.73999 4.95 4.24999 5.61Z"
                style={{ fill: color }}
                className="app-icon-fill"
            />
        </svg>
    );
};

export const ClearFilterAltIcon = ({ className = null, color = undefined }) => {
    return (
        <svg
            className={'ui-sym path' + (className ? ' ' + className : '')}
            width="15"
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M9.25 7.48438L8.35938 6.59375L10.5938 3.75H5.51563L4.26563 2.5H11.875C12.1354 2.5 12.3229 2.61458 12.4375 2.84375C12.5521 3.07292 12.5313 3.29167 12.375 3.5L9.25 7.48438ZM12.3594 14.125L8.75 10.5156V11.875C8.75 12.0521 8.6901 12.2005 8.57031 12.3203C8.45052 12.4401 8.30208 12.5 8.125 12.5H6.875C6.69792 12.5 6.54948 12.4401 6.42969 12.3203C6.3099 12.2005 6.25 12.0521 6.25 11.875V8.01563L0.875 2.64063L1.75 1.75L13.25 13.25L12.3594 14.125Z"
                style={{ fill: color }}
                className="app-icon-fill"
            />
        </svg>
    );
};
