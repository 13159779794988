import { useEffect, useRef, useState } from 'react';
import PopupMenu from '../Popup';
import { faqnationEvents } from '../../constants/custom-events';
import { callApi } from '../../functions/api';
import { apiRoutes } from '../../helpers/api';
import Button from '../Button';
import './import-database-handler.scss';
import { showError, showInfo } from '../towsts/Toasts';

export const ImportDatabaseHandler = () => {
    const popupRef = useRef(null);
    const stateRef = useRef({
        visible: false,
        aliasId: '',
        dbId: '',
    });
    const [state, setState] = useState({
        visible: false,
        aliasId: '',
        dbId: '',
    });

    useEffect(() => {
        window.addEventListener(
            faqnationEvents.import_database,
            handleImportEvent
        );
        return () => {
            window.removeEventListener(
                faqnationEvents.import_database,
                handleImportEvent
            );
        };
    }, []);

    useEffect(() => {
        stateRef.current = { ...state };
    }, [state]);

    async function importDatabase(file) {
        if (!stateRef.current.aliasId || !stateRef.current.dbId) {
            return;
        }
        setVisible(true);
        const formData = new FormData();
        formData.append('file', file);
        const res = await callApi({
            method: 'post',
            path: apiRoutes.database.import
                .replace(':aliasuid', stateRef.current.aliasId)
                .replace(':templateuid', stateRef.current.dbId),
            headers: { 'Content-Type': 'multipart/form-data' },
            payload: formData,
        });
        if (res.status === 200) {
            showInfo('Project imported successfully!');
            document.location.reload()
        } else if (res.status === 500) {
            showError("Server error, we couldn't import your project!");
        } else {
            showError("Something went wrong, we couldn't import your project!");
        }
        resetState();
    }

    function handleImportEvent(evnt) {
        let details = evnt.detail;
        setState({
            visible: false,
            aliasId: details.aliasId,
            dbId: details.dbId,
        });
    }

    function handleSelectFile(evnt) {
        let file = evnt.target.files[0];
        if (!file) {
            resetState();
            return;
        }
        importDatabase(file);
    }

    function resetState() {
        setState({ visible: false, aliasId: '', dbId: '' });
        document.getElementById('import-database').value = '';
    }

    function setVisible(v) {
        setState((state) => ({
            ...state,
            visible: v,
        }));
    }

    return (
        <>
            <div style={{ position: 'fixed', top: '-200vh' }}>
                <label
                    htmlFor="import-database"
                    id="import-database-input-label"></label>

                <input
                    onChange={(evnt) => {
                        handleSelectFile(evnt);
                    }}
                    tabIndex={-1}
                    id="import-database"
                    type="file"
                    accept="text/csv"
                />
            </div>
            {state.visible && (
                <PopupMenu
                    className="import-indicator"
                    ref={popupRef}
                    open={true}
                    onOpen={() => {
                        setVisible(true);
                    }}
                    onClose={() => {
                        setVisible(false);
                    }}
                    closeOnDocumentClick={false}>
                    <Button loading={true}></Button>
                </PopupMenu>
            )}
        </>
    );
};

export function importDatabase({ dbId, aliasId }) {
    window.dispatchEvent(
        new CustomEvent(faqnationEvents.import_database, {
            detail: {
                dbId: dbId,
                aliasId: aliasId,
            },
        })
    );
    console.log(document.getElementById('import-database-input-label'));
    document.getElementById('import-database-input-label').click();
}
