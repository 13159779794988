import { useEffect, useState } from 'react';
import './color-selector.scss';
import { Text } from '../Text';
import { Lable } from '../../panels/projectSettingsPanel/ProjectSettingsPanel';

export function ColorSelector({
    id = '',
    label = null,
    help = {},
    value = '',
    onChange = (v) => {},
}) {
    const [hexValue, setHexValue] = useState('');

    useEffect(() => {
        let clrValue = value;
        if (clrValue.substring(0, 1) === '#') {
            clrValue = clrValue.substring(1);
        }
        setHexValue(clrValue.toUpperCase());
    }, [value]);
    return (
        <>
            <div className="color-selector-container">
                {label && <div className='label'><Lable help={help} text={label} /></div>}
                <div className={'color-selector-controls'}>
                    <label htmlFor={id} className={'color-selector'}>
                        <span
                            className={'color-selector--indicator'}
                            style={{ backgroundColor: value }}
                            data-clrvalue={value}></span>
                        <input
                            type="text"
                            value={hexValue}
                            className={'color-selector--hexvalue'}
                            onChange={(evnt) => {
                                setHexValue(evnt.target.value);
                            }}
                            onBlur={(evnt) => {
                                let clr = evnt.target.value;
                                if (clr.substring(0, 1) !== '#') {
                                    clr = '#' + clr;
                                }
                                if (clr.length !== 4 && clr.length !== 7) {
                                    setHexValue(value);
                                    return;
                                }
                                if (
                                    clr.substring(1).search(/[^0-9a-f]/i) >= 0
                                ) {
                                    setHexValue(value);
                                    return;
                                }
                                if (clr.length === 4) {
                                    let clrR = clr.substring(1, 2);
                                    let clrG = clr.substring(2, 3);
                                    let clrB = clr.substring(3, 4);
                                    clr = `#${clrR}${clrR}${clrG}${clrG}${clrB}${clrB}`;
                                }
                                onChange(clr);
                            }}
                            maxLength={7}
                        />
                    </label>
                    <input
                        id={id}
                        type={'color'}
                        value={(value || '').toLowerCase()}
                        onChange={(e) => {
                            onChange(e.target.value);
                        }}
                    />
                </div>
            </div>
        </>
    );
}
