import { useEffect, useRef, useState } from 'react';
import PopupMenu from '../Popup';
import { faqnationEvents } from '../../constants/custom-events';
import { callApi } from '../../functions/api';
import { apiRoutes } from '../../helpers/api';
import Button from '../Button';
import './export-database-handler.scss';
import { showInfo } from '../towsts/Toasts';

export const ExportDatabaseHandler = () => {
    const popupRef = useRef(null);
    const [state, setState] = useState({
        visible: false,
        aliasId: '',
        dbId: '',
        dbName: '',
    });

    useEffect(() => {
        window.addEventListener(
            faqnationEvents.export_database,
            handleExportEvent
        );
        return () => {
            window.removeEventListener(
                faqnationEvents.export_database,
                handleExportEvent
            );
        };
    }, []);

    useEffect(() => {
        (async () => {
            if (!state.visible || !state.aliasId || !state.dbId) {
                return;
            }
            let res = await callApi({
                method: 'post',
                path: apiRoutes.database.export
                    .replace(':aliasuid', state.aliasId)
                    .replace(':templateuid', state.dbId),
                payload: {},
            });
            if (res.status === 200) {
                let url = URL.createObjectURL(
                    new Blob([res.data || ''], { type: 'text/csv' })
                );
                let a = document.createElement('a');
                a.href = url;
                a.setAttribute(
                    'download',
                    `faqnation-${state.dbName.replace(' ', '-')}.csv`
                );
                a.click();
                showInfo('Project exported successfully!');
            }
            setState({ visible: false, aliasId: '', dbId: '', dbName: '' });
        })();
    }, [state]);

    function handleExportEvent(evnt) {
        let details = evnt.detail;
        setState({
            visible: true,
            aliasId: details.aliasId,
            dbId: details.dbId,
            dbName: details.dbName,
        });
    }

    function setVisible(v) {
        setState((state) => ({
            ...state,
            visible: v,
        }));
    }

    if (!state.visible) {
        return <></>;
    }

    return (
        <PopupMenu
            className="export-indicator"
            ref={popupRef}
            open={true}
            onOpen={() => {
                setVisible(true);
            }}
            onClose={() => {
                setVisible(false);
            }}
            closeOnDocumentClick={false}>
            <Button loading={true}></Button>
        </PopupMenu>
    );
};

export function exportDatabase({ dbName, dbId, aliasId }) {
    window.dispatchEvent(
        new CustomEvent(faqnationEvents.export_database, {
            detail: {
                dbName: dbName,
                dbId: dbId,
                aliasId: aliasId,
            },
        })
    );
}
