import React, { cloneElement, useEffect, useRef, useState } from 'react';
import Button from '../../components/Button';
import { Symbol } from '../../components/elements/Icon';
import PopupMenu, { SelectBox } from '../../components/Popup';
import { Text } from '../../components/Text';
import Database from '../../functions/Database';
import useSetting from '../../hooks/useSetting';
import { RightPanelTabs } from '../../panels/right/main';
import useBarelayout from '../barelayout/hook';
import { getAPIBase } from '../../env';
import useDatabase from '../../hooks/useDatabase';
import '../../assets/scss/components/select.scss';
import { createPortal } from 'react-dom';
import { formatDate, formatTime } from '../../functions/Utilities';
import useAuth from '../../hooks/useAuth';
import { recordsetsIdx } from '../barelayout/ctx';
import { UserQuestionPreviewPopup } from '../../components/user-question-preview-popup/UserQuestionPreviewPopup';
import { Switch } from '../../panels/right/accountOptionsPanel/accountOptionsPanel';
import { showError } from '../../components/towsts/Toasts';

let lastSelectedIdx = -1;
let lastSelection = [];

export const TableCell = ({
    cell = null,
    field = null,
    idx = null,
    recordset = null,
    fieldset = null,
    fieldsetidx = null,
    record = null,
    recordidx = null,
    recordsetidx = null,
    timeline = null,
    timelineDate = null,
    extraMode = false,
}) => {
    const brCtx = useBarelayout();
    const dbCtx = useDatabase();
    const authCtx = useAuth();
    const settingCtx = useSetting();

    const [userQuestionViewPopup, setUserQuestionPopup] = useState(false);

    const selectRef = useRef(null);

    const [newValue, setNewValue] = useState(undefined);
    const [isSelectMenuOpen, setIsSelectMenuOpen] = useState(false);
    const [buttonActionWaiting, setButtonActionWaiting] = useState(false);

    var style = {};

    const [options, setOptions] = useState([]);

    const FetchField = async () => {
        return await brCtx.fetchField({
            uid: field.id,
            fieldsetuid: fieldset.id,
        });
    };

    if (
        (recordset &&
            recordset.attributes &&
            recordset.attributes['cellstyle'] &&
            field.attributes &&
            field.attributes.use_records_style) ||
        field.type === 'selector'
    ) {
        style = recordset.attributes['cellstyle'];
    }

    const getValueCore = () => {
        return cell ? cell.value : null;
    };

    const getValue = () => {
        if (newValue !== undefined) {
            return newValue;
        }

        if (field.type === 'button') {
            return field.attributes.label || '';
        }

        var v = getValueCore();
        var finalValue = v;
        if (field.label === 'VIEWS' && finalValue === null) {
            finalValue = 0;
        }

        if (field.type === 'select') {
            finalValue = '';
            options &&
                options.map((opt) => {
                    if (opt.value === v) {
                        finalValue = opt.label;
                        return;
                    }
                });
            if (finalValue === null) {
                finalValue = '';
            }
        }

        if (field.type === 'date') {
            try {
                // console.log("Final value :: ", finalValue)
                if(finalValue && finalValue.length > 0) {
                    let date_time = finalValue;
                    let date = new Date(date_time); // Directly use the input value as Date
                    // console.log("Date came :: ", date)
                    let currentDate = new Date();
                    let dt = (currentDate - date) / (1000 * 60 * 60); // Difference in hours
                    console.log(dt);
                    if (date.getDate() === currentDate.getDate() &&
                        date.getMonth() === currentDate.getMonth() &&
                        date.getFullYear() === currentDate.getFullYear()) {
                        finalValue = `Today`;
                    } else if (dt > 0 && dt < 48) {
                        finalValue = `Yesterday`;
                    } else {
                        finalValue = `${formatDate(date, authCtx.account.date_format)}`;
                    }
                }
                else
                {
                    finalValue = '';
                }
            } catch (err) {
                finalValue = '';
            }
        }

        return finalValue;
    };

    const FetchAltImage = (imguid) => {
        /** imguid can be link also,,in case link, check it and fetch it, else do like below */

        Database.fetchDatabaseImage({
            imageuid: imguid,
        }).then((res) => {
            if (res.status === 200) {
                var img_cells = {
                    ...brCtx.image_cells,
                };
                img_cells[imguid] = res.data.image.data;
                brCtx.setBasic({
                    image_cells: img_cells,
                });
            }
        });
    };

    const getImageValue = () => {
        var value = getValue();

        if (value.includes('data:image') || value.includes('http')) {
            return value;
        }

        if (cell && cell.attributes && cell.attributes.image) {
            FetchAltImage(cell.attributes.image);
            return cell.attributes.image;
        }
    };

    useEffect(() => {
        if (field.type === 'select') {
            getSelectOptions();
        }

        // console.log("Select fields updated.");

        if (
            field.type === 'subuser' &&
            field.attributes &&
            field.attributes['override_type_to'] === 'select'
        ) {
            getSubuserSelectOptions();
        }
    }, [field.type, brCtx.select_fields]);

    const getSubuserSelectOptions = () => {
        // return [];
        return getSelectOptions();
    };

    const getSelectOptions = (force) => {
        if (!field) {
            return [];
        }

        if (
            field.attributes &&
            field.attributes.referType === 'recordsets_categorizer'
        ) {
            return [];
        }

        if (!force && brCtx.select_fields && brCtx.select_fields[field.id]) {
            setOptions(brCtx.select_fields[field.id].options || []);
            return;
        } else {
            /** fetch */
            return;
            FetchField().then((res) => {
                if (res.status === 200) {
                    var optionlist = [];

                    var optionsets = res.data.field.others.options;

                    optionsets &&
                        Array.isArray(optionsets) &&
                        optionsets.map((optset, x) => {
                            optset &&
                                optset.records &&
                                Array.isArray(optset.records) &&
                                optset.records.map((rec, y) => {
                                    optionlist.push({
                                        id: rec.uid,
                                        parentId: optset.uid,
                                        attributes: rec.attributes,
                                        value: rec.uid,
                                        label:
                                            (rec.cell && rec.cell.value) ||
                                            '<empty>',
                                    });
                                });
                        });
                    setOptions(optionlist);

                    var newoptions = {};

                    newoptions[field.id] = {
                        options: optionlist,
                    };

                    brCtx.setBasic({
                        select_fields: {
                            ...brCtx.select_fields,
                            ...newoptions,
                        },
                    });
                }
            });
            // console.log('FETCHING');
            // // console.log(brCtx.select_fields);
            // /** @todo fix here more than 1 requests (move it to the field) */
            // var newfield = {};
            // newfield[field.id] = {
            //     loading: true,
            // };
            // brCtx.setBasic({
            //     select_fields: {
            //         ...brCtx.select_fields,
            //         ...newfield,
            //     },
            // });
            // FetchField().then((res) => {
            //     if (res.status === 200) {
            //         var optionlist = [];
            //         var optionsets = res.data.field.others.options;
            //         optionsets &&
            //             Array.isArray(optionsets) &&
            //             optionsets.map((optset, x) => {
            //                 optset &&
            //                     optset.records &&
            //                     Array.isArray(optset.records) &&
            //                     optset.records.map((rec, y) => {
            //                         optionlist.push({
            //                             id: rec.uid,
            //                             parentId: optset.uid,
            //                             attributes: rec.attributes,
            //                             value: rec.uid,
            //                             label:
            //                                 (rec.cell && rec.cell.value) ||
            //                                 '<empty>',
            //                         });
            //                     });
            //             });
            //         setOptions(optionlist);
            //         var newoptions = {};
            //         newoptions[field.id] = {
            //             options: optionlist,
            //         };
            //         brCtx.setBasic({
            //             select_fields: {
            //                 ...brCtx.select_fields,
            //                 ...newoptions,
            //             },
            //         });
            //     }
            // });
        }
    };

    const isRecordSelected = (recordId = record.id) => {
        if (brCtx.selected_records) {
            if (brCtx.selected_records[recordsetidx]) {
                return brCtx.selected_records[recordsetidx].indexOf(recordId);
            }
        }
        return -1;
    };

    const handleOnClick = (e) => {
        if ((e && e.target.tagName === 'svg') || e.target.tagName === 'path') {
            if (
                brCtx.current_table.recordsetIdx !== recordsetsIdx.FAQsRecordset
            ) {
                return;
            }
            handleRecordCollapseClick(e);
            return;
        }

        if (field.type === 'selector') {
            let i = isRecordSelected();

            if (i > -1 && !e.shiftKey) {
                // console.log('Selected.');
                lastSelectedIdx = -1;
                let newArray = brCtx.selected_records[recordsetidx];
                newArray.splice(i, 1);

                var newObject = {};
                newObject[recordsetidx] = newArray;
                if (newArray.length < 1) {
                    newObject = {
                        ...(brCtx.selected_records || {}),
                    };
                    delete newObject[recordsetidx];
                    brCtx.setBasic({
                        selected_records: {
                            ...newObject,
                        },
                    });
                } else {
                    brCtx.setBasic({
                        selected_records: {
                            ...(brCtx.selected_records || {}),
                            ...newObject,
                        },
                    });
                }
            } else {
                let newArray;
                if (e.shiftKey && lastSelectedIdx>=0) {
                    newArray = [...lastSelection];
                    if (lastSelectedIdx >= 0) {
                        let selectionStart = Math.min(
                            recordidx,
                            lastSelectedIdx
                        );
                        let selectionEnd = Math.max(recordidx, lastSelectedIdx);
                        for (let i = selectionStart; i <= selectionEnd; i++) {
                            let recordId =
                                brCtx.recordsets[recordsetidx].records[i].id;
                            if (newArray.indexOf(recordId) === -1) {
                                newArray.push(recordId);
                            }
                        }
                    }
                } else {
                    newArray =
                        (brCtx.selected_records &&
                            brCtx.selected_records[recordsetidx]) ||
                        [];
                    newArray.push(record.id);
                    lastSelectedIdx = recordidx;
                    lastSelection = [...newArray];
                }

                var newObject = {};
                newObject[recordsetidx] = newArray;

                brCtx.setBasic({
                    selected_records: {
                        ...(brCtx.selected_records || {}),
                        ...newObject,
                    },
                });
            }
            return;
        }
        if (
            brCtx.current_table.recordsetIdx === recordsetsIdx.InboxsRecordset
        ) {
            setUserQuestionPopup(true);
            return;
        }

        if (e.isTrusted) {
            if (
                e.target.classList &&
                e.target.classList.contains('--close-select-menu')
            ) {
                return;
            }
            switch (field.type) {
                case 'checkbox':
                    return;
                case 'select':
                    setIsSelectMenuOpen(true);
                    return;
                default:
                    break;
            }
        }

        if (!timeline) {
            settingCtx.setRightPanel({
                visible: true,
                active: RightPanelTabs.Recordview,
            });
        }

        brCtx.setBasic({
            active_cell: {
                cellidx: idx,
                e: e,
                fieldidx: idx,
                recordid: record.id,
                recordsetidx: recordsetidx,
                is_calendar: timeline,
            },
        });
    };

    const handleRecordCollapseClick = (e) => {
        brCtx.toggleExpandRecords(record.id);
    };

    const handleRecordSelectChange = (e) => {
        brCtx.handleCellUpdate({
            e: e,
            field: field,
            cell: cell,
            record: record,
            fieldset: fieldset,
            recordset: recordset,
            is_calendar: false,
            new_value: e.option.value,
        });
    };

    const getRecordLastUpdate = () => {
        try {
            //get record from databaseCtx
            let drecord = (dbCtx.recordsets[
                brCtx.current_table.recordsetIdx
            ].records.filter((elm) => {
                return elm.uid === record.id;
            }) || [])[0];
            //get last update time of record
            let date_time = (
                (drecord.cells.filter((elm) => {
                    return elm.fielduid === 'updatedAt';
                }) || [])[0] || {}
            ).value;
            //format date
            let date = new Date(new Date(date_time).toLocaleString('en-US'));
            let currentDate = new Date();
            let dt = (currentDate.getTime() - date.getTime()) / 1000 / 60 / 60;
            if (date.getDate() === currentDate.getDate()) {
                return `Today ${formatTime(date, authCtx.account.time_format)}`;
            } else if (dt < 48) {
                return `Yesterday`;
            } else {
                return `${formatDate(date, authCtx.account.date_format)}`;
            }
        } catch (err) {
            //console.error(err);
            return '';
        }
    };

    const formatNumber = (n) => {
        if (n.toString().length < 2) {
            return '0' + n;
        }
        return n;
    };

    const renderNonTimelineCell = () => {
        return (
            <>
                {field.type === 'selector' ? (
                    <>
                        <div className="--selected-cell-indicator"></div>
                        <span className="--row-number">{recordidx + 1}</span>
                    </>
                ) : (
                    <>
                        {field.label === 'LAST UPDATE' ? (
                            <Text
                                className={'--grid-cell-text-content'}
                                style={!extraMode ? style : {}}
                                value={getRecordLastUpdate()}
                            />
                        ) : field.type === 'date' ? (
                            <Text
                                className={'--grid-cell-text-content'}
                                style={!extraMode ? style : {}}
                                value={getValue()}
                            />
                        ) : field.type === 'string' ? (
                            <Text
                                className={'--grid-cell-text-content'}
                                style={!extraMode ? style : {}}
                                value={getValue()}
                            />
                        ) : field.type === 'rich_text' ? (
                            <Text
                                className={'--grid-cell-text-content'}
                                style={!extraMode ? style : {}}
                                value={((v) => {
                                    let blocks = v['blocks'] || [];
                                    let value = '';
                                    if (blocks.length > 0) {
                                        for (
                                            let i = 0;
                                            i < blocks.length;
                                            i++
                                        ) {
                                            if (
                                                blocks[i].type === 'paragraph'
                                            ) {
                                                value = blocks[i].data
                                                    ? blocks[i].data.text
                                                    : '';
                                                break;
                                            } else if (
                                                blocks[i].type === 'header'
                                            ) {
                                                value = blocks[i].data
                                                    ? blocks[i].data.text
                                                    : '';
                                                break;
                                            }
                                        }
                                        if (value.length > 0) {
                                            let tempDiv =
                                                document.createElement('div');
                                            tempDiv.innerHTML = value;
                                            value = tempDiv.innerText;
                                        }
                                    }
                                    return value;
                                })(JSON.parse(getValue() || '{}'))}
                            />
                        ) : field.type === 'integer' ? (
                            <Text
                                className={'--grid-cell-text-content'}
                                style={!extraMode ? style : {}}
                                value={getValue()}
                            />
                        ) : field.type === 'link' ? (
                            <Text
                                className={'--grid-cell-text-content'}
                                style={!extraMode ? style : {}}
                                value={
                                    <a
                                        className="--link-wrapper"
                                        target={'_blank'}
                                        href={getValue()}
                                        rel="noreferrer">
                                        {getValue()}
                                    </a>
                                }
                            />
                        ) : field.type === 'email' ? (
                            <Text
                                className={'--grid-cell-text-content'}
                                style={!extraMode ? style : {}}
                                value={
                                    <a
                                        className="--link-wrapper"
                                        target={'_blank'}
                                        href={`mail:${getValue()}`}
                                        rel="noreferrer">
                                        {getValue()}
                                    </a>
                                }
                            />
                        ) : field.type === 'image' ? (
                            <>
                                {getValue() && (
                                    <img
                                        alt=""
                                        src={`${getAPIBase()}/media/${getValue()}`}
                                    />
                                )}
                            </>
                        ) : field.type === 'button' ? (
                            <>
                                <Button
                                    onClick={async (evnt) => {
                                        evnt.stopPropagation();
                                        evnt.preventDefault();
                                        let action = async () => {
                                            return dbCtx.createOrUpdateCell({
                                                recordsetuid: recordset.id,
                                                recorduid: record.id,
                                                fieldsetuid: fieldset.id,
                                                fielduid: field.id,
                                                value: '',
                                                attributes: null,
                                                datecode: null,
                                                optional_image: null,
                                                is_calendar: false,
                                            });
                                        };
                                        let confirmed = true;
                                        if (
                                            (
                                                field.attributes.action || ''
                                            ).indexOf('with_warning') >= 0
                                        ) {
                                            confirmed =
                                                window.confirm('Are you sure?');
                                        }
                                        if (confirmed) {
                                            setButtonActionWaiting(true);
                                            try {
                                                let res = await action();
                                            } catch (err) {
                                                showError(
                                                    'Something went wrong, please try again!'
                                                );
                                            }
                                            setButtonActionWaiting(false);
                                            document.location.reload();
                                        }
                                    }}
                                    style={{
                                        padding: '0 16px',
                                        height: '30px',
                                    }}
                                    type={'neutral'}
                                    loading={buttonActionWaiting}>
                                    {getValue()}
                                </Button>
                            </>
                        ) : field.type === 'checkbox' ? (
                            <Switch
                                id={'cell-' + record.id}
                                value={'' + getValue() === 'true'}
                                onUpdate={(newValue) => {
                                    brCtx.handleCellUpdate({
                                        e: {},
                                        field: field,
                                        cell: cell,
                                        record: record,
                                        fieldset: fieldset,
                                        recordset: recordset,
                                        is_calendar: false,
                                        new_value: newValue,
                                    });
                                }}
                            />
                        ) : field.type === 'select' ? (
                            <>
                                {getValue() ? (
                                    <Text
                                        className={
                                            '--grid-cell-text-content --grid-cell-sellect-value'
                                        }
                                        style={!extraMode ? style : {}}
                                        value={getValue()}
                                    />
                                ) : (
                                    <Text
                                        className={
                                            '--grid-cell-text-content --grid-cell-sellect-placeholder'
                                        }
                                        style={!extraMode ? { ...style } : {}}
                                        value={'Topic'}
                                    />
                                )}
                                <SelectMenu
                                    fieldset={fieldset}
                                    field={field}
                                    recordset={recordset}
                                    record={record}
                                    cell={cell}
                                    options={options}
                                    isOpen={isSelectMenuOpen}
                                    setIsOpen={setIsSelectMenuOpen}
                                    setOptions={setOptions}
                                    trigger={
                                        <span
                                            style={{
                                                position: 'absolute',
                                            }}></span>
                                    }
                                />
                            </>
                        ) : (
                            <Text
                                className={'--grid-cell-text-content'}
                                style={!extraMode ? style : {}}
                                value={getValue()}
                            />
                        )}
                    </>
                )}
            </>
        );
    };

    const getTimelineCellIsEditable = () => {
        var override_editable =
            field && field.attributes && field.attributes['override_editable'];

        if (override_editable && override_editable.enabled) {
            if (
                override_editable.prop &&
                override_editable.prop.includes('todayonly')
            ) {
                return timelineDate.is_today;
            }
        }

        return true;
    };

    const handleCheckboxToggle = (e) => {
        var new_value = getValue() === 'true' ? 'true' : 'false';

        setNewValue(new_value);

        brCtx
            .handleCellUpdate({
                e: e,
                field: field,
                cell: cell,
                record: record,
                fieldset: fieldset,
                recordset: recordset,
                is_calendar: timeline,
                new_value: new_value,
                calendar_datecode: timelineDate.datecode,
            })
            .then(() => {
                setNewValue(undefined);
            });
    };

    const handleSelectChange = (e) => {
        brCtx.handleCellUpdate({
            e: e,
            field: field,
            cell: cell,
            record: record,
            fieldset: fieldset,
            recordset: recordset,
            is_calendar: timeline,
            new_value: e.target.value,
            calendar_datecode: timelineDate.datecode,
        });
    };

    const handleNumpadClose = (e) => {
        if (newValue != undefined) {
            brCtx
                .handleCellUpdate({
                    e: e,
                    field: field,
                    cell: cell,
                    record: record,
                    fieldset: fieldset,
                    recordset: recordset,
                    is_calendar: timeline,
                    new_value: `${parseInt(newValue)}`,
                    calendar_datecode: timelineDate.datecode,
                })
                .then(() => {
                    setNewValue(undefined);
                });
        }
    };

    const getFieldType = () => {
        if (field.type === 'subuser') {
            return field.attributes.override_type_to;
        }

        return field.type;
    };

    const onNumpadClick = (e) => {
        let key = e.key;

        let lastValue = getValue();
        let newValue = '';

        if (key === 'CC') {
            setNewValue(null);
        } else if (key === 'C') {
            if (lastValue.length > 0) {
                newValue = lastValue.substring(0, lastValue.length - 1);
            }
        } else if (key === 'MINUS') {
            let t = '-';
            let found = false;
            if (lastValue[0] === '-') {
                found = true;
                t = '+';
            }

            if (lastValue[0] === '+') {
                found = true;
            }

            newValue = `${t}${
                lastValue
                    ? found
                        ? lastValue.substring(1, lastValue.length)
                        : lastValue
                    : ''
            }`;
        } else {
            newValue = `${lastValue ? lastValue : ''}${key}`;
        }

        if (newValue) {
            var intVal = parseFloat(newValue);

            if (intVal) {
                if (field.attributes && field.attributes.max) {
                    if (intVal > field.attributes.max) {
                        newValue = `${field.attributes.max}`;
                    }
                }

                if (field.attributes && field.attributes.min) {
                    if (intVal < field.attributes.min) {
                        newValue = `${field.attributes.min}`;
                    }
                }
            }
        }

        // console.log(intVal)

        // console.log("Last value: ", lastValue);
        // console.log("New value: ", newValue);

        setNewValue(newValue);
    };

    const getSuffix = () => {
        if (field.type === 'temperature') {
            if (field.attributes && field.attributes['unit']) {
                if (field.attributes['unit'] === 'dc') {
                    return ' °C';
                }
            }
        }

        return '';
    };

    const handleAddOptionKeyUp = (e) => {
        if (e.keyCode === 13) {
            // brCtx.addRecordset({
            //     label: e.target.value,
            // });

            brCtx
                .createOptionForField(fieldset.id, field.id, {
                    label: e.target.value,
                    attributes: null,
                })
                .then(() => {
                    getSelectOptions(true);
                });

            e.target.value = '';
        }
    };

    const renderTimelineCell = () => {
        // console.log(field)
        // console.log(timelineDate)

        return (
            <>
                {getFieldType() === 'string' ? (
                    <Text
                        className={'--grid-cell-text-content'}
                        style={style}
                        value={getValue()}
                    />
                ) : getFieldType() === 'checkbox' ? (
                    <>
                        {(timelineDate.use_default_value ||
                            timelineDate.is_today) && (
                            <Button
                                onClick={handleCheckboxToggle}
                                className={
                                    '--timeline-checkbox' +
                                    (getTimelineCellIsEditable()
                                        ? ''
                                        : ' --not-editable')
                                }
                                type={'icon'}>
                                {getValue() === 'true' ? (
                                    <Symbol name={'check'} />
                                ) : (
                                    ''
                                )}
                            </Button>
                        )}
                    </>
                ) : getFieldType() === 'select' ? (
                    <>
                        {(timelineDate.use_default_value ||
                            timelineDate.is_today) && (
                            <SelectBox
                                ref={selectRef}
                                onChange={handleSelectChange}
                                options={options}
                                defaultValue={getValue()}
                                disabled={!getTimelineCellIsEditable()}
                                triggerStyle={{
                                    height: 28,
                                    width: '100%',
                                }}
                            />
                        )}
                    </>
                ) : getFieldType() === 'temperature' ||
                  getFieldType() === 'integer' ? (
                    <>
                        {(timelineDate.use_default_value ||
                            timelineDate.is_today) && (
                            <PopupMenu
                                offsetX={0}
                                offsetY={0}
                                /** since cell click not triggering due to this, so when popup opens set active_cell like this. */
                                onOpen={handleOnClick}
                                onClose={handleNumpadClose}
                                contentStyle={{ width: 250 }}
                                trigger={
                                    <Button
                                        className={
                                            '--timeline-temperature' +
                                            (getTimelineCellIsEditable()
                                                ? ''
                                                : ' --not-editable')
                                        }>
                                        {getValue() && (
                                            <Text
                                                value={`${getValue()}${getSuffix()}`}
                                            />
                                        )}
                                    </Button>
                                }>
                                <div className="--inner-num-keyboard">
                                    <div className="--rows">
                                        <Button
                                            onClick={() =>
                                                onNumpadClick({
                                                    key: 7,
                                                })
                                            }
                                            className={'key'}
                                            type={'icon'}>
                                            <Text value={'7'} />
                                        </Button>
                                        <Button
                                            onClick={() =>
                                                onNumpadClick({
                                                    key: 8,
                                                })
                                            }
                                            className={'key'}
                                            type={'icon'}>
                                            <Text value={'8'} />
                                        </Button>
                                        <Button
                                            onClick={() =>
                                                onNumpadClick({
                                                    key: 9,
                                                })
                                            }
                                            className={'key'}
                                            type={'icon'}>
                                            <Text value={'9'} />
                                        </Button>
                                    </div>

                                    <div className="--rows">
                                        <Button
                                            onClick={() =>
                                                onNumpadClick({
                                                    key: 4,
                                                })
                                            }
                                            className={'key'}
                                            type={'icon'}>
                                            <Text value={'4'} />
                                        </Button>
                                        <Button
                                            onClick={() =>
                                                onNumpadClick({
                                                    key: 5,
                                                })
                                            }
                                            className={'key'}
                                            type={'icon'}>
                                            <Text value={'5'} />
                                        </Button>
                                        <Button
                                            onClick={() =>
                                                onNumpadClick({
                                                    key: 6,
                                                })
                                            }
                                            className={'key'}
                                            type={'icon'}>
                                            <Text value={'6'} />
                                        </Button>
                                    </div>

                                    <div className="--rows">
                                        <Button
                                            onClick={() =>
                                                onNumpadClick({
                                                    key: 1,
                                                })
                                            }
                                            className={'key'}
                                            type={'icon'}>
                                            <Text value={'1'} />
                                        </Button>
                                        <Button
                                            onClick={() =>
                                                onNumpadClick({
                                                    key: 2,
                                                })
                                            }
                                            className={'key'}
                                            type={'icon'}>
                                            <Text value={'2'} />
                                        </Button>
                                        <Button
                                            onClick={() =>
                                                onNumpadClick({
                                                    key: 3,
                                                })
                                            }
                                            className={'key'}
                                            type={'icon'}>
                                            <Text value={'3'} />
                                        </Button>
                                    </div>

                                    <div className="--rows">
                                        <Button
                                            onClick={() =>
                                                onNumpadClick({
                                                    key: 'MINUS',
                                                })
                                            }
                                            className={'key'}
                                            type={'icon'}>
                                            <Text value={'-'} />
                                        </Button>
                                        <Button
                                            onClick={() =>
                                                onNumpadClick({
                                                    key: 0,
                                                })
                                            }
                                            className={'key'}
                                            type={'icon'}>
                                            <Text value={'0'} />
                                        </Button>
                                        <Button
                                            onClick={() =>
                                                onNumpadClick({
                                                    key: 'C',
                                                })
                                            }
                                            className={'key'}
                                            type={'icon'}>
                                            <Symbol name={'backspace'} />
                                        </Button>
                                    </div>

                                    <div className="--rows">
                                        <Button
                                            onClick={() =>
                                                onNumpadClick({
                                                    key: 'CC',
                                                })
                                            }
                                            className={'key key-block'}
                                            type={'icon'}>
                                            <Text value={'Clear'} />
                                        </Button>
                                    </div>
                                </div>
                            </PopupMenu>
                        )}
                    </>
                ) : (
                    ''
                )}
            </>
        );
    };

    return (
        <>
            <td>
                <div
                    className={
                        '--grid-cell' +
                        ` --grid-cell-type-${field.type}` +
                        (idx === 0 &&
                        fieldsetidx === 1 &&
                        brCtx.expanded_records &&
                        record &&
                        brCtx.expanded_records.includes(record.id)
                            ? ' --expanded-record'
                            : '')
                    }
                    onClick={handleOnClick}
                    style={{ width: `${field.width.normal}px` }}>
                    {/*!extraMode && idx === 0 && fieldsetidx === 1 && (
                <Symbol
                    onClick={handleRecordCollapseClick}
                    className={'mr-4 collapse-expand-button-cell'}
                    name={'arrow_drop_down'}
                />
            )*/}

                    {/* /** Fix spacing */}
                    {extraMode && idx === 0 && fieldsetidx === 1 && (
                        <div
                            className={'mr-4'}
                            style={{
                                width: 24,
                            }}
                        />
                    )}

                    {timeline ? renderTimelineCell() : renderNonTimelineCell()}
                </div>
            </td>
            {userQuestionViewPopup && (
                <UserQuestionPreviewPopup
                    questionId={record.id}
                    onClose={() => {
                        setUserQuestionPopup(false);
                    }}
                />
            )}
        </>
    );
};

export const TableCellset = ({
    cellset = null,
    fieldset = null,
    recordset = null,
    record = null,
    recordidx = null,
    recordsetidx = null,
    idx = null,
    timeline = false,
    timelineDate = null,
    extraMode = false,
}) => {
    /** for now */

    return (
        <>
            {/*<div
                className={
                    '--grid-cellset' +
                    (timeline
                        ? timelineDate.is_today
                            ? ' --highlight --timeline'
                            : ' --timeline'
                        : '')
                }>*/}
            {fieldset &&
                fieldset.fields &&
                fieldset.fields.map((field, x) => {
                    if (field.attributes && field.attributes.hidden) {
                        return <React.Fragment key={field.id}></React.Fragment>;
                    }

                    return (
                        <TableCell
                            cell={
                                (cellset &&
                                    cellset.cells &&
                                    cellset.cells[field.id]) ||
                                null
                            }
                            key={field.id}
                            field={field}
                            fieldset={fieldset}
                            fieldsetidx={idx}
                            recordset={recordset}
                            record={record}
                            recordidx={recordidx}
                            recordsetidx={recordsetidx}
                            idx={x}
                            timeline={timeline}
                            timelineDate={timelineDate}
                            extraMode={extraMode}
                        />
                    );
                })}
            {/* </div> */}
        </>
    );
};

export const SelectMenu = ({
    fieldset,
    field,
    recordset,
    record,
    cell,
    isOpen,
    setIsOpen,
    trigger,
    options,
    setOptions,
}) => {
    const popup = useRef(null);
    const triggerRef = useRef(null);
    const submitBtn = useRef(null);
    const newOptionInput = useRef(null);

    const brCtx = useBarelayout();

    const [newOptionValue, setNewOptionValue] = useState('');
    const [editModActive, setEditModActive] = useState(false);
    const [pos, setPos] = useState({ x: -10000, y: -10000 });

    useEffect(() => {
        if (isOpen) {
            let x = triggerRef.current.getBoundingClientRect().left;
            let y = triggerRef.current.getBoundingClientRect().top;
            let popupW = popup.current.getBoundingClientRect().width;
            let popupH = popup.current.getBoundingClientRect().height;
            if (x + popupW > window.innerWidth) {
                x -= x + popupW - window.innerWidth;
            }
            if (y + popupH > window.innerHeight) {
                y -= y + popupH - window.innerHeight + 5;
            }
            setPos({ x: x, y: y });
        } else {
            setPos({ x: -10000, y: -10000 });
        }
    }, [isOpen]);

    const handleAddOption = () => {
        brCtx
            .createOptionForField(fieldset.id, field.id, {
                label: newOptionInput.current.value,
                attributes: null,
            })
            .then(() => {
                getSelectOptions(true, field, cell);
            });

        setNewOptionValue('');
    };

    const getSelectOptions = (force, field, cell) => {
        if (!field) {
            return [];
        }

        if (
            field.attributes &&
            field.attributes.referType === 'recordsets_categorizer'
        ) {
            return [];
        }

        if (!force && brCtx.select_fields && brCtx.select_fields[field.id]) {
            setOptions(brCtx.select_fields[field.id].options || []);
            return;
        } else {
            /** fetch */
            FetchField().then((res) => {
                if (res.status === 200) {
                    var optionlist = [];

                    var optionsets = res.data.field.others.options;

                    optionsets &&
                        Array.isArray(optionsets) &&
                        optionsets.map((optset, x) => {
                            optset &&
                                optset.records &&
                                Array.isArray(optset.records) &&
                                optset.records.map((rec, y) => {
                                    optionlist.push({
                                        id: rec.uid,
                                        parentId: optset.uid,
                                        attributes: rec.attributes,
                                        value: rec.uid,
                                        label:
                                            (rec.cell && rec.cell.value) ||
                                            '<empty>',
                                    });
                                });
                        });
                    setOptions(optionlist);

                    var newoptions = {};

                    newoptions[field.id] = {
                        options: optionlist,
                    };

                    brCtx.setBasic({
                        select_fields: {
                            ...brCtx.select_fields,
                            ...newoptions,
                        },
                    });
                }
            });
        }
    };

    const FetchField = async () => {
        return await brCtx.fetchField({
            uid: field.id,
            fieldsetuid: fieldset.id,
        });
    };

    const closePopup = () => {
        setEditModActive(false);
        setIsOpen(false);
    };

    const handleRecordSelectChange = (e, value) => {
        brCtx.handleCellUpdate({
            e: e,
            field: field,
            cell: cell,
            record: record,
            fieldset: fieldset,
            recordset: recordset,
            is_calendar: false,
            new_value: value === cell.value ? null : value,
        });
    };

    const updateOption = async (optionIndex, newValue) => {
        let newOptions = options.slice(0);
        newOptions[optionIndex].label = newValue;
        setOptions(newOptions.slice(0));
        await brCtx.updateField({
            uid: field.id,
            fieldsetuid: fieldset.id,
            data: {
                attributes: {
                    options: newOptions
                        .slice(0)
                        .filter((option) => option.id !== null)
                        .map((option, i) => ({
                            id: i + 1,
                            uid: option.id,
                            label: option.label,
                            attributes: option.attributes,
                        })),
                },
            },
        });
    };

    const removeOption = async (optionIndex) => {
        let newOptions = options.slice(0);
        newOptions.splice(optionIndex, 1);
        setOptions(newOptions.slice(0));
        await brCtx.updateField({
            uid: field.id,
            fieldsetuid: fieldset.id,
            data: {
                attributes: {
                    options: newOptions
                        .slice(0)
                        .filter((option) => option.id !== null)
                        .map((option, i) => ({
                            id: i + 1,
                            uid: option.id,
                            label: option.label,
                            attributes: option.attributes,
                        })),
                },
            },
        });
    };

    return (
        <>
            {cloneElement(trigger, { ref: triggerRef })}
            {isOpen &&
                createPortal(
                    <>
                        <div
                            className={'--close-select-menu'}
                            onClick={() => {
                                closePopup();
                            }}></div>
                        <div
                            ref={popup}
                            className={'--select-menu'}
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                            style={{
                                transform: `translate(${pos.x}px, ${pos.y}px)`,
                            }}>
                            {(options || []).map((option, i) => {
                                if (editModActive && option.id === null) {
                                    return <></>;
                                }
                                return (
                                    <SelectOption
                                        key={option.value}
                                        option={option}
                                        index={i}
                                        setOptions={setOptions}
                                        editable={editModActive}
                                        selected={option.value === cell.value}
                                        onSelectOptionChange={(e, v) => {
                                            handleRecordSelectChange(e, v);
                                            setIsOpen(false);
                                        }}
                                        updateOption={updateOption}
                                        removeOption={removeOption}
                                    />
                                );
                            })}
                            {!editModActive && (options || []).length === 0 && (
                                <Text
                                    style={{
                                        padding: '8px 0',
                                        textAlign: 'center',
                                        display: 'block',
                                        opacity: '.5',
                                    }}
                                    value={'No Options!'}
                                />
                            )}
                            {editModActive && (
                                <form
                                    className={
                                        '--select-add-option-input-container'
                                    }
                                    onSubmit={(evnt) => {
                                        evnt.preventDefault();
                                        handleAddOption();
                                    }}>
                                    <input
                                        ref={newOptionInput}
                                        className={'--select-add-option-input'}
                                        type="text"
                                        value={newOptionValue}
                                        placeholder={'New option title'}
                                        onChange={(evnt) => {
                                            setNewOptionValue(
                                                evnt.target.value
                                            );
                                        }}
                                        onBlur={(evnt) => {
                                            if (evnt.target.value.trim()) {
                                                handleAddOption();
                                            }
                                        }}
                                        required
                                    />
                                    <input
                                        ref={submitBtn}
                                        type={'submit'}
                                        style={{ display: 'none' }}
                                    />
                                    <Button
                                        type={'icon'}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            submitBtn.current.click();
                                        }}>
                                        <Symbol name="enter" />
                                    </Button>
                                </form>
                            )}
                            <div className="--select-separator"></div>
                            <div
                                className="--select-edit-btn"
                                onClick={(e) => {
                                    setEditModActive((v) => !v);
                                }}>
                                {editModActive ? 'Done' : 'Edit'}
                            </div>
                        </div>
                    </>,
                    document.getElementById('hero-popup-layer')
                )}
        </>
    );
};

const SelectOption = ({
    option,
    setOptions,
    index,
    editable,
    selected,
    onSelectOptionChange,
    updateOption,
    removeOption,
}) => {
    const editActive_ = useRef(false);
    const editable_ = useRef(false);
    const inputRef = useRef(null);
    const labelRef = useRef(null);
    const submitBtn = useRef(null);

    const [value, setValue] = useState('');
    const [editActive, setEditActive] = useState(false);

    useEffect(() => {
        setValue(option.label);
    }, [option]);

    useEffect(() => {
        editActive_.current = editActive;
        if (editActive && editable) {
            labelRef.current.click();
        }
    }, [editActive]);

    useEffect(() => {
        editable_.current = editable;
        if (!editable && editActive) {
            setEditActive(false);
        }
    }, [editable]);

    const saveChanges = () => {
        setEditActive(false);
        updateOption(index, inputRef.current.value);
    };

    return (
        <div
            className={`--select-option ${selected ? 'selected' : ''}`}
            onClick={(e) => {
                e.stopPropagation();
            }}>
            <form
                className={'--select-option-input-container'}
                onClick={(e) => {
                    if (!editable_.current) {
                        onSelectOptionChange(e, option.value);
                    } else if (!editActive_.current) {
                        setEditActive(true);
                    }
                }}
                onSubmit={(evnt) => {
                    evnt.preventDefault();
                    saveChanges();
                }}>
                <label
                    ref={labelRef}
                    htmlFor={`option-${option.value}-${index}`}
                    style={{ display: 'none' }}></label>
                <input
                    ref={inputRef}
                    className={'--select-option-input'}
                    type="text"
                    id={`option-${option.value}-${index}`}
                    value={option.id === null ? ' ' : value}
                    placeholder={'Option title'}
                    onChange={(evnt) => {
                        setValue(evnt.target.value);
                    }}
                    onBlur={() => {
                        setEditActive(false);
                        setValue(option.label);
                    }}
                    disabled={!editActive}
                    required
                />
                <input
                    ref={submitBtn}
                    type={'submit'}
                    style={{ display: 'none' }}
                />
            </form>
            {editable ? (
                <span
                    className={'--select-option-icon --remove'}
                    onClick={() => {
                        removeOption(index);
                    }}>
                    <Symbol name="close" />
                </span>
            ) : (
                <span className={'--select-option-icon --check'}>
                    <Symbol name="check" />
                </span>
            )}
        </div>
    );
};
