import Page from '../../components/Page';
import FaqnationWidget from '../../components/faqnationweidget/FaqnationWidget';
import { getAPIBase } from '../../env';
import Container from '../../layouts/mainlayout/Container';
import MainLayout from '../../layouts/mainlayout/MainLayout';

export default function FAQPage() {
    return (
        <Page title="FAQ Nation - FAQ">
            <MainLayout>
                <section style={{ marginBottom: '100px', marginTop: '80px' }}>
                    <h3>F.A.Q.</h3>
                    <Container>
                        <FaqnationWidget
                            faqId={'faq_faqnationWidgetWrapper'}
                            apiBase={'https://api.faqnation.com'}
                            faqnationProjectId={'tp_2Tdk5d1L'}
                            faqnationUserId={'JI7Jm4PdoPWB9SUNfOTxA'}
                            faqnationId={'Gi5s2IB3LNN26AQXhmdb1ct5YNSqW9AcUDry'}
                            faqsLimit={6}
                            options={{
                                hideSearch: false,
                                hideTopics: false,
                                hideSendQuestion: false,
                                colors: {
                                    primary_clr_light: '#3F4C63',
                                    on_primary_clr_light: '#fff',
                                },
                            }}
                        />
                    </Container>
                </section>
            </MainLayout>
        </Page>
    );
}
