import Page from '../../components/Page';
import { SelectPlan } from '../../components/Popup/Plans';
import FaqnationWidget from '../../components/faqnationweidget/FaqnationWidget';
import Container from '../../layouts/mainlayout/Container';
import MainLayout from '../../layouts/mainlayout/MainLayout';
import { useNavigate } from 'react-router-dom';

export default function PlansPage() {
    const navigate = useNavigate();

    function selectPlan(planName) {
        if (!planName) {
            return;
        }
        try {
            if (planName !== 'free') {
                sessionStorage.setItem('faqn_selected_plan', planName);
            }
        } catch (err) {}
        navigate('/signup');
    }

    return (
        <Page title="FAQ Nation - Plans">
            <MainLayout>
                <section style={{ marginBottom: '80px', marginTop: '80px' }}>
                    <Container>
                        <SelectPlan theme="light" action={selectPlan} />
                    </Container>
                </section>
                <section style={{ marginBottom: '100px', marginTop: '0' }}>
                    <Container>
                        <FaqnationWidget
                            faqId={'plans_faqnationWidgetWrapper'}
                            apiBase={'https://api.faqnation.com'}
                            faqnationProjectId={'tp_SrCDxZuF'}
                            faqnationUserId={'JI7Jm4PdoPWB9SUNfOTxA'}
                            faqnationId={'q3HTrCETwX5Kcyth3VeDnZT30oYRZdEA4Tqb'}
                            faqsLimit={6}
                            options={{
                                hideSearch: true,
                                hideTopics: true,
                                hideSendQuestion: false,
                                colors: {
                                    primary_clr_light: '#3f4c63',
                                    on_primary_clr_light: '#fff',
                                },
                            }}
                        />
                    </Container>
                </section>
            </MainLayout>
        </Page>
    );
}
