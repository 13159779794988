// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.events-subscribe-btn-container {
  text-align: center;
  padding: 0px 0 50px;
}
.events-subscribe-btn-container .events-subscribe-btn {
  min-width: 190px;
  display: inline-block;
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/pages/events/eventspage.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,mBAAA;AACJ;AAAI;EACI,gBAAA;EACA,qBAAA;EACA,kBAAA;AAER","sourcesContent":[".events-subscribe-btn-container {\n    text-align: center;\n    padding: 0px 0 50px;\n    .events-subscribe-btn {\n        min-width: 190px;\n        display: inline-block;\n        text-align: center;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
