import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Button from '../../components/Button';
import { Symbol } from '../../components/elements/Icon';
import Input from '../../components/Input';
import { Text } from '../../components/Text';
import useAlias from '../../hooks/useAlias';
import useAuth from '../../hooks/useAuth';
import useSetting from '../../hooks/useSetting';
import { BodyPanelTabs } from '../body';
import { TopPanelTabs } from '../top';

const DatabaseItem = ({ item = null, noBorder = false }) => {
    const aliasCtx = useAlias();
    const settingCtx = useSetting();
    // <Link to={item && item.uid || '#'}>

    const handleLinkClick = (e) => {
        settingCtx.setBodyPanel({ active: BodyPanelTabs.Databaseview });
        settingCtx.setTopPanel({ active: TopPanelTabs.DatabaseIdle });
    };

    return (
        <Link
            onClick={handleLinkClick}
            to={`/${aliasCtx.alias.alias}/${item.uid}`}>
            <div className={'--database' + (noBorder ? ' --no-border' : '')}>
                {item && (
                    <>
                        <div
                            className="--database-icon-box mb-8"
                            style={{ background: item['theme'] }}>
                            <Symbol name={item['icon']} />
                        </div>
                        <div>
                            <Text value={item['label']} />
                        </div>
                    </>
                )}
            </div>
        </Link>
    );
};

export default function AliasviewBodyPanel() {
    const aliasCtx = useAlias();
    const settingCtx = useSetting();

    const [databases, setDatabases] = useState(null);

    useEffect(() => {
        aliasCtx.groupDatabases();
    }, [aliasCtx.databases]);

    useEffect(() => {
        loadDatabases();
    }, [aliasCtx.grouped_databases, aliasCtx.hidden_group_idx]);

    const handleSearch = (q) => {
        let databases = [];
        aliasCtx.databases &&
            aliasCtx.databases.map((db, x) => {
                if (db.label.toLowerCase().includes(q.toLowerCase())) {
                    databases.push(db);
                }
            });
        setDatabases(databases);
    };

    const loadDatabases = () => {
        var databases = [];

        aliasCtx.grouped_databases &&
            Object.keys(aliasCtx.grouped_databases).map((key, x) => {
                /** if this key is visible */
                if (aliasCtx.hidden_group_idx.length > 0) {
                    if (aliasCtx.hidden_group_idx.indexOf(x) > -1) {
                        aliasCtx.grouped_databases[key] &&
                            aliasCtx.grouped_databases[key].map(
                                (database, y) => {
                                    databases.push(database);
                                }
                            );
                    }
                } else {
                    aliasCtx.grouped_databases[key] &&
                        aliasCtx.grouped_databases[key].map((database, y) => {
                            databases.push(database);
                        });
                }
            });

        setDatabases(databases);
    };

    useEffect(() => {
        if (
            settingCtx.top_panel.query &&
            settingCtx.top_panel.query.length > 0
        ) {
            handleSearch(settingCtx.top_panel.query);
        } else {
            setDatabases(aliasCtx.databases);
        }
    }, [settingCtx.top_panel.query]);

    return (
        <>
            <div
                className="--panel-section"
                style={{ height: 'auto', paddingBottom: 80 }}>
                {aliasCtx &&
                    aliasCtx.initialized &&
                    databases &&
                    databases.map((database, x) => {
                        return (
                            <DatabaseItem
                                key={database.uid}
                                noBorder={x % 2}
                                item={database}
                            />
                        );
                    })}
            </div>
        </>
    );
}

export function AliaspinBodyPanel() {
    const authCtx = useAuth();
    const aliasCtx = useAlias();
    const settingCtx = useSetting();

    const [pin, setPin] = useState(null);

    const [signoutWarn, setSignoutWarn] = useState(false);

    const handlePinOnChange = (e) => {
        setPin(e.target.value);
    };

    useEffect(() => {
        const unlock = async (_pin) => {
            let result = await aliasCtx.unlockAlias({
                pin: _pin,
            });

            if (result) {
                // refreshAlias
                aliasCtx.reload().then(() => {
                    settingCtx.setBodyPanel({
                        active: BodyPanelTabs.AliasDashboard,
                    });
                });
            } else {
                // setPin(null);
            }
        };

        if (pin && pin.length > 3) {
            unlock(pin);
        }
    }, [pin]);

    const handleSignoutClick = (e) => {
        if (signoutWarn) {
            authCtx.removeAliasLogin({
                uid: aliasCtx.alias.uid
            })
            authCtx.removeLoginCookies({
                redirectToMain: true,
            });
        } else {
            setSignoutWarn(true);
        }
    };

    return (
        <>
            <div
                className="--panel-section --flex-l --column --auto-height p-16"
                style={{ zIndex: '999999999999' }}>
                <Button
                    wide={true}
                    style={{ width: '80%', maxWidth: 300 }}
                    onClick={handleSignoutClick}>
                    {signoutWarn ? (
                        <Text
                            style={{ color: '#ff0060' }}
                            value={'Are you sure?'}
                        />
                    ) : (
                        <Text value={'Logout'} />
                    )}
                </Button>

                <Text
                    className={'mt-12'}
                    value={aliasCtx.alias && aliasCtx.alias.label}
                />
            </div>

            <div
                className="--panel-section --flex-l p-16"
                style={{ position: 'absolute' }}>
                <div className="--above-box">
                    {/* {aliasCtx.loading && <Button style={{
            position: 'absolute',
            right: 0,
            top: -20,
            pointerEvents: 'none'
          }} type={'icon'} loading={true}></Button>} */}
                    <Input
                        onChange={handlePinOnChange}
                        label={
                            <>
                                <Text value={'PIN'} />
                            </>
                        }
                        boxClassName={'--pin-input'}
                        className={'encode-as-password --hide-default-controls'}
                        type={'tel'}
                    />
                </div>
            </div>
        </>
    );
}
