import { apiRoutes } from '../helpers/api';
import { callApi } from './api';

async function generateTags({
    aliasId = undefined,
    projectId = undefined,
    questionId = undefined,
}) {
    if (!questionId || !aliasId || !projectId) {
        return {};
    }

    return callApi({
        method: 'post',
        path: apiRoutes.AI.generateTags
            .replace('{aliasuid}', aliasId)
            .replace('{databaseuid}', projectId)
            .replace('{questionid}', questionId),
        payload:{}
    });
}

export default { generateTags };
