import React, { useEffect, useRef, useState } from 'react';
import Button from '../../components/Button';
import { Symbol } from '../../components/elements/Icon';
import Input from '../../components/Input';
import { Text } from '../../components/Text';
import useBarelayout from './hook';

const BrCellboxDialog = () => {
    const brCtx = useBarelayout();

    const [cell, setCell] = useState(null);
    const [field, setField] = useState(null);
    const [record, setRecord] = useState(null);
    const [fieldset, setFieldset] = useState(null);
    const [recordset, setRecordset] = useState(null);

    const inputRef = useRef(null);

    const getCell = () => {
        var { fieldsetidx, fieldidx, recordidx, recordsetidx } =
            brCtx.dialog.cellbox || {};

        var fieldset =
            (brCtx.calendar_fieldsets &&
                brCtx.calendar_fieldsets[fieldsetidx]) ||
            null;
        var record =
            (brCtx.recordsets &&
                brCtx.recordsets[recordsetidx] &&
                brCtx.recordsets[recordsetidx] &&
                brCtx.recordsets[recordsetidx].records &&
                brCtx.recordsets[recordsetidx].records[recordidx]) ||
            null;
        var field = (fieldset && fieldset.fields[fieldidx]) || null;

        var calendar_cellset = null;

        if (record && fieldset) {
            calendar_cellset =
                (record.calendar_cellsets &&
                    record.calendar_cellsets[brCtx.active_calendar_datecode] &&
                    record.calendar_cellsets[brCtx.active_calendar_datecode][
                        fieldset.id
                    ]) ||
                null;

            var cell =
                calendar_cellset &&
                calendar_cellset.cells &&
                calendar_cellset.cells[field.id];

            return {
                cell: cell,
                record: record,
                fieldset: fieldset,
                recordset:
                    (brCtx.recordsets && brCtx.recordsets[recordsetidx]) ||
                    null,
            };
        }

        return {
            record: record,
            cell: cell,
            fieldset: fieldset,
            recordset:
                (brCtx.recordsets && brCtx.recordsets[recordsetidx]) || null,
        };
    };

    const getField = () => {
        var { fieldsetidx, fieldidx, recordidx, recordsetidx } =
            brCtx.dialog.cellbox || {};

        if (
            brCtx.calendar_fieldsets &&
            brCtx.calendar_fieldsets[fieldsetidx] &&
            brCtx.calendar_fieldsets[fieldsetidx].fields
        ) {
            return (
                brCtx.calendar_fieldsets[fieldsetidx].fields[fieldidx] || null
            );
        }

        return null;
    };

    useEffect(() => {
        var cell = getCell();
        setCell(cell.cell);
        setRecord(cell.record);
        setFieldset(cell.fieldset);
        setRecordset(cell.recordset);
        setField(getField());
    }, [brCtx.calendar_fieldsets, brCtx.recordsets, brCtx.dialog.cellbox]);

    const handleOnChange = (e) => {};

    const handleOnUpdate = () => {
        handleClose();

        var value = (inputRef.current && inputRef.current.value) || null;

        var st = brCtx.handleCellUpdate({
            e: null,
            field: field,
            cell: cell,
            record: record,
            fieldset: fieldset,
            recordset: recordset,
            is_calendar: true,
            new_value: value,
        });
    };

    const handleClose = (e) => {
        brCtx.setBasic({
            dialog: {
                ...(brCtx.dialog || {}),
                visible: false,
            },
        });
    };

    return (
        <>
            {field && record && (
                <>
                    <div
                        className="--dialog-content-bar --center"
                        style={{ height: 50 }}>
                        {brCtx.fieldsets &&
                            brCtx.fieldsets.map((fieldset, x) => {
                                var cellset =
                                    (record.cellsets &&
                                        record.cellsets[fieldset.id]) ||
                                    null;

                                if (cellset) {
                                    return (
                                        <React.Fragment key={fieldset.id}>
                                            {fieldset &&
                                                fieldset.fields &&
                                                fieldset.fields.map(
                                                    (fieldz, z) => {
                                                        if (z === 0) {
                                                            var cell =
                                                                (cellset &&
                                                                    cellset
                                                                        .cells[
                                                                        fieldz
                                                                            .id
                                                                    ]) ||
                                                                null;

                                                            return (
                                                                <Text
                                                                    key={
                                                                        fieldz.id
                                                                    }
                                                                    className={
                                                                        'cell-text'
                                                                    }
                                                                    value={
                                                                        (cell &&
                                                                            cell.value) ||
                                                                        null
                                                                    }
                                                                />
                                                            );
                                                        }
                                                    }
                                                )}
                                        </React.Fragment>
                                    );
                                }
                            })}
                    </div>

                    <div
                        className="--dialog-content-bar --center"
                        style={{
                            width: '80%',
                            margin: '0 auto',
                            marginBottom: 16,
                        }}>
                        <Input
                            ref={inputRef}
                            key={field.id && field.id}
                            onBlur={handleOnChange}
                            style={{
                                width: '100%',
                                marginTop: 4,
                                marginBottom: 4,
                            }}
                            type={'tel'}
                            boxClassName={'w-100p'}
                            placeholder={'Anzahl'}
                            className={'--hide-default-controls'}
                            defaultValue={(cell && cell.value) || null}
                        />
                    </div>

                    <div
                        className="--dialog-content-bar --row --start"
                        style={{ width: '80%', margin: '2px auto' }}>
                        <input
                            className="mr-8"
                            id={'mhd'}
                            name={'_demo_opt'}
                            type={'radio'}
                        />
                        <label htmlFor={'mhd'}>
                            <Text value={'MHD uberschritten'} />
                        </label>
                    </div>

                    <div
                        className="--dialog-content-bar --row --start"
                        style={{ width: '80%', margin: '2px auto' }}>
                        <input
                            className="mr-8"
                            id={'bruch'}
                            name={'_demo_opt'}
                            type={'radio'}
                        />
                        <label htmlFor={'bruch'}>
                            <Text value={'Bruch'} />
                        </label>
                    </div>

                    <div
                        className="--dialog-content-bar --row --start"
                        style={{ width: '80%', margin: '2px auto' }}>
                        <input
                            className="mr-8"
                            id={'qual'}
                            name={'_demo_opt'}
                            type={'radio'}
                        />
                        <label htmlFor={'qual'}>
                            <Text value={'Qualität'} />
                        </label>
                    </div>

                    <div
                        className="--dialog-content-bar --spw --row"
                        style={{
                            width: '80%',
                            margin: '0 auto',
                            marginTop: 16,
                        }}>
                        <Button onClick={handleClose}>
                            <Symbol
                                name={'delete'}
                                className={'mr-8'}
                            />
                            <Text value={'Löschen'} />
                        </Button>

                        <Button onClick={handleOnUpdate}>
                            <Symbol
                                name={'check'}
                                className={'mr-8'}
                            />
                            <Text value={'Melden'} />
                        </Button>
                    </div>
                </>
            )}
        </>
    );
};

export default BrCellboxDialog;
