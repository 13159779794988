import axios from 'axios';
import { useMemo } from 'react';
import { useCookies } from 'react-cookie';
import { CookiesArray, CookiesList } from '../constants/Cookies';
import { getBearerTokenFormat } from '../functions/Utilities';
import axiosInstance from '../helpers/axios';
import useAuth from '../hooks/useAuth';
import useSetting from '../hooks/useSetting';
import { BodyPanelTabs } from '../panels/body';

const Axios = ({ children }) => {
    const [cookies] = useCookies(CookiesArray);
    const gAuth = useAuth();
    const settingCtx = useSetting();

    useMemo(() => {
        var times = 0;

        var newAuth = null;

        axiosInstance.interceptors.request.use(
            (config) => {
                const token = cookies[CookiesList.accessToken];
                if (token) {
                    config.headers['Authorization'] = newAuth
                        ? newAuth
                        : getBearerTokenFormat(token);
                    newAuth = null;
                }
                return config;
            },
            (error) => {
                return Promise.reject(error);
            }
        );

        axiosInstance.interceptors.response.use(
            (response) => {
                times = 0;
                return response;
            },
            async function (error) {
                const originalRequest = error.config;
                // console.log(originalRequest)
                // console.log(error)
                /** only try 5 times */
                if (
                    times < 3 &&
                    error.status === 401 &&
                    !originalRequest._retry &&
                    error.data &&
                    error.data.error &&
                    error.data.error.code === 'AUTHORIZATIONTOKEN_UNAUTHORIZED'
                ) {
                    times++;
                    console.error('Token expired: ' + times);
                    const new_access_token = await gAuth.refreshLogin();
                    // console.log(new_access_token)
                    newAuth = getBearerTokenFormat(
                        new_access_token.accessToken
                    );
                    // axios.defaults.headers.common['Authorization'] = 'getBearerTokenFormat(new_access_token.accessToken)';
                    return axiosInstance(originalRequest);
                }

                // if(error.data === undefined && error.name === 'AxiosError' && error.code === "ERR_NETWORK")
                // {
                //   // if(!window.navigator.onLine){
                //   //   console.log('no internet connection');
                //   // }
                //   window.alert("Error")
                //   settingCtx.setBodyPanel({
                //     active: BodyPanelTabs.ErrorPage
                //   });
                // }

                console.log(error);

                return Promise.reject(error);
            }
        );

        /** add refreshTk here */
    }, []);

    return children;
};

export default Axios;
