import React, {
    createContext,
    useEffect,
    useState,
    useReducer,
    useRef,
} from 'react';
import Alias from '../functions/Alias';
import Database from '../functions/Database';
import useAuth from '../hooks/useAuth';

const initialState = {
    initialized: false,

    alias: null,

    member: null,

    databases: null,

    grouped_databases: null,

    databases_notification: [],

    hidden_group_idx: [],

    loading: false,

    locked: null,

    isUnlocked: false,

    subuser: null,

    activedatabase: null,

    branding: null,

    error: null,
};

const handlers = {
    INITIALIZE: (state, action) => {
        const { alias, member, branding } = action.payload;

        return {
            ...state,
            error: null,
            ...(action.payload || {}),
            databases:
                (alias &&
                    alias.templates &&
                    alias.templates.sort(
                        (a, b) => a.orderNumber - b.orderNumber
                    )) ||
                null,
            subuser: (alias && alias.subuser) || null,
            locked: (alias && alias.authorized) || true,
            initialized: true,
        };
    },

    LATER: (state, action) => {
        return {
            ...state,
            ...(action.payload || {}),
        };
    },

    LOADING: (state, action) => {
        const { loading } = action.payload;

        return {
            ...state,
            loading: loading,
        };
    },
};

const reducer = (state, action) =>
    handlers[action.type] ? handlers[action.type](state, action) : state;

const AliasContext = createContext({
    ...initialState,
    unlockAlias: () => {},
    reload: () => {},
    groupDatabases: () => {},
    setHiddenGroups: () => {},
    createDatabase: async () => {},
    updateDatabase: async () => {},
    deleteDatabase: async () => {},
});

function AliasProvider({ children, alias, aliasAddr, offline }) {
    const [state, dispatch] = useReducer(reducer, initialState);
    const [loadedAlias, setLoadedAlias] = useState(alias);

    const authCtx = useAuth();

    useEffect(() => {
        setLoadedAlias(alias);
    }, [alias]);

    const auto_unlock = () => {
        if (localStorage.getItem(state.alias.uid)) {
            reload();
        }
    };

    useEffect(() => {
        if (state.initialized && !state.is_unlocked) {
            auto_unlock();
        }
    }, [state.initialized, state.locked, state.is_unlocked]);

    const setLoading = ({ loading = null }) => {
        dispatch({
            type: 'LOADING',
            payload: {
                loading: loading,
            },
        });
    };

    useEffect(() => {
        // console.log("[alias.ctx] mounting");

        /** here canceling doesn't required since we have no multi-alias system here */

        const loadBranding = () => {
            var brandLogo = null;

            Alias.fetchAliasBranding({
                aliasuid: loadedAlias['aliasuid'],
            }).then((brand) => {
                if (brand.status === 200) {
                    brandLogo =
                        (brand.data &&
                            brand.data.branding &&
                            brand.data.branding.data) ||
                        null;
                }

                dispatch({
                    type: 'LATER',
                    payload: {
                        branding: brandLogo,
                    },
                });
            });
        };

        const initialize = async () => {
            setLoading({
                loading: true,
            });

            loadBranding();

            const response = await Alias.fetchAlias({
                aliasuid: loadedAlias['uid'],
            });

            if (response.status === 200) {
                dispatch({
                    type: 'INITIALIZE',
                    payload: {
                        alias: (response.data && response.data.alias) || null,
                        member: (response.data && response.data.member) || null,
                    },
                });
            } else {
                dispatch({
                    type: 'INITIALIZE',
                    payload: {
                        alias: null,
                        member: null,
                        error: response,
                    },
                });
            }

            setLoading({
                loading: false,
            });
        };

        const initializeOffline = () => {
            setLoading({
                loading: true,
            });

            loadBranding();

            dispatch({
                type: 'INITIALIZE',
                payload: {
                    alias: loadedAlias.alias || null,
                    member: loadedAlias || null,
                },
            });

            setLoading({ loading: false });
        };

        if (loadedAlias && loadedAlias.uid) {
            if (offline) {
                initializeOffline();
            } else {
                initialize();
            }
        }

        return () => {
            // console.log("[alias.ctx] unmounting");
        };
    }, [loadedAlias]);

    const unlockAlias = async ({ pin = null }) => {
        setLoading({ loading: true });
        const response = await Alias.loginAlias({
            aliasuid: state.alias.uid,
            pin: pin,
        });

        setLoading({ loading: false });

        if (response.status === 200) {
            authCtx.saveAliasLogin({
                accessToken: response.data.alias.authorization.accessToken,
                uid: state.alias.uid,
            });

            return true;
        }

        return false;
    };

    const loadBranding = () => {
        var brandLogo = null;

        Alias.fetchAliasBranding({
            aliasuid: loadedAlias['aliasuid'],
        }).then((brand) => {
            if (brand.status === 200) {
                brandLogo =
                    (brand.data &&
                        brand.data.branding &&
                        brand.data.branding.data) ||
                    null;
            }

            dispatch({
                type: 'LATER',
                payload: {
                    branding: brandLogo,
                },
            });
        });
    };

    const reload = async (
        data = {
            including_branding: false,
        }
    ) => {
        setLoading({ loading: true });

        if (data.including_branding) {
            loadBranding();
        }

        const response = await Alias.fetchAlias({
            aliasuid: state.alias.uid,
        });

        if (response.status === 200) {
            dispatch({
                type: 'INITIALIZE',
                payload: {
                    alias: (response.data && response.data.alias) || null,
                    member: (response.data && response.data.member) || null,
                },
            });
        } else {
            dispatch({
                type: 'INITIALIZE',
                payload: {
                    alias: null,
                    member: null,
                    error: response,
                },
            });
        }

        setLoading({ loading: false });

        //get databases notification counts
    };

    const setHiddenGroups = (h = []) => {
        dispatch({
            type: 'LATER',
            payload: {
                hidden_group_idx: [...h],
            },
        });
    };

    const groupDatabases = () => {
        var map = {};

        state.databases &&
            state.databases.map((database, x) => {
                let tag_name =
                    (database.attributes && database.attributes.tag) || '';

                if (tag_name === '') {
                    map[tag_name] = [...(map[tag_name] || []), database];
                }
            });

        state.databases &&
            state.databases.map((database, x) => {
                let tag_name =
                    (database.attributes && database.attributes.tag) || '';

                if (tag_name !== '') {
                    map[tag_name] = [...(map[tag_name] || []), database];
                }
            });

        dispatch({
            type: 'LATER',
            payload: {
                grouped_databases: map,
            },
        });
    };

    const createDatabase = async ({
        label = null,
        icon = null,
        theme = null,
        sortNumber = null,
        attributes = null,
        layout = null,
    }) => {
        const _resp = await Database.createDatabase({
            aliasuid: state.alias.uid,
            dbdata: {
                templateLabel: label,
                templateIcon: icon,
                templateTheme: theme,
                templateAttributes: attributes,
            },
            layout: layout,
        });

        if (_resp.status === 201) {
            const dbId = _resp.data.template.template.uid;
            dispatch({
                type: 'LATER',
                payload: {
                    databases: [
                        {
                            aliasuid: state.alias.uid,
                            attributes: attributes,
                            createdAt: new Date(),
                            icon: icon,
                            label: label,
                            layout: 'grid',
                            theme: theme,
                            uid: dbId,
                            index: (state.databases || []).length + 1,
                        },
                        ...state.databases,
                    ],
                },
            });
        }

        return _resp;

        // if (_resp.status === 201) {
        //     await reload({
        //         including_branding: false
        //     });
        // }
    };

    const deleteDatabase = async ({ uid = null }) => {
        const _resp = await Database.deleteDatabase({
            aliasuid: state.alias.uid,
            databaseuid: uid,
        });

        dispatch({
            type: 'LATER',
            payload: {
                databases: state.databases
                    .filter((db) => db.uid !== uid)
                    .sort((db1, db2) => db1.index - db2.index)
                    .map((db, index) => ({
                        ...db,
                        index: index+1,
                        orderNumber: index+1,
                    })),
            },
        });

        return _resp;
    };

    const updateDatabase = async ({
        uid = null,
        label = undefined,
        icon = undefined,
        theme = undefined,
        layout = undefined,
        sortNumber = undefined,
        attributes = undefined,
        apiEnabled = undefined,
        allowedOrigins = undefined,
        index = undefined,
    }) => {
        const _resp = await Database.updateDatabase({
            aliasuid: state.alias.uid,
            databaseuid: uid,
            dbdata: {
                templateLabel: label,
                templateLayout: layout,
                templateAttributes: attributes,
                templateTheme: theme,
                templateIcon: icon,
                apiEnabled: apiEnabled,
                allowedOrigins: allowedOrigins,
                index: index,
            },
        });
        let indexUpdate = { from: -1, to: -1, direction: 0, min: -1, max: -1 };
        if (index !== undefined) {
            indexUpdate.to = index;
            for (let i = 0; i < state.databases.length; i++) {
                if (state.databases[i].uid === uid) {
                    indexUpdate.from = state.databases[i].index;
                }
            }
            indexUpdate.direction = indexUpdate.from > indexUpdate.to ? +1 : -1;
            indexUpdate.min = Math.min(indexUpdate.from, indexUpdate.to);
            indexUpdate.max = Math.max(indexUpdate.from, indexUpdate.to);
        }

        dispatch({
            type: 'LATER',
            payload: {
                databases: state.databases.map((database) => {
                    if (database.uid === uid) {
                        return {
                            ...database,
                            icon: icon || database.icon,
                            label: label || database.label,
                            allowedOrigins:
                                allowedOrigins || database.allowedOrigins,
                            apiEnabled: apiEnabled || database.apiEnabled,
                            index: index,
                        };
                    }
                    // if (
                    //     (database.index > indexUpdate.from &&
                    //         database.index <= indexUpdate.to) ||
                    //     (database.index < indexUpdate.from &&
                    //         database.index >= indexUpdate.to)
                    // ) {
                    //     console.log(
                    //         database.index,
                    //         '--->',
                    //         database.index + indexUpdate.direction
                    //     );
                    // }
                    /*console.log(
                        database.index,
                        (database.index > indexUpdate.from &&
                            database.index <= indexUpdate.to) ||
                            (database.index < indexUpdate.from &&
                                database.index >= indexUpdate.to)
                            ? database.index + indexUpdate.direction
                            : database.index + indexUpdate.direction
                    );*/
                    return {
                        ...database,
                        index:
                            (database.index > indexUpdate.from &&
                                database.index <= indexUpdate.to) ||
                            (database.index < indexUpdate.from &&
                                database.index >= indexUpdate.to)
                                ? database.index + indexUpdate.direction
                                : database.index,
                    };
                }),
            },
        });

        return _resp;

        /** refresh in auth */
        // if (_resp.status === 200) {
        //     await reload({
        //         including_branding: false
        //     });
        // }
    };
    const setBasic = ({ payload }) => {
        dispatch({
            type: 'LATER',
            payload: {
                ...payload,
            },
        });
    };

    return (
        <AliasContext.Provider
            value={{
                ...state,
                unlockAlias,
                reload,
                groupDatabases,
                setHiddenGroups,
                createDatabase,
                updateDatabase,
                deleteDatabase,
                setBasic,
            }}>
            {children}
        </AliasContext.Provider>
    );
}

export { AliasContext, AliasProvider };
