import React, {
    createContext,
    useEffect,
    useReducer,
    useRef,
    useState,
} from 'react';

const initialState = {
    initialized: false,

    recordset: null,

    records: null,

    idx: null,
};

const handlers = {
    INITIALIZE: (state, action) => {
        return {
            ...state,
            ...action.payload,
            initialized: true,
        };
    },

    UNINITIALIZE: (state) => {
        return {
            ...state,
            ...initialState,
        };
    },
};

const reducer = (state, action) =>
    handlers[action.type] ? handlers[action.type](state, action) : state;

const RecordsetCtx = createContext({
    ...initialState,
});

function RecordsetProvider({
    children,
    idx = null,
    recordset = null,
    ...props
}) {
    // useEffect(() => {
    //     console.log('Redneredned provider');
    // }, []);

    const [state, dispatch] = useReducer(reducer, initialState);

    useEffect(() => {
        const initialize = () => {
            dispatch({
                type: 'INITIALIZE',
                payload: {
                    idx: idx,
                    recordset: recordset,
                    records: (recordset && recordset.records) || [],
                },
            });
        };

        if (recordset) {
            initialize();
        }
    }, [recordset, idx]);

    return (
        <RecordsetCtx.Provider
            value={{
                ...state,
            }}>
            {children}
        </RecordsetCtx.Provider>
    );
}

export { RecordsetCtx, RecordsetProvider };
