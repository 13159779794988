import './pricing-card.scss';

export function PricingCard({
    name,
    price,
    oldPrice = undefined,
    currency,
    paymentDuration,
    features,
    actionName,
    extraInfo = '',
    action,
    mostPopular = false,
    theme = 'dark',
}) {
    const currency_symbol = (() => {
        switch (currency.toLowerCase()) {
            case 'euro':
                return '€';
            case 'usd':
                return '$';
            default:
                return '';
        }
    })();
    return (
        <div
            className={`pricing-card ${mostPopular ? 'most-popular' : ''} ${
                theme === 'light' ? '__light' : ''
            }`}>
            <h3 className="pricing-card-title">{name}</h3>
            <div className="pricing-card-price-container">
                <span className="price">
                    {currency_symbol}
                    {price}
                </span>
                {oldPrice !== undefined && (
                    <span
                        className="old-price"
                        data-save={`save ${
                            oldPrice - price
                        }${currency_symbol}`}>
                        {currency_symbol}
                        {oldPrice}
                    </span>
                )}
            </div>
            <div className="pricing-card-payment-duration">
                {paymentDuration}
            </div>
            <div>
                {(features || []).map((feature, i) => {
                    return (
                        <div className="feature" key={'feature-0' + i}>
                            <span className="check-icon">
                                <svg
                                    width="24"
                                    viewBox="0 0 24 26"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        className="icon-fill"
                                        d="M10.6 17.6708L17.65 10.166L16.25 8.67568L10.6 14.6902L7.75 11.6563L6.35 13.1466L10.6 17.6708ZM12 23.4192C10.6167 23.4192 9.31667 23.1396 8.1 22.5804C6.88333 22.0219 5.825 21.2636 4.925 20.3055C4.025 19.3475 3.31267 18.2208 2.788 16.9257C2.26267 15.6305 2 14.2466 2 12.7741C2 11.3015 2.26267 9.91762 2.788 8.62246C3.31267 7.32729 4.025 6.20068 4.925 5.24262C5.825 4.28455 6.88333 3.52591 8.1 2.96668C9.31667 2.40816 10.6167 2.12891 12 2.12891C13.3833 2.12891 14.6833 2.40816 15.9 2.96668C17.1167 3.52591 18.175 4.28455 19.075 5.24262C19.975 6.20068 20.6873 7.32729 21.212 8.62246C21.7373 9.91762 22 11.3015 22 12.7741C22 14.2466 21.7373 15.6305 21.212 16.9257C20.6873 18.2208 19.975 19.3475 19.075 20.3055C18.175 21.2636 17.1167 22.0219 15.9 22.5804C14.6833 23.1396 13.3833 23.4192 12 23.4192Z"
                                        fill="white"
                                    />
                                </svg>
                            </span>
                            <span className="feature-title">
                                {feature.name}
                            </span>
                        </div>
                    );
                })}
            </div>
            <button
                className={`default-button ${
                    theme === 'dark'
                        ? 'default-button-secondary'
                        : 'default-button-primary'
                } default-button-full pricing-card-action`}
                onClick={() => {
                    action();
                }}>
                {actionName}
            </button>
            {extraInfo && (
                <div className="pricing-card-extra-info">{extraInfo}</div>
            )}
        </div>
    );
}
