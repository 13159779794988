import { useEffect, useRef, useState } from 'react';
import Button from '../../components/Button';
import { Symbol } from '../../components/elements/Icon';
import Input from '../../components/Input';
import { Text } from '../../components/Text';
import useBarelayout from '../../layouts/barelayout/hook';
import { FieldTypeMap } from '../../layouts/fullview/main';
import { getAPIBase } from '../../env';
import useDatabase from '../../hooks/useDatabase';
import { callApi } from '../../functions/api';
import { SelectMenu } from '../../layouts/fullview/cell';
import { TagsEditor } from '../../components/tags-editor/TagsEditor';
import { QuillEditor } from '../../components/quill-editor/QuillEditor';
import useSetting from '../../hooks/useSetting';
import { Switch } from './accountOptionsPanel/accountOptionsPanel';
import AI from '../../functions/AI';
import { showError } from '../../components/towsts/Toasts';
import { formatDate } from '../../functions/Utilities'


export const Field = ({
    field = null,
    idx = null,
    cell = null,
    record = null,
    fieldset = null,
    recordset = null,
    is_calendar = null,
    index,
    showTitle = true,
    label = undefined,
    placeholder = 'Value',
    validate = (v) => {
        return true;
    },
    accept = '.png, .jpg, .jpeg, .gif, .webp, .svg',
    singelLine = false,
    maxWidth = undefined,
    noFill = false,
    tags_before = <></>,
    className = '',
    onKeyDown = (evnt) => {},
    data_lable = undefined,
}) => {
    const ref = useRef(null);
    const cellValueRef = useRef(null);
    const inputRef = useRef(null);
    const imageInputLabelRef = useRef(null);

    const brCtx = useBarelayout();
    const dbCtx = useDatabase();
    const settingCtx = useSetting();

    const [options, setOptions] = useState([]);
    const [uploadingImage, setUploadingImage] = useState(false);
    const [imageUploadProgress, setImageUploadProgress] = useState(0);
    const [isSelectMenuOpen, setIsSelectMenuOpen] = useState(false);

    useEffect(() => {
        cellValueRef.current = cell ? cell.value : null;
    }, [cell]);

    useEffect(() => {
        if (field.type === 'select') {
            getSelectOptions();
        }
    }, [field.type, brCtx.select_fields]);

    useEffect(() => {
        if (!brCtx.active_cell) {
            return;
        }
        if (field.type === 'string' && inputRef.current) {
            inputRef.current.innerText = getValue();
        }
        if (
            brCtx.active_cell.fieldidx === index ||
            (index === 0 && brCtx.active_cell.fieldidx === null)
        ) {
            try {
                switch (field.type) {
                    case 'string':
                        inputRef.current.focus();
                        break;
                    case 'image':
                        imageInputLabelRef.current.click();
                        break;
                    default:
                        break;
                }
            } catch (e) {}
        }
    }, [field, brCtx.active_cell, record]);

    useEffect(() => {
        if (
            field.type === 'select' &&
            field.attributes &&
            field.attributes.referType === 'recordsets_categorizer'
        ) {
            getSelectOptions();
        }
    }, [brCtx.recordsets]);

    const uploadImage = (file) => {
        if (!validate(file)) {
            return;
        }
        let formdata = new FormData();
        formdata.append('file', file);
        setUploadingImage(true);

        callApi({
            method: 'post',
            headers: { 'Content-Type': 'multipart/form-data' },
            payload: formdata,
            path: `${getAPIBase()}/template/cellAuto/${
                dbCtx.database.aliasuid
            }/${dbCtx.database.uid}/${recordset.id}/${record.id}/${
                fieldset.id
            }/${field.id}`,
            onUploadProgress: (p) => {
                const { loaded, total } = p;
                let percent = Math.floor((loaded * 100) / total);
                setImageUploadProgress(percent);
            },
        })
            .then((res) => {
                if (res.status !== 200) {
                    showError('Error uploading image, please try again');
                    return;
                }
                dbCtx.getRecordsets();
            })
            .catch((err) => {
                showError('Error uploading image, please try again');
            })
            .finally(() => {
                setUploadingImage(false);
            });
    };

    const handleOnBlur = (e) => {
        let oldValue = getValue() || '';
        let newValue = e.target.value || '';
        if (newValue !== oldValue) {
            brCtx.handleCellUpdate({
                e: e,
                field: field,
                cell: cell,
                record: record,
                fieldset: fieldset,
                recordset: recordset,
                is_calendar: is_calendar,
                new_value: newValue,
            });
        }
    };

    const handleOnSelect = (e) => {
        if (
            field.attributes &&
            field.attributes.referType === 'recordsets_categorizer'
        ) {
            /** update record's recordset */

            brCtx.updateRecord({
                uid: record.id,
                recordsetuid: recordset.id,
                data: {
                    newrecordsetuid: e.option.value,
                },
            });

            return;
        }

        brCtx.handleCellUpdate({
            e: e,
            field: field,
            cell: cell,
            record: record,
            fieldset: fieldset,
            recordset: recordset,
            is_calendar: is_calendar,
            new_value: e.option.value,
        });
    };

    const FetchField = async () => {
        return await brCtx.fetchField({
            uid: field.id,
            fieldsetuid: fieldset.id,
        });
    };

    const getSelectOptions = (force = false) => {
        if (!field) {
            return [];
        }

        if (
            field.attributes &&
            field.attributes.referType === 'recordsets_categorizer'
        ) {
            var opts = [];

            brCtx.recordsets.map((r) => {
                opts.push({
                    id: r.id,
                    value: r.id,
                    label: r.label || '<empty>',
                    attributes:
                        (r.attributes && r.attributes.cellstyle) || null,
                });
            });
            opts.unshift({
                id: null,
                value: null,
                label: null,
                attributes: null,
            });
            setOptions(opts);
            return;

            // return [];
        }

        if (brCtx.select_fields && brCtx.select_fields[field.id] && !force) {
            let optionsList = (
                brCtx.select_fields[field.id].options || []
            ).slice(0);

            /*optionsList.unshift({
                id: null,
                parentId: brCtx.select_fields[field.id].id,
                value: null,
                label: null,
                attributes: null,
            });*/
            setOptions(optionsList);
            return;
        } else {
            /** fetch */
            FetchField().then((res) => {
                if (res.status === 200) {
                    var optionlist = [];

                    var optionsets = res.data.field.others.options;

                    optionsets &&
                        Array.isArray(optionsets) &&
                        optionsets.map((optset, x) => {
                            optset &&
                                optset.records &&
                                Array.isArray(optset.records) &&
                                optset.records.map((rec, y) => {
                                    optionlist.push({
                                        id: rec.uid,
                                        parentId: optset.uid,
                                        attributes: rec.attributes,
                                        value: rec.uid,
                                        label:
                                            (rec.cell && rec.cell.value) ||
                                            '<empty>',
                                    });
                                });
                        });
                    setOptions(optionlist);

                    var newoptions = {};

                    newoptions[field.id] = {
                        options: optionlist,
                    };

                    brCtx.setBasic({
                        select_fields: {
                            ...brCtx.select_fields,
                            ...newoptions,
                        },
                    });
                }
            });
        }
    };

    const getValueCore = () => {
        return (cell && cell.value) ?? null;
    };

    const getValue = () => {

        var v = getValueCore();

        var finalVa = v;

        if (field.type === 'select') {
            if (
                field.attributes &&
                field.attributes.referType === 'recordsets_categorizer'
            ) {
                finalVa = recordset.id;
            }
        }
        else if(field.type === "date") {
            let a = formatDate(new Date(finalVa), "YYYY-MM-DD");
            console.log("X :: ", a)
            return a;
        }

        return finalVa;
    };

    const getSelectLabel = () => {
        var v = getValueCore();

        var finalValue = v;
        if (field.type === 'select') {
            finalValue = '';
            options &&
                options.map((opt) => {
                    if (opt.value === v) {
                        finalValue = opt.label;
                        return;
                    }
                });
            if (finalValue === null) {
                finalValue = '';
            }
        }
        return finalValue;
    };

    const handleAddRecordsetKeyUp = (e) => {
        if (e.keyCode === 13) {
            brCtx.addRecordset({
                label: e.target.value,
            });
            e.target.value = '';
        }
    };

    const handleAddOptionKeyUp = (e) => {
        if (e.keyCode === 13) {
            // brCtx.addRecordset({
            //     label: e.target.value,
            // });

            brCtx
                .createOptionForField(fieldset.id, field.id, {
                    label: e.target.value,
                    attributes: null,
                })
                .then(() => {
                    getSelectOptions(true);
                });

            e.target.value = '';
        }
    };

    if (!brCtx.active_cell) {
        return <></>;
    }

    if (field.type === 'select') {
        var extraItems = () => {
            return null;
        };

        if (
            field.attributes &&
            field.attributes.referType === 'recordsets_categorizer'
        ) {
            extraItems = () => {
                return (
                    <>
                        <div
                            className="w-100p"
                            style={{
                                padding: '4px 8px',
                                borderTop: '1px solid rgba(0, 0, 0, 0.08)',
                            }}>
                            <Input
                                onKeyUp={handleAddRecordsetKeyUp}
                                boxClassName={'no-default-style w-100p'}
                                className={'no-default-style w-100p'}
                                autoFocus={true}
                                style={{ color: '#777777' }}
                                placeholder={'Kategorie'}
                            />

                            <div className={'ml-12'}>
                                <Text
                                    style={{ fontSize: 11, opacity: 0.8 }}
                                    value={
                                        <>
                                            <span>
                                                Neue Kategorie hinzufügen,{' '}
                                            </span>
                                            <span style={{ color: '#4470c3' }}>
                                                ENTER
                                            </span>{' '}
                                            <span>drücken zum sichern.</span>
                                        </>
                                    }
                                />
                            </div>
                        </div>
                    </>
                );
            };
        } else {
            extraItems = () => {
                return (
                    <>
                        <div
                            className="w-100p"
                            style={{
                                padding: '4px 8px',
                                borderTop: '1px solid rgba(0, 0, 0, 0.08)',
                            }}>
                            <Input
                                onKeyUp={handleAddOptionKeyUp}
                                boxClassName={'no-default-style w-100p'}
                                className={'no-default-style w-100p'}
                                autoFocus={true}
                                style={{ color: '#777777' }}
                                placeholder={'Option'}
                            />

                            <div className={'ml-12'}>
                                <Text
                                    style={{ fontSize: 11, opacity: 0.8 }}
                                    value={
                                        <>
                                            <span>
                                                Neue option hinzufügen,{' '}
                                            </span>
                                            <span style={{ color: '#4470c3' }}>
                                                ENTER
                                            </span>{' '}
                                            <span>drücken zum sichern.</span>
                                        </>
                                    }
                                />
                            </div>
                        </div>
                    </>
                );
            };
        }

        return (
            <>
                <div
                    style={maxWidth !== undefined ? { maxWidth: maxWidth } : {}}
                    className={`--field ${!showTitle ? '--noTitle' : ''} ${
                        noFill ? '--noFill' : ''
                    } ${className}`}>
                    {showTitle && (
                        <Text value={label || (field && field.label) || null} />
                    )}

                    {/*<SelectBox
                        ref={ref}
                        onChange={handleOnSelect}
                        options={options}
                        defaultValue={getValue()}
                        triggerStyle={{
                            height: 34,
                        }}
                        contentStyle={{
                            width: 225,
                        }}
                        extraItems={extraItems()}
                        placeholder={'Choice'}
                    />*/}
                    <SelectMenu
                        cell={cell}
                        field={field}
                        fieldset={fieldset}
                        record={record}
                        recordset={recordset}
                        isOpen={isSelectMenuOpen}
                        setIsOpen={setIsSelectMenuOpen}
                        options={options}
                        setOptions={setOptions}
                        trigger={
                            <Button
                                className={'--select-box-custom'}
                                onClick={() => {
                                    setIsSelectMenuOpen((v) => !v);
                                }}
                                data-lable={data_lable}>
                                <Text
                                    value={
                                        getSelectLabel() ||
                                        placeholder ||
                                        'Choice'
                                    }
                                />
                                <Symbol
                                    className={'ml-8 --select-box-arrow'}
                                    name={'expand_icon'}
                                />
                            </Button>
                        }
                    />
                </div>
            </>
        );
    }

    const handleCheckboxToggle = (newValue) => {
        var new_val = '' + newValue === 'true' ? 'true' : 'false';

        brCtx.handleCellUpdate({
            e: {},
            field: field,
            cell: cell,
            record: record,
            fieldset: fieldset,
            recordset: recordset,
            is_calendar: is_calendar,
            new_value: new_val,
        });
    };

    if (field.type === 'checkbox') {
        // console.log(cell);
        // console.log(field);

        return (
            <>
                <div
                    style={maxWidth !== undefined ? { maxWidth: maxWidth } : {}}
                    className={`--field ${!showTitle ? '--noTitle' : ''} ${
                        noFill ? '--noFill' : ''
                    } ${className}`}>
                    {showTitle && (
                        <Text value={label || (field && field.label) || null} />
                    )}

                    <span className={'--checkbox-container'}>
                        {/* <Button
                            onClick={handleCheckboxToggle}
                            className={`--checkbox-default ${
                                '' + getValue() === 'true' ? 'on' : 'off'
                            }
                            }`}
                            autoFocus={brCtx.active_cell.fieldidx === index}
                            type={'icon'}></Button> */}
                        <Switch
                            id={field.id}
                            onUpdate={handleCheckboxToggle}
                            value={'' + getValue() === 'true' ? true : false}
                        />
                    </span>
                </div>
            </>
        );
    }

    if (field.type === 'rich_text') {
        return (
            <>
                {/*<button
                    style={{ padding: '10px', margin: '10px' }}
                    onClick={() => {
                        let pageContent = `
                            <div style="width:calc(100% - 20px);max-width:600px;margin:10px auto;">
                                ${editorjsToHtml(
                                    JSON.parse(getValue() || '{}')
                                )}
                                <style>ul{list-style: disc;padding:4px 20px;}<style>
                            </div>
                        `;
                        if (!preview_window || preview_window.closed) {
                            preview_window = window.open('', '_blank');
                            preview_window.onload = () => {
                                preview_window.document.body.innerHTML =
                                    pageContent;
                            };
                        }
                        preview_window.document.body.innerHTML = pageContent;
                        preview_window.focus();
                    }}>
                    Preview
                </button>*/}
                <QuillEditor
                    placeholder={field.label}
                    data={getValue() || ''}
                    key={record.id}
                    autofocus={brCtx.active_cell.fieldidx === index}
                    projectId={dbCtx.database.uid}
                    projectLimits={dbCtx.limits || {}}
                    onChangeHandler={(newValue) => {
                        handleOnBlur({
                            target: {
                                value: newValue,
                            },
                        });
                    }}
                    handleLimitsRiched={(msg) => {
                        settingCtx.setUpgradeProjectDialog({
                            visible: true,
                            project_id: dbCtx.database.uid,
                            msg: msg,
                        });
                    }}></QuillEditor>
            </>
        );
    }

    if (field.type === 'string') {
        return (
            <>
                <div
                    style={maxWidth !== undefined ? { maxWidth: maxWidth } : {}}
                    className={`--field ${!showTitle ? '--noTitle' : ''} ${
                        noFill ? '--noFill' : ''
                    } ${className}`}>
                    {showTitle && (
                        <Text value={label || (field && field.label) || null} />
                    )}

                    <Input
                        ref={inputRef}
                        onBlur={(e) => {
                            handleOnBlur({
                                target: {
                                    value: singelLine
                                        ? e.target.value
                                        : e.target.innerText,
                                },
                            });
                        }}
                        type={singelLine ? 'text' : 'span'}
                        onKeyDown={(evnt) => {
                            onKeyDown(evnt);
                        }}
                        key={`${field && field.id}-${record && record.id}`}
                        placeholder={placeholder || 'Value'}
                        value={getValue()}
                        readOnly={field.automatic_value}
                        disabled={field.automatic_value}
                        defaultValue={getValue()}
                        autoFocus={
                            brCtx.active_cell.cellidx === index ||
                            brCtx.active_cell.fieldidx === index ||
                            (index === 0 && brCtx.active_cell.fieldidx === null)
                        }
                    />
                </div>
            </>
        );
    }

    if (field.type === 'list') {
        return (
            <>
                <div className="--field faq-tags-field">
                    <TagsEditor
                        placeholder={placeholder || field.label}
                        tags={getValue() || []}
                        handleAddTags={({ newTagsList = [] }) => {
                            if (newTagsList.length === 0) {
                                handleOnBlur({
                                    target: {
                                        value: '',
                                    },
                                });
                                return;
                            }
                            handleOnBlur({
                                target: {
                                    value: newTagsList.join(','),
                                },
                            });
                        }}
                        handleAddTagsWithAI={async () => {
                            return new Promise(async (resolve, reject) => {
                                let res = await AI.generateTags({
                                    aliasId: dbCtx.database.aliasuid,
                                    projectId: dbCtx.database.uid,
                                    questionId: record.id,
                                });
                                if (res.data && res.data.tags) {
                                    const targetRecordId = record.id;
                                    const targetFieldId = (() => {
                                        for (
                                            let j = 0;
                                            j < dbCtx.fieldsets.length;
                                            j++
                                        ) {
                                            let fields =
                                                dbCtx.fieldsets[j].fields;
                                            for (
                                                let i = 0;
                                                i < fields.length;
                                                i++
                                            ) {
                                                if (
                                                    fields[i].label === 'Tags'
                                                ) {
                                                    return fields[i].uid;
                                                }
                                            }
                                        }
                                        return '';
                                    })();
                                    let newRecordsets = dbCtx.recordsets.map(
                                        (recordset, i) => {
                                            if (i !== 0) {
                                                return { ...recordset };
                                            }
                                            return {
                                                ...recordset,
                                                records: recordset.records.map(
                                                    (record) => {
                                                        if (
                                                            record.uid !==
                                                            targetRecordId
                                                        ) {
                                                            return {
                                                                ...record,
                                                            };
                                                        }
                                                        return {
                                                            ...record,
                                                            cells: record.cells.map(
                                                                (cell) => {
                                                                    if (
                                                                        cell.fielduid !==
                                                                        targetFieldId
                                                                    ) {
                                                                        return {
                                                                            ...cell,
                                                                        };
                                                                    }
                                                                    return {
                                                                        ...cell,
                                                                        value: [
                                                                            ...res
                                                                                .data
                                                                                .tags,
                                                                        ],
                                                                    };
                                                                }
                                                            ),
                                                        };
                                                    }
                                                ),
                                            };
                                        }
                                    );
                                    dbCtx.setBasic({
                                        recordsets: [...newRecordsets],
                                    });
                                }
                                resolve("done");
                            });
                        }}
                        handleRemoveTags={() => {}}
                        before={tags_before}
                    />
                </div>
            </>
        );
    }

    if (field.type === 'image') {
        return (
            <>
                <div
                    style={maxWidth !== undefined ? { maxWidth: maxWidth } : {}}
                    className={`--field ${!showTitle ? '--noTitle' : ''} ${
                        noFill ? '--noFill' : ''
                    } ${className}`}>
                    {showTitle && (
                        <Text value={label || (field && field.label) || null} />
                    )}
                    <div className={'--image-input'}>
                        {uploadingImage ? (
                            <>
                                <span className={'placeholder'}>
                                    <span className={'loader'}></span>
                                    <span style={{ color: '#707070' }}>
                                        {imageUploadProgress}
                                        {'% Uploaded'}
                                    </span>
                                </span>
                            </>
                        ) : (
                            <>
                                {getValue() ? (
                                    <>
                                        <div
                                            className={
                                                'edit-image-actions-container'
                                            }>
                                            <div
                                                className={
                                                    'edit-image-actions'
                                                }>
                                                <Button
                                                    className={'button-icon'}
                                                    onClick={() => {
                                                        imageInputLabelRef.current.click();
                                                    }}>
                                                    <Symbol name={'mode'} />
                                                </Button>
                                                <Button
                                                    className={'button-icon'}
                                                    onClick={(e) => {
                                                        brCtx.handleCellUpdate({
                                                            e: e,
                                                            field: field,
                                                            cell: cell,
                                                            record: record,
                                                            fieldset: fieldset,
                                                            recordset:
                                                                recordset,
                                                            is_calendar:
                                                                is_calendar,
                                                            new_value:
                                                                '$cmd://remove',
                                                        });
                                                    }}>
                                                    <Symbol />
                                                </Button>
                                            </div>
                                        </div>
                                        <div
                                            className="uploaded-image-preview"
                                            style={{
                                                backgroundImage: `url(${getAPIBase()}/media/${getValue()})`,
                                            }}></div>
                                    </>
                                ) : (
                                    <>
                                        <span className={'placeholder'}>
                                            <Symbol
                                                name={'image'}
                                                color={'#707070'}
                                            />
                                            <span style={{ color: '#707070' }}>
                                                {'Select an Image'}
                                            </span>
                                        </span>
                                    </>
                                )}
                                <label
                                    ref={imageInputLabelRef}
                                    htmlFor={`input-${field.id}`}></label>
                                <input
                                    /*style={{
                                        display: `${getValue() ? 'none' : ''}`,
                                    }}*/
                                    id={`input-${field.id}`}
                                    type="file"
                                    accept={accept}
                                    title="select an image"
                                    onChange={(e) => {
                                        if (e.target.files[0]) {
                                            uploadImage(e.target.files[0]);
                                        }
                                    }}
                                />
                            </>
                        )}
                    </div>
                </div>
            </>
        );
    }

    return (
        <>
            <div
                style={maxWidth !== undefined ? { maxWidth: maxWidth } : {}}
                className={`--field ${!showTitle ? '--noTitle' : ''} ${
                    noFill ? '--noFill' : ''
                } ${className}`}>
                {showTitle && (
                    <Text value={label || (field && field.label) || null} />
                )}
                {/*<input defaultValue={getValue()} type={"date"} />*/}
                <Input
                    onBlur={handleOnBlur}
                    type={FieldTypeMap[(field && field.type) || null]}
                    key={`${field.id}-${record.id}`}
                    placeholder={placeholder || 'Value'}
                    readOnly={field.automatic_value}
                    disabled={field.automatic_value}
                    value={getValue()}
                    autoFocus={
                        brCtx.active_cell.fieldidx === index ||
                        (index === 0 && brCtx.active_cell.fieldidx === null)
                    }
                />
            </div>
        </>
    );
};

export const Fieldset = ({
    fieldset = null,
    idx = null,
    cellset = null,
    is_calendar = null,
    recordset = null,
    record = null,
}) => {
    return (
        <>
            {fieldset && fieldset.fields && (
                <>
                    {fieldset.fields
                        // .filter((field) => field.label === 'Tags')
                        .map((field) => {
                            return (
                                <Field
                                    key={field.id}
                                    field={field}
                                    cell={
                                        (cellset && cellset.cells[field.id]) ||
                                        null
                                    }
                                    record={record}
                                    fieldset={fieldset}
                                    recordset={recordset}
                                    is_calendar={is_calendar}
                                    index={3}
                                    // showTitle={false}
                                    showTitle={true}
                                    // placeholder={'Tag'}
                                    // data_lable={'Add tag'}
                                    // tags_before={
                                    //     <>
                                    //         {fieldset.fields
                                    //             .filter(
                                    //                 (field) =>
                                    //                     field.label === 'TOPIC'
                                    //             )
                                    //             .map((field) => {
                                    //                 return (
                                    //                     <Field
                                    //                         key={field.id}
                                    //                         field={field}
                                    //                         cell={
                                    //                             (cellset &&
                                    //                                 cellset
                                    //                                     .cells[
                                    //                                     field.id
                                    //                                 ]) ||
                                    //                             null
                                    //                         }
                                    //                         record={record}
                                    //                         fieldset={fieldset}
                                    //                         recordset={
                                    //                             recordset
                                    //                         }
                                    //                         is_calendar={
                                    //                             is_calendar
                                    //                         }
                                    //                         index={1}
                                    //                         showTitle={false}
                                    //                         maxWidth={'180px'}
                                    //                         placeholder={
                                    //                             'Select ' +
                                    //                             field.label.toLowerCase()
                                    //                         }
                                    //                         data_lable={
                                    //                             'Choice category'
                                    //                         }
                                    //                     />
                                    //                 );
                                    //             })}
                                    //     </>
                                    // }
                                />
                            );
                        })}
                    {fieldset.fields
                        .filter((field) => field.label === 'FAQ')
                        .map((field) => {
                            return (
                                <Field
                                    key={field.id}
                                    field={field}
                                    cell={
                                        (cellset && cellset.cells[field.id]) ||
                                        null
                                    }
                                    record={record}
                                    fieldset={fieldset}
                                    recordset={recordset}
                                    is_calendar={is_calendar}
                                    index={-999}
                                    showTitle={false}
                                    noFill={true}
                                    placeholder={'Question'}
                                    className={'--faq-title'}
                                    singelLine={false}
                                    onKeyDown={(evnt) => {
                                        if (
                                            evnt.key === 'Enter' ||
                                            evnt.keyCode === 13
                                        ) {
                                            evnt.preventDefault();

                                            let quillEditors =
                                                document.getElementsByClassName(
                                                    'ql-editor'
                                                );
                                            for (
                                                let i = 0;
                                                i < quillEditors.length;
                                                i++
                                            ) {
                                                if (
                                                    quillEditors[
                                                        i
                                                    ].getAttribute(
                                                        'data-placeholder'
                                                    ) === 'Article'
                                                ) {
                                                    quillEditors[i].focus();
                                                }
                                            }
                                        }
                                    }}
                                />
                            );
                        })}
                    {fieldset.fields
                        .filter((field) => field.label === 'Article')
                        .map((field) => {
                            return (
                                <div style={{ margin: '-8px 6px 0' }}>
                                    <Field
                                        key={field.id}
                                        field={field}
                                        cell={
                                            (cellset &&
                                                cellset.cells[field.id]) ||
                                            null
                                        }
                                        record={record}
                                        fieldset={fieldset}
                                        recordset={recordset}
                                        is_calendar={is_calendar}
                                        index={2}
                                        showTitle={false}
                                        placeholder={field.label}
                                    />
                                </div>
                            );
                        })}
                </>
            )}
        </>
    );
};

export default function RecordviewTab() {
    const brCtx = useBarelayout();

    const [active_cell, setActiveCell] = useState(null);

    useEffect(() => {
        setActiveCell(brCtx.active_cell);
    }, [brCtx.active_cell]);

    if (!active_cell) {
        return (
            <div style={{ padding: 16 }}>
                <Text
                    style={{ fontSize: 18, fontWeight: 600 }}
                    value={'None of records are selected.'}
                />
            </div>
        );
    }

    /** get active record */
    // let active_recordset = brCtx.active_cell && brCtx.active_cell.recordsetidx || null;
    // let active_record = brCtx.active_cell && brCtx.active_cell.recordidx || null;
    let recordset =
        (brCtx.recordsets && brCtx.recordsets[active_cell.recordsetidx]) ||
        null;
    let record =
        ((recordset &&
            recordset.records &&
            recordset.records.filter(
                (record) => record.id === active_cell.recordid
            )) ||
            [])[0] || null;

    return (
        <>
            <div className="--section --align-start --edit-record-container">
                {brCtx.fieldsets && record && (
                    <Fieldset
                        key={brCtx.fieldsets[1].id}
                        fieldset={brCtx.fieldsets[1]}
                        record={record}
                        is_calendar={false}
                        recordset={recordset}
                        cellset={
                            (record &&
                                record.cellsets[brCtx.fieldsets[1].id]) ||
                            null
                        }
                        idx={1}
                    />
                )}
            </div>
        </>
    );
}
