export const CheckedIcon = ({ className = null, color = undefined }) => {
    return (
        <svg
            className={'ui-sym path' + (className ? ' ' + className : '')}
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M3.13194 13.1572C2.78736 13.1572 2.49237 13.0345 2.24698 12.7891C2.0016 12.5437 1.87891 12.2487 1.87891 11.9041V3.13292C1.87891 2.78833 2.0016 2.49335 2.24698 2.24796C2.49237 2.00258 2.78736 1.87988 3.13194 1.87988H11.9032C11.9867 1.87988 12.065 1.88771 12.1381 1.90338C12.2112 1.91904 12.2843 1.94253 12.3574 1.97386L11.1983 3.13292H3.13194V11.9041H11.9032V7.73781L13.1562 6.48478V11.9041C13.1562 12.2487 13.0335 12.5437 12.7881 12.7891C12.5427 13.0345 12.2478 13.1572 11.9032 13.1572H3.13194ZM7.21996 10.6511L3.68014 7.1113L4.55726 6.23417L7.21996 8.89687L12.9682 3.14858L13.861 4.01004L7.21996 10.6511Z"
                style={{ fill: color }}
                className="app-icon-fill"
            />
        </svg>
    );
};

export const CheckIcon = ({ className = null, color = undefined }) => {
    return (
        <svg
            className={'ui-sym path' + (className ? ' ' + className : '')}
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M6.76406 12.7501L2.72656 8.71263L3.73594 7.70326L6.76406 10.7314L13.263 4.23242L14.2724 5.2418L6.76406 12.7501Z"
                style={{ fill: color }}
                className="app-icon-fill"
            />
        </svg>
    );
};

export const CheckboxOffIcon = ({ className = null, color = undefined }) => {
    return (
        <svg
            className={'ui-sym path' + (className ? ' ' + className : '')}
            width="15"
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M1.85417 14.1875C1.43021 14.1875 1.06727 14.0365 0.765365 13.7346C0.463455 13.4327 0.3125 13.0698 0.3125 12.6458V1.85417C0.3125 1.43021 0.463455 1.06727 0.765365 0.765365C1.06727 0.463455 1.43021 0.3125 1.85417 0.3125H12.6458C13.0698 0.3125 13.4327 0.463455 13.7346 0.765365C14.0365 1.06727 14.1875 1.43021 14.1875 1.85417V12.6458C14.1875 13.0698 14.0365 13.4327 13.7346 13.7346C13.4327 14.0365 13.0698 14.1875 12.6458 14.1875H1.85417ZM1.85417 12.6458H12.6458V1.85417H1.85417V12.6458Z"
                style={{ fill: color }}
                className="app-icon-fill"
            />
        </svg>
    );
};

export const CheckboxOnIcon = ({ className = null, color = undefined }) => {
    return (
        <svg
            className={'ui-sym path' + (className ? ' ' + className : '')}
            width="15"
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M6.17083 10.4875L11.6052 5.05312L10.526 3.97396L6.17083 8.32917L3.97396 6.13229L2.89479 7.21146L6.17083 10.4875ZM1.85417 14.1875C1.43021 14.1875 1.06727 14.0365 0.765365 13.7346C0.463455 13.4327 0.3125 13.0698 0.3125 12.6458V1.85417C0.3125 1.43021 0.463455 1.06727 0.765365 0.765365C1.06727 0.463455 1.43021 0.3125 1.85417 0.3125H12.6458C13.0698 0.3125 13.4327 0.463455 13.7346 0.765365C14.0365 1.06727 14.1875 1.43021 14.1875 1.85417V12.6458C14.1875 13.0698 14.0365 13.4327 13.7346 13.7346C13.4327 14.0365 13.0698 14.1875 12.6458 14.1875H1.85417ZM1.85417 12.6458H12.6458V1.85417H1.85417V12.6458Z"
                style={{ fill: color }}
                className="app-icon-fill"
            />
        </svg>
    );
};
