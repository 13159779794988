import React from 'react';
import { Text } from '../../components/Text';

export default function HistoryviewTab() {

    return (
        <>
            <div className="--section --align-start">
                <div style={{ padding: 16 }}>
                    <Text
                        style={{ fontSize: 18, fontWeight: 600 }}
                        value={'Activities'}
                    />
                </div>
            </div>
        </>
    );
}
