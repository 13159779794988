import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';
import useAuth from '../../hooks/useAuth';

export function OverviewLayout({
    scrollable = null,
    background = null,
    children,
}) {
    return (
        <div
            style={{
                background: background,
            }}
            className={
                'dashboard-layout' + (scrollable ? ' scroll-container' : '')
            }>
            {children}
        </div>
    );
}

export default function OverviewPage() {
    const authCtx = useAuth();

    const navigateTo = useNavigate();

    useEffect(() => {
        const redirectToFirstAlias = () => {
            if (
                authCtx.initialized &&
                authCtx.aliases &&
                authCtx.aliases.length > 0
            ) {
                var alias = authCtx.aliases[0];
                navigateTo(alias.alias.alias);
            }
        };

        console.log('[overview] mounting.');
        redirectToFirstAlias();

        return () => {
            console.log('[overview] unmounting.');
        };
    }, [authCtx.initialized, authCtx.aliases]);

    return <></>;
}
