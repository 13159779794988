// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.confirm-popup-content,
.alert-popup-content {
  max-width: 400px;
}`, "",{"version":3,"sources":["webpack://./src/components/PopupsManager/popups-manager.scss"],"names":[],"mappings":"AAAA;;EAEI,gBAAA;AACJ","sourcesContent":[".confirm-popup-content,\n.alert-popup-content {\n    max-width: 400px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
