import { forwardRef } from 'react';
import './container.scss';

const Container = forwardRef(function Container({ className, children }, ref) {
    return (
        <div ref={ref} className={`container ${className}`}>
            {children}
        </div>
    );
});

export default Container;
