// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loading-animation {
  width: 20px;
  height: 20px;
  border: 3px solid #ccc;
  border-left-color: transparent;
  border-right-color: transparent;
  animation: 0.5s 0.8s linear infinite loading-spin-animation;
  border-radius: 50%;
  margin: 30px auto 0;
  opacity: 0;
}

@keyframes loading-spin-animation {
  0% {
    opacity: 1;
    transform: rotate(0deg);
  }
  100% {
    opacity: 1;
    transform: rotate(360deg);
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Loading/loading.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,YAAA;EACA,sBAAA;EACA,8BAAA;EACA,+BAAA;EACA,2DAAA;EACA,kBAAA;EACA,mBAAA;EACA,UAAA;AACJ;;AAEA;EACI;IACI,UAAA;IACA,uBAAA;EACN;EACE;IACI,UAAA;IACA,yBAAA;EACN;AACF","sourcesContent":[".loading-animation{\n    width: 20px;\n    height: 20px;\n    border: 3px solid #ccc;\n    border-left-color: transparent;\n    border-right-color: transparent;\n    animation: .5s .8s linear infinite loading-spin-animation;\n    border-radius: 50%;\n    margin: 30px auto 0;\n    opacity: 0;\n}\n\n@keyframes loading-spin-animation {\n    0%{\n        opacity: 1;\n        transform: rotate(0deg);\n    }\n    100%{\n        opacity: 1;\n        transform: rotate(360deg);\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
