import React, { useEffect, useRef, useState } from 'react';
import { Resizable } from 'react-quick-resizable';
import Button from '../../components/Button';
import { Symbol } from '../../components/elements/Icon';
import { Text } from '../../components/Text';
import useSetting from '../../hooks/useSetting';
import useBarelayout from '../../layouts/barelayout/hook';
import AlertviewTab from './alertview';
import HistoryviewTab from './historyview';
import RecordviewTab, { Field } from './recordview';
import useDatabase from '../../hooks/useDatabase';

/** @todo implement tab feature */

export const RightPanelTabs = {
    Recordview: 'ef34f4',
    Historyview: 'ef34w',
    Alertsview: '45t45',
};

export default function RightPanel({ onResize = () => {} }) {
    const brCtx = useBarelayout();
    const settingCtx = useSetting();

    const rightPanelWrapper = useRef(null);
    const isRightPanelVisible = useRef(settingCtx.right_panel.visible);

    const [visible, setVisible] = useState(null);
    const [tab, setTab] = useState(null);

    const [deleteActive, setDeleteActive] = useState(false);

    useEffect(() => {
        setDeleteActive(false);
    }, [brCtx.active_cell]);

    useEffect(() => {
        setVisible(settingCtx.right_panel.visible);
    }, [settingCtx.right_panel.visible]);

    useEffect(() => {
        setTab(settingCtx.right_panel.active);
    }, [settingCtx.right_panel.active]);

    // useEffect(() => {
    //     if(brCtx.active_cell)
    //     {
    //         settingCtx.setRightPanel({
    //             visible: true,
    //             tab: RightPanelTabs.Recordview
    //         });
    //     }
    //     else
    //     {
    //         settingCtx.setRightPanel({
    //             visible: false
    //         });
    //     }
    // }, [brCtx.active_cell]);

    useEffect(() => {
        isRightPanelVisible.current = settingCtx.right_panel.visible;
    }, [settingCtx.right_panel.visible]);

    useEffect(() => {
        let target;
        let clickTimeout;
        const clickListener = (e) => {
            target = e.target;
            try {
                clearTimeout(clickTimeout);
            } catch (err) {}
            clickTimeout = setTimeout(() => {
                window.removeEventListener('mouseup', listener);
            }, 300);
            window.addEventListener('mouseup', listener);
        };
        const listener = (e) => {
            try {
                clearTimeout(clickTimeout);
                window.removeEventListener('mouseup', listener);
            } catch (e) {}
            if (!isRightPanelVisible.current) {
                return;
            }
            let parent = target;
            while (
                parent !== document.body &&
                parent !== document.body.parentElement
            ) {
                if (
                    !parent ||
                    (parent.classList &&
                        (parent.classList.contains('--right-panel-wrapper') ||
                            parent.classList.contains('--grid-cell') ||
                            parent.classList.contains('popups-container') ||
                            parent.id === 'hero-popup-layer' ||
                            parent.classList.contains('popup-content') ||
                            parent.classList.contains(
                                '--grid-recordset-record'
                            )))
                ) {
                    return;
                }
                parent = parent.parentElement;
            }
            if (isRightPanelVisible.current) hideRightPanel();
        };
        document.addEventListener('mousedown', clickListener);
        return () => {
            document.removeEventListener('mousedown', clickListener);
            window.removeEventListener('mouseup', listener);
        };
    }, []);

    const hideRightPanel = (e) => {
        setDeleteActive(false);
        settingCtx.setRightPanel({
            visible: false,
        });
        brCtx.setBasic({
            active_cell: {},
        });
    };

    if (!brCtx.fieldsets || !brCtx.recordsets || !brCtx.fieldsets[1]) {
        return <></>;
    }

    return (
        <>
            {/*settingCtx.right_panel.visible && <div className={'right-panel-bg'} onClick={hideRightPanel}></div>*/}
            <Resizable
                ref={rightPanelWrapper}
                defaultWidth={400}
                defaultHeight={'100%'}
                height={'100%'}
                directions={{
                    left: true,
                }}
                className={
                    '--right-panel-wrapper' +
                    (visible ? ' --visible' : ' --no-visible')
                }>
                <div className="panel-right">
                    <div
                        className={
                            '--inner scroll-container --faq-editor-panel-container'
                        }>
                        <div className="--section --align-start --fixed-to-top">
                            <div className="--tabs-container">
                                {/*<Button
                                    className={
                                        '--tab' +
                                        (tab === RightPanelTabs.Recordview
                                            ? ' --active'
                                            : '')
                                    }
                                    type={'icon'}
                                    onClick={() =>
                                        handleSwitchTab(
                                            RightPanelTabs.Recordview
                                        )
                                    }>
                                    <Symbol name={'web_asset'} />
                                </Button>

                                <Button
                                    className={
                                        '--tab' +
                                        (tab === RightPanelTabs.Historyview
                                            ? ' --active'
                                            : '')
                                    }
                                    type={'icon'}
                                    onClick={() =>
                                        handleSwitchTab(
                                            RightPanelTabs.Historyview
                                        )
                                    }>
                                    <Symbol name={'history'} />
                                </Button>

                                <Button
                                    className={
                                        '--tab' +
                                        (tab === RightPanelTabs.Alertsview
                                            ? ' --active'
                                            : '')
                                    }
                                    type={'icon'}
                                    onClick={() =>
                                        handleSwitchTab(
                                            RightPanelTabs.Alertsview
                                        )
                                    }>
                                    <Symbol name={'report'} />
                                </Button>*/}
                                {brCtx.active_cell && (
                                    <>
                                        <Button
                                            onClick={() => {
                                                if (!deleteActive) {
                                                    setDeleteActive(true);
                                                    return;
                                                }
                                                let recordsetId =
                                                    brCtx.recordsets[
                                                        brCtx.active_cell
                                                            .recordsetidx
                                                    ].id;
                                                let recordId =
                                                    brCtx.active_cell.recordid;
                                                hideRightPanel();
                                                (async () => {
                                                    let b =
                                                        await brCtx.deleteRecord(
                                                            {
                                                                recordsetuid:
                                                                    recordsetId,
                                                                uid: recordId,
                                                            }
                                                        );
                                                })();
                                            }}>
                                            <Symbol
                                                color={
                                                    deleteActive ? 'red' : ''
                                                }
                                                name="delete_alt1"
                                                className={'mr-8'}
                                            />
                                            <Text
                                                style={{
                                                    color: deleteActive
                                                        ? 'red'
                                                        : '',
                                                }}
                                                value={
                                                    deleteActive
                                                        ? 'Are you sure?'
                                                        : 'Remove'
                                                }
                                            />
                                        </Button>
                                        <TogglePublic />
                                        <EditFaqInPopup />
                                    </>
                                )}
                                <Button
                                    className={'--tab'}
                                    type={'icon'}
                                    style={{
                                        marginLeft: '8px',
                                        marginTop: '-8px',
                                    }}
                                    onClick={hideRightPanel}>
                                    <Symbol name={'close'} />
                                </Button>
                            </div>
                        </div>

                        {/* <div className="--section --align-end"></div> */}

                        {brCtx.active_cell && (
                            <>
                                {tab === RightPanelTabs.Recordview ? (
                                    <RecordviewTab />
                                ) : tab === RightPanelTabs.Historyview ? (
                                    <HistoryviewTab />
                                ) : tab === RightPanelTabs.Alertsview ? (
                                    <AlertviewTab />
                                ) : null}
                            </>
                        )}
                    </div>
                </div>
            </Resizable>
        </>
    );
}

export const TogglePublic = () => {
    const brCtx = useBarelayout();

    const [recordset, setRecordset] = useState(null);
    const [record, setRecord] = useState(null);
    const [cellset, setCellset] = useState(null);

    useEffect(() => {
        if (!brCtx.recordsets) {
            return;
        }
        let recordset = brCtx.recordsets[brCtx.active_cell['recordsetidx']];
        if (!recordset) {
            return;
        }
        let record;
        if (brCtx.recordsets && recordset && recordset.records) {
            record = recordset.records.filter(
                (record) => record.id === brCtx.active_cell['recordid']
            )[0];
        }
        if (!record) {
            return;
        }
        setRecordset(recordset);
        setRecord(record);
        setCellset(record.cellsets[brCtx.fieldsets[1].id] || null);
    }, [brCtx.recordsets, brCtx.fieldsets, brCtx.active_cell]);

    if (!brCtx.fieldsets[1] || !record) {
        return <></>;
    }

    return (
        <span
            style={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'nowrap',
                alignItems: 'center',
            }}>
            {brCtx.fieldsets[1].fields
                .filter((field) => field.label === 'PUBLISH')
                .map((field) => {
                    return (
                        <>
                            <Field
                                key={field.id}
                                field={field}
                                cell={
                                    (cellset && cellset.cells[field.id]) || null
                                }
                                record={record}
                                fieldset={brCtx.fieldsets[1]}
                                recordset={recordset}
                                is_calendar={false}
                                index={-1}
                                showTitle={false}
                                placeholder={''}
                                label={'Online'}
                            />
                            <Text
                                style={{ marginLeft: '-8px' }}
                                value={'Online'}
                            />
                        </>
                    );
                })}
        </span>
    );
};

const EditFaqInPopup = () => {
    const dbCtx = useDatabase();
    const brCtx = useBarelayout();

    const [recordset, setRecordset] = useState(null);
    const [record, setRecord] = useState(null);

    useEffect(() => {
        if (!brCtx.recordsets) {
            return;
        }
        let recordset = brCtx.recordsets[brCtx.active_cell['recordsetidx']];
        if (!recordset) {
            return;
        }
        let record;
        if (brCtx.recordsets && recordset && recordset.records) {
            record = recordset.records.filter(
                (record) => record.id === brCtx.active_cell['recordid']
            )[0];
        }
        setRecordset(recordset);
        setRecord(record);
    }, [brCtx.recordsets, brCtx.fieldsets, brCtx.active_cell]);

    return (
        <Button
            className={'--tab'}
            type={'icon'}
            style={{
                marginLeft: 'auto',
                marginTop: '-8px',
            }}
            onClick={() => {
                window.open(
                    `/edit/${dbCtx.database.aliasuid}/${dbCtx.database.uid}/${recordset.id}/${record.id}`,
                    'popup',
                    'width=' +
                        Math.min(600, Math.floor(window.innerWidth * 0.6)) +
                        ', height=' +
                        Math.floor(window.innerHeight * 0.9) +
                        ', scrollbars=no, toolbar=no,status=no, resizable=yes, menubar=no, location=no, directories=no '
                );
            }}>
            <Symbol name={'openfull'} />
        </Button>
    );
};
