// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  width: calc(100% - 20px);
  max-width: 1400px;
  margin: 0 auto;
}`, "",{"version":3,"sources":["webpack://./src/layouts/mainlayout/container.scss"],"names":[],"mappings":"AAAA;EACI,wBAAA;EACA,iBAAA;EACA,cAAA;AACJ","sourcesContent":[".container {\n    width: calc(100% - 20px);\n    max-width: 1400px;\n    margin: 0 auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
