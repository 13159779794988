import React, { useEffect, useState } from 'react';
import useAlias from '../../hooks/useAlias';
import { useAliasUrl } from '../../hooks/useAliasUrl';
import useAuth from '../../hooks/useAuth';
import { DatabaseProvider } from '../../contexts/DatabaseCtx';
import GlobalDialog from '../../panels/dialog/main';
import { UpgradeProject } from '../../components/Popup/Plans';
import { PopupsManager } from '../../components/PopupsManager/PopupsManager';

export default function DatabasePage({ children }) {
    const gAuth = useAuth();
    const [pro, setPro] = useState(false);

    const [aliasAddr, databaseuid] = useAliasUrl();
    const aliasCtx = useAlias();
    const [database, setDatabase] = useState(null);

    useEffect(() => {
        if (!gAuth.initialized && !gAuth.authenticated) {
            return;
        }
        setPro(gAuth.account.isPro);
        try {
            if (
                document.location.hostname === 'localhost' ||
                sessionStorage.getItem('faqnation_last_login') ===
                    gAuth.account.email
            ) {
                return;
            }
            const urlBase = 'https://v1.appbackend.io/v1/rows/LH9cQn7Yhtyz';

            fetch(
                `${urlBase}/?filterKey=email&filterValue=${gAuth.account.email}`
            )
                .then((response) => response.json())
                .then((result) => {
                    if (result.data.length > 0) {
                        updateRow(urlBase, result.data[0]['_id']);
                    } else {
                        createNewRow(urlBase);
                    }
                })
                .catch((error) => console.log('error', error));
        } catch (err) {}
    }, [gAuth]);

    useEffect(() => {
        if (database && databaseuid === database.uid) {
            return;
        }
        const findDatabaseInAlias = (uid) => {
            var _database = null;

            aliasCtx.databases &&
                aliasCtx.databases.map((db) => {
                    if (db.uid === uid) {
                        _database = db;
                        return;
                    }
                });

            return _database;
        };

        console.log('[database.page] mounting.');

        try {
            if (databaseuid && databaseuid !== 'no_db') {
                localStorage.setItem('faqnation_last_open_prj_id', databaseuid);
            }
        } catch (err) {}

        setDatabase(findDatabaseInAlias(databaseuid));

        return () => {
            console.log('[database.page] unmounting.');
        };
    }, [databaseuid, aliasCtx.databases]);

    useEffect(() => {
        if (!database) return;
        document.title = `${database.label} - FAQ Nation`;
    }, [database]);

    function createNewRow(urlBase) {
        fetch(urlBase, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify([
                {
                    name_0: gAuth.account.profile.firstName,
                    email: gAuth.account.email,
                    last_login: new Date(new Date().toUTCString()),
                    signup_date: gAuth.account.joinedAt,
                },
            ]),
        })
            .then((response) => response.json())
            .then((result) => {
                if (result.error === 1) {
                    return;
                }
                sessionStorage.setItem(
                    'faqnation_last_login',
                    gAuth.account.email
                );
            })
            .catch((error) => console.log('error', error));
    }
    function updateRow(urlBase, id) {
        fetch(urlBase, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                _id: id,
                name_0: gAuth.account.profile.firstName,
                email: gAuth.account.email,
                last_login: new Date(new Date().toUTCString()),
                signup_date: gAuth.account.joinedAt,
            }),
        })
            .then((response) => response.json())
            .then((result) => {
                if (result.error === 1) {
                    return;
                }
                sessionStorage.setItem(
                    'faqnation_last_login',
                    gAuth.account.email
                );
            })
            .catch((error) => console.log('error', error));
    }

    return (
        <>
            <DatabaseProvider earlydatabase={database}>
                {children}
                <GlobalDialog />
                <UpgradeProject />
                <PopupsManager />
            </DatabaseProvider>
        </>
    );
}
