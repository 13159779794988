export function MailIcon({
    className = null,
    color = undefined,
    color2 = undefined,
}) {
    return (
        <svg
            className={'ui-sym path' + (className ? ' ' + className : '')}
            width="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M2.99757 14.9865C2.58547 14.9865 2.23269 14.8397 1.93922 14.5463C1.64576 14.2528 1.49902 13.9 1.49902 13.4879V4.4966C1.49902 4.0845 1.64576 3.73171 1.93922 3.43825C2.23269 3.14478 2.58547 2.99805 2.99757 2.99805H14.986C15.3981 2.99805 15.7509 3.14478 16.0443 3.43825C16.3378 3.73171 16.4845 4.0845 16.4845 4.4966V13.4879C16.4845 13.9 16.3378 14.2528 16.0443 14.5463C15.7509 14.8397 15.3981 14.9865 14.986 14.9865H2.99757ZM8.99178 9.74153L14.986 5.99515V4.4966L8.99178 8.24297L2.99757 4.4966V5.99515L8.99178 9.74153Z"
                style={{ fill: color2 }}
                className="app-icon-fill-2"
            />
            <path
                d="M2.99757 14.9865C2.58547 14.9865 2.23269 14.8397 1.93922 14.5463C1.64576 14.2528 1.49902 13.9 1.49902 13.4879V4.4966C1.49902 4.0845 1.64576 3.73171 1.93922 3.43825C2.23269 3.14478 2.58547 2.99805 2.99757 2.99805H14.986C15.3981 2.99805 15.7509 3.14478 16.0443 3.43825C16.3378 3.73171 16.4845 4.0845 16.4845 4.4966V13.4879C16.4845 13.9 16.3378 14.2528 16.0443 14.5463C15.7509 14.8397 15.3981 14.9865 14.986 14.9865H2.99757ZM8.99178 9.74153L2.99757 5.99515V13.4879H14.986V5.99515L8.99178 9.74153ZM8.99178 8.24297L14.986 4.4966H2.99757L8.99178 8.24297ZM2.99757 5.99515V4.4966V13.4879V5.99515Z"
                style={{ fill: color }}
                className="app-icon-fill"
            />
        </svg>
    );
}
