import { useEffect } from 'react';
import useAuth from '../../hooks/useAuth';
import { useNavigate } from 'react-router-dom';

export function AuthPage() {
    const navigate = useNavigate();
    const authCtx = useAuth();
    useEffect(() => {
        let urlParts = document.location.href.split('/auth?');
        if (urlParts.length !== 2) {
            errorAuth();
            return;
        }
        let params = urlParts[1].split('&');
        if (params.length !== 2) {
            errorAuth();
            return;
        }
        let urlParams = {};
        for (let i = 0; i < params.length; i++) {
            let paramParts = params[i].split('=');
            if (paramParts.length !== 2) {
                errorAuth();
                return;
            }
            urlParams[paramParts[0]] = paramParts[1];
        }
        if (!urlParams['secure_tk'] || !urlParams['secure_rf']) {
            errorAuth();
            return;
        }
        authCtx.setLoginCookies({
            accessToken: urlParams['secure_tk'],
            refreshToken: urlParams['secure_rf'],
        });
        navigate('/');
    }, []);

    const errorAuth = () => {
        navigate('/home');
    };
    return <></>;
}
