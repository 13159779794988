import english from './en.json';
import hindi from './hi.json';
import german from './de.json';

const localeList = {
    en: {
        code: 'EN',
        translation: english,
    },
    hi: {
        code: 'HI',
        translation: hindi,
    },
    de: {
        code: 'DE',
        translation: german,
    },
};

export default localeList;
