/**
 *
 * Customized version of react popup by el... on github
 *
 */

import { PositionTypes } from './Specs';
import { PopupMenu } from './Popup';
import Button from '../Button';
import { Text } from '../Text';
import { Symbol } from '../elements/Icon';
import React, { forwardRef, useEffect, useRef, useState } from 'react';

export { PositionTypes };
export default PopupMenu;

export const PopupButton = forwardRef(
    ({ icon, iconColor = undefined, label, textStyle = {}, ...props }, ref) => {
        return (
            <Button
                ref={ref}
                type={'popup'}
                wide={true}
                {...props}>
                {icon && (
                    <Symbol
                        className={label ? 'mr-8' : ''}
                        name={icon}
                        color={iconColor}
                    />
                )}
                {label && (
                    <Text
                        style={textStyle}
                        value={label}
                    />
                )}
            </Button>
        );
    }
);

export const PopupRightIconButton = ({
    icon,
    label,
    textStyle = {},
    ...props
}) => {
    return (
        <Button
            type={'popup'}
            wide={true}
            {...props}>
            <Text
                style={textStyle}
                value={label}
            />
            {icon && (
                <Symbol
                    className={'ml-8'}
                    name={icon}
                />
            )}
        </Button>
    );
};

export const getInlineStylingAttrs = (attrs) => {
    var _style = {};

    if (attrs) {
        let _style_found = false;
        if (attrs['background']) {
            _style_found = true;
            _style['background'] = attrs.background;
        }
        if (attrs['color']) {
            _style_found = true;
            _style['color'] = attrs.color;
        }

        if (_style_found) {
            _style['width'] = 'auto';
            _style['padding'] = '2px 4px';
            _style['borderRadius'] = 4;

            return _style;
        }
    }

    return _style;
};

export const SelectBox = forwardRef(
    (
        {
            onChange = () => {},
            options = [],
            contentStyle = {},
            triggerStyle = {},
            defaultValue = null,
            disabled = false,
            extraItems = null,
            forceDefaultValue = false,
            placeholder = '',
            className = '',
            value = null,
        },
        ref
    ) => {
        const [selected, setSelected] = useState(null);
        const [visibility, setVisibility] = useState(false);

        useEffect(() => {
            let defaultValueIndex;
            for (let i = 0; i < (options || []).length; i++) {
                if (options[i].value === defaultValue) {
                    defaultValueIndex = i;
                    break;
                }
            }
            if (value !== selected)
                setSelected(
                    value === null || value < 0 ? defaultValueIndex : value
                );
        }, [value]);

        useEffect(() => {
            options &&
                options.map((opt, i) => {
                    if (opt.value === defaultValue) {
                        setSelected(i);
                    }
                });
        }, [defaultValue, options]);

        const handlePopupClose = (e) => {};

        const handleChange = ({ event = null, option = null, idx = null }) => {
            if (!forceDefaultValue) {
                setSelected(idx);
            }

            onChange({
                event: event,
                option: option,
                idx: idx,
                target: {
                    value: option.value,
                },
            });

            if (ref.current) {
                ref.current.close();
            }
        };

        return (
            <>
                <PopupMenu
                    ref={ref}
                    key={'SELECTX'}
                    arrow={false}
                    contentStyle={contentStyle}
                    position={[
                        PositionTypes.BottomCenter,
                        PositionTypes.TopCenter,
                    ]}
                    nested={true}
                    open={visibility}
                    offsetX={0}
                    offsetY={6}
                    onClose={handlePopupClose}
                    trigger={
                        <Button
                            className={'--select-box-custom '+className}
                            style={{
                                ...triggerStyle,
                                pointerEvents: disabled ? 'none' : undefined,
                            }}
                            title={
                                (options &&
                                    options[selected] &&
                                    options[selected].title) ||
                                undefined
                            }>
                            {options &&
                                options[selected] &&
                                options[selected].icon && (
                                    <Symbol
                                        className={'mr-8'}
                                        name={options[selected].icon}
                                    />
                                )}
                            <Text
                                style={{
                                    lineHeight: 'initial',
                                    height: 'auto',
                                    ...getInlineStylingAttrs(
                                        options &&
                                            options[selected] &&
                                            options[selected].attributes
                                    ),
                                    opacity:
                                        options &&
                                        selected !== null &&
                                        options[selected] &&
                                        options[selected].label
                                            ? null
                                            : 0.6,
                                }}
                                value={
                                    (options &&
                                        selected !== null &&
                                        options[selected] &&
                                        options[selected].label) ||
                                    placeholder
                                }
                            />
                            <Symbol
                                className={'ml-8 --select-box-arrow'}
                                name={'arrow_drop_down_alt'}
                            />
                        </Button>
                    }>
                    {!options ||
                        (options && options.length < 1 && (
                            <PopupButton
                                style={{ pointerEvents: 'none' }}
                                textStyle={{
                                    lineHeight: 'initial',
                                    height: 'auto',
                                }}
                                label={'No options available.'}
                            />
                        ))}
                    {options &&
                        options.map((opt, i) => {
                            if (opt.hidden) {
                                return <></>;
                            }
                            let newGroupe = false;
                            if (
                                i > 0 &&
                                opt.groupe &&
                                options[i - 1].groupe !== opt.groupe
                            ) {
                                newGroupe = true;
                            }
                            return (
                                <>
                                    {i > 0 && newGroupe && (
                                        <div className="select-box-items-groups-separator"></div>
                                    )}
                                    <PopupButton
                                        key={opt.value}
                                        textStyle={{
                                            lineHeight: 'initial',
                                            height: 'auto',
                                            ...getInlineStylingAttrs(
                                                opt && opt.attributes
                                            ),
                                        }}
                                        title={opt.title || undefined}
                                        label={opt.label}
                                        onClick={(e) =>
                                            handleChange({
                                                event: e,
                                                option: opt,
                                                idx: i,
                                            })
                                        }
                                        icon={opt.icon}
                                    />
                                </>
                            );
                        })}
                    {extraItems && extraItems}
                </PopupMenu>
            </>
        );
    }
);
