import React, { useEffect, useRef, useState } from 'react';
import Button from '../../components/Button';
import { Symbol } from '../../components/elements/Icon';
import Input from '../../components/Input';
import PopupMenu, { PopupButton, PositionTypes } from '../../components/Popup';
import { Text } from '../../components/Text';
import useBarelayout from '../barelayout/hook';
import '../../assets/scss/components/editFieldPopup.scss';

import { FieldsData } from './fieldsdata';
import { Field } from '../../panels/right/Field';
import { SortUtility } from '../../panels/top/database_full';
import useSetting from '../../hooks/useSetting';
import { LeftPanelTabs } from '../../panels/left/main';
import { fieldsetsIdx } from '../barelayout/ctx';
import { exportDatabase } from '../../components/export-database-handler/ExportDatabaseHandler';
import { importDatabase } from '../../components/import-database-handler/ImportDatabaseHandler';
import useDatabase from '../../hooks/useDatabase';

export function TableField({ fieldset = null, field = null }) {
    const oldFieldWidth = useRef(field.width.normal);

    const brCtx = useBarelayout();

    const [fieldWidth, setFieldWidth] = useState(field.width.normal);

    const [deleteActive, setDeleteActive] = useState(false);

    useEffect(() => {
        setFieldWidth(field.width.normal);
    }, [field.width.normal]);

    const handleKeyUp = (e) => {
        if (e.keyCode === 13) {
            brCtx.updateField({
                fieldsetuid: fieldset.id,
                uid: field.id,
                data: {
                    label: (e.target.value || '').trim(),
                },
            });
        }
    };

    const handleDefaultValueKeyUp = (e) => {
        if (e.keyCode === 13) {
            brCtx.updateField({
                fieldsetuid: fieldset.id,
                uid: field.id,
                data: {
                    defaultValue: (e.target.value || '').trim(),
                },
            });
        }
    };

    const handleDelete = () => {
        if (deleteActive) {
            brCtx.deleteField({
                fieldsetuid: fieldset.id,
                uid: field.id,
            });
        } else {
            setDeleteActive(true);
        }
    };

    const handleUpdateType = (e) => {
        brCtx.updateField({
            fieldsetuid: fieldset.id,
            uid: field.id,
            data: {
                type: e.type,
            },
        });
    };

    const isAllRecordSelected = () => {
        let selectedRecordsLength = 0;
        if (brCtx.selected_records[brCtx.current_table.recordsetIdx]) {
            selectedRecordsLength =
                brCtx.selected_records[brCtx.current_table.recordsetIdx].length;
        }
        if (
            selectedRecordsLength > 0 &&
            selectedRecordsLength ===
                brCtx.recordsets[brCtx.current_table.recordsetIdx].records
                    .length
        ) {
            return true;
        }
        return false;
    };

    const toggleSelectAllCells = () => {
        if (isAllRecordSelected()) {
            brCtx.setBasic({
                selected_records: {},
            });
        } else {
            brCtx.setBasic({
                selected_records: {
                    [brCtx.current_table.recordsetIdx]: brCtx.recordsets[
                        brCtx.current_table.recordsetIdx
                    ].records.map((elm, i) => elm.id),
                },
            });
        }
    };

    const handelStartResizeField = (e) => {
        window.addEventListener('mousemove', handelResizeField);
        window.addEventListener('mouseup', removeResizeFieldHandler);
    };

    const handelResizeField = (e) => {
        /*setFieldWidth((oldWidth) => {
            let newWidth = oldWidth + e.movementX;
            if (newWidth > 50 && newWidth < 500) {
                return newWidth;
            }
            return oldWidth;
        });*/
        try {
            let newWidth = oldFieldWidth.current + e.movementX;
            if (newWidth > 50 && newWidth < 500) {
                oldFieldWidth.current = newWidth;
                let newFieldset = ((fieldset || {}).fields || []).map((elm) => {
                    if (elm.id === field.id) {
                        return { ...elm, width: { normal: newWidth } };
                    }
                    return elm;
                });

                let newFieldsets = brCtx.fieldsets.slice(0);
                newFieldsets[brCtx.current_table.fieldsetIdx] = {
                    ...fieldset,
                    fields: newFieldset,
                };
                brCtx.setBasic({
                    fieldsets: newFieldsets.slice(0),
                });
            }
        } catch (err) {}
    };

    const removeResizeFieldHandler = (e) => {
        try {
            brCtx.updateField({
                fieldsetuid: fieldset.id,
                uid: field.id,
                noReload: true,
                data: {
                    width: oldFieldWidth.current,
                },
            });
        } catch (err) {}
        window.removeEventListener('mousemove', handelResizeField);
        window.removeEventListener('mouseup', removeResizeFieldHandler);
    };

    return (
        <th>
            {field.type === 'selector' ? (
                <>
                    {
                        /*{brCtx.recordsets[brCtx.current_table.recordsetIdx] &&
                    brCtx.recordsets[brCtx.current_table.recordsetIdx].records.length > 0 ? (*/
                        isAllRecordSelected() ? (
                            <div
                                className={
                                    '--grid-field' +
                                    ` --field-type-${field.type} ${
                                        isAllRecordSelected()
                                            ? '--selected'
                                            : ''
                                    }`
                                }
                                onClick={() => {
                                    if (
                                        brCtx.recordsets[
                                            brCtx.current_table.recordsetIdx
                                        ] &&
                                        brCtx.recordsets[
                                            brCtx.current_table.recordsetIdx
                                        ].records.length > 0
                                    ) {
                                        toggleSelectAllCells();
                                    }
                                }}
                                style={{
                                    width: field.width.normal || 200,
                                    position: 'relative',
                                }}
                                title={'Clear selection'}>
                                <div className="--selected-all-cells-indicator"></div>
                            </div>
                        ) : (brCtx.recordsets[
                              brCtx.current_table.recordsetIdx
                          ] &&
                              brCtx.recordsets[brCtx.current_table.recordsetIdx]
                                  .records.length > 0) ||
                          brCtx.selectedFilters.length > 0 ? (
                            <ShowMoreOptions
                                field={field}
                                toggleSelectAllCells={toggleSelectAllCells}
                            />
                        ) : (
                            <div
                                className={`--grid-field --field-type-${field.type} `}
                                style={{
                                    width: field.width.normal || 200,
                                    position: 'relative',
                                }}></div>
                        )
                    }
                </>
            ) : (
                <>
                    <div
                        className={`--grid-field --field-type-${field.type}`}
                        onClick={() => {
                            let rule = 'ascending';
                            if (
                                brCtx.sortRulesList[0]['field']['id'] ===
                                field.id
                            ) {
                                if (
                                    brCtx.sortRulesList[0]['rule'] ===
                                    'ascending'
                                ) {
                                    rule = 'descending';
                                } else {
                                    rule = null;
                                }
                            }
                            if (rule) {
                                brCtx.setBasic({
                                    sortRulesList: [
                                        {
                                            field: field,
                                            rule: rule,
                                        },
                                    ],
                                });
                            } else {
                                brCtx.setBasic({
                                    sortRulesList: [
                                        {
                                            field: {
                                                automatic_value: false,
                                                id: 'date_of_creation',
                                                label: 'Creation Date',
                                                type: 'date',
                                                value: null,
                                                width: { normal: 310 },
                                            },
                                            rule: 'ascending',
                                        },
                                    ],
                                });
                            }
                        }}
                        style={{
                            width: fieldWidth + 'px',
                            position: 'relative',
                        }}>
                        {field.not_ready && (
                            <Button
                                loading={true}
                                style={{
                                    pointerEvents: 'none',
                                    position: 'absolute',
                                    right: 12,
                                }}
                                className={''}
                                type={'icon'}></Button>
                        )}

                        <Text
                            value={
                                (field && field.label !== 'FAQ'
                                    ? field.label !== 'PUBLISH'
                                        ? field.label.toLowerCase()
                                        : 'Online'
                                    : 'Question') /*field.label*/ || null
                            }
                        />
                        {brCtx.sortRulesList[0]['field']['id'] === field.id && (
                            <span
                                className={'field-sort-indicator'}
                                type={'icon'}>
                                <Symbol
                                    name={
                                        brCtx.sortRulesList[0]['rule'] ===
                                        'ascending'
                                            ? 'arrow_up'
                                            : 'arrow_down'
                                    }
                                />
                            </span>
                        )}
                    </div>

                    <div
                        className={'resize_field_handle'}
                        onMouseDown={handelStartResizeField}></div>
                </>
            )}
        </th>
    );
}

export function TableFieldset({ fieldset = null }) {
    return (
        <>
            {fieldset &&
                fieldset.fields &&
                fieldset.fields.map((field) => {
                    if (field.attributes && field.attributes['hidden']) {
                        return <React.Fragment key={field.id}></React.Fragment>;
                    }

                    return (
                        <TableField
                            key={field.id}
                            fieldset={fieldset}
                            field={field}
                        />
                    );
                })}
        </>
    );
}

const ShowMoreOptions = ({ field, toggleSelectAllCells }) => {
    const popupMenuRef = useRef(null);
    const dbCtx = useDatabase();
    const brCtx = useBarelayout();
    const settingCtx = useSetting();
    const [sortPopupIsOpen, setSortPopupIsOpen] = useState(false);

    const closeMoreOptionsMenu = () => {
        popupMenuRef.current.close();
    };

    return (
        <>
            <PopupMenu
                ref={popupMenuRef}
                offsetX={-20}
                offsetY={0}
                position={'right top'}
                nested={true}
                className={'table-more-options-menu'}
                trigger={
                    <div
                        className={`--grid-field --field-type-${field.type}`}
                        style={{
                            width: field.width.normal || 200,
                            position: 'relative',
                        }}
                        title={'More options'}>
                        <div className="--show-more-options-arrow"></div>
                    </div>
                }>
                <PopupButton
                    label={'Sort'}
                    icon={'sort_by_alpha'}
                    onClick={() => {
                        setSortPopupIsOpen(true);
                        closeMoreOptionsMenu();
                    }}
                />
                {brCtx.current_table.fieldsetIdx ===
                    fieldsetsIdx.FAQsFieldset && (
                    <>
                        <PopupButton
                            label={'Filter'}
                            icon={'filter_alt'}
                            onClick={(evnt) => {
                                if (settingCtx.filter_panel.visible) {
                                    settingCtx.setFilterPanel({
                                        ...settingCtx.filter_panel,
                                        visible: false,
                                    });
                                } else {
                                    settingCtx.setFilterPanel({
                                        ...settingCtx.filter_panel,
                                        visible: true,
                                    });
                                }
                                closeMoreOptionsMenu();
                            }}
                        />
                        <PopupButton
                            label={'Export'}
                            icon={'export'}
                            onClick={() => {
                                exportDatabase({
                                    dbId: dbCtx.database.uid,
                                    aliasId: dbCtx.database.aliasuid,
                                    dbName: dbCtx.database.label,
                                });
                                closeMoreOptionsMenu();
                            }}
                        />
                        <PopupButton
                            label={'Import'}
                            icon={'import'}
                            onClick={() => {
                                importDatabase({
                                    dbId: dbCtx.database.uid,
                                    aliasId: dbCtx.database.aliasuid,
                                });
                                closeMoreOptionsMenu();
                            }}
                        />
                    </>
                )}
                <PopupButton
                    label={'Select all'}
                    icon={'checked'}
                    onClick={() => {
                        toggleSelectAllCells();
                    }}
                />
            </PopupMenu>
            <SortUtility
                isActive={true}
                open={sortPopupIsOpen}
                setOpen={setSortPopupIsOpen}
                trigger={<div style={{ position: 'absolute' }}></div>}
            />
        </>
    );
};
