import React, { forwardRef } from 'react';
import { Helmet } from 'react-helmet-async';

const Page = forwardRef(({ children, title = '...', ...other }, ref) => {
    return (
        <div
            ref={ref}
            {...other}>
            <Helmet>
                <title>{title}</title>
            </Helmet>
            {children}
        </div>
    );
});

export default Page;
