import './jobspage.scss';
import Page from '../../components/Page';
import { Text } from '../../components/Text';
import FaqnationWidget from '../../components/faqnationweidget/FaqnationWidget';
import Container from '../../layouts/mainlayout/Container';
import MainLayout from '../../layouts/mainlayout/MainLayout';

export default function JobsPage() {
    return (
        <Page title="FAQNation - Jobs">
            <MainLayout>
                <section style={{ marginBottom: '100px', marginTop: '80px' }}>
                    <h3>
                        Become Part of
                        <br /> FAQ Nation
                    </h3>
                    <Container>
                        <FaqnationWidget
                            faqId={'jobs_faqnationWidgetWrapper'}
                            apiBase={'https://api.faqnation.com'}
                            faqnationProjectId={'tp_bS9beRdq'}
                            faqnationUserId={'JI7Jm4PdoPWB9SUNfOTxA'}
                            faqnationId={'oFs8dpFIgc9WBxGnArO0pJQQ1DDeBSjl2DcE'}
                            faqsLimit={6}
                            options={{
                                hideSearch: true,
                                hideTopics: false,
                                hideSendQuestion: true,
                                colors: {
                                    primary_clr_light: '#3f4c63',
                                    on_primary_clr_light: '#fff',
                                },
                            }}
                        />
                        <div
                            className="contact-hr-btn-container"
                            style={{ textAlign: 'center' }}>
                            <a
                                href="mailto:hr@faqnation.com"
                                className="secondary-btn contact-hr-btn animateOnHover">
                                <Text value="Write HR" />
                            </a>
                        </div>
                    </Container>
                </section>
            </MainLayout>
        </Page>
    );
}
