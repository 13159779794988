import { useEffect, useState } from 'react';
import { fieldsetsIdx, recordsetsIdx } from '../../layouts/barelayout/ctx';
import useBarelayout from '../../layouts/barelayout/hook';
import Button from '../Button';
import PopupMenu from '../Popup';
import { Text } from '../Text';
import { Symbol } from '../elements/Icon';
import './user-question-preview-popup.scss';
import useDatabase from '../../hooks/useDatabase';
import { formatDate } from '../../functions/Utilities';
import useAuth from '../../hooks/useAuth';
import useSetting from '../../hooks/useSetting';
import { RightPanelTabs } from '../../panels/right/main';
import { rightPanelViewStyles } from '../../panels/right/editFaqPanel/FaqEditorPanel';
import { showError } from '../towsts/Toasts';

export function UserQuestionPreviewPopup({ questionId, onClose }) {
    const authCtx = useAuth();
    const settingCtx = useSetting();
    const dbCtx = useDatabase();
    const brCtx = useBarelayout();

    const [deleteActive, setDeleteActive] = useState(false);
    const [addingQuestion, setAddingQuestion] = useState(false);
    const [addRecordWasTriggered, setAddRecordWasTriggered] = useState({
        state: false,
        recordId: null,
    });

    const recordIdx = brCtx.recordsets[recordsetsIdx.InboxsRecordset].records
        .map((record) => record.id)
        .indexOf(questionId);
    const record =
        brCtx.recordsets[recordsetsIdx.InboxsRecordset].records[recordIdx];
    const cellset =
        record &&
        record.cellsets &&
        record.cellsets[brCtx.fieldsets[fieldsetsIdx.InboxsFieldset].id];
    let dateFieldId;
    let questionFieldId;
    let emailFieldId;
    for (
        let i = 0, counter = 3;
        i < brCtx.fieldsets[fieldsetsIdx.InboxsFieldset].fields.length;
        i++
    ) {
        let field = brCtx.fieldsets[fieldsetsIdx.InboxsFieldset].fields[i];
        switch (field.label.toLowerCase()) {
            case 'question':
                questionFieldId = field.id;
                counter--;
                break;
            case 'date':
                dateFieldId = field.id;
                counter--;
                break;
            case 'user email':
                emailFieldId = field.id;
                counter--;
                break;
            default: {
            }
        }
        if (counter <= 0) {
            break;
        }
    }
    const date = formatDate(
        new Date(Date.parse(cellset.cells[dateFieldId].value)),
        authCtx.account.date_format
    );
    const question = cellset.cells[questionFieldId].value;
    const email = cellset.cells[emailFieldId].value;

    async function removeQuestion(id) {
        return dbCtx.deleteRecord({
            recordsetuid: dbCtx.recordsets[recordsetsIdx.InboxsRecordset].uid,
            uid: id,
        });
    }

    useEffect(() => {
        if (brCtx.recordsets && addRecordWasTriggered.state) {
            brCtx.setBasic({
                active_cell: {
                    cellidx: null,
                    e: null,
                    fieldidx: null,
                    recordid: addRecordWasTriggered.recordId,
                    recordsetidx: 0,
                    is_calendar: false /** false */,
                },
            });
            settingCtx.setRightPanel({
                visible: true,
                active: RightPanelTabs.Recordview,
                force_view_style: rightPanelViewStyles.POPUP
            });
            setAddRecordWasTriggered({ state: false, recordId: null });
            onClose();
        }
    }, [brCtx.recordsets, addRecordWasTriggered]);

    const addQuestion = async (id, question) => {
        let faqTileId;
        let m = 1;
        for (let i = 0; m > 0 && i < dbCtx.fieldsets.length; i++) {
            for (
                let n = 0;
                m > 0 && n < dbCtx.fieldsets[i].fields.length;
                n++
            ) {
                let field = dbCtx.fieldsets[i].fields[n];
                if (field.label === 'FAQ') {
                    faqTileId = field.uid;
                    m--;
                    break;
                }
            }
        }
        let recordValues = {};
        recordValues[faqTileId] = question;
        try {
            setAddingQuestion(true);
            let res = await dbCtx.addRecord({
                aliasuid: dbCtx.database.aliasuid,
                dbId: dbCtx.database.uid,
                recordsetuid: dbCtx.recordsets[recordsetsIdx.FAQsRecordset].uid,
                attributes: null,
                values: recordValues,
                noReload: false,
            });
            if (res.status === 201 && res.data.record) {
                removeQuestion(id);
                setAddRecordWasTriggered({
                    state: true,
                    recordId: res.data.record.uid,
                });
            } else if (res.status === 400) {
                if (res.data.messageCode === 'LIMIT_REACHED') {
                    settingCtx.setUpgradeProjectDialog({
                        visible: true,
                        project_id: dbCtx.database.uid,
                        msg: 'You are currently on a free project, upgrade it and get unlimited Questions!',
                    });
                    /*alert(
                        'Upgrade to the PRO plan to be able to add more than 8 FAQs!'
                    );*/
                }
            } else {
                showError('error creating a new FAQ, please try again!');
            }
        } catch (err) {
            showError('Error adding FAQ, please try again!');
        }
        setAddingQuestion(false);
    };

    return (
        <PopupMenu
            className="userQuestionPreviewPopup"
            open={true}
            onClose={onClose}>
            <div className="userQuestionPreviewPopup-header">
                <Button
                    onClick={async () => {
                        if (!deleteActive) {
                            setDeleteActive(true);
                            return;
                        }
                        const res = await removeQuestion(questionId);
                        onClose();
                    }}>
                    <Symbol
                        color={deleteActive ? 'red' : ''}
                        name="delete_alt1"
                        className={'mr-8'}
                    />
                    <Text
                        style={{
                            color: deleteActive ? 'red' : '',
                        }}
                        value={deleteActive ? 'Are you sure?' : 'Remove'}
                    />
                </Button>
                <Button
                    type={'icon'}
                    style={{ marginLeft: 'auto' }}
                    onClick={onClose}>
                    <Symbol name="close" />
                </Button>
            </div>
            <div className="userQuestionPreviewPopup-content">
                <div className="userQuestionPreviewPopup-date">{date}</div>
                {email && (
                    <div className="userQuestionPreviewPopup-email">
                        <a
                            rel="noreferrer"
                            target="_blank"
                            href={'mailto:' + email}>
                            {email}
                        </a>
                    </div>
                )}
                <div className="userQuestionPreviewPopup-question">
                    {question}
                </div>
            </div>
            <div className="userQuestionPreviewPopup-actions">
                <Button
                    loading={addingQuestion}
                    type={'primary'}
                    onClick={() => {
                        addQuestion(questionId, question);
                    }}
                    style={{ padding: '0 20px' }}>
                    <Text value={'Answer'} />
                </Button>
            </div>
        </PopupMenu>
    );
}
