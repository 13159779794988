import React, { useEffect, useRef, useState } from 'react';
import Button from '../../components/Button';
import { Symbol } from '../../components/elements/Icon';
import Input from '../../components/Input';
import { Text } from '../../components/Text';
import useAlias from '../../hooks/useAlias';
import { CellProvider } from './cellctx';
import useCell from './cellHook';
import { BrDialogTabs } from './dialog';
import useBarelayout from './hook';
import { RecordProvider } from './recordctx';
import useRecord from './recordHook';
import { RecordsetProvider } from './recordsetctx';
import useRecordset from './recordsetHook';
import { v4 as uuidV4 } from 'uuid';

const getCellWidth = (field, is_calendar = false) => {
    if (field.type === 'checkbox' || field.type === 'subuser') {
        return 30;
    } else if (field.type === 'integer') {
        return 100;
    } else if (field.type === 'temperature') {
        return 180;
    }

    if (is_calendar) {
        return 120;
    }

    // return 140;

    return 'auto';
};

export const IntegerCell = ({
    // cell = null,
    // field = null,
    // is_calendar = null,
    onUpdate = () => {},
}) => {
    const brCtx = useBarelayout();
    const cellCtx = useCell();
    const recordCtx = useRecord();

    const inputRef = useRef(null);

    const getValue = () => {
        if (cellCtx.cell && cellCtx.cell.value) {
            return cellCtx.cell.value;
        }

        return cellCtx.field.value || null;
    };

    const handleOnChange = (e) => {
        onUpdate(e);
    };

    // useEffect(() => {
    //   if(brCtx.active_cell && brCtx.active_cell.e)
    //   {
    //     const active_cell = brCtx.active_cell;

    //     if(active_cell.fieldid === cellCtx.field.id)
    //     {
    //       if(active_cell.recordid === recordCtx.record.id)
    //       {
    //         if(active_cell.cellidx === 0)
    //         {
    //           inputRef.current.focus();
    //         }
    //       }
    //     }
    //   }
    // }, [cellCtx.field, cellCtx.cell, recordCtx.record]);

    const handleFocus = ({ e = e, i = 0 }) => {
        // brCtx.setBasic({
        //   active_cell: {
        //     cellidx: i,
        //     e: e,
        //     fieldid: cellCtx.field.id,
        //     recordid: recordCtx.record.id
        //   }
        // });
    };

    return (
        <Input
            ref={inputRef}
            key={cellCtx.field.id + getValue()}
            onBlur={handleOnChange}
            onFocus={(e) => handleFocus({ e: e, i: 0 })}
            style={{ width: '90%', marginTop: 4, marginBottom: 4 }}
            type={'tel'}
            inputMode={'numeric'}
            className={'--hide-default-controls'}
            defaultValue={getValue()}
        />
    );
};

export const TemperatureCell = ({
    // cell = null,
    // field = null,
    // is_calendar = null,
    onUpdate = () => {},
}) => {
    const brCtx = useBarelayout();
    const cellCtx = useCell();
    const recordCtx = useRecord();

    const lInputRef = useRef(null);
    const rInputRef = useRef(null);

    const getValue = () => {
        if (cellCtx.cell && cellCtx.cell.value) {
            return cellCtx.cell.value;
        }

        return cellCtx.field.value || null;
    };

    const getLValue = () => {
        return (getValue() && getValue().split('.')[0]) || null;
    };

    const getRValue = () => {
        return (getValue() && getValue().split('.')[1]) || 0;
    };

    const getRValueWithSuffix = () => {
        // return `${getRValue()} °C`;
        return `${getRValue()}`;
    };

    // useEffect(() => {
    //   if(brCtx.active_cell && brCtx.active_cell.e)
    //   {
    //     const active_cell = brCtx.active_cell;

    //     if(active_cell.fieldid === cellCtx.field.id)
    //     {
    //       if(active_cell.recordid === recordCtx.record.id)
    //       {
    //         if(active_cell.cellidx === 0)
    //         {
    //           lInputRef.current.focus();
    //         }
    //         else if(active_cell.cellidx === 1)
    //         {
    //           rInputRef.current.focus();
    //         }
    //       }
    //     }
    //   }
    // }, [cellCtx.field, cellCtx.cell, recordCtx.record]);

    const handleOnChange = (e) => {
        let value = `${parseInt(lInputRef.current.value) || ''}.${parseInt(
            rInputRef.current.value
        )}`;
        // value.replace('°C', '');
        onUpdate({
            ...e,
            target: {
                ...e.target,
                value: value,
            },
        });
    };

    const handleOnKeyUpInDecimal = (e) => {
        let value = e.target.value || '0';
        // if(!value.includes('°C'))
        // {
        //   value += '°C';
        // }
        e.target.value = value;
    };

    const handleFocus = ({ e = e, i = 0 }) => {
        // brCtx.setBasic({
        //   active_cell: {
        //     cellidx: i,
        //     e: e,
        //     fieldid: cellCtx.field.id,
        //     recordid: recordCtx.record.id
        //   }
        // });
    };

    return (
        <>
            <Input
                // key={cellCtx.field.id}
                // key={getLValue()}
                key={
                    (cellCtx && cellCtx.field.id + getLValue() + '-x') ||
                    uuidV4()
                }
                ref={lInputRef}
                onFocus={(e) => handleFocus({ e: e, i: 0 })}
                onBlur={handleOnChange}
                style={{ width: '90%', marginTop: 4, marginBottom: 4 }}
                type={'tel'}
                className={'--hide-default-controls'}
                defaultValue={getLValue()}
            />
            <Text
                value={'.'}
                style={{ marginRight: 4 }}
            />
            <Input
                // key={`${cellCtx.field.id}-x`}
                key={(cellCtx && cellCtx.field.id + getRValue()) || uuidV4()}
                ref={rInputRef}
                onKeyUp={handleOnKeyUpInDecimal}
                onFocus={(e) => handleFocus({ e: e, i: 1 })}
                onBlur={handleOnChange}
                style={{ width: '90%', marginTop: 4, marginBottom: 4 }}
                type={'tel'}
                className={'--hide-default-controls'}
                defaultValue={getRValueWithSuffix()}
            />
            <Text value={'°C'} />
        </>
    );
};

export const CheckboxCell = ({
    cell = null,
    field = null,
    is_calendar = null,
    onUpdate = () => {},
}) => {
    // useEffect(() => {
    //   console.log(cell)
    // }, [cell]);

    const getValue = () => {
        return cell && cell.value === 'true' ? 'false' : 'true';
    };

    const handleOnChange = (e) => {
        // onUpdate({
        //   ...e,
        //   target: {
        //     ...e.target,
        //     value: getValue(),
        //   },
        // });
        onUpdate({
            e: e,
            value: getValue(),
        });
    };
    return (
        <input
            key={`${field.id}-${(cell && cell.value) || ''}`}
            onChange={handleOnChange}
            type={'checkbox'}
            defaultChecked={cell && cell.value === 'true'}></input>
    );
};

export const SubuserCheckboxCell = ({
    cell = null,
    field = null,
    is_calendar = null,
    onUpdate = () => {},
}) => {
    const aliasCtx = useAlias();

    const getValue = () => {
        return cell && cell.value ? null : aliasCtx.subuser.record.uid;
    };

    const handleOnChange = (e) => {
        onUpdate({
            ...e,
            target: {
                ...e.target,
                value: getValue(),
            },
        });
    };
    return (
        <input
            key={field.id + getValue()}
            onChange={handleOnChange}
            type={'checkbox'}
            defaultChecked={cell && cell.value ? true : false}></input>
    );
};

export const StringCell = ({
    cell = null,
    field = null,
    recordset = null,
    is_calendar = null,
}) => {
    const recordsetCtx = useRecordset();

    var style = {};

    if (
        recordsetCtx &&
        recordsetCtx.recordset.attributes &&
        recordsetCtx.recordset.attributes['cellstyle'] &&
        !is_calendar
    ) {
        style = recordsetCtx.recordset.attributes['cellstyle'];
    }

    // style={
    //   background: 'red'
    // }

    return (
        <>
            {cell && cell.value && (
                <Text
                    style={style}
                    className={'cell-text'}
                    value={cell.value}
                />
            )}
        </>
    );
};

export function Cell(
    {
        // cell = null,
        // record = null,
        // fieldset = null,
        // recordset = null,
        // field = null,
        // is_calendar = null,
    }
) {
    const brCtx = useBarelayout();

    const cellCtx = useCell();
    const recordCtx = useRecord();
    const recordsetCtx = useRecordset();

    const [cell, setCell] = useState(null);
    const [field, setField] = useState(null);

    useEffect(() => {
        // console.log("Rendered field: "+field);
    }, [field]);

    useEffect(() => {
        setCell(cellCtx.cell);
    }, [cellCtx.cell]);

    useEffect(() => {
        setField(cellCtx.field);
    }, [cellCtx.field]);

    var FilteredCell = () => {
        return <span>{(cellCtx.cell && cellCtx.cell.value) || ''}</span>;
    };

    if (field) {
        if (field.type === 'integer') {
            FilteredCell = IntegerCell;
        }
        if (field.type === 'temperature') {
            FilteredCell = TemperatureCell; /* TemperatureCell */
        } else if (field.type === 'string') {
            FilteredCell = StringCell;
        } else if (field.type === 'time') {
            FilteredCell = StringCell;
        } else if (field.type === 'date') {
            FilteredCell = StringCell;
        } else if (field.type === 'select') {
            FilteredCell = StringCell;
        } else if (field.type === 'checkbox') {
            FilteredCell = CheckboxCell;
        } else if (field.type === 'subuser') {
            if (field.attributes && field.attributes['type'] === 'checkbox') {
                FilteredCell = SubuserCheckboxCell;
            }
        }
    }

    const handleOnUpdate = (e) => {
        var value = null;
        var ee = null;

        if (cellCtx.field.type === 'checkbox') {
            value = e.value;
            ee = e.e;
        } else {
            value = e.target.value;
            ee = e;
        }

        if (cellCtx && cellCtx.cell && value === cellCtx.value) {
            return;
        }

        var st = brCtx.handleCellUpdate({
            e: ee,
            field: cellCtx.field,
            cell: cellCtx.cell,
            record: recordCtx.record,
            fieldset: cellCtx.fieldset,
            recordset: recordsetCtx.recordset,
            is_calendar: cellCtx.is_calendar,
            calendar_datecode: brCtx.active_calendar_datecode,
            new_value: value,
        });
    };

    var style = {};

    if (cellCtx && cellCtx.field && cellCtx.field.type === 'checkbox') {
        style['padding'] = 0;
    }
    if (
        cellCtx &&
        cellCtx.field &&
        cellCtx.field.type === 'subuser' &&
        cellCtx.field.attributes &&
        cellCtx.field.attributes['type'] === 'checkbox'
    ) {
        style['padding'] = 0;
    }

    if (cellCtx.field) {
        style['width'] = getCellWidth(cellCtx.field, cellCtx.is_calendar);
    }

    return (
        <div
            className="--cell"
            style={style}>
            <FilteredCell
                onUpdate={handleOnUpdate}
                cell={cell}
                field={cellCtx.field}
            />
        </div>
    );
}

export function Cellset({
    fieldset = null,
    cellset = null,
    recordset = null,
    record = null,
    recordidx = null,
    recordsetidx = null,
    fieldsetidx = null,
    is_calendar = null,
}) {
    const brCtx = useBarelayout();

    const recordCtx = useRecord();
    const recordsetCtx = useRecordset();

    const [field, setField] = useState(0);

    // useEffect(() => {
    //   console.log("Rendered cellset: "+field);
    // }, [field]);

    useEffect(() => {
        // console.log(brCtx.active_field)
        setField(brCtx.active_field);
    }, [brCtx.active_field]);

    if (!fieldset) {
        return <></>;
    }

    const getCell = () => {
        if (cellset && cellset.cells) {
            if (cellset.cells) {
                let fieldid =
                    (fieldset &&
                        fieldset.fields[field] &&
                        fieldset.fields[field].id) ||
                    null;

                return cellset.cells[fieldid] || null;
            }
        }

        return null;
    };

    const getCellByField = (field) => {
        if (cellset && cellset.cells) {
            if (cellset.cells) {
                let fieldid = field.id || null;
                return cellset.cells[fieldid] || null;
            }
        }

        return null;
    };

    const handleOpenExplainedView = (e, fieldIdx) => {
        brCtx.setBasic({
            dialog: {
                ...(brCtx.dialog || {}),
                visible: true,
                tab: BrDialogTabs.CELLBOX,
                cellbox: {
                    fieldidx: fieldIdx,
                    fieldsetidx: fieldsetidx,
                    recordidx: recordCtx.idx,
                    recordsetidx: recordsetCtx.idx,
                },
            },
        });
    };

    if (fieldset.fields[field]) {
        if (fieldset.fields[field].type === 'selector') {
            return <></>;
        }
    }

    // if(field < 1)
    // {
    if (is_calendar) {
        return (
            <div
                key={fieldset.id}
                className="--cellset">
                {field === 1 ? (
                    <div className="--cell">
                        <Button
                            onClick={(e) => handleOpenExplainedView(e, field)}>
                            <Text
                                value={
                                    fieldset.fields[field] &&
                                    fieldset.fields[field].label
                                }
                            />
                        </Button>
                    </div>
                ) : (
                    <>
                        {/* <Cell
              is_calendar={is_calendar}
              field={fieldset.fields && fieldset.fields[field]}
              fieldset={fieldset}
              record={record}
              recordset={recordset}
              cell={getCell()}
            /> */}
                        <CellProvider
                            key={
                                fieldset.fields &&
                                fieldset.fields[field] &&
                                fieldset.fields[field].id
                            }
                            is_calendar={is_calendar}
                            field={fieldset.fields && fieldset.fields[field]}
                            fieldset={fieldset}
                            cell={getCell()}>
                            <Cell />
                        </CellProvider>
                    </>
                )}
            </div>
        );
    } else {
        return (
            <div
                key={fieldset.id}
                className="--cellset">
                {fieldset.fields &&
                    fieldset.fields.map((field, x) => {
                        if (x != 0) {
                            return (
                                <React.Fragment key={field.id}></React.Fragment>
                            );
                        }

                        var cell = getCellByField(field);

                        return (
                            <CellProvider
                                key={field.id}
                                is_calendar={is_calendar}
                                field={field}
                                fieldset={fieldset}
                                cell={cell}>
                                <Cell />
                                {brCtx.last_cells &&
                                    brCtx.last_cells[recordCtx.record.id] && (
                                        <>
                                            <div
                                                className="--cell"
                                                style={{
                                                    width: 'auto',
                                                    padding: 4,
                                                }}>
                                                <Text
                                                    className="--last-timestamp"
                                                    value={`${
                                                        (brCtx.last_cells[
                                                            recordCtx.record.id
                                                        ].audit &&
                                                            brCtx.last_cells[
                                                                recordCtx.record
                                                                    .id
                                                            ].audit.name) ||
                                                        null
                                                    } ${
                                                        brCtx.last_cells[
                                                            recordCtx.record.id
                                                        ].label
                                                    }`}
                                                />
                                            </div>
                                        </>
                                    )}
                            </CellProvider>
                        );
                    })}
            </div>
        );
    }
    // }
    // else
    // {
    //   // if(field != 1)
    //   // {
    //   //   return <React.Fragment key={field}></React.Fragment>;
    //   // }
    //   console.log(field)
    //   return (
    //     <div className="--cellset">
    //       <div className="--cell">
    //         <Button>
    //           <Text value={fieldset.fields[field] && fieldset.fields[field].label} />
    //         </Button>
    //       </div>
    //     </div>
    //   );
    // }
}

export function Record() {
    const brCtx = useBarelayout();

    const recordCtx = useRecord();

    const [record, setRecord] = useState(recordCtx.record || undefined);

    // useEffect(() => {
    //   console.log("Rendered record: "+recordCtx.record);
    // }, []);

    useEffect(() => {
        setRecord(recordCtx.record);
    }, [recordCtx.record]);

    if (!recordCtx.initialized || !record) {
        return <></>;
    }

    const getCellsetCalendar = (fieldset) => {
        if (
            record.calendar_cellsets &&
            record.calendar_cellsets[brCtx.active_calendar_datecode]
        ) {
            return record.calendar_cellsets[brCtx.active_calendar_datecode][
                fieldset.id
            ];
        }

        return null;
    };

    return (
        <div className="--record">
            {/* {brCtx.calendar_fieldsets && brCtx.calendar_fieldsets.map((fieldset, x) => {

                    return (<Cellset is_calendar={true} key={fieldset.id} fieldset={fieldset} cellset={getCellsetCalendar(fieldset)} />);

                })} */}

            {brCtx.calendar_fieldsets &&
                brCtx.calendar_fieldsets.map((fieldset, x) => {
                    if (x != 0)
                        return (
                            <React.Fragment key={fieldset.id}></React.Fragment>
                        );

                    return (
                        <Cellset
                            is_calendar={true}
                            key={fieldset.id}
                            fieldset={fieldset}
                            // record={record}
                            // recordset={recordsetCtx.recordset}
                            // recordidx={recordCtx.idx}
                            // recordsetidx={recordsetCtx.idx}
                            fieldsetidx={x}
                            cellset={getCellsetCalendar(fieldset)}
                        />
                    );
                })}

            {brCtx.fieldsets &&
                brCtx.fieldsets.map((fieldset, x) => {
                    return (
                        <Cellset
                            key={fieldset.id}
                            fieldset={fieldset}
                            // record={record}
                            // recordset={recordsetCtx.recordset}
                            // recordidx={recordCtx.idx}
                            fieldsetidx={x}
                            // recordsetidx={recordsetCtx.idx}
                            cellset={recordCtx.cellsets[fieldset.id]}
                        />
                    );
                })}
        </div>
    );
}

export function Recordset() {
    const brCtx = useBarelayout();

    const recordsetCtx = useRecordset();

    const [records, setRecords] = useState(null);

    useEffect(() => {
        setRecords(recordsetCtx.records);
    }, [recordsetCtx.records]);

    // useEffect(() => {
    //   console.log("Rendered recordset: "+recordsetCtx.recordset);
    // }, []);

    // if (!recordsetCtx.initialized || !recordsetCtx.recordset) return <></>;

    const handleOnRecordsetCollapseClick = (e) => {
        brCtx.toggleCollapseRecordsets(recordsetCtx.idx);
    };

    return (
        <div
            className={
                '--recordset' +
                (brCtx.collapsed_recordsets.includes(recordsetCtx.idx)
                    ? ' --collapsed'
                    : '')
            }>
            <div className="--recordset-label">
                <div onClick={handleOnRecordsetCollapseClick}>
                    <Symbol
                        className={'--icon'}
                        name={'arrow_drop_down'}
                    />
                </div>

                <div onClick={handleOnRecordsetCollapseClick}>
                    <Text
                        className={'--label'}
                        value={
                            recordsetCtx.recordset &&
                            recordsetCtx.recordset.label
                        }
                    />
                </div>
            </div>

            {!brCtx.collapsed_recordsets.includes(recordsetCtx.idx) && (
                <div className="--records-container scroll-container">
                    {/* {recordset.records &&
            recordset.records.map((record, x) => {
              return (
                <Record recordidx={x} recordsetidx={idx} recordset={recordset} key={record.id} record={record} />
              );
            })} */}

                    {recordsetCtx.initialized &&
                        records &&
                        records.map((record, x) => {
                            return (
                                <RecordProvider
                                    idx={x}
                                    record={record}
                                    key={record.id}>
                                    <Record />
                                </RecordProvider>
                            );
                        })}
                </div>
            )}
        </div>
    );
}

export default function BarelayoutBareView() {
    const brCtx = useBarelayout();

    const [recordsets, setRecordsets] = useState(null);

    useEffect(() => {
        setRecordsets(brCtx.recordsets);
    }, [brCtx.recordsets]);

    return (
        <>
            <div className="bare-layout-grid-view">
                {brCtx.initialized &&
                    recordsets &&
                    recordsets.map((recordset, x) => {
                        if (
                            brCtx.active_recordset === 'all' ||
                            brCtx.active_recordset === x
                        ) {
                            return (
                                <RecordsetProvider
                                    key={recordset.id}
                                    recordset={recordset}
                                    idx={x}>
                                    <Recordset />
                                </RecordsetProvider>
                            );
                            // return (
                            //   <Recordset key={recordset.id} recordset={recordset} idx={x} />
                            // );
                        } else {
                            return (
                                <React.Fragment
                                    key={recordset.id}></React.Fragment>
                            );
                        }
                    })}
            </div>
        </>
    );
}
