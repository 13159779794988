import React, {
    createContext,
    useEffect,
    useReducer,
    useRef,
    useState,
} from 'react';

const initialState = {
    initialized: false,

    record: null,

    calendar_cellsets: null,

    cellsets: null,

    idx: null,
};

const handlers = {
    INITIALIZE: (state, action) => {
        return {
            ...state,
            ...action.payload,
            initialized: true,
        };
    },

    UNINITIALIZE: (state) => {
        return {
            ...state,
            ...initialState,
        };
    },
};

const reducer = (state, action) =>
    handlers[action.type] ? handlers[action.type](state, action) : state;

const RecordCtx = createContext({
    ...initialState,
});

function RecordProvider({ children, idx = null, record = null, ...props }) {
    const [state, dispatch] = useReducer(reducer, initialState);

    useEffect(() => {
        const initialize = () => {
            dispatch({
                type: 'INITIALIZE',
                payload: {
                    idx: idx,
                    record: record,
                    calendar_cellsets: record.calendar_cellsets || null,
                    cellsets: record.cellsets || null,
                },
            });
        };

        if (record) {
            initialize();
        }
    }, [record, idx]);

    return (
        <RecordCtx.Provider
            value={{
                ...state,
            }}>
            {children}
        </RecordCtx.Provider>
    );
}

export { RecordCtx, RecordProvider };
