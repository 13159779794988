import React from 'react';
import Input from '../../components/Input';
import { Text } from '../../components/Text';
import useDatabase from '../../hooks/useDatabase';

export default function AlertviewTab() {
    const dbCtx = useDatabase();

    const handleOnMinBlur = (e) => {
        dbCtx.updateDatabase({
            alertMin: parseFloat(e.target.value) || 0,
        });
    };

    const handleOnMaxBlur = (e) => {
        dbCtx.updateDatabase({
            alertMax: parseFloat(e.target.value) || 0,
        });
    };

    return (
        <>
            <div className="--section --align-start">
                <div style={{ padding: 16 }}>
                    <Text
                        style={{ fontSize: 18, fontWeight: 600 }}
                        value={'Alerts'}
                    />
                </div>

                <div className="--field">
                    <Text value={'Min'} />

                    <Input
                        key={(dbCtx.alerts && dbCtx.alerts.min) || '--min'}
                        onBlur={handleOnMinBlur}
                        type={'text'}
                        placeholder={'Value'}
                        defaultValue={
                            (dbCtx.alerts && `${dbCtx.alerts.min}`) || null
                        }
                    />
                </div>

                <div className="--field">
                    <Text value={'Max'} />

                    <Input
                        key={(dbCtx.alerts && dbCtx.alerts.max) || '--max'}
                        onBlur={handleOnMaxBlur}
                        type={'text'}
                        placeholder={'Value'}
                        defaultValue={
                            (dbCtx.alerts && `${dbCtx.alerts.max}`) || null
                        }
                    />
                </div>
            </div>
        </>
    );
}
