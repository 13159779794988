import { useEffect, useRef } from 'react';
import useDatabase from '../../hooks/useDatabase';
import './help-page-preview.scss';
import useHelpPagePreview from '../../hooks/useHelpPagePreview';
import { helpPagePreviewViews } from '../../contexts/HelpPagePreviewCtx';

export function HelpPagePreview() {
    const iframeRef = useRef(null);
    const dbCtx = useDatabase();
    const helpPagePreviewCtx = useHelpPagePreview();

    useEffect(() => {
        if (!iframeRef.current) {
            return;
        }
        let iframeElm = iframeRef.current;
        let iframeElmWindow = iframeElm.contentWindow;
        try {
            iframeElm.onload = () => {
                try {
                    iframeElmWindow.addEventListener(
                        'beforeunload',
                        preventNavigation
                    );
                } catch (err) {}
            };
        } catch (err) {}
        return () => {
            try {
                iframeElmWindow.removeEventListener(
                    'beforeunload',
                    preventNavigation
                );
            } catch (err) {}
        };
    }, []);

    function preventNavigation(evnt) {
        evnt.preventDefault();
        evnt.returnValue = true;
    }

    return (
        <div
            className={`page-preview-container ${
                helpPagePreviewCtx.state.view === helpPagePreviewViews.mobile
                    ? 'mobile'
                    : ''
            }`}>
            <iframe
                ref={iframeRef}
                title={'faqnation help pgae preview'}
                referrerpolicy="strict-origin-when-cross-origin"
                src={`${'https://preview.faqnation.com'}?prvsubdomain=${
                    dbCtx.subdomain.name
                }`}
                style={{
                    width: '100%',
                    height: '100%',
                    border: 'none',
                    background: '#fff',
                }}></iframe>
        </div>
    );
}
