import React, { useEffect, useRef, useState } from 'react';
import { useOnClickOutside } from '../../hooks/useOnClickOutside';
import BrCameraDialog from './cam';
import useBarelayout from './hook';
import BrQrCodeDialog from './qr';
import BrCellboxDialog from './cellbox';

export const BrDialogTabs = {
    QR_SCANNER: '_qr_scanner',
    CAMERA: '_camera',
    CELLBOX: '_cellbox',
};

export const BrDialog = () => {
    const brCtx = useBarelayout();
    const ref = useRef(null);

    const [visibility, setVisibility] = useState(false);
    const [tab, setTab] = useState(null);

    useEffect(() => {
        if (brCtx.initialized) {
            setTab(brCtx.dialog.tab);
        }
    }, [brCtx.dialog.tab]);

    useEffect(() => {
        if (brCtx.initialized) {
            setVisibility(brCtx.dialog.visible);
        }
    }, [brCtx.dialog.visible]);

    const handleClose = (e) => {
        brCtx.setBasic({
            dialog: {
                ...(brCtx.dialog || {}),
                visible: false,
            },
        });
    };

    const onOutsideClick = (e) => {
        handleClose();
    };

    useOnClickOutside(ref, onOutsideClick, () => {}, visibility);

    var style_for_cell_box = {
        width: '80vh',
        height: 260,
        maxWidth: 380,
        borderRadius: 12,
        top: '32%',
        left: '50%',
        transform: 'translateX(-50%) translateY(-50%)',
        background: '#fff',
        boxShadow: '0 0 12px -6px rgba(0, 0, 0, 0.04)',
    };

    return (
        <>
            {visibility && (
                <div className="br-dialog--backfall">
                    <div
                        ref={ref}
                        className="br-dialog"
                        style={
                            tab === BrDialogTabs.CELLBOX
                                ? style_for_cell_box
                                : {}
                        }>
                        <div className="--inner">
                            {tab === BrDialogTabs.QR_SCANNER ? (
                                <BrQrCodeDialog />
                            ) : tab === BrDialogTabs.CAMERA ? (
                                <BrCameraDialog />
                            ) : tab === BrDialogTabs.CELLBOX ? (
                                <BrCellboxDialog />
                            ) : null}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};
