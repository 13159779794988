import { useEffect, useRef } from 'react';
import Button from '../../components/Button';
import { Symbol } from '../../components/elements/Icon';
import Input from '../../components/Input';
import { Text } from '../../components/Text';
import useAlias from '../../hooks/useAlias';
import useAuth from '../../hooks/useAuth';
import useSetting from '../../hooks/useSetting';
import { TopPanelTabs } from '../top';

export default function AliasviewTopPanel() {
    const authCtx = useAuth();
    const aliasCtx = useAlias();
    const settingCtx = useSetting();

    const renderSubuser = () => {
        const onLockButtonClick = (e) => {
            authCtx.removeAliasLogin({
                uid: aliasCtx.alias.uid,
            });
            window.location.assign('/');
        };

        if (aliasCtx.subuser && aliasCtx.subuser.name) {
            return (
                <Button onClick={onLockButtonClick}>
                    <Symbol
                        name={'lock'}
                        className={'mr-8'}
                    />
                    <Text value={aliasCtx.subuser.name.cell.value} />
                </Button>
            );
        }

        return <></>;
    };

    const handleSearchClick = (e) => {
        settingCtx.setTopPanel({
            active: TopPanelTabs.AliasSearchTab,
        });
    };

    const handleDatabaseTagSelectionChange = (e) => {
        var v = parseInt(e.target.value);

        var newhidden = [];

        if (v === -1) {
        } else {
            newhidden.push(v);
        }

        aliasCtx.setHiddenGroups(newhidden);
    };

    const handleDatabaseGroupClick = ({
        e = null,
        groupIdx = null,
        key = null,
    }) => {
        var newhidden = [];
        if (aliasCtx.hidden_group_idx) {
            let i = aliasCtx.hidden_group_idx.indexOf(groupIdx);
            if (i > -1) {
                newhidden = [...aliasCtx.hidden_group_idx];
                newhidden.splice(i, 1);
            } else {
                newhidden = [...(aliasCtx.hidden_group_idx || []), groupIdx];
            }
        } else {
            newhidden = [...(aliasCtx.hidden_group_idx || []), groupIdx];
        }

        aliasCtx.setHiddenGroups(newhidden);
    };

    return (
        <>
            <div className="--panel-section">
                <Button
                    type={'icon'}
                    onClick={handleSearchClick}
                    className={'--outline mr-12'}>
                    <Symbol name={'search'} />
                </Button>

                {/* <PopupMenu
            ref={filterRef}
            offsetX={60}
            offsetY={10}
            contentStyle={{ width: 170 }}
            trigger={
              <Button type={"icon"} className={"--outline"}>
                <Symbol name={"filter_alt"} />
              </Button>
            }
            position={PositionTypes.BottomCenter}>
              
              {aliasCtx.grouped_databases && Object.keys(aliasCtx.grouped_databases).map((key, x) => {

                var props = {};

                if(!aliasCtx.hidden_group_idx.includes(x))
                {
                  props['icon'] = 'check';
                }

                return (<PopupRightIconButton
                          onClick={(e) => handleDatabaseGroupClick({e: e, groupIdx: x, key: key})}
                          key={x}
                          label={key}
                          style={{ justifyContent: 'space-between' }}
                          {...props} />);

              })}

        </PopupMenu> */}

                <Button
                    style={{ overflow: 'hidden' }}
                    htmlFor={'database_tag_filters'}
                    tag={'label'}
                    type={'icon'}
                    className={'--outline --override-selectbox'}>
                    <Symbol
                        className={'--icon'}
                        name={'filter_alt'}
                    />
                    <select
                        onChange={handleDatabaseTagSelectionChange}
                        style={{
                            height: '100%',
                            width: 110,
                            opacity: 0,
                            cursor: 'pointer',
                        }}
                        name={'database_tag_filters'}
                        id={'database_tag_filters'}>
                        <option value={-1}>All</option>
                        {aliasCtx.grouped_databases &&
                            Object.keys(aliasCtx.grouped_databases).map(
                                (key, x) => {
                                    return (
                                        <option
                                            key={x}
                                            value={x}>
                                            {key}
                                        </option>
                                    );
                                }
                            )}
                    </select>
                </Button>
            </div>

            <div className="--panel-section">
                {aliasCtx.loading && (
                    <Button
                        loading={aliasCtx.loading}
                        type={'icon'}
                        style={{ pointerEvents: 'none' }}></Button>
                )}
                {renderSubuser()}
            </div>
        </>
    );
}

export function AliasviewSearchPanel() {
    const settingCtx = useSetting();

    const inputRef = useRef(null);

    useEffect(() => {
        inputRef.current.focus();
    }, []);

    const handleSearchClick = (e) => {
        settingCtx.setTopPanel({
            active: TopPanelTabs.AliasView,
            query: null,
        });
    };

    const handleOnChange = (e) => {
        settingCtx.setTopPanel({
            query: e.target.value,
        });
    };

    return (
        <>
            <div
                className="--panel-section"
                style={{ width: '100%' }}>
                <Input
                    ref={inputRef}
                    onChange={handleOnChange}
                    placeholder={'Find databases'}
                    size={'wide-mobile'}
                    autoFocus={true}
                    boxClassName={'no-default-style'}
                />
            </div>

            <div className="--panel-section">
                <Button
                    onClick={handleSearchClick}
                    type={'icon'}>
                    <Symbol name={'close'} />
                </Button>
            </div>
        </>
    );
}
