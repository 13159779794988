import React, { useEffect } from 'react';
import useAuth from '../hooks/useAuth';
import SplashScreen from '../components/splashscreen/SplashScreen';
import { useNavigate, useParams } from 'react-router-dom';

export default function MainPage({ children }) {
    const gAuth = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        try {
            let redirectTo = localStorage.getItem('faqnation_redirect_to');
            if (redirectTo) {
                localStorage.removeItem('faqnation_redirect_to');
                document.location.href = redirectTo;
            }
        } catch (err) {}
    }, []);

    useEffect(() => {
        if (!gAuth.initialized) {
            try {
                let redirectTo = sessionStorage.getItem(
                    'faqnation-redirect-to',
                    document.location.href
                );
                if (redirectTo) {
                    sessionStorage.removeItem('faqnation-redirect-to');
                    document.location.href = redirectTo;
                }
            } catch (err) {}
            return;
        }
        if (!gAuth.authenticated) {
            let queries = document.location.search.substring(1).split('&');
            for (let i = 0; i < queries.length; i++) {
                if (queries[i].split('=')[0] === 'answer') {
                    try {
                        sessionStorage.setItem(
                            'faqnation-redirect-to',
                            document.location.href
                        );
                    } catch (err) {}
                    navigate('/login');
                    return;
                }
            }
            navigate('/login');
        }
    }, [gAuth]);

    return <>{gAuth.authenticated ? <>{children}</> : <SplashScreen />}</>;
}
