export const CloseIcon = ({ className = null, color = undefined }) => {
    return (
        <svg
            className={'ui-sym path' + (className ? ' ' + className : '')}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
                style={{ fill: color }}
                className="app-icon-fill"
            />
        </svg>
    );
};

export const CheckIcon = ({ className = null, color = undefined }) => {
    return (
        <svg
            className={'ui-sym path' + (className ? ' ' + className : '')}
            width="18"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_17_4631)">
                <path
                    d="M9.00016 16.17L4.83016 12L3.41016 13.41L9.00016 19L21.0002 6.99997L19.5902 5.58997L9.00016 16.17Z"
                    style={{ fill: color }}
                />
            </g>
            <defs>
                <clipPath id="clip0_17_4631">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export const ThermostatIcon = ({ className = null, color = undefined }) => {
    return (
        <svg
            className={'ui-sym path' + (className ? ' ' + className : '')}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_17_1379)">
                <path
                    d="M15 13V5C15 3.34 13.66 2 12 2C10.34 2 9 3.34 9 5V13C7.79 13.91 7 15.37 7 17C7 19.76 9.24 22 12 22C14.76 22 17 19.76 17 17C17 15.37 16.21 13.91 15 13ZM11 5C11 4.45 11.45 4 12 4C12.55 4 13 4.45 13 5H12V6H13V8H12V9H13V11H11V5Z"
                    style={{ fill: color }}
                />
            </g>
            <defs>
                <clipPath id="clip0_17_1379">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export const BakeryDiningIcon = ({ className = null, color = undefined }) => {
    return (
        <svg
            className={'ui-sym path' + (className ? ' ' + className : '')}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <mask
                id="mask0_762_1122"
                style={{ maskType: 'alpha' }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="24"
                height="24">
                <rect width="24" height="24" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_762_1122)">
                <path
                    d="M20.875 17.7C21.3083 17.85 21.7416 17.6917 22.175 17.225C22.6083 16.7583 22.7083 16.3 22.475 15.85L20.35 11.475L18.4 16.85L20.875 17.7ZM14.6 16.75H16.825L19.65 8.9C19.7333 8.66667 19.7376 8.45 19.663 8.25C19.5876 8.05 19.4333 7.9 19.2 7.8L16.7 6.875C16.4333 6.79167 16.2043 6.79567 16.013 6.887C15.821 6.979 15.7083 7.15 15.675 7.4L14.6 16.75ZM7.19997 16.75H9.39997L8.34997 7.4C8.31664 7.13333 8.19597 6.954 7.98797 6.862C7.77931 6.77067 7.55831 6.775 7.32497 6.875L4.82497 7.8C4.59164 7.88333 4.43764 8.02933 4.36297 8.238C4.28764 8.446 4.29164 8.66667 4.37497 8.9L7.19997 16.75ZM2.89997 17.7L5.62497 16.85L3.69997 11.475L1.54997 15.85C1.31664 16.3 1.37497 16.754 1.72497 17.212C2.07497 17.6707 2.46664 17.8333 2.89997 17.7ZM10.9 16.75H13.1L14.3 6C14.3333 5.71667 14.254 5.479 14.062 5.287C13.8706 5.09567 13.6333 5 13.35 5H10.65C10.3666 5 10.1293 5.09567 9.93797 5.287C9.74597 5.479 9.66664 5.71667 9.69997 6L10.9 16.75Z"
                    style={{ fill: color }}
                />
            </g>
        </svg>
    );
};

export const FaucetIcon = ({ className = null, color = undefined }) => {
    return (
        <svg
            className={'ui-sym path' + (className ? ' ' + className : '')}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <mask
                id="mask0_762_1125"
                style={{ maskType: 'alpha' }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="24"
                height="24">
                <rect width="24" height="24" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_762_1125)">
                <path
                    d="M2 15V13H7V12H5C4.71667 12 4.479 11.904 4.287 11.712C4.09567 11.5207 4 11.2833 4 11C4 10.7167 4.09567 10.479 4.287 10.287C4.479 10.0957 4.71667 10 5 10H7C7.55 10 8.02067 10.1957 8.412 10.587C8.804 10.979 9 11.45 9 12V13H11V6.1C11 4.95 11.4 3.97933 12.2 3.188C13 2.396 13.975 2 15.125 2C15.8917 2 16.6 2.2 17.25 2.6C17.9 3 18.4 3.55 18.75 4.25L19.9 6.55L18.1 7.45L16.95 5.15C16.7667 4.8 16.5083 4.52067 16.175 4.312C15.8417 4.104 15.4833 4 15.1 4C14.5167 4 14.021 4.204 13.613 4.612C13.2043 5.02067 13 5.51667 13 6.1V13H15V12C15 11.45 15.196 10.979 15.588 10.587C15.9793 10.1957 16.45 10 17 10H19C19.2833 10 19.5207 10.0957 19.712 10.287C19.904 10.479 20 10.7167 20 11C20 11.2833 19.904 11.5207 19.712 11.712C19.5207 11.904 19.2833 12 19 12H17V13H22V15H2ZM6 21C5.45 21 4.97933 20.8043 4.588 20.413C4.196 20.021 4 19.55 4 19V16H20V19C20 19.55 19.8043 20.021 19.413 20.413C19.021 20.8043 18.55 21 18 21H6Z"
                    style={{ fill: color }}
                />
            </g>
        </svg>
    );
};
