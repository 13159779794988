import { useEffect, useRef, useState } from 'react';
import '../../../assets/scss/core/elements/_filters.scss';
import useBarelayout from '../../../layouts/barelayout/hook';
import { Text } from '../../../components/Text';
import { SelectBox } from '../../../components/Popup';
import Input from '../../../components/Input';
import { FieldTypeMap } from '../../../layouts/fullview/main';
import { Symbol } from '../../../components/elements/Icon';
import { fieldsetsIdx } from '../../../layouts/barelayout/ctx';

const getCellValue_forFilter = (record, field, cellset, brCtx) => {
    let cell = record['cellsets'][cellset]['cells'][field.id];
    let cellValue = null;
    if (cell) {
        cellValue = cell.value;
    }
    if (field.type === 'checkbox') {
        cellValue =
            '' + cellValue === 'true' ? 1 : '' + cellValue === 'false' ? 0 : 0;
    } else if (field.type === 'integer') {
        cellValue = 1 * cellValue;
    }
    return cellValue;
};

export const applyFilter = (record, filtersList, brCtx) => {
    let cellset = Object.keys(record['cellsets']);
    for (let i = 0; i < filtersList.length; i++) {
        let filter = filtersList[i];
        let cellValue = getCellValue_forFilter(
            record,
            filter,
            cellset[0],
            brCtx
        );
        if (cellValue === null && filter.type !== 'select') {
            return false;
        }
        if (filter.type === 'integer') {
            if (filter.value[0] !== null) {
                if (cellValue < filter.value[0]) {
                    return false;
                }
            }
            if (filter.value[1] !== null) {
                if (cellValue > filter.value[1]) {
                    return false;
                }
            }
        } else if (filter.type === 'string') {
            if (
                cellValue.toLowerCase().indexOf(filter.value.toLowerCase()) < 0
            ) {
                return false;
            }
        } else if (filter.type === 'checkbox') {
            let filterValue =
                filter.value === 1 ? 1 : filter.value === 0 ? 0 : 2;
            if (
                cellValue.toString().toLowerCase() !==
                filterValue.toString().toLowerCase()
            ) {
                return false;
            }
        } else if (filter.type === 'select') {
            if ((filter.value || []).indexOf(cellValue) < 0) {
                return false;
            }
        } else {
            if (
                cellValue.toString().toLowerCase() !==
                filter.value.toString().toLowerCase()
            ) {
                return false;
            }
        }
    }
    return true;
};

export const Field = ({
    key,
    field,
    fieldset,
    setFieldValue,
    reset,
    label,
}) => {
    const ref = useRef(null);
    const booleanSelectFieldRef = useRef(null);
    const inputRef = useRef(null);

    const brCtx = useBarelayout();

    const [options, setOptions] = useState([]);
    const [checkboxFieldOptions, setCheckboxFieldOptions] = useState([
        {
            label: 'All',
            value: 2,
        },
        {
            label: label || field.label,
            value: 1,
        },
        {
            label: 'Not ' + (label || field.label),
            value: 0,
        },
    ]);

    const [checkBoxValue, setCheckBoxValue] = useState(null);
    const [fromValue, setFromValue] = useState('');
    const [toValue, setToValue] = useState('');

    useEffect(() => {
        if (field.type === 'select') {
            getSelectOptions();
        }
    }, [field.type, brCtx.select_fields]);

    useEffect(() => {
        if (
            field.type === 'select' &&
            field.attributes &&
            field.attributes.referType === 'recordsets_categorizer'
        ) {
            getSelectOptions();
        }
    }, [brCtx.recordsets]);

    useEffect(() => {
        if (field.type === 'string' && inputRef.current) {
            inputRef.current.innerText = getValue();
        }
    }, [field]);

    useEffect(() => {
        if (reset === 0) {
            return;
        }
        if (field.type === 'select') {
            setOptions((options) => {
                return options.map((option) => ({ ...option, active: false }));
            });
        } else if (field.type === 'checkbox') {
            setCheckBoxValue((v) => (v === null ? -1 : null));
        } else if (field.type === 'integer') {
            setFromValue('');
            setToValue('');
        }
    }, [reset]);

    const handleOnBlur = (e) => {
        setFieldValue(e.target.value || null);
    };

    const handleOnBlur_from = (e) => {
        setFieldValue([e.target.value || null, field.value[1]]);
    };

    const handleOnBlur_to = (e) => {
        setFieldValue([field.value[0], e.target.value || null]);
    };

    const handleOnSelect = (index) => {
        //setFieldValue(e['option']['value']);
        let newOptions = options.slice(0);
        newOptions[index].active = !newOptions[index].active;
        setOptions(newOptions.slice(0));
        setFieldValue(
            newOptions
                .filter((option) => option.active)
                .map((option) => option.value)
        );
    };

    const FetchField = async () => {
        return await brCtx.fetchField({
            uid: field.id,
            fieldsetuid: fieldset.id,
        });
    };

    const getSelectOptions = (force = false) => {
        if (!field) {
            return [];
        }

        let withouttopicOption = {
            id: '1',
            value: null,
            label: 'Empty',
            active: false,
            attributes: null,
        };

        if (
            field.attributes &&
            field.attributes.referType === 'recordsets_categorizer'
        ) {
            let opts = [];

            brCtx.recordsets.map((r) => {
                opts.push({
                    id: r.id,
                    value: r.id,
                    label: r.label || '<empty>',
                    active: false,
                    attributes:
                        (r.attributes && r.attributes.cellstyle) || null,
                });
            });

            opts.unshift({ ...withouttopicOption });

            setOptions(opts);
            return;

            // return [];
        }

        if (brCtx.select_fields && brCtx.select_fields[field.id] && !force) {
            let opts = [...(brCtx.select_fields[field.id].options || [])];
            if (opts.length > 0) {
                opts.unshift({ ...withouttopicOption });
            }
            setOptions([...opts]);
            return;
        } else {
            /** fetch */
            FetchField().then((res) => {
                if (res.status === 200) {
                    var optionlist = [];

                    var optionsets = res.data.field.others.options;

                    optionsets &&
                        Array.isArray(optionsets) &&
                        optionsets.map((optset, x) => {
                            optset &&
                                optset.records &&
                                Array.isArray(optset.records) &&
                                optset.records.map((rec, y) => {
                                    optionlist.push({
                                        id: rec.uid,
                                        parentId: optset.uid,
                                        attributes: rec.attributes,
                                        value: rec.uid,
                                        label:
                                            (rec.cell && rec.cell.value) ||
                                            '<empty>',
                                    });
                                });
                        });

                    let opts = optionlist.slice(0);
                    if (opts.length > 0) {
                        opts.unshift({ ...withouttopicOption });
                    }
                    setOptions(opts);

                    var newoptions = {};

                    newoptions[field.id] = {
                        options: [...optionlist],
                    };
                    brCtx.setBasic({
                        select_fields: {
                            ...brCtx.select_fields,
                            ...newoptions,
                        },
                    });
                }
            });
        }
    };

    const getValueCore = () => {
        return field.value;
        //return (cell && cell.value) || (field && field.value) || null;
    };

    const getValue = () => {
        var v = getValueCore();

        var finalVa = v;

        return finalVa;
    };

    if (field.type === 'select') {
        return options.length > 0 ? (
            <>
                <div key={key} className="--filter-field">
                    <div className="--filter-field-label">
                        <Text value={label || (field && field.label) || null} />
                    </div>

                    <SelectMultiple
                        onChange={handleOnSelect}
                        reset={reset}
                        options={options}
                    />
                </div>
            </>
        ) : (
            <></>
        );
    }

    const handleCheckBoxFieldChange = (e) => {
        setFieldValue(e.option.value);
        /*var new_val = getValue() === 'true' ? 'false' : 'true';
        setFieldValue(new_val);*/
    };

    if (field.type === 'checkbox') {
        return (
            <>
                <div
                    key={key}
                    className="--filter-field --filter-field-inline --filter-field-inline-auto">
                    <span className="--filter-field-label">
                        <Text value={label || (field && field.label) || null} />
                    </span>

                    {/*<Button
                        onClick={handleCheckboxToggle}
                        className={`--checkbox-default ${
                            getValue() === 'true' ? 'on' : 'off'
                        }`}
                        type={'icon'}></Button>*/}
                    <div className="select-box-conatiner">
                        <SelectBox
                            ref={booleanSelectFieldRef}
                            onChange={(e) => {
                                handleCheckBoxFieldChange(e);
                            }}
                            options={checkboxFieldOptions}
                            triggerStyle={{
                                width: '100%',
                            }}
                            value={checkBoxValue}
                            defaultValue={
                                checkboxFieldOptions.length > 0
                                    ? checkboxFieldOptions[0].value
                                    : null
                            }
                        />
                    </div>
                </div>
            </>
        );
    }

    if (field.type === 'integer') {
        return (
            <>
                <div
                    key={key}
                    className="--filter-field --filter-field-inline --filter-field-inline-auto">
                    <div className="--filter-field-label">
                        <Text value={label || (field && field.label) || null} />
                    </div>

                    <div className="--filter-field-range">
                        <Input
                            onBlur={handleOnBlur_from}
                            type={FieldTypeMap[(field && field.type) || null]}
                            placeholder={'From'}
                            value={fromValue}
                            readOnly={field.automatic_value}
                            disabled={field.automatic_value}
                            onChange={(e) => {
                                setFromValue(e.target.value);
                            }}
                        />
                        <Input
                            onBlur={handleOnBlur_to}
                            type={FieldTypeMap[(field && field.type) || null]}
                            placeholder={'To'}
                            value={toValue}
                            readOnly={field.automatic_value}
                            disabled={field.automatic_value}
                            onChange={(e) => {
                                setToValue(e.target.value);
                            }}
                        />
                    </div>
                </div>
            </>
        );
    }

    if (field.type === 'string') {
        return (
            <>
                <div key={key} className="--filter-field">
                    <div className="--filter-field-label">
                        <Text value={label || (field && field.label) || null} />
                    </div>

                    <Input
                        ref={inputRef}
                        onBlur={(e) => {
                            handleOnBlur({
                                target: {
                                    value: e.target.innerText,
                                },
                            });
                        }}
                        type={'span'}
                        value={getValue()}
                        key={`${field.id}-`}
                        placeholder={'Value'}
                        readOnly={field.automatic_value}
                        disabled={field.automatic_value}
                    />
                </div>
            </>
        );
    }

    return (
        <>
            <div key={key} className="--filter-field">
                <div className="--filter-field-label">
                    <Text value={label || (field && field.label) || null} />
                </div>

                <Input
                    onBlur={handleOnBlur}
                    type={FieldTypeMap[(field && field.type) || null]}
                    key={`${field.id}-`}
                    placeholder={'Value'}
                    readOnly={field.automatic_value}
                    disabled={field.automatic_value}
                />
            </div>
        </>
    );
};

export const Filter = ({ reset, setReset }) => {
    const brCtx = useBarelayout();
    const [filters, setFilters] = useState([]);
    const [isFilterActive, setIsFilterActive] = useState(false);

    useEffect(() => {
        return () => {
            brCtx.setBasic({
                selectedFilters: [],
            });
        };
    }, []);

    useEffect(() => {
        if (reset === 0) {
            return;
        }
        resetFilters();
        setReset(0);
    }, [reset]);

    useEffect(() => {
        resetFilters();
    }, [brCtx.fieldsets]);

    useEffect(() => {
        let selectedFilters = filters.filter((elm) => {
            if (elm.type === 'integer') {
                return elm.value[0] !== null || elm.value[1] !== null;
            }
            if (elm.type === 'checkbox') {
                return elm.value === 1 || elm.value === 0;
            }
            if (elm.type === 'select') {
                return elm.value.length > 0;
            }
            return elm.value !== null;
        });

        if (selectedFilters.length > 0) {
            if (!isFilterActive) {
                setIsFilterActive(true);
            }
        } else if (isFilterActive) {
            setIsFilterActive(false);
        }
        brCtx.setBasic({
            selectedFilters: selectedFilters.slice(0),
        });
    }, [filters]);

    const resetFilters = () => {
        let filtersList = [];
        brCtx.fieldsets &&
            brCtx.fieldsets[brCtx.current_table.fieldsetIdx] &&
            brCtx.fieldsets[brCtx.current_table.fieldsetIdx]['fields'].map(
                (field, i) => {
                    if (field['attributes'] && field['attributes']['hidden']) {
                        return null;
                    }
                    if (field.type === 'integer') {
                        filtersList.push({
                            ...field,
                            value: [null, null],
                            isSet: false,
                        });
                        return null;
                    }
                    if (field.type === 'select') {
                        filtersList.push({
                            ...field,
                            value: [],
                            isSet: false,
                        });
                        return null;
                    }
                    filtersList.push({
                        ...field,
                        value: null,
                        isSet: false,
                    });
                    return null;
                }
            );
        setFilters(filtersList.slice(0));
    };

    return (
        <>
            {filters.map((field, i) => {
                if (field['attributes'] && field['attributes']['hidden']) {
                    return null;
                }
                if (
                    brCtx.current_table.fieldsetIdx ===
                    fieldsetsIdx.InboxsFieldset
                        ? []
                        : ['topic', 'publish', 'views'].indexOf(
                              (field.label || '').toLowerCase()
                          ) < 0
                ) {
                    return null;
                }
                return (
                    <Field
                        key={i + '-' + field.id}
                        field={field}
                        fieldset={
                            brCtx.fieldsets[brCtx.current_table.fieldsetIdx]
                        }
                        setFieldValue={(v) => {
                            setFilters((oldFiltersList) => {
                                let newFiltersList = oldFiltersList.slice(0);
                                newFiltersList[i] = {
                                    ...newFiltersList[i],
                                    value: v,
                                    isSet: v !== null,
                                };
                                return newFiltersList.slice(0);
                            });
                        }}
                        label={
                            field.label === 'PUBLISH'
                                ? 'online'
                                : field.label.toLowerCase()
                        }
                        reset={reset}
                    />
                );
            })}
        </>
    );
};

const SelectMultiple = ({ options, onChange, reset }) => {
    const [showAll, setShowAll] = useState(false);

    useEffect(() => {
        if (reset === 1) {
            setShowAll(false);
        }
    }, [reset]);

    return (
        <div>
            {options.length > 0 ? (
                <>
                    {options.map((option, i) => {
                        if (showAll || i < 3) {
                            return (
                                <div
                                    className={`filter-select-multi-options-item ${
                                        option.active ? '--active' : ''
                                    }`}
                                    style={option.value===null?{opacity:.8}:{}}
                                    onClick={() => {
                                        onChange(i);
                                    }}>
                                    <Symbol
                                        className={'--multi-option-icon'}
                                        color={
                                            option.active
                                                ? '#3C3C3C'
                                                : '#6E6E6E'
                                        }
                                        name={
                                            option.active
                                                ? 'checkboxon'
                                                : 'checkboxoff'
                                        }
                                    />
                                    <Text
                                        className={'--multi-option-label'}
                                        value={option.label}
                                    />
                                </div>
                            );
                        }
                        return <></>;
                    })}
                    {options.length > 3 && (
                        <div className={'filter-select-show-more-options'}>
                            <Text
                                value={showAll ? 'Less' : 'More..'}
                                title={
                                    showAll
                                        ? 'Show less options'
                                        : 'Show more options'
                                }
                                onClick={() => {
                                    setShowAll((v) => !v);
                                }}
                            />
                        </div>
                    )}
                </>
            ) : (
                <></>
            )}
        </div>
    );
};
