export const Enter = ({ className = null, color = undefined }) => {
    return (
        <svg
            className={'ui-sym path' + (className ? ' ' + className : '')}
            width="19"
            viewBox="0 0 19 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M7.91602 16.6253L9.04414 15.4972L6.19414 12.667H15.041V3.16699H13.4577V11.0837H6.19414L9.04414 8.23366L7.93581 7.10553L3.16602 11.8753L7.91602 16.6253Z"
                style={{ fill: color }}
                className="app-icon-fill"
            />
        </svg>
    );
};
