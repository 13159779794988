import { useEffect, useRef, useState } from 'react';
import Page from '../../components/Page';
import Container from '../../layouts/mainlayout/Container';
import MainLayout from '../../layouts/mainlayout/MainLayout';
import Button from '../../components/Button';

export default function ContactPage() {
    const [pageSrc, setPageSrc] = useState('');
    const [iframeHeight, setIframeHeight] = useState(0);
    const [loading, setLoading] = useState(true);
    /*useEffect(() => {
        let container = containerRef.current;
        if (!container) {
            return;
        }
        let script = document.createElement('script');
        script.type = 'text/javascript';
        script.async = true;
        script.src = 'https://cdn.deftform.com/embed.js';
        container.appendChild(script);
        return () => {
            try {
                container.removeChild(script);
            } catch (err) {}
        };
    }, []);*/
    useEffect(() => {
        setLoading(true);
        setIframeHeight(0);
        let documentContent = `<html><head></head><body style="padding:0;margin:0;">
        <div
            class="deftform"
            data-form-id="ac721397-b2eb-409b-ba5b-c5f2c6fe987e"
            data-form-width="100%"
            data-form-height="800"
            data-form-auto-height="1"></div>
        <script src="https://cdn.deftform.com/embed.js"></script>
        </body></html>`;
        let documentContentBlob = new Blob([documentContent], {
            type: 'text/html',
        });
        let documentContentBlobUrl = URL.createObjectURL(documentContentBlob);
        setPageSrc(documentContentBlobUrl);
    }, []);
    return (
        <Page title="FAQNation - Contact us">
            <MainLayout>
                <section style={{ marginBottom: '100px', marginTop: '80px' }}>
                    <h3>Contact us</h3>
                    <Container>
                        {loading && (
                            <div
                                style={{
                                    textAlign: 'center',
                                    margin: '10px 0',
                                }}>
                                <Button loading={loading}></Button>
                            </div>
                        )}
                        <iframe
                            onLoad={(evnt) => {
                                let iframe = evnt.target;
                                let iframeBody =
                                    iframe.contentWindow.document.body;
                                let interval = setInterval(() => {
                                    if (
                                        iframe.offsetHeight !==
                                        iframeBody.offsetHeight
                                    ) {
                                        setIframeHeight(
                                            iframeBody.offsetHeight
                                        );
                                        clearInterval(interval);
                                        setLoading(false);
                                    }
                                }, 200);
                            }}
                            title="widget"
                            src={pageSrc}
                            style={{
                                width: '100%',
                                border: 'none',
                                outline: 'none',
                                height: iframeHeight + 'px',
                            }}></iframe>
                    </Container>
                </section>
            </MainLayout>
        </Page>
    );
}
