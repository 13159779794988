export default function LinkIcon({ className = null, color = undefined }) {
    return (
        <svg
            className={'ui-sym path' + (className ? ' ' + className : '')}
            width="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <rect
                x="2"
                y="5.30078"
                width="14"
                height="7.4"
                rx="2.9"
                style={{ fill: color }}
                className="app-icon-fill-2"
            />
            <path
                d="M8.2425 12.7369H5.2454C4.2089 12.7369 3.32538 12.3716 2.59484 11.6411C1.8643 10.9105 1.49902 10.027 1.49902 8.99052C1.49902 7.95402 1.8643 7.0705 2.59484 6.33996C3.32538 5.60941 4.2089 5.24414 5.2454 5.24414H8.2425V6.74269H5.2454C4.621 6.74269 4.09027 6.96123 3.65319 7.39831C3.21611 7.83538 2.99757 8.36612 2.99757 8.99052C2.99757 9.61491 3.21611 10.1456 3.65319 10.5827C4.09027 11.0198 4.621 11.2383 5.2454 11.2383H8.2425V12.7369ZM5.99468 9.73979V8.24124H11.9889V9.73979H5.99468ZM9.74105 12.7369V11.2383H12.7382C13.3625 11.2383 13.8933 11.0198 14.3304 10.5827C14.7674 10.1456 14.986 9.61491 14.986 8.99052C14.986 8.36612 14.7674 7.83538 14.3304 7.39831C13.8933 6.96123 13.3625 6.74269 12.7382 6.74269H9.74105V5.24414H12.7382C13.7747 5.24414 14.6582 5.60941 15.3887 6.33996C16.1193 7.0705 16.4845 7.95402 16.4845 8.99052C16.4845 10.027 16.1193 10.9105 15.3887 11.6411C14.6582 12.3716 13.7747 12.7369 12.7382 12.7369H9.74105Z"
                style={{ fill: color }}
                className="app-icon-fill"
            />
        </svg>
    );
}
