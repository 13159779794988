export const ExpandIcon = ({ className = null, color = undefined }) => {
    return (
        <svg
            className={'ui-sym path' + (className ? ' ' + className : '')}
            width="15"
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M7.5 13.75L3.75 10L4.64063 9.10938L7.5 11.9688L10.3594 9.10938L11.25 10L7.5 13.75ZM4.65625 5.875L3.75 5L7.5 1.25L11.25 5L10.3438 5.875L7.5 3.03125L4.65625 5.875Z"
                style={{ fill: color }}
                className="app-icon-fill"
            />
        </svg>
    );
};
