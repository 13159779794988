import { TimelineView } from './timeline';
import { GridView } from './grid';

const FieldTypeMap = {
    integer: 'number',
    date: 'date',
    string: 'text',
    checkbox: 'checkbox',
    link: 'url',
};

export { TimelineView, GridView, FieldTypeMap };
