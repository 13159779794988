export default function ColorsIcon({ className = null, color = undefined }) {
    return (
        <svg
            className={'ui-sym path' + (className ? ' ' + className : '')}
            width="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M7.5 5L12.2631 10.25H2.73686L7.5 5Z"
                style={{ fill: color }}
                className="app-icon-fill-2"
            />
            <path
                d="M6.48073 15.3596L1.87268 10.7516C1.74781 10.6267 1.65415 10.4894 1.59171 10.3395C1.52927 10.1896 1.49805 10.0335 1.49805 9.8712C1.49805 9.70886 1.52927 9.55276 1.59171 9.4029C1.65415 9.25305 1.74781 9.11568 1.87268 8.9908L6.18102 4.7012L4.19544 2.71562L5.35681 1.49805L12.8496 8.9908C12.9744 9.11568 13.065 9.25305 13.1212 9.4029C13.1774 9.55276 13.2055 9.70886 13.2055 9.8712C13.2055 10.0335 13.1774 10.1896 13.1212 10.3395C13.065 10.4894 12.9744 10.6267 12.8496 10.7516L8.24152 15.3596C8.11665 15.4845 7.97928 15.5782 7.82942 15.6406C7.67957 15.7031 7.52347 15.7343 7.36113 15.7343C7.19878 15.7343 7.04268 15.7031 6.89283 15.6406C6.74297 15.5782 6.60561 15.4845 6.48073 15.3596ZM7.36113 5.88131L3.3525 9.88993H11.3697L7.36113 5.88131ZM14.8351 15.7343C14.3856 15.7343 14.0047 15.5751 13.6925 15.2566C13.3803 14.9382 13.2242 14.5479 13.2242 14.0859C13.2242 13.7487 13.3085 13.4303 13.4771 13.1305C13.6457 12.8308 13.8361 12.5374 14.0484 12.2501L14.8351 11.2386L15.6594 12.2501C15.8592 12.5374 16.0465 12.8308 16.2213 13.1305C16.3961 13.4303 16.4836 13.7487 16.4836 14.0859C16.4836 14.5479 16.3212 14.9382 15.9965 15.2566C15.6718 15.5751 15.2847 15.7343 14.8351 15.7343Z"
                style={{ fill: color }}
                className="app-icon-fill"
            />
        </svg>
    );
}
