import Container from '../mainlayout/Container';
import lgHeaderWhiteImage from '../../assets/images/lg-header-white.svg';
import './footer.scss';
import { useRef } from 'react';
import { Link } from 'react-router-dom';

export default function Footer() {
    const links = useRef([
        {
            label: 'About',
            links: [
                { label: 'Jobs', target: '/jobs' },
                { label: 'Why FAQ Nation?', target: '/why-faqnation' },
                { label: 'Features', target: '/features' },
                { label: 'Plans', target: '/plans' },
            ],
        },
        {
            label: 'Legal',
            links: [
                { label: 'Privacy', target: '/privacy' },
                { label: 'Terms of use', target: '/terms' },
                { label: 'GDPR', target: '/gdpr' },
                { label: 'Imprint', target: '/imprint' },
            ],
        },
        {
            label: 'Connect',
            links: [
                { label: 'Updates', target: 'https://changelog.faqnation.com' },
                { label: 'Events', target: '/events' },
                /*{ label: 'PressKit', target: './' },
                { label: 'Blog', target: './' },*/
            ],
        },
        {
            label: 'Help',
            links: [
                { label: 'API', target: '/api' },
                { label: 'F.A.Q.', target: '/faq' },
                { label: 'Support', target: 'https://support.faqnation.com' },
                { label: 'Email', target: '/contact' },
            ],
        },
    ]);

    return (
        <footer className="main-footer_style2">
            <Container className={'main-footer-container'}>
                <div className="main-footer-info-section">
                    <span className={`main-footer-website-logo-container`}>
                        <Link
                            to={'/home'}
                            className={`main-footer-website-logo`}>
                            <img src={lgHeaderWhiteImage} alt="" />
                        </Link>
                    </span>
                    <div>
                        <p>
                            FAQ Nation is a FAQ management tool that is operated
                            and hosted entirely within the EU. Our focus is
                            streamlining FAQ management through the use of AI.
                        </p>
                        <div className='main-footer-badges-container'>
                            <img
                                src="/static/images/ccpa.png"
                                width="54"
                                alt=""
                            />
                            <img
                                src="/static/images/gdpr.png"
                                width="54"
                                alt=""
                            />
                            <img
                                src="/static/images/ssl.png"
                                width="54"
                                alt=""
                            />
                        </div>
                    </div>
                </div>
                <div>
                    <div className={'main-footer-links-container'}>
                        {links.current.map((linksList, i) => {
                            return (
                                <nav
                                    className="main-footer-list"
                                    key={`main-footer-list-${i}`}>
                                    <h4 className={'main-footer-list-title'}>
                                        {linksList.label}
                                    </h4>
                                    <ul>
                                        {linksList.links.map((link, n) => {
                                            return (
                                                <li
                                                    key={`main-footer-list-item-${i}${n}`}>
                                                    <Link to={link.target}>
                                                        {link.label}
                                                    </Link>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </nav>
                            );
                        })}
                    </div>
                    <div className={'main-footer-section2-container'}>
                        <span className="main-footer-copyright">
                            {`Copyright © ${new Date().getFullYear()} FAQ Nation. All rights reserved.`}
                        </span>
                    </div>
                </div>
            </Container>
        </footer>
    );
}
