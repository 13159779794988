import React, { useEffect } from 'react';
import useLocale from './hooks/useLocale';
import Router from './routes';

export default function App() {
    const localeCtx = useLocale();

    useEffect(() => {
        localeCtx.setLocale(localeCtx.AvailableLocale.de);
    }, [localeCtx.Initialized]);

    return (
        <>
            {/*authCtx.initialized && settingCtx.initialized ?*/ <Router /> /*: <></>*/}
        </>
    );
}
