import { useEffect, useState } from 'react';
import useDatabase from '../../hooks/useDatabase';
import PopupMenu from '../Popup';
import './share-faqs-popup.scss';
import useAuth from '../../hooks/useAuth';
import { Text } from '../Text';
import Button from '../Button';
import { Symbol } from '../elements/Icon';
import { copy_text } from '../../functions/Utilities';
import { projectSettingsPanel } from '../../panels/projectSettingsPanel/ProjectSettingsPanel';
import { Switch } from '../../panels/right/accountOptionsPanel/accountOptionsPanel';

export function ShareFAQsPopup({ isOpen, onClose }) {
    const dbCtx = useDatabase();
    const authCtx = useAuth();

    const [helpPageUrl, setHelpPageUrl] = useState('');

    useEffect(() => {
        setHelpPageUrl(
            'https://' +
                ((authCtx.account.isPro
                    ? dbCtx.subdomain && dbCtx.subdomain.custom_domain
                    : null) ||
                    (dbCtx.subdomain && dbCtx.subdomain.name) +
                        '.faqnation.com')
        );
    }, [dbCtx.subdomain, authCtx]);

    const handleCheckboxToggle = async (new_val) => {
        dbCtx.setBasic({
            settings: {
                ...(dbCtx.settings || {}),
                apiEnabled: new_val,
            },
        });
        try {
            const res = await dbCtx.updateDatabase({
                apiEnabled: new_val,
                noReload: true,
            });
            if (res.status !== 200) {
                dbCtx.setBasic({
                    settings: {
                        ...(dbCtx.settings || {}),
                        apiEnabled: !new_val,
                    },
                });
            }
        } catch (err) {
            dbCtx.setBasic({
                settings: {
                    ...(dbCtx.settings || {}),
                    apiEnabled: !new_val,
                },
            });
        }
    };

    return (
        <PopupMenu className={'share-faqs'} open={isOpen} onClose={onClose}>
            <div>
                <div className={'toggle-share-faqs-container'}>
                    <Switch
                        label={'Make Page public'}
                        value={dbCtx.settings && dbCtx.settings.apiEnabled}
                        onUpdate={handleCheckboxToggle}
                        id={'toggle-faq-page--sp'}
                    />
                </div>
                <div
                    className={`shareFAQsPopupContent ${
                        dbCtx.settings && dbCtx.settings.apiEnabled
                            ? ''
                            : 'disabled'
                    }`}>
                    <div className="share-faqs-link-container">
                        <Text value={helpPageUrl} />
                        <Button
                            onClick={() => {
                                window.open(helpPageUrl);
                            }}>
                            <Symbol name="open_link" />
                        </Button>
                        <Button
                            onClick={() => {
                                onClose();
                                projectSettingsPanel.open('Embed');
                            }}>
                            <Symbol name="code" />
                        </Button>
                        <Button
                            onClick={() => {
                                copy_text(helpPageUrl);
                            }}>
                            <Symbol name="content_copy" />
                        </Button>
                    </div>
                    <div style={{ textAlign: 'center', marginBottom: '20px' }}>
                        <ShareWith
                            title={`${dbCtx.database.label || ''}`}
                            url={helpPageUrl}
                        />
                    </div>
                </div>
            </div>
        </PopupMenu>
    );
}

function ShareWith({ url, title }) {
    return (
        <div className={'shareWithList'}>
            <span
                onClick={() => {
                    window.open(
                        `https://wa.me?text= ${encodeURI(
                            'Check out ' + title + ' at ' + url
                        )}`
                    );
                }}>
                <svg
                    width="40"
                    height="40"
                    viewBox="0 0 41 41"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <circle cx="20.0977" cy="20.1885" r="20" fill="#4BCF46" />
                    <path
                        d="M28.9459 11.2279C26.583 8.85935 23.4362 7.55664 20.092 7.55664C13.1894 7.55664 7.57248 13.1735 7.57248 20.0762C7.57248 22.2812 8.1477 24.4355 9.24175 26.3359L7.46533 32.8213L14.1029 31.0787C15.9301 32.0769 17.9885 32.6014 20.0864 32.6014H20.092C26.989 32.6014 32.73 26.9845 32.73 20.0818C32.73 16.7376 31.3088 13.5965 28.9459 11.2279ZM20.092 30.4922C18.2197 30.4922 16.3869 29.9903 14.791 29.0429L14.4131 28.8173L10.4768 29.8493L11.5257 26.0089L11.2776 25.6141C10.2343 23.9561 9.68727 22.0443 9.68727 20.0762C9.68727 14.3409 14.3567 9.67143 20.0977 9.67143C22.8779 9.67143 25.489 10.7542 27.4515 12.7224C29.414 14.6905 30.6208 17.3016 30.6152 20.0818C30.6152 25.8228 25.8273 30.4922 20.092 30.4922ZM25.7991 22.6985C25.489 22.5406 23.9494 21.7849 23.6618 21.6834C23.3742 21.5763 23.1655 21.5255 22.9568 21.8413C22.7482 22.1571 22.1504 22.8564 21.9643 23.0707C21.7838 23.2794 21.5977 23.3076 21.2876 23.1497C19.4491 22.2304 18.2423 21.5086 17.0298 19.4276C16.7084 18.875 17.3513 18.9145 17.949 17.7189C18.0505 17.5102 17.9998 17.3298 17.9208 17.1719C17.8419 17.014 17.2159 15.4744 16.9565 14.8484C16.7027 14.2394 16.4433 14.324 16.2516 14.3127C16.0711 14.3014 15.8624 14.3014 15.6538 14.3014C15.4451 14.3014 15.1068 14.3804 14.8191 14.6905C14.5315 15.0063 13.7251 15.762 13.7251 17.3016C13.7251 18.8411 14.8473 20.33 14.9996 20.5386C15.1575 20.7473 17.2046 23.9053 20.3458 25.2645C22.3309 26.1216 23.1091 26.195 24.1017 26.0483C24.7051 25.9581 25.9514 25.2926 26.2108 24.5595C26.4702 23.8264 26.4702 23.2004 26.3913 23.0707C26.3179 22.9297 26.1093 22.8508 25.7991 22.6985Z"
                        fill="white"
                    />
                </svg>
            </span>
            <span
                onClick={() => {
                    window.open(
                        `https://t.me/share/url?url= ${encodeURI(
                            url
                        )}&text=${encodeURI(title)}`
                    );
                }}>
                <svg
                    width="40"
                    height="40"
                    viewBox="0 0 41 41"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <circle cx="20.5" cy="20.5" r="20.5" fill="#2EA5D8" />
                    <path
                        d="M31.3466 13.4929L27.6044 31.1408C27.3221 32.3863 26.5859 32.6963 25.5396 32.1095L19.8378 27.9079L17.0865 30.554C16.7821 30.8584 16.5274 31.1131 15.9406 31.1131L16.3503 25.3061L26.918 15.757C27.3775 15.3473 26.8184 15.1204 26.2039 15.53L13.1396 23.7561L7.51527 21.9957C6.29187 21.6138 6.26973 20.7723 7.76991 20.1856L29.7689 11.7104C30.7875 11.3284 31.6787 11.9373 31.3466 13.4929Z"
                        fill="white"
                    />
                </svg>
            </span>
            <span
                onClick={() => {
                    window.open(
                        `https://www.facebook.com/sharer/sharer.php?u=${encodeURI(
                            url
                        )}&href=${encodeURI(url)}&t=${encodeURI(title)}`
                    );
                }}>
                <svg
                    width="41"
                    height="41"
                    viewBox="0 0 41 41"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M29.6516 6.80078H11.8733V38.487H16.5153L15.9043 39.5754L17.5394 40.6779C19.9548 40.8776 21.3278 40.8719 23.795 40.6779L24.2348 38.487H29.6516V6.80078Z"
                        fill="white"
                    />
                    <path
                        d="M40.6763 20.8779C40.6763 9.80093 31.7247 0.826172 20.6763 0.826172C9.62788 0.826172 0.67627 9.80093 0.67627 20.8779C0.67627 30.8859 7.98998 39.1815 17.5513 40.687V26.6743H12.4706V20.8779H17.5513V16.46C17.5513 11.435 20.5351 8.65927 25.1053 8.65927C27.294 8.65927 29.5827 9.0506 29.5827 9.0506V13.9827H27.0601C24.5763 13.9827 23.8013 15.5286 23.8013 17.1141V20.8779H29.348L28.4609 26.6743H23.8013V40.687C33.3626 39.1815 40.6763 30.8859 40.6763 20.8779Z"
                        fill="#1877F2"
                    />
                </svg>
            </span>
            <span
                onClick={() => {
                    window.open(
                        `https://twitter.com/share?url=${encodeURI(
                            url
                        )}&text=${encodeURI(title)}`
                    );
                }}>
                <svg
                    width="40"
                    height="40"
                    viewBox="0 0 41 41"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <circle cx="20.0488" cy="20.499" r="20" fill="black" />
                    <path
                        d="M30.0036 30.2373L22.2178 18.8866L22.2311 18.8972L29.2511 10.7617H26.9052L21.1865 17.3834L16.6451 10.7617H10.4926L17.7614 21.3591L17.7605 21.3582L10.0942 30.2373H12.4402L18.7981 22.8702L23.8511 30.2373H30.0036ZM15.7156 12.5322L26.6396 28.4668H24.7806L13.8477 12.5322H15.7156Z"
                        fill="white"
                    />
                </svg>
            </span>
            <span
                onClick={() => {
                    window.open(
                        `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURI(
                            url
                        )}&title=${encodeURI(title)}&source=''`
                    );
                }}>
                <svg
                    width="40"
                    height="40"
                    viewBox="0 0 44 44"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <circle cx="22" cy="22" r="22" fill="#1178B3" />
                    <path
                        d="M15.391 34.6223H10.3928V18.5268H15.391V34.6223ZM12.8892 16.3313C11.291 16.3313 9.99463 15.0075 9.99463 13.4092C9.99463 12.6415 10.2996 11.9053 10.8424 11.3625C11.3853 10.8196 12.1215 10.5146 12.8892 10.5146C13.6569 10.5146 14.3932 10.8196 14.936 11.3625C15.4788 11.9053 15.7838 12.6415 15.7838 13.4092C15.7838 15.0075 14.4869 16.3313 12.8892 16.3313ZM34.0974 34.6223H29.11V26.7871C29.11 24.9198 29.0724 22.5251 26.5114 22.5251C23.9128 22.5251 23.5146 24.5539 23.5146 26.6526V34.6223H18.5218V18.5268H23.3155V20.7224H23.3854C24.0527 19.4578 25.6827 18.1232 28.1145 18.1232C33.1729 18.1232 34.1028 21.4543 34.1028 25.7808V34.6223H34.0974Z"
                        fill="white"
                    />
                </svg>
            </span>
            <span
                onClick={() => {
                    window.open(
                        `http://www.reddit.com/submit?url= ${encodeURI(
                            url
                        )}&title=${encodeURI(title)}`
                    );
                }}>
                <svg
                    width="41"
                    height="41"
                    viewBox="0 0 41 41"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <circle cx="20.5" cy="20.5" r="20.5" fill="#FC471E" />
                    <path
                        d="M31.6034 16.8964C30.716 16.8964 29.9351 17.2632 29.3612 17.837C27.2491 16.3757 24.4034 15.4351 21.2501 15.3345L22.8889 7.95105L28.107 9.12246C28.107 10.4004 29.1482 11.4416 30.4261 11.4416C31.7277 11.4416 32.7749 10.3708 32.7749 9.09288C32.7749 7.81498 31.7336 6.74414 30.4261 6.74414C29.515 6.74414 28.7282 7.29435 28.3377 8.04571L22.5753 6.76781C22.2854 6.69089 22.0014 6.89796 21.9245 7.18786L20.126 15.3286C16.9963 15.4587 14.1802 16.3994 12.0622 17.8607C11.4883 17.2632 10.6778 16.8964 9.79037 16.8964C6.50096 16.8964 5.4242 21.3099 8.43556 22.8185C8.32907 23.2859 8.28174 23.7828 8.28174 24.2798C8.28174 29.2376 13.8666 33.2547 20.7235 33.2547C27.61 33.2547 33.1949 29.2376 33.1949 24.2798C33.1949 23.7828 33.1417 23.2622 33.0115 22.7948C35.9637 21.2803 34.8751 16.8964 31.6034 16.8964ZM13.2099 23.1321C13.2099 21.8305 14.2512 20.7833 15.5587 20.7833C16.8366 20.7833 17.8778 21.8246 17.8778 23.1321C17.8778 24.41 16.8366 25.4512 15.5587 25.4512C14.2571 25.4571 13.2099 24.41 13.2099 23.1321ZM25.8884 28.6637C23.7349 30.8172 17.6589 30.8172 15.5054 28.6637C15.2688 28.4566 15.2688 28.0898 15.5054 27.8532C15.7125 27.6461 16.0793 27.6461 16.2864 27.8532C17.9311 29.5393 23.3858 29.5689 25.1015 27.8532C25.3086 27.6461 25.6754 27.6461 25.8825 27.8532C26.125 28.0898 26.125 28.4566 25.8884 28.6637ZM25.8411 25.4571C24.5632 25.4571 23.5219 24.4159 23.5219 23.138C23.5219 21.8364 24.5632 20.7892 25.8411 20.7892C27.1426 20.7892 28.1898 21.8305 28.1898 23.138C28.1839 24.41 27.1426 25.4571 25.8411 25.4571Z"
                        fill="white"
                    />
                </svg>
            </span>
            <span
                onClick={() => {
                    if (navigator.share) {
                        // Use the default share functionality
                        navigator
                            .share({
                                title: encodeURI(title),
                                text: 'Check out this link!',
                                url: url,
                            })
                            .catch((error) => {
                                document.location.href = `mailto: ?subject=bookmarker.me&body=${url}`;
                            });
                    } else {
                        document.location.href = `mailto: ?subject=bookmarker.me&body=${url}`;
                    }
                }}>
                <svg
                    width="40"
                    height="40"
                    viewBox="0 0 44 44"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <circle cx="22" cy="22" r="22" fill="#36465B" />
                    <path
                        d="M16.7499 32.0413C16.2228 32.0413 15.7716 31.8537 15.3963 31.4783C15.0209 31.103 14.8333 30.6518 14.8333 30.1247V19.583C14.8333 19.0559 15.0209 18.6047 15.3963 18.2294C15.7716 17.854 16.2228 17.6663 16.7499 17.6663H19.6249V19.583H16.7499V30.1247H28.2499V19.583H25.3749V17.6663H28.2499C28.777 17.6663 29.2282 17.854 29.6036 18.2294C29.9789 18.6047 30.1666 19.0559 30.1666 19.583V30.1247C30.1666 30.6518 29.9789 31.103 29.6036 31.4783C29.2282 31.8537 28.777 32.0413 28.2499 32.0413H16.7499ZM21.5416 25.333V14.6236L20.0083 16.157L18.6666 14.7913L22.4999 10.958L26.3333 14.7913L24.9916 16.157L23.4583 14.6236V25.333H21.5416Z"
                        fill="white"
                    />
                </svg>
            </span>
        </div>
    );
}
