export function ArrowUp({ className = null, color = undefined }) {
    return (
        <svg
            className={'ui-sym path' + (className ? ' ' + className : '')}
            width="21"
            height="21"
            viewBox="0 0 71 72"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M31.425 24.3832L19.825 35.9832C19.0917 36.7165 18.1583 37.0832 17.025 37.0832C15.8917 37.0832 14.9583 36.7165 14.225 35.9832C13.4917 35.2499 13.125 34.3165 13.125 33.1832C13.125 32.0499 13.4917 31.1165 14.225 30.3832L32.625 11.9832C33.425 11.1832 34.3583 10.7832 35.425 10.7832C36.4917 10.7832 37.425 11.1832 38.225 11.9832L56.625 30.3832C57.3583 31.1165 57.725 32.0499 57.725 33.1832C57.725 34.3165 57.3583 35.2499 56.625 35.9832C55.8917 36.7165 54.9583 37.0832 53.825 37.0832C52.6917 37.0832 51.7583 36.7165 51.025 35.9832L39.425 24.3832V57.1832C39.425 58.3165 39.0417 59.2665 38.275 60.0332C37.5083 60.7999 36.5583 61.1832 35.425 61.1832C34.2917 61.1832 33.3417 60.7999 32.575 60.0332C31.8083 59.2665 31.425 58.3165 31.425 57.1832V24.3832Z"
                style={{ fill: color }}
                className="app-icon-fill"
            />
        </svg>
    );
}
