import { useEffect, useRef, useState } from 'react';
import './accountoptionspanel.scss';
import useSetting from '../../../hooks/useSetting';
import Button from '../../../components/Button';
import { Symbol } from '../../../components/elements/Icon';
import { Text } from '../../../components/Text';
import useAuth from '../../../hooks/useAuth';
import Plans from '../../../components/Popup/Plans';
import useAlias from '../../../hooks/useAlias';
import PopupMenu, { SelectBox } from '../../../components/Popup';
import Input from '../../../components/Input';
import avatarIcon from '../../../assets/images/avatar.svg';
import { callApi } from '../../../functions/api';
import { apiRoutes } from '../../../helpers/api';
import { useNavigate } from 'react-router-dom';
import { showError, showInfo } from '../../../components/towsts/Toasts';
import { createPortal } from 'react-dom';
import { popupsManager } from '../../../components/PopupsManager/PopupsManager';

export const AccountOptionsPanel = () => {
    const visible_ = useRef(false);
    const rightAccountOptionsPanelWrapper = useRef(null);

    const settingCtx = useSetting();

    const [visible, setVisible] = useState(false);

    useEffect(() => {
        visible_.current = settingCtx.right_account_options_panel.visible;
        if (visible !== settingCtx.right_account_options_panel.visible) {
            setVisible(settingCtx.right_account_options_panel.visible);
        }
    }, [settingCtx.right_account_options_panel.visible]);

    useEffect(() => {
        let target;
        let clickTimeout;
        const clickListener = (e) => {
            target = e.target;
            try {
                clearTimeout(clickTimeout);
            } catch (err) {}
            clickTimeout = setTimeout(() => {
                window.removeEventListener('mouseup', listener);
            }, 300);
            window.addEventListener('mouseup', listener);
        };
        const listener = (e) => {
            try {
                clearTimeout(clickTimeout);
                window.removeEventListener('mouseup', listener);
            } catch (e) {}
            if (!rightAccountOptionsPanelWrapper.current) {
                return;
            }
            let parent = target;
            while (
                parent !== document.body &&
                parent !== document.body.parentElement
            ) {
                if (
                    !parent ||
                    (parent.classList &&
                        parent.classList.contains('--right-panel-wrapper')) ||
                    parent.classList.contains('popups-container') ||
                    parent.id === 'hero-popup-layer'
                ) {
                    return;
                }
                parent = parent.parentElement;
            }
            if (visible_.current) hideRightAccountOptionsPanel();
        };
        document.addEventListener('mousedown', clickListener);
        return () => {
            document.removeEventListener('mousedown', clickListener);
            window.removeEventListener('mouseup', listener);
        };
    }, []);

    const hideRightAccountOptionsPanel = () => {
        settingCtx.setBasic({
            right_account_options_panel: {
                visible: false,
            },
        });
    };

    return (
        <>
            <div
                ref={rightAccountOptionsPanelWrapper}
                style={{ width: '240px' }}
                className={
                    '--right-panel-wrapper' +
                    ' --account-options-panel ' +
                    (visible ? ' --visible' : ' --no-visible')
                }>
                <div className="panel-right">
                    <div className={'--inner scroll-container'}>
                        <div className="--section --align-end">
                            <EditAccount />
                            <Separator />
                            <EditSettings />
                            <Separator />
                            {/* <UpgradeToPro /> */}
                            {/* <OpenTutorials /> */}
                            <OpenSupport />
                            <OpenUpdates />
                            <OpenLeaveFeedback />

                            <Separator />
                            <LogoutBtn />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

const Separator = () => {
    return <div className="--panel-items-separator"></div>;
};

const PanelWrapper = ({ children }) => {
    return <div className="--panel-wrapper">{children}</div>;
};

const PanelItemWrapper = ({ handleClick = () => {}, children }) => {
    return (
        <PanelWrapper>
            <Button onClick={handleClick} className={'--panel-item'}>
                {children}
            </Button>
        </PanelWrapper>
    );
};

const PanelItem = ({ iconName, label, handleClick = () => {} }) => {
    return (
        <PanelItemWrapper handleClick={handleClick}>
            <div className="--icon">
                <Symbol name={iconName} />
            </div>
            <Text value={label} />
        </PanelItemWrapper>
    );
};

const EditAccount = () => {
    const authCtx = useAuth();
    const [editAccountPopupIsOprn, setEditAccountPopupIsOprn] = useState(false);
    const [userAvatar, setUserAvatar] = useState('');
    useEffect(() => {
        setUserAvatar(
            authCtx.account &&
                authCtx.account.profile &&
                authCtx.account.profile.profilePicture
        );
    }, [authCtx]);
    return (
        <>
            <PanelItemWrapper
                handleClick={() => {
                    setEditAccountPopupIsOprn(true);
                }}>
                <div
                    className="--user-avatar"
                    style={{
                        backgroundImage: `url(${userAvatar || avatarIcon})`,
                    }}></div>
                <Text value={'Account'} />
            </PanelItemWrapper>
            <EditAccountPopup
                isOpen={editAccountPopupIsOprn}
                setIsOpen={setEditAccountPopupIsOprn}
            />
        </>
    );
};

const EditSettings = () => {
    const [editSettingsPopupIsOprn, setEditSettingsPopupIsOprn] =
        useState(false);
    return (
        <>
            <PanelItem
                iconName={'gear_icon'}
                label={'Settings'}
                handleClick={() => {
                    setEditSettingsPopupIsOprn(true);
                }}
            />
            <EditSettingsPopup
                isOpen={editSettingsPopupIsOprn}
                setIsOpen={setEditSettingsPopupIsOprn}
            />
        </>
    );
};

const LogoutBtn = () => {
    const authCtx = useAuth();
    const aliasCtx = useAlias();

    const handleSignoutClick = (e) => {
        authCtx.removeAliasLogin({
            uid: aliasCtx.alias.uid,
        });
        authCtx.removeLoginCookies({
            redirectToMain: true,
        });
    };

    return (
        <PanelItem
            iconName={'logout'}
            label={'Logout'}
            handleClick={handleSignoutClick}
        />
    );
};

const SetDateFormat = () => {
    const authCtx = useAuth();
    const selectboxref = useRef(null);
    const options = useRef([
        'YYYY-MM-DD',
        'YYYY.MM.DD',
        'YYYY/D/M',
        'DD.MM.YY',
        'DD.MM.YYYY',
        'DD/MM/YYYY',
        'D/M/YY',
        'M/D/YY',
    ]);

    return (
        <PanelWrapper>
            <Text value="Date Format"></Text>
            <SelectBox
                ref={selectboxref}
                className={'--select-style-2'}
                value={options.current.indexOf(authCtx.account.date_format)}
                options={options.current.map((elm, i) => {
                    return { id: elm + i, value: elm, label: elm };
                })}
                onChange={(e) => {
                    let newValue = e.option.value;
                    if (newValue === authCtx.account.date_format) {
                        return;
                    }
                    authCtx
                        .updateAccount({ date_format: newValue })
                        .then((res) => {})
                        .catch((err) => {
                            showError('Error changing Date format settings!');
                        });
                }}
            />
        </PanelWrapper>
    );
};

const SetTimeFormat = () => {
    const authCtx = useAuth();
    const selectboxref = useRef(null);
    const options = useRef(['24h', '12h']);

    return (
        <PanelWrapper>
            <Text value="Time Format"></Text>
            <SelectBox
                ref={selectboxref}
                className={'--select-style-2'}
                value={options.current.indexOf(authCtx.account.time_format)}
                options={options.current.map((elm, i) => {
                    return { id: elm + i, value: elm, label: elm };
                })}
                onChange={(e) => {
                    let newValue = e.option.value;
                    if (newValue === authCtx.account.time_format) {
                        return;
                    }

                    authCtx
                        .updateAccount({ time_format: newValue })
                        .then((res) => {})
                        .catch((err) => {
                            showError('Error changing Time format settings!');
                        });
                }}
            />
        </PanelWrapper>
    );
};

const UpgradeToPro = () => {
    const authCtx = useAuth();
    const [plansPopupOpen, setPlansPopupOpen] = useState(false);

    return (
        <>
            <PanelItem
                iconName={'star'}
                handleClick={() => {
                    if (authCtx.account.isPro) {
                        document.location.href =
                            process.env.REACT_APP_STRIPE_SUBSCRIPTION_MANAGER_URL;
                    } else {
                        setPlansPopupOpen(true);
                    }
                }}
                label={
                    authCtx.account.isPro
                        ? 'Manage subscription'
                        : 'Upgrade to PRO'
                }></PanelItem>
            <Plans
                open={plansPopupOpen}
                onOpen={() => {
                    setPlansPopupOpen(true);
                }}
                onClose={() => {
                    setPlansPopupOpen(false);
                }}
            />
        </>
    );
};

/*const OpenTutorials = () => {
    return (
        <PanelItem
            iconName={'school'}
            label={'Tutorials'}
            handleClick={() => {
                document.location.href = './#';
            }}
        />
    );
};*/

const OpenSupport = () => {
    return (
        <PanelItem
            iconName={'support'}
            label={'Support'}
            handleClick={() => {
                document.location.href = 'https://support.faqnation.com';
            }}
        />
    );
};

const OpenUpdates = () => {
    return (
        <PanelItem
            iconName={'history'}
            label={'Updates'}
            handleClick={() => {
                document.location.href = 'https://changelog.faqnation.com';
            }}
        />
    );
};

const OpenLeaveFeedback = () => {
    const [sendFeedbackPopupIsOpen, setSendFeedbackPopupIsOpen] =
        useState(false);

    return (
        <>
            <PanelItem
                iconName={'message'}
                label={'Leave Feedback'}
                handleClick={() => {
                    setSendFeedbackPopupIsOpen(true);
                }}
            />
            <SendFeedbackPopup
                isOpen={sendFeedbackPopupIsOpen}
                setIsOpen={setSendFeedbackPopupIsOpen}
            />
        </>
    );
};

const SendFeedbackPopup = ({ isOpen, setIsOpen }) => {
    const [pageSrc, setPageSrc] = useState('');
    const [iframeHeight, setIframeHeight] = useState(0);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        setIframeHeight(0);
        let documentContent = `<html><head></head><body style="padding:0;margin:0;">
        <div
            class="deftform"
            data-form-id="7e508129-bb5f-48f8-a573-d5143e14c0eb"
            data-form-width="100%"
            data-form-height="800"
            data-form-auto-height="1"></div>
        <script src="https://cdn.deftform.com/embed.js"></script>
        </body></html>`;
        let documentContentBlob = new Blob([documentContent], {
            type: 'text/html',
        });
        let documentContentBlobUrl = URL.createObjectURL(documentContentBlob);
        setPageSrc(documentContentBlobUrl);
    }, []);
    return (
        <>
            <PopupMenu
                className="send-feedback-popup"
                open={isOpen}
                onClose={() => {
                    setIsOpen(false);
                }}>
                <div className="send-feedback-popup-header">
                    <Button
                        className="send-feedback-popup-close"
                        onClick={() => {
                            setIsOpen(false);
                        }}
                        type="icon">
                        <Symbol name="close" />
                    </Button>
                </div>
                {loading && (
                    <div style={{ textAlign: 'center', margin: '10px 0' }}>
                        <Button loading={loading}></Button>
                    </div>
                )}
                <iframe
                    className="send-feedback-popup-content-iframe"
                    onLoad={(evnt) => {
                        let iframe = evnt.target;
                        let iframeBody = iframe.contentWindow.document.body;
                        let interval = setInterval(() => {
                            if (
                                iframe.offsetHeight !== iframeBody.offsetHeight
                            ) {
                                setIframeHeight(iframeBody.offsetHeight);
                                clearInterval(interval);
                                setLoading(false);
                            }
                        }, 200);
                    }}
                    title="widget"
                    src={pageSrc}
                    style={{
                        height: iframeHeight + 'px',
                    }}></iframe>
            </PopupMenu>
        </>
    );
};

const UpdateUserAvatar = () => {
    const authCtx = useAuth();
    const [userAvatar, setUserAvatar] = useState('');
    useEffect(() => {
        setUserAvatar(
            authCtx.account &&
                authCtx.account.profile &&
                authCtx.account.profile.profilePicture
        );
    }, [authCtx]);
    return (
        <div className="edit-user-avatar-container">
            <label htmlFor="update-user-avatar">
                <div
                    className="edit-user-avatar"
                    style={{
                        backgroundImage: `url(${userAvatar || avatarIcon})`,
                    }}></div>
            </label>
            <input
                style={{ display: 'none' }}
                type="file"
                id="update-user-avatar"
                accept="image/png"
            />
        </div>
    );
};
const UpdateUserName = () => {
    const authCtx = useAuth();
    const [userName, setUserName] = useState('');
    const [initialized, setInitialized] = useState(false);
    useEffect(() => {
        if (initialized) {
            return;
        }
        setInitialized(true);
        let userNameFromSurvey = JSON.parse(
            (authCtx.account && authCtx.account.survey) || '{}'
        ).name;
        let userName =
            authCtx.account &&
            authCtx.account.profile &&
            authCtx.account.profile.firstName;
        setUserName(userName || userNameFromSurvey);
    }, [authCtx]);

    function updateUserName(newName) {
        authCtx.updateAccount({ name: newName });
    }
    return (
        <Input
            className="edit-user-account-popup-input"
            label="Name"
            type="text"
            placeholder="Name"
            value={userName}
            onChange={(evnt) => {
                setUserName(evnt.target.value);
            }}
            onBlur={(evnt) => {
                let currentUserName =
                    authCtx.account &&
                    authCtx.account.profile &&
                    authCtx.account.profile.firstName;
                if (evnt.target.value.trim()) {
                    setUserName(evnt.target.value);
                    updateUserName(evnt.target.value);
                } else {
                    setUserName(currentUserName);
                }
            }}
        />
    );
};
const UpdateUserEmail = () => {
    const newUserEmail = useRef(null);
    const userPassword = useRef(null);
    const authCtx = useAuth();
    const [userEmail, setUserEmail] = useState('');
    const [initialized, setInitialized] = useState(false);
    const [loading, setLoading] = useState(false);
    const [editEmailPopupVisible, setEditEmailPopupVisible] = useState(false);

    useEffect(() => {
        if (initialized) {
            return;
        }
        setInitialized(true);
        setUserEmail(authCtx.account && authCtx.account.email);
    }, [authCtx]);

    async function updateUserEmail() {
        setLoading(true);
        try {
            let newEmail = newUserEmail.current.value;
            let password = userPassword.current.value;
            if (newEmail === authCtx.account.email) {
                showError('Email is the same as the current email!');
                setLoading(false);
                return;
            }
            let res = await callApi({
                path: apiRoutes.account.changeEmail,
                method: 'patch',
                payload: {
                    email: newEmail,
                    password: password,
                },
            });
            if (res.status === 200) {
                showInfo('Email updated successfully!');
                authCtx.setBasic({
                    account: { ...authCtx.account, email: newEmail },
                });
                setUserEmail(newEmail);
                setEditEmailPopupVisible(false);
            } else if (res.status === 400) {
                switch (res.data.message) {
                    case 'invalid_email':
                        showError(` \`${newEmail}\` is not a valid email!`);
                        break;
                    case 'no_change':
                        showError('Email is the same as the current email!');
                        break;
                    case 'email_already_found':
                        showError('Email already exists!');
                        break;
                    case 'invalid_password':
                        showError('Password is not correct, please try again!');
                        break;
                    default:
                        showError(
                            "Something went wrong, we couldn't update your email!"
                        );
                }
            } else {
                showError(
                    "Something went wrong, we couldn't update your email!"
                );
            }
        } catch (err) {}
        setLoading(false);
    }

    return (
        <>
            <div className="edit-user-account-popup-input-container">
                <Input
                    className="edit-user-account-popup-input"
                    label={'Email'}
                    type={'email'}
                    autoComplete={'email'}
                    autocapitalize={'none'}
                    spellcheck={'false'}
                    placeholder={'Email'}
                    value={userEmail || ''}
                    disabled={true}
                />
                <Button
                    className={'edit-user-account-email-btn'}
                    onClick={() => {
                        setEditEmailPopupVisible(true);
                    }}>
                    <Symbol name="mode" />
                </Button>
            </div>
            {editEmailPopupVisible &&
                createPortal(
                    <PopupMenu
                        className={'edit-email-popup'}
                        open={editEmailPopupVisible}
                        onClose={() => {
                            setEditEmailPopupVisible(false);
                        }}
                        contentStyle={{ maxWidth: '375px' }}
                        closeOnDocumentClick={false}>
                        <form
                            autocomplete="off"
                            onSubmit={(evnt) => {
                                evnt.preventDefault();
                                updateUserEmail();
                            }}
                            style={{
                                width: 'calc(100% - 40px)',
                                margin: '20px auto',
                            }}>
                            <Input
                                ref={newUserEmail}
                                type={'email'}
                                label={'New Email'}
                                placeholder={'New Email'}
                                autocomplete={'off'}
                                autocapitalize={'off'}
                                spellcheck={'false'}
                                autofocus={'true'}
                                required
                            />
                            <input
                                /*this input element is just to prevent the stupid browser from auto filling the email (and it may not work:(*/
                                type={'email'}
                                style={{ position: 'fixed', top: '-100vh' }}
                                tabIndex={-1}
                            />
                            <Input
                                ref={userPassword}
                                type={'password'}
                                label={'Your password'}
                                placeholder={'Your password'}
                                autocomplete={'off'}
                                autocapitalize={'off'}
                                spellcheck={'false'}
                                required
                            />
                            <div style={{ direction: 'rtl' }}>
                                <Button
                                    isSubmit={true}
                                    type={'primary'}
                                    loading={loading}>
                                    <Text value={'Update'} />
                                </Button>
                                <Button
                                    isSubmit={false}
                                    disabled={loading}
                                    type={'secondary'}
                                    onClick={() => {
                                        setEditEmailPopupVisible(false);
                                    }}
                                    style={{ marginRight: '12px' }}>
                                    <Text value={'Cancel'} />
                                </Button>
                            </div>
                        </form>
                    </PopupMenu>,
                    document.getElementById('hero-popup-layer') || document.body
                )}
        </>
    );
};

const ToggleNewsLetter = () => {
    const updateTimeout = useRef(null);
    const authCtx = useAuth();
    const [value, setValue] = useState(true);
    const [initialized, setInitialized] = useState(false);
    useEffect(() => {
        if (initialized) {
            return;
        }
        setInitialized(true);
        setValue(
            authCtx.account &&
                authCtx.account.profile &&
                authCtx.account.profile.newsletter
        );
    }, [authCtx]);
    function onUpdate(newsletterValue) {
        setValue(newsletterValue);
        try {
            clearTimeout(updateTimeout.current);
        } catch (err) {}
        authCtx.setBasic({
            account: {
                ...authCtx.account,
                profile: {
                    ...authCtx.account.profile,
                    newsletter: newsletterValue,
                },
            },
        });
        updateTimeout.current = setTimeout(() => {
            authCtx.updateAccount({ newsletter: newsletterValue });
        }, 1000);
    }
    return (
        <Switch
            value={value}
            onUpdate={onUpdate}
            label={'Newsletter'}
            id={'toggle-newsletter'}
        />
    );
};
const ToggleUserQuestions = () => {
    const updateTimeout = useRef(null);
    const authCtx = useAuth();
    const [value, setValue] = useState(true);
    const [initialized, setInitialized] = useState(false);
    useEffect(() => {
        if (initialized) {
            return;
        }
        setInitialized(true);
        setValue(
            authCtx.account &&
                authCtx.account.profile &&
                authCtx.account.profile.user_questions
        );
    }, [authCtx]);
    function onUpdate(user_questions_value) {
        setValue(user_questions_value);
        try {
            clearTimeout(updateTimeout.current);
        } catch (err) {}
        authCtx.setBasic({
            account: {
                ...authCtx.account,
                profile: {
                    ...authCtx.account.profile,
                    user_questions: user_questions_value,
                },
            },
        });
        updateTimeout.current = setTimeout(() => {
            authCtx.updateAccount({ user_questions: user_questions_value });
        }, 1000);
    }
    return (
        <Switch
            value={value}
            onUpdate={onUpdate}
            label={'User Questions'}
            id={'toggle-user-questions'}
        />
    );
};

const RemoveAccount = () => {
    const authCtx = useAuth();
    const aliasCtx = useAlias();
    const Navigate = useNavigate();

    async function removeAccount() {
        const res = await callApi({
            method: 'delete',
            path: apiRoutes.auth.removeAccount,
        });
        if (res.status === 200) {
            authCtx.removeAliasLogin({
                uid: aliasCtx.alias.uid,
            });
            authCtx.removeLoginCookies({
                redirectToMain: true,
            });
            Navigate('/home');
        } else {
            showError('Error, please try again!');
        }
    }

    return (
        <Button
            onClick={() => {
                popupsManager.confirm({
                    msg: 'Are you sure?',
                    onConfirm: removeAccount,
                    danger: true,
                });
            }}
            className={'remove-account-btn'}>
            {'Remove Account'}
        </Button>
    );
};

export const Switch = ({ value, onUpdate, label, id }) => {
    return (
        <>
            <label
                htmlFor={id}
                className={`switch-container ${
                    label ? 'switch-with-label' : ''
                }`}>
                {label && <Text className={'switch-label'} value={label} />}
                <div className={'switch' + (value ? ' on' : ' off')}></div>
            </label>
            <input
                id={id}
                value={value}
                onChange={(e) => {
                    console.log(e.target.checked);
                    onUpdate(e.target.checked);
                }}
                type={'checkbox'}
                checked={value}
                style={{ display: 'none' }}
            />
        </>
    );
};

const EditAccountPopup = ({ isOpen, setIsOpen }) => {
    return (
        <>
            <PopupMenu
                className={'edit-account-popup'}
                open={isOpen}
                onClose={() => {
                    setIsOpen(false);
                }}>
                <Button
                    className="icon close-popup"
                    onClick={() => {
                        setIsOpen(false);
                    }}>
                    <Symbol name="close" />
                </Button>
                <div className="edit-account-popup-content-container">
                    <UpdateUserAvatar />
                    <div className={'edit-user-account-popup-option-container'}>
                        <UpdateUserName />
                    </div>
                    <div className={'edit-user-account-popup-option-container'}>
                        <UpdateUserEmail />
                    </div>
                    <div className={'edit-user-account-popup-option-container'}>
                        <h3>{'Email settings'}</h3>
                        <div
                            className={
                                'edit-user-account-popup-option-container'
                            }>
                            <ToggleNewsLetter />
                        </div>
                        <div
                            className={
                                'edit-user-account-popup-option-container'
                            }>
                            <ToggleUserQuestions />
                        </div>
                    </div>
                    <hr />
                    <div
                        style={{ textAlign: 'center' }}
                        className={'edit-user-account-popup-option-container'}>
                        <RemoveAccount />
                    </div>
                </div>
            </PopupMenu>
        </>
    );
};
const EditSettingsPopup = ({ isOpen, setIsOpen }) => {
    return (
        <>
            <PopupMenu
                className={'edit-settings-popup'}
                open={isOpen}
                onClose={() => {
                    setIsOpen(false);
                }}>
                <Button
                    className="icon close-popup"
                    onClick={() => {
                        setIsOpen(false);
                    }}>
                    <Symbol name="close" />
                </Button>
                <div className="edit-settings-popup-content-container">
                    <div className={'edit-settings-popup-option-container'}>
                        <SetDateFormat />
                    </div>
                    <div className={'edit-settings-popup-option-container'}>
                        <SetTimeFormat />
                    </div>
                </div>
            </PopupMenu>
        </>
    );
};
