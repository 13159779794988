import React, { useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import PopupMenu, { PopupButton, PositionTypes } from '../../components/Popup';
import Button from '../../components/Button';
import useDatabase from '../../hooks/useDatabase';
import useAlias from '../../hooks/useAlias';
import useSetting from '../../hooks/useSetting';
import { DbIcon, GDialogTabs } from '../dialog/main';
import { Symbol } from '../../components/elements/Icon';
import { Text } from '../../components/Text';
import useBarelayout from '../../layouts/barelayout/hook';
import { VerticalSortableList } from '../../components/vertical-sortable-list/VerticalSortableList';
import useQuestionsNotifs from '../../hooks/useQuestionsNotifs';

export const DatabasesList = ({ databases }) => {
    const navigate = useNavigate();

    const popupRef = useRef(null);

    const dbCtx = useDatabase();
    const brCtx = useBarelayout();
    const aliasCtx = useAlias();
    const settingCtx = useSetting();
    const dbQuestionsCtx = useQuestionsNotifs();
    const [deleteActive, setDeleteActive] = useState(false);
    const [dbCtxMenuVisibility, setDbCtxMenuVisibility] = useState(
        (databases || []).map((database, x) => false)
    );

    const handleOnItemClick = (e) => {
        // console.log(e);
        // e.preventDefault();
        settingCtx.setRightPanel({
            visible: false,
        });
        brCtx.setBasic({
            active_cell: {},
            selected_records: {},
            selectedFilters: [],
            sortRulesList: [
                {
                    field: {
                        automatic_value: false,
                        id: 'date_of_creation',
                        label: 'Creation Date',
                        type: 'date',
                        value: null,
                        width: { normal: 310 },
                    },
                    rule: 'ascending',
                },
            ],
            searchOptions: {
                query: null,
                fields: [],
            },
        });
        brCtx.openFAQs();
    };

    const handleOnEditClick = (database) => {
        settingCtx.setGDialog({
            visible: true,
            active: GDialogTabs.database,
            data: {
                label: database.label,
                icon: database.icon,
                theme: database.theme,
                uid: database.uid,
                mode: 'edit',
            },
        });

        if (popupRef.current) {
            popupRef.current.close();
        }
    };

    const handleOnDeleteClick = (database, index) => {
        if (deleteActive) {
            setDbCtxMenuVisibility((oldV) => {
                let newV = oldV.slice(0);
                newV[index] = false;
                return newV.slice(0);
            });
            aliasCtx
                .deleteDatabase({
                    uid: database.uid,
                })
                .then(() => {
                    if (dbCtx.database && dbCtx.database.uid === database.uid) {
                        let dbId = '';
                        for (
                            let i = 0;
                            i < (aliasCtx.databases || []).length;
                            i++
                        ) {
                            if (
                                aliasCtx.databases[i] &&
                                aliasCtx.databases[i].uid !== database.uid
                            ) {
                                dbId = aliasCtx.databases[i].uid;
                                break;
                            }
                        }
                        navigate('/' + aliasCtx.alias.alias + '/' + dbId);
                    }
                    //window.location.reload();
                });

            if (popupRef.current) {
                popupRef.current.close();
            }
        } else {
            setDeleteActive(true);
        }
    };

    return (
        <>
            <VerticalSortableList
                autoHideDragHandle={true}
                onDragEnd={(info) => {
                    /*handle drag and*/
                    aliasCtx.updateDatabase({
                        uid: databases[info.from].uid,
                        index: info.to + 1,
                    });
                }}>
                {databases &&
                    databases.map((database, x) => {
                        const notificationsCount=Math.min(99,dbQuestionsCtx.state.databaseQuestionsCount[database.uid] || 0)
                        return (
                            <React.Fragment
                                key={database.uid + '-' + database.index}>
                                {database.attributes &&
                                    database.attributes['seprators_up'] && (
                                        <div className="--db-seprator"></div>
                                    )}

                                <div
                                    onContextMenu={(evnt) => {
                                        evnt.preventDefault();
                                        setDbCtxMenuVisibility((oldV) => {
                                            let newV = oldV.slice(0);
                                            newV[x] = true;
                                            return newV.slice(0);
                                        });
                                    }}
                                    className={
                                        '--item-wrapper ' +
                                        (dbCtx.database &&
                                        dbCtx.database.uid === database.uid
                                            ? ' --active'
                                            : '' || '')
                                    }>
                                    <Link
                                        reloadDocument={false}
                                        replace={false}
                                        onClick={handleOnItemClick}
                                        key={database.uid}
                                        to={`/${aliasCtx.alias.alias}/${database.uid}`}>
                                        <div className={'--item --db p-8'}>
                                            <div
                                                className={`--item-color ${notificationsCount===0?'--no-notifications':''}`}
                                                style={{
                                                    //background: database.theme,
                                                    height: 50,
                                                }} data-notifications={notificationsCount}>
                                                <DbIcon
                                                    className="--item-icon"
                                                    size={'20px'}
                                                    name={database.icon}
                                                />
                                            </div>
                                            <div
                                                className={'--item-text pl-12'}>
                                                <Text value={database.label} />
                                            </div>
                                        </div>
                                    </Link>
                                    <PopupMenu
                                        ref={popupRef}
                                        offsetX={0}
                                        offsetY={0}
                                        contentStyle={{
                                            width: 170,
                                        }}
                                        open={dbCtxMenuVisibility[x]}
                                        onClose={() => {
                                            setDeleteActive(false);
                                            setDbCtxMenuVisibility((oldV) => {
                                                let newV = oldV.slice(0);
                                                newV[x] = false;
                                                return newV.slice(0);
                                            });
                                        }}
                                        onOpen={() => {
                                            setDbCtxMenuVisibility((oldV) => {
                                                let newV = oldV.slice(0);
                                                newV[x] = true;
                                                return newV.slice(0);
                                            });
                                        }}
                                        trigger={
                                            <Button
                                                className={'--more-btn'}
                                                type={'icon'}>
                                                <Symbol name="more_horiz" />
                                            </Button>
                                        }
                                        position={PositionTypes.BottomCenter}>
                                        <PopupButton
                                            icon={'mode'}
                                            label={'Edit'}
                                            onClick={() => {
                                                setDbCtxMenuVisibility(
                                                    (oldV) => {
                                                        let newV =
                                                            oldV.slice(0);
                                                        newV[x] = false;
                                                        return newV.slice(0);
                                                    }
                                                );
                                                handleOnEditClick(database);
                                            }}
                                        />

                                        <PopupButton
                                            icon={'delete'}
                                            label={
                                                deleteActive ? (
                                                    <span
                                                        style={{
                                                            color: 'red',
                                                        }}>
                                                        Are you sure?
                                                    </span>
                                                ) : (
                                                    'Remove'
                                                )
                                            }
                                            iconColor={
                                                deleteActive ? '#f00' : null
                                            }
                                            onClick={() => {
                                                handleOnDeleteClick(
                                                    database,
                                                    x
                                                );
                                            }}
                                        />
                                    </PopupMenu>
                                </div>

                                {database.attributes &&
                                    database.attributes['seprators_down'] && (
                                        <div className="--db-seprator"></div>
                                    )}
                            </React.Fragment>
                        );
                    })}
            </VerticalSortableList>
        </>
    );
};
