import React, { useEffect, useRef, useState } from 'react';
import { Resizable } from 'react-quick-resizable';
import Button from '../../../components/Button';
import { Symbol } from '../../../components/elements/Icon';
import { Text } from '../../../components/Text';
import useSetting from '../../../hooks/useSetting';
import useBarelayout from '../../../layouts/barelayout/hook';
import useDatabase from '../../../hooks/useDatabase';
import RecordviewTab, { Field } from '../recordview';
import './faq-editor-panel.scss';
import { RightPanelTabs } from '../main';
import useAuth from '../../../hooks/useAuth';

export const rightPanelViewStyles = {
    DEFAULT: 'fixed_right',
    POPUP: 'popup',
};

export default function FaqEditorPanel({ onResize = () => {} }) {
    const brCtx = useBarelayout();
    const authCtx = useAuth();
    const settingCtx = useSetting();

    const rightPanelWrapper = useRef(null);
    const isRightPanelVisible = useRef(settingCtx.right_panel.visible);
    const rightPanelViewStyleRef = useRef(settingCtx.right_panel.view_style);

    const [visible, setVisible] = useState(null);

    const [deleteActive, setDeleteActive] = useState(false);

    const panelViewStyle = settingCtx.right_panel.force_view_style
        ? settingCtx.right_panel.force_view_style
        : settingCtx.right_panel.view_style;

    useEffect(() => {
        setDeleteActive(false);
    }, [brCtx.active_cell]);

    useEffect(() => {
        setVisible(settingCtx.right_panel.visible);
    }, [settingCtx.right_panel.visible]);

    useEffect(() => {
        if (settingCtx.right_panel.view_style === null) {
            /*initialize right panel view style from account settings*/
            settingCtx.setBasic({
                right_panel: {
                    ...settingCtx.right_panel,
                    view_style: authCtx.account.recordEditorPosition,
                },
            });
        }
        isRightPanelVisible.current = settingCtx.right_panel.visible;
        rightPanelViewStyleRef.current = authCtx.account.recordEditorPosition;
    }, [settingCtx.right_panel]);

    useEffect(() => {
        let target;
        let clickTimeout;
        const clickListener = (e) => {
            target = e.target;
            try {
                clearTimeout(clickTimeout);
            } catch (err) {}
            clickTimeout = setTimeout(() => {
                window.removeEventListener('mouseup', listener);
            }, 300);
            window.addEventListener('mouseup', listener);
        };
        const listener = (e) => {
            try {
                clearTimeout(clickTimeout);
                window.removeEventListener('mouseup', listener);
            } catch (e) {}
            if (!isRightPanelVisible.current) {
                return;
            }
            let parent = target;
            while (
                parent !== document.body &&
                parent !== document.body.parentElement
            ) {
                if (
                    !parent ||
                    (parent.classList &&
                        (parent.classList.contains('--right-panel-wrapper') ||
                            parent.classList.contains('--grid-cell') ||
                            parent.classList.contains('popups-container') ||
                            parent.id === 'hero-popup-layer' ||
                            parent.classList.contains('popup-content') ||
                            parent.classList.contains(
                                '--grid-recordset-record'
                            )))
                ) {
                    return;
                }
                parent = parent.parentElement;
            }
            if (isRightPanelVisible.current) hideRightPanel();
        };
        document.addEventListener('mousedown', clickListener);
        return () => {
            document.removeEventListener('mousedown', clickListener);
            window.removeEventListener('mouseup', listener);
        };
    }, []);

    const hideRightPanel = (e) => {
        setDeleteActive(false);
        settingCtx.setRightPanel({
            visible: false,
            force_view_style: null,
        });
        brCtx.setBasic({
            active_cell: {},
        });
    };

    if (!brCtx.fieldsets || !brCtx.recordsets || !brCtx.fieldsets[1]) {
        return <></>;
    }

    return (
        <>
            {panelViewStyle === rightPanelViewStyles.POPUP && (
                <div
                    className={`--faq-editor-popup-view-overlay ${
                        visible ? '--visible' : ''
                    }`}></div>
            )}
            <Resizable
                ref={rightPanelWrapper}
                defaultWidth={400}
                // defaultHeight={'100%'}
                // height={'100%'}
                directions={
                    panelViewStyle === rightPanelViewStyles.DEFAULT
                        ? { left: true }
                        : {}
                }
                className={`--right-panel-wrapper ${
                    visible ? '--visible' : '--no-visible'
                } ${
                    panelViewStyle === rightPanelViewStyles.POPUP
                        ? '--faq-editor-popup-view'
                        : ''
                }`}>
                <div className="panel-right">
                    <div
                        className={
                            '--inner scroll-container --faq-editor-panel-container'
                        }>
                        <div className="--section --align-start --fixed-to-top">
                            <div className="--tabs-container">
                                {/*{brCtx.active_cell && (*/}
                                {/*    <>*/}
                                {/*        <Button*/}
                                {/*            onClick={() => {*/}
                                {/*                if (!deleteActive) {*/}
                                {/*                    setDeleteActive(true);*/}
                                {/*                    return;*/}
                                {/*                }*/}
                                {/*                let recordsetId =*/}
                                {/*                    brCtx.recordsets[*/}
                                {/*                        brCtx.active_cell*/}
                                {/*                            .recordsetidx*/}
                                {/*                    ].id;*/}
                                {/*                let recordId =*/}
                                {/*                    brCtx.active_cell.recordid;*/}
                                {/*                hideRightPanel();*/}
                                {/*                (async () => {*/}
                                {/*                    let b =*/}
                                {/*                        await brCtx.deleteRecord(*/}
                                {/*                            {*/}
                                {/*                                recordsetuid:*/}
                                {/*                                    recordsetId,*/}
                                {/*                                uid: recordId,*/}
                                {/*                            }*/}
                                {/*                        );*/}
                                {/*                })();*/}
                                {/*            }}>*/}
                                {/*            <Symbol*/}
                                {/*                color={*/}
                                {/*                    deleteActive ? 'red' : ''*/}
                                {/*                }*/}
                                {/*                color2={*/}
                                {/*                    deleteActive*/}
                                {/*                        ? 'transparent'*/}
                                {/*                        : ''*/}
                                {/*                }*/}
                                {/*                name="delete_alt1"*/}
                                {/*                className={'mr-8'}*/}
                                {/*            />*/}
                                {/*            <Text*/}
                                {/*                style={{*/}
                                {/*                    color: deleteActive*/}
                                {/*                        ? 'red'*/}
                                {/*                        : '',*/}
                                {/*                }}*/}
                                {/*                value={*/}
                                {/*                    deleteActive*/}
                                {/*                        ? 'Are you sure?'*/}
                                {/*                        : 'Remove'*/}
                                {/*                }*/}
                                {/*            />*/}
                                {/*        </Button>*/}
                                {/*        <TogglePublic />*/}
                                {/*        <EditFaqInPopup />*/}
                                {/*    </>*/}
                                {/*)}*/}


                                {/*<Button*/}
                                {/*    className={'--tab with-bg'}*/}
                                {/*    type={'icon'}*/}
                                {/*    style={{*/}
                                {/*        marginLeft: '8px',*/}
                                {/*        marginTop: '-8px',*/}
                                {/*    }}*/}
                                {/*    onClick={hideRightPanel}>*/}
                                {/*    <Symbol name={'close'} />*/}
                                {/*</Button>*/}

                                <Button
                                    className={
                                        'button-icon with-bg close-add-database-popup'
                                    }
                                    // onClick={handleClose}
                                >
                                    <Symbol name={'more_horiz'} />
                                </Button>
                                <Button
                                    className={
                                        'button-icon with-bg'
                                    }
                                    onClick={hideRightPanel}>
                                    <Symbol name={'close'} />
                                </Button>
                            </div>
                        </div>

                        {/* <div className="--section --align-end"></div> */}

                        {brCtx.active_cell && (
                            <>
                                <RecordviewTab />
                            </>
                        )}
                    </div>
                </div>
            </Resizable>
        </>
    );
}

export const TogglePublic = () => {
    const brCtx = useBarelayout();

    const [recordset, setRecordset] = useState(null);
    const [record, setRecord] = useState(null);
    const [cellset, setCellset] = useState(null);

    useEffect(() => {
        if (!brCtx.recordsets) {
            return;
        }
        let recordset = brCtx.recordsets[brCtx.active_cell['recordsetidx']];
        if (!recordset) {
            return;
        }
        let record;
        if (brCtx.recordsets && recordset && recordset.records) {
            record = recordset.records.filter(
                (record) => record.id === brCtx.active_cell['recordid']
            )[0];
        }
        if (!record) {
            return;
        }
        setRecordset(recordset);
        setRecord(record);
        setCellset(record.cellsets[brCtx.fieldsets[1].id] || null);
    }, [brCtx.recordsets, brCtx.fieldsets, brCtx.active_cell]);

    if (!brCtx.fieldsets[1] || !record) {
        return <></>;
    }

    return (
        <span
            style={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'nowrap',
                alignItems: 'center',
            }}>
            {brCtx.fieldsets[1].fields
                .filter((field) => field.label === 'PUBLISH')
                .map((field) => {
                    return (
                        <>
                            <Field
                                key={field.id}
                                field={field}
                                cell={
                                    (cellset && cellset.cells[field.id]) || null
                                }
                                record={record}
                                fieldset={brCtx.fieldsets[1]}
                                recordset={recordset}
                                is_calendar={false}
                                index={-1}
                                showTitle={false}
                                placeholder={''}
                                label={'Online'}
                            />
                            <Text
                                style={{ marginLeft: '-8px' }}
                                value={'Online'}
                            />
                        </>
                    );
                })}
        </span>
    );
};

const EditFaqInPopup = () => {
    const settingCtx = useSetting();

    return (
        <Button
            className={'--tab'}
            type={'icon'}
            style={{
                marginLeft: 'auto',
                marginTop: '-8px',
            }}
            onClick={() => {
                if (
                    settingCtx.right_panel.view_style ===
                    rightPanelViewStyles.DEFAULT
                ) {
                    settingCtx.setRightPanel({
                        view_style: rightPanelViewStyles.POPUP,
                    });
                } else {
                    settingCtx.setRightPanel({
                        view_style: rightPanelViewStyles.DEFAULT,
                    });
                }
            }}>
            <Symbol
                name={
                    settingCtx.right_panel.view_style ===
                    rightPanelViewStyles.DEFAULT
                        ? 'openfull'
                        : 'closefull'
                }
            />
        </Button>
    );
};
const EditFaqInPopupWindow = () => {
    const dbCtx = useDatabase();
    const brCtx = useBarelayout();

    const [recordset, setRecordset] = useState(null);
    const [record, setRecord] = useState(null);

    useEffect(() => {
        if (!brCtx.recordsets) {
            return;
        }
        let recordset = brCtx.recordsets[brCtx.active_cell['recordsetidx']];
        if (!recordset) {
            return;
        }
        let record;
        if (brCtx.recordsets && recordset && recordset.records) {
            record = recordset.records.filter(
                (record) => record.id === brCtx.active_cell['recordid']
            )[0];
        }
        setRecordset(recordset);
        setRecord(record);
    }, [brCtx.recordsets, brCtx.fieldsets, brCtx.active_cell]);

    return (
        <Button
            className={'--tab'}
            type={'icon'}
            style={{
                marginLeft: 'auto',
                marginTop: '-8px',
            }}
            onClick={() => {
                window.open(
                    `/edit/${dbCtx.database.aliasuid}/${dbCtx.database.uid}/${recordset.id}/${record.id}`,
                    'popup',
                    'width=' +
                        Math.min(600, Math.floor(window.innerWidth * 0.6)) +
                        ', height=' +
                        Math.floor(window.innerHeight * 0.9) +
                        ', scrollbars=no, toolbar=no,status=no, resizable=yes, menubar=no, location=no, directories=no '
                );
            }}>
            <Symbol name={'openfull'} />
        </Button>
    );
};
