export const ExportIcon = ({
    className = null,
    color = undefined,
    color2 = undefined,
}) => {
    return (
        <svg
            className={'ui-sym path' + (className ? ' ' + className : '')}
            width="16"
            viewBox="0 0 16 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <rect
                y="0.949219"
                width="16"
                height="16"
                rx="2"
                style={{ fill: color2 }}
                className="app-icon-fill-2"
            />
            <path
                d="M2 16.9492C1.45 16.9492 0.979333 16.7536 0.588 16.3622C0.196 15.9702 0 15.4992 0 14.9492V11.9492H2V14.9492H14V11.9492H16V14.9492C16 15.4992 15.8043 15.9702 15.413 16.3622C15.021 16.7536 14.55 16.9492 14 16.9492H2ZM8 12.9492L3 7.94922L4.4 6.49922L7 9.09922V0.949219H9V9.09922L11.6 6.49922L13 7.94922L8 12.9492Z"
                style={{ fill: color }}
                className="app-icon-fill"
            />
        </svg>
    );
};
