import { QrReader } from 'react-qr-reader';
import Button from '../../components/Button';
import { Symbol } from '../../components/elements/Icon';
import useSetting from '../../hooks/useSetting';
import { AliasViewsList } from '../../pages/alias/homepage';
import { TopPanelTabs } from '../../panels/top';
import useBarelayout from './hook';

const videoConstraints = {
    width: 360,
    height: 640,
    facingMode: 'environment',
};

const BrQrCodeDialog = () => {
    const settingCtx = useSetting();
    const brCtx = useBarelayout();

    const handleScan = (data) => {
        if (data) {
            if (data.text) {
                /** [@todo: trigger 1st cell editing] (maybe optional.) */
                settingCtx.setTopPanel({
                    active: TopPanelTabs.DatabaseSearchTab,
                    qr_query: data.text,
                });
                brCtx.searchCells(data.text);
            }
            handleClose();
        }

        /** @info fake qr result for test */

        // let text = 'ew';
        // setTimeout(() => {

        //     if(settingCtx.view_mode === AliasViewsList.FullControl)
        //     {
        //         settingCtx.setTopPanel({
        //             active: TopPanelTabs.DatabaseFullSearchTab,
        //             qr_query: text
        //         });
        //     }
        //     else if(settingCtx.view_mode === AliasViewsList.SimpleControl)
        //     {
        //         settingCtx.setTopPanel({
        //             active: TopPanelTabs.DatabaseSearchTab,
        //             qr_query: text
        //         });
        //     }
        //     brCtx.searchCells(text);

        //     handleClose();

        // }, 3000);
    };

    const handleClose = (e) => {
        brCtx.setBasic({
            dialog: {
                ...(brCtx.dialog || {}),
                visible: false,
            },
        });
    };

    return (
        <>
            <div className="br-dialog-close">
                <Button
                    onClick={handleClose}
                    type={'icon'}>
                    <Symbol name={'close'} />
                </Button>
            </div>
            <QrReader
                delay={300}
                legacyMode={true}
                style={{
                    width: '100vw',
                    height: '100%',
                }}
                onError={() => {}}
                onResult={(data, error) => handleScan(data)}
                constraints={videoConstraints}
            />
        </>
    );
};

export default BrQrCodeDialog;
