import { useEffect, useState } from 'react';
import Button from '../Button';
import { Symbol } from '../elements/Icon';
import './add-record-btn.scss';
import useDatabase from '../../hooks/useDatabase';
import useSetting from '../../hooks/useSetting';
import useBarelayout from '../../layouts/barelayout/hook';
import { RightPanelTabs } from '../../panels/right/main';
import { recordsetsIdx } from '../../layouts/barelayout/ctx';
import { callApi } from '../../functions/api';
import { rightPanelViewStyles } from '../../panels/right/editFaqPanel/FaqEditorPanel';
import { showError } from '../towsts/Toasts';

export function AddRecordBtn({ onClick }) {
    const dbCtx = useDatabase();
    const brCtx = useBarelayout();
    const settingCtx = useSetting();

    const [addRecordWasTriggered, setAddRecordWasTriggered] = useState({
        recordId: null,
        state: false,
    });
    const [waitingForCreateRecord, setWaitingForCreateRecord] = useState(false);

    useEffect(() => {
        if (brCtx.recordsets && addRecordWasTriggered.state) {
            brCtx.setBasic({
                active_cell: {
                    cellidx: -999,
                    e: null,
                    fieldidx: 2,
                    recordid: addRecordWasTriggered.recordId,
                    recordsetidx: 0,
                    is_calendar: false /** false */,
                },
            });
            settingCtx.setRightPanel({
                visible: true,
                active: RightPanelTabs.Recordview,
                force_view_style: rightPanelViewStyles.POPUP
            });
            setAddRecordWasTriggered({ state: false, recordId: null });
        }
    }, [brCtx.recordsets, addRecordWasTriggered]);

    async function addNewRecord() {
        if (waitingForCreateRecord) {
            return;
        }
        setWaitingForCreateRecord(true);
        let faqTileId;
        let m = 1;
        for (let i = 0; m > 0 && i < dbCtx.fieldsets.length; i++) {
            for (
                let n = 0;
                m > 0 && n < dbCtx.fieldsets[i].fields.length;
                n++
            ) {
                let field = dbCtx.fieldsets[i].fields[n];
                if (field.label === 'FAQ') {
                    faqTileId = field.uid;
                    m--;
                    break;
                }
            }
        }
        let recordValues = {};
        recordValues[faqTileId] = '';
        try {
            let m= callApi({method:'get', path:`pay/upgrade/${dbCtx.database.uid}`})
            let res = await dbCtx.addRecord({
                aliasuid: dbCtx.database.aliasuid,
                dbId: dbCtx.database.uid,
                recordsetuid: dbCtx.recordsets[recordsetsIdx.FAQsRecordset].uid,
                attributes: null,
                values: recordValues,
                noReload: false,
            });
            if (res.status === 201 && res.data.record) {
                setAddRecordWasTriggered({
                    state: true,
                    recordId: res.data.record.uid,
                });
            } else if (res.status === 400) {
                if (res.data.messageCode === 'LIMIT_REACHED') {
                    settingCtx.setUpgradeProjectDialog({
                        visible: true,
                        project_id: dbCtx.database.uid,
                        msg: 'You are currently on a free project, upgrade it and get unlimited Questions!',
                    });
                    /*alert(
                        'Upgrade to the PRO plan to be able to add more than 8 FAQs!'
                    );*/
                }
            } else {
                showError('Error creating a new FAQ, please try again!');
            }
        } catch (err) {}

        setWaitingForCreateRecord(false);
    }
    if (
        brCtx.fieldsets.length !== 4 ||
        brCtx.current_table.recordsetIdx !== recordsetsIdx.FAQsRecordset
    ) {
        return <></>;
    }
    return (
        <Button
            className={`addRecordBtn`}
            loading={waitingForCreateRecord}
            onClick={addNewRecord}>
            <span
                data-hint={"Let's start by adding a question"}
                className={`${
                    brCtx.original_recordsets[recordsetsIdx.FAQsRecordset] &&
                    brCtx.original_recordsets[recordsetsIdx.FAQsRecordset]
                        .records.length === 0
                        ? '--addquestion-hint'
                        : '--addquestion-hint --hidden'
                }`}></span>
            <Symbol name="add" />
        </Button>
    );
}
