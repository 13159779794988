// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.countDown_cell::after {
  content: attr(data-lable);
}`, "",{"version":3,"sources":["webpack://./src/components/count-down/count-down.scss"],"names":[],"mappings":"AACI;EACI,yBAAA;AAAR","sourcesContent":[".countDown_cell {\n    &::after {\n        content: attr(data-lable);\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
