import { useEffect, useRef, useState } from 'react';
import Page from '../../components/Page';
import MainLayout from '../../layouts/mainlayout/MainLayout';
import './featurespage.scss';
import Container from '../../layouts/mainlayout/Container';

export default function FeaturesPage() {
    return (
        <Page title="FAQ Nation - Features">
            <MainLayout>
                <main className="featuresPageContainer">
                    <FeaturesNav />
                    <Features />
                </main>
            </MainLayout>
        </Page>
    );
}

export const FeaturesNav = () => {
    const [features, setFeatures] = useState([
        {
            title: 'Receive Questions',
            description: 'Users can submit questions via contact form.',
            icon: (
                <svg
                    width="39"
                    viewBox="0 0 39 39"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M8.07115 33.8982C7.18335 33.8982 6.42333 33.582 5.79111 32.9498C5.15889 32.3176 4.84277 31.5576 4.84277 30.6698V8.07115C4.84277 7.18335 5.15889 6.42333 5.79111 5.79111C6.42333 5.15889 7.18335 4.84277 8.07115 4.84277H30.6698C31.5576 4.84277 32.3176 5.15889 32.9498 5.79111C33.582 6.42333 33.8982 7.18335 33.8982 8.07115V30.6698C33.8982 31.5576 33.582 32.3176 32.9498 32.9498C32.3176 33.582 31.5576 33.8982 30.6698 33.8982H8.07115ZM8.07115 30.6698H30.6698V25.8272H25.8272C25.0201 26.8495 24.0583 27.6432 22.9419 28.2081C21.8254 28.7731 20.6349 29.0556 19.3705 29.0556C18.106 29.0556 16.9156 28.7731 15.7991 28.2081C14.6826 27.6432 13.7208 26.8495 12.9137 25.8272H8.07115V30.6698ZM19.3705 25.8272C20.3928 25.8272 21.3209 25.5313 22.1549 24.9394C22.9889 24.3475 23.5674 23.5674 23.8902 22.5988H30.6698V8.07115H8.07115V22.5988H14.8507C15.1736 23.5674 15.752 24.3475 16.586 24.9394C17.42 25.5313 18.3481 25.8272 19.3705 25.8272ZM19.3705 22.5988L12.9137 16.1421L15.1736 13.8015L17.7563 16.3842V9.68534H20.9847V16.3842L23.5674 13.8015L25.8272 16.1421L19.3705 22.5988Z"
                        fill="#3F4C63"
                    />
                </svg>
            ),
            target: '#receive-questions',
        },
        {
            title: 'Searchable',
            description: 'Effortless navigation and research for users.',
            icon: (
                <svg
                    width="40"
                    viewBox="0 0 40 39"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M19.9729 22.5988L2.2168 12.9136L19.9729 3.22852L37.7289 12.9136L19.9729 22.5988ZM19.9729 29.0555L3.14495 19.895L6.53475 18.0387L19.9729 25.3832L33.411 18.0387L36.8008 19.895L19.9729 29.0555ZM19.9729 35.5123L3.14495 26.3518L6.53475 24.4954L19.9729 31.84L33.411 24.4954L36.8008 26.3518L19.9729 35.5123ZM19.9729 18.9265L30.9897 12.9136L19.9729 6.90079L8.95603 12.9136L19.9729 18.9265Z"
                        fill="#3F4C63"
                    />
                </svg>
            ),
            target: '#searchable',
        },
        {
            title: 'Insights',
            description: 'Real-time analytics on article views.',
            icon: (
                <svg
                    width="39"
                    viewBox="0 0 39 39"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M3.43262 33.8982V30.6698H35.7164V33.8982H3.43262ZM5.04681 29.0556V17.7563H9.88937V29.0556H5.04681ZM13.1177 29.0556V9.68534H17.9603V29.0556H13.1177ZM21.1887 29.0556V14.5279H26.0312V29.0556H21.1887ZM29.2596 29.0556V4.84277H34.1022V29.0556H29.2596Z"
                        fill="#3F4C63"
                    />
                </svg>
            ),
            target: '#insights',
        },
        {
            title: 'Content',
            description: 'Embed images, videos, and HTML for engaging content.',
            icon: (
                <svg
                    width="39"
                    viewBox="0 0 39 39"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M8.07115 33.8982C7.18335 33.8982 6.42333 33.582 5.79111 32.9498C5.15889 32.3176 4.84277 31.5576 4.84277 30.6698V8.07115C4.84277 7.18335 5.15889 6.42333 5.79111 5.79111C6.42333 5.15889 7.18335 4.84277 8.07115 4.84277H30.6698C31.5576 4.84277 32.3176 5.15889 32.9498 5.79111C33.582 6.42333 33.8982 7.18335 33.8982 8.07115V30.6698C33.8982 31.5576 33.582 32.3176 32.9498 32.9498C32.3176 33.582 31.5576 33.8982 30.6698 33.8982H8.07115ZM8.07115 30.6698H30.6698V8.07115H8.07115V30.6698ZM9.68534 27.4414H29.0556L23.0024 19.3705L18.1598 25.8272L14.5279 20.9847L9.68534 27.4414ZM13.7208 16.1421C14.3934 16.1421 14.9651 15.9067 15.4359 15.4359C15.9067 14.9651 16.1421 14.3934 16.1421 13.7208C16.1421 13.0482 15.9067 12.4765 15.4359 12.0057C14.9651 11.5349 14.3934 11.2995 13.7208 11.2995C13.0482 11.2995 12.4765 11.5349 12.0057 12.0057C11.5349 12.4765 11.2995 13.0482 11.2995 13.7208C11.2995 14.3934 11.5349 14.9651 12.0057 15.4359C12.4765 15.9067 13.0482 16.1421 13.7208 16.1421Z"
                        fill="#3F4C63"
                    />
                </svg>
            ),
            target: '#content',
        },
        {
            title: 'Embeddable',
            description: 'Seamlessly integrate FAQs into any website.',
            icon: (
                <svg
                    width="39"
                    viewBox="0 0 39 39"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M12.9136 25.8271H32.2839V9.68527H12.9136V25.8271ZM12.9136 29.0555C12.0258 29.0555 11.2658 28.7394 10.6336 28.1072C10.0014 27.475 9.68527 26.715 9.68527 25.8271V6.45689C9.68527 5.56909 10.0014 4.80907 10.6336 4.17685C11.2658 3.54463 12.0258 3.22852 12.9136 3.22852H32.2839C33.1717 3.22852 33.9317 3.54463 34.5639 4.17685C35.1962 4.80907 35.5123 5.56909 35.5123 6.45689V25.8271C35.5123 26.715 35.1962 27.475 34.5639 28.1072C33.9317 28.7394 33.1717 29.0555 32.2839 29.0555H12.9136ZM6.45689 35.5123C5.56909 35.5123 4.80907 35.1962 4.17685 34.5639C3.54463 33.9317 3.22852 33.1717 3.22852 32.2839V9.68527H6.45689V32.2839H29.0555V35.5123H6.45689Z"
                        fill="#3F4C63"
                    />
                </svg>
            ),
            target: '#embeddable',
        },
        {
            title: 'Customizable Styling',
            description: 'Tailor FAQs to match your brand.',
            icon: (
                <svg
                    width="39"
                    viewBox="0 0 39 39"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M13.9629 33.091L4.03561 23.1637C3.76658 22.8947 3.5648 22.5988 3.43029 22.2759C3.29577 21.9531 3.22852 21.6168 3.22852 21.2671C3.22852 20.9173 3.29577 20.581 3.43029 20.2582C3.5648 19.9354 3.76658 19.6394 4.03561 19.3704L13.3172 10.1292L9.03959 5.85157L11.5416 3.22852L27.6835 19.3704C27.9525 19.6394 28.1475 19.9354 28.2686 20.2582C28.3897 20.581 28.4502 20.9173 28.4502 21.2671C28.4502 21.6168 28.3897 21.9531 28.2686 22.2759C28.1475 22.5988 27.9525 22.8947 27.6835 23.1637L17.7562 33.091C17.4872 33.36 17.1912 33.5618 16.8684 33.6963C16.5456 33.8308 16.2093 33.8981 15.8595 33.8981C15.5098 33.8981 15.1735 33.8308 14.8507 33.6963C14.5278 33.5618 14.2319 33.36 13.9629 33.091ZM15.8595 12.6715L7.22363 21.3074H24.4954L15.8595 12.6715ZM31.9611 33.8981C30.9925 33.8981 30.172 33.5551 29.4994 32.869C28.8268 32.183 28.4906 31.3423 28.4906 30.3469C28.4906 29.6205 28.6722 28.9345 29.0353 28.2888C29.3985 27.6431 29.8088 27.0109 30.2662 26.3921L31.9611 24.213L33.7367 26.3921C34.1671 27.0109 34.5707 27.6431 34.9473 28.2888C35.324 28.9345 35.5123 29.6205 35.5123 30.3469C35.5123 31.3423 35.1625 32.183 34.4631 32.869C33.7636 33.5551 32.9296 33.8981 31.9611 33.8981Z"
                        fill="#3F4C63"
                    />
                </svg>
            ),
            target: '#customizable-styling',
        },
    ]);
    return (
        <section id="functions">
            <Container className="features-nav-container">
                <h3>Meet the revolution of FAQ</h3>
                <p>
                    FAQ Mastery for the Next Era: A Groundbreaking Tool
                    Revolutionizing FAQ Management
                </p>
                <div className="features-nav">
                    {features.map((feature, i) => (
                        <a
                            href={feature.target}
                            key={feature.title + '--' + i}
                            className="feature">
                            <div className="feature-icon">{feature.icon}</div>
                            <div className="feature-title">{feature.title}</div>
                            <div className="feature-description">
                                {feature.description}
                            </div>
                        </a>
                    ))}
                </div>
            </Container>
        </section>
    );
};

export const Features = () => {
    const [features, setFeatures] = useState([
        {
            title: 'Receive Questions',
            icon: '/static/images/features/questions.png',
            abstract: 'Seamless Interaction with Users',
            description:
                "Engage with your audience effortlessly through our 'Receive Questions' feature. When users can't find answers in your FAQs, they can simply use the contact form to submit their questions directly. Stay connected with your users, gather valuable feedback, and build a comprehensive FAQ database based on real user inquiries.",
        },
        {
            title: 'Searchable',
            icon: '/static/images/features/search.png',
            abstract: 'Effortless Navigation and Research',
            description:
                'Discover the convenience of our Search bar and Topic feature, empowering users to navigate and research questions with ease. Utilise the search box to swiftly find specific queries, while intuitive navigation tabs allow seamless exploration of various topics. Effortlessly organise and locate information, ensuring users find answers quickly and efficiently.',
        },
        {
            title: 'Insights',
            icon: '/static/images/features/insights.png',
            abstract: 'Data-Driven Article Prioritization',
            description:
                "Track the pulse of your FAQs with real-time analytics on article views. Admins can gauge user interest by viewing the number of visitors per article, empowering them to prioritize content effectively. Our system dynamically sorts the most-read articles and questions to the top, providing users with quicker access to the most relevant information. Stay ahead with data-driven insights, refreshed dynamically based on the last 30 days' user activity.",
        },
        {
            title: 'Content',
            icon: '/static/images/features/content.png',
            abstract: 'Enhance Articles with Images, Videos, and HTML',
            description:
                "Make your articles truly standout with our 'Multimedia Rich Content' feature. Seamlessly embed images, videos, or custom HTML code to create engaging and personalized FAQs. Whether it's visual aids, instructional videos, or interactive elements, our tool lets you tailor each article to your needs. Elevate user experience with dynamic, multimedia-rich content that educates and captivates.",
        },
        {
            title: 'Embeddable',
            icon: '/static/images/features/embed.png',
            abstract: 'Integrate FAQs Anywhere',
            description:
                "Expand the reach of your FAQs with our 'Embeddable FAQs' feature. Effortlessly integrate your FAQ section into any website using a simple code snippet. Or, create a public page that can be customized to match your brand's look and feel. Whether embedded on your website or shared via a personalized page, FAQs remain easily accessible and tailored to your audience.",
        },
        {
            title: 'Customizable Styling',
            icon: '/static/images/features/styles.png',
            abstract: 'Tailor to Match Your Brand',
            description:
                "Personalize the look and feel of your FAQs with our 'Customizable Styling' feature. Users can easily adapt the CSS styles to create a seamless integration with their website's design. Whether it's matching color schemes, fonts, or layout, our tool empowers you to maintain brand consistency while delivering a polished user experience. Stand out with FAQs that are uniquely yours, perfectly blending into your website's aesthetics.",
        },
    ]);

    return (
        <section
            id={features[0].title.toLocaleLowerCase().replace(' ', '-')}
            className="main-features-section">
            <Container className={'features-container'}>
                {features.map((feature, i) => (
                    <article
                        id={
                            i > 0
                                ? feature.title
                                      .toLocaleLowerCase()
                                      .replace(' ', '-')
                                : ''
                        }
                        key={'mfeature-' + i}
                        className="feature-container">
                        <div className="feature-illustration">
                            <img src={feature.icon} alt="" />
                        </div>
                        <div className="feature-description">
                            <span>
                                <h3 className="feature-title">
                                    {feature.title}
                                </h3>
                                <p className="feature-abstract">
                                    {feature.abstract}
                                </p>
                                <p className="feature-content">
                                    {feature.description}
                                </p>
                            </span>
                        </div>
                    </article>
                ))}
            </Container>
        </section>
    );
};
