import React from 'react';
import { Text } from '../../components/Text';

export function ErrorPagePanel() {

    return (
        <>
            <div
                className="--panel-section --flex-l p-16"
                style={{ position: 'relative' }}>
                <div className="--above-box">
                    <Text value={'Something went wrong.'} />
                </div>
            </div>
        </>
    );
}
