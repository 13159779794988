// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contactFormContainer {
  max-width: 820px;
  margin-top: 40px;
}

.contactSalesForm {
  min-height: 1250px;
}
@media (min-width: 805px) {
  .contactSalesForm {
    min-height: 900px;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/contact-sales/contact-sales.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,gBAAA;AACJ;;AACA;EACI,kBAAA;AAEJ;AADI;EAFJ;IAGQ,iBAAA;EAIN;AACF","sourcesContent":[".contactFormContainer{\n    max-width: 820px;\n    margin-top: 40px;\n}\n.contactSalesForm{\n    min-height: 1250px;\n    @media (min-width: 805px){\n        min-height: 900px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
