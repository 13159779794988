export const PositionTypes = {
    TopLeft: 'top left',
    TopCenter: 'top center',
    TopRight: 'top right',
    RightTop: 'right top',
    RightCenter: 'right center',
    RightBottom: 'right bottom',
    BottomLeft: 'bottom left',
    BottomCenter: 'bottom center',
    BottomRight: 'bottom right',
    LeftTop: 'left top',
    LeftCenter: 'left center',
    LeftBottom: 'left bottom',
    CenterCenter: 'center center',
};

export const GetPositionTypesValueArray = () => {
    let r = [];
    Object.keys(PositionTypes).map((k) => {
        r.push(PositionTypes[k]);
    });
    return r;
};
