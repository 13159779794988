import { apiRoutes } from '../helpers/api';
import { callApi } from './api';

const assignLogin = async ({ authCode = null }) => {
    return await callApi({
        method: 'post',
        payload: JSON.stringify({
            authcode: authCode,
        }),
        path: apiRoutes.auth.assignLogin,
    });
};

const checkIfUserExist = async ({ email = null }) => {
    return await callApi({
        method: 'post',
        payload: JSON.stringify({
            email: email,
        }),
        path: apiRoutes.auth.checkIfUserExist,
    });
};

const sendResetPasswordRequest = async ({ email = null }) => {
    return await callApi({
        method: 'post',
        payload: JSON.stringify({
            email: email,
        }),
        path: apiRoutes.auth.sendResetPasswordRequest,
    });
};

const resetPassword = async ({ confirmKey = null, newPassword = null }) => {
    return await callApi({
        method: 'post',
        payload: JSON.stringify({
            confirmKey: confirmKey,
            newPasscode: newPassword,
        }),
        path: apiRoutes.auth.resetPassword,
    });
};

const confirmResetPasswordRequest = async ({ key = null }) => {
    return await callApi({
        method: 'post',
        payload: JSON.stringify({
            emailKey: key,
        }),
        path: apiRoutes.auth.confirmResetPasswordRequest,
    });
};

const login = async ({ email = null, password = null }) => {
    return await callApi({
        method: 'post',
        payload: JSON.stringify({
            email: email,
            passcode: password,
        }),
        path: apiRoutes.auth.login,
    });
};

const signup = async ({ email = null, password = null }) => {
    return await callApi({
        method: 'post',
        payload: JSON.stringify({
            email: email,
            passcode: password,
        }),
        path: apiRoutes.auth.signup,
    });
};

/** verify account */
const verify = async ({ code = null, token = null }) => {
    return await callApi({
        method: 'post',
        payload: JSON.stringify({
            code: code,
            token: token,
        }),
        path: apiRoutes.auth.verify,
    });
};

/** me [required login] */

const me = async (data = { signal: null, cancelToken: null }) => {
    return await callApi({
        method: 'get',
        path: apiRoutes.account.me,
        signal: data.signal,
        cancelToken: data.cancelToken,
    });
};

const updateAccount = async ({
    name = undefined,
    newsletter = undefined,
    user_questions = undefined,
    date_format = undefined,
    time_format = undefined,
    survey = undefined,
}) => {
    return await callApi({
        method: 'patch',
        path: apiRoutes.account.update,
        payload: JSON.stringify({
            name: name,
            newsletter: newsletter,
            user_questions: user_questions,
            date_format: date_format,
            time_format: time_format,
            survey: survey,
        }),
    });
};

/** refresh */
const refreshToken = async (
    data = { refreshToken: null, signal: undefined, cancelToken: undefined }
) => {
    return await callApi({
        method: 'post',
        path: apiRoutes.auth.refresh,
        payload: JSON.stringify({
            refreshToken: data.refreshToken,
        }),
        signal: data.signal,
        cancelToken: data.cancelToken,
    });
};

const uploadFileToUserSpace = async ({
    file = undefined,
    projectId = undefined,
    onUploadProgress = (e) => {},
}) => {
    if (!file) {
        return {};
    }
    const formData = new FormData();
    formData.append('file', file);
    const res = await callApi({
        method: 'post',
        headers: { 'Content-Type': 'multipart/form-data' },
        payload: formData,
        path: 'space?projectId=' + projectId,
        onUploadProgress: onUploadProgress,
    });
    return res.data;
};

export default {
    assignLogin,
    checkIfUserExist,
    sendResetPasswordRequest,
    confirmResetPasswordRequest,
    resetPassword,
    login,
    signup,
    verify,
    me,
    updateAccount,
    refreshToken,
    uploadFileToUserSpace,
};
