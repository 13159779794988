import { PopupMenu } from './Popup';
import './plans.scss';
import { useEffect, useState } from 'react';
import { callApi } from '../../functions/api';
import Button from '../Button';
import { Symbol } from '../elements/Icon';
import { PricingCard } from '../pricing-card/PricingCard';
import plans_list from '../../constants/plans.json';
import { CountDown, dateToSeconds } from '../count-down/CountDown';
import useSetting from '../../hooks/useSetting';
import { apiRoutes } from '../../helpers/api';
import { createPortal } from 'react-dom';
import { UseLicenseKey } from '../use-license-key-dialog/UseLicenseKeyDialog';
import { showError, showInfo } from '../towsts/Toasts';

export default function Plans({ open, onOpen, onClose, noClose = false }) {
    const [loading, setLoading] = useState(false);

    const selectPlan = async (planId) => {
        if (planId === 'free') {
            onClose();
            return;
        }
        setLoading(true);
        try {
            let res = await callApi({
                path: apiRoutes.plans.getSubscribeLink,
                method: 'post',
                payload: { planId: planId },
            });
            if (res.status !== 200) {
                throw new Error('Unknown Error');
            }
            document.location.href = res.data.checkoutUrl;
        } catch (err) {
            showError('Error, please try again!');
        }
        setTimeout(() => {
            try {
                setLoading(false);
            } catch (err) {}
        }, 1000);
    };

    return (
        <PopupMenu
            open={open}
            onOpen={onOpen}
            onClose={onClose}
            closeOnDocumentClick={!noClose}
            closeOnEscape={!noClose}
            className={'plans-popup'}>
            {loading && <div className="loading"></div>}
            <div style={{ display: loading ? 'none' : '' }}>
                {!noClose && (
                    <Button onClick={onClose} className={'close-plans-popup'}>
                        <Symbol />
                    </Button>
                )}
                <br />
                <br />
                <SelectPlan action={selectPlan} />
            </div>
        </PopupMenu>
    );
}

export const SelectPlan = ({
    action,
    theme = 'dark',
    freePlanActionLable = "Let's Start",
    freePlanExtraInfo = 'Lifetime for Free',
    oneProjectLable = 'Purchase 1 Project',
    oneProjectExtraInfo = '30-Day Money Back Guarantee',
    threeProjectLable = 'Purchase 3 Projects',
    threeProjectExtraInfo = '30-Day Money Back Guarantee',
    unlimitedProjectsLable = 'Purchase Limitless',
    unlimitedProjectsExtraInfo = '30-Day Money Back Guarantee',
    noFreeOption = false,
}) => {
    const [descount, setDescount] = useState(false);
    const [projectsAmount, setProjectsAmount] = useState(1);

    return (
        <>
            <div
                className={`prices-container ${
                    theme === 'light' ? '__light' : ''
                } ${noFreeOption ? 'no-free-option' : ''}`}>
                {!noFreeOption && (
                    <PricingCard
                        name={'Tester'}
                        price={0}
                        currency={'euro'}
                        paymentDuration={'No cost, forever'}
                        extraInfo={freePlanExtraInfo}
                        actionName={freePlanActionLable}
                        action={() => {
                            action('free');
                        }}
                        theme={theme}
                        features={[
                            { name: '1 Project', included: true },
                            { name: '10 Questions', included: true },
                            { name: 'Help Page', included: true },
                        ]}
                    />
                )}
                <div>
                    <div
                        className={`select-projects-amount ${
                            theme === 'light' ? '__light' : ''
                        }`}>
                        <div
                            onClick={() => {
                                setProjectsAmount(1);
                            }}
                            className={`${
                                projectsAmount === 1 ? 'selected' : ''
                            }`}>
                            1 Project
                        </div>
                        <div
                            onClick={() => {
                                setProjectsAmount(3);
                            }}
                            className={`${
                                projectsAmount === 3 ? 'selected' : ''
                            }`}>
                            3 Projects
                        </div>
                    </div>
                    {projectsAmount === 3 ? (
                        <PricingCard
                            name={plans_list['threeProjectLifeTime'].label}
                            price={
                                descount
                                    ? plans_list['threeProjectLifeTime'][
                                          'price_after_descount'
                                      ]
                                    : plans_list['threeProjectLifeTime'].price
                            }
                            oldPrice={
                                descount
                                    ? plans_list['threeProjectLifeTime'].price
                                    : undefined
                            }
                            currency={
                                plans_list['threeProjectLifeTime'].currency
                            }
                            paymentDuration={
                                plans_list['threeProjectLifeTime'][
                                    'payment_duration'
                                ]
                            }
                            mostPopular={true}
                            actionName={threeProjectLable}
                            extraInfo={threeProjectExtraInfo}
                            theme={theme}
                            action={() => {
                                action(plans_list['threeProjectLifeTime'].name);
                            }}
                            features={[
                                {
                                    name: 'Limitless Questions',
                                    included: true,
                                },
                                { name: 'Views Counter', included: true },
                                { name: 'SEO Adaption', included: true },
                                {
                                    name: 'Custom Domain + SSL',
                                    included: true,
                                },
                                { name: 'Embed Images', included: true },
                                {
                                    name: 'Embed on Website',
                                    included: true,
                                },
                            ]}
                        />
                    ) : (
                        <PricingCard
                            name={plans_list['oneProjectLifeTime'].label}
                            price={
                                descount
                                    ? plans_list['oneProjectLifeTime'][
                                          'price_after_descount'
                                      ]
                                    : plans_list['oneProjectLifeTime'].price
                            }
                            oldPrice={
                                descount
                                    ? plans_list['oneProjectLifeTime'].price
                                    : undefined
                            }
                            currency={plans_list['oneProjectLifeTime'].currency}
                            paymentDuration={
                                plans_list['oneProjectLifeTime'][
                                    'payment_duration'
                                ]
                            }
                            mostPopular={true}
                            actionName={oneProjectLable}
                            extraInfo={oneProjectExtraInfo}
                            theme={theme}
                            action={() => {
                                action(plans_list['oneProjectLifeTime'].name);
                            }}
                            features={[
                                {
                                    name: 'Limitless Questions',
                                    included: true,
                                },
                                { name: 'Views Counter', included: true },
                                { name: 'SEO Adaption', included: true },
                                {
                                    name: 'Custom Domain + SSL',
                                    included: true,
                                },
                                { name: 'Embed Images', included: true },
                                {
                                    name: 'Embed on Website',
                                    included: true,
                                },
                            ]}
                        />
                    )}
                </div>
                <PricingCard
                    name={plans_list['unlimitedLifeTime'].label}
                    price={
                        descount
                            ? plans_list['unlimitedLifeTime'][
                                  'price_after_descount'
                              ]
                            : plans_list['unlimitedLifeTime'].price
                    }
                    oldPrice={
                        descount
                            ? plans_list['unlimitedLifeTime'].price
                            : undefined
                    }
                    currency={plans_list['unlimitedLifeTime'].currency}
                    paymentDuration={
                        plans_list['unlimitedLifeTime']['payment_duration']
                    }
                    actionName={unlimitedProjectsLable}
                    extraInfo={unlimitedProjectsExtraInfo}
                    theme={theme}
                    action={() => {
                        action(plans_list['unlimitedLifeTime'].name);
                    }}
                    features={[
                        { name: 'Limitless Project', included: true },
                        { name: 'Limitless Questions', included: true },
                        { name: 'Views Counter', included: true },
                        { name: 'SEO Adaption', included: true },
                        { name: 'Custom Domain + SSL', included: true },
                        { name: 'Embed Images', included: true },
                        { name: 'Embed on Website', included: true },
                    ]}
                />
            </div>
            <div className="count-down-container">
                {descount && (
                    <h3 className="count-down-title">
                        Limited Time Offer Ends in
                    </h3>
                )}
                <CountDown
                    until={dateToSeconds({
                        year: 2024,
                        month: 6,
                        date: 1,
                        hours: 0,
                        minutes: 0,
                        seconds: 0,
                    })}
                    onCountDownEnd={() => {
                        setDescount(false);
                    }}
                    onCountDownStart={() => {
                        setDescount(true);
                    }}
                />
                {descount && (
                    <p className="count-down-description">
                        Early users can enjoy special conditions until 31 May
                        2024.{' '}
                        {/* Enter the code "BETALAUNCH" at checkout. It
                        request a testimonial review between 20 days after
                        purchase for our landing page. */}
                        <a className="highlighted-word" href="#">
                            Learn more
                        </a>
                    </p>
                )}
            </div>
        </>
    );
};

export const UpgradeProject = () => {
    const settingCtx = useSetting();
    const [descount, setDescount] = useState(false);
    const [useLicenseKey, setUseLicenseKey] = useState(false);
    const [availablePaidProjects, setAvailablePaidProjects] = useState(0);

    const [loading, setLoading] = useState(true);

    const projectId = settingCtx.upgrade_project_dialog.project_id;

    useEffect(() => {
        if (!settingCtx.upgrade_project_dialog.visible) {
            return;
        }
        (async () => {
            try {
                let res = await callApi({
                    path: apiRoutes.plans.getUserLimitations,
                    method: 'get',
                });
                if (res.status === 200) {
                    if (res.data.limits.paidProjectLeft > 0) {
                        setAvailablePaidProjects(
                            res.data.limits.paidProjectLeft
                        );
                    }
                }
            } catch (err) {}
            setLoading(false);
        })();
    }, [settingCtx.upgrade_project_dialog.visible]);

    const onClose = () => {
        settingCtx.setUpgradeProjectDialog({
            visible: false,
            projectId: undefined,
            msg: '',
        });
    };

    const action = async (planId) => {
        if (planId === 'free') {
            onClose();
            return;
        }
        setLoading(true);
        try {
            let res = await callApi({
                path: '/pay/subscribe',
                method: 'post',
                payload: { planId: planId, projectId: projectId },
            });
            if (res.status !== 200) {
                throw new Error('Unknown Error');
            }
            document.location.href = res.data.checkoutUrl;
        } catch (err) {
            showError('Error, please try again!');
        }
        setTimeout(() => {
            try {
                setLoading(false);
            } catch (err) {}
        }, 1000);
    };

    const upgradeProject = async () => {
        setLoading(true);
        try {
            let res = await callApi({
                path: `${apiRoutes.plans.upgradeProject}?projectId=${projectId}`,
                method: 'post',
                payload: {},
            });
            if (res.status !== 200) {
                if (res.status === 400) {
                    throw new Error('Impossible to upgrade project');
                }
                throw new Error('Unknown Error');
            }
            showInfo('Project upgraded successfully!');
            document.location.reload();
        } catch (err) {
            showError(err.toString());
        }
        setLoading(false);
    };

    return (
        <>
            <PopupMenu
                open={settingCtx.upgrade_project_dialog.visible}
                onOpen={() => {}}
                onClose={onClose}
                closeOnDocumentClick={true}
                closeOnEscape={true}
                className={`${
                    availablePaidProjects <= 0 ? 'plans-popup' : ''
                }`}>
                {loading && <div className="loading"></div>}
                <div style={{ display: loading ? 'none' : '' }}>
                    {true && (
                        <Button
                            onClick={onClose}
                            className={'close-plans-popup'}>
                            <Symbol />
                        </Button>
                    )}
                    <br />
                    <br />
                    <h3
                        style={{
                            textAlign: 'center',
                            maxWidth: '42ch',
                            margin: '10px auto',
                            fontSize: '1.4rem',
                        }}>
                        {settingCtx.upgrade_project_dialog.msg}
                    </h3>

                    {availablePaidProjects > 0 ? (
                        <div style={{ textAlign: 'center', padding: '20px 0' }}>
                            <Button
                                onClick={upgradeProject}
                                type={'primary'}
                                style={{ padding: '1.4em 2.2em' }}>
                                Upgrade project
                            </Button>
                        </div>
                    ) : (
                        <>
                            <div className="prices-container no-free-option">
                                <PricingCard
                                    name={
                                        plans_list['oneProjectLifeTime'].label
                                    }
                                    price={
                                        descount
                                            ? plans_list['oneProjectLifeTime'][
                                                  'price_after_descount'
                                              ]
                                            : plans_list['oneProjectLifeTime']
                                                  .price
                                    }
                                    oldPrice={
                                        descount
                                            ? plans_list['oneProjectLifeTime']
                                                  .price
                                            : undefined
                                    }
                                    currency={
                                        plans_list['oneProjectLifeTime']
                                            .currency
                                    }
                                    paymentDuration={
                                        plans_list['oneProjectLifeTime'][
                                            'payment_duration'
                                        ]
                                    }
                                    mostPopular={false}
                                    actionName={'Purchase 1 project'}
                                    action={() => {
                                        action(
                                            plans_list['oneProjectLifeTime']
                                                .name
                                        );
                                    }}
                                    theme="light"
                                    features={[
                                        { name: '1 Project', included: true },
                                        {
                                            name: 'Limitless Questions',
                                            included: true,
                                        },
                                        { name: 'Track Views', included: true },
                                        { name: 'SEO Setup', included: true },
                                        {
                                            name: 'Custom Domain + SSL',
                                            included: true,
                                        },
                                        {
                                            name: 'Video & Images',
                                            included: true,
                                        },
                                        {
                                            name: 'Embed on Website',
                                            included: true,
                                        },
                                    ]}
                                />
                                <PricingCard
                                    name={
                                        plans_list['threeProjectLifeTime'].label
                                    }
                                    price={
                                        descount
                                            ? plans_list[
                                                  'threeProjectLifeTime'
                                              ]['price_after_descount']
                                            : plans_list['threeProjectLifeTime']
                                                  .price
                                    }
                                    oldPrice={
                                        descount
                                            ? plans_list['threeProjectLifeTime']
                                                  .price
                                            : undefined
                                    }
                                    currency={
                                        plans_list['threeProjectLifeTime']
                                            .currency
                                    }
                                    paymentDuration={
                                        plans_list['threeProjectLifeTime'][
                                            'payment_duration'
                                        ]
                                    }
                                    mostPopular={true}
                                    actionName={'Purchase 3 projects'}
                                    action={() => {
                                        action(
                                            plans_list['threeProjectLifeTime']
                                                .name
                                        );
                                    }}
                                    theme="light"
                                    features={[
                                        { name: '3 Projects', included: true },
                                        {
                                            name: 'Limitless Questions',
                                            included: true,
                                        },
                                        { name: 'Track Views', included: true },
                                        { name: 'SEO Setup', included: true },
                                        {
                                            name: 'Custom Domain + SSL',
                                            included: true,
                                        },
                                        {
                                            name: 'Video & Images',
                                            included: true,
                                        },
                                        {
                                            name: 'Embed on Website',
                                            included: true,
                                        },
                                    ]}
                                />
                            </div>
                            <div className="count-down-container">
                                {descount && (
                                    <h3 className="count-down-title">
                                        Limited Time Offer Ends in
                                    </h3>
                                )}
                                <CountDown
                                    until={dateToSeconds({
                                        year: 2024,
                                        month: 6,
                                        date: 1,
                                        hours: 0,
                                        minutes: 0,
                                        seconds: 0,
                                    })}
                                    onCountDownEnd={() => {
                                        setDescount(false);
                                    }}
                                    onCountDownStart={() => {
                                        setDescount(true);
                                    }}
                                />
                                {descount && (
                                    <p className="count-down-description">
                                        Early users can enjoy special conditions
                                        until 31 May 2024.{' '}
                                        {/* Enter the code "BETALAUNCH" at checkout. It
                                request a testimonial review between 20 days after
                                purchase for our landing page. */}
                                        <a
                                            style={{ whiteSpace: 'nowrap' }}
                                            className="highlighted-word"
                                            href="#">
                                            Learn more
                                        </a>
                                    </p>
                                )}
                            </div>
                            <div style={{ textAlign: 'center' }}>
                                <a
                                    href="#l-k"
                                    title="yf"
                                    onClick={(evnt) => {
                                        evnt.preventDefault();
                                        setUseLicenseKey(true);
                                    }}
                                    className="select_plan_use_key">
                                    {'You have a license key?'}
                                </a>
                            </div>
                        </>
                    )}
                </div>
            </PopupMenu>
            {useLicenseKey &&
                createPortal(
                    <UseLicenseKey
                        onCancel={() => {
                            setUseLicenseKey(false);
                        }}
                        onSuccess={() => {
                            setUseLicenseKey(false);
                            upgradeProject();
                        }}
                    />,
                    document.getElementById('hero-popup-layer') || document.body
                )}
        </>
    );
};
