import React, { forwardRef } from 'react';
import { AddIcon, AddIconCircle } from '../../assets/icons/Add';
import { MenuBookIcon } from '../../assets/icons/Book';
import {
    CloseIcon,
    FaucetIcon,
    ThermostatIcon,
} from '../../assets/icons/Close';
import { LoginIcon } from '../../assets/icons/Login';
import {
    ArrowDropDownIcon,
    ArrowDropDownIconAlt,
    ArrowLeft,
    ArrowRight,
    DatabaseIcon,
    FieldsIcon,
    HideIcon,
    MoreHorizIcon,
    MoreVertIcon,
    OpenLinkIcon,
    SwitchIcon,
} from '../../assets/icons/More';
import {
    InboxIcon,
    MenuIcon,
    MenuOpenIcon,
    OptionsMenuIcon,
    SettingsIcon,
} from '../../assets/icons/Menu';
import { SearchIcon } from '../../assets/icons/Search';
import { SortByAlphaIcon } from '../../assets/icons/Sort';
import { ClearFilterAltIcon, FilterAltIcon } from '../../assets/icons/Filter';
import { DragIndicatorIcon } from '../../assets/icons/DragIndicator';
import DragHandleIcon from '../../assets/icons/Drag';
import { ModeIcon } from '../../assets/icons/Mode';
import { ContentCopyIcon } from '../../assets/icons/ContentCopy';
import { DeleteIcon, DeleteAlt1Icon } from '../../assets/icons/Delete';
import { WebAssetIcon } from '../../assets/icons/WebAsset';
import {
    AcUnitIcon,
    AppsIcon,
    BackspaceIcon,
    BadgeIcon,
    BlenderIcon,
    CalendarMonthIcon,
    CalendarTodayIcon,
    ClearAllIcon,
    DnsIcon,
    DownloadIcon,
    DriveFileRenameIcon,
    FmtBadIcon,
    ImageIcon,
    KettleIcon,
    LanguageIcon,
    LockIcon,
    LogoutIcon,
    LooksOneIcon,
    LunchDiningIcon,
    Person2Icon,
    QrScannerIcon,
    RadioButtonCheckedIcon,
    ReportIcon,
    RestaurantIcon,
    StoreIcon,
    TitleIcon,
    TodayIcon,
    ToggleOnIcon,
    UploadIcon,
    WebIcon,
    WorkspacesIcon,
} from '../../assets/icons/Person';
import { BakeryDiningIcon } from '../../assets/icons/Close';
import {
    CheckboxOffIcon,
    CheckboxOnIcon,
    CheckedIcon,
    CheckIcon,
} from '../../assets/icons/Check';
import { Enter } from '../../assets/icons/Enter';
import { OpenInFull } from '../../assets/icons/OpenInFull';
import { Calendar01Icon, Calendar31Icon } from '../../assets/icons/Calendar';
import { HistoryIcon } from '../../assets/icons/History';
import { SupportIcon } from '../../assets/icons/Support';
import { SchoolIcon } from '../../assets/icons/School';
import { StarIcon } from '../../assets/icons/Star';
import { MsgIcon } from '../../assets/icons/Msg';
import { ReloadIcon } from '../../assets/icons/Reload';
import { GearIcon } from '../../assets/icons/Gear';
import { ArrowOutWard } from '../../assets/icons/Arrows';
import ColorsIcon from '../../assets/icons/Colors';
import EarthIcon from '../../assets/icons/Earth';
import CodeIcon from '../../assets/icons/Code';
import { ArrowDown } from '../../assets/icons/ArrowDown';
import { ArrowUp } from '../../assets/icons/ArrowUp';
import { APIIcon } from '../../assets/icons/API';
import { CloseFull } from '../../assets/icons/CloseFull';
import { ColumnsIcon } from '../../assets/icons/ColumnsIcon';
import { ExpandIcon } from '../../assets/icons/Expand';
import EmbedIcon from '../../assets/icons/Embed';
import LinkIcon from '../../assets/icons/Link';
import { BotIcon } from '../../assets/icons/Bot';
import { TagIcon } from '../../assets/icons/Tag';
import { GithubIcon } from '../../assets/icons/Github';
import { MailIcon } from '../../assets/icons/Mail';
import {
    VisibilityIcon,
    VisibilityOffIcon,
} from '../../assets/icons/Visibility';
import { DesktopIcon } from '../../assets/icons/Desktop';
import { MobileIcon } from '../../assets/icons/Mobile';
import { ImportIcon } from '../../assets/icons/Import';
import { ExportIcon } from '../../assets/icons/Export';
import { ShareIcon } from '../../assets/icons/Share';

export const Symbol = ({
    name = 'window',
    color = undefined,
    color2 = undefined,
    theme = 'outlined',
    className,
    style,
    dark = false,
    ...props
}) => {
    var Component = CloseIcon;

    if (name === 'arrow_outward') {
        if (theme === 'outlined') {
            Component = ArrowOutWard;
        }
    } else if (name === 'search') {
        if (theme === 'outlined') {
            Component = SearchIcon;
        }
    } else if (name === 'colors') {
        if (theme === 'outlined') {
            Component = ColorsIcon;
        }
    } else if (name === 'earth') {
        if (theme === 'outlined') {
            Component = EarthIcon;
        }
    } else if (name === 'expand_icon') {
        if (theme === 'outlined') {
            Component = ExpandIcon;
        }
    } else if (name === 'embed_icon') {
        if (theme === 'outlined') {
            Component = EmbedIcon;
        }
    } else if (name === 'school') {
        if (theme === 'outlined') {
            Component = SchoolIcon;
        }
    } else if (name === 'message') {
        if (theme === 'outlined') {
            Component = MsgIcon;
        }
    } else if (name === 'support') {
        if (theme === 'outlined') {
            Component = SupportIcon;
        }
    } else if (name === 'openfull') {
        if (theme === 'outlined') {
            Component = OpenInFull;
        }
    } else if (name === 'closefull') {
        if (theme === 'outlined') {
            Component = CloseFull;
        }
    } else if (name === 'add') {
        if (theme === 'outlined') {
            Component = AddIcon;
        }
    } else if (name === 'add_circle_icon') {
        if (theme === 'outlined') {
            Component = AddIconCircle;
        }
    } else if (name === 'books') {
        if (theme === 'outlined') {
            Component = MenuBookIcon;
        }
    } else if (name === 'link_icon') {
        if (theme === 'outlined') {
            Component = LinkIcon;
        }
    } else if (name === 'star') {
        if (theme === 'outlined') {
            Component = StarIcon;
        }
    } else if (name === 'close') {
        if (theme === 'outlined') {
            Component = CloseIcon;
        }
    } else if (name === 'login') {
        if (theme === 'outlined') {
            Component = LoginIcon;
        }
    } else if (name === 'enter') {
        if (theme === 'outlined') {
            Component = Enter;
        }
    } else if (name === 'more_horiz') {
        if (theme === 'outlined') {
            Component = MoreHorizIcon;
        }
    } else if (name === 'more_vert') {
        if (theme === 'outlined') {
            Component = MoreVertIcon;
        }
    } else if (name === 'menu_open') {
        if (theme === 'outlined') {
            Component = MenuOpenIcon;
        }
    } else if (name === 'settings_icon') {
        if (theme === 'outlined') {
            Component = SettingsIcon;
        }
    } else if (name === 'gear_icon') {
        if (theme === 'outlined') {
            Component = GearIcon;
        }
    } else if (name === 'inbox_icon') {
        if (theme === 'outlined') {
            Component = InboxIcon;
        }
    } else if (name === 'menu') {
        if (theme === 'outlined') {
            Component = MenuIcon;
        }
    } else if (name === 'code') {
        if (theme === 'outlined') {
            Component = CodeIcon;
        }
    } else if (name === 'options_menu') {
        if (theme === 'outlined') {
            Component = OptionsMenuIcon;
        }
    } else if (name === 'open_link') {
        if (theme === 'outlined') {
            Component = OpenLinkIcon;
        }
    } else if (name === 'sort_by_alpha') {
        if (theme === 'outlined') {
            Component = SortByAlphaIcon;
        }
    } else if (name === 'filter_alt') {
        if (theme === 'outlined') {
            Component = FilterAltIcon;
        }
    } else if (name === 'clear_filter_alt') {
        if (theme === 'outlined') {
            Component = ClearFilterAltIcon;
        }
    } else if (name === 'drag_indicator') {
        if (theme === 'outlined') {
            Component = DragIndicatorIcon;
        }
    } else if (name === 'drag_handle') {
        if (theme === 'outlined') {
            Component = DragHandleIcon;
        }
    } else if (name === 'mode') {
        if (theme === 'outlined') {
            Component = ModeIcon;
        }
    } else if (name === 'api_icon') {
        if (theme === 'outlined') {
            Component = APIIcon;
        }
    } else if (name === 'content_copy') {
        if (theme === 'outlined') {
            Component = ContentCopyIcon;
        }
    } else if (name === 'clear_all') {
        if (theme === 'outlined') {
            Component = ClearAllIcon;
        }
    } else if (name === 'delete') {
        if (theme === 'outlined') {
            Component = DeleteIcon;
        }
    } else if (name === 'delete_alt1') {
        if (theme === 'outlined') {
            Component = DeleteAlt1Icon;
        }
    } else if (name === 'web_asset') {
        if (theme === 'outlined') {
            Component = WebAssetIcon;
        }
    } else if (name === 'history') {
        if (theme === 'outlined') {
            Component = HistoryIcon;
        }
    } else if (name === 'calendar01') {
        if (theme === 'outlined') {
            Component = Calendar01Icon;
        }
    } else if (name === 'calendar31') {
        if (theme === 'outlined') {
            Component = Calendar31Icon;
        }
    } else if (name === 'person2') {
        if (theme === 'outlined') {
            Component = Person2Icon;
        }
    } else if (name === 'arrow_drop_down') {
        if (theme === 'outlined') {
            Component = ArrowDropDownIcon;
        }
    } else if (name === 'share') {
        if (theme === 'outlined') {
            Component = ShareIcon;
        }
    } else if (name === 'arrow_down') {
        if (theme === 'outlined') {
            Component = ArrowDown;
        }
    } else if (name === 'arrow_up') {
        if (theme === 'outlined') {
            Component = ArrowUp;
        }
    } else if (name === 'arrow_drop_down_alt') {
        if (theme === 'outlined') {
            Component = ArrowDropDownIconAlt;
        }
    } else if (name === 'arrow_left') {
        if (theme === 'outlined') {
            Component = ArrowLeft;
        }
    } else if (name === 'arrow_right') {
        if (theme === 'outlined') {
            Component = ArrowRight;
        }
    } else if (name === 'thermostat') {
        if (theme === 'outlined') {
            Component = ThermostatIcon;
        }
    } else if (name === 'check') {
        if (theme === 'outlined') {
            Component = CheckIcon;
        }
    } else if (name === 'checked') {
        if (theme === 'outlined') {
            Component = CheckedIcon;
        }
    } else if (name === 'restaurant') {
        if (theme === 'outlined') {
            Component = RestaurantIcon;
        }
    } else if (name === 'kettle') {
        if (theme === 'outlined') {
            Component = KettleIcon;
        }
    } else if (name === 'bakery_dining') {
        if (theme === 'outlined') {
            Component = BakeryDiningIcon;
        }
    } else if (name === 'faucet') {
        if (theme === 'outlined') {
            Component = FaucetIcon;
        }
    } else if (name === 'qr_scanner') {
        if (theme === 'outlined') {
            Component = QrScannerIcon;
        }
    } else if (name === 'backspace') {
        if (theme === 'outlined') {
            Component = BackspaceIcon;
        }
    } else if (name === 'today') {
        if (theme === 'outlined') {
            Component = TodayIcon;
        }
    } else if (name === 'calendar_month') {
        if (theme === 'outlined') {
            Component = CalendarMonthIcon;
        }
    } else if (name === 'ac_unit') {
        if (theme === 'outlined') {
            Component = AcUnitIcon;
        }
    } else if (name === 'lunch_dining') {
        if (theme === 'outlined') {
            Component = LunchDiningIcon;
        }
    } else if (name === 'blender') {
        if (theme === 'outlined') {
            Component = BlenderIcon;
        }
    } else if (name === 'fmt_bad') {
        if (theme === 'outlined') {
            Component = FmtBadIcon;
        }
    } else if (name === 'badge') {
        if (theme === 'outlined') {
            Component = BadgeIcon;
        }
    } else if (name === 'lock') {
        if (theme === 'outlined') {
            Component = LockIcon;
        }
    } else if (name === 'upload') {
        if (theme === 'outlined') {
            Component = UploadIcon;
        }
    } else if (name === 'reload') {
        if (theme === 'outlined') {
            Component = ReloadIcon;
        }
    } else if (name === 'workspaces') {
        if (theme === 'outlined') {
            Component = WorkspacesIcon;
        }
    } else if (name === 'title') {
        if (theme === 'outlined') {
            Component = TitleIcon;
        }
    } else if (name === 'web') {
        if (theme === 'outlined') {
            Component = WebIcon;
        }
    } else if (name === 'visibility') {
        if (theme === 'outlined') {
            Component = VisibilityIcon;
        }
    } else if (name === 'visibility_off') {
        if (theme === 'outlined') {
            Component = VisibilityOffIcon;
        }
    } else if (name === 'title') {
        if (theme === 'outlined') {
            Component = TitleIcon;
        }
    } else if (name === 'download') {
        if (theme === 'outlined') {
            Component = DownloadIcon;
        }
    } else if (name === 'clear_all') {
        if (theme === 'outlined') {
            Component = ClearAllIcon;
        }
    } else if (name === 'drive_file_rename') {
        if (theme === 'outlined') {
            Component = DriveFileRenameIcon;
        }
    } else if (name === 'logout') {
        if (theme === 'outlined') {
            Component = LogoutIcon;
        }
    } else if (name === 'report') {
        if (theme === 'outlined') {
            Component = ReportIcon;
        }
    } else if (name === 'image') {
        if (theme === 'outlined') {
            Component = ImageIcon;
        }
    } else if (name === 'export') {
        if (theme === 'outlined') {
            Component = ExportIcon;
        }
    } else if (name === 'import') {
        if (theme === 'outlined') {
            Component = ImportIcon;
        }
    } else if (name === 'dns') {
        if (theme === 'outlined') {
            Component = DnsIcon;
        }
    } else if (name === 'calendar_today') {
        if (theme === 'outlined') {
            Component = CalendarTodayIcon;
        }
    } else if (name === 'looks_one') {
        if (theme === 'outlined') {
            Component = LooksOneIcon;
        }
    } else if (name === 'mail') {
        if (theme === 'outlined') {
            Component = MailIcon;
        }
    } else if (name === 'radio_button_checked') {
        if (theme === 'outlined') {
            Component = RadioButtonCheckedIcon;
        }
    } else if (name === 'toggle_on') {
        if (theme === 'outlined') {
            Component = ToggleOnIcon;
        }
    } else if (name === 'language') {
        if (theme === 'outlined') {
            Component = LanguageIcon;
        }
    } else if (name === 'apps') {
        if (theme === 'outlined') {
            Component = AppsIcon;
        }
    } else if (name === 'store') {
        if (theme === 'outlined') {
            Component = StoreIcon;
        }
    } else if (name === 'hide') {
        if (theme === 'outlined') {
            Component = HideIcon;
        }
    } else if (name === 'fields') {
        if (theme === 'outlined') {
            Component = FieldsIcon;
        }
    } else if (name === 'database') {
        if (theme === 'outlined') {
            Component = DatabaseIcon;
        }
    } else if (name === 'checkboxon') {
        if (theme === 'outlined') {
            Component = CheckboxOnIcon;
        }
    } else if (name === 'checkboxoff') {
        if (theme === 'outlined') {
            Component = CheckboxOffIcon;
        }
    } else if (name === 'check') {
        if (theme === 'outlined') {
            Component = CheckIcon;
        }
    } else if (name === 'switch') {
        if (theme === 'outlined') {
            Component = SwitchIcon;
        }
    } else if (name === 'columns_icon') {
        if (theme === 'outlined') {
            Component = ColumnsIcon;
        }
    } else if (name === 'bot_icon') {
        if (theme === 'outlined') {
            Component = BotIcon;
        }
    } else if (name === 'tag_icon') {
        if (theme === 'outlined') {
            Component = TagIcon;
        }
    } else if (name === 'github_icon') {
        if (theme === 'outlined') {
            Component = GithubIcon;
        }
    } else if (name === 'visibility_icon') {
        if (theme === 'outlined') {
            Component = VisibilityIcon;
        }
    } else if (name === 'visibility_off_icon') {
        if (theme === 'outlined') {
            Component = VisibilityOffIcon;
        }
    } else if (name === 'desktop_icon') {
        if (theme === 'outlined') {
            Component = DesktopIcon;
        }
    } else if (name === 'mobile_icon') {
        if (theme === 'outlined') {
            Component = MobileIcon;
        }
    } else if (name === 'mail_icon') {
        if (theme === 'outlined') {
            Component = MailIcon;
        }
    }

    return (
        <Component
            className={(className ? className : '') + (dark ? ' dark' : '')}
            color={color}
            color2={color2}
        />
    );
};
