import { useEffect, useRef } from 'react';
import { copy_text } from '../../functions/Utilities';
import { Symbol } from '../elements/Icon';
import './code-block.scss';

export function CodeBlock({
    code = '',
    className = '',
    language = '',
    contentEditable = false,
    lineWrap = false,
}) {
    const codeRef = useRef(null)
    const codeContainerRef = useRef(null);

    useEffect(() => {
        let codeContainer = codeContainerRef.current;
        let code = codeRef.current;
        try {
            window.hljs.highlightBlock(code);
        } catch (err) {}
        if (contentEditable) {
            code.addEventListener('keyup', handleOnCodeEdited);
        }
        return () => {
            if (contentEditable) {
                code.removeEventListener('keyup', handleOnCodeEdited);
            }
        };
    }, []);

    function handleOnCodeEdited(evnt) {
        try {
            codeRef.current.removeAttribute('data-highlighted');
            window.hljs.highlightBlock(codeRef.current);
        } catch (err) {}
    }

    return (
        <div
            ref={codeContainerRef}
            className={`code-block-container ${className}`}>
            <div>
                <pre
                    ref={codeRef}
                    className={`code-container ${lineWrap ? 'wrap-code' : ''} hljs language-${language}`}
                    contentEditable={contentEditable}>
                    {code}
                </pre>
            </div>
            <div
                className="copy-code"
                onClick={(e) => {
                    copy_text(e.target.previousElementSibling.textContent);
                }}>
                <Symbol name="content_copy" />
            </div>
        </div>
    );
}
