import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Symbol } from '../../components/elements/Icon';
import { Text } from '../../components/Text';
import useAlias from '../../hooks/useAlias';
import useSetting from '../../hooks/useSetting';
import { BodyPanelTabs } from '../body';
import { TopPanelTabs } from '../top';

const DatabaseItem = ({ item = null, noBorder = false }) => {
    const aliasCtx = useAlias();
    const settingCtx = useSetting();
    // <Link to={item && item.uid || '#'}>

    const handleLinkClick = (e) => {
        settingCtx.setBodyPanel({ active: BodyPanelTabs.Databaseview });
        settingCtx.setTopPanel({ active: TopPanelTabs.DatabaseIdle });
    };

    return (
        <Link
            onClick={handleLinkClick}
            to={`/${aliasCtx.alias.alias}/${item.uid}`}>
            <div className={'--database'}>
                {item && (
                    <>
                        <div
                            className="--database-icon-box mr-12"
                            style={{ background: item['theme'] }}>
                            <Symbol name={item['icon']} />
                        </div>
                        <div>
                            <Text value={item['label']} />
                        </div>
                    </>
                )}
            </div>
        </Link>
    );
};

const DatabaseGroup = ({ group = null, label = null }) => {
    return (
        <>
            <div className="--database-group">
                <div className="--header">
                    <Text value={label} />
                </div>

                <div className="--body">
                    {group &&
                        group.map((database, x) => {
                            return (
                                <DatabaseItem
                                    key={database.uid}
                                    item={database}
                                />
                            );
                        })}
                </div>
            </div>
        </>
    );
};

export default function AliasviewFullBodyPanel() {
    const aliasCtx = useAlias();

    const [databases, setDatabases] = useState(null);

    useEffect(() => {
        document.body.style.background = '#f7f7f8';
    }, []);

    useEffect(() => {
        aliasCtx.groupDatabases();
    }, [aliasCtx.databases]);

    useEffect(() => {
        setDatabases(aliasCtx.grouped_databases);
        // console.log(aliasCtx.grouped_databases);
    }, [aliasCtx.grouped_databases]);

    return (
        <>
            <div
                className="--panel-section --full-overview"
                style={{ height: 'auto', paddingBottom: 80 }}>
                {/** notification message needed here */}

                {aliasCtx &&
                    aliasCtx.initialized &&
                    databases &&
                    Object.keys(databases).map((key, x) => {
                        return (
                            <DatabaseGroup
                                key={`${key}-${x}`}
                                group={databases[key]}
                                label={key}
                            />
                        );
                    })}
            </div>
        </>
    );
}
