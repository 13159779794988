import React, { useEffect, useState } from 'react';
import Button from '../../../components/Button';
import { Symbol } from '../../../components/elements/Icon';
import { Text } from '../../../components/Text';
import useSetting from '../../../hooks/useSetting';
import { Filter } from './filter';
import useBarelayout from '../../../layouts/barelayout/hook';
import { Resizable } from 'react-quick-resizable';

export default function FilterPanel({ marginLeft_ = 0 }) {
    const settingCtx = useSetting();
    const brCtx = useBarelayout();

    const [visible, setVisible] = useState(null);

    const [resetFilterSignal, setResetFilterSignal] = useState(0);

    useEffect(() => {
        setVisible(settingCtx.filter_panel.visible);
    }, [settingCtx.filter_panel.visible]);

    const hideFilterPanel = (e) => {
        settingCtx.setFilterPanel({
            ...settingCtx.filter_panel,
            visible: false,
        });
    };

    const onResize = (e) => {
        settingCtx.setFilterPanel({
            ...settingCtx.filter_panel,
            width: e.width,
        });
    };

    const clearFilter = () => {
        brCtx.setBasic({
            selectedFilters: [],
        });
        setResetFilterSignal(1);
    };

    return (
        <>
            <Resizable
                defaultWidth={220}
                minWidth={220}
                maxWidth={420}
                defaultHeight={'100%'}
                height={'100%'}
                directions={{
                    right: true,
                }}
                onResize={onResize}
                style={{ marginLeft: `${visible?marginLeft_:0}px` }}
                className={
                    'panel-left filter-panel' +
                    (visible ? ' --visible' : ' --no-visible')
                }>
                <div className={'--inner scroll-container'}>
                    <div className="--section pt-4 pb-4 --header">
                        <div
                            className="--item pl-12"
                            style={{
                                height: 52,
                            }}>
                            <Button
                                onClick={hideFilterPanel}>
                                <Symbol name={'close'} />
                                <Text value={'Close'}/>
                            </Button>
                        </div>
                    </div>
                    <div className="--section pb-8 --content --filters-container">
                        <div style={{ width: '100%' }}>
                            <Filter
                                reset={resetFilterSignal}
                                setReset={setResetFilterSignal}
                            />
                        </div>
                        <div className={'clear-filter-btn-container'}>
                            <Button
                                onClick={() => {
                                    clearFilter();
                                }}
                                className={'--item-text'}
                                style={{
                                    marginTop: 'auto',
                                }}
                                title={'Clear Filter'}>
                                <Symbol
                                    className={'mr-4'}
                                    name="clear_filter_alt"
                                />
                                <Text value={'Clear Filter'} />
                            </Button>
                        </div>
                    </div>
                </div>
            </Resizable>
        </>
    );
}
