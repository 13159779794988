export const FieldsData = [
    {
        type: 'string',
        label: 'Text',
        icon: 'title',
    },

    {
        type: 'rich_text',
        label: 'Rich text',
        icon: 'title',
    },

    {
        type: 'integer',
        label: 'Number',
        icon: 'looks_one',
    },

    {
        type: 'checkbox',
        label: 'Switch',
        icon: 'switch',
    },

    {
        type: 'image',
        label: 'Image',
        icon: 'image',
    },

    /*{
        icon: 'mail',
        label: 'Email',
        type: 'email',
    },

    {
        icon: 'language',
        label: 'URL',
        type: 'link',
    },*/
    {
        icon: 'radio_button_checked',
        label: 'Select',
        type: 'select',
    },
    {
        icon: 'calendar_today',
        label: 'Date',
        type: 'date',
    },
    /*{
        icon: 'category',
        label: 'Kategorie',
        type: 'select',
        attr_type: 'recordsets_categorizer',
        hidden: true
    },*/
];
