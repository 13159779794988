export const BotIcon = ({
    className = null,
    color = undefined,
    color2 = undefined,
}) => {
    return (
        <svg
            className={'ui-sym path' + (className ? ' ' + className : '')}
            width="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M3.33301 17.5V13.3333C3.33301 12.875 3.4962 12.4826 3.82259 12.1562C4.14898 11.8299 4.54134 11.6667 4.99967 11.6667H14.9997C15.458 11.6667 15.8504 11.8299 16.1768 12.1562C16.5031 12.4826 16.6663 12.875 16.6663 13.3333V17.5H3.33301ZM7.49967 10.8333C6.3469 10.8333 5.36426 10.4271 4.55176 9.61458C3.73926 8.80208 3.33301 7.81944 3.33301 6.66667C3.33301 5.51389 3.73926 4.53125 4.55176 3.71875C5.36426 2.90625 6.3469 2.5 7.49967 2.5H12.4997C13.6525 2.5 14.6351 2.90625 15.4476 3.71875C16.2601 4.53125 16.6663 5.51389 16.6663 6.66667C16.6663 7.81944 16.2601 8.80208 15.4476 9.61458C14.6351 10.4271 13.6525 10.8333 12.4997 10.8333H7.49967ZM7.49967 7.5C7.73579 7.5 7.9337 7.42014 8.09342 7.26042C8.25315 7.10069 8.33301 6.90278 8.33301 6.66667C8.33301 6.43056 8.25315 6.23264 8.09342 6.07292C7.9337 5.91319 7.73579 5.83333 7.49967 5.83333C7.26356 5.83333 7.06565 5.91319 6.90592 6.07292C6.7462 6.23264 6.66634 6.43056 6.66634 6.66667C6.66634 6.90278 6.7462 7.10069 6.90592 7.26042C7.06565 7.42014 7.26356 7.5 7.49967 7.5ZM12.4997 7.5C12.7358 7.5 12.9337 7.42014 13.0934 7.26042C13.2531 7.10069 13.333 6.90278 13.333 6.66667C13.333 6.43056 13.2531 6.23264 13.0934 6.07292C12.9337 5.91319 12.7358 5.83333 12.4997 5.83333C12.2636 5.83333 12.0656 5.91319 11.9059 6.07292C11.7462 6.23264 11.6663 6.43056 11.6663 6.66667C11.6663 6.90278 11.7462 7.10069 11.9059 7.26042C12.0656 7.42014 12.2636 7.5 12.4997 7.5Z"
                style={{ fill: color2 }}
                className="app-icon-fill-2"
            />
            <path
                d="M3.33301 17.5V13.3333C3.33301 12.875 3.4962 12.4826 3.82259 12.1562C4.14898 11.8299 4.54134 11.6667 4.99967 11.6667H14.9997C15.458 11.6667 15.8504 11.8299 16.1768 12.1562C16.5031 12.4826 16.6663 12.875 16.6663 13.3333V17.5H3.33301ZM7.49967 10.8333C6.3469 10.8333 5.36426 10.4271 4.55176 9.61458C3.73926 8.80208 3.33301 7.81944 3.33301 6.66667C3.33301 5.51389 3.73926 4.53125 4.55176 3.71875C5.36426 2.90625 6.3469 2.5 7.49967 2.5H12.4997C13.6525 2.5 14.6351 2.90625 15.4476 3.71875C16.2601 4.53125 16.6663 5.51389 16.6663 6.66667C16.6663 7.81944 16.2601 8.80208 15.4476 9.61458C14.6351 10.4271 13.6525 10.8333 12.4997 10.8333H7.49967ZM4.99967 15.8333H14.9997V13.3333H4.99967V15.8333ZM7.49967 9.16667H12.4997C13.1941 9.16667 13.7844 8.92361 14.2705 8.4375C14.7566 7.95139 14.9997 7.36111 14.9997 6.66667C14.9997 5.97222 14.7566 5.38194 14.2705 4.89583C13.7844 4.40972 13.1941 4.16667 12.4997 4.16667H7.49967C6.80523 4.16667 6.21495 4.40972 5.72884 4.89583C5.24273 5.38194 4.99967 5.97222 4.99967 6.66667C4.99967 7.36111 5.24273 7.95139 5.72884 8.4375C6.21495 8.92361 6.80523 9.16667 7.49967 9.16667ZM7.49967 7.5C7.73579 7.5 7.9337 7.42014 8.09342 7.26042C8.25315 7.10069 8.33301 6.90278 8.33301 6.66667C8.33301 6.43056 8.25315 6.23264 8.09342 6.07292C7.9337 5.91319 7.73579 5.83333 7.49967 5.83333C7.26356 5.83333 7.06565 5.91319 6.90592 6.07292C6.7462 6.23264 6.66634 6.43056 6.66634 6.66667C6.66634 6.90278 6.7462 7.10069 6.90592 7.26042C7.06565 7.42014 7.26356 7.5 7.49967 7.5ZM12.4997 7.5C12.7358 7.5 12.9337 7.42014 13.0934 7.26042C13.2531 7.10069 13.333 6.90278 13.333 6.66667C13.333 6.43056 13.2531 6.23264 13.0934 6.07292C12.9337 5.91319 12.7358 5.83333 12.4997 5.83333C12.2636 5.83333 12.0656 5.91319 11.9059 6.07292C11.7462 6.23264 11.6663 6.43056 11.6663 6.66667C11.6663 6.90278 11.7462 7.10069 11.9059 7.26042C12.0656 7.42014 12.2636 7.5 12.4997 7.5Z"
                style={{ fill: color }}
                className="app-icon-fill"
            />
        </svg>
    );
};
