import React, {
    createContext,
    useEffect,
    useReducer,
    useRef,
    useState,
} from 'react';
import useDatabase from '../../hooks/useDatabase';
import { BrDialog, BrDialogTabs } from './dialog';

import {
    getToday,
    getPastDates,
    getDates,
    getMonthFormat,
    T,
    CalendarDays,
    getDayLabel,
    getWithZero,
    generateDadixDatecode,
} from './lib';

import { v4 as uuidV4 } from 'uuid';
import { applySearch, applySortRules } from '../../panels/top/database_full';
import { applyFilter } from '../../panels/left/filterPanel/filter';

export const fieldsetsIdx = {
    SelectorFieldset: 0,
    FAQsFieldset: 1,
    PageFieldset: 2,
    InboxsFieldset: 3,
};
export const recordsetsIdx = {
    FAQsRecordset: 0,
    PageRecordset: 1,
    InboxsRecordset: 2,
};

const initialState = {
    initialized: false,

    loading: null,

    recordsets: [],

    active_recordset: 'all',

    hidden_recordsets: [],

    original_recordsets: [],

    calendar_fieldsets: [],

    timeline_series: [],

    /** LAST DATE CREATED CELLS WITH AUDITS */
    /**
   * format:
   * CELLSET ID: {
        CELL ID: {
          cell: CELL,
          audit: AUDIT
        }
   * }
   */
    last_cells: {},

    fieldsets: [],

    /** active_field index (which field to show on first?, it can be switched by toppanels like for produktion and waste) */
    active_field: 0,

    /** for rightpanel (): {recordidx: 0, recordsetidx: 1, fieldidx: 3, fieldsetidx: 4} (((where it got clicked))) */
    active_record: null,

    active_cell:
        null /** what? {fieldid: 'xxx' , recordid, box number (for temperature etc like input,, it doesn't matter in others). ,, if matched select itself (do focus on its own input)} */,

    /** works with idx */
    collapsed_recordsets: [],

    connected_records: [],

    /** works with uid/id */
    expanded_records: [],

    selected_records: {} /** recordsetidx: [recordidx] */,

    search_query: null,

    active_calendar_datecode: generateDadixDatecode(
        getToday().getDate(),
        getToday().getMonth(),
        getToday().getFullYear()
    ),

    features: {
        qr_search: {
            enabled: false,
        },
    },

    dialog: {
        visible: false,
        tab: null,
        camera: {
            captured_image_data: null,
        },
        cellbox: {
            recordsetidx: null,
            recordidx: null,
            fieldidx: null,
            fieldsetidx: null,
        },
    },

    timeline_conf: {
        from_today: 3 /** from (minus from today) */,
        limit: 6,
    },

    /** select fields options::: {UID: {options: [], attr: null}} */
    select_fields: {},

    image_cells: {},

    selectedFilters: [],
    sortRulesList: [],
    searchOptions: {
        query: null,
        fields: [],
    },

    column_filters: {},

    current_table: {
        recordsetIdx: recordsetsIdx.FAQsRecordset,
        fieldsetIdx: fieldsetsIdx.FAQsFieldset,
    },
};

const handlers = {
    INITIALIZE: (state, action) => {
        return {
            ...state,
            ...(action.payload || {}),
            collapsed_recordsets: [],
            original_recordsets: [],
            search_query: null,
            initialized: true,
            features: {
                qr_search: {
                    enabled: false,
                },
            },
            dialog: {
                visible: false,
                tab: null,
                camera: {
                    captured_image_data: null,
                },
            },
            active_recordset: 'all',
            active_field: 0,
            sortRulesList: [
                {
                    field: {
                        automatic_value: false,
                        id: 'date_of_creation',
                        label: 'Creation Date',
                        type: 'date',
                        value: null,
                        width: { normal: 310 },
                    },
                    rule: 'ascending',
                },
            ],
            current_table: {
                recordsetIdx: recordsetsIdx.FAQsRecordset,
                fieldsetIdx: fieldsetsIdx.FAQsFieldset,
            },
        };
    },

    LOADING: (state, action) => {
        return {
            ...state,
            loading: action.payload.loading,
        };
    },

    BASIC: (state, action) => {
        return {
            ...state,
            ...(action.payload || {}),
        };
    },

    UNINITIALIZE: (state, action) => {
        return {
            ...state,
            ...initialState,
            active_recordset: 'all',
            active_field: 0,
            recordsets: [],
        };
    },
};

const reducer = (state, action) =>
    handlers[action.type] ? handlers[action.type](state, action) : state;

const BarelayoutCtx = createContext({
    ...initialState,
    setBasic: () => {},
    toggleCollapseRecordsets: () => {},
    searchCells: () => {},
    changeWatchingCamera: () => {},
    updateWithCamera: () => {},
    buildTimelineFieldsets: () => {},
    addRecord: () => {},
    updateRecord: () => {},
    updateRecordset: () => {},
    deleteRecordset: () => {},
    deleteRecord: () => {},
    deleteRecords: () => {},
    addField: () => {},
    updateField: () => {},
    deleteField: () => {},
    fetchField: async () => {},
    addRecordset: () => {},
    deleteSelectedRecords: async () => {},
    createOptionForField: async () => {},
    toggleExpandRecords: () => {},
    addRecordsetFirst: async () => {},
});

function BarelayoutProvider({
    children,
    getCalendarCellAudits = () => {},
    ...props
}) {
    const [state, dispatch] = useReducer(reducer, initialState);

    const [watchingCamera, setWatchingCamera] = useState(false);
    const dbCtx = useDatabase();

    useEffect(() => {
        const initialize = () => {
            dispatch({
                type: 'INITIALIZE',
                payload: {
                    active_field: 0,
                },
            });
        };

        const uninitialize = () => {
            dispatch({
                type: 'UNINITIALIZE',
                payload: {},
            });
        };

        // console.log('[barelayout.ctx] mounting');
        initialize();

        return () => {
            // console.log('[barelayout.ctx] unmounting');
            uninitialize();
        };
    }, []);

    useEffect(() => {
        getLastCellAndAudits(state.recordsets);
    }, [state.recordsets]);


    // const applyMultiFilters = (filters) => {
    //     let field_ids = Object.keys(state.column_filters);
    //     let USE_OR = true;
    //     let records_found = [];
    //     state.recordsets[state.current_table.recordsetIdx].records.map((record) => {
    //         let matched_all_conditions = true;
    //         let matched = false;
    //         console.log(state.fieldsets, state.current_table, state.current_table.fieldsetIdx)
    //         state.fieldsets[1].fields.map((field) => {
    //             let rc_cellset = record.cellsets[state.fieldsets[1].id];
    //             if(field_ids.includes(field.id)) {
    //                 console.log("Record :: ", record, rc_cellset.cells[field.id])
    //                 if(rc_cellset.cells[field.id]) {
    //
    //                     let value = rc_cellset.cells[field.id].value;
    //                     if(field.type !== "unknown") {
    //                         if(value.toLowerCase().includes(filters[field.id].toLowerCase())) {
    //                             matched = true;
    //                         }
    //                         else
    //                         {
    //                             matched_all_conditions = false;
    //                         }
    //                     }
    //                 }
    //             }
    //         })
    //         if(matched) {
    //             if(USE_OR) {
    //                 records_found.push(record);
    //             }
    //             else
    //             {
    //                 if(matched_all_conditions) {
    //                     records_found.push(record);
    //                 }
    //             }
    //         }
    //     });
    //
    //     let recordsets_found = [];
    //     recordsets_found = state.recordsets.map((recordset, idx) => {
    //         if (idx === state.current_table.recordsetIdx) {
    //             return {
    //                 ...recordset,
    //                 records: records_found,
    //             };
    //         }
    //         return { ...recordset };
    //     });
    //
    //     return recordsets_found;
    // }


    const applyMultiFilters = (filters) => {
        console.log(state.original_recordsets)
        let field_ids = Object.keys(state.column_filters);
        let USE_OR = false;
        let records_found_raw = [];
        console.log(dbCtx.recordsets)
        let fields_map = {};
        state.fieldsets[1].fields.map((x) => {
            fields_map[x.id] = x;
        })
        dbCtx.recordsets[0].records.map((record) => {
            let matched_all_conditions = true;
            let matched = false;
            // console.log(state.fieldsets, state.current_table, state.current_table.fieldsetIdx)
            record.cells.map((cell) => {
                if(field_ids.includes(cell.fielduid)) {
                    if(cell && cell.value) {
                        let value = cell.value;
                        if(fields_map[cell.fielduid].type !== "unknown") {
                            if(value.toLowerCase().includes(filters[cell.fielduid].toLowerCase())) {
                                matched = true;
                            }
                            else
                            {
                                matched_all_conditions = false;
                            }
                        }
                    }
                }
            })
            if(matched) {
                if(USE_OR) {
                    records_found_raw.push(record.uid);
                }
                else
                {
                    if(matched_all_conditions) {
                        records_found_raw.push(record.uid);
                    }
                }
            }
        });

        let records_found = [];

        state.original_recordsets[state.current_table.recordsetIdx].records.map((rec) => {
            if(records_found_raw.includes(rec.id)) {
                records_found.push(rec);
            }
        })

        let recordsets_found = [];
        recordsets_found = state.recordsets.map((recordset, idx) => {
            if (idx === state.current_table.recordsetIdx) {
                return {
                    ...recordset,
                    records: records_found,
                };
            }
            return { ...recordset };
        });

        return recordsets_found;
    }

    useEffect(() => {

        if(state.column_filters && Object.keys(state.column_filters).length > 0) {

        }
        else
        {
            dispatch({
                type: "BASIC",
                payload: {
                    recordsets: state.original_recordsets || []
                }
            });
            return;
        }

        let rs = applyMultiFilters(state.column_filters);
        setBasic({
            recordsets: rs.slice(0)
        });

    }, [state.column_filters])

    useEffect(() => {
        let rs = JSON.parse(JSON.stringify(state.original_recordsets));
        if (rs.length === 0) {
            return;
        }
        /**apply filter*/
        if (state.selectedFilters.length > 0) {
            rs[0].records = rs[0].records
                .filter((record) =>
                    applyFilter(record, state.selectedFilters, state)
                )
                .slice(0);
        }
        /**apply search*/
        if (
            state.searchOptions.query !== null &&
            state.searchOptions.query.trim().length > 0
        ) {
            rs = applySearch(state.searchOptions, rs, state).slice(0);
        }
        /*apply sort*/
        rs = applySortRules(dbCtx, state, state.sortRulesList, rs).slice(0);
        setBasic({
            recordsets: rs.slice(0),
        });
    }, [state.sortRulesList, state.selectedFilters, state.searchOptions]);

    const getLastDatecodeOfCell = (datecodes, today_cell) => {
        let today = state.active_calendar_datecode;

        let max = null;

        datecodes &&
            Array.isArray(datecodes) &&
            datecodes.map((datecode, x) => {
                // if(datecode === today)
                // {
                // }
                // else
                // {
                let date = datecode.split('-');

                let d = new Date();

                d.setDate(date[0]);
                d.setMonth(date[1]);
                d.setFullYear(date[2]);

                if (max) {
                    if (d > max) {
                        max = d;
                    }
                } else {
                    max = d;
                }
                // }
            });

        return max;
    };

    // useEffect(() => {
    //   console.log(state.active_cell)
    // }, [state.active_cell]);

    const openFAQs = () => {
        setBasic({
            current_table: {
                recordsetIdx: recordsetsIdx.FAQsRecordset,
                fieldsetIdx: fieldsetsIdx.FAQsFieldset,
            },
            selected_records: {},
        });
    };
    const openInbox = () => {
        setBasic({
            current_table: {
                recordsetIdx: recordsetsIdx.InboxsRecordset,
                fieldsetIdx: fieldsetsIdx.InboxsFieldset,
            },
            selected_records: {},
        });
    };
    const openPagePreview = () => {
        setBasic({
            current_table: {
                recordsetIdx: recordsetsIdx.PageRecordset,
                fieldsetIdx: fieldsetsIdx.PageFieldset,
            },
            selected_records: {},
        });
    };

    const getCalendarAgoText = (today, current, auditTimeX) => {
        let tt = today.split('-');
        let todayy = new Date();
        todayy.setDate(tt[0]);
        todayy.setMonth(tt[1]);
        todayy.setFullYear(tt[2]);

        let auditTime = new Date(auditTimeX ? auditTimeX : undefined);
        // console.log(auditTimeX);
        // auditTime.setMinutes(auditTimeX.)

        if (todayy.getFullYear() === current.getFullYear()) {
            if (todayy.getMonth() === current.getMonth()) {
                let daysdiff = todayy.getDate() - current.getDate();

                if (daysdiff === 1) {
                    return `Yesterday ${auditTime.getHours()}:${auditTime.getMinutes()}`;
                } else if (daysdiff > 1) {
                    return `vor ${daysdiff} Tage`;
                } else {
                    if (auditTimeX && auditTime) {
                        return `${auditTime.getHours()}:${auditTime.getMinutes()}`;
                    }
                }
            }
        }

        return generateDadixDatecode(
            current.getDate(),
            current.getMonth(),
            current.getFullYear()
        );
    };

    const getLastCellAndAudits = async (recordsets) => {
        var results = {};

        recordsets &&
            recordsets.map((recordset, x) => {
                recordset.records &&
                    recordset.records.map((record, y) => {
                        // console.log(
                        //     record.calendar_cellsets[
                        //         state.active_calendar_datecode
                        //     ]
                        // );

                        const needed_date = getLastDatecodeOfCell(
                            (record.calendar_cellsets &&
                                Object.keys(record.calendar_cellsets)) ||
                                []
                        );

                        if (needed_date) {
                            const needed_datecode = generateDadixDatecode(
                                needed_date.getDate(),
                                needed_date.getMonth(),
                                needed_date.getFullYear()
                            );

                            let calendar_cellsets =
                                record.calendar_cellsets[needed_datecode];

                            let calendar_cellset =
                                (calendar_cellsets &&
                                    calendar_cellsets[
                                        Object.keys(calendar_cellsets)[0]
                                    ]) ||
                                null;

                            if (calendar_cellset) {
                                let key =
                                    (calendar_cellset.cells &&
                                        Object.keys(calendar_cellset.cells)) ||
                                    null;
                                let cell =
                                    (key &&
                                        calendar_cellset.cells &&
                                        calendar_cellset.cells[key[0]] &&
                                        calendar_cellset.cells[key[0]]) ||
                                    null;

                                if (cell) {
                                    results[record.id] = {
                                        cell: cell,
                                        datecode: needed_datecode,
                                        date: needed_date,
                                        audit: null,
                                        label: '',
                                    };
                                }
                            }
                        }
                    });
            });

        var new_results = [];

        var newest_results = {};

        var cells_to_record_map = {};

        Object.keys(results).map((r) => {
            new_results.push(results[r].cell.id);
            cells_to_record_map[results[r].cell.id] = r;
        });

        if (Object.keys(results).length > 0) {
            await getCalendarCellAudits({
                celluids: new_results,
            }).then((calendar_cells) => {
                // console.log(calendar_cells);

                if (calendar_cells) {
                    calendar_cells.map((c) => {
                        if (c.audit && c.subuser) {
                            // console.log(c.audit);

                            if (
                                c.audit.actiontype &&
                                c.audit.actiontype.type === 'edit'
                            ) {
                                if (
                                    c.audit.differences &&
                                    c.audit.differences[0] &&
                                    c.audit.differences[0].value
                                ) {
                                    let v = c.audit.differences[0].value;
                                    if (v['to'] !== 'true') {
                                        /** filter here value only if true then,, in server, it is already the newest audit */
                                        return;
                                    }
                                }
                            }

                            newest_results[cells_to_record_map[c.celluid]] = {
                                ...results[cells_to_record_map[c.celluid]],
                                audit: {
                                    name:
                                        (c.subuser.cells &&
                                            c.subuser.cells.nameCell &&
                                            c.subuser.cells.nameCell.value) ||
                                        null,
                                },
                                label: getCalendarAgoText(
                                    state.active_calendar_datecode,
                                    results[cells_to_record_map[c.celluid]]
                                        .date,
                                    c.audit.createdAt
                                ),
                            };
                        } else {
                            results[cells_to_record_map[c.celluid]] = undefined;
                        }
                    });
                }
            });
        }

        setBasic({
            last_cells: newest_results,
        });
    };

    const buildTimelineFieldsets = () => {
        /** build time for < 7 > 7 */
        var today = getToday();
        var todayDatecode = generateDadixDatecode(
            today.getDate(),
            today.getMonth(),
            today.getFullYear()
        );

        var wrappers = [];

        let direction = 1;

        /** get - + 4 days from todayy */
        var from = getToday();

        // from.setDate(from.getDate() - 3);
        // var limit = 6;

        from.setDate(from.getDate() - state.timeline_conf.from_today);
        var limit = state.timeline_conf.limit;

        var datelist =
            direction === 1 ? getDates(from, limit) : getPastDates(from, limit);

        var is_editable = true;

        datelist.map((date, x) => {
            var datecode = generateDadixDatecode(
                date.getDate(),
                date.getMonth(),
                date.getFullYear()
            );

            wrappers.push({
                uid: `${datecode}-${x}`,
                date: date,
                datecode: datecode,
                label: `${getDayLabel(
                    CalendarDays[date.getDay()]
                )} ${getWithZero(date.getDate())}.${getWithZero(
                    getMonthFormat(date.getMonth() + 1 /** +1 just for UI */)
                )}.`,
                is_today: datecode === todayDatecode,
                is_editable: is_editable,
                use_default_value: date < today,
                merge_non_editables: true,
            });
        });

        setBasic({
            timeline_series: wrappers,
        });
    };

    const setBasic = (data = {}) => {
        dispatch({
            type: 'BASIC',
            payload: data,
        });
    };

    const toggleCollapseRecordsets = (index) => {
        let x = (state.collapsed_recordsets || []).indexOf(index);

        if (x > -1) {
            let new_collapseds = [...state.collapsed_recordsets];
            new_collapseds.splice(x, 1);
            dispatch({
                type: 'BASIC',
                payload: {
                    collapsed_recordsets: new_collapseds,
                },
            });
        } else {
            dispatch({
                type: 'BASIC',
                payload: {
                    collapsed_recordsets: [
                        ...(state.collapsed_recordsets || []),
                        index,
                    ],
                },
            });
        }
    };

    const toggleExpandRecords = (id) => {
        let x = (state.expanded_records || []).indexOf(id);

        if (x > -1) {
            let new_expandeds = [...state.expanded_records];
            new_expandeds.splice(x, 1);
            dispatch({
                type: 'BASIC',
                payload: {
                    expanded_records: new_expandeds,
                },
            });
        } else {
            dispatch({
                type: 'BASIC',
                payload: {
                    expanded_records: [...(state.expanded_records || []), id],
                },
            });
        }
    };

    const searchCells = (query) => {
        return false;

        let searchFields = state.searchOptions.fields;

        if (!query || query.length < 1) {
            dispatch({
                type: 'BASIC',
                payload: {
                    recordsets: state.original_recordsets || [],
                },
            });

            return;
        }

        let recordsets_found = [];

        state.original_recordsets &&
            state.original_recordsets.map((recordset, x) => {
                let recordsetfound = false;
                let records_found = [];

                recordset &&
                    recordset.records &&
                    recordset.records.map((record, y) => {
                        let recordfound = false;

                        state.fieldsets &&
                            state.fieldsets.map((fieldset, z) => {
                                let cellset = record.cellsets[fieldset.id];

                                fieldset &&
                                    fieldset.fields &&
                                    fieldset.fields.map((field, zz) => {
                                        if (
                                            searchFields.indexOf(field.id) < 0
                                        ) {
                                            return null;
                                        }
                                        if (
                                            !recordfound &&
                                            cellset &&
                                            cellset.cells[field.id] &&
                                            cellset.cells[field.id].value
                                        ) {
                                            let v =
                                                cellset.cells[field.id].value;

                                            if (
                                                v
                                                    .toLowerCase()
                                                    .includes(
                                                        query.toLowerCase()
                                                    )
                                            ) {
                                                recordfound = true;
                                            }
                                        }
                                    });
                            });

                        if (recordfound) {
                            recordsetfound = true;
                            records_found.push(record);
                        }
                    });

                if (recordsetfound) {
                    recordsets_found.push({
                        ...recordset,
                        records: records_found,
                    });
                }
            });

        dispatch({
            type: 'BASIC',
            payload: {
                recordsets: recordsets_found,
            },
        });
    };

    const handleCellUpdate = async (
        data = {
            e: null,
            field: null,
            record: null,
            fieldset: null,
            recordset: null,
            datecode: null,
            is_calendar: null,
            new_value: null,
            calendar_datecode: state.active_calendar_datecode,
        }
    ) => {
        if (!data) return;

        if (!data.field || !data.record || !data.fieldset || !data.recordset)
            return;

        try {
            var field = data.field; /** checking is image required? */

            var opt_image =
                (field.type === 'checkbox' &&
                    field &&
                    field.attributes &&
                    field.attributes['capture_image']) ||
                null;

            if (opt_image) {
                let required = opt_image['required'];
                if (required && required['status'] === 'input_interval') {
                    if (required['interval'] >= required['interval_limit']) {
                        let for_value = required['for_value'] || [];
                        if (for_value.includes(data.new_value)) {
                            data.e.target.checked = false;
                            /** open_camera */
                            setWatchingCamera({
                                status: true,
                                data: data,
                            });
                            setBasic({
                                dialog: {
                                    ...(state.dialog || {}),
                                    visible: true,
                                    tab: BrDialogTabs.CAMERA,
                                },
                            });
                        } else {
                            return updateFinal(data);
                            return;
                        }
                    } else {
                        /** no need of image: as calculated */
                        return updateFinal(data);
                    }
                }
            } else {
                return updateFinal(data);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const updateWithCamera = async (picture) => {
        if (watchingCamera && watchingCamera['status']) {
            var data = watchingCamera['data'];

            await dbCtx
                .createOrUpdateCell({
                    recordsetuid: data.recordset.id,
                    recorduid: data.record.id,
                    fieldsetuid: data.fieldset.id,
                    fielduid: data.field.id,
                    value: data.new_value,
                    datecode: data.calendar_datecode,
                    is_calendar: data.is_calendar,
                    optional_image: picture || null,
                })
                .then(() => {
                    setBasic({
                        dialog: {
                            ...(state.dialog || {}),
                            camera: {
                                ...(state.dialog.camera || {}),
                                captured_image_data: null,
                            },
                            visible: false,
                        },
                    });
                    setWatchingCamera(null);
                });
        } else {
            return;
        }
    };

    const changeWatchingCamera = (w) => {};

    const updateFinal = async (data) => {
        await dbCtx
            .createOrUpdateCell({
                recordsetuid: data.recordset.id,
                recorduid: data.record.id,
                fieldsetuid: data.fieldset.id,
                fielduid: data.field.id,
                value: data.new_value,
                datecode: data.calendar_datecode,
                is_calendar: data.is_calendar,
                optional_image:
                    (state.dialog &&
                        state.dialog.camera &&
                        state.dialog.camera.captured_image_data) ||
                    null,
            })
            .then(() => {
                setBasic({
                    dialog: {
                        ...(state.dialog || {}),
                        camera: {
                            ...(state.dialog.camera || {}),
                            captured_image_data: null,
                        },
                        visible: false,
                    },
                });
                setWatchingCamera(null);
            });
    };

    const addField = (field) => {
        /** it may getting the "selector fieldset", so fix it */
        var fieldset =
            state.fieldsets && state.fieldsets[state.fieldsets.length - 1];

        if (fieldset && fieldset.id === 'selector') {
            fieldset =
                state.fieldsets && state.fieldsets[state.fieldsets.length - 2];
        }

        let fieldsetid = undefined;

        if (fieldset) {
            fieldsetid = fieldset.id;
        }

        var optionals = {};

        if (field.hidden) {
            optionals['attrs'] = {
                ...(optionals['attrs'] || {}),
                hidden: field.hidden,
            };
        }

        if (field.attr_type) {
            optionals['attrs'] = {
                ...(optionals['attrs'] || {}),
                referType: field.attr_type,
            };
        }

        dbCtx.addField({
            label: field.label,
            type: field.type,
            defaultValue: null,
            fieldsetuid: fieldsetid,
            ...(optionals || {}),
        });

        let fieldsets = [...(state.fieldsets || [])];

        /** add record to first recordset */
        let fields = [
            ...((fieldset && fieldset.fields) || []),
            {
                id: uuidV4(),
                attributes: null,
                width: {
                    normal: 200,
                },
                value: null,
                label: field.label,
                type: field.type,
                not_ready: true /** it is fake. (it shows) */,
            },
        ];

        fieldsets[state.fieldsets.length - 1].fields = fields;

        /** add record in promise */

        setBasic({
            fieldsets: fieldsets,
        });
    };

    const updateField = ({
        fieldsetuid = null,
        uid = null,
        noReload = true,
        data = {},
    }) => {
        dbCtx.updateField({
            uid: uid,
            fieldsetuid: fieldsetuid,
            noReload: noReload,
            ...data,
        });
    };

    const createOptionForField = async (
        fieldsetuid = null,
        uid = null,
        data = {}
    ) => {
        return await dbCtx.createOptionForSelectField({
            uid: uid,
            fieldsetuid: fieldsetuid,
            ...data,
        });
    };

    const deleteField = ({ fieldsetuid = null, uid = null }) => {
        dbCtx.deleteField({
            uid: uid,
            fieldsetuid: fieldsetuid,
        });
    };

    const addRecordsetFirst = () => {
        dbCtx.addRecordset({
            label: `Set ${
                (state.recordsets && state.recordsets.length + 1) || 1
            }`,
        });
    };

    const updateRecordset = ({ uid = null, data = {} }) => {
        dbCtx.updateRecordset({
            uid: uid,
            attributes: data.attributes || undefined,
        });
    };

    const deleteRecordset = ({ uid = null }) => {
        dbCtx.deleteRecordset({
            uid: uid,
        });
    };

    const deleteRecord = ({ recordsetuid = null, uid = null }) => {
        dbCtx.deleteRecord({
            recordsetuid: recordsetuid,
            uid: uid,
        });
    };
    const deleteRecords = ({ recordsetuid = null, uids = [] }) => {
        dbCtx.deleteRecords({
            recordsetuid: recordsetuid,
            uids: uids,
        });
    };

    const deleteSelectedRecords = async () => {
        if (state.search_query && state.search_query.length > 0) {
            window.alert('This action is under development.');
            return;
        }

        var recordsets = state.recordsets;

        if (
            state.selected_records &&
            Object.keys(state.selected_records).length > 0
        ) {
            let selected_records = { ...state.selected_records };
            setBasic({
                selected_records: {},
            });
            return await Promise.all(
                Object.keys(selected_records).map(async (recordsetidx, x) => {
                    let recordset = recordsets[recordsetidx];
                    await deleteRecords({
                        recordsetuid: recordset.id,
                        uids: selected_records[recordsetidx],
                    });
                })
            );
        } else {
            return;
        }
    };

    const updateRecord = ({ uid = null, recordsetuid = null, data = {} }) => {
        dbCtx.updateRecord({
            uid: uid,
            recordsetuid: recordsetuid,
            ...data,
        });
    };

    const addRecordset = ({ label = null }) => {
        dbCtx.addRecordset({
            label: label,
        });
    };

    const addRecord = () => {
        if (!state.recordsets || state.recordsets.length < 1) {
            let recordset = {
                id: uuidV4(),
                attributes: null,
                label: `Set ${
                    (state.recordsets && state.recordsets.length + 1) || 1
                }`,
                not_ready: true,
                records: [],
            };
            setBasic({
                recordsets: [recordset],
            });
            addRecordsetFirst();
            return;
        }

        let recordsets = [...(state.recordsets || [])];

        return dbCtx.addRecord({
            recordsetuid: recordsets[state.current_table.recordsetIdx].id,
            attributes: null,
        });
    };

    const fetchField = async (
        data = {
            uid: null,
            fieldsetuid: null,
            is_calendar: null,
        }
    ) => {
        return await dbCtx.fetchField(data);
    };

    const fetchFieldsets = (fieldsets, is_calendar = false) => {
        fieldsets.map((fieldset, x) => {
            fieldset.fields &&
                fieldset.fields.map((field, y) => {
                    if (
                        (field.type === 'select' || field.type === 'subuser') &&
                        !state.select_fields[field.id]
                    ) {
                        if (
                            field.attributes &&
                            field.attributes.referType ===
                                'recordsets_categorizer'
                        ) {
                            return;
                        }

                        fetchField({
                            uid: field.id,
                            fieldsetuid: fieldset.id,
                            is_calendar: is_calendar,
                        }).then((res) => {
                            if (res.status === 200) {
                                var optionlist = [];

                                var optionsets = res.data.field.others.options;

                                optionsets &&
                                    Array.isArray(optionsets) &&
                                    optionsets.map((optset, x) => {
                                        optset &&
                                            optset.records &&
                                            Array.isArray(optset.records) &&
                                            optset.records.map((rec, y) => {
                                                optionlist.push({
                                                    id: rec.uid,
                                                    parentId: optset.uid,
                                                    attributes: rec.attributes,
                                                    value: rec.uid,
                                                    label:
                                                        (rec.cell &&
                                                            rec.cell.value) ||
                                                        '<empty>',
                                                });
                                            });
                                    });

                                var newoptions = {};

                                newoptions[field.id] = {
                                    options: optionlist,
                                };

                                // console.log(newoptions)

                                setBasic({
                                    select_fields: {
                                        ...state.select_fields,
                                        ...newoptions,
                                    },
                                });
                            }
                        });
                    }
                });
        });
    };

    useEffect(() => {
        if (state.calendar_fieldsets) {
            fetchFieldsets(state.calendar_fieldsets, true);
        }
    }, [state.calendar_fieldsets]);

    useEffect(() => {
        if (state.fieldsets) {
            fetchFieldsets(state.fieldsets, false);
        }
    }, [state.fieldsets]);

    return (
        <BarelayoutCtx.Provider
            value={{
                ...state,
                setBasic,
                toggleCollapseRecordsets,
                searchCells,
                handleCellUpdate,
                changeWatchingCamera,
                updateWithCamera,
                buildTimelineFieldsets,
                addRecord,
                updateRecord,
                updateRecordset,
                deleteRecordset,
                deleteRecord,
                deleteRecords,
                addField,
                updateField,
                deleteField,
                fetchField,
                addRecordset,
                deleteSelectedRecords,
                createOptionForField,
                toggleExpandRecords,
                addRecordsetFirst,
                openFAQs,
                openInbox,
                openPagePreview,
            }}>
            {children}

            <BrDialog />
        </BarelayoutCtx.Provider>
    );
}

export { BarelayoutCtx, BarelayoutProvider };
