import React, { useEffect, useState } from 'react';
import useAlias from '../../hooks/useAlias';
import useSetting from '../../hooks/useSetting';
import BodyPanel, { BodyPanelTabs } from '../../panels/body';
import TopPanel, { TopPanelTabs } from '../../panels/top';
import { AliasViewsList } from './homepage';

export default function AliasOverview() {
    const aliasCtx = useAlias();
    const settingCtx = useSetting();
    const [locked, setLocked] = useState(null);
    const [dbs, setDbs] = useState(null);

    useEffect(() => {
        if (settingCtx.view_mode === AliasViewsList.FullControl) {
            settingCtx.setTopPanel({
                active: TopPanelTabs.AliasViewFull,
            });

            settingCtx.setBodyPanel({
                active: BodyPanelTabs.AliasDashboardFull,
            });
        } else if (settingCtx.view_mode === AliasViewsList.SimpleControl) {
            settingCtx.setTopPanel({
                active: TopPanelTabs.AliasView,
            });

            settingCtx.setBodyPanel({
                active: BodyPanelTabs.AliasDashboard,
            });
        }
    }, [settingCtx.view_mode]);

    useEffect(() => {
        if (aliasCtx.initialized && aliasCtx.alias && aliasCtx.databases) {
            let dbs_ = aliasCtx.databases;
            setDbs(dbs_);
            setLocked(aliasCtx.alias.locked);
        }
    }, [aliasCtx.initialized, aliasCtx.databases]);

    useEffect(() => {
        if (
            aliasCtx.initialized &&
            !aliasCtx.loading &&
            locked != null &&
            dbs != null
        ) {
            if (!locked) {
                console.log('alias is not locked.');
                if (settingCtx.view_mode === AliasViewsList.FullControl) {
                    settingCtx.setBodyPanel({
                        active: BodyPanelTabs.AliasDashboardFull,
                    });
                } else {
                    settingCtx.setBodyPanel({
                        active: BodyPanelTabs.AliasDashboard,
                    });
                }
            } else {
                console.log('alias is locked.');
                settingCtx.setBodyPanel({
                    active: BodyPanelTabs.AliasUnlock,
                });
            }
        }
    }, [locked, dbs]);

    return (
        <>
            {!locked && <TopPanel />}

            <BodyPanel />
        </>
    );
}
