import React, { useEffect } from 'react';
import useAlias from '../../hooks/useAlias';
import useDatabase from '../../hooks/useDatabase';
import useSetting from '../../hooks/useSetting';
import { BarelayoutProvider } from '../../layouts/barelayout/ctx';
import BodyPanel, { BodyPanelTabs } from '../../panels/body';
import TopPanel, { TopPanelTabs } from '../../panels/top';
import { AliasViewsList } from '../alias/homepage';

export default function Databaseview() {
    const aliasCtx = useAlias();
    const dbCtx = useDatabase();
    const settingCtx = useSetting();

    useEffect(() => {
        if (aliasCtx.initialized && aliasCtx.alias && aliasCtx.member) {
            /** check if it is owner of alias then that view, or if signed subuser have "manager" scope, then that view */
            if (aliasCtx.member.scope.includes('owner')) {
                settingCtx.setViewmode(AliasViewsList.FullControl);
            } else if (aliasCtx.subuser) {
                /** check subuser */
                if (aliasCtx.subuser.scope.cell.scopes.includes('manager')) {
                    settingCtx.setViewmode(AliasViewsList.FullControl);
                } else {
                    settingCtx.setViewmode(AliasViewsList.SimpleControl);
                }
            }
        } else {
            /** till doesn't alias loaded or unlocked use simple. */
            settingCtx.setViewmode(AliasViewsList.SimpleControl);
        }
    }, [aliasCtx.alias, aliasCtx.initialized, aliasCtx.subuser]);

    useEffect(() => {
        // settingCtx.setTopPanel({
        //   active: TopPanelTabs.DatabaseIdle,
        // });

        // settingCtx.setBodyPanel({
        //   active: BodyPanelTabs.Databaseview,
        // });

        if (settingCtx.view_mode === AliasViewsList.FullControl) {
            settingCtx.setTopPanel({
                active: TopPanelTabs.DatabaseFullIdle,
            });

            settingCtx.setBodyPanel({
                active: BodyPanelTabs.DatabaseviewFull,
            });
        } else if (settingCtx.view_mode === AliasViewsList.SimpleControl) {
            settingCtx.setTopPanel({
                active: TopPanelTabs.DatabaseIdle,
            });

            settingCtx.setBodyPanel({
                active: BodyPanelTabs.Databaseview,
            });
        }
    }, [settingCtx.view_mode]);

    return (
        <>
            {/** database browser */}
            <BarelayoutProvider
                getCalendarCellAudits={dbCtx.getCalendarCellAudits}>
                {settingCtx.view_mode === AliasViewsList.SimpleControl && (
                    <TopPanel />
                )}

                <BodyPanel />
            </BarelayoutProvider>
        </>
    );
}
