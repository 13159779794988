import './eventspage.scss';
import Page from '../../components/Page';
import { Text } from '../../components/Text';
import FaqnationWidget from '../../components/faqnationweidget/FaqnationWidget';
import Container from '../../layouts/mainlayout/Container';
import MainLayout from '../../layouts/mainlayout/MainLayout';

export default function EventsPage() {
    return (
        <Page title="FAQNation - Events">
            <MainLayout>
                <section style={{ marginBottom: '100px', marginTop: '80px' }}>
                    <h3>Events</h3>
                    <Container>
                        <FaqnationWidget
                            faqId={'events_faqnationWidgetWrapper'}
                            apiBase={'https://api.faqnation.com'}
                            faqnationProjectId={'tp_6yqEUlJt'}
                            faqnationUserId={'JI7Jm4PdoPWB9SUNfOTxA'}
                            faqnationId={'o4BcCLllWAXBbAWkTtrdRxAsXTcM29odyEUj'}
                            faqsLimit={6}
                            options={{
                                hideSearch: true,
                                hideTopics: true,
                                hideSendQuestion: true,
                                colors: {
                                    primary_clr_light: '#3f4c63',
                                    on_primary_clr_light: '#fff',
                                },
                            }}
                        />
                        <div
                            className="events-subscribe-btn-container"
                            style={{ textAlign: 'center' }}>
                            <a
                                href="#"
                                className="secondary-btn events-subscribe-btn animateOnHover">
                                <Text value="Subscribe for Events" />
                            </a>
                        </div>
                    </Container>
                </section>
            </MainLayout>
        </Page>
    );
}
