import React, { createContext, useEffect, useReducer, useRef } from 'react';
import { useCookies } from 'react-cookie';
import { CookiesArray, CookiesList } from '../constants/Cookies';
import Account from '../functions/Account';
import { getExpirationTime } from '../functions/Utilities';
import AssignSession from 'assign-login';
import { SessionType } from 'assign-login/dist/lib/requestSession';

const initialState = {
    initialized: false,
    error: null,
    response: null /** tried a time? */,
    authenticated: false,
    account: null,
    aliases: null,
};

const handlers = {
    INITIALIZE: (state, action) => {
        const { account } = action.payload;

        return {
            ...state,
            ...(action.payload || {}),
            aliases: (account && account.aliases) || null,
            initialized: true,
        };
    },

    LOGIN: (state, action) => {
        return {
            ...state,
            ...(action.payload || {}),
        };
    },

    BASIC: (state, action) => {
        return {
            ...state,
            ...(action.payload || {}),
        };
    },
};

const reducer = (state, action) =>
    handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext({
    ...initialState,
    login: () => {},
    saveAliasLogin: () => {},
    removeAliasLogin: () => {},
    refreshLogin: async () => {},
    reInitialize: async () => {},
});

function AuthProvider({ children }) {
    const isLoggedIn = useRef(false);
    const [state, dispatch] = useReducer(reducer, initialState);
    const [cookies, setCookie, removeCookie] = useCookies(CookiesArray);

    useEffect(() => {
        const initialize = async () => {
            if (
                cookies[CookiesList.accessToken] &&
                cookies[CookiesList.accessToken] !== ''
            ) {
                var _response = null;

                _response = await Account.me();
                if (_response && _response.status === 200) {
                    dispatch({
                        type: 'INITIALIZE',
                        payload: {
                            authenticated: _response.status === 200,
                            account:
                                (_response.data && _response.data.account) ||
                                null,
                            response: _response,
                        },
                    });
                } else {
                    if (
                        _response.statusText === 'Unauthorized' ||
                        _response.statusText === 'Not Found'
                    ) {
                        removeLoginCookies({ redirectToMain: false });
                    }
                    dispatch({
                        type: 'INITIALIZE',
                        payload: {
                            authenticated: false,
                            account: null,
                        },
                    });
                }
            } else {
                dispatch({
                    type: 'INITIALIZE',
                    payload: {
                        authenticated: false,
                        account: null,
                    },
                });
            }
        };

        initialize();

        return () => {};
    }, []);

    useEffect(() => {
        isLoggedIn.current = state.authenticated && state.initialized;
    }, [state]);

    const reInitialize = async () => {
        if (
            cookies[CookiesList.accessToken] &&
            cookies[CookiesList.accessToken] != ''
        ) {
            var _response = null;

            _response = await Account.me();

            if (_response) {
                dispatch({
                    type: 'INITIALIZE',
                    payload: {
                        authenticated: _response.status === 200,
                        account:
                            (_response.data && _response.data.account) || null,
                        response: _response,
                    },
                });
            }
        } else {
            dispatch({
                type: 'INITIALIZE',
                payload: {
                    authenticated: false,
                    account: null,
                },
            });
        }
    };

    const setLoginCookies = ({
        accessToken = null,
        refreshToken = null,
        rememberMe = false,
    }) => {
        //remove OTP cookies
        removeCookie('faqnation_user', { path: '/' });
        removeCookie('faqnation_user_email', { path: '/' });

        setCookie(CookiesList.accessToken, accessToken, {
            expires: getExpirationTime(rememberMe ? 28 : .5),
            path: '/',
        });
        setCookie(CookiesList.refreshToken, refreshToken, {
            expires: getExpirationTime(rememberMe ? 28 : .5),
            path: '/',
        });
    };

    const removeLoginCookies = ({ redirectToMain = false }) => {
        removeCookie(CookiesList.accessToken, { path: '/' });
        removeCookie(CookiesList.refreshToken, { path: '/' });

        if (redirectToMain) {
            window.location.assign('/');
        }
    };

    const saveAliasLogin = ({ accessToken = null, uid = null }) => {
        localStorage.setItem(`${uid}`, `${accessToken}`);
    };

    const removeAliasLogin = ({ uid = null }) => {
        localStorage.removeItem(`${uid}`);
        try {
            localStorage.clear();
        } catch (e) {}
    };

    const checkIfUserExist = async ({ email = undefined }) => {
        const response = await Account.checkIfUserExist({
            email: email,
        });

        return response['data'];
    };

    const sendResetPasswordRequest = async ({ email = undefined }) => {
        const response = await Account.sendResetPasswordRequest({
            email: email,
        });

        return new Promise((resolve, reject) => {
            if (response.status === 200) {
                resolve({
                    status: response.status,
                    msg: 'email_sent',
                    err: false,
                });
            }
            if (response.status === 400) {
                resolve({
                    status: response.status,
                    msg: 'limit_reached',
                    err: true,
                });
            }
            if (response.status === 404) {
                resolve({
                    status: response.status,
                    msg: 'no_account_found',
                    err: true,
                });
            }
            resolve({
                status: response.status,
                msg: 'faild_to_send_email',
                err: true,
            });
        });
    };

    const resetPassword = async ({
        confirmKey = undefined,
        newPassword = undefined,
    }) => {
        const response = await Account.resetPassword({
            confirmKey: confirmKey,
            newPassword: newPassword,
        });

        return new Promise((resolve, reject) => {
            if (response.status === 200) {
                resolve({
                    status: response.status,
                    msg: 'password_updated',
                    err: false,
                });
            }
            if (response.status === 400) {
                resolve({
                    status: response.status,
                    msg: 'invalid_password',
                    err: true,
                });
            }
            if (response.status === 404) {
                resolve({
                    status: response.status,
                    msg: 'request_expired',
                    err: true,
                });
            }
            resolve({
                status: response.status,
                msg: 'error_reset_password',
                err: true,
            });
        });
    };

    const confirmResetPasswordRequest = async ({ key = undefined }) => {
        const response = await Account.confirmResetPasswordRequest({
            key: key,
        });

        console.log(response.status);
        return new Promise((resolve, reject) => {
            console.log(response.status);
            if (response.status === 200) {
                resolve({
                    status: response.status,
                    msg: 'confirmed',
                    key: response.data.confirm_key,
                    email: response.data.email,
                    err: false,
                });
            }
            if (response.status === 400) {
                resolve({
                    status: response.status,
                    msg: 'request_expired',
                    err: true,
                });
            }
            resolve({
                status: response.status,
                msg: 'error',
                err: true,
            });
        });
    };

    const login = async (
        { email = null, password = null, useCb = false, rememberMe = false },
        cb
    ) => {
        const response = await Account.login({
            email: email,
            password: password,
        });

        let _result,
            _error = false;
        if (response.status === 200) {
            /** filter values [@todo] */
            _result = response.data.account;
            setLoginCookies({
                ...response.data.authorization,
                rememberMe: rememberMe,
            });
        } else {
            return new Promise((resolve, reject) => {
                resolve({ error: true, status: response.status });
            });
        }

        dispatch({
            type: 'LOGIN',
            payload: {
                response: response,
                account: _result,
                error: _error,
                authenticated: _error ? false : true,
            },
        });
    };

    const signup = async (
        { email = null, password = null, useCb = false },
        cb
    ) => {
        const response = await Account.signup({
            email: email,
            password: password,
        });

        //console.log(response);

        let _result,
            _error = false;
        if (response.status === 200) {
            /** filter values [@todo] */
            _result = response.data.account;
            setLoginCookies({
                ...response.data.authorization,
                rememberMe: false,
            });
        } else {
            return new Promise((resolve, reject) => {
                resolve({ error: true, status: response.status, error_msg: response.data.message });
            });
        }

        dispatch({
            type: 'LOGIN',
            payload: {
                response: response,
                account: _result,
                error: _error,
                authenticated: _error ? false : true,
            },
        });
    };

    const verify = async ({ code = null, token = null }) => {
        const response = await Account.verify({
            code: code,
            token: token,
        });

        let _result;
        let _error;

        if (response.status === 200) {
            /** filter values [@todo] */
            _result = response.data.account;
            setLoginCookies(response.data.authorization);
        } else {
            _error = true;
        }

        dispatch({
            type: 'LOGIN',
            payload: {
                response: response,
                account: _result,
                error: _error,
                authenticated: _error ? false : true,
            },
        });

        return response;
    };

    const refreshLogin = async () => {
        var response = await Account.refreshToken({
            refreshToken: cookies[CookiesList.refreshToken],
        });
        if (response.status === 200) {
            // setLoginCookies(response.data.account.authorization);
            // return response.data.account.authorization;
            setLoginCookies(response.data.authorization);
            return response.data.authorization;
        }
        return {
            accessToken: null,
            refreshToken: null,
        };
    };

    const updateAccount = async ({
        name = undefined,
        newsletter = undefined,
        user_questions = undefined,
        date_format = undefined,
        time_format = undefined,
    }) => {
        let newOptions = {};
        if (name) {
            newOptions['profile'] = {
                ...state.account['profile'],
                firstName: name,
            };
        }
        if (newsletter !== undefined) {
            newOptions['profile'] = {
                ...state.account['profile'],
                newsletter: newsletter,
            };
        }
        if (user_questions !== undefined) {
            newOptions['profile'] = {
                ...state.account['profile'],
                user_questions: user_questions,
            };
        }
        if (date_format) {
            newOptions.date_format = date_format;
        }
        if (time_format) {
            newOptions.time_format = time_format;
        }
        dispatch({
            type: 'BASIC',
            payload: {
                account: { ...state.account, ...newOptions },
            },
        });
        return await Account.updateAccount({
            name: name,
            newsletter: newsletter,
            user_questions: user_questions,
            date_format: date_format,
            time_format: time_format,
        });
    };

    const setBasic = (payload) => {
        dispatch({
            type: 'BASIC',
            payload: {
                ...payload,
            },
        });
    };

    return (
        <AuthContext.Provider
            value={{
                ...state,
                checkIfUserExist,
                sendResetPasswordRequest,
                confirmResetPasswordRequest,
                resetPassword,
                login,
                signup,
                verify,
                updateAccount,
                setLoginCookies,
                removeLoginCookies,
                saveAliasLogin,
                removeAliasLogin,
                refreshLogin,
                reInitialize,
                setBasic,
            }}>
            {children}
        </AuthContext.Provider>
    );
}

export { AuthContext, AuthProvider };
