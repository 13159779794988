export const ModeIcon = ({ className = null, color = undefined }) => {
    return (
        <svg
            className={'ui-sym path' + (className ? ' ' + className : '')}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_17_1769)">
                <path
                    d="M14.06 9.02L14.98 9.94L5.92 19H5V18.08L14.06 9.02V9.02ZM17.66 3C17.41 3 17.15 3.1 16.96 3.29L15.13 5.12L18.88 8.87L20.71 7.04C21.1 6.65 21.1 6.02 20.71 5.63L18.37 3.29C18.17 3.09 17.92 3 17.66 3V3ZM14.06 6.19L3 17.25V21H6.75L17.81 9.94L14.06 6.19V6.19Z"
                    style={{ fill: color }}
                />
            </g>
            <defs>
                <clipPath id="clip0_17_1769">
                    <rect
                        width="24"
                        height="24"
                        fill="white"
                    />
                </clipPath>
            </defs>
        </svg>
    );
};
