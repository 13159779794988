import React, { forwardRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import FormLabel from './FormLabel';
import FormHint from './FormHint';
import { Symbol } from './elements/Icon';

const propTypes = {
    children: PropTypes.node,
    label: PropTypes.node,
    labelHidden: PropTypes.bool,
    type: PropTypes.oneOf([
        'textarea',
        'span',
        'text',
        'email',
        'tel',
        'password',
        'number',
        'search',
        'color',
        'date',
        'time',
        'checkbox',
        'url',
        'datetime-local',
    ]),
    name: PropTypes.string,
    status: PropTypes.string,
    disabled: PropTypes.bool,
    value: PropTypes.string,
    formGroup: PropTypes.string,
    hasIcon: PropTypes.string,
    size: PropTypes.string,
    placeholder: PropTypes.string,
    rows: PropTypes.number,
    boxClassName: PropTypes.string,
    hint: PropTypes.node,
};

const defaultProps = {
    children: null,
    label: null,
    labelHidden: false,
    type: 'text',
    name: undefined,
    status: '',
    disabled: false,
    value: undefined,
    formGroup: null,
    hasIcon: null,
    size: '',
    placeholder: '',
    rows: 3,
    boxClassName: '',
    hint: null,
};

const Input = forwardRef(
    (
        {
            className,
            children,
            label,
            labelHidden,
            type,
            name,
            status,
            disabled,
            value,
            formGroup,
            hasIcon,
            size,
            placeholder,
            rows,
            boxClassName,
            hint,
            key,
            ...props
        },
        ref
    ) => {
        const [inputValue, setInputValue] = useState('');
        useEffect(() => {
            if (props.autoFocus) {
                if (type !== 'span') return;
                let focusTimeout = setTimeout(() => {
                    try {
                        let s = window.getSelection();
                        let r = document.createRange();
                        r.setStart(ref.current, 0);
                        r.setEnd(ref.current, 0);
                        s.removeAllRanges();
                        s.addRange(r);
                    } catch (err) {
                        console.error(err);
                    }
                }, 100);
                return () => {
                    clearTimeout(focusTimeout);
                };
            }
        }, []);
        useEffect(() => {
            if (type === 'span' && ref.current) {
                ref.current.innerText = value;
            }
            setInputValue(value);
        }, [value]);
        const wrapperClasses = classNames(
            'form-input-wrapper',
            formGroup &&
                formGroup !== '' &&
                (formGroup === 'desktop' ? 'form-group-desktop' : 'form-group'),
            hasIcon && hasIcon !== '' && 'has-icon-' + hasIcon,
            className && 'wrapper-' + className
        );

        const classes = classNames(
            'form-input',
            size && `form-input-${size}`,
            status && `form-${status}`,
            className
        );

        const boxClasses = classNames(
            'form-input-box',
            boxClassName,
            size && `form-input-box-${size}`
        );

        const Component =
            type === 'textarea'
                ? 'textarea'
                : type === 'span'
                ? 'span'
                : 'input';

        const getType = () => {
            if (type === 'textarea' || type === 'span') {
                return undefined;
            }
            return type;
        };

        return (
            <>
                <div className={boxClasses}>
                    {label && (
                        <FormLabel labelHidden={labelHidden} id={props.id}>
                            {label}
                        </FormLabel>
                    )}
                    <div className={wrapperClasses}>
                        {hasIcon && (
                            <Symbol
                                className={'icon'}
                                name={hasIcon}
                                style={{
                                    userSelect: 'none',
                                    WebkitUserSelect: 'none',
                                }}
                            />
                        )}
                        {Component === 'span' ? (
                            <Component
                                {...props}
                                ref={ref}
                                key={key}
                                onDrop={(e) => {
                                    e.preventDefault();
                                }}
                                onPaste={(evnt) => {
                                    try {
                                        if (props.onPaste) {
                                            props.onPaste(evnt);
                                        } else {
                                            evnt.preventDefault();
                                            let text =
                                                evnt.clipboardData.getData(
                                                    'text/plain'
                                                );
                                            const selection =
                                                window.getSelection();
                                            try {
                                                if (selection.rangeCount) {
                                                    let range =
                                                        selection.getRangeAt(0);
                                                    range.deleteContents();
                                                }
                                            } catch (err) {}
                                            if (
                                                selection.focusNode.insertData
                                            ) {
                                                selection.focusNode.insertData(
                                                    selection.anchorOffset,
                                                    text
                                                );
                                            } else {
                                                selection.focusNode.appendChild(
                                                    document.createTextNode(
                                                        text
                                                    )
                                                );
                                            }
                                            setInputValue(ref.current.innerText);
                                        }
                                    } catch (err) {
                                        console.err(err);
                                    }
                                }}
                                className={`${classes} ${
                                    type === 'span' ? 'text-input' : ''
                                }`}
                                name={name}
                                disabled={disabled}
                                contentEditable={`${
                                    type === 'span' ? 'true' : ''
                                }`}
                                onInput={(evnt) => {
                                    setInputValue(ref.current.innerText);
                                }}
                                placeholder={`${
                                    (inputValue ?? '').length > 0 &&
                                    inputValue !== '\n'
                                        ? ''
                                        : placeholder
                                }`}
                                onBlur={(evnt) => {
                                    const selection = document.getSelection();
                                    selection.empty();
                                    try {
                                        props.onBlur(evnt);
                                    } catch (err) {}
                                }}
                                /*dangerouslySetInnerHTML={{
                                    __html: `${type === 'span' ? value : null}`,
                                }}*/
                            >
                                {/*children*/}
                                {/*value*/}
                            </Component>
                        ) : (
                            <>
                                <label
                                    htmlFor={props.id}
                                    style={{ display: 'none' }}></label>
                                <Component
                                    {...props}
                                    ref={ref}
                                    key={key}
                                    id={props.id}
                                    type={getType()}
                                    className={`${classes}`}
                                    name={name}
                                    disabled={disabled}
                                    value={inputValue}
                                    onChange={(evnt) => {
                                        setInputValue(evnt.target.value);
                                        try {
                                            props.onChange(evnt);
                                        } catch (err) {}
                                    }}
                                    placeholder={placeholder}
                                    rows={type === 'textarea' ? rows : null}>
                                    {children}
                                </Component>
                            </>
                        )}
                    </div>
                    {hint && <FormHint status={status}>{hint}</FormHint>}
                </div>
            </>
        );
    }
);

Input.propTypes = propTypes;
Input.defaultProps = defaultProps;

export default Input;
