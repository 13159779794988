export const apiRoutes = {
    auth: {
        assignLogin: 'auth/assign',
        checkIfUserExist: 'auth/check_exist',
        sendResetPasswordRequest: 'auth/request_password_reset',
        confirmResetPasswordRequest: 'auth/reset_password',
        resetPassword: 'auth/change_password',
        // login: 'auth/login_v2',
        login: 'auth/access',
        signup: 'auth/signup_v2',
        verify: 'auth/verify',
        refresh: 'auth/refresh',
        removeAccount: 'auth',
    },

    account: {
        me: 'account',
        update: 'account',
        space: 'space',
        questionsNotifications: 'account/count_notifications',
        changeEmail: '/account/change_email',
    },

    alias: {
        getAlias: 'alias/{aliasuid}',
        loginAlias: 'alias/access/{aliasuid}',
    },

    database: {
        createDatabase: 'template/{aliasuid}',
        getDatabase: 'template/{aliasuid}/{databaseuid}',
        getDatabaseLimits: 'pay/upgrade/{databaseuid}',
        getDatabaseImage: 'template/image/null/{imageuid}',
        deleteDatabase: 'template/{aliasuid}/{databaseuid}',
        updateDatabase: 'template/{aliasuid}/{databaseuid}',
        getDatabaseRecords: 'template/records/{aliasuid}/{databaseuid}',
        getDatabaseRecord:
            'template/record/{aliasuid}/{databaseuid}/{recordsetuid}/{recorduid}',
        /*getConnectedAliasesDatabaseRecords:
            'template/records/all/{aliasuid}/{databasename}',*/
        createOrUpdateCell:
            'template/cellAuto/{aliasuid}/{databaseuid}/{recordsetuid}/{recorduid}/{fieldsetuid}/{fielduid}',
        createOrUpdateCalendarCell:
            'template/calendarCellAuto/{aliasuid}/{databaseuid}/{recordsetuid}/{recorduid}/{fieldsetuid}/{fielduid}',
        getCalendarCellAudits:
            'template/audits/calendarCell/{aliasuid}/{databaseuid}',
        createRecordset: 'template/recordset/{aliasuid}/{databaseuid}',
        updateRecordset:
            'template/recordset/{aliasuid}/{databaseuid}/{recordsetuid}',
        deleteRecordset:
            'template/recordset/{aliasuid}/{databaseuid}/{recordsetuid}',
        createRecord: 'template/record/{aliasuid}/{databaseuid}/{recordsetuid}',
        deleteRecord:
            'template/record/{aliasuid}/{databaseuid}/{recordsetuid}/{recorduid}',
        updateRecord:
            'template/record/{aliasuid}/{databaseuid}/{recordsetuid}/{recorduid}',
        createFieldset: 'template/fieldset/{aliasuid}/{databaseuid}',
        createAutoFieldAndFieldset:
            'template/fieldAuto/{aliasuid}/{databaseuid}',
        createField: 'template/field/{aliasuid}/{databaseuid}/{fieldsetuid}',
        updateField:
            'template/field/{aliasuid}/{databaseuid}/{fieldsetuid}/{fielduid}',
        deleteField:
            'template/field/{aliasuid}/{databaseuid}/{fieldsetuid}/{fielduid}',
        createOptionForField:
            'template/option/field/{aliasuid}/{databaseuid}/{fieldsetuid}/{fielduid}',
        getField:
            'template/field/{aliasuid}/{databaseuid}/{fieldsetuid}/{fielduid}',
        getCalendarField:
            'template/calendarField/{aliasuid}/{databaseuid}/{fieldsetuid}/{fielduid}',
        uploadProjectIcon: 'template/{aliasuid}/{databaseuid}/icon',
        export: 'template/export/:aliasuid/:templateuid',
        import: 'template/import/:aliasuid/:templateuid',
    },
    api: {
        getAPIKeys: 'template/api/{aliasuid}/{databaseuid}',
        addNewAPIKey: 'template/api/{aliasuid}/{databaseuid}',
        deleteAPIKey: 'template/api/{aliasuid}/{databaseuid}',
    },

    plans: {
        getPlans: 'pay/plans',
        getSubscribeLink: 'pay/subscribe',
        getBuyAddonLink: 'pay/subscribe',
        getUserLimitations: 'pay/upgrade',
        upgradeProject: '/account/upgrade_project',
    },
    licenseKey: {
        applyKey: 'account/apply_license',
    },
    AI: {
        generateFAQs: 'template/{aliasuid}/{databaseuid}/generate_questions',
        generateTags:
            'template/{aliasuid}/{databaseuid}/generate_tags/{questionid}',
    },

    gh: {
        auth: 'gh/auth?accountId={accountuid}&projectId={projectuid}',
        getRepos: 'gh/repos',
        connectRepo:
            'gh/connect_repo?accountId={accountuid}&projectId={projectuid}&repoId={repoid}&repoUrl={repourl}',
    },
};
