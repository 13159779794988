import { useEffect } from 'react';
import { useReducer } from 'react';
import { createContext } from 'react';
import localeList from '../locale/locale';

const InitialState = {
    Initialized: false,
    Locale: localeList.en,
    T: localeList.en.translation,
    AvailableLocale: localeList,
};

const Handlers = {
    INITIALIZE: (state, action) => {
        return {
            ...state,
            Initialized: true,
        };
    },

    LOCALE: (state, action) => {
        return {
            ...state,
            Locale: action.payload.Locale,
            T: action.payload.Locale.translation,
        };
    },
};

const reducer = (state, action) =>
    Handlers[action.type] ? Handlers[action.type](state, action) : state;

const LocaleContext = createContext({
    ...InitialState,
});

function LocaleProvider({ children, ...props }) {
    const [state, dispatch] = useReducer(reducer, InitialState);

    useEffect(() => {
        const initialize = () => {
            dispatch({
                type: 'INITIALIZE',
                payload: {},
            });
        };

        initialize();
    }, []);

    const setLocale = (locale) => {
        dispatch({
            type: 'LOCALE',
            payload: {
                Locale: locale,
            },
        });
    };

    return (
        <LocaleContext.Provider
            value={{
                ...state,
                setLocale,
            }}>
            {children}
        </LocaleContext.Provider>
    );
}

export { LocaleContext, LocaleProvider };
