// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.--faq-editor-popup-view-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
  top: -200vh;
  background-color: #000;
  opacity: 0;
  transition: opacity 0.2s 0s ease-in, top 0s 0.22s linear;
}
.--faq-editor-popup-view-overlay.--visible {
  top: 0;
  opacity: 0.5;
  transition: opacity 0.2s 0s ease-in, top 0s 0s linear;
}

.--faq-editor-popup-view {
  z-index: 99999;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: fixed !important;
  margin: auto;
  overflow: hidden;
  width: 100% !important;
  max-width: 700px !important;
  top: -200%;
  transition: transform 0.1s 0s ease-in, opacity 0.1s 0s ease-in, top 0s 0.11s linear;
  transform: translateY(-5%) scale(0.97);
  opacity: 0.2;
}
@media (min-width: 700px) {
  .--faq-editor-popup-view {
    height: 90% !important;
  }
  .--faq-editor-popup-view,
  .--faq-editor-popup-view .panel-right {
    border-radius: 12px;
  }
}
.--faq-editor-popup-view.--visible {
  transition: transform 0.1s 0s ease-in, opacity 0.1s 0s ease-in, top 0s 0s linear;
  transform: translateY(0px) scale(1);
  opacity: 1;
  top: 0;
}`, "",{"version":3,"sources":["webpack://./src/panels/right/editFaqPanel/faq-editor-panel.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,YAAA;EACA,cAAA;EACA,WAAA;EACA,sBAAA;EACA,UAAA;EACA,wDAAA;AACJ;AACI;EACI,MAAA;EACA,YAAA;EACA,qDAAA;AACR;;AAGA;EACI,cAAA;EACA,OAAA;EACA,QAAA;EACA,MAAA;EACA,SAAA;EACA,0BAAA;EACA,YAAA;EACA,gBAAA;EACA,sBAAA;EACA,2BAAA;EAQA,UAAA;EACA,mFAAA;EAEA,sCAAA;EACA,YAAA;AARJ;AAHI;EAXJ;IAYQ,sBAAA;EAMN;EALM;;IAEI,mBAAA;EAOV;AACF;AAAI;EACI,gFAAA;EAEA,mCAAA;EACA,UAAA;EACA,MAAA;AACR","sourcesContent":[".--faq-editor-popup-view-overlay {\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    z-index: 99999;\n    top: -200vh;\n    background-color: #000;\n    opacity: .0;\n    transition: opacity 0.2s 0s ease-in,\n        top 0s .22s linear;\n    &.--visible {\n        top: 0;\n        opacity: .5;\n        transition: opacity 0.2s 0s ease-in,\n            top 0s 0s linear;\n    }\n}\n.--faq-editor-popup-view {\n    z-index: 99999;\n    left: 0;\n    right: 0;\n    top: 0;\n    bottom: 0;\n    position: fixed !important;\n    margin: auto;\n    overflow: hidden;\n    width: 100% !important;\n    max-width: 700px !important;\n    @media (min-width: 700px) {\n        height: 90% !important;\n        &,\n        .panel-right {\n            border-radius: 12px;\n        }\n    }\n    top: -200%;\n    transition: transform 0.1s 0s ease-in, opacity 0.1s 0s ease-in,\n        top 0s 0.11s linear;\n    transform: translateY(-5%) scale(0.97);\n    opacity: 0.2;\n    &.--visible {\n        transition: transform 0.1s 0s ease-in, opacity 0.1s 0s ease-in,\n            top 0s 0s linear;\n        transform: translateY(0px) scale(1);\n        opacity: 1;\n        top: 0;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
