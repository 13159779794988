import { useRef, useState } from 'react';
import './toasts.scss';
import { useEffect } from 'react';
import { createPortal } from 'react-dom';

export function Toasts() {
    const [toasts, setToasts] = useState([
        /*{
            id: 1,
            type: 'ERROR_TOAST',
            message: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.',
        },
        {
            id: 2,
            type: 'WARNING_TOAST',
            message: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.',
        },
        {
            id: 3,
            type: 'INFO_TOAST',
            message: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.',
        },*/
    ]);
    const [toastsToRemove, setToastsToRemove] = useState([]);

    useEffect(() => {
        window.addEventListener('faqnation-show-toast', handleToastEvent);
        return () => {
            window.removeEventListener(
                'faqnation-show-toast',
                handleToastEvent
            );
        };
    }, []);

    useEffect(() => {
        if (
            toastsToRemove.length > 0 &&
            toastsToRemove.length === toasts.length
        ) {
            let toastsToRemoveList = [...toastsToRemove];
            setToasts((toasts) => {
                return toasts.filter(
                    (toast) => toastsToRemoveList.indexOf(toast.id) < 0
                );
            });
            setToastsToRemove([]);
        }
    }, [toastsToRemove]);

    function handleToastEvent(evnt) {
        let details = evnt.detail || {};
        setToasts((toasts) => {
            return [
                ...toasts,
                {
                    id: `${
                        Math.random() * 9999
                    }${new Date().getMilliseconds()}${new Date().getSeconds()}${new Date().getMinutes()}`,
                    type: details.type,
                    message: details.message,
                },
            ];
        });
    }

    return (
        <>
            {createPortal(
                <div className="toasts-container">
                    {toasts.map((toast, i) => {
                        return (
                            <Toast
                                key={'toast-00' + i}
                                onClose={() => {
                                    setToastsToRemove((toastsToRemove) => [
                                        ...toastsToRemove,
                                        toast.id,
                                    ]);
                                }}
                                type={toast.type}
                                message={toast.message}
                            />
                        );
                    })}
                </div>,
                document.body
            )}
        </>
    );
}

function Toast({ type, message, onClose }) {
    const toastRef = useRef(null);
    const [forceHide, setForceHide] = useState(false);
    const [isClosed, setIsClosed] = useState(false);

    useEffect(() => {
        if (forceHide) {
            setIsClosed(true);
        }
    }, [forceHide]);

    const Icon = () => {
        switch (type) {
            case 'WARNING_TOAST':
                return (
                    <svg
                        width="61"
                        height="61"
                        viewBox="0 0 61 61"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M12.7647 48.728H47.9349C51.5313 48.728 53.7732 44.828 51.975 41.722L34.3899 11.3393C32.5917 8.23327 28.1079 8.23327 26.3096 11.3393L8.72456 41.722C6.92635 44.828 9.16828 48.728 12.7647 48.728ZM30.3498 32.3806C29.0653 32.3806 28.0144 31.3297 28.0144 30.0453V25.3746C28.0144 24.0902 29.0653 23.0393 30.3498 23.0393C31.6342 23.0393 32.6851 24.0902 32.6851 25.3746V30.0453C32.6851 31.3297 31.6342 32.3806 30.3498 32.3806ZM32.6851 41.722H28.0144V37.0513H32.6851V41.722Z"
                            fill="#FF8C00"
                        />
                    </svg>
                );
            case 'ERROR_TOAST':
                return (
                    <svg
                        width="61"
                        height="61"
                        viewBox="0 0 61 61"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M30.3551 9.00977C18.4075 9.00977 8.71094 18.7064 8.71094 30.654C8.71094 42.6016 18.4075 52.2982 30.3551 52.2982C42.3027 52.2982 51.9993 42.6016 51.9993 30.654C51.9993 18.7064 42.3027 9.00977 30.3551 9.00977ZM30.3551 32.8184C29.1647 32.8184 28.1907 31.8444 28.1907 30.654V21.9963C28.1907 20.8059 29.1647 19.8319 30.3551 19.8319C31.5456 19.8319 32.5196 20.8059 32.5196 21.9963V30.654C32.5196 31.8444 31.5456 32.8184 30.3551 32.8184ZM32.5196 41.4761H28.1907V37.1472H32.5196V41.4761Z"
                            fill="#FF0000"
                        />
                    </svg>
                );
            default:
                return (
                    <svg
                        width="61"
                        height="61"
                        viewBox="0 0 61 61"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M30.3529 9.00977C18.2701 9.00977 8.46387 18.816 8.46387 30.8988C8.46387 42.9815 18.2701 52.7878 30.3529 52.7878C42.4356 52.7878 52.2419 42.9815 52.2419 30.8988C52.2419 18.816 42.4356 9.00977 30.3529 9.00977ZM30.3529 41.8433C29.149 41.8433 28.164 40.8583 28.164 39.6544V30.8988C28.164 29.6949 29.149 28.7099 30.3529 28.7099C31.5568 28.7099 32.5418 29.6949 32.5418 30.8988V39.6544C32.5418 40.8583 31.5568 41.8433 30.3529 41.8433ZM32.5418 24.3321H28.164V19.9543H32.5418V24.3321Z"
                            fill="#708FFF"
                        />
                    </svg>
                );
        }
    };
    const getTitle = () => {
        switch (type) {
            case 'WARNING_TOAST':
                return 'Warning';
            case 'ERROR_TOAST':
                return 'Error';
            default:
                return 'Info';
        }
    };
    const getClassName = () => {
        switch (type) {
            case 'WARNING_TOAST':
                return 'warning';
            case 'ERROR_TOAST':
                return 'error';
            default:
                return 'info';
        }
    };

    return (
        <div
            ref={toastRef}
            style={{
                height: `${
                    forceHide ? `${toastRef.current.offsetHeight}px` : ''
                }`,
            }}
            className={`toast toast-${getClassName()} ${
                isClosed ? 'closed' : ''
            }`}
            onAnimationEnd={() => {
                setForceHide(true);
                onClose();
            }}
            onClick={() => {
                setForceHide(true);
            }}>
            <div className="toast-icon-container">
                <Icon />
            </div>
            <div className={`toast-content`}>
                <h3 className={`toast-title`}>{getTitle()}</h3>
                <p className={`toast-message`}>{message}</p>
            </div>
        </div>
    );
}

function showToast({ type, message }) {
    window.dispatchEvent(
        new CustomEvent('faqnation-show-toast', {
            detail: {
                type: type,
                message: message,
            },
        })
    );
}

export function showError(message = '') {
    showToast({ type: 'ERROR_TOAST', message: message.toString() });
}

export function showWarning(message = '') {
    showToast({ type: 'WARNING_TOAST', message: message.toString() });
}

export function showInfo(message = '') {
    showToast({ type: 'INFO_TOAST', message: message.toString() });
}
