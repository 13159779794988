import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';

export const useAliasUrl = () => {
    const urlparams = useParams();
    const [aliasaddr, setAliasAddr] = useState(null);
    const [databaseUid, setDatabaseUid] = useState(null);

    useEffect(() => {
        setAliasAddr(urlparams['alias']);
        setDatabaseUid(urlparams['database']);
    }, [urlparams]);

    return [aliasaddr, databaseUid];
};
