// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.editFaqPage {
  max-height: 100%;
  min-height: 100%;
  overflow: auto;
}

.editFaqPageContainer {
  width: calc(100% - 20px);
  max-width: 600px;
  margin: 0 auto;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: flex-start;
}`, "",{"version":3,"sources":["webpack://./src/pages/editfaq/editfaqpage.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,gBAAA;EACA,cAAA;AACJ;;AACA;EACI,wBAAA;EACA,gBAAA;EACA,cAAA;EACA,iBAAA;EACA,aAAA;EACA,sBAAA;EACA,wBAAA;EACA,uBAAA;AAEJ","sourcesContent":[".editFaqPage {\n    max-height: 100%;\n    min-height: 100%;\n    overflow: auto;\n}\n.editFaqPageContainer {\n    width: calc(100% - 20px);\n    max-width: 600px;\n    margin: 0 auto;\n    min-height: 100vh;\n    display: flex;\n    flex-direction: column;\n    justify-content: stretch;\n    align-items: flex-start;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
