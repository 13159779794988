import React, {
    createContext,
    useEffect,
    useReducer,
    useRef,
    useState,
} from 'react';

const initialState = {
    initialized: false,

    cell: null,

    value: null,

    idx: null,

    is_calendar: null,

    field: null,
    fieldset: null,
};

const handlers = {
    INITIALIZE: (state, action) => {
        return {
            ...state,
            ...action.payload,
            initialized: true,
        };
    },

    UNINITIALIZE: (state) => {
        return {
            ...state,
            ...initialState,
        };
    },
};

const reducer = (state, action) =>
    handlers[action.type] ? handlers[action.type](state, action) : state;

const CellCtx = createContext({
    ...initialState,
});

function CellProvider({
    children,
    is_calendar = null,
    field = null,
    fieldset = null,
    idx = null,
    cell = null,
    ...props
}) {
    const [state, dispatch] = useReducer(reducer, initialState);

    useEffect(() => {
        const initialize = () => {
            dispatch({
                type: 'INITIALIZE',
                payload: {
                    idx: idx,
                    cell: cell,
                    value: (cell && cell.value) || null,
                    is_calendar: is_calendar,
                    field: field,
                    fieldset: fieldset,
                },
            });
        };

        if (field && fieldset) {
            initialize();
        }
    }, [cell, idx, field, fieldset, is_calendar]);

    return (
        <CellCtx.Provider
            value={{
                ...state,
            }}>
            {children}
        </CellCtx.Provider>
    );
}

export { CellCtx, CellProvider };
