import { useEffect } from 'react';
import Button from '../../components/Button';
import Input from '../../components/Input';
import { SelectBox } from '../../components/Popup';
import { Text } from '../../components/Text';
import { Symbol } from '../../components/elements/Icon';
import { useRef } from 'react';
import useBarelayout from '../../layouts/barelayout/hook';
import { useState } from 'react';
import { FieldTypeMap } from '../../layouts/fullview/main';

export const Field = ({
    field = null,
    fieldset = null,
    index,
    onUpdateValue,
    value,
    noExtraItems,
    placeholder,
}) => {
    const ref = useRef(null);
    const inputRef = useRef(null);
    const brCtx = useBarelayout();

    const [options, setOptions] = useState([]);

    const handleOnBlur = (e) => {
        onUpdateValue(e.target.value);
    };

    const handleOnSelect = (e) => {
        onUpdateValue(e.option.value);
    };

    const FetchField = async () => {
        return await brCtx.fetchField({
            uid: field.id,
            fieldsetuid: fieldset.id,
        });
    };

    useEffect(() => {
        if (field.type === 'select') {
            getSelectOptions();
        }
    }, [field.type, brCtx.select_fields]);

    useEffect(() => {
        if (
            field.type === 'select' &&
            field.attributes &&
            field.attributes.referType === 'recordsets_categorizer'
        ) {
            getSelectOptions();
        }
    }, [brCtx.recordsets]);

    useEffect(() => {
        if (field.type === 'string' && inputRef.current) {
            inputRef.current.innerText = getValue();
        }
    }, [field]);

    const getSelectOptions = (force = false) => {
        if (!field) {
            return [];
        }

        if (
            field.attributes &&
            field.attributes.referType === 'recordsets_categorizer'
        ) {
            var opts = [];

            brCtx.recordsets.map((r) => {
                opts.push({
                    id: r.id,
                    value: r.id,
                    label: r.label || '<empty>',
                    attributes:
                        (r.attributes && r.attributes.cellstyle) || null,
                });
            });

            setOptions(opts);
            return;

            // return [];
        }

        if (brCtx.select_fields && brCtx.select_fields[field.id] && !force) {
            let options = (brCtx.select_fields[field.id].options || []).slice(
                0
            );
            options.unshift({
                ...options[0],
                attributes: null,
                id: null,
                label: null,
                value: null,
            });
            setOptions(options);
            return;
        } else {
            /** fetch */
            FetchField().then((res) => {
                if (res.status === 200) {
                    var optionlist = [];

                    var optionsets = res.data.field.others.options;

                    optionsets &&
                        Array.isArray(optionsets) &&
                        optionsets.map((optset, x) => {
                            optset &&
                                optset.records &&
                                Array.isArray(optset.records) &&
                                optset.records.map((rec, y) => {
                                    optionlist.push({
                                        id: rec.uid,
                                        parentId: optset.uid,
                                        attributes: rec.attributes,
                                        value: rec.uid,
                                        label:
                                            (rec.cell && rec.cell.value) ||
                                            '<empty>',
                                    });
                                });
                        });
                    let options = optionlist.slice(0);
                    options.unshift({
                        ...options[0],
                        attributes: null,
                        id: null,
                        label: null,
                        value: null,
                    });
                    setOptions(options);

                    var newoptions = {};

                    newoptions[field.id] = {
                        options: optionlist,
                    };

                    brCtx.setBasic({
                        select_fields: {
                            ...brCtx.select_fields,
                            ...newoptions,
                        },
                    });
                }
            });
        }
    };

    const getValue = () => {
        return value;
    };

    const handleAddRecordsetKeyUp = (e) => {
        if (e.keyCode === 13) {
            brCtx.addRecordset({
                label: e.target.value,
            });
            e.target.value = '';
        }
    };

    const handleAddOptionKeyUp = (e) => {
        if (e.keyCode === 13) {
            // brCtx.addRecordset({
            //     label: e.target.value,
            // });

            brCtx
                .createOptionForField(fieldset.id, field.id, {
                    label: e.target.value,
                    attributes: null,
                })
                .then(() => {
                    getSelectOptions(true);
                });

            e.target.value = '';
        }
    };

    if (field.type === 'select') {
        var extraItems = () => {
            return null;
        };

        if (
            field.attributes &&
            field.attributes.referType === 'recordsets_categorizer'
        ) {
            extraItems = () => {
                return (
                    <>
                        <div
                            className="w-100p"
                            style={{
                                padding: '4px 8px',
                                borderTop: '1px solid rgba(0, 0, 0, 0.08)',
                            }}>
                            <Input
                                onKeyUp={handleAddRecordsetKeyUp}
                                boxClassName={'no-default-style w-100p'}
                                className={'no-default-style w-100p'}
                                autoFocus={true}
                                style={{ color: '#777777' }}
                                placeholder={'Kategorie'}
                            />

                            <div className={'ml-12'}>
                                <Text
                                    style={{ fontSize: 11, opacity: 0.8 }}
                                    value={
                                        <>
                                            <span>
                                                Neue Kategorie hinzufügen,{' '}
                                            </span>
                                            <span style={{ color: '#4470c3' }}>
                                                ENTER
                                            </span>{' '}
                                            <span>drücken zum sichern.</span>
                                        </>
                                    }
                                />
                            </div>
                        </div>
                    </>
                );
            };
        } else {
            extraItems = () => {
                return (
                    <>
                        <div
                            className="w-100p"
                            style={{
                                padding: '4px 8px',
                                borderTop: '1px solid rgba(0, 0, 0, 0.08)',
                            }}>
                            <Input
                                onKeyUp={handleAddOptionKeyUp}
                                boxClassName={'no-default-style w-100p'}
                                className={'no-default-style w-100p'}
                                autoFocus={true}
                                style={{ color: '#777777' }}
                                placeholder={'Option'}
                            />

                            <div className={'ml-12'}>
                                <Text
                                    style={{ fontSize: 11, opacity: 0.8 }}
                                    value={
                                        <>
                                            <span>
                                                Neue option hinzufügen,{' '}
                                            </span>
                                            <span style={{ color: '#4470c3' }}>
                                                ENTER
                                            </span>{' '}
                                            <span>drücken zum sichern.</span>
                                        </>
                                    }
                                />
                            </div>
                        </div>
                    </>
                );
            };
        }

        return (
            <>
                <SelectBox
                    ref={ref}
                    onChange={handleOnSelect}
                    options={options}
                    defaultValue={getValue()}
                    triggerStyle={{
                        height: 34,
                    }}
                    contentStyle={{
                        width: 225,
                    }}
                    extraItems={
                        noExtraItems
                            ? () => {
                                  return null;
                              }
                            : extraItems()
                    }
                />
            </>
        );
    }

    const handleCheckboxToggle = (e) => {
        var new_val = getValue() === 'true' ? 'false' : 'true';
        onUpdateValue(new_val);
    };

    if (field.type === 'checkbox') {
        // console.log(cell);
        // console.log(field);

        return (
            <>
                <span className={"--checkbox-container"}>
                    <Button
                        onClick={handleCheckboxToggle}
                        className={`--checkbox-default ${
                            getValue() === 'true' ? 'on' : 'off'
                        }`}
                        autoFocus={
                            brCtx.active_cell &&
                            (brCtx.active_cell.fieldidx === index ||
                                (index === 0 &&
                                    brCtx.active_cell.fieldidx === null))
                        }
                        type={'icon'}></Button>
                </span>
            </>
        );
    }

    if (field.type === 'string') {
        return (
            <>
                <Input
                    ref={inputRef}
                    onBlur={(e) => {
                        handleOnBlur({
                            target: {
                                value: e.target.innerText,
                            },
                        });
                    }}
                    type={'span'}
                    placeholder={placeholder}
                    value={getValue()}
                    readOnly={field.automatic_value}
                    disabled={field.automatic_value}
                    defaultValue={getValue()}
                    autoFocus={
                        brCtx.active_cell &&
                        (brCtx.active_cell.fieldidx === index ||
                            (index === 0 &&
                                brCtx.active_cell.fieldidx === null))
                    }
                />
            </>
        );
    }

    return (
        <>
            <Input
                onBlur={handleOnBlur}
                type={FieldTypeMap[(field && field.type) || null]}
                placeholder={'Value'}
                readOnly={field.automatic_value}
                disabled={field.automatic_value}
                defaultValue={getValue()}
                autoFocus={
                    brCtx.active_cell &&
                    (brCtx.active_cell.fieldidx === index ||
                        (index === 0 && brCtx.active_cell.fieldidx === null))
                }
            />
        </>
    );
};
