import { useEffect, useRef } from 'react';
import Button from '../../components/Button';
import { Symbol } from '../../components/elements/Icon';
import Input from '../../components/Input';
import { Text } from '../../components/Text';
import useAlias from '../../hooks/useAlias';
import useAuth from '../../hooks/useAuth';
import useSetting from '../../hooks/useSetting';
import { BrDialogTabs } from '../../layouts/barelayout/dialog';
import useBarelayout from '../../layouts/barelayout/hook';
import { BodyPanelTabs } from '../body';
import { TopPanelTabs } from '../top';

export default function DatabaseviewTopPanel() {
    const authCtx = useAuth();
    const aliasCtx = useAlias();
    const settingCtx = useSetting();

    const brCtx = useBarelayout();

    const renderSubuser = () => {
        const onLockButtonClick = (e) => {
            authCtx.removeAliasLogin({
                uid: aliasCtx.alias.uid,
            });
            window.location.assign('/');
        };

        if (aliasCtx.subuser && aliasCtx.subuser.name) {
            return (
                <Button
                    onClick={onLockButtonClick}
                    className={'ml-16 mr-16'}>
                    <Symbol
                        name={'lock'}
                        className={'mr-8'}
                    />
                    <Text value={aliasCtx.subuser.name.cell.value} />
                </Button>
            );
        }

        return <></>;
    };

    const renderBarelayoutFields = () => {
        const handleOnFieldClick = ({ e = null, idx = null }) => {
            // brCtx.setBasic({
            //   active_field: idx,
            // });

            if (brCtx.active_field === 0) {
                brCtx.setBasic({
                    active_field: 1,
                });
            } else {
                brCtx.setBasic({
                    active_field: 0,
                });
            }
        };

        const handleQrButtonClick = (e) => {
            brCtx.setBasic({
                dialog: {
                    ...(brCtx.dialog || {}),
                    visible: true,
                    tab: BrDialogTabs.QR_SCANNER,
                },
            });
        };

        return (
            <>
                {brCtx.features &&
                    brCtx.features.qr_search &&
                    brCtx.features.qr_search.enabled && (
                        <Button
                            className={'ml-12'}
                            onClick={handleQrButtonClick}>
                            <Symbol
                                name={'qr_scanner'}
                                className={'mr-8'}
                            />
                            <Text value={'QR'} />
                        </Button>
                    )}

                {brCtx.calendar_fieldsets &&
                    brCtx.calendar_fieldsets[0] &&
                    brCtx.calendar_fieldsets[0].fields &&
                    brCtx.calendar_fieldsets[0].fields.map((field, x) => {
                        /** for now */

                        if (x === 1) {
                            return (
                                <Button
                                    key={field.id}
                                    className={
                                        'ml-12' +
                                        (x === brCtx.active_field
                                            ? ' --active-red-trigger'
                                            : '')
                                    }
                                    onClick={(e) => {
                                        handleOnFieldClick({ e: e, idx: x });
                                    }}>
                                    <Symbol
                                        name={'report'}
                                        className={'mr-8'}
                                    />
                                    <Text value={field.label} />
                                </Button>
                            );
                        }
                    })}
            </>
        );
    };

    const handleSearchClick = (e) => {
        settingCtx.setTopPanel({
            active: TopPanelTabs.DatabaseSearchTab,
        });
    };

    const handleDashboardClick = (e) => {
        settingCtx.setBodyPanel({
            active: BodyPanelTabs.AliasDashboard,
        });
        settingCtx.setTopPanel({
            active: TopPanelTabs.AliasView,
        });
    };

    const handleRecordsetOnChange = (e) => {
        var v = parseInt(e.target.value);

        if (e.target.value === 'all') {
            v = 'all';
        }

        brCtx.setBasic({
            active_recordset: v,
        });
    };

    return (
        <>
            <div className="--panel-section">
                <Button
                    type={'icon'}
                    onClick={handleDashboardClick}
                    className={'--outline mr-12'}>
                    <Symbol name={'apps'} />
                </Button>

                <Button
                    type={'icon'}
                    onClick={handleSearchClick}
                    className={'--outline mr-12'}>
                    <Symbol name={'search'} />
                </Button>

                {/* <Button type={"icon"} className={"--outline"}>
          <Symbol name={"filter_alt"} />
        </Button> */}

                <Button
                    style={{ overflow: 'hidden' }}
                    htmlFor={'reordset_filters'}
                    tag={'label'}
                    type={'icon'}
                    className={'--outline --override-selectbox'}>
                    <Symbol
                        className={'--icon'}
                        name={'filter_alt'}
                    />
                    <select
                        onChange={handleRecordsetOnChange}
                        style={{
                            height: '100%',
                            width: 110,
                            opacity: 0,
                            cursor: 'pointer',
                        }}
                        name={'reordset_filters'}
                        id={'reordset_filters'}>
                        <option value={'all'}>All</option>
                        {brCtx.recordsets &&
                            brCtx.recordsets.map((recordset, x) => {
                                return (
                                    <option
                                        key={x}
                                        value={x}>
                                        {recordset.label}
                                    </option>
                                );
                            })}
                    </select>
                </Button>

                {brCtx.initialized && renderBarelayoutFields()}
            </div>

            <div className="--panel-section">
                {/* {dbCtx.loading && (
          <Button
            loading={dbCtx.loading > -1 ? true : false}
            type={"icon"}
            style={{ pointerEvents: "none" }}
          ></Button>
        )} */}
                {renderSubuser()}
            </div>
        </>
    );
}

export function DatabaseviewSearchPanel() {
    const settingCtx = useSetting();

    const brCtx = useBarelayout();
    const inputRef = useRef(null);

    useEffect(() => {
        inputRef.current.focus();
    }, []);

    const handleSearchClick = (e) => {
        settingCtx.setTopPanel({
            active: TopPanelTabs.DatabaseIdle,
        });
        brCtx.searchCells(null);
    };

    const handleOnChange = (e) => {
        brCtx.searchCells(e.target.value || null);
    };

    useEffect(() => {
        inputRef.current.value = settingCtx.top_panel.qr_query || '';
    }, [settingCtx.top_panel.qr_query]);

    return (
        <>
            <div
                className="--panel-section"
                style={{ width: '100%' }}>
                <Input
                    ref={inputRef}
                    onChange={handleOnChange}
                    placeholder={'Suche'}
                    size={'wide-mobile'}
                    autoFocus={true}
                    boxClassName={'no-default-style'}
                />
            </div>

            <div
                className="--panel-section"
                style={{ width: 'auto' }}>
                <Button
                    onClick={handleSearchClick}
                    type={'icon'}>
                    <Symbol name={'close'} />
                </Button>
            </div>
        </>
    );
}
