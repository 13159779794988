export const OpenInFull = ({ className = null, color = undefined }) => {
    return (
        <svg
            className={'ui-sym path' + (className ? ' ' + className : '')}
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M22.4902 24.2865L24.3545 22.4222L18.2289 16.2966L24.3545 10.171L22.4902 8.30664L14.5003 16.2966L22.4902 24.2865ZM10.5053 24.2865V8.30664H7.84199V24.2865H10.5053Z"
                style={{ fill: color }}
                className="app-icon-fill"
            />
        </svg>
    );
};
