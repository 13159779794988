import axios from 'axios';
import { getAPIBase } from '../env';

const axiosInstance = axios.create({
    baseURL: getAPIBase(),
});

export const axiosDefaultConfig = {
    headers: {
        Accept: '*/*',
        'Content-Type': 'application/json; charset=utf-8',
    },
};

axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (axios.isCancel(error)) {
            // console.log('Cancelled.');
            return Promise.reject(
                (error.response && error.response) || 'NO_WORRY'
            );
        } else {
            if (!error.response.data) {
                // window.alert("Network connection error")
            }
            console.error(error);
        }

        return Promise.reject((error.response && error.response) || 'WRONG');
    }
);

export default axiosInstance;
