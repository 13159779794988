import { useEffect, useRef, useState } from 'react';
import './popups-manager.scss';
import { Text } from '../Text';
import Button from '../Button';
import { PopupMenu } from '../Popup/Popup';
import { createPortal } from 'react-dom';

//let popupsStack = [];

export function PopupsManager() {
    const [popupsStack, setPopupsStack] = useState([]);

    useEffect(() => {
        window.addEventListener('add_popup', handleAddPopup);
        window.addEventListener('close_popup', handleClosePopup);
        return () => {
            window.removeEventListener('add_popup', handleAddPopup);
            window.removeEventListener('close_popup', handleClosePopup);
        };
    }, []);

    function handleAddPopup(evnt) {
        let newPopup = { ...evnt.detail };
        setPopupsStack((popupsStack) => [...popupsStack, newPopup]);
    }

    function handleClosePopup(evnt) {
        let targetPopupId = evnt.detail['id'];
        setPopupsStack((popupsStack) =>
            popupsStack.filter((popup) => popup.id !== targetPopupId)
        );
    }

    function onClose({ targetPopupId }) {
        setPopupsStack((popupsStack) =>
            popupsStack.filter((popup) => popup.id !== targetPopupId)
        );
    }

    return (
        <>
            {popupsStack.map((popup) => (
                <>
                    {createPortal(
                        <PopupMenu
                            open={true}
                            className={popup.className || ''}
                            onClose={() => {
                                onClose({ targetPopupId: popup.id });
                            }}
                            closeOnDocumentClick={false}
                            closeOnEscape={false}>
                            {popup.content}
                        </PopupMenu>,
                        document.getElementById('hero-popup-layer') ||
                            document.body
                    )}
                </>
            ))}
        </>
    );
}

export function showPopup({
    content = ({ id = '' }) => <></>,
    className = '',
}) {
    let id = `${
        Math.random() * 9999
    }-${new Date().getMilliseconds()}-${new Date().getSeconds()}-${new Date().getMinutes()}-${new Date().getTime()}`;

    window.dispatchEvent(
        new CustomEvent('add_popup', {
            detail: {
                id: id,
                content: content({ id: id }),
                className: className,
            },
        })
    );
}

export function closePopup({ id = '' }) {
    window.dispatchEvent(
        new CustomEvent('close_popup', { detail: { id: id } })
    );
}

function showAlert(msg = '') {
    showPopup({
        content: ({ id }) => (
            <>
                <div className="--popup-content">
                    <Text value={msg} />
                </div>
                <div className="--popup-actions">
                    <Button
                        loading={false}
                        onClick={() => {
                            closePopup({ id: id });
                        }}
                        style={{ minWidth: '100px' }}
                        type={'primary'}>
                        <Text value={'Confirm'} />
                    </Button>
                </div>
            </>
        ),
        className: 'alert-popup',
    });
}

function showConfirm({
    msg = '',
    onConfirm = () => {},
    danger = false,
}) {
    showPopup({
        content: ({ id }) => (
            <>
                <div className="--popup-content">
                    <Text value={msg} />
                </div>
                <div className="--popup-actions">
                    <Button
                        loading={false}
                        onClick={() => {
                            closePopup({ id: id });
                        }}
                        style={{ minWidth: '100px' }}
                        type={'secondary'}>
                        <Text value={'Cancel'} />
                    </Button>
                    <Button
                        loading={false}
                        onClick={() => {
                            closePopup({ id: id });
                            onConfirm();
                        }}
                        style={{ minWidth: '100px' }}
                        type={danger ? 'danger-filled' : 'primary'}>
                        <Text value={'Confirm'} />
                    </Button>
                </div>
            </>
        ),
        className: 'confirm-popup',
    });
}

export const popupsManager = {
    showPopup,
    closePopup,
    alert: showAlert,
    confirm: showConfirm,
};
