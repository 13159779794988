import { useEffect, useRef, useState } from 'react';
import './count-down.scss';

export const CountDown = ({
    until,
    onCountDownEnd = () => {},
    onCountDownStart = () => {},
}) => {
    const initialized = useRef(false);
    const [counter, setCounter] = useState({
        days: '00',
        hours: '00',
        minutes: '00',
        seconds: '00',
    });
    const days = counter.days;
    const hours = counter.hours;
    const minutes = counter.minutes;
    const seconds = counter.seconds;
    useEffect(() => {
        let currentDate = new Date().getTime();
        const loopId = setInterval(() => {
            let newDate = new Date().getTime();
            if (newDate - currentDate >= 1000) {
                currentDate = newDate;
                let remainingTime = (until - newDate) / 1000;
                let days = remainingTime / 86400;
                days |= days;
                remainingTime -= days * 86400;
                let hours = remainingTime / 3600;
                hours |= hours;
                remainingTime -= hours * 3600;
                let minutes = remainingTime / 60;
                minutes |= minutes;
                remainingTime -= minutes * 60;
                let seconds = remainingTime | remainingTime;
                if (days <= 0 && hours <= 0 && minutes <= 0 && seconds <= 0) {
                    onCountDownEnd();
                    clearInterval(loopId);
                    days = 0;
                    hours = 0;
                    minutes = 0;
                    seconds = 0;
                } else {
                    if (!initialized.current) {
                        onCountDownStart();
                        initialized.current = true;
                    }
                }
                setCounter({
                    days: formatNumber(days, 2),
                    hours: formatNumber(hours, 2),
                    minutes: formatNumber(minutes, 2),
                    seconds: formatNumber(seconds, 2),
                });
            }
        }, 100);
        return () => {
            clearInterval(loopId);
        };
    }, []);

    if (
        days === '00' &&
        hours === '00' &&
        minutes === '00' &&
        seconds === '00'
    ) {
        return <></>;
    }

    return (
        <div className={'countDown'}>
            <span className={'countDown_cell'} data-lable={'Days'}>
                {days}
            </span>
            <span className={'countDown_cell'} data-lable={'Hours'}>
                {hours}
            </span>
            <span className={'countDown_cell'} data-lable={'Minutes'}>
                {minutes}
            </span>
            <span className={'countDown_cell'} data-lable={'Seconds'}>
                {seconds}
            </span>
        </div>
    );
};

export function dateToSeconds({ year, month, date, hours, minutes, seconds }) {
    return new Date(
        new Date(
            new Date(
                new Date(
                    new Date(new Date().setFullYear(year)).setMonth(month - 1)
                ).setDate(date)
            ).setHours(hours)
        ).setMinutes(minutes)
    ).setSeconds(seconds);
}

function formatNumber(number, charsNumber) {
    let result = '' + number;
    for (let i = charsNumber - result.length; i > 0; i--) {
        result = '0' + result;
    }
    return result;
}
