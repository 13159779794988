export const HistoryIcon = ({
    className = null,
    color = undefined,
    color2 = undefined,
}) => {
    return (
        <svg
            className={'ui-sym path' + (className ? ' ' + className : '')}
            width="25"
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <circle
                cx="12.8682"
                cy="12.5859"
                r="9.42969"
                fill="#E4CAFA"
                style={{ fill: color2 }}
            />
            <path
                d="M12.8682 22.0156C11.5585 22.0156 10.3318 21.7668 9.18797 21.2691C8.04418 20.7714 7.04882 20.0991 6.2019 19.2522C5.35497 18.4053 4.68267 17.4099 4.18499 16.2661C3.68732 15.1223 3.43848 13.8956 3.43848 12.5859C3.43848 11.2763 3.68732 10.0495 4.18499 8.90574C4.68267 7.76195 5.35497 6.7666 6.2019 5.91967C7.04882 5.07275 8.04418 4.40044 9.18797 3.90277C10.3318 3.40509 11.5585 3.15625 12.8682 3.15625C14.3001 3.15625 15.6578 3.46184 16.9413 4.07303C18.2248 4.68421 19.3118 5.5486 20.2024 6.66619V4.20399H22.2979V10.4905H16.0114V8.39497H18.8927C18.1767 7.41707 17.2949 6.64873 16.2471 6.08993C15.1994 5.53113 14.0731 5.25174 12.8682 5.25174C10.8251 5.25174 9.09192 5.96333 7.66874 7.38651C6.24555 8.8097 5.53396 10.5428 5.53396 12.5859C5.53396 14.629 6.24555 16.3622 7.66874 17.7854C9.09192 19.2085 10.8251 19.9201 12.8682 19.9201C14.7017 19.9201 16.3039 19.3264 17.6747 18.139C19.0455 16.9515 19.8531 15.4498 20.0976 13.6337H22.2455C21.9835 16.026 20.9576 18.0211 19.1677 19.6189C17.3778 21.2167 15.278 22.0156 12.8682 22.0156ZM15.8018 16.9865L11.8204 13.005V7.34722H13.9159V12.1668L17.2687 15.5196L15.8018 16.9865Z"
                fill="#8A28E3"
                style={{ fill: color }}
            />
        </svg>
    );
};
