import React, { useEffect, useState } from 'react';
import useSetting from '../../hooks/useSetting';
import LeftPanel from '../left/main';
import DatabaseviewFullSubBodyPanel from '../subbody/database_full';
import TopPanel from '../top';
import useAuth from '../../hooks/useAuth';
import { GDialogTabs } from '../dialog/main';
import FilterPanel from '../left/filterPanel/filterPanel';
import { AccountOptionsPanel } from '../right/accountOptionsPanel/accountOptionsPanel';
import { MainMenuBtn } from '../top/database_full';
import useAlias from '../../hooks/useAlias';
import { ExportDatabaseHandler } from '../../components/export-database-handler/ExportDatabaseHandler';
import { ImportDatabaseHandler } from '../../components/import-database-handler/ImportDatabaseHandler';
import { Toasts } from '../../components/towsts/Toasts';
import Button from "../../components/Button";

const NoDatabasesView = () => {
    const settingCtx = useSetting();
    const handleOnAddClick = (e) => {
        settingCtx.setGDialog({
            visible: true,
            active: GDialogTabs.database,
        });
    };

    return (
        <div className={'--no-dbs-page-container'}>
            <div className={'--no-dbs-top-panel'}>
                <MainMenuBtn />
            </div>
            <div className={'--no-dbs-page'}>
                <div className={'title'}>Seams there is no Database</div>
                {/*<button*/}
                {/*    className={'create-db-button'}*/}
                {/*    onClick={() => {*/}
                {/*        handleOnAddClick();*/}
                {/*    }}>*/}
                {/*    Create Project*/}
                {/*</button>*/}
                <div className={'no-db-page-button-container'}>
                    <Button type={"secondary mr-pd"}>
                        <span className={"t-ext"}>Learn how to use</span>
                    </Button>
                    <Button type={"primary mr-pd"} onClick={() => {
                        handleOnAddClick();
                    }}>
                        <span className={"t-ext"}>Create Database</span>
                    </Button>
                </div>
            </div>
            <AccountOptionsPanel/>
        </div>
    );
};

export default function DatabaseviewFullBodyPanel() {
    const settingCtx = useSetting();
    const authCtx = useAuth();
    const aliasCtx = useAlias();

    const [leftPanelWidth, setLeftPanelWidth] = useState(0);
    const [filterPanelWidth, setFilterPanelWidth] = useState(0);
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        if (settingCtx.left_panel.visible) {
            setLeftPanelWidth(settingCtx.left_panel.width);
        }
    }, [settingCtx.left_panel.width]);

    useEffect(() => {
        if (settingCtx.filter_panel.visible) {
            setFilterPanelWidth(settingCtx.filter_panel.width);
        }
    }, [settingCtx.filter_panel.width]);

    useEffect(() => {
        if (
            authCtx.aliases &&
            authCtx.aliases[0] &&
            authCtx.aliases[0].alias['templates'].length <
                (aliasCtx.databases || []).length
        ) {
            let newData = [...authCtx.aliases];
            newData[0].alias['templates'] = [...(aliasCtx.databases || [])];
            authCtx.setBasic(newData);
        }
    }, [aliasCtx.databases]);

    useEffect(() => {
        setVisible(
            settingCtx.left_panel.visible || settingCtx.filter_panel.visible
        );
        if (settingCtx.left_panel.visible) {
            setLeftPanelWidth(settingCtx.left_panel.width);
        } else {
            setLeftPanelWidth(0);
        }
        if (settingCtx.filter_panel.visible) {
            setFilterPanelWidth(settingCtx.filter_panel.width);
        } else {
            setFilterPanelWidth(0);
        }
    }, [settingCtx.left_panel.visible, settingCtx.filter_panel.visible]);

    if (
        authCtx.aliases &&
        authCtx.aliases[0] &&
        !authCtx.aliases[0].alias['templates'][0]
    ) {
        return (
            <>
                {/* <LeftPanel /> */}
                <NoDatabasesView />
            </>
        );
    }
    return (
        <>
            <LeftPanel />
            <FilterPanel marginLeft_={visible ? leftPanelWidth : 0} />

            <div
                style={{
                    marginLeft: `${
                        visible ? leftPanelWidth + filterPanelWidth : 0
                    }px`,
                    width: `calc(100% - ${
                        visible ? leftPanelWidth + filterPanelWidth : 0
                    }px)`,
                }}
                className="--panel-section --body-view">
                <TopPanel />

                <DatabaseviewFullSubBodyPanel />
            </div>
            <ExportDatabaseHandler />
            <ImportDatabaseHandler />
            <Toasts />

            {/** left sidebar */}
            {/** subbody: {akraview, right sidebar} */}
        </>
    );
}
