import BareDatabaseLayout from '../../layouts/barelayout/main';

/**
 * for only today
 * calendar layout only
 * datecode: onlytoday
 * only one field of one fieldset on main page
 * after that main fieldset of database
 * recordset all
 * record all
 * colored records
 */

export default function DatabaseviewBodyPanel() {
    // const aliasCtx = useAlias();
    // const dbCtx = useDatabase();
    // const settingCtx = useSetting();

    // console.log(dbCtx.error);

    return (
        <>
            {/* {!dbCtx.error && <div className="--panel-section scroll-container">
        <BareDatabaseLayout />
      </div> || <ErrorPagePanel />} */}
            <div
                className="--panel-section scroll-container"
                style={{ paddingBottom: 100 }}>
                <BareDatabaseLayout />
            </div>
        </>
    );
}
