import axiosInstance, { axiosDefaultConfig } from '../helpers/axios';

/**
 * generic api caller
 */
export const callApi = async ({
    headers = {},
    method = null,
    path = null,
    signal = undefined,
    cancelToken = undefined,
    payload = null,
    onUploadProgress = (e)=>{}
}) => {
    var error = null;

    var extras = {};

    if (signal) {
        extras['signal'] = signal;
    }
    if (cancelToken) {
        extras['cancelToken'] = cancelToken;
    }

    if (method === 'post') {
        error = await axiosInstance
            .post(path, payload, {
                ...axiosDefaultConfig,
                headers: {
                    ...axiosDefaultConfig.headers,
                    ...headers,
                },
                onUploadProgress,
                ...extras,
            })
            .then(
                (response) => {
                    return response;
                },
                (error) => error
            );
    } else if (method === 'put') {
        error = await axiosInstance
            .put(path, payload, {
                ...axiosDefaultConfig,
                headers: {
                    ...axiosDefaultConfig.headers,
                    ...headers,
                },
                ...extras,
            })
            .then(
                (response) => {
                    return response;
                },
                (error) => error
            );
    } else if (method === 'patch') {
        error = await axiosInstance
            .patch(path, payload, {
                ...axiosDefaultConfig,
                headers: {
                    ...axiosDefaultConfig.headers,
                    ...headers,
                },
                ...extras,
            })
            .then(
                (response) => {
                    return response;
                },
                (error) => error
            );
    } else if (method === 'get') {
        error = await axiosInstance
            .get(path, {
                ...axiosDefaultConfig,
                headers: {
                    ...axiosDefaultConfig.headers,
                    ...headers,
                },
                ...extras,
            })
            .then(
                (response) => {
                    return response;
                },
                (error) => error
            );
    } else if (method === 'delete') {
        error = await axiosInstance
            .delete(path, {
                ...axiosDefaultConfig,
                headers: {
                    ...axiosDefaultConfig.headers,
                    ...headers,
                },
                ...extras,
            })
            .then(
                (response) => {
                    return response;
                },
                (error) => error
            );
    }

    return error;
};
