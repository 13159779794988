export const DeleteIcon = ({ className = null, color = undefined }) => {
    return (
        <svg
            className={'ui-sym path' + (className ? ' ' + className : '')}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_6_16131)">
                <path
                    d="M16 9V19H8V9H16ZM14.5 3H9.5L8.5 4H5V6H19V4H15.5L14.5 3ZM18 7H6V19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V7Z"
                    style={{ fill: color }}
                />
            </g>
            <defs>
                <clipPath id="clip0_6_16131">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};
export const DeleteAlt1Icon = ({
    className = null,
    color = undefined,
    color2 = undefined,
}) => {
    return (
        <svg
            className={'ui-sym path' + (className ? ' ' + className : '')}
            width="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <rect
                x="4"
                y="3"
                width="10"
                height="12"
                rx="1"
                style={color2 ? { fill: color2 } : {}}
                className="app-icon-fill-2"
            />
            <path
                d="M5.24457 15.733C4.83253 15.733 4.4798 15.5863 4.18637 15.2929C3.89295 14.9995 3.74624 14.6468 3.74624 14.2347V4.49555H2.99707V2.99721H6.7429V2.24805H11.2379V2.99721H14.9837V4.49555H14.2346V14.2347C14.2346 14.6468 14.0879 14.9995 13.7944 15.2929C13.501 15.5863 13.1483 15.733 12.7362 15.733H5.24457ZM12.7362 4.49555H5.24457V14.2347H12.7362V4.49555ZM6.7429 12.7364H8.24124V5.99388H6.7429V12.7364ZM9.73957 12.7364H11.2379V5.99388H9.73957V12.7364Z"
                style={{ fill: color }}
                className="app-icon-fill"
            />
        </svg>
    );
};
