export const DesktopIcon = ({
    className = null,
    color = undefined,
    color2 = undefined,
}) => {
    return (
        <svg
            width="25"
            className={'ui-sym path' + (className ? ' ' + className : '')}
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M8.81738 21.1484V19.1484H10.8174V17.1484H4.81738C4.26738 17.1484 3.79655 16.9526 3.40488 16.5609C3.01322 16.1693 2.81738 15.6984 2.81738 15.1484V5.14844C2.81738 4.59844 3.01322 4.1276 3.40488 3.73594C3.79655 3.34427 4.26738 3.14844 4.81738 3.14844H20.8174C21.3674 3.14844 21.8382 3.34427 22.2299 3.73594C22.6215 4.1276 22.8174 4.59844 22.8174 5.14844V15.1484C22.8174 15.6984 22.6215 16.1693 22.2299 16.5609C21.8382 16.9526 21.3674 17.1484 20.8174 17.1484H14.8174V19.1484H16.8174V21.1484H8.81738Z"
                style={{ fill: color2 }}
                className="app-icon-fill-2"
            />
            <path
                d="M8.81738 21.1484V19.1484H10.8174V17.1484H4.81738C4.26738 17.1484 3.79655 16.9526 3.40488 16.5609C3.01322 16.1693 2.81738 15.6984 2.81738 15.1484V5.14844C2.81738 4.59844 3.01322 4.1276 3.40488 3.73594C3.79655 3.34427 4.26738 3.14844 4.81738 3.14844H20.8174C21.3674 3.14844 21.8382 3.34427 22.2299 3.73594C22.6215 4.1276 22.8174 4.59844 22.8174 5.14844V15.1484C22.8174 15.6984 22.6215 16.1693 22.2299 16.5609C21.8382 16.9526 21.3674 17.1484 20.8174 17.1484H14.8174V19.1484H16.8174V21.1484H8.81738ZM4.81738 15.1484H20.8174V5.14844H4.81738V15.1484Z"
                style={{ fill: color }}
                className="app-icon-fill"
            />
        </svg>
    );
};
