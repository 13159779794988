import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import Button from '../../components/Button';
import { Symbol } from '../../components/elements/Icon';
import PopupMenu, {
    PopupButton,
    PositionTypes,
    SelectBox,
} from '../../components/Popup';
import { Text } from '../../components/Text';
import useAlias from '../../hooks/useAlias';
import useAuth from '../../hooks/useAuth';

export default function AliasviewFullTopPanel() {
    const authCtx = useAuth();
    const aliasCtx = useAlias();

    const selectRef = useRef(null);

    const [options, setOptions] = useState([]);

    const navigateTo = useNavigate();

    useEffect(() => {
        var opts = []
        authCtx.aliases && authCtx.aliases.map((alias, x) => {
            opts.push({
                id: alias.alias.uid,
                label: alias.alias.label,
                value: alias.alias.alias
            });
        });
        setOptions(opts);
    }, [authCtx.aliases]);


    const renderSubuser = () => {
        const onLockButtonClick = (e) => {
            authCtx.removeAliasLogin({
                uid: aliasCtx.alias.uid,
            });
            window.location.assign('/');
        };

        if (aliasCtx.subuser && aliasCtx.subuser.name) {
            return (
                <Button onClick={onLockButtonClick}>
                    <Symbol
                        name={'lock'}
                        className={'mr-8'}
                    />
                    <Text value={aliasCtx.subuser.name.cell.value} />
                </Button>
            );
        }

        return <></>;
    };

    const renderOnlyAdmin = () => {

        if(!aliasCtx.member || (aliasCtx.member && !aliasCtx.member.scope.includes('owner')))
        {
            return <></>;
        }

        const handleOnChange = (e) => {
            navigateTo(`../../${e.target.value}`);
        }


        return (
            <>
                <SelectBox
                    ref={selectRef}
                    onChange={handleOnChange}
                    options={options}
                    triggerStyle={{
                        width: 160,
                        minWidth: 160,
                    }}
                    defaultValue={aliasCtx.alias.alias}
                />
            </>
        );

    }

    const handleSignoutClick = (e) => {
        authCtx.removeAliasLogin({
            uid: aliasCtx.alias.uid
        })
        authCtx.removeLoginCookies({
            redirectToMain: true,
        });
    };

    return (
        <>
            <div
                className="--panel-section"
                style={{ marginRight: 40 }}>
                <img
                    height={30}
                    className="--alias-branding"
                    src={aliasCtx.branding}
                />
            </div>

            <div className="--panel-section">
                {aliasCtx.loading && (
                    <Button
                        loading={aliasCtx.loading}
                        type={'icon'}
                        style={{ pointerEvents: 'none' }}></Button>
                )}
                {renderSubuser()}

                {renderOnlyAdmin()}

                <PopupMenu
                    contentStyle={{ width: 200, padding: 0 }}
                    offsetX={-10}
                    offsetY={0}
                    trigger={
                        <Button
                            type={'icon'}
                            className={'no-default-style'}
                            style={{ width: 40, height: 40 }}>
                            <Symbol name={'more_vert'} />
                        </Button>
                    }
                    position={PositionTypes.BottomRight}>
                    <PopupButton
                        icon={'logout'}
                        onClick={handleSignoutClick}
                        label={'Signout'}
                    />
                </PopupMenu>
            </div>
        </>
    );
}
