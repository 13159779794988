import { useRef, useState } from 'react';
import Button from '../Button';
import Input from '../Input';
import PopupMenu from '../Popup';
import { Text } from '../Text';
import { callApi } from '../../functions/api';
import { apiRoutes } from '../../helpers/api';
import { showError } from '../towsts/Toasts';

export function UseLicenseKey({ onSuccess = () => {}, onCancel = () => {} }) {
    const licenseKeyInput = useRef(null);
    const [licenseKey, setLicenseKey] = useState('');
    const [waiting, setWaiting] = useState(false);

    async function checkLicenseKey() {
        if (!licenseKeyInput.current) {
            return;
        }
        let licenseKey = licenseKeyInput.current.value.trim();
        if (!licenseKey) {
            return;
        }
        setWaiting(true);
        const res = await callApi({
            path: apiRoutes.licenseKey.applyKey,
            method: 'post',
            payload: {
                license_key: licenseKey,
            },
        });
        if (
            res.status === 200 &&
            (res.data || {}).message === 'license_apply_success'
        ) {
            onSuccess();
        } else if (
            res.status === 404 &&
            (res.data || {}).message === 'invalid_license_key'
        ) {
            showError('Invalid license key!');
        } else {
            showError('Unknown Error!');
        }
        setWaiting(false);
    }

    return (
        <PopupMenu
            disabled={waiting}
            open={true}
            onClose={onCancel}
            closeOnDocumentClick={false}>
            <form
                onSubmit={(evnt) => {
                    evnt.preventDefault();
                    checkLicenseKey();
                }}
                style={{ maxWidth: '400px' }}
                className="--popup-content">
                <label className="t-ext" htmlFor="labelForHowYouFindUsInput">
                    license key
                </label>
                <Input
                    ref={licenseKeyInput}
                    id={'labelForHowYouFindUsInput'}
                    onChange={(evnt) => {
                        setLicenseKey(evnt.target.value);
                    }}
                    value={licenseKey}
                    placeholder={'license key'}
                    autocapitalize={'none'}
                    spellcheck={'false'}
                />
            </form>
            <div className="--popup-actions">
                <Button
                    loading={false}
                    disabled={waiting}
                    onClick={onCancel}
                    style={{ minWidth: '100px' }}
                    type={'secondary'}>
                    <Text value={'Cancel'} />
                </Button>
                <Button
                    loading={waiting}
                    onClick={checkLicenseKey}
                    style={{ minWidth: '100px' }}
                    type={'primary'}>
                    <Text value={'Confirm'} />
                </Button>
            </div>
        </PopupMenu>
    );
}
