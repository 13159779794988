// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page-preview-container {
  width: 100%;
  height: calc(100% - 4px);
  min-width: 768px;
  max-width: 1440px;
  margin: 0 auto;
}
.page-preview-container.mobile {
  width: 375px;
  min-width: unset;
  max-width: unset;
}`, "",{"version":3,"sources":["webpack://./src/components/help-page-preview/help-page-preview.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,wBAAA;EACA,gBAAA;EACA,iBAAA;EACA,cAAA;AACJ;AAAI;EACI,YAAA;EACA,gBAAA;EACA,gBAAA;AAER","sourcesContent":[".page-preview-container {\n    width: 100%;\n    height: calc(100% - 4px);\n    min-width: 768px;\n    max-width: 1440px;\n    margin: 0 auto;\n    &.mobile {\n        width: 375px;\n        min-width: unset;\n        max-width: unset;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
