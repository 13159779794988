import { useEffect, useRef, useState } from 'react';
import Button from '../../components/Button';
import { Symbol } from '../../components/elements/Icon';
import Input from '../../components/Input';
import { Text } from '../../components/Text';

//import IconsList from '../../constants/IconsList.json';
import dbicons from '../../constants/db_icons_list.json';
import useSetting from '../../hooks/useSetting';
import useAlias from '../../hooks/useAlias';
import PopupMenu, { PopupButton, PositionTypes, SelectBox } from '../../components/Popup'
import { FieldsData } from '../../layouts/fullview/fieldsdata';
import { useNavigate } from 'react-router-dom';
import './dialog.scss';
import { callApi } from '../../functions/api';
import { getAPIBase } from '../../env';
import { apiRoutes } from '../../helpers/api';
import { showError } from '../../components/towsts/Toasts';
import useDatabase from '../../hooks/useDatabase'
import Popup from '../../components/Popup'

export const GDialogTabs = {
    database: 'iefdwf',
};

export default function GlobalDialog() {
    const dbIconInputFileIcon = useRef(null);
    const dbNameInput = useRef(null);
    const navigate = useNavigate();

    const settingCtx = useSetting();
    const aliasCtx = useAlias();
    const dbCtx = useDatabase();

    const [visible, setVisible] = useState(false);
    const [tab, setTab] = useState(false);

    const [databaseTitle, setDatabaseTitle] = useState('');

    const [selectIconVisibility, setSelectIconVisibility] = useState(false);
    const [icons, setIcons] = useState([]);
    const [selectedIcon, setSelectedIcon] = useState(icons[0]);

    const [dbCreationState, setDbCreationState] = useState(0);
    const [fieldsToCreate, setFieldsToCreate] = useState([
        {
            label: '',
            type: 'string',
        },
    ]);

    const [loading, setLoading] = useState(false);

    const morePopupRef = useRef(null);
    const [morePopupOpen, setMorePopupOpen] = useState(false);

    useEffect(() => {
        let iconsList = dbicons.map((icon) => {
            let fLetter = icon.substring(0, 1).toUpperCase();
            return {
                id: icon,
                label:
                    fLetter +
                    icon.substring(1).replaceAll('_', ' ').replaceAll('-', ' '),
            };
        });
        setIcons(iconsList);
        if (!selectedIcon) {
            setSelectedIcon(iconsList[0]);
        }
    }, [selectIconVisibility]);
    useEffect(() => {
        let iconsList = dbicons.map((icon) => {
            let fLetter = icon.substring(0, 1).toUpperCase();
            return {
                id: icon,
                label:
                    fLetter +
                    icon.substring(1).replaceAll('_', ' ').replaceAll('-', ' '),
            };
        });
        setIcons(iconsList);
        if (
            settingCtx.g_dialog.visible &&
            settingCtx.g_dialog.data &&
            settingCtx.g_dialog.data.mode === 'edit'
        ) {
            setDatabaseTitle(settingCtx.g_dialog.data.label);
            if ((settingCtx.g_dialog.data.icon ?? '').indexOf(':') >= 0) {
                setSelectedIcon({
                    id: settingCtx.g_dialog.data.icon,
                    name: '',
                });
            } else {
                let dbIcon = icons.filter(
                    (icon) => icon.id === settingCtx.g_dialog.data.icon
                );
                if ((dbIcon || []).length > 0) {
                    setSelectedIcon(dbIcon[0]);
                } else {
                    setSelectedIcon(iconsList[0]);
                }
            }
        } else {
            setSelectedIcon(iconsList[0]);
            setDatabaseTitle('');
        }
        try {
            dbNameInput.current.previousSibling.click();
            /*dbNameInput.current.click()
            dbNameInput.current.focus()*/
        } catch (e) {}
    }, [visible]);

    useEffect(() => {
        setVisible(settingCtx.g_dialog.visible);
        if (dbIconInputFileIcon.current) {
            dbIconInputFileIcon.current.value = '';
        }
    }, [settingCtx.g_dialog.visible]);

    useEffect(() => {
        setTab(settingCtx.g_dialog.active);
    }, [settingCtx.g_dialog.active]);

    const handleClose = () => {
        if (loading) {
            return;
        }
        try {
            sessionStorage.removeItem('generate_with_ai_description');
            localStorage.removeItem('faqnation_create_new_project');
        } catch (err) {}
        setSelectIconVisibility(false);
        settingCtx.setGDialog({
            visible: false,
            data: {
                uid: null,
                icon: 'login',
                color: 'rgba(34, 41, 53, 1)',
                label: null,
                mode: 'add', // or 'edit'
            },
        });
        setDbCreationState(0);
        setFieldsToCreate([
            {
                label: '',
                type: 'string',
            },
        ]);
    };

    const saveDatabase = async () => {
        if (databaseTitle.trim().length === 0) {
            try {
                dbNameInput.current.previousSibling.click();
            } catch (e) {}
            return;
        }

        setLoading(true);
        let savingDone = false;
        if (
            databaseTitle !== settingCtx.g_dialog.data.label ||
            (selectedIcon.id.indexOf(':') < 0 &&
                selectedIcon.id !== settingCtx.g_dialog.data.icon)
        ) {
            aliasCtx
                .updateDatabase({
                    label: databaseTitle,
                    icon:
                        selectedIcon.id.indexOf(':') >= 0
                            ? undefined
                            : selectedIcon.id,
                    uid: settingCtx.g_dialog.data.uid,
                })
                .then(() => {
                    if (savingDone) {
                        setLoading(false);
                        handleClose();
                    }
                    savingDone = true;
                });
        }
        {
            const res = await uploadProjectIcon({
                aliasId: aliasCtx.alias.uid,
                projectId: settingCtx.g_dialog.data.uid,
            });
            aliasCtx.setBasic({
                payload: {
                    databases: aliasCtx.databases.map((database) => {
                        if (database.uid === settingCtx.g_dialog.data.uid) {
                            return {
                                ...database,
                                icon: selectedIcon.id,
                                label: databaseTitle,
                            };
                        }
                        return database;
                    }),
                },
            });
            if (savingDone) {
                setLoading(false);
                handleClose();
            }
            savingDone = true;
        }
    };


    const createDatabase = async () => {
        if (databaseTitle.trim().length === 0) {
            try {
                dbNameInput.current.previousSibling.click();
            } catch (e) {}
            return;
        }

        if(dbCreationState === 0) {
            setDbCreationState(1);
            return;
        }

        for (let n = 0; n < fieldsToCreate.length; n++) {
            let field = fieldsToCreate[n];
            if (!field.label || !field.label.trim()) {
                try {
                    let newFieldsNameInputs = document.getElementsByClassName(
                        'db-field-name-input'
                    );
                    newFieldsNameInputs[n].previousSibling.click();
                } catch (err) {}
                return;
            }
        }

        setLoading(true);
        aliasCtx
            .createDatabase({
                label: databaseTitle,
                icon: selectedIcon.id,
                // layout: 'faqnation',
            })
            .then(async (res) => {
                if (res.status === 400) {
                    setLoading(false);
                    if (res.data.messageCode === 'LIMIT_REACHED') {
                        settingCtx.setUpgradeProjectDialog({
                            visible: true,
                            project_id: undefined,
                            msg: 'You have reached the maximum number of projects!\nYou can buy more projects as you need',
                        });
                    } else {
                        showError('Error creating project, Please try again!');
                    }
                    return;
                } else if (res.status !== 201) {
                    showError('Error creating project, Please try again!');
                    return;
                }
                const dbId = res.data.template.template.uid;
                /*let error = false;
                const createdDbRes = await Database.fetchDatabaseRecords({
                    aliasuid: aliasCtx.alias.uid,
                    databaseuid: dbId,
                });
                if (
                    !createdDbRes.data ||
                    !createdDbRes.data.template ||
                    !createdDbRes.data.template.recordsets ||
                    (createdDbRes.data.template.recordsets[
                        recordsetsIdx.PageRecordset
                    ] &&
                        createdDbRes.data.template.recordsets[
                            recordsetsIdx.PageRecordset
                        ].records.length === 0)
                ) {
                    error = true;
                    try {
                        await aliasCtx.deleteDatabase({ uid: dbId });
                    } catch (err) {
                        document.location = '/';
                    }
                }

                if (!error) {*/
                // try {
                //     let projectDescription = sessionStorage.getItem(
                //         'generate_with_ai_description'
                //     );
                //     if (projectDescription) {
                //         let aliasuid = aliasCtx.alias.uid;
                //         if (!dbId) {
                //             throw new Error('');
                //         }
                //         const res = await callApi({
                //             method: 'post',
                //             path: apiRoutes.AI.generateFAQs
                //                 .replace('{aliasuid}', aliasuid)
                //                 .replace('{databaseuid}', dbId),
                //             payload: { description: projectDescription },
                //         });
                //         sessionStorage.removeItem(
                //             'generate_with_ai_description'
                //         );
                //     }
                //     localStorage.removeItem('faqnation_create_new_project');
                // } catch (err) {
                //     console.error(err);
                // } finally {
                //     setLoading(false);
                // }
                handleClose();
                const upload_icon_res = await uploadProjectIcon({
                    aliasId: aliasCtx.alias.uid,
                    projectId: dbId,
                });

                // const dbId = res.data.template.template.uid;
                const aliasuid = aliasCtx.alias.uid;
                let n = 0;
                while (n < fieldsToCreate.length) {
                    let newField = fieldsToCreate[n];
                    let m = 0;
                    while (m === 0) {
                        try {
                            let r = await dbCtx.addField({
                                aliasuid: aliasuid,
                                dbId: dbId,
                                label: newField.label,
                                type: newField.type,
                                defaultValue: null,
                                fieldsetuid: null,
                                attrs: newField.attributes,
                                noReload: true,
                            });
                            m++;
                        } catch (err) {}
                    }
                    n++;
                }

                setLoading(false);
                navigate('/' + aliasCtx.alias.alias + '/' + dbId);
                /*} else {
                    alert('Error creating project, Please try again!');
                }*/
            });
    };

    const uploadProjectIcon = async ({
        aliasId = undefined,
        projectId = undefined,
    }) => {
        if (
            !dbIconInputFileIcon.current ||
            !dbIconInputFileIcon.current.files[0]
        ) {
            return;
        }
        const formData = new FormData();
        formData.append('file', dbIconInputFileIcon.current.files[0]);
        const res = await callApi({
            method: 'put',
            path: `${apiRoutes.database.uploadProjectIcon
                .replace('{aliasuid}', aliasId)
                .replace('{databaseuid}', projectId)}`,

            headers: { 'Content-Type': 'multipart/form-data' },
            payload: formData,
        });
    };

    const handleIconSearch = (e) => {
        let searchQuery = (e.target.value || '').trim();

        if (searchQuery && searchQuery.length > 0) {
            /** search */
            if (dbicons) {
                var _slist = [];
                dbicons.map((item) => {
                    if (
                        item.toLowerCase().includes(searchQuery.toLowerCase())
                    ) {
                        _slist.push(item);
                    }
                });

                setIcons(
                    _slist.map((icon) => {
                        let fLetter = icon.substring(0, 1).toUpperCase();
                        return {
                            id: icon,
                            label:
                                fLetter +
                                icon
                                    .substring(1)
                                    .replaceAll('_', ' ')
                                    .replaceAll('-', ' '),
                        };
                    })
                );
            }
        } else {
            setIcons(
                dbicons.map((icon) => {
                    let fLetter = icon.substring(0, 1).toUpperCase();
                    return {
                        id: icon,
                        label:
                            fLetter +
                            icon
                                .substring(1)
                                .replaceAll('_', ' ')
                                .replaceAll('-', ' '),
                    };
                })
            );
        }
    };

    return (
        <>
            {visible && (
                <>
                    {/*<div
                    className="br-dialog--backfall"
                    style={{
                        zIndex: 99999,
                    }}>
                    <div
                        className="--database-dialog scroll-container"
                        ref={dialogRef}>
                        {tab === GDialogTabs.database ? (
                            <DatabaseGDiaTab onClose={handleClose} />
                        ) : (
                            ''
                        )}
                    </div>
                </div>*/}
                    <PopupMenu
                        className={('--add-database-popup')+(dbCreationState === 1 ? " --full-view" : "")}
                        closeOnDocumentClick={false}
                        open={settingCtx.g_dialog.visible}
                        onClose={() => {
                            handleClose();
                        }}>
                        <div className={'--add-database-popup-header'}>
                            {/*<span></span>*/}
                            {/*<Button*/}
                            {/*    className={*/}
                            {/*        'button-icon with-bg close-add-database-popup'*/}
                            {/*    }*/}
                            {/*    // onClick={handleClose}*/}
                            {/*>*/}
                            {/*    <Symbol name={'more_horiz'} />*/}
                            {/*</Button>*/}
                            <PopupMenu
                                ref={morePopupRef}
                            offsetX={0}
                            offsetY={0}
                            contentStyle={{
                                width: 170
                            }}
                            open={morePopupOpen}
                            onClose={() => {
                                setMorePopupOpen(false);
                            }}
                            onOpen={() => {
                                setMorePopupOpen(true);
                            }}
                            closeOnDocumentClick={true}
                            trigger={
                                <Button
                                    className={
                                        'button-icon with-bg close-add-database-popup'
                                    }
                                >
                                    <Symbol name={'more_horiz'} />
                                </Button>
                            }
                            position={PositionTypes.BottomLeft}>
                                <PopupButton
                                    icon={'close'}
                                label={'Remove'}
                                onClick={() => {
                                    handleClose();
                                }} />
                            </PopupMenu>
                            <Button
                                className={
                                    'button-icon with-bg close-add-database-popup'
                                }
                                onClick={handleClose}>
                                <Symbol name={'close'} />
                            </Button>
                        </div>
                        <>
                            <div className={'--add-database-popup-content'}>
                                {dbCreationState === 0 ? (
                                    <>
                                        <div className={"--add-database-select-icon-title"}>
                                            <span className={"t-ext"}>Icon</span>
                                        </div>
                                        <div
                                            className={
                                                '--add-database-select-icon'
                                            }>
                                            <PopupMenu
                                                className={
                                                    '--select-icon-popup'
                                                }
                                                offsetX={0}
                                                nested={true}
                                                open={selectIconVisibility}
                                                onClose={() => {
                                                    setSelectIconVisibility(
                                                        false
                                                    );
                                                }}
                                                onOpen={() => {
                                                    setSelectIconVisibility(
                                                        true
                                                    );
                                                }}
                                                trigger={
                                                    <Button
                                                        className={
                                                            'select-dbicon'
                                                        }
                                                        type={'icon'}>
                                                        <DbIcon
                                                            className="select-dbicon-icon"
                                                            name={
                                                                selectedIcon
                                                                    ? selectedIcon.id
                                                                    : ''
                                                            }
                                                        />
                                                    </Button>
                                                }>
                                                <UploadProjectIcon/>
                                                <div className="icons-list-container">
                                                    {icons.map((icon) => {
                                                        return (
                                                            <Button
                                                                onClick={() => {
                                                                    setSelectedIcon(
                                                                        {
                                                                            ...icon,
                                                                            url: undefined,
                                                                        }
                                                                    );
                                                                    if (
                                                                        dbIconInputFileIcon.current
                                                                    ) {
                                                                        dbIconInputFileIcon.current.value =
                                                                            '';
                                                                    }
                                                                    setSelectIconVisibility(
                                                                        false
                                                                    );
                                                                }}>
                                                                <DbIcon
                                                                    className="select-dbicon-icon"
                                                                    size="40px"
                                                                    name={
                                                                        icon.id
                                                                    }
                                                                />
                                                            </Button>
                                                        );
                                                    })}
                                                </div>
                                            </PopupMenu>
                                        </div>
                                        <div
                                            className={'--add-database-title'}
                                            data-label={'Name'}>
                                            <Input
                                                ref={dbNameInput}
                                                value={databaseTitle}
                                                id={'db-name-input'}
                                                placeholder={'Place a database title'}
                                                autoFocus={true}
                                                onChange={(e) => {
                                                    setDatabaseTitle(
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                        </div>
                                    </>
                                ) : (
                                    <SetDbFields
                                        fieldsToCreate={fieldsToCreate}
                                        setFieldsToCreate={setFieldsToCreate}
                                    />
                                )}
                            </div>
                            <div className={'--add-database-popup-actions'}>
                                <Button
                                    type={'secondary'}
                                    onClick={handleClose}>
                                    Cancel
                                </Button>
                                {settingCtx.g_dialog.data.mode === 'add' ? (
                                    <Button
                                        type={'primary'}
                                        loading={loading}
                                        onClick={createDatabase}>
                                        {dbCreationState === 0 ? 'Next' : 'Done'}
                                    </Button>
                                ) : (
                                    <Button
                                        type={'primary'}
                                        loading={loading}
                                        onClick={saveDatabase}>
                                        Save
                                    </Button>
                                )}
                            </div>
                        </>
                    </PopupMenu>
                    <input
                        ref={dbIconInputFileIcon}
                        id="project-icon-file-input"
                        className="project-icon-file-input"
                        type="file"
                        accept="image/*"
                        onChange={(evnt) => {
                            if (!evnt.target.files[0]) {
                                return;
                            }
                            setSelectedIcon({
                                id: URL.createObjectURL(evnt.target.files[0]),
                                name: '',
                            });
                            setSelectIconVisibility(false);
                        }}
                    />
                </>
            )}
        </>
    );
}

const SetDbFields = ({ fieldsToCreate, setFieldsToCreate }) => {
    const fieldTypeRef = useRef();

    return (
        <>
            {fieldsToCreate.map((elm, i) => {
                return (
                    <div className={'--add-field-container'}>
                        <div className={'--add-field-drag-handle'}>
                            <Button
                                className={
                                    'button-icon'
                                }>
                                <Symbol name={'drag_handle'} />
                            </Button>
                        </div>
                        <div className={'--add-field-title'}>
                            <Input
                                value={elm.label}
                                className={'db-field-name-input form-input-box-wide-mobile'}
                                id={'db-field-name-input-' + i}
                                placeholder={'Title'}

                                autoFocus={
                                    i === fieldsToCreate.length - 1 &&
                                    (!elm.label || !elm.label.trim().length)
                                }
                                onChange={(e) => {
                                    setFieldsToCreate((oldV) => {
                                        let newV = oldV.slice(0)
                                        newV[i].label = e.target.value
                                        return newV.slice(0)
                                    })
                                }}
                            />
                        </div>
                        <div className="--add-field-type-item">
                            <SelectBox
                                ref={fieldTypeRef}
                                onChange={(e) => {
                                    //console.log(e)
                                    setFieldsToCreate((oldV) => {
                                        let newV = oldV.slice(0)
                                        newV[i].type = e.option.value
                                        return newV.slice(0)
                                    })
                                    //handleFieldChange(e, ruleIndex);
                                }}
                                options={FieldsData.map((field, x) => {
                                    return {
                                        id: `${field.type}`,
                                        value: field.type,
                                        icon: field.icon,
                                        label: field.label,
                                        disabled: true,
                                    }
                                })}
                                triggerStyle={{
                                    width: '100%',
                                }}
                                defaultValue={elm.type ? elm.type : 'string'}
                            />
                        </div>
                        <div
                            className={`--add-field-item-visibility`}
                            onClick={() => {
                            }}>
                            <Button
                                className={
                                    'button-icon with-bg'
                                }
                                onClick={() => {
                                    console.log("Fields to create :: ", fieldsToCreate)
                                    setFieldsToCreate((oldV) => {
                                        let newV = oldV.slice(0)
                                        if(newV[i]["attributes"] && newV[i]["attributes"]["hidden"]) {
                                            newV[i].attributes = {
                                                ...(newV["attributes"] || {}),
                                                "hidden": false
                                            }
                                        }
                                        else
                                        {
                                            newV[i].attributes = {
                                                ...(newV["attributes"] || {}),
                                                "hidden": true
                                            }
                                        }
                                        return newV.slice(0)
                                    })
                                }}>
                                <Symbol name={elm["attributes"] && elm["attributes"]["hidden"] ? 'visibility_off' : 'visibility'} />
                            </Button>
                        </div>
                        <div
                            className={`--add-field-item-delete ${
                                i === 0 ? '--invisible-NO' : ''
                            }`}
                            onClick={() => {
                                // setFieldsToCreate((oldV) => {
                                //     let newV = oldV.slice(0)
                                //     newV.splice(i, 1)
                                //     //[i].type = e.option.value;
                                //     return newV.slice(0)
                                // })
                                /*let newSorlRules =
                                        brCtx.sortRulesList.slice(0);
                                    newSorlRules.splice(ruleIndex, 1);
                                    brCtx.setBasic({
                                        sortRulesList: newSorlRules.slice(0),
                                    });*/
                            }}>

                            <Button
                                className={
                                    'button-icon with-bg'
                                }
                                onClick={() => {
                                    setFieldsToCreate((oldV) => {
                                        let newV = oldV.slice(0)
                                        newV.splice(i, 1)
                                        //[i].type = e.option.value;
                                        return newV.slice(0)
                                    })
                                }}>
                                <Symbol name={'delete'} />
                            </Button>
                        </div>
                    </div>
                );
            })}
            {
                <>
                    <div className={'--add-field-container'}>
                        <div className={'--add-field-nn'}>
                            <Button
                                type={'secondary'}
                                onClick={() => {
                                    setFieldsToCreate((oldV) => {
                                        let newV = oldV.slice(0)
                                        newV.push({
                                            label: 'String',
                                            type: 'string',
                                            attributes: {
                                                hidden: false
                                            }
                                        })
                                        return newV.slice(0)
                                    })
                                }}>
                                Add
                            </Button>
                            {/*<SelectBox*/}
                            {/*    ref={fieldTypeRef}*/}
                            {/*    onChange={(e) => {*/}
                            {/*        //console.log(e)*/}
                            {/*        setFieldsToCreate((oldV) => {*/}
                            {/*            let newV = oldV.slice(0)*/}
                            {/*            newV.push({*/}
                            {/*                label: '',*/}
                            {/*                type: e.option.value,*/}
                            {/*                attributes: {*/}
                            {/*                    hidden: false*/}
                            {/*                }*/}
                            {/*            })*/}
                            {/*            return newV.slice(0)*/}
                            {/*        })*/}
                            {/*        //handleFieldChange(e, ruleIndex);*/}
                            {/*    }}*/}
                            {/*    options={FieldsData.map((field, x) => {*/}
                            {/*        return {*/}
                            {/*            id: `${field.type}`,*/}
                            {/*            value: field.type,*/}
                            {/*            icon: field.icon,*/}
                            {/*            label: field.label,*/}
                            {/*            disabled: true,*/}
                            {/*        };*/}
                            {/*    })}*/}
                            {/*    triggerStyle={{*/}
                            {/*        width: '100%',*/}
                            {/*    }}*/}
                            {/*    placeholder={'Add'}*/}
                            {/*    defaultValue={null}*/}
                            {/*    forceDefaultValue={true}*/}
                            {/*/>*/}
                        </div>
                        <div className="--add-field-type-item"></div>
                        <div
                            className={`--add-field-item-delete --invisible`}
                            onClick={() => {
                                /*let newSorlRules =
                                        brCtx.sortRulesList.slice(0);
                                    newSorlRules.splice(ruleIndex, 1);
                                    brCtx.setBasic({
                                        sortRulesList: newSorlRules.slice(0),
                                    });*/
                            }}>
                            <Symbol />
                        </div>
                    </div>
                </>
            }
        </>
    );
};

export const DbIcon = ({ name = '', size = '18px', className = '' }) => {
    let url = null;
    if (name && name.indexOf('image:') >= 0) {
        url = getAPIBase() + '/media/' + (name.split(':')[1] ?? '');
        name = null;
    } else if (name && name.indexOf(':') >= 0) {
        url = name;
        name = null;
    }
    return (
        <span
            className={'db-icon ' + className}
            style={{
                width: size,
                height: size,
                display: 'block',
                backgroundImage: `url(${
                    url
                        ? url
                        : `'/static/images/db_icons/svg_icons/${name}.svg'`
                })`,
            }}></span>
    );
};

const StartFromScratchIcon = () => {
    return (
        <svg
            width="73"
            height="73"
            viewBox="0 0 73 73"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M41.8993 12.1673H21.292V6.08398H47.9826L41.8993 12.1673ZM29.7326 24.334H12.167V18.2507H35.816L29.7326 24.334ZM17.566 36.5007H3.04199V30.4173H23.6493L17.566 36.5007ZM44.4087 42.584L36.5003 34.6757L21.292 49.884L29.2003 57.7923L44.4087 42.584ZM40.8347 30.3413L48.743 38.2496L63.8753 23.1173L55.967 15.209L40.8347 30.3413ZM34.4472 28.2121L50.8722 44.6371L33.4587 62.0507C32.242 63.2673 30.8225 63.8757 29.2003 63.8757C27.5781 63.8757 26.1587 63.2673 24.942 62.0507L24.7899 61.8986L22.8128 63.8757H7.60449L17.1857 54.2944L17.0337 54.1423C15.817 52.9257 15.2087 51.5062 15.2087 49.884C15.2087 48.2618 15.817 46.8423 17.0337 45.6257L34.4472 28.2121ZM34.4472 28.2121L51.7087 10.9507C52.9253 9.73398 54.3448 9.12565 55.967 9.12565C57.5892 9.12565 59.0087 9.73398 60.2253 10.9507L68.1337 18.859C69.3503 20.0757 69.9587 21.4951 69.9587 23.1173C69.9587 24.7395 69.3503 26.159 68.1337 27.3757L50.8722 44.6371L34.4472 28.2121Z"
                fill="#3f4c63"
            />
        </svg>
    );
};
const UseAIIcon = () => {
    return (
        <svg
            width="73"
            height="73"
            viewBox="0 0 73 73"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12.167 63.875V48.6667C12.167 46.9938 12.7627 45.5616 13.954 44.3703C15.1453 43.179 16.5774 42.5833 18.2503 42.5833H54.7503C56.4232 42.5833 57.8554 43.179 59.0467 44.3703C60.238 45.5616 60.8337 46.9938 60.8337 48.6667V63.875H12.167ZM27.3753 39.5417C23.1677 39.5417 19.5811 38.0589 16.6154 35.0932C13.6498 32.1276 12.167 28.541 12.167 24.3333C12.167 20.1257 13.6498 16.5391 16.6154 13.5734C19.5811 10.6078 23.1677 9.125 27.3753 9.125H45.6253C49.833 9.125 53.4196 10.6078 56.3852 13.5734C59.3508 16.5391 60.8337 20.1257 60.8337 24.3333C60.8337 28.541 59.3508 32.1276 56.3852 35.0932C53.4196 38.0589 49.833 39.5417 45.6253 39.5417H27.3753ZM18.2503 57.7917H54.7503V48.6667H18.2503V57.7917ZM27.3753 33.4583H45.6253C48.16 33.4583 50.3146 32.5712 52.0889 30.7969C53.8632 29.0226 54.7503 26.8681 54.7503 24.3333C54.7503 21.7986 53.8632 19.6441 52.0889 17.8698C50.3146 16.0955 48.16 15.2083 45.6253 15.2083H27.3753C24.8406 15.2083 22.6861 16.0955 20.9118 17.8698C19.1375 19.6441 18.2503 21.7986 18.2503 24.3333C18.2503 26.8681 19.1375 29.0226 20.9118 30.7969C22.6861 32.5712 24.8406 33.4583 27.3753 33.4583ZM27.3753 27.375C28.2371 27.375 28.9595 27.0835 29.5425 26.5005C30.1255 25.9175 30.417 25.1951 30.417 24.3333C30.417 23.4715 30.1255 22.7491 29.5425 22.1661C28.9595 21.5832 28.2371 21.2917 27.3753 21.2917C26.5135 21.2917 25.7911 21.5832 25.2081 22.1661C24.6252 22.7491 24.3337 23.4715 24.3337 24.3333C24.3337 25.1951 24.6252 25.9175 25.2081 26.5005C25.7911 27.0835 26.5135 27.375 27.3753 27.375ZM45.6253 27.375C46.4871 27.375 47.2095 27.0835 47.7925 26.5005C48.3755 25.9175 48.667 25.1951 48.667 24.3333C48.667 23.4715 48.3755 22.7491 47.7925 22.1661C47.2095 21.5832 46.4871 21.2917 45.6253 21.2917C44.7635 21.2917 44.0411 21.5832 43.4581 22.1661C42.8752 22.7491 42.5837 23.4715 42.5837 24.3333C42.5837 25.1951 42.8752 25.9175 43.4581 26.5005C44.0411 27.0835 44.7635 27.375 45.6253 27.375Z"
                fill="#3f4c63"
            />
        </svg>
    );
};

const UploadProjectIcon = ({ onChange }) => {
    const [uploading, setUploading] = useState(false);

    return (
        <div className="upload-project-icon-container">
            <label
                className="label-for-project-icon-file-input"
                htmlFor="project-icon-file-input"></label>
            <Button type={'primary'} loading={uploading} onClick={() => {}}>
                <Text value={'Upload icon'} />
            </Button>
        </div>
    );
};
