export const ReloadIcon = ({ className = null, color = undefined }) => {
    return (
        <svg
            className={'ui-sym path' + (className ? ' ' + className : '')}
            width="25"
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12.502 20.6914C10.2686 20.6914 8.37695 19.9164 6.82695 18.3664C5.27695 16.8164 4.50195 14.9247 4.50195 12.6914C4.50195 10.4581 5.27695 8.56641 6.82695 7.01641C8.37695 5.46641 10.2686 4.69141 12.502 4.69141C13.652 4.69141 14.752 4.92907 15.802 5.40441C16.852 5.87907 17.752 6.55807 18.502 7.44141V4.69141H20.502V11.6914H13.502V9.69141H17.702C17.1686 8.75807 16.4396 8.02474 15.515 7.49141C14.5896 6.95807 13.5853 6.69141 12.502 6.69141C10.8353 6.69141 9.41862 7.27474 8.25195 8.44141C7.08529 9.60807 6.50195 11.0247 6.50195 12.6914C6.50195 14.3581 7.08529 15.7747 8.25195 16.9414C9.41862 18.1081 10.8353 18.6914 12.502 18.6914C13.7853 18.6914 14.9436 18.3247 15.977 17.5914C17.0103 16.8581 17.7353 15.8914 18.152 14.6914H20.252C19.7853 16.4581 18.8353 17.8997 17.402 19.0164C15.9686 20.1331 14.3353 20.6914 12.502 20.6914Z"
                style={{ fill: color }}
                className="app-icon-fill"
            />
        </svg>
    );
};
