/**
 * @author: Ashu sharma (ashusharma.sigmadev@gmail.com) (github: @ashusharmasigdev)
 * @copyright 2022
 */

import React from 'react';
import { createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { CookiesProvider } from 'react-cookie';
import { BrowserRouter } from 'react-router-dom';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

import './assets/scss/master.scss';
import { AuthProvider } from './contexts/AuthCtx';
import Axios from './contexts/Axios';
import { LocaleProvider } from './contexts/LocaleCtx';
import { SettingProvider } from './contexts/SettingCtx';
import App from './App';

const root = createRoot(document.getElementById('root'));

root.render(
    <HelmetProvider>
        <CookiesProvider>
            <BrowserRouter>
                <AuthProvider>
                    <SettingProvider>
                        <Axios>
                            <LocaleProvider>
                                <App />
                            </LocaleProvider>
                        </Axios>
                    </SettingProvider>
                </AuthProvider>
            </BrowserRouter>
        </CookiesProvider>
    </HelmetProvider>
);

serviceWorkerRegistration.unregister();

reportWebVitals();
