export function GithubIcon({ className = null, color = undefined, color2=undefined }) {
    return (
        <svg
            className={'ui-sym path' + (className ? ' ' + className : '')}
            width="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <ellipse
                cx="10.4579"
                cy="10.2628"
                rx="8.38171"
                ry="8.7843"
                style={{ fill: color2 }}
                className="app-icon-fill-2"
            />
            <path
                d="M9.38569 1.5392C5.46775 1.9776 2.31637 5.22174 1.89051 9.16731C1.46464 13.2882 3.7643 16.9708 7.25638 18.3736C7.51189 18.4613 7.76741 18.286 7.76741 17.9352V16.5324C7.76741 16.5324 7.42672 16.6201 7.00086 16.6201C5.80844 16.6201 5.29741 15.5679 5.21224 14.9541C5.12706 14.6034 4.95672 14.3404 4.7012 14.0774C4.44568 13.9897 4.36051 13.9897 4.36051 13.902C4.36051 13.7266 4.61603 13.7266 4.7012 13.7266C5.21224 13.7266 5.6381 14.3404 5.80844 14.6034C6.23431 15.3049 6.74534 15.4802 7.00086 15.4802C7.34155 15.4802 7.59707 15.3925 7.76741 15.3049C7.85258 14.6911 8.1081 14.0774 8.61914 13.7266C6.66017 13.2882 5.21224 12.1484 5.21224 10.2195C5.21224 9.25499 5.6381 8.29051 6.23431 7.58908C6.14913 7.41372 6.06396 6.97532 6.06396 6.36157C6.06396 6.01085 6.06396 5.48477 6.31948 4.9587C6.31948 4.9587 7.5119 4.9587 8.70431 6.09853C9.13017 5.92317 9.72638 5.83549 10.3226 5.83549C10.9188 5.83549 11.515 5.92317 12.026 6.09853C13.1333 4.9587 14.4109 4.9587 14.4109 4.9587C14.5812 5.48477 14.5812 6.01085 14.5812 6.36157C14.5812 7.063 14.496 7.41372 14.4109 7.58908C15.0071 8.29051 15.4329 9.16731 15.4329 10.2195C15.4329 12.1484 13.985 13.2882 12.026 13.7266C12.5371 14.165 12.8778 14.9541 12.8778 15.7433V18.0229C12.8778 18.286 13.1333 18.549 13.474 18.4613C16.6254 17.1461 18.8398 13.9897 18.8398 10.3071C18.8398 5.04638 14.496 0.925448 9.38569 1.5392Z"
                style={{ fill: color }}
                className="app-icon-fill"
            />
        </svg>
    );
}
