export const ImportIcon = ({
    className = null,
    color = undefined,
    color2 = undefined,
}) => {
    return (
        <svg
            className={'ui-sym path' + (className ? ' ' + className : '')}
            width="17"
            viewBox="0 0 17 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0.794922 2.94922C0.794922 1.84465 1.69035 0.949219 2.79492 0.949219H14.7949C15.8995 0.949219 16.7949 1.84465 16.7949 2.94922V14.9492C16.7949 16.0538 15.8995 16.9492 14.7949 16.9492H2.79492C1.69035 16.9492 0.794922 16.0538 0.794922 14.9492V2.94922Z"
                style={{ fill: color2 }}
                className="app-icon-fill-2"
            />
            <path
                d="M2.79492 16.9492C2.24492 16.9492 1.77426 16.7536 1.38292 16.3622C0.990922 15.9702 0.794922 15.4992 0.794922 14.9492V11.9492H2.79492V14.9492H14.7949V11.9492H16.7949V14.9492C16.7949 15.4992 16.5993 15.9702 16.2079 16.3622C15.8159 16.7536 15.3449 16.9492 14.7949 16.9492H2.79492ZM7.79492 12.9492V4.79922L5.19492 7.39922L3.79492 5.94922L8.79492 0.949219L13.7949 5.94922L12.3949 7.39922L9.79492 4.79922V12.9492H7.79492Z"
                style={{ fill: color }}
                className="app-icon-fill"
            />
        </svg>
    );
};
