export const APIConfig = {
    host: process.env.REACT_APP_API_HOST,
    port: process.env.REACT_APP_API_PORT,
};

console.log("api config ::: ", APIConfig)

export function getAPIBase() {
    if (!APIConfig.port) {
        return `${APIConfig.host}`;
    }

    return `${APIConfig.host}:${APIConfig.port}`;
}
