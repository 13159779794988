export default function EmbedIcon({ className = null, color = undefined }) {
    return (
        <svg
            className={'ui-sym path' + (className ? ' ' + className : '')}
            width="19"
            viewBox="0 0 19 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <rect
                x="3.21777"
                y="4.36914"
                width="13.0098"
                height="9.04297"
                style={{ fill: color }}
                className="app-icon-fill-2"
            />
            <path
                d="M1.45801 16.7194V15.221H17.9397V16.7194H1.45801ZM3.70551 14.4719C3.29347 14.4719 2.94073 14.3252 2.64731 14.0317C2.35389 13.7383 2.20717 13.3856 2.20717 12.9735V4.73271C2.20717 4.32067 2.35389 3.96793 2.64731 3.67451C2.94073 3.38109 3.29347 3.23438 3.70551 3.23438H15.6922C16.1042 3.23438 16.4569 3.38109 16.7504 3.67451C17.0438 3.96793 17.1905 4.32067 17.1905 4.73271V12.9735C17.1905 13.3856 17.0438 13.7383 16.7504 14.0317C16.4569 14.3252 16.1042 14.4719 15.6922 14.4719H3.70551ZM3.70551 12.9735H15.6922V4.73271H3.70551V12.9735Z"
                style={{ fill: color }}
                className="app-icon-fill"
            />
        </svg>
    );
}
