import { useEffect } from 'react';
import Page from '../../components/Page';
import Container from '../../layouts/mainlayout/Container';
import MainLayout from '../../layouts/mainlayout/MainLayout';
import './contact-sales.scss';

export default function ContactSalesPage() {
    useEffect(() => {
        let script = document.createElement('script');
        script.src = 'https://cdn.deftform.com/embed.js';
        document.body.appendChild(script);
        return () => {
            try {
                document.body.removeChild(script);
            } catch (err) {}
        };
    }, []);
    return (
        <Page title="FAQ Nation - Contact Sales">
            <MainLayout>
                <section style={{ marginBottom: '100px', marginTop: '80px' }}>
                    <h3>Contact Sales</h3>
                    <Container className={'contactFormContainer'}>
                        <div
                            className="deftform"
                            data-form-id="01c1f301-8222-479d-91b7-3c1fbee9570d"
                            data-form-width="100%"
                            data-form-height="800"
                            data-form-auto-height="1"></div>
                    </Container>
                </section>
            </MainLayout>
        </Page>
    );
}
