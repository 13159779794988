import { apiRoutes } from '../helpers/api';
import { callApi } from './api';

/**
 * create database
 */
const createDatabase = async ({
    aliasuid = null,
    dbdata = null,
    layout = null,
}) => {
    return await callApi({
        headers: {
            'alias-authorization':
                'Bearer ' + localStorage.getItem(aliasuid) || undefined,
        },
        method: 'post',
        path:
            apiRoutes.database.createDatabase.replace('{aliasuid}', aliasuid) +
            (layout ? `?layout=${layout}` : ''),
        payload: dbdata,
    });
};

/**
 * database fetch image
 */
const fetchDatabaseImage = async ({ imageuid = null }) => {
    return await callApi({
        method: 'get',
        path: apiRoutes.database.getDatabaseImage.replace(
            '{imageuid}',
            `${imageuid}`
        ),
    });
};

/**
 * database fetch
 */
const fetchDatabase = async ({
    aliasuid = null,
    databaseuid = null,
    cancelToken = undefined,
    signal = undefined,
}) => {
    const response = await callApi({
        headers: {
            'alias-authorization':
                'Bearer ' + localStorage.getItem(aliasuid) || undefined,
        },
        method: 'get',
        path: apiRoutes.database.getDatabase
            .replace('{aliasuid}', `${aliasuid}`)
            .replace('{databaseuid}', `${databaseuid}`),
        signal: signal,
        cancelToken: cancelToken,
    });

    fixFieldsetsOrder(response);
    return response;
};

/**
 * database limits fetch
 */
const fetchDatabaseLimits = async ({
    databaseuid = null,
    cancelToken = undefined,
    signal = undefined,
}) => {
    const response = await callApi({
        method: 'get',
        path: apiRoutes.database.getDatabaseLimits.replace(
            '{databaseuid}',
            `${databaseuid}`
        ),
        signal: signal,
        cancelToken: cancelToken,
    });
    
    return response;
};

/** delete database */
const deleteDatabase = async ({ aliasuid = null, databaseuid = null }) => {
    return await callApi({
        headers: {
            'alias-authorization':
                'Bearer ' + localStorage.getItem(aliasuid) || undefined,
        },
        method: 'delete',
        path: apiRoutes.database.deleteDatabase
            .replace('{aliasuid}', `${aliasuid}`)
            .replace('{databaseuid}', `${databaseuid}`),
    });
};

/**
 *
 * update database
 */
const updateDatabase = async ({
    aliasuid = null,
    databaseuid = null,
    dbdata = null,
}) => {
    return await callApi({
        headers: {
            'alias-authorization':
                'Bearer ' + localStorage.getItem(aliasuid) || undefined,
        },
        method: 'put',
        path: apiRoutes.database.updateDatabase
            .replace('{aliasuid}', `${aliasuid}`)
            .replace('{databaseuid}', `${databaseuid}`),
        payload: dbdata || {},
    });
};

/**
 * database fetch with rows and its details
 */

const fetchDatabaseRecords = async ({
    aliasuid = null,
    databaseuid = null,
    cancelToken = undefined,
    signal = undefined,
}) => {
    try {
        const response = await callApi({
            headers: {
                'alias-authorization':
                    'Bearer ' + localStorage.getItem(aliasuid) || undefined,
            },
            method: 'get',
            path: apiRoutes.database.getDatabaseRecords
                .replace('{aliasuid}', `${aliasuid}`)
                .replace('{databaseuid}', `${databaseuid}`),
            signal: signal,
            cancelToken: cancelToken,
        });
        fixFieldsetsOrder(response);
        return response;
    } catch (err) {
        console.error(err);
        return {};
        //document.location.reload();
    }
};

/**
 * database fetch one row and its details
 */

const fetchDatabaseRecord = async ({
    aliasuid = null,
    databaseuid = null,
    recordsetuid = null,
    recorduid = null,
    cancelToken = undefined,
    signal = undefined,
}) => {
    try {
        return await callApi({
            headers: {
                'alias-authorization':
                    'Bearer ' + localStorage.getItem(aliasuid) || undefined,
            },
            method: 'get',
            path: apiRoutes.database.getDatabaseRecord
                .replace('{aliasuid}', `${aliasuid}`)
                .replace('{databaseuid}', `${databaseuid}`)
                .replace('{recordsetuid}', `${recordsetuid}`)
                .replace('{recorduid}', `${recorduid}`),
            signal: signal,
            cancelToken: cancelToken,
        });
    } catch (err) {
        console.error(err);
        //document.location.reload();
    }
};

/**
 * create/update cell automatically
 */
const createOrUpdateCell = async ({
    aliasuid = null,
    databaseuid = null,
    recordsetuid = null,
    recorduid = null,
    fieldsetuid = null,
    fielduid = null,
    celldata = null,
}) => {
    return await callApi({
        method: 'post',
        headers: {
            'alias-authorization':
                'Bearer ' + localStorage.getItem(aliasuid) || undefined,
        },
        path: apiRoutes.database.createOrUpdateCell
            .replace('{aliasuid}', `${aliasuid}`)
            .replace('{databaseuid}', `${databaseuid}`)
            .replace('{recordsetuid}', `${recordsetuid}`)
            .replace('{recorduid}', `${recorduid}`)
            .replace('{fieldsetuid}', `${fieldsetuid}`)
            .replace('{fielduid}', `${fielduid}`),
        payload: celldata,
    });
};

/**
 * get calendar cells audits
 */
const getCalendarCellAudits = async ({
    aliasuid = null,
    databaseuid = null,
    celldata = null,
}) => {
    return await callApi({
        method: 'post',
        headers: {
            'alias-authorization':
                'Bearer ' + localStorage.getItem(aliasuid) || undefined,
        },
        path: apiRoutes.database.getCalendarCellAudits
            .replace('{aliasuid}', `${aliasuid}`)
            .replace('{databaseuid}', `${databaseuid}`),
        payload: celldata,
    });
};

/**
 * create/update calendar cell automatically
 */
const createOrUpdateCalendarCell = async ({
    aliasuid = null,
    databaseuid = null,
    recordsetuid = null,
    recorduid = null,
    fieldsetuid = null,
    fielduid = null,
    celldata = null,
}) => {
    return await callApi({
        method: 'post',
        headers: {
            'alias-authorization':
                'Bearer ' + localStorage.getItem(aliasuid) || undefined,
        },
        path: apiRoutes.database.createOrUpdateCalendarCell
            .replace('{aliasuid}', `${aliasuid}`)
            .replace('{databaseuid}', `${databaseuid}`)
            .replace('{recordsetuid}', `${recordsetuid}`)
            .replace('{recorduid}', `${recorduid}`)
            .replace('{fieldsetuid}', `${fieldsetuid}`)
            .replace('{fielduid}', `${fielduid}`),
        payload: celldata,
    });
};

/**
 * add recordset
 */
const createRecordset = async ({
    aliasuid = null,
    databaseuid = null,
    recordsetdata = null,
}) => {
    return await callApi({
        method: 'post',
        path: apiRoutes.database.createRecordset
            .replace('{aliasuid}', `${aliasuid}`)
            .replace('{databaseuid}', `${databaseuid}`),
        payload: recordsetdata,
    });
};

/**
 * update recordset
 */
const updateRecordset = async ({
    aliasuid = null,
    databaseuid = null,
    recordsetuid = null,
    recordsetdata = null,
}) => {
    return await callApi({
        method: 'put',
        path: apiRoutes.database.updateRecordset
            .replace('{aliasuid}', `${aliasuid}`)
            .replace('{databaseuid}', `${databaseuid}`)
            .replace('{recordsetuid}', `${recordsetuid}`),
        payload: recordsetdata,
    });
};

/**
 * delete recordset
 */
const deleteRecordset = async ({
    aliasuid = null,
    databaseuid = null,
    recordsetuid = null,
}) => {
    return await callApi({
        method: 'delete',
        path: apiRoutes.database.deleteRecordset
            .replace('{aliasuid}', `${aliasuid}`)
            .replace('{databaseuid}', `${databaseuid}`)
            .replace('{recordsetuid}', `${recordsetuid}`),
    });
};

/*
 * add record
 */
const createRecord = async ({
    aliasuid = null,
    databaseuid = null,
    recordsetuid = null,
    recorddata = null,
}) => {
    return await callApi({
        method: 'post',
        path: apiRoutes.database.createRecord
            .replace('{aliasuid}', `${aliasuid}`)
            .replace('{databaseuid}', `${databaseuid}`)
            .replace('{recordsetuid}', `${recordsetuid}`),
        payload: recorddata,
    });
};

/**
 * update record
 */
const updateRecord = async ({
    aliasuid = null,
    databaseuid = null,
    recordsetuid = null,
    recorduid = null,
    recorddata = null,
}) => {
    return await callApi({
        method: 'put',
        path: apiRoutes.database.updateRecord
            .replace('{aliasuid}', `${aliasuid}`)
            .replace('{databaseuid}', `${databaseuid}`)
            .replace('{recordsetuid}', `${recordsetuid}`)
            .replace('{recorduid}', `${recorduid}`),
        payload: recorddata,
    });
};

/**
 * delete record
 */
const deleteRecord = async ({
    aliasuid = null,
    databaseuid = null,
    recordsetuid = null,
    recorduid = null,
}) => {
    return await callApi({
        method: 'delete',
        path: apiRoutes.database.deleteRecord
            .replace('{aliasuid}', `${aliasuid}`)
            .replace('{databaseuid}', `${databaseuid}`)
            .replace('{recordsetuid}', `${recordsetuid}`)
            .replace('{recorduid}', `${recorduid}`),
    });
};

/**
 * delete record
 */
const deleteRecords = async ({
    aliasuid = null,
    databaseuid = null,
    recordsetuid = null,
    recorduids = null,
}) => {
    return await callApi({
        method: 'delete',
        path: apiRoutes.database.deleteRecord
            .replace('{aliasuid}', `${aliasuid}`)
            .replace('{databaseuid}', `${databaseuid}`)
            .replace('{recordsetuid}', `${recordsetuid}`)
            .replace('{recorduid}', `${recorduids}`),
    });
};

/**
 * add fieldset
 */
const createFieldset = async ({ aliasuid = null, databaseuid = null }) => {
    return await callApi({
        method: 'post',
        path: apiRoutes.database.createFieldset
            .replace('{aliasuid}', `${aliasuid}`)
            .replace('{databaseuid}', `${databaseuid}`),
        payload: '{}',
    });
};

/**
 * auto add field
 */
const createAutoFieldAndFieldset = async ({
    aliasuid = null,
    databaseuid = null,
    fielddata = null,
}) => {
    return await callApi({
        method: 'post',
        path: apiRoutes.database.createAutoFieldAndFieldset
            .replace('{aliasuid}', `${aliasuid}`)
            .replace('{databaseuid}', `${databaseuid}`),
        payload: fielddata,
    });
};

/**
 * add field
 */
const createField = async ({
    aliasuid = null,
    databaseuid = null,
    fieldsetuid = null,
    fielddata = null,
}) => {
    return await callApi({
        method: 'post',
        path: apiRoutes.database.createField
            .replace('{aliasuid}', `${aliasuid}`)
            .replace('{databaseuid}', `${databaseuid}`)
            .replace('{fieldsetuid}', `${fieldsetuid}`),
        payload: fielddata,
    });
};

const fetchField = async ({
    aliasuid = null,
    databaseuid = null,
    fieldsetuid = null,
    fielduid = null,
    is_calendar = null,
}) => {
    return await callApi({
        method: 'get',
        path: (is_calendar
            ? apiRoutes.database.getCalendarField
            : apiRoutes.database.getField
        )
            .replace('{aliasuid}', `${aliasuid}`)
            .replace('{databaseuid}', `${databaseuid}`)
            .replace('{fieldsetuid}', `${fieldsetuid}`)
            .replace('{fielduid}', `${fielduid}`),
    });
};

const updateField = async ({
    aliasuid = null,
    databaseuid = null,
    fieldsetuid = null,
    fielduid = null,
    fielddata = null,
}) => {
    return await callApi({
        method: 'put',
        path: apiRoutes.database.updateField
            .replace('{aliasuid}', `${aliasuid}`)
            .replace('{databaseuid}', `${databaseuid}`)
            .replace('{fieldsetuid}', `${fieldsetuid}`)
            .replace('{fielduid}', `${fielduid}`),
        payload: fielddata,
    });
};

const createOptionForSelectField = async ({
    aliasuid = null,
    databaseuid = null,
    fieldsetuid = null,
    fielduid = null,
    optiondata = null,
}) => {
    //
    return await callApi({
        method: 'post',
        path: apiRoutes.database.createOptionForField
            .replace('{aliasuid}', `${aliasuid}`)
            .replace('{databaseuid}', `${databaseuid}`)
            .replace('{fieldsetuid}', `${fieldsetuid}`)
            .replace('{fielduid}', `${fielduid}`),
        payload: optiondata,
    });
};

const deleteField = async ({
    aliasuid = null,
    databaseuid = null,
    fieldsetuid = null,
    fielduid = null,
}) => {
    return await callApi({
        method: 'delete',
        path: apiRoutes.database.deleteField
            .replace('{aliasuid}', `${aliasuid}`)
            .replace('{databaseuid}', `${databaseuid}`)
            .replace('{fieldsetuid}', `${fieldsetuid}`)
            .replace('{fielduid}', `${fielduid}`),
    });
};

const fixFieldsetsOrder = (response) => {
    if (response.data && response.data.template) {
        let newFieldsets = [];
        response.data.template.fieldsets.map((fieldset) => {
            for (let i = 0; i < fieldset.fields.length; i++) {
                if (fieldset.fields[i].label === 'FAQ') {
                    newFieldsets[0] = { ...fieldset };
                    break;
                }
                if (fieldset.fields[i].label === 'Page title') {
                    newFieldsets[1] = { ...fieldset };
                    break;
                }
                if (fieldset.fields[i].label === 'Question') {
                    newFieldsets[2] = { ...fieldset };
                    break;
                }
            }
            return null;
        });
        if (!newFieldsets.length && response.data.template.fieldsets.length) {
            newFieldsets = response.data.template.fieldsets;
        }
        response.data.template.fieldsets = [...newFieldsets];
    }
};

export default {
    fetchDatabase,
    fetchDatabaseLimits,
    fetchDatabaseRecords,
    fetchDatabaseRecord,
    createOrUpdateCell,
    createOrUpdateCalendarCell,
    getCalendarCellAudits,
    createRecordset,
    updateRecordset,
    deleteRecordset,
    createRecord,
    updateRecord,
    deleteRecord,
    deleteRecords,
    createFieldset,
    createAutoFieldAndFieldset,
    createField,
    updateField,
    deleteField,
    fetchField,
    updateDatabase,
    createDatabase,
    deleteDatabase,
    createOptionForSelectField,
    fetchDatabaseImage,
};
