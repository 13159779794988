import axios from 'axios';
import React, {
    createContext,
    useEffect,
    useReducer,
    useRef,
    useState,
} from 'react';
import Database from '../functions/Database';
import useAlias from '../hooks/useAlias';
import useSetting from '../hooks/useSetting';
import { callApi } from '../functions/api';
import { apiRoutes } from '../helpers/api';

const initialState = {
    initialized: false,

    database: null,

    datasource: null,

    fieldsets: null,

    calendar_fieldsets: null,

    connected_sets: null,

    recordsets: null,

    alerts: null,

    loading:
        -1 /** index of below array: if it is greater than -1, means it is loading */,

    loading_process: [],

    settings: null,

    added_record_now: null,

    error: null,
};

const handlers = {
    INITIALIZE: (state, action) => {
        const { database } = action.payload;

        if (database) {
            return {
                ...state,
                error: null,
                initialized: true,
                database: database.template,
                datasource: database.datasource,
                fieldsets: database.fieldsets,
                calendar_fieldsets: database.calendar_fieldsets,
                recordsets: database.recordsets,
                layout: database.template.layout,
                settings: database.setting,
                subdomain: database.link,
                custom_domain_hint: database.custom_domain_hint,
                alerts:
                    (database.template.attributes &&
                        database.template.attributes.alerts) ||
                    null,
            };
        } else {
            return {
                ...state,
                initialized: false,
            };
        }
    },

    LATER: (state, action) => {
        return {
            ...state,
            ...(action.payload || {}),
        };
    },

    LOADING: (state, action) => {
        const { loading } = action.payload;
        return {
            ...state,
            loading: loading,
        };
    },

    BASIC: (state, action) => {
        return {
            ...state,
            ...(action.payload || {}),
        };
    },
};

const reducer = (state, action) =>
    handlers[action.type] ? handlers[action.type](state, action) : state;

const DatabaseContext = createContext({
    ...initialState,
    getRecordsets: () => {},
    getOneRecord: async () => {},
    createOrUpdateCell: async () => {},
    reload: async () => {},
    getCalendarCellAudits: async () => {},
    addRecordset: async () => {},
    updateRecordset: async () => {},
    deleteRecordset: async () => {},
    addRecord: async () => {},
    deleteRecord: async () => {},
    deleteRecords: async () => {},
    updateRecord: async () => {},
    addFieldset: async () => {},
    addField: async () => {},
    addFieldToFieldset: async () => {},
    updateField: async () => {},
    deleteField: async () => {},
    fetchField: async () => {},
    updateDatabase: async () => {},
    createOptionForSelectField: async () => {},
    exportDatabase: async () => {},
    setBasic: () => {},
});

const getColorFromGradient = (str = '') => {
    if (str.includes('linear-gradient')) {
        let i = str.indexOf('#');

        let r = str.substring(i, i + 1 + 6);
        return r;
    }

    return str;
};

function DatabaseProvider({ children, earlydatabase }) {
    const cancelTokenDatabase = useRef();

    const aliasCtx = useAlias();
    const settingCtx = useSetting();
    const [state, dispatch] = useReducer(reducer, initialState);

    const [loadedDatabase, setLoadedDatabase] = useState(null);

    useEffect(() => {
        const initialize = async () => {
            // var process = settingCtx.addProc

            cancelTokenDatabase.current = axios.CancelToken.source();

            setLoading(true);

            Database.fetchDatabase({
                aliasuid: aliasCtx.alias.uid,
                databaseuid: loadedDatabase.uid,
                cancelToken: cancelTokenDatabase.current.token,
            })
                .then((response) => {
                    if (response.status === 200) {
                        dispatch({
                            type: 'INITIALIZE',
                            payload: {
                                database:
                                    (response &&
                                        response.data &&
                                        response.data.template) ||
                                    null,
                            },
                        });

                        document.documentElement.style.setProperty(
                            '--theme-color',
                            getColorFromGradient(loadedDatabase.theme)
                        );
                    }
                    setLoading(false);

                    // settingCtx.finishProc(name)
                })
                .catch((e) => {
                    // console.log("WRONG LOADING")
                    // console.log(e);
                    dispatch({
                        type: 'INITIALIZE',
                        payload: {
                            error: e,
                        },
                    });
                });
            Database.fetchDatabaseLimits({ databaseuid: loadedDatabase.uid })
                .then((res) => {
                    setBasic({
                        limits: { ...res.data },
                    });
                })
                .catch((err) => {});
        };

        // console.log("[database.ctx] mounting");
        if (loadedDatabase) {
            initialize();
        }

        return () => {
            if (cancelTokenDatabase.current) {
                // console.log("[database.ctx] cancelling");
                cancelTokenDatabase.current.cancel();
            }
            dispatch({
                type: 'LATER',
                payload: {
                    ...initialState,
                },
            });
            // console.log("[database.ctx] unmounting");
        };
    }, [loadedDatabase]);

    useEffect(() => {
        setLoadedDatabase(earlydatabase);
    }, [earlydatabase]);

    const setLoading = (st) => {
        dispatch({
            type: 'LOADING',
            payload: {
                loading: st,
            },
        });
    };

    const setBasic = (data = {}) => {
        dispatch({
            type: 'BASIC',
            payload: data || {},
        });
    };

    const reload = async () => {
        // var process = settingCtx.addProc

        cancelTokenDatabase.current = axios.CancelToken.source();

        setLoading(true);

        Database.fetchDatabase({
            aliasuid: aliasCtx.alias.uid,
            databaseuid: loadedDatabase.uid,
            cancelToken: cancelTokenDatabase.current.token,
        })
            .then((response) => {
                if (response.status === 200) {
                    // dispatch({
                    //   type: "INITIALIZE",
                    //   payload: {
                    //     database:
                    //       (response && response.data && response.data.template) || null,
                    //   },
                    // });

                    const database =
                        (response && response.data && response.data.template) ||
                        null;

                    // console.log(database)
                    if (database) {
                        dispatch({
                            type: 'LATER',
                            payload: {
                                // database: database.template,
                                // datasource: database.datasource,
                                fieldsets: database.fieldsets,
                                calendar_fieldsets: database.calendar_fieldsets,
                                settings: database.setting,
                                subdomain: database.link,
                                custom_domain_hint: database.custom_domain_hint,
                                // recordsets: database.recordsets
                            },
                        });
                    }

                    document.documentElement.style.setProperty(
                        '--theme-color',
                        getColorFromGradient(loadedDatabase.theme)
                    );
                }

                setLoading(false);

                // settingCtx.finishProc(name)
            })
            .catch((e) => {
                dispatch({
                    type: 'INITIALIZE',
                    payload: {
                        error: e,
                    },
                });
                // console.log(e);
            });
    };

    const getRecordsets = async () => {
        if (!state.database) return;

        setLoading(true);

        cancelTokenDatabase.current = axios.CancelToken.source();

        const response = await Database.fetchDatabaseRecords({
            aliasuid: aliasCtx.alias && aliasCtx.alias.uid,
            databaseuid:
                (state.database && state.database.uid) || loadedDatabase.uid,
            cancelToken: cancelTokenDatabase.current.token,
        });

        var recordsets = [];

        if (response.status === 200) {
            recordsets = response.data.template.recordsets;
            dispatch({
                type: 'LATER',
                payload: {
                    recordsets: recordsets,
                },
            });
        } else {
            dispatch({
                type: 'LATER',
                payload: {
                    error: response,
                },
            });
        }

        setLoading(false);
    };

    const getOneRecord = async (recordsetuid, recorduid) => {
        if (!state.database) return;

        setLoading(true);

        cancelTokenDatabase.current = axios.CancelToken.source();

        const response = await Database.fetchDatabaseRecord({
            aliasuid: aliasCtx.alias && aliasCtx.alias.uid,
            databaseuid:
                (state.database && state.database.uid) || loadedDatabase.uid,
            recordsetuid: recordsetuid,
            recorduid: recorduid,
            cancelToken: cancelTokenDatabase.current.token,
        });

        var recordsets = [];

        if (response.status === 200) {
            recordsets = response.data.template.recordsets;
            dispatch({
                type: 'LATER',
                payload: {
                    recordsets: recordsets.slice(0),
                },
            });
        } else {
            dispatch({
                type: 'LATER',
                payload: {
                    error: response,
                },
            });
        }

        setLoading(false);
    };

    const createOrUpdateCell = async ({
        recordsetuid = null,
        recorduid = null,
        fieldsetuid = null,
        fielduid = null,
        value = null /** cellvalue */,
        attributes = null,
        datecode = null,
        optional_image = null,
        is_calendar = false,
    }) => {
        setLoading(true);

        var response = null;

        if (is_calendar) {
            response = await Database.createOrUpdateCalendarCell({
                aliasuid: state.database.aliasuid,
                databaseuid: state.database.uid,
                recordsetuid: recordsetuid,
                recorduid: recorduid,
                fieldsetuid: fieldsetuid,
                fielduid: fielduid,
                celldata: {
                    cellValue: value,
                    cellAttributes: attributes,
                    cellDatecode: datecode,
                    cellType: is_calendar ? 'calendar' : 'normal',
                    cellImage: optional_image || undefined,
                },
            });
        } else {
            let recordsetIdx = 0;
            let recordIdx = 0;
            let cellIdx = -1;
            //get recordset index
            for (let i = 0; i < state.recordsets.length; i++) {
                if (state.recordsets[i].uid === recordsetuid) {
                    recordsetIdx = i;
                    break;
                }
            }
            //get record index
            for (
                let i = 0;
                i < state.recordsets[recordsetIdx].records.length;
                i++
            ) {
                if (
                    state.recordsets[recordsetIdx].records[i].uid === recorduid
                ) {
                    recordIdx = i;
                    break;
                }
            }
            //get cell index
            for (
                let i = 0;
                i <
                state.recordsets[recordsetIdx].records[recordIdx].cells.length;
                i++
            ) {
                if (
                    state.recordsets[recordsetIdx].records[recordIdx].cells[i]
                        .fielduid === fielduid
                ) {
                    cellIdx = i;
                    break;
                }
            }

            let newRecordsets = state.recordsets.slice(0);
            if (cellIdx > -1) {
                let field = state.fieldsets[0].fields.filter(
                    (field) => field.uid === fielduid
                );
                let fieldType = ((field || [])[0] || {}).type;
                newRecordsets[recordsetIdx].records[recordIdx].cells[cellIdx] =
                    {
                        ...newRecordsets[recordsetIdx].records[recordIdx].cells[
                            cellIdx
                        ],
                        value:
                            value === '$cmd://remove'
                                ? null
                                : fieldType === 'list'
                                ? value
                                      .split(',')
                                      .filter((item) => item.length > 0)
                                      .map((item, i) => ({
                                          id: i,
                                          label: item,
                                      }))
                                : value,
                        attributes: attributes,
                    };
            } else {
                let newCell = {
                    attributes: attributes,
                    fielduid: fielduid,
                    uid: null,
                    value: value === '$cmd://remove' ? null : value,
                };
                newRecordsets[recordsetIdx].records[recordIdx].cells.push({
                    ...newCell,
                });
            }
            dispatch({
                type: 'LATER',
                payload: {
                    recordsets: newRecordsets,
                },
            });
            response = await Database.createOrUpdateCell({
                aliasuid: state.database.aliasuid,
                databaseuid: state.database.uid,
                recordsetuid: recordsetuid,
                recorduid: recorduid,
                fieldsetuid: fieldsetuid,
                fielduid: fielduid,
                celldata: {
                    cellValue: value,
                    cellAttributes: attributes,
                    cellDatecode: datecode,
                    cellType: is_calendar ? 'calendar' : 'normal',
                    cellImage: optional_image || undefined,
                },
            });

            if (response.status !== 200) {
                //error
            }
        }

        /*await reload();
        await getRecordsets();*/

        setLoading(false);
    };

    const getCalendarCellAudits = async (celluids = []) => {
        setLoading(true);

        var response = null;

        if (state.initialized) {
            response = await Database.getCalendarCellAudits({
                aliasuid: state.database.aliasuid || loadedDatabase.aliasuid,
                databaseuid:
                    (state.database && state.database.uid) ||
                    loadedDatabase.uid,
                celldata: celluids,
            });
        }

        // console.log(response);

        setLoading(false);

        if (
            state.database &&
            state.database.attributes &&
            state.database.attributes.tag
        ) {
            if (state.database.attributes.tag !== 'Prüflisten') {
                return null;
            }

            if (state.database.label === 'Temperaturkontrol') {
                return null;
            }
        }

        if (response.status === 200) {
            return response.data.template.calendar_cells_audits;
        }
        return null;
    };

    const updateRecordset = async ({ uid = null, attributes = undefined }) => {
        setLoading(true);

        const data = {};

        if (attributes != undefined) {
            data['recordsetAttributes'] = {
                ...attributes,
            };
        }

        const _resp = await Database.updateRecordset({
            aliasuid: state.database.aliasuid,
            databaseuid: state.database.uid,
            recordsetuid: uid,
            recordsetdata: data,
        });

        /** @todo merge adding recordset will also send all recordsets (as special route/option) */
        await getRecordsets();

        setLoading(false);
    };

    const addRecordset = async ({
        label = null,
        aliasuid = state.database.aliasuid,
        /** generate random colors */
        attributes = null,
        dbId = state.database.uid,
        noReload = false,
    }) => {
        setLoading(true);

        const _resp = await Database.createRecordset({
            aliasuid: aliasuid,
            databaseuid: dbId,
            recordsetdata: {
                recordsetAttributes: {
                    ...(attributes || {}),
                    label: label,
                },
            },
        });

        /** @todo merge adding recordset will also send all recordsets (as special route/option) */
        if (!noReload) {
            await getRecordsets();
        }

        setLoading(false);

        return _resp;
    };

    const deleteRecordset = async ({ uid = null }) => {
        setLoading(true);

        const _resp = await Database.deleteRecordset({
            aliasuid: state.database.aliasuid,
            databaseuid: state.database.uid,
            recordsetuid: uid,
        });

        /** @todo merge adding recordset will also send all recordsets (as special route/option) */
        await getRecordsets();

        setLoading(false);
    };

    const addRecord = async ({
        aliasuid = state.database.aliasuid,
        dbId = state.database.uid,
        recordsetuid = null,
        attributes = null,
        useAI = false,
        values = {},
        noReload = false,
    }) => {
        setLoading(true);

        const _resp = await Database.createRecord({
            aliasuid: aliasuid,
            databaseuid: dbId,
            recordsetuid: recordsetuid,
            recorddata: {
                recordAttributes: {
                    ...(attributes || {}),
                },
                recordValues: {
                    ...(values || {}),
                },
                useAI: useAI || false,
            },
        });

        /** @todo merge adding recordset will also send all recordsets (as special route/option) */
        if (!noReload && _resp.status === 201 && _resp.data.record) {
            //await getRecordsets();
            let editedRecordsetIndex = -1;
            for (let i = 0; i < state.recordsets.length; i++) {
                if (state.recordsets[i].uid === recordsetuid) {
                    editedRecordsetIndex = i;
                    break;
                }
            }

            let updatedRecordsets = state.recordsets.slice(0);
            try {
                updatedRecordsets[editedRecordsetIndex].records.push({
                    ..._resp.data.record,
                    calendar_cells: [],
                });

                setBasic({
                    recordsets: updatedRecordsets,
                });
            } catch (err) {
                console.error(err);
                //document.location.reload();
            }
        }
        setLoading(false);
        return _resp;
    };

    const updateRecord = async ({
        uid = null,
        recordsetuid = null,
        height = undefined,
        orderNumber = undefined,
        attributes = undefined,
        newrecordsetuid = undefined,
    }) => {
        setLoading(true);

        const data = {};

        if (attributes != undefined) {
            data['recordsetAttributes'] = {
                ...attributes,
            };
        }

        const _resp = await Database.updateRecord({
            aliasuid: state.database.aliasuid,
            databaseuid: state.database.uid,
            recordsetuid: recordsetuid,
            recorduid: uid,
            recorddata: {
                recordHeight: height,
                recordAttributes: attributes,
                recordOrderNumber: orderNumber,
                recordsetuid: newrecordsetuid,
            },
        });

        /** @todo merge adding recordset will also send all recordsets (as special route/option) */
        await getRecordsets();

        setLoading(false);
    };

    const deleteRecord = async ({
        recordsetuid = null,
        uid = null,
        noReload = false,
    }) => {
        setLoading(true);

        const _resp = await Database.deleteRecord({
            aliasuid: state.database.aliasuid,
            databaseuid: state.database.uid,
            recordsetuid: recordsetuid,
            recorduid: uid,
        });

        /** @todo merge adding recordset will also send all recordsets (as special route/option) */
        if (!noReload) {
            //await getRecordsets();
            let updatedRecordsets = state.recordsets.slice(0);
            let updatedRecordsetIndex = -1;
            for (let i = 0; i < state.recordsets.length; i++) {
                if (state.recordsets[i].uid === recordsetuid) {
                    updatedRecordsetIndex = i;
                    break;
                }
            }
            updatedRecordsets[updatedRecordsetIndex].records =
                updatedRecordsets[updatedRecordsetIndex].records.filter(
                    (record) => record.uid !== uid
                );
            setBasic({
                recordsets: updatedRecordsets.slice(0),
            });
        }

        setLoading(false);

        return _resp;
    };

    const deleteRecords = async ({
        recordsetuid = null,
        uids = null,
        noReload = false,
    }) => {
        setLoading(true);

        const _resp = await Database.deleteRecords({
            aliasuid: state.database.aliasuid,
            databaseuid: state.database.uid,
            recordsetuid: recordsetuid,
            recorduids: uids.join(','),
        });

        /** @todo merge adding recordset will also send all recordsets (as special route/option) */
        if (!noReload) {
            //await getRecordsets();
            let updatedRecordsets = state.recordsets.slice(0);
            let updatedRecordsetIndex = -1;
            for (let i = 0; i < state.recordsets.length; i++) {
                if (state.recordsets[i].uid === recordsetuid) {
                    updatedRecordsetIndex = i;
                    break;
                }
            }
            updatedRecordsets[updatedRecordsetIndex].records =
                updatedRecordsets[updatedRecordsetIndex].records.filter(
                    (record) => !uids.includes(record.uid)
                );
            setBasic({
                recordsets: updatedRecordsets.slice(0),
            });
        }

        setLoading(false);

        return _resp;
    };

    const addFieldset = async ({
        dbId = state.database.uid,
        aliasuid = state.database.aliasuid,
    }) => {
        setLoading(true);

        const _resp = await Database.createFieldset({
            aliasuid: aliasuid,
            databaseuid: dbId,
        });

        setLoading(false);
        return _resp;
    };

    const addField = async ({
        dbId = state.database.uid,
        aliasuid = state.database.aliasuid,
        label = null,
        type = null,
        defaultValue = null,
        fieldsetuid = null,
        attrs = null,
        width = 120,
        noReload = false,
    }) => {
        setLoading(true);

        const _resp = await Database.createAutoFieldAndFieldset({
            aliasuid: aliasuid,
            databaseuid: dbId,
            fielddata: {
                fieldLabel: label,
                fieldType: type,
                fieldDefaultValue: defaultValue,
                fieldsetuid: fieldsetuid,
                fieldAttributes: attrs,
                fieldWidth: width,
            },
        });

        /** @todo merge adding recordset will also send all recordsets (as special route/option) */
        if (!noReload) await reload();

        setLoading(false);
        return _resp;
    };

    const addFieldToFieldset = async ({
        dbId = state.database.uid,
        aliasuid = state.database.aliasuid,
        label = null,
        type = null,
        defaultValue = null,
        fieldsetuid = null,
        attrs = null,
        width = 120,
        noReload = false,
    }) => {
        setLoading(true);

        const _resp = await Database.createField({
            aliasuid: aliasuid,
            databaseuid: dbId,
            fieldsetuid: fieldsetuid,
            fielddata: {
                fieldLabel: label,
                fieldType: type,
                fieldDefaultValue: defaultValue,
                fieldsetuid: fieldsetuid,
                fieldAttributes: attrs,
                fieldWidth: width,
            },
        });

        /** @todo merge adding recordset will also send all recordsets (as special route/option) */
        if (!noReload) await reload();

        setLoading(false);
        return _resp;
    };

    const deleteField = async ({ fieldsetuid = null, uid = null }) => {
        setLoading(true);

        const _resp = await Database.deleteField({
            aliasuid: state.database.aliasuid,
            databaseuid: state.database.uid,
            fieldsetuid: fieldsetuid,
            fielduid: uid,
        });

        /** @todo merge adding recordset will also send all recordsets (as special route/option) */
        await reload();

        setLoading(false);
    };

    const fetchField = async ({
        fieldsetuid = null,
        uid = null,
        is_calendar = null,
    }) => {
        setLoading(true);

        const _resp = await Database.fetchField({
            aliasuid: state.database.aliasuid,
            databaseuid: state.database.uid,
            fieldsetuid: fieldsetuid,
            fielduid: uid,
            is_calendar: is_calendar,
        });

        /** @todo merge adding recordset will also send all recordsets (as special route/option) */
        // await reload();

        setLoading(false);

        return _resp;
    };

    const createOptionForSelectField = async ({
        fieldsetuid = null,
        uid = null,
        label = undefined,
        attributes = undefined,
    }) => {
        setLoading(true);

        const _resp = await Database.createOptionForSelectField({
            aliasuid: state.database.aliasuid,
            databaseuid: state.database.uid,
            fieldsetuid: fieldsetuid,
            fielduid: uid,
            optiondata: {
                optionValue: label,
                optionAttributes: attributes,
            },
        });

        /** @todo merge adding recordset will also send all recordsets (as special route/option) */
        // await reload();
        /** DO FETCH FIELD TO FETCH OPTIONS */

        setLoading(false);
    };

    const updateField = async ({
        fieldsetuid = null,
        uid = null,
        label = undefined,
        type = undefined,
        defaultValue = undefined,
        attributes = undefined,
        width = undefined,
        orderNumber = undefined,
        noReload = true,
    }) => {
        setLoading(true);

        const _resp = await Database.updateField({
            aliasuid: state.database.aliasuid,
            databaseuid: state.database.uid,
            fieldsetuid: fieldsetuid,
            fielduid: uid,
            fielddata: {
                fieldLabel: label,
                fieldType: type,
                fieldDefaultValue: defaultValue,
                fieldAttributes: attributes,
                fieldWidth: width,
                fieldOrderNumber: orderNumber,
            },
        });

        if (!noReload) {
            /** @todo merge adding recordset will also send all recordsets (as special route/option) */
            await reload();
        }

        setLoading(false);
    };

    const updateDatabase = async ({
        label = undefined,
        layout = undefined,
        attributes = undefined,
        alertMin = undefined,
        alertMax = undefined,
        templateLink = undefined,
        customDomain = undefined,
        apiEnabled = undefined,
        allowedOrigins = undefined,
        notification_enabled = undefined,
        notification_emails = undefined,
        notification_frequency = undefined,
        noReload = false,
    }) => {
        setLoading(true);

        const _resp = await Database.updateDatabase({
            aliasuid: state.database.aliasuid,
            databaseuid: state.database.uid,
            dbdata: {
                templateLabel: label,
                templateLayout: layout,
                templateAttributes: attributes,
                templateAlertMin: alertMin,
                templateAlertMax: alertMax,
                templateLink: templateLink,
                customDomain: customDomain,
                apiEnabled: apiEnabled,
                allowedOrigins: allowedOrigins,
                notification_enabled: notification_enabled,
                notification_emails: notification_emails,
                notification_frequency: notification_frequency,
                /** @todo fieldsetuid */
            },
        });

        if (!noReload) await reload();

        setLoading(false);

        return _resp;
    };

    const exportDatabase = async () => {
        if(!state.database){return}
        let res = await callApi({
            method: 'post',
            path: apiRoutes.database.export
                .replace(':aliasuid', state.database.aliasuid)
                .replace(':templateuid', state.database.uid),
            payload: {}
        });
    };

    return (
        <DatabaseContext.Provider
            value={{
                ...state,
                getRecordsets,
                getOneRecord,
                getCalendarCellAudits,
                createOrUpdateCell,
                reload,
                addRecordset,
                updateRecordset,
                deleteRecordset,
                addRecord,
                deleteRecord,
                deleteRecords,
                updateRecord,
                addFieldset,
                addField,
                addFieldToFieldset,
                updateField,
                deleteField,
                fetchField,
                updateDatabase,
                createOptionForSelectField,
                exportDatabase,
                setBasic,
            }}>
            {children}
        </DatabaseContext.Provider>
    );
}

export { DatabaseContext, DatabaseProvider };
