/** @format */

import React, { useEffect, useRef, useState } from 'react';
import Container from './Container';
import lgHeaderImage from '../../assets/images/lg-header.svg';
import './header.scss';
import useAuth from '../../hooks/useAuth';
import { CookiesList } from '../../constants/Cookies';
import { useCookies } from 'react-cookie';
import { Link } from 'react-router-dom';

export default function Header() {
    const authCtx = useAuth();
    const [authenticated, setAthenticated] = useState(false);
    const [cookies, setCookie, removeCookie] = useCookies([
        CookiesList.accessToken,
    ]);
    const links = useRef([
        { label: 'Plans', target: '/plans' },
        { label: 'Features', target: '/features' },
        { label: 'Contact', target: '/contact' },
    ]);

    useEffect(() => {
        if (document.location.hash) {
            let hash = document.location.hash;
            document.location.hash = '';
            document.location.hash = hash;
        }
        setAthenticated(
            cookies[CookiesList.accessToken] && cookies[CookiesList.accessToken]
        );
    }, []);

    return (
        <>
            <header>
                <Container className={'header-container'}>
                    <Link to={'/home'} className={`website-logo`}>
                        <img src={lgHeaderImage} alt="" />
                    </Link>
                    <nav className="full-nav">
                        <ul>
                            {(links.current || []).map((link, i) => {
                                if (!link.label || !link.target) {
                                    return null;
                                }
                                return (
                                    <li
                                        key={'link-' + i}
                                        className={`${
                                            link.type === 'button'
                                                ? 'nav-btn'
                                                : ''
                                        }`}>
                                        <Link to={link.target}>{link.label}</Link>
                                    </li>
                                );
                            })}
                        </ul>
                    </nav>
                    <Link
                        to={
                            authenticated || authCtx.authenticated
                                ? '/'
                                : `/login`
                        }
                        className="login-btn only-large-screens animateOnHover">
                        Log in
                    </Link>
                    {((buttonsLinksList) => {
                        return (
                            <>
                                {buttonsLinksList.length > 0 && (
                                    <nav className="btns-nav">
                                        <ul>
                                            {buttonsLinksList.map((link, i) => {
                                                if (
                                                    !link.label ||
                                                    !link.target
                                                ) {
                                                    return null;
                                                }
                                                return (
                                                    <li
                                                        key={'link-' + i}
                                                        className={`${
                                                            link.type ===
                                                            'button'
                                                                ? 'nav-btn'
                                                                : ''
                                                        }`}>
                                                        <Link to={link.target}>
                                                            {link.label}
                                                        </Link>
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </nav>
                                )}
                            </>
                        );
                    })(
                        (links.current || []).filter(
                            (link) => link.type === 'button'
                        )
                    )}
                </Container>
            </header>
        </>
    );
}
