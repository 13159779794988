import { memo, useEffect, useRef } from 'react';

function FaqNationWidget({
    faqId,
    faqnationProjectId,
    faqnationUserId,
    faqnationId,
    faqsLimit,
    options = {},
    apiBase,
    theme = '',
}) {
    const waitForScriptLoadingTimeout = useRef(null);
    useEffect(() => {
        if (!faqId || !faqId.trim()) {
            return () => {};
        }
        if (
            !document.getElementById(faqId) ||
            document.getElementById(faqId).children.length > 0
        ) {
            return;
        } else {
            document.getElementById(faqId).innerHTML = '';
        }
        if (!window.initFaqNation) {
            try {
                clearTimeout(waitForScriptLoadingTimeout.current);
            } catch (err) {}
            waitForScriptLoadingTimeout.current = setTimeout(() => {
                waitForScriptLoading();
            }, 1000);
            return () => {
                try {
                    document.getElementById(faqId).innerHTML = '';
                } catch (err) {
                    console.error(err);
                }
                try {
                    clearTimeout(waitForScriptLoadingTimeout.current);
                } catch (err) {
                    console.error(err);
                }
            };
        }
        initWidget();

        return () => {
            try {
                document.getElementById(faqId).innerHTML = '';
            } catch (err) {
                console.error(err);
            }
        };
    }, [faqId]);

    const waitForScriptLoading = () => {
        if (window.initFaqNation) {
            initWidget();
            return;
        }
        waitForScriptLoadingTimeout.current = setTimeout(() => {
            waitForScriptLoading();
        }, 1000);
    };

    const initWidget = () => {
        window.initFaqNation({
            faqnationWrapperId: faqId,
            faqnationApiBase: apiBase,
            faqnationProjectId: faqnationProjectId,
            faqnationUserId: faqnationUserId,
            faqnationId: faqnationId,
            faqsLimit: faqsLimit,
            faqnationOptions: options,
        });
    };

    return (
        <>
            <div className={`container`}>
                <div className={`faq-placeholder ${theme}`} id={faqId}></div>
            </div>
        </>
    );
}

export default memo(FaqNationWidget);
