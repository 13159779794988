export const CloseFull = ({ className = null, color = undefined }) => {
    return (
        <svg
            className={'ui-sym path' + (className ? ' ' + className : '')}
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M9.37896 24.2865L7.51465 22.4222L13.6403 16.2966L7.51465 10.171L9.37896 8.30664L17.3689 16.2966L9.37896 24.2865ZM21.3638 24.2865V8.30664H24.0271V24.2865H21.3638Z"
                style={{ fill: color }}
                className="app-icon-fill"
            />
        </svg>
    );
};
