export const ShareIcon = ({ className = null, color = undefined }) => {
    return (
        <svg
            className={'ui-sym path' + (className ? ' ' + className : '')}
            width="16"
            viewBox="0 0 11 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M2.74967 10.5423C2.49759 10.5423 2.28179 10.4526 2.10228 10.273C1.92276 10.0935 1.83301 9.87773 1.83301 9.62565V4.58398C1.83301 4.3319 1.92276 4.1161 2.10228 3.93659C2.28179 3.75707 2.49759 3.66732 2.74967 3.66732H4.12467V4.58398H2.74967V9.62565H8.24967V4.58398H6.87467V3.66732H8.24967C8.50176 3.66732 8.71756 3.75707 8.89707 3.93659C9.07658 4.1161 9.16634 4.3319 9.16634 4.58398V9.62565C9.16634 9.87773 9.07658 10.0935 8.89707 10.273C8.71756 10.4526 8.50176 10.5423 8.24967 10.5423H2.74967ZM5.04134 7.33398V2.21211L4.30801 2.94544L3.66634 2.29232L5.49967 0.458984L7.33301 2.29232L6.69134 2.94544L5.95801 2.21211V7.33398H5.04134Z"
                style={{ fill: color }}
                className="app-icon-fill"
            />
        </svg>
    );
};
