export const MobileIcon = ({
    className = null,
    color = undefined,
    color2 = undefined,
}) => {
    return (
        <svg
            width="22"
            className={'ui-sym path' + (className ? ' ' + className : '')}
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <rect
                x="6.16504"
                y="1.97852"
                width="10.1836"
                height="17.4707"
                style={{ fill: color2 }}
                className="app-icon-fill-2"
            />
            <path
                d="M6.93359 20.3633C6.45234 20.3633 6.04036 20.1919 5.69766 19.8492C5.35495 19.5065 5.18359 19.0945 5.18359 18.6133V2.86328C5.18359 2.38203 5.35495 1.97005 5.69766 1.62734C6.04036 1.28464 6.45234 1.11328 6.93359 1.11328H15.6836C16.1648 1.11328 16.5768 1.28464 16.9195 1.62734C17.2622 1.97005 17.4336 2.38203 17.4336 2.86328V18.6133C17.4336 19.0945 17.2622 19.5065 16.9195 19.8492C16.5768 20.1919 16.1648 20.3633 15.6836 20.3633H6.93359ZM6.93359 17.7383V18.6133H15.6836V17.7383H6.93359ZM6.93359 15.9883H15.6836V5.48828H6.93359V15.9883ZM6.93359 3.73828H15.6836V2.86328H6.93359V3.73828Z"
                style={{ fill: color }}
                className="app-icon-fill"
            />
        </svg>
    );
};
