import React, { createContext, useEffect, useReducer, useRef } from 'react';
import { GDialogTabs } from '../panels/dialog/main';
import { RightPanelTabs } from '../panels/right/main';
import { LeftPanelTabs } from '../panels/left/main';
import { rightPanelViewStyles } from '../panels/right/editFaqPanel/FaqEditorPanel';
import { callApi } from '../functions/api';
import { apiRoutes } from '../helpers/api';

const initialState = {
    initialized: false,

    default_color: 'rgba(34, 41, 53, 1)',
    active_color: 'rgba(34, 41, 53, 1)',

    finished_processes: [],
    running_processes: [],
    in_queue_processes: [],

    top_panel: {
        active: null,
        query: null,
        search_active: false,
        qr_query: null,
    },

    left_panel: {
        visible: false,
        width: 220,
        active: LeftPanelTabs.DbsList,
    },

    filter_panel: {
        visible: false,
        width: 220,
    },

    right_panel: {
        visible: false,
        active: RightPanelTabs.Recordview,
        view_style: null,
        force_view_style: null,
    },

    right_setting_panel: {
        visible: false,
    },

    right_users_questions_panel: {
        visible: false,
    },

    right_account_options_panel: {
        visible: false,
    },

    body_panel: {
        active: null,
    },

    upgrade_project_dialog: {
        visible: false,
        project_id: undefined,
        msg: '',
    },

    g_dialog: {
        visible: false,
        active: GDialogTabs.database,
        data: {
            uid: null,
            icon: 'login',
            color: 'rgba(34, 41, 53, 1)',
            label: null,
            mode: 'add', // or 'edit'
        },
    },

    view_mode: null,
};

const handlers = {
    INITIALIZE: (state, action) => {
        return {
            ...state,
            ...(action.payload || {}),
            initialized: true,
        };
    },

    PROCESS: (state, action) => {
        return {
            ...state,
            ...(action.payload || {}),
        };
    },

    PANEL: (state, action) => {
        return {
            ...state,
            ...(action.payload || {}),
        };
    },

    BASIC: (state, action) => {
        return {
            ...state,
            ...(action.payload || {}),
        };
    },
};

const reducer = (state, action) =>
    handlers[action.type] ? handlers[action.type](state, action) : state;

const SettingContext = createContext({
    ...initialState,
    setTopPanel: () => {},
    setBodyPanel: () => {},
    setViewmode: () => {},
    setLeftPanel: () => {},
    setFilterPanel: () => {},
    setRightPanel: () => {},
    setGDialog: () => {},
    setUpgradeProjectDialog: () => {},
    setBasic: () => {},
});

function SettingProvider({ children }) {
    const stateRef = useRef({});
    const [state, dispatch] = useReducer(reducer, initialState);

    const running_processes = useRef([]);
    const finished_processes = useRef([]);

    useEffect(() => {
        stateRef.current = state;
    }, [state]);

    useEffect(() => {
        const initialize = async () => {
            try {
                if (window.innerWidth >= 600) {
                    if (localStorage.getItem('left_panel_state')) {
                        initialState['left_panel']['visible'] =
                            localStorage.getItem('left_panel_state') === 'true';
                    }
                }
                if (localStorage.getItem('left_panel_width')) {
                    initialState['left_panel']['width'] = parseInt(
                        localStorage.getItem('left_panel_width')
                    );
                }
            } catch (err) {}
            dispatch({
                type: 'INITIALIZE',
            });
        };

        // console.log('[setting.ctx] mounting');

        initialize();

        return () => {
            // console.log('[setting.ctx] unmounting');
        };
    }, []);

    useEffect(() => {
        if (state.running_processes.length > 0) {
            // console.log('[setting.ctx] enabling alert before exiting.');
            window.onbeforeunload = function () {
                return true;
            };
        } else {
            // console.log('[setting.ctx] disabling alert before exiting.');
            window.onbeforeunload = null;
        }
    }, [state.running_processes]);

    const setTopPanel = (top_panel = {}) => {
        dispatch({
            type: 'PANEL',
            payload: {
                top_panel: {
                    ...(state.top_panel || {}),
                    ...(top_panel || {}),
                },
            },
        });
    };

    const setBodyPanel = (body_panel = {}) => {
        dispatch({
            type: 'PANEL',
            payload: {
                body_panel: {
                    ...(state.body_panel || {}),
                    ...(body_panel || {}),
                },
            },
        });
    };

    const setLeftPanel = (left_panel = {}) => {
        dispatch({
            type: 'PANEL',
            payload: {
                left_panel: {
                    ...(state.left_panel || {}),
                    ...(left_panel || {}),
                },
            },
        });

        try {
            localStorage.setItem(
                'left_panel_state',
                left_panel.visible ?? state.left_panel.visible
            );
            localStorage.setItem(
                'left_panel_width',
                left_panel.width ?? state.left_panel.width
            );
        } catch (err) {}
    };

    const setFilterPanel = (filter_panel = {}) => {
        dispatch({
            type: 'PANEL',
            payload: {
                filter_panel: {
                    ...(state.filter_panel || {}),
                    ...(filter_panel || {}),
                },
            },
        });
    };

    const setRightPanel = async (right_panel = {}) => {
        dispatch({
            type: 'PANEL',
            payload: {
                right_panel: {
                    ...(stateRef.current.right_panel || {}),
                    ...(right_panel || {}),
                },
            },
        });
        if (right_panel.view_style) {
            const res = await callApi({
                method: 'patch',
                path: apiRoutes.account.update,
                payload: {
                    recordEditorPosition: right_panel.view_style,
                },
            });
        }
    };

    const setUpgradeProjectDialog = (data = {}) => {
        setBasic({
            upgrade_project_dialog: {
                ...state.upgrade_project_dialog,
                ...data,
            },
        });
    };

    const setGDialog = (gd = {}) => {
        dispatch({
            type: 'PANEL',
            payload: {
                g_dialog: {
                    ...(state.g_dialog || {}),
                    ...(gd || {}),
                },
            },
        });
    };

    const setBasic = (data = {}) => {
        dispatch({
            type: 'BASIC',
            payload: data || {},
        });
    };

    const setViewmode = (viewm) => {
        dispatch({
            type: 'PROCESS',
            payload: {
                view_mode: viewm,
            },
        });
    };

    return (
        <SettingContext.Provider
            value={{
                ...state,
                setTopPanel,
                setBodyPanel,
                setLeftPanel,
                setFilterPanel,
                setRightPanel,
                setViewmode,
                setUpgradeProjectDialog,
                setGDialog,
                setBasic,
            }}>
            {children}
        </SettingContext.Provider>
    );
}

export { SettingContext, SettingProvider };
