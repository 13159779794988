import React, { forwardRef } from 'react';

export const Text = forwardRef(
    ({ value, className, style, dark = false, ...props }, ref) => {
        return (
            <span
                ref={ref}
                className={
                    't-ext' +
                    (className ? ' ' + className : '') +
                    (dark ? ' dark' : '')
                }
                style={style}
                {...props}>
                {value}
            </span>
        );
    }
);
