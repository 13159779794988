import { useEffect, useRef, useState } from 'react';
import './tags-editor.scss';
import { Symbol } from '../elements/Icon';
import { flushSync } from 'react-dom';
import Button from '../Button';
import { Tooltip } from '../Popup/Tooltip';
export function TagsEditor({
    placeholder,
    handleAddTags = () => {},
    handleAddTagsWithAI = async () => {},
    handleRemoveTags,
    tags,
    before = <></>,
}) {
    const inputSizeCalculatorRef = useRef(null);

    const [tagsList, setTagsList] = useState([]);
    const [newTagValue, setNewTagValue] = useState('');
    const [newTagInputSize, setNewTagInputSize] = useState(0);
    const [newTagInputHasFocus, setNewTagInputHasFocus] = useState(false);
    const [waitingForAutTagsGen, setWaitingForAutoTagsGen] = useState(false);

    useEffect(() => {
        setTagsList(tags || []);
    }, [tags]);

    /*useEffect(() => {
        if (!inputSizeCalculatorRef.current) {
            return;
        }
        setNewTagInputSize(inputSizeCalculatorRef.current.offsetWidth + 8);
    }, [newTagValue]);*/

    function deleteTag(tagId) {
        if (tagId === null || tagId === undefined) {
            return;
        }
        let newTagsList = tags.filter((tag) => tag.id !== tagId);
        setTagsList([...newTagsList]);
        handleAddTags({
            newTagsList: newTagsList.map((tag) => tag.label),
        });
    }

    function addTags(tags) {
        setTagsList((oldTags) => [
            ...oldTags,
            ...tags.map((tag, i) => ({
                label: tag,
                id: 'temp_' + i + Math.floor(Math.random() * 1000),
            })),
        ]);
        handleAddTags({
            newTagsList: [...tagsList.map((tag) => tag.label), ...tags],
        });
    }

    function handleAddTagInputChange(evnt) {
        if (evnt.target.value.indexOf(' ') > 0) {
            let tags = evnt.target.value.trim().split(' ');
            addTags(tags);
            setNewTagValue('');
            return;
        }
        setNewTagValue(evnt.target.value);
    }

    return (
        <div className="tags-editor-container">
            {before}
            <Tooltip
                trigger={
                    <Button
                        onClick={async () => {
                            setWaitingForAutoTagsGen(true);
                            let res = await handleAddTagsWithAI();
                            setWaitingForAutoTagsGen(false);
                        }}
                        disabled={waitingForAutTagsGen}
                        loading={waitingForAutTagsGen}
                        className={'ai-tags-generator-btn'}>
                        <Symbol name={'bot_icon'} />
                    </Button>
                }
                text={'Generate tags with AI'}
            />
            <Tooltip
                text={'Add tag'}
                trigger={
                    <Button
                        onClick={() => {
                            setNewTagInputHasFocus(true);
                        }}
                        disabled={waitingForAutTagsGen}
                        className={'add-new-tag-btn'}>
                        <Symbol name={'tag_icon'} />
                    </Button>
                }
            />
            {tagsList.map((tag) => (
                <div className="tag-elment" key={tag.id} title={tag.label}>
                    <span className="tag-elment-value">{tag.label}</span>
                    <span
                        className={`delete-tag-elment ${(() => {
                            return tag.id.toString().indexOf('temp_') === 0
                                ? 'saving'
                                : '';
                        })()}`}
                        tabIndex={-1}
                        onClick={() => {
                            if (waitingForAutTagsGen) {
                                return;
                            }
                            deleteTag(tag.id);
                        }}>
                        <Symbol name="close" />
                    </span>
                </div>
            ))}
            <form
                className="add-new-tag-input-container"
                onSubmit={(evnt) => {
                    evnt.preventDefault();
                    if (newTagValue.trim().length > 0) {
                        let tags = newTagValue.trim().split(' ');
                        addTags(tags);
                        setNewTagValue('');
                    }
                }}>
                <label
                    ref={inputSizeCalculatorRef}
                    className="add-new-tag-input-size-calculator">
                    {newTagValue}
                </label>
                {newTagInputHasFocus && (
                    <input
                        className="add-new-tag-input"
                        placeholder="Tag"
                        value={newTagValue}
                        autoFocus={true}
                        style={{
                            width: `${newTagInputSize}px`,
                        }}
                        onBlur={() => {
                            setNewTagInputHasFocus(false);
                        }}
                        onChange={(evnt) => {
                            flushSync(() => {
                                handleAddTagInputChange(evnt);
                            });
                            setNewTagInputSize(
                                inputSizeCalculatorRef.current.offsetWidth + 8
                            );
                        }}
                    />
                )}
            </form>
        </div>
    );
}
