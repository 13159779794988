import React, { Suspense, lazy } from 'react';
import { useRoutes, Outlet } from 'react-router';
import AliasPage from '../pages/alias';
import AliasHomepage from '../pages/alias/homepage';
import DatabasePage from '../pages/database';
import Databaseview from '../pages/database/view';
import MainPage from '../pages/main';
import OverviewPage from '../pages/main/overview';
import { PayFaild, PaySuccess } from '../pages/payResult';
import HomePage from '../pages/home/HomePage';
import EditFaqPage from '../pages/editfaq/EditFaqPage';
import { AuthPage } from '../pages/auth/AuthPage';
import PlansPage from '../pages/plans/PlansPage';
import FeaturesPage from '../pages/features/FeaturesPage';
import JobsPage from '../pages/jobs/JobsPage';
import EventsPage from '../pages/events/EventsPage';
import TermsPage from '../pages/terms/TermsPage';
import PrivacyPage from '../pages/privacy/PrivacyPage';
import GDPRPage from '../pages/gdpr/GDPRPage';
import ImprintPage from '../pages/imprint/ImprintPage';
import WhyFAQNationPage from '../pages/why faqnation/WhyFaqnationPage';
import APIDocsPage from '../pages/apidocs/APIDocsPage';
import FAQPage from '../pages/faq/FaqPage';
import ContactSalesPage from '../pages/contact-sales/ContactSales';
import {
    AuthenticationPage,
    ResetPasswordPage,
} from '../pages/login-signup/AuthenticationPage';
import ContactPage from '../pages/contact/ContactPage';

const Loadable = (Component) => (props) => {
    return (
        <Suspense fallback={<></>}>
            <Component {...props} />
        </Suspense>
    );
};

export default function Router() {
    return useRoutes([
        // {
        //     path: '/edit' /*this route is for FAQ editor page */,
        //     elm: <Outlet />,
        //     children: [
        //         {
        //             path: ':alias',
        //             element: (
        //                 <>
        //                     <AliasPage>
        //                         <Outlet />
        //                     </AliasPage>
        //                 </>
        //             ),
        //             children: [
        //                 {
        //                     path: ':database/:recordset/:record',
        //                     element: <EditFaqPage />,
        //                 },
        //             ],
        //         },
        //     ],
        // },
        {
            path: '/',
            element: (
                <MainPage>
                    <Outlet />
                </MainPage>
            ),
            children: [
                {
                    path: '/',
                    element: (
                        <>
                            <Outlet />
                        </>
                    ),
                    children: [
                        {
                            path: '',
                            element: <OverviewPage /> /** Dashboard */,
                        },
                        {
                            path: ':alias',
                            element: (
                                <>
                                    <AliasPage>
                                        <Outlet />
                                    </AliasPage>
                                </>
                            ),
                            children: [
                                {
                                    path: '',
                                    // element: <AliasOverview />,
                                    element: <AliasHomepage />,
                                },
                                {
                                    path: ':database',
                                    element: (
                                        <>
                                            <DatabasePage>
                                                <Outlet />
                                            </DatabasePage>
                                        </>
                                    ),
                                    children: [
                                        {
                                            path: '',
                                            element: <Databaseview />,
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
            ],
        },
        // {
        //     path: 'home',
        //     element: <HomePage />,
        // },
        {
            path: 'login',
            element: <AuthenticationPage />,
        },
        // {
        //     path: 'signup',
        //     element: <AuthenticationPage />,
        // },
        // {
        //     path: 'reset_password/:key',
        //     element: <ResetPasswordPage />,
        // },
        // {
        //     path: 'plans',
        //     element: <PlansPage />,
        // },
        // {
        //     path: 'features',
        //     element: <FeaturesPage />,
        // },
        // {
        //     path: 'contact',
        //     element: <ContactPage />,
        // },
        // {
        //     path: 'terms',
        //     element: <TermsPage />,
        // },
        // {
        //     path: 'privacy',
        //     element: <PrivacyPage />,
        // },
        // {
        //     path: 'gdpr',
        //     element: <GDPRPage />,
        // },
        // {
        //     path: 'imprint',
        //     element: <ImprintPage />,
        // },
        // {
        //     path: 'jobs',
        //     element: <JobsPage />,
        // },
        // {
        //     path: 'events',
        //     element: <EventsPage />,
        // },
        // {
        //     path: 'why-faqnation',
        //     element: <WhyFAQNationPage />,
        // },
        // {
        //     path: 'contact-sales',
        //     element: <ContactSalesPage />,
        // },
        // {
        //     path: 'api',
        //     element: <APIDocsPage />,
        // },
        // {
        //     path: 'faq',
        //     element: <FAQPage />,
        // },
        // {
        //     path: 'auth',
        //     element: <AuthPage />,
        // },
        // {
        //     path: 'pay-success',
        //     element: <PaySuccess />,
        // },
        // {
        //     path: 'pay-faild',
        //     element: <PayFaild />,
        // },
    ]);
}
