export const ColumnsIcon = ({ className = null, color = undefined }) => {
    return (
        <svg
            className={'ui-sym path' + (className ? ' ' + className : '')}
            width="25"
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <rect
                x="3.66211"
                y="5.48242"
                width="18.3662"
                height="13.332"
                style={{ fill: color }}
                className="app-icon-fill-2"
            />
            <path
                d="M2.33496 17.9001V6.36551C2.33496 5.73111 2.56085 5.18802 3.01262 4.73625C3.46439 4.28448 4.00748 4.05859 4.64188 4.05859H20.7615C21.3959 4.05859 21.939 4.28448 22.3907 4.73625C22.8425 5.18802 23.0684 5.73111 23.0684 6.36551V17.9001C23.0684 18.5345 22.8425 19.0776 22.3907 19.5294C21.939 19.9811 21.3959 20.207 20.7615 20.207H4.64188C4.00748 20.207 3.46439 19.9811 3.01262 19.5294C2.56085 19.0776 2.33496 18.5345 2.33496 17.9001ZM4.61304 17.9001H8.4483V6.36551H4.61304V17.9001ZM10.7552 17.9001H14.5905V6.36551H10.7552V17.9001ZM16.8974 17.9001H20.7326V6.36551H16.8974V17.9001Z"
                style={{ fill: color }}
                className="app-icon-fill"
            />
        </svg>
    );
};
