import React, { useEffect, useState } from 'react';
import Button from '../../components/Button';
import { Symbol } from '../../components/elements/Icon';
import Input from '../../components/Input';
import PopupMenu, { PopupButton, PositionTypes } from '../../components/Popup';
import { Text } from '../../components/Text';
import useBarelayout from '../barelayout/hook';

import { TableCellset } from './cell';
import useSetting from '../../hooks/useSetting';
import { RightPanelTabs } from '../../panels/right/main';
import { fieldsetsIdx, recordsetsIdx } from '../barelayout/ctx';
import useDatabase from '../../hooks/useDatabase';
import { rightPanelViewStyles } from '../../panels/right/editFaqPanel/FaqEditorPanel';
import { showError } from '../../components/towsts/Toasts';

export const TableRecord = ({
    record = null,
    idx = null,
    recordset = null,
    recordsetidx = null,
    timeline = false,
}) => {
    const brCtx = useBarelayout();
    const dbCtx = useDatabase();
    const settingCtx = useSetting();

    const [addingQuestion, setAddingQuestion] = useState(false);
    const [addRecordWasTriggered, setAddRecordWasTriggered] = useState({
        state: false,
        recordId: null,
    });

    const fieldsetIdx = brCtx.current_table.fieldsetIdx;
    const cellSet =
        (record &&
            record.cellsets &&
            record.cellsets[brCtx.fieldsets[fieldsetIdx].id]) ||
        null;

    useEffect(() => {
        if (brCtx.recordsets && addRecordWasTriggered.state) {
            brCtx.setBasic({
                active_cell: {
                    cellidx: null,
                    e: null,
                    fieldidx: null,
                    recordid: addRecordWasTriggered.recordId,
                    recordsetidx: 0,
                    is_calendar: false /** false */,
                },
            });
            settingCtx.setRightPanel({
                visible: true,
                active: RightPanelTabs.Recordview,
                force_view_style: rightPanelViewStyles.POPUP
            });
            setAddRecordWasTriggered({ state: false, recordId: null });
        }
    }, [brCtx.recordsets, addRecordWasTriggered]);

    const isActiveRecord = () => {
        if (
            record &&
            brCtx.active_cell &&
            brCtx.active_cell.recordsetidx === recordsetidx &&
            brCtx.active_cell.recordid === record.id
        ) {
            return true;
        }

        return false;
    };

    const isRecordSelected = () => {
        if (brCtx.selected_records) {
            if (brCtx.selected_records[recordsetidx]) {
                return brCtx.selected_records[recordsetidx].indexOf(record.id);
            }
        }
        return -1;
    };

    const remove = async (id) => {
        let res = await dbCtx.deleteRecord({
            recordsetuid: brCtx.recordsets[recordsetsIdx.InboxsRecordset].id,
            uid: id,
        });
    };

    const answerQuestion = async () => {
        let id = record.id;
        let questionFieldId;
        for (
            let i = 0;
            i < brCtx.fieldsets[fieldsetsIdx.InboxsFieldset].fields.length;
            i++
        ) {
            let field = brCtx.fieldsets[fieldsetsIdx.InboxsFieldset].fields[i];
            if (field.label.toLowerCase() === 'question') {
                questionFieldId = field.id;
                break;
            }
        }
        let question =
            record.cellsets[brCtx.fieldsets[fieldsetIdx].id].cells[
                questionFieldId
            ].value;

        let faqTileId;
        for (
            let n = 0;
            n < brCtx.fieldsets[fieldsetsIdx.FAQsFieldset].fields.length;
            n++
        ) {
            let field = brCtx.fieldsets[fieldsetsIdx.FAQsFieldset].fields[n];
            if (field.label === 'FAQ') {
                faqTileId = field.id;
                break;
            }
        }
        let recordValues = {};
        recordValues[faqTileId] = question;
        try {
            setAddingQuestion(true);
            let res = await dbCtx.addRecord({
                aliasuid: dbCtx.database.aliasuid,
                dbId: dbCtx.database.uid,
                recordsetuid: dbCtx.recordsets[recordsetsIdx.FAQsRecordset].uid,
                attributes: null,
                values: recordValues,
                noReload: false,
            });
            if (res.status === 201 && res.data.record) {
                remove(id);
                setAddRecordWasTriggered({
                    state: true,
                    recordId: res.data.record.uid,
                });
            } else if (res.status === 400) {
                if (res.data.messageCode === 'LIMIT_REACHED') {
                    settingCtx.setUpgradeProjectDialog({
                        visible: true,
                        project_id: dbCtx.database.uid,
                        msg: 'You are currently on a free project, upgrade it and get unlimited Questions!',
                    });
                    /*alert(
                        'Upgrade to the PRO plan to be able to add more than 8 FAQs!'
                    );*/
                }
            } else {
                showError('Error creating a new FAQ, please try again!');
            }
        } catch (err) {
            showError('Error creating a new FAQ, please try again!');
        }
        setAddingQuestion(false);
    };

    if (!timeline) {
        return (
            <>
                <tr
                    className={
                        '--grid-recordset-record' +
                        (isActiveRecord() ? ' --active' : '') +
                        (isRecordSelected() > -1 ? ' --selected' : '') +
                        (Object.keys(brCtx.selected_records).length > 0
                            ? ' --selection-active'
                            : '')
                    }>
                    {brCtx.fieldsets && (
                        <>
                            {brCtx.fieldsets[fieldsetsIdx.SelectorFieldset] && (
                                <TableCellset
                                    key={
                                        brCtx.fieldsets[
                                            fieldsetsIdx.SelectorFieldset
                                        ].id +
                                        '-' +
                                        fieldsetsIdx.SelectorFieldset
                                    }
                                    cellset={
                                        (record &&
                                            record.cellsets &&
                                            record.cellsets[
                                                brCtx.fieldsets[
                                                    fieldsetsIdx
                                                        .SelectorFieldset
                                                ].id
                                            ]) ||
                                        null
                                    }
                                    fieldset={
                                        brCtx.fieldsets[
                                            fieldsetsIdx.SelectorFieldset
                                        ]
                                    }
                                    recordset={recordset}
                                    recordidx={idx}
                                    idx={fieldsetsIdx.SelectorFieldset}
                                    record={record}
                                    recordsetidx={recordsetidx}
                                    timeline={timeline}
                                />
                            )}
                            {brCtx.fieldsets[fieldsetIdx] && (
                                <TableCellset
                                    key={
                                        brCtx.fieldsets[fieldsetIdx].id +
                                        '-' +
                                        fieldsetIdx
                                    }
                                    cellset={
                                        (record &&
                                            record.cellsets &&
                                            record.cellsets[
                                                brCtx.fieldsets[fieldsetIdx].id
                                            ]) ||
                                        null
                                    }
                                    fieldset={brCtx.fieldsets[fieldsetIdx]}
                                    recordset={recordset}
                                    recordidx={idx}
                                    idx={fieldsetIdx}
                                    record={record}
                                    recordsetidx={recordsetidx}
                                    timeline={timeline}
                                />
                            )}
                            {brCtx.fieldsets[fieldsetIdx] &&
                                (fieldsetIdx === fieldsetsIdx.FAQsFieldset ? (
                                    <td
                                        onClick={(evnt) => {
                                            try {
                                                evnt.target.parentElement.children[1].children[0].click();
                                            } catch (e) {}
                                        }}></td>
                                ) : fieldsetIdx ===
                                  fieldsetsIdx.InboxsFieldset ? (
                                    <td
                                        onClick={(evnt) => {
                                            try {
                                                evnt.target.parentElement.children[1].children[0].click();
                                            } catch (e) {}
                                        }}>
                                        <Button
                                            onClick={(evnt) => {
                                                evnt.stopPropagation();
                                                answerQuestion();
                                            }}
                                            id={
                                                'answer-question-button_' +
                                                (record.id.split('-')[1] || '')
                                            }
                                            loading={addingQuestion}
                                            type={'neutral'}
                                            style={{
                                                margin: '0 8px',
                                                height: '30px',
                                                padding: '0 12px',
                                            }}>
                                            <Text value={'Answer'} />
                                        </Button>
                                    </td>
                                ) : (
                                    <td></td>
                                ))}
                        </>
                    )}
                </tr>
                {brCtx.expanded_records &&
                    brCtx.expanded_records.includes(record.id) &&
                    brCtx.connected_records &&
                    brCtx.connected_records.map((alias, w) => {
                        var record_x =
                            (alias.records && alias.records[record.id]) || null;

                        if (!record_x) {
                            return <React.Fragment key={alias.uid + '-' + w} />;
                        }

                        return (
                            <div
                                className={
                                    '--grid-recordset-record --extra-aliases' +
                                    (w === 0 ? ' --first-rec' : '') +
                                    (w === brCtx.connected_records.length - 1
                                        ? ' --last-rec'
                                        : '')
                                }
                                key={alias.uid + '-' + w}>
                                {brCtx.fieldsets &&
                                    brCtx.fieldsets.map((fieldset, x) => {
                                        return (
                                            <TableCellset
                                                key={fieldset.id + '-' + x}
                                                cellset={
                                                    (record_x &&
                                                        record_x.cellsets &&
                                                        record_x.cellsets[
                                                            fieldset.id
                                                        ]) ||
                                                    null
                                                }
                                                fieldset={fieldset}
                                                recordset={recordset}
                                                recordidx={idx}
                                                idx={x}
                                                record={record_x}
                                                extraMode={true}
                                                recordsetidx={recordsetidx}
                                                timeline={timeline}
                                            />
                                        );
                                    })}
                            </div>
                        );
                    })}
            </>
        );
    }

    return (
        <>
            <div
                className={
                    '--grid-recordset-record' +
                    (isActiveRecord() ? ' --active' : '')
                }>
                {brCtx.timeline_series &&
                    brCtx.timeline_series.map((date) => {
                        var calendar_cellsets =
                            (record.calendar_cellsets &&
                                record.calendar_cellsets[date.datecode]) ||
                            null;

                        return (
                            <React.Fragment key={date.datecode}>
                                {brCtx.calendar_fieldsets &&
                                    brCtx.calendar_fieldsets.map(
                                        (fieldset, x) => {
                                            return (
                                                <TableCellset
                                                    key={fieldset.id}
                                                    cellset={
                                                        (calendar_cellsets &&
                                                            calendar_cellsets[
                                                                fieldset.id
                                                            ]) ||
                                                        null
                                                    }
                                                    fieldset={fieldset}
                                                    recordset={recordset}
                                                    recordidx={idx}
                                                    idx={x}
                                                    record={record}
                                                    recordsetidx={recordsetidx}
                                                    timeline={true}
                                                    timelineDate={date}
                                                />
                                            );
                                        }
                                    )}
                            </React.Fragment>
                        );
                    })}
            </div>
            {brCtx.expanded_records &&
                brCtx.expanded_records.includes(record.id) &&
                brCtx.connected_records &&
                brCtx.connected_records.map((alias, w) => {
                    var record_x =
                        (alias.records && alias.records[record.id]) || null;

                    if (!record_x) {
                        return <React.Fragment key={alias.uid} />;
                    }

                    return (
                        <div
                            className={
                                '--grid-recordset-record --extra-aliases' +
                                (w === 0 ? ' --first-rec' : '') +
                                (w === brCtx.connected_records.length - 1
                                    ? ' --last-rec'
                                    : '') +
                                (w === 0 ? ' --first-rec' : '') +
                                (w === brCtx.connected_records.length - 1
                                    ? ' --last-rec'
                                    : '')
                            }
                            key={alias.uid}>
                            {brCtx.timeline_series &&
                                brCtx.timeline_series.map((date) => {
                                    var calendar_cellsets =
                                        (record_x &&
                                            record_x.calendar_cellsets &&
                                            record_x.calendar_cellsets[
                                                date.datecode
                                            ]) ||
                                        null;

                                    return (
                                        <React.Fragment key={date.datecode}>
                                            {brCtx.calendar_fieldsets &&
                                                brCtx.calendar_fieldsets.map(
                                                    (fieldset, x) => {
                                                        return (
                                                            <TableCellset
                                                                key={
                                                                    fieldset.id
                                                                }
                                                                cellset={
                                                                    (calendar_cellsets &&
                                                                        calendar_cellsets[
                                                                            fieldset
                                                                                .id
                                                                        ]) ||
                                                                    null
                                                                }
                                                                fieldset={
                                                                    fieldset
                                                                }
                                                                recordset={
                                                                    recordset
                                                                }
                                                                recordidx={idx}
                                                                idx={x}
                                                                record={
                                                                    record_x
                                                                }
                                                                recordsetidx={
                                                                    recordsetidx
                                                                }
                                                                timeline={true}
                                                                timelineDate={
                                                                    date
                                                                }
                                                                extraMode={true}
                                                            />
                                                        );
                                                    }
                                                )}
                                        </React.Fragment>
                                    );
                                })}
                        </div>
                    );
                })}
        </>
    );
};

const colorsArray = [
    '#C7DAFD',
    '#E8C7FD',
    '#FDD1C7',
    '#E8FDC7',
    '#C7FDE3',
    '#C7E6FD',
    '#FDC7F1',
    '#FDF1C7',
    '#C7FDE3',
    '#C7D6FD',
    '#E8FDC7',
    '#CBFDC7',
    '#F2C7FD',
    '#FDC7E1',
    '#FFDED0',
    '#FFB1BF',
    '#FFFDD0',
];

export const TableRecordset = ({
    recordset = null,
    idx = null,
    timeline = false,
}) => {
    const brCtx = useBarelayout();
    const settingCtx = useSetting();

    const [deleteActive, setDeleteActive] = useState(false);
    const [addRecordWasTriggered, setAddRecordWasTriggered] = useState(false);

    const handleDelete = () => {
        if (deleteActive) {
            brCtx.deleteRecordset({
                uid: recordset.id,
            });
        } else {
            setDeleteActive(true);
        }
    };

    const handleKeyUp = (e) => {
        if (e.keyCode === 13) {
            /** enter key */
            brCtx.updateRecordset({
                uid: recordset.id,
                data: {
                    attributes: {
                        ...recordset.attributes,
                        label: (e.target.value || '').trim(),
                    },
                },
            });
        }
    };

    const handleUpdateColor = (color) => {
        let attributes = {
            ...(recordset.attributes || {}),
            cellstyle: {
                ...(recordset.attributes.cellstyle || {}),
                background: color,
            },
        };

        brCtx.updateRecordset({
            uid: recordset.id,
            data: {
                attributes: attributes,
            },
        });
    };

    const handleCollapseClick = () => {
        brCtx.toggleCollapseRecordsets(idx);
    };

    // const handleAddRecordset = (e) => {
    //     e.preventDefault();
    //     brCtx.addRecordsetFirst();
    // }

    if (brCtx.hidden_recordsets.includes(idx)) {
        return <></>;
    }

    return (
        <>
            {!brCtx.collapsed_recordsets.includes(idx) && (
                <>
                    {recordset &&
                        recordset.records &&
                        recordset.records.map((record, x) => {
                            return (
                                <TableRecord
                                    key={record.id + '-' + x}
                                    idx={x}
                                    record={record}
                                    recordset={recordset}
                                    recordsetidx={idx}
                                    timeline={timeline}
                                />
                            );
                        })}
                </>
            )}
            {((recordset &&
                recordset.records &&
                recordset.records.length === 0) ||
                !recordset ||
                !recordset.records) && <tr></tr>}
        </>
    );
};
