export const CalendarDays = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
];

export const getDates = (start, range) => {
    var arydates = [];

    for (let i = 0; i <= range; i++) {
        var currentDate = new Date();
        currentDate.setFullYear(start.getFullYear());
        currentDate.setMonth(start.getMonth());
        currentDate.setDate(start.getDate() + i);
        arydates.push(currentDate);
    }

    /** NOTE: december is shown as 0 */

    return arydates;
};

export const getPastDates = (end, range) => {
    var arydates = [];

    for (let i = range; i != 0; i--) {
        var currentDate = new Date();
        currentDate.setFullYear(end.getFullYear());
        currentDate.setMonth(end.getMonth());
        currentDate.setDate(end.getDate() - 1 * arydates.length);
        arydates.push(currentDate);
    }

    /** NOTE: december is shown as 0 */

    return arydates;
};

export const getMonthFormat = (n) => {
    if (n === 0) return 12;
    return n;
};

export const T = {
    Monday: 'Montag',
    Tuesday: 'Dienstag',
    Wednesday: 'Mittwoch',
    Thursday: 'Donnerstag',
    Friday: 'Freitag',
    Saturday: 'Samstag',
    Sunday: 'Sonntag',
};

export const getDayLabel = (str) => {
    let st = T[str];
    return st[0] + st[1];
};

export const getWithZero = (n) => {
    if (n < 10 && n > 0) {
        return `0${n}`;
    }

    return `${n}`;
};

/** getToday by api */
export const getToday = () => {
    // let d = new Date();
    // let d = new Date.UTC();

    /** @todo to UTC use timestamps */
    let d = new Date();

    // d.setMonth(d.getMonth());

    return d;
};

export const generateDadixDatecode = (date, month, year) => {
    return `${date}-${month}-${year}`;
};
