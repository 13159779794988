import './project-settings-panel.scss';
import { useEffect, useRef, useState } from 'react';
import useSetting from '../../hooks/useSetting';
import Button from '../../components/Button';
import { Symbol } from '../../components/elements/Icon';
import { Text } from '../../components/Text';
import useBarelayout from '../../layouts/barelayout/hook';
import { Field } from '../right/recordview';
import Input from '../../components/Input';
import useDatabase from '../../hooks/useDatabase';
import { getAPIBase } from '../../env';
import useAuth from '../../hooks/useAuth';
import PopupMenu from '../../components/Popup';
import { Tooltip } from '../../components/Popup/Tooltip';
import { RGBToHSL, copy_text, parseRBGClr } from '../../functions/Utilities';
import { ColorSelector } from '../../components/color-selector/ColorSelector';
import { recordsetsIdx } from '../../layouts/barelayout/ctx';
import { callApi } from '../../functions/api';
import { apiRoutes } from '../../helpers/api';
import { VerticalSortableList } from '../../components/vertical-sortable-list/VerticalSortableList';
import { CodeBlock } from '../../components/code-block/CodeBlock';
import { Switch } from '../right/accountOptionsPanel/accountOptionsPanel';
import plans from '../../constants/plans.json';
import { exportDatabase } from '../../components/export-database-handler/ExportDatabaseHandler';
import { importDatabase } from '../../components/import-database-handler/ImportDatabaseHandler';
import { showError } from '../../components/towsts/Toasts';
import { faqnationEvents } from '../../constants/custom-events';
import { popupsManager } from '../../components/PopupsManager/PopupsManager';
import useAlias from '../../hooks/useAlias';
import { useNavigate } from 'react-router-dom';
import { flushSync } from 'react-dom';

export const ProjectSettingsPanel = () => {
    const visible_ = useRef(false);
    const settingCtx = useSetting();
    const brCtx = useBarelayout();
    const dbCtx = useDatabase();
    const authCtx = useAuth();

    const [visible, setVisible] = useState(false);
    const categories = [
        {
            label: 'Project',
            icon: 'settings_icon',
            limited_to_payed: false,
        },
        {
            label: 'Style',
            icon: 'colors',
            limited_to_payed: false,
        },
        {
            label: 'Meta Data',
            icon: 'earth',
            limited_to_payed: false,
        },
        {
            label: 'Links',
            icon: 'link_icon',
            limited_to_payed: false,
        },
        {
            label: 'Notifications',
            icon: 'mail_icon',
            limited_to_payed: false,
        },
        /*{
            label: 'Github',
            icon: 'github_icon',
            limited_to_payed: false,
        },*/
        {
            separator: true,
        },
        {
            label: 'Embed',
            icon: 'embed_icon',
            limited_to_payed: true,
        },
        {
            label: 'API',
            icon: 'api_icon',
            limited_to_payed: true,
        },
        {
            separator: true,
        },
        {
            label: 'Import',
            icon: 'import',
            limited_to_payed: false,
            action: importToCurrentDatabase,
        },
        {
            label: 'Export',
            icon: 'export',
            limited_to_payed: false,
            action: () => {
                exportCurrentDatabase();
            },
        },
    ];
    const [selectedCategory, setSelectedCategory] = useState(
        categories[0].label
    );

    const [helpPageUrl, setHelpPageUrl] = useState('');

    useEffect(() => {
        window.addEventListener(
            faqnationEvents.open_project_settings_panel,
            handleOpenEvent
        );
        window.addEventListener(
            faqnationEvents.close_project_settings_panel,
            handleCloseEvent
        );
        return () => {
            window.removeEventListener(
                faqnationEvents.open_project_settings_panel,
                handleOpenEvent
            );
            window.removeEventListener(
                faqnationEvents.close_project_settings_panel,
                handleCloseEvent
            );
        };
    }, []);

    useEffect(() => {
        if (dbCtx.subdomain && dbCtx.subdomain.custom_domain) {
            if (dbCtx.subdomain.custom_domain.split('.').length > 1) {
                setHelpPageUrl('https://' + dbCtx.subdomain.custom_domain);
            } else {
                setHelpPageUrl(
                    'https://' +
                        dbCtx.subdomain.name +
                        '.' +
                        dbCtx.subdomain.custom_domain
                );
            }
        } else {
            setHelpPageUrl(
                'https://' +
                    (dbCtx.subdomain && dbCtx.subdomain.name) +
                    '.faqnation.com'
            );
        }
    }, [dbCtx.subdomain, authCtx.account]);

    useEffect(() => {
        visible_.current = visible;
        if (!visible) {
            setSelectedCategory(categories[0].label);
        } else {
            brCtx.setBasic({
                active_cell: {
                    cellid: 0,
                    fieldidx: 0,
                    is_calendar: false,
                    recordid:
                        brCtx.recordsets[recordsetsIdx.PageRecordset].records[0]
                            .uid,
                    recordsetidx: 1,
                },
            });
        }
    }, [visible]);

    function handleOpenEvent(evnt) {
        let details = evnt.detail || {};
        if (details.category) {
            setSelectedCategory(details.category);
        }
        setVisible(true);
    }

    function handleCloseEvent(evnt) {
        setVisible(false);
    }

    const hideRightSettingsPanel = () => {
        setVisible(false);
        /*settingCtx.setBasic({
            right_setting_panel: {
                visible: false,
            },
        });*/
    };

    function exportCurrentDatabase() {
        exportDatabase({
            dbName: dbCtx.database.label,
            dbId: dbCtx.database.uid,
            aliasId: dbCtx.database.aliasuid,
        });
    }
    function importToCurrentDatabase() {
        importDatabase({
            dbId: dbCtx.database.uid,
            aliasId: dbCtx.database.aliasuid,
        });
    }

    if (
        !brCtx.recordsets ||
        !brCtx.recordsets[recordsetsIdx.PageRecordset] ||
        brCtx.recordsets[recordsetsIdx.PageRecordset].records.length === 0
    ) {
        return <></>;
    }

    return (
        <PopupMenu
            className={'settings-panel'}
            open={visible}
            onClose={() => {
                hideRightSettingsPanel();
            }}>
            <div className="settings-panel-container">
                <div className="settings-nav-menu">
                    <div className="settings-nav-menu--items-container">
                        <Button
                            className={
                                'settings-nav-menu--item settings-nav-menu--item-link'
                            }
                            onClick={() => {
                                try {
                                    window.open(helpPageUrl);
                                } catch (err) {}
                            }}>
                            <span className="settings-nav-menu--item-icon">
                                <Symbol name="open_link" />
                            </span>
                            <span className="settings-nav-menu--item-label">
                                <Text value={'Open Page'} />
                            </span>
                        </Button>
                        <hr />
                        {categories.map((category, i) => {
                            if (category.separator) {
                                return <hr />;
                            }
                            return (
                                <Button
                                    key={i}
                                    onClick={() => {
                                        if (
                                            category.limited_to_payed &&
                                            !dbCtx.limits.is_pro
                                        ) {
                                            settingCtx.setUpgradeProjectDialog({
                                                visible: true,
                                                project_id: dbCtx.database.uid,
                                                msg: 'You are currently on a free project, upgrade it to use this feature!',
                                            });
                                            return;
                                        }
                                        if (!category.action) {
                                            setSelectedCategory(category.label);
                                        } else {
                                            category.action();
                                        }
                                    }}
                                    className={`settings-nav-menu--item ${
                                        category.label === selectedCategory
                                            ? 'selected'
                                            : ''
                                    }`}>
                                    <span className="settings-nav-menu--item-icon">
                                        <Symbol name={category.icon} />
                                    </span>
                                    <span className="settings-nav-menu--item-label">
                                        <Text value={category.label} />
                                    </span>
                                </Button>
                            );
                        })}
                    </div>
                </div>
                <div className="settings-main-content">
                    <div className="settings-main-content--header">
                        <h3 className="settings-main-content--header-title">
                            {selectedCategory}
                        </h3>
                        <Button
                            onClick={hideRightSettingsPanel}
                            className="settings-main-content--header-close"
                            type={'icon'}>
                            <Symbol name="close" />
                        </Button>
                    </div>
                    <div>
                        {selectedCategory === 'Project' ? (
                            <SettingsCategoryContent />
                        ) : selectedCategory === 'Style' ? (
                            <StyleCategoryContent />
                        ) : selectedCategory === 'Meta Data' ? (
                            <SEOCategoryContent />
                        ) : selectedCategory === 'Links' ? (
                            <EditLinksCategoryContent />
                        ) : selectedCategory === 'Notifications' ? (
                            (<NotificationsSettings /> /*: selectedCategory === 'Github' ? (
                            <GithubIntegrationSettings />
                        )*/ /*: selectedCategory === 'Github' ? (
                            <GithubIntegrationSettings />
                        )*/)
                        ) : /* : selectedCategory === 'Github' ? (
                            <GithubIntegrationSettings />
                        )*/ selectedCategory === 'Embed' ? (
                            <EmbedSettings />
                        ) : selectedCategory === 'API' ? (
                            <APISettings />
                        ) : (
                            <></>
                        )}
                    </div>
                </div>
            </div>
        </PopupMenu>
    );
};

const SettingsCategoryContent = () => {
    const brCtx = useBarelayout();
    const dbCtx = useDatabase();
    const aliasCtx = useAlias();

    const navigate = useNavigate();

    const [cellset, setCellset] = useState(null);
    const fieldset = brCtx.fieldsets[2];
    const [waitingForRemoveProject, setWaitingForRemoveProject] =
        useState(false);

    const [category, setCategory] = useState({
        initialized: false,
        options: {
            'Publish Link': {
                idx: -1,
                tooltip: {
                    txt: '',
                    img: '',
                },
            },
            Topics: {
                idx: -1,
                tooltip: {
                    txt: '',
                    img: '',
                },
            },
            Search: {
                idx: -1,
                tooltip: {
                    txt: '',
                    img: '',
                },
            },
            'Ask Question': {
                idx: -1,
                tooltip: {
                    txt: '',
                    img: '',
                },
            },
            'Sorting by': {
                idx: -1,
                tooltip: {
                    txt: '',
                    img: '',
                },
            },
        },
    });

    useEffect(() => {
        if (
            !brCtx.recordsets ||
            !brCtx.recordsets[recordsetsIdx.PageRecordset]
        ) {
            return;
        }
        setCategory((category) => {
            if (
                brCtx.recordsets[recordsetsIdx.PageRecordset].records.length ===
                0
            ) {
                return { ...category };
            }
            for (let i = 0; i < brCtx.fieldsets[2].fields.length; i++) {
                let fieldLabel = brCtx.fieldsets[2].fields[i].label;
                if (fieldLabel in category.options) {
                    category.options[fieldLabel].idx = i;
                }
            }
            return { ...category, initialized: true };
        });
    }, [brCtx.recordsets]);

    useEffect(() => {
        setCellset(
            (brCtx.recordsets &&
                brCtx.recordsets[recordsetsIdx.PageRecordset] &&
                brCtx.recordsets[recordsetsIdx.PageRecordset].records &&
                brCtx.recordsets[recordsetsIdx.PageRecordset].records[0] &&
                brCtx.recordsets[recordsetsIdx.PageRecordset].records[0]
                    .cellsets[fieldset.id]) ||
                null
        );
    }, [fieldset, brCtx.recordsets]);

    function removeProject() {
        popupsManager.confirm({
            msg: (
                <>
                    Are you sure you want to remove{' '}
                    <b>`{dbCtx.database.label}`</b> project?
                    <br />
                    (this action is irreversible)
                </>
            ),
            onConfirm: async () => {
                setWaitingForRemoveProject(true);
                try {
                    aliasCtx
                        .deleteDatabase({
                            uid: dbCtx.database.uid,
                        })
                        .then(() => {
                            let dbId = '';
                            for (
                                let i = 0;
                                i < (aliasCtx.databases || []).length;
                                i++
                            ) {
                                if (
                                    aliasCtx.databases[i] &&
                                    aliasCtx.databases[i].uid !==
                                        dbCtx.database.uid
                                ) {
                                    dbId = aliasCtx.databases[i].uid;
                                    break;
                                }
                            }
                            navigate('/' + aliasCtx.alias.alias + '/' + dbId);
                        })
                        .catch((err) => {
                            showError(
                                "Something went wrong, we couldn't remove `" +
                                    dbCtx.database.label +
                                    '`'
                            );
                        })
                        .finally(() => {
                            setWaitingForRemoveProject(false);
                            projectSettingsPanel.close();
                        });
                } catch (err) {}
            },
            danger: true,
        });
    }

    if (!category.initialized) {
        return <></>;
    }

    return (
        <>
            <IsPagePublicField />
            <hr />
            <Option
                brCtx={brCtx}
                cellset={cellset}
                fieldset={fieldset}
                label={'Topics'}
                option={category.options['Topics']}
            />
            <Option
                brCtx={brCtx}
                cellset={cellset}
                fieldset={fieldset}
                option={category.options['Search']}
                label={'Search'}
            />
            <Option
                brCtx={brCtx}
                cellset={cellset}
                fieldset={fieldset}
                option={category.options['Ask Question']}
                label={'Ask Questions'}
            />
            {category.options['Sorting by'].idx !== -1 && (
                <>
                    <hr />
                    <SelectSortingRule
                        brCtx={brCtx}
                        cellset={cellset}
                        fieldset={fieldset}
                        option={category.options['Sorting by']}
                        label={'Sorting by'}
                    />
                </>
            )}
            <hr />
            <div className="--field" style={{ alignItems: 'center' }}>
                <Lable text={'Dangerous area'} />
                <div>
                    <Button
                        loading={waitingForRemoveProject}
                        type="danger-filled"
                        onClick={() => {
                            removeProject();
                        }}>
                        <Text value={'Remove project'} />
                    </Button>
                </div>
            </div>
        </>
    );
};
const StyleCategoryContent = () => {
    const brCtx = useBarelayout();
    const dbCtx = useDatabase();
    const [cellset, setCellset] = useState(null);
    const fieldset = brCtx.fieldsets[2];

    const [category, setCategory] = useState({
        initialized: false,
        options: {
            Logo: {
                idx: -1,
                tooltip: {
                    txt: 'for the image size you can use 160x160px or 250x150px or 350x75px or 400x100px or any other similar size',
                    img: '',
                },
            },
            'Logo link to': {
                idx: -1,
                tooltip: {
                    txt: '',
                    img: '',
                },
            },
            Colors: {
                idx: -1,
                tooltip: {
                    txt: '',
                    img: '',
                },
            },
            'Text style': {
                idx: -1,
                tooltip: {
                    txt: '',
                    img: '',
                },
            },
            'Footer Text': {
                idx: -1,
                tooltip: {
                    txt: '',
                    img: '',
                },
            },
        },
    });

    useEffect(() => {
        if (
            !brCtx.recordsets ||
            !brCtx.recordsets[recordsetsIdx.PageRecordset]
        ) {
            return;
        }
        setCategory((category) => {
            if (
                brCtx.recordsets[recordsetsIdx.PageRecordset].records.length ===
                0
            ) {
                return { ...category };
            }
            for (let i = 0; i < brCtx.fieldsets[2].fields.length; i++) {
                let fieldLabel = brCtx.fieldsets[2].fields[i].label;
                if (fieldLabel in category.options) {
                    category.options[fieldLabel].idx = i;
                }
            }
            return { ...category, initialized: true };
        });
    }, [brCtx.recordsets]);

    useEffect(() => {
        setCellset(
            (brCtx.recordsets &&
                brCtx.recordsets[recordsetsIdx.PageRecordset] &&
                brCtx.recordsets[recordsetsIdx.PageRecordset].records &&
                brCtx.recordsets[recordsetsIdx.PageRecordset].records[0] &&
                brCtx.recordsets[recordsetsIdx.PageRecordset].records[0]
                    .cellsets[fieldset.id]) ||
                null
        );
    }, [fieldset, brCtx.recordsets]);

    if (!category.initialized) {
        return <></>;
    }

    return (
        <>
            <PageColors
                brCtx={brCtx}
                cellset={cellset}
                fieldset={fieldset}
                field={fieldset.fields[category.options['Colors'].idx]}
            />
            <div className="edit-page-logo-container">
                <style
                    dangerouslySetInnerHTML={{
                        __html: `
                .edit-page-logo-container .--image-input{
                    background-color:${
                        (cellset &&
                            JSON.parse(
                                (cellset.cells || {})[
                                    fieldset.fields[
                                        category.options['Colors'].idx
                                    ].id
                                ].value || '{}'
                            )['primary-clr']) ||
                        '#3F4C63'
                    }!important;
                }
                .edit-page-logo-container .--image-input *{
                    color:${
                        (cellset &&
                            JSON.parse(
                                (cellset.cells || {})[
                                    fieldset.fields[
                                        category.options['Colors'].idx
                                    ].id
                                ].value || '{}'
                            )['secondary-clr']) ||
                        '#ffffff'
                    }!important;
                    fill:${
                        (cellset &&
                            JSON.parse(
                                (cellset.cells || {})[
                                    fieldset.fields[
                                        category.options['Colors'].idx
                                    ].id
                                ].value || '{}'
                            )['secondary-clr']) ||
                        '#ffffff'
                    }!important;
                }
                `,
                    }}></style>
                <OptionImage
                    brCtx={brCtx}
                    option={category.options['Logo']}
                    cellset={cellset}
                    fieldset={fieldset}
                    label={'Your Logo'}
                />
            </div>
            <Option
                brCtx={brCtx}
                option={category.options['Logo link to']}
                cellset={cellset}
                fieldset={fieldset}
                label={'Logo link'}
            />

            {category.options['Text style'].idx >= 0 && (
                <>
                    {' '}
                    <hr />
                    <EditPageTextStyle
                        brCtx={brCtx}
                        cellset={cellset}
                        fieldset={fieldset}
                        field={
                            fieldset.fields[category.options['Text style'].idx]
                        }
                    />
                </>
            )}
            {dbCtx.limits['is_pro'] && (
                <>
                    <hr />
                    <EditPageFooter
                        brCtx={brCtx}
                        option={category.options['Footer Text']}
                        cellset={cellset}
                        fieldset={fieldset}
                        field={
                            fieldset.fields[category.options['Footer Text'].idx]
                        }
                    />
                </>
            )}
        </>
    );
};
const SEOCategoryContent = () => {
    const brCtx = useBarelayout();
    const [cellset, setCellset] = useState(null);
    const fieldset = brCtx.fieldsets[2];

    const [category, setCategory] = useState({
        initialized: false,
        options: {
            'Page title': {
                idx: -1,
                tooltip: {
                    txt: '',
                    img: '',
                },
            },
            'Public URL': {
                idx: -1,
                tooltip: {
                    txt: '',
                    img: '',
                },
            },
            'Visible to search engines': {
                idx: -1,
                tooltip: {
                    txt: '',
                    img: '',
                },
            },
            'Meta Description': {
                idx: -1,
                tooltip: {
                    txt: '',
                    img: '',
                },
            },
            'Meta Keywords': {
                idx: -1,
                tooltip: {
                    txt: '',
                    img: '',
                },
            },
            'OG Image': {
                idx: -1,
                tooltip: {
                    txt: '',
                    img: '',
                },
            },
            Favicon: {
                idx: -1,
                tooltip: {
                    txt: 'the file type must be .png, the size will be better to be 512x512px or 180x180px or 96x96px or 32x32px',
                    img: '',
                },
            },
        },
    });

    useEffect(() => {
        if (
            !brCtx.recordsets ||
            !brCtx.recordsets[recordsetsIdx.PageRecordset]
        ) {
            return;
        }
        setCategory((category) => {
            if (
                brCtx.recordsets[recordsetsIdx.PageRecordset].records.length ===
                0
            ) {
                return { ...category };
            }
            for (let i = 0; i < brCtx.fieldsets[2].fields.length; i++) {
                let fieldLabel = brCtx.fieldsets[2].fields[i].label;
                if (fieldLabel in category.options) {
                    category.options[fieldLabel].idx = i;
                }
            }
            return { ...category, initialized: true };
        });
    }, [brCtx.recordsets]);

    useEffect(() => {
        setCellset(
            (brCtx.recordsets &&
                brCtx.recordsets[recordsetsIdx.PageRecordset] &&
                brCtx.recordsets[recordsetsIdx.PageRecordset].records &&
                brCtx.recordsets[recordsetsIdx.PageRecordset].records[0] &&
                brCtx.recordsets[recordsetsIdx.PageRecordset].records[0]
                    .cellsets[fieldset.id]) ||
                null
        );
    }, [fieldset, brCtx.recordsets]);

    if (!category.initialized) {
        return <></>;
    }

    return (
        <>
            <PageTitleField
                fieldset={fieldset}
                field={fieldset.fields[category.options['Page title'].idx]}
            />
            <hr />
            <PageLinkField />
            <hr />
            <Option
                brCtx={brCtx}
                cellset={cellset}
                fieldset={fieldset}
                option={category.options['Visible to search engines']}
                label={'Visible on search'}
            />
            <Option
                brCtx={brCtx}
                cellset={cellset}
                fieldset={fieldset}
                option={category.options['Meta Description']}
                label={'Meta Description'}
            />
            <ProjectMetaKeysEditor
                brCtx={brCtx}
                cellset={cellset}
                field={fieldset.fields[category.options['Meta Keywords'].idx]}
                fieldset={fieldset}
                option={category.options['Meta Keywords']}
                label={'Meta Keywords'}
            />
            <hr />
            {category.options['OG Image'].idx !== -1 && (
                <OptionImage
                    brCtx={brCtx}
                    option={category.options['OG Image']}
                    cellset={cellset}
                    fieldset={fieldset}
                    accept={'.png'}
                    validate={(file) => {
                        return file.type === 'image/png';
                    }}
                />
            )}
            <OptionImage
                brCtx={brCtx}
                option={category.options['Favicon']}
                cellset={cellset}
                fieldset={fieldset}
                accept={'.png'}
                validate={(file) => {
                    return file.type === 'image/png';
                }}
            />
        </>
    );
};

const EditLinksCategoryContent = () => {
    const brCtx = useBarelayout();
    const fieldset = brCtx.fieldsets[2];

    const [category, setCategory] = useState({
        options: {
            'Navigation Links': {
                idx: -1,
                tooltip: {
                    txt: '',
                    img: '',
                },
            },
            Logo: {
                idx: -1,
            },
            Colors: {
                idx: -1,
            },
        },
    });

    useEffect(() => {
        if (
            !brCtx.recordsets ||
            !brCtx.recordsets[recordsetsIdx.PageRecordset]
        ) {
            return;
        }
        setCategory((category) => {
            if (
                brCtx.recordsets[recordsetsIdx.PageRecordset].records.length ===
                0
            ) {
                return { ...category };
            }
            for (let i = 0; i < brCtx.fieldsets[2].fields.length; i++) {
                let fieldLabel = brCtx.fieldsets[2].fields[i].label;
                if (fieldLabel in category.options) {
                    category.options[fieldLabel].idx = i;
                }
            }
            return { ...category, initialized: true };
        });
    }, [brCtx.recordsets]);

    if (!category.initialized) {
        return <></>;
    }

    return (
        <NavigationLinksEditor
            field={fieldset.fields[category.options['Navigation Links'].idx]}
            fieldset={fieldset}
            logoUrl={
                brCtx.recordsets[recordsetsIdx.PageRecordset].records[0]
                    .cellsets[brCtx.fieldsets[2].id].cells[
                    fieldset.fields[category.options['Logo'].idx].id
                ].value
            }
            colors={
                brCtx.recordsets[recordsetsIdx.PageRecordset].records[0]
                    .cellsets[brCtx.fieldsets[2].id].cells[
                    fieldset.fields[category.options['Colors'].idx].id
                ].value
            }
        />
    );
};

const EmbedSettings = () => {
    const dbCtx = useDatabase();

    return (
        <>
            {
                <>
                    <div
                        style={{
                            margin: '20px auto',
                            width: 'calc(100% - 40px)',
                        }}>
                        <div>
                            <Lable
                                text={<b>{'Widget'}</b>}
                                help={{
                                    txt: 'faqnation widget',
                                    img: '/static/images/tooltips/faqnation_widget.png',
                                }}
                            />
                        </div>
                        <br />
                        <Text value={'Add this code inside the '} />
                        <b>
                            <Text value={'<body>'} />
                        </b>
                        <Text
                            value={
                                ' tag of your page (where you want the Faqnation widget to appear)'
                            }
                        />
                    </div>
                    <div className="--code-wrapper-container">
                        <CodeBlock
                            language="xml"
                            lineWrap={true}
                            code={`
<script src="https://faqnation.com/embed/faq.js" 
    data-project="${dbCtx.database.uid}" 
    data-user="${dbCtx.database.aliasuid}" 
    data-id="${dbCtx.settings && dbCtx.settings.secretKey}"
    ></script>
                        `}
                        />
                    </div>
                    <div
                        style={{
                            margin: '20px auto',
                            width: 'calc(100% - 40px)',
                        }}>
                        <div>
                            <Lable
                                text={<b>{'Floating Widget'}</b>}
                                help={{
                                    txt: 'faqnation floating widget',
                                    img: '/static/images/tooltips/faqnation_floating_widget.png',
                                }}
                            />
                        </div>
                        <br />
                        <Text value={'Add this code inside the '} />
                        <b>
                            <Text value={'<body>'} />
                        </b>
                        <Text value={' tag of your page'} />
                    </div>
                    <div className="--code-wrapper-container">
                        <CodeBlock
                            language="xml"
                            lineWrap={true}
                            code={`
<script src="https://faqnation.com/embed/floating-button.js" 
    data-project="${dbCtx.database.uid}" 
    data-user="${dbCtx.database.aliasuid}" 
    data-id="${dbCtx.settings && dbCtx.settings.secretKey}"
    ></script>
                        `}
                        />
                    </div>
                </>
            }
        </>
    );
};

const APISettings = () => {
    const dbCtx = useDatabase();
    const [loading, setLoading] = useState(false);
    const [keysList, setKeysList] = useState([]);

    const apiCreditOptions = useRef([
        { id: plans.addons.APICalls500.name, label: '500' },
        { id: plans.addons.APICalls2000.name, label: '2000' },
        { id: plans.addons.APICalls10000.name, label: '10000' },
        { id: plans.addons.APICalls50000.name, label: '50000' },
        { id: plans.addons.APICalls100000.name, label: '100000' },
        { id: plans.addons.APICalls500000.name, label: '500000' },
    ]);

    const apiCreditsAvailable = (dbCtx.limits['limits'] || {})['apiCalls'] || 0;

    useEffect(() => {
        getAPIKeys();
    }, []);

    async function getAPIKeys() {
        setLoading(true);
        try {
            let res = await callApi({
                method: 'get',
                path: apiRoutes.api.getAPIKeys
                    .replace('{aliasuid}', dbCtx.database.aliasuid)
                    .replace('{databaseuid}', dbCtx.database.uid),
            });
            if (res.status !== 200) {
                throw new Error('Error loading API keys list');
            }
            let keysList = res.data.keylist;
            setKeysList(keysList);
        } catch (err) {
            showError('Error loading API keys list');
        }
        setLoading(false);
    }

    async function buyCredits(id) {
        setLoading(true);
        try {
            let res = await callApi({
                path: apiRoutes.plans.getBuyAddonLink,
                method: 'post',
                payload: {
                    planId: id,
                    projectId: dbCtx.database.uid,
                },
            });
            if (res.status !== 200) {
                throw new Error('Unknown Error');
            }
            document.location.href = res.data.checkoutUrl;
        } catch (err) {
            showError('Something went wrong, please try again!');
        }
        setLoading(false);
    }

    async function generateNewKey() {
        setLoading(true);
        try {
            let res = await callApi({
                method: 'post',
                path: apiRoutes.api.addNewAPIKey
                    .replace('{aliasuid}', dbCtx.database.aliasuid)
                    .replace('{databaseuid}', dbCtx.database.uid),
                payload: {
                    label: undefined,
                },
            });
            //let newKey = res.data;
            //setKeysList((keysList) => [...keysList, newKey]);
            if (res.status !== 201) {
                throw new Error('Error adding new API key');
            }
            getAPIKeys();
        } catch (err) {
            showError('Error adding new API key');
            setLoading(false);
        }
    }

    async function removeKey(keyValue) {
        try {
            let res = await await callApi({
                method: 'delete',
                path:
                    apiRoutes.api.addNewAPIKey
                        .replace('{aliasuid}', dbCtx.database.aliasuid)
                        .replace('{databaseuid}', dbCtx.database.uid) +
                    '?key=' +
                    keyValue,
            });
            if (res.status !== 200) {
                throw new Error('Error removing PI key');
            }
            getAPIKeys();
        } catch (err) {
            showError('Error removing PI key');
            setLoading(false);
        }
    }

    return (
        <>
            <div className="--field">
                <div className="--field-label">
                    <Text value={'Available API credits'} />
                </div>
                <div>
                    <div className="availableApiCredits">
                        <Text value={apiCreditsAvailable} />
                        <PopupMenu
                            trigger={
                                <Button
                                    type="primary"
                                    className="buyCredits"
                                    loading={loading}
                                    onClick={buyCredits}>
                                    <Text value={'Buy credits'} />
                                </Button>
                            }
                            nested={true}>
                            {apiCreditOptions.current.map((option) => {
                                return (
                                    <div
                                        className="api-credit-buy-option"
                                        key={'api-option-' + option.id}>
                                        <Text
                                            className={
                                                'api-credit-buy-option-label'
                                            }
                                            value={option.label}
                                        />
                                        <Button
                                            onClick={() => {
                                                buyCredits(option.id);
                                            }}
                                            loading={loading}
                                            style={{ minWidth: '70px' }}
                                            type={'primary'}>
                                            <Text value={'Buy'} />
                                        </Button>
                                    </div>
                                );
                            })}
                        </PopupMenu>
                    </div>
                </div>
            </div>
            <div className="apiKeysList">
                {(keysList || []).map((key, i) => {
                    return (
                        <div className="apiKeyItem" key={i + '_' + key.id}>
                            <Button
                                onClick={() => {
                                    copy_text(key.key);
                                }}>
                                <Symbol name="content_copy" />
                            </Button>
                            <span className="keyValue">
                                <Text value={key.key} />
                            </span>
                            <Button
                                onClick={() => {
                                    removeKey(key.key);
                                }}>
                                <Symbol name="delete" color={'#FF6767'} />
                            </Button>
                        </div>
                    );
                })}
            </div>
            <div className="--field">
                <div className="--field-label">
                    <b>
                        <Text value={' '} />
                    </b>
                </div>
                <div className="addAPIKeyContainer">
                    <div style={{ width: '100%' }}></div>

                    <Button
                        type="primary"
                        className="addAPIKeyBtn"
                        loading={loading}
                        onClick={generateNewKey}>
                        <Text value={'Add API key'} />
                    </Button>
                </div>
            </div>
        </>
    );
};

const NotificationsSettings = () => {
    const popupMenuRef = useRef(null);
    const emailInputRef = useRef(null);

    const dbCtx = useDatabase();

    const [projectNotificationActive, setProjectNotificationActive] =
        useState(false);
    //const [replayAndPost, setReplayAndPost] = useState(false);
    const [notificationsFrequency, setNotificationsFrequency] =
        useState('instant');

    const [emailInputValue, setEmailInputValue] = useState('');
    const [emailsList, setEmailsList] = useState([]);
    const [waitingForEmailsUpdate, setWaitingForEmailsUpdate] = useState(false);

    const [notificationsFrequencyOptions, setNotificationsFrequencyOptions] =
        useState([
            { label: 'Immediately', value: 'instant' },
            { label: 'once every 1 hour', value: 'per_hour' },
            { label: 'once every 8 hours', value: 'per_8hour' },
            { label: 'Daily', value: 'per_1day' },
            { label: 'once every 2 days', value: 'per_2day' },
            { label: 'once every 3 days', value: 'per_3day' },
        ]);

    useEffect(() => {
        setEmailsList(
            dbCtx.settings['notification_emails']
                ? dbCtx.settings['notification_emails'].split(',')
                : []
        );
        setProjectNotificationActive(
            dbCtx.settings['notification_enabled'] || false
        );
        let notificationFrequency =
            dbCtx.settings['notification_frequency'] || 'instant';
        for (let i = 0; i < notificationsFrequencyOptions.length; i++) {
            if (
                notificationsFrequencyOptions[i].value === notificationFrequency
            ) {
                setNotificationsFrequency(
                    notificationsFrequencyOptions[i].label
                );
                break;
            }
        }
    }, [dbCtx]);

    async function removeEmail(index) {
        let newEmailsList = '';
        let m = await setEmailsList((emailsList) => {
            emailsList.splice(index, 1);
            newEmailsList = [...emailsList].join(',');
            return [...emailsList];
        });
        updateNotificationEmailsListOnServer(newEmailsList);
    }

    async function addEmail() {
        if (
            waitingForEmailsUpdate ||
            !emailInputRef.current ||
            !emailInputRef.current.value.trim()
        ) {
            return;
        }
        let newEmail = emailInputRef.current.value;
        let emailExist = false;
        let newEmailsList = '';
        let m = await setEmailsList((emailsList) => {
            if (emailsList.indexOf(newEmail) >= 0) {
                emailExist = true;
                return emailsList;
            }
            newEmailsList = [...emailsList, newEmail].join(',');
            return [...emailsList, newEmail];
        });
        if (emailExist) {
            return;
        }
        updateNotificationEmailsListOnServer(newEmailsList);
        setEmailInputValue('');
    }

    async function updateNotificationEmailsListOnServer(newEmailsList) {
        setWaitingForEmailsUpdate(true);
        let res = await updateOptionInServer({
            optionName: 'notification_emails',
            newValue: newEmailsList,
        });
        setWaitingForEmailsUpdate(false);
    }

    async function updateOptionInServer({ optionName, newValue }) {
        try {
            dbCtx.setBasic({
                settings: {
                    ...(dbCtx.settings || {}),
                    [optionName]: newValue,
                },
            });
            const res = await dbCtx.updateDatabase({
                [optionName]: newValue,
                noReload: true,
            });
        } catch (err) {}
    }

    return (
        <>
            <div className="--field">
                <div className="--field-label">
                    <Lable
                        text={'Notification'}
                        help={{ txt: 'Recive Notification', img: undefined }}
                    />
                </div>
                <div style={{ padding: '8px 0' }}>
                    <div style={{ width: '100%' }}></div>

                    <Switch
                        id={'project_notification_switch'}
                        value={projectNotificationActive}
                        onUpdate={(v) => {
                            console.log(v);
                            setProjectNotificationActive(v);
                            updateOptionInServer({
                                optionName: 'notification_enabled',
                                newValue: v,
                            });
                        }}
                    />
                </div>
            </div>
            <div className="--field">
                <div className="--field-label">
                    <Text value={'Frequency'} />
                </div>
                <div style={{ padding: '8px 0' }}>
                    <div style={{ width: '100%' }}></div>

                    <PopupMenu
                        ref={popupMenuRef}
                        nested={true}
                        trigger={
                            <Button
                                style={{
                                    width: '100%',
                                    minWidth: '200px',
                                    maxWidth: '300px',
                                }}
                                className={'--select-box-custom'}>
                                <Text value={notificationsFrequency} />
                                <Symbol
                                    className={'ml-8 --select-box-arrow'}
                                    name={'expand_icon'}
                                />
                            </Button>
                        }>
                        {notificationsFrequencyOptions.map((option) => {
                            return (
                                <Button
                                    onClick={() => {
                                        setNotificationsFrequency(option.label);
                                        updateOptionInServer({
                                            optionName:
                                                'notification_frequency',
                                            newValue: option.value,
                                        });
                                        popupMenuRef.current.close();
                                    }}
                                    key={option.value}
                                    type={'popup'}>
                                    {option.label}
                                </Button>
                            );
                        })}
                    </PopupMenu>
                </div>
            </div>
            <hr />
            {/* <div className="--field">
                <div className="--field-label">
                    <Lable
                        text={'Replay and post'}
                        help={{
                            txt: 'Make possible to publish the FAQ post by simply reply the email',
                            img: undefined,
                        }}
                    />
                </div>
                <div style={{ padding: '8px 0' }}>
                    <div style={{ width: '100%' }}></div>

                    <Switch
                        id={'replayAndPost_switch'}
                        value={replayAndPost}
                        onUpdate={(v) => {
                            setReplayAndPost(v);
                        }}
                    />
                </div>
            </div>
            <hr /> */}
            <div>
                <div className="emailsList">
                    {(emailsList || []).map((email, i) => {
                        return (
                            <div className="emailItem" key={i + '_' + email}>
                                <span className="emailValue">
                                    <Text value={email} />
                                </span>
                                <Button
                                    loading={waitingForEmailsUpdate}
                                    onClick={() => {
                                        removeEmail(i);
                                    }}>
                                    <Symbol name="delete" color={'#FF6767'} />
                                </Button>
                            </div>
                        );
                    })}
                </div>
                <form
                    onSubmit={(evnt) => {
                        evnt.preventDefault();
                        addEmail();
                    }}
                    className="add-email-form">
                    <Input
                        ref={emailInputRef}
                        value={emailInputValue}
                        onChange={(evnt) => {
                            setEmailInputValue(evnt.target.value);
                        }}
                        type={'email'}
                        autoComplete={'email'}
                        autocapitalize={'none'}
                        spellcheck={'false'}
                        placeholder={'Email'}
                        required
                    />
                    <Button
                        loading={waitingForEmailsUpdate}
                        className={'submit-email'}
                        type={'primary'}>
                        <Text value={'Add'} />
                    </Button>
                </form>
            </div>
        </>
    );
};

/*
const GithubIntegrationSettings = () => {
    const [ghRepos, setGHRepos] = useState({
        status: 0,
    });
    const [connectionInProgress, setConnectionInProgress] = useState(false);

    const projectuid = 'tp_KnCc8h7C';
    const accountuid = 'user_he9TbtV9JfCJTc';

    const fetchGhRepos = async () => {
        let response = await callApi({
            method: 'get',
            path: apiRoutes.gh.getRepos,
        });
        if (response.status === 200) {
            let repos = await response.data.repos;
            setGHRepos({
                status: 1,
                data: repos,
            });
        } else {
            if (response.status === 400) {
                if (response.data.code === 'GH_NOT_CONNECTED') {
                    setGHRepos({
                        status: -1,
                    });
                } else {
                    setGHRepos({
                        status: 2,
                    });
                }
            }
        }
    };

    useEffect(() => {
        fetchGhRepos();
    }, []);

    const handleStartConnectGithub = async (e) => {
        try {
            setConnectionInProgress(true);
            let connection_auth_link =
                getAPIBase() +
                '/' +
                apiRoutes.gh.auth
                    .replace('{projectuid}', projectuid)
                    .replace('{accountuid}', accountuid);
            let connection_window = window.open(
                connection_auth_link,
                'Connect with github',
                'width=460,height=600'
            );

            let check_window_exists = setInterval(() => {
                if (connection_window) {
                    if (connection_window.closed) {
                        setConnectionInProgress(false);
                        fetchGhRepos();
                        clearInterval(check_window_exists);
                    }
                } else {
                    setConnectionInProgress(false);
                    fetchGhRepos();
                    clearInterval(check_window_exists);
                }
            }, 1000);
        } catch (err) {
            fetchGhRepos();
            console.warn(err);
            showError('Error while connecting with github :: ', err);
            setConnectionInProgress(false);
        }
    };

    const handleConnectRepo = (repo, i) => (e) => {
        try {
            const { id, name, url } = repo;
            let link =
                getAPIBase() +
                '/' +
                apiRoutes.gh.connectRepo
                    .replace('{projectuid}', projectuid)
                    .replace('{accountuid}', accountuid)
                    .replace('{repoid}', id)
                    .replace('{repourl}', url);
            let connection_window = window.open(
                link,
                `Connect repo ${name}`,
                'width=460,height=600'
            );

            let check_window_exists = setInterval(() => {
                if (connection_window) {
                    if (connection_window.closed) {
                        clearInterval(check_window_exists);
                    }
                } else {
                    clearInterval(check_window_exists);
                }
            }, 1000);
        } catch (error) {}
    };

    return (
        <>
            <div style={{ padding: '0 8px 20px' }}>
                <Text
                    value={
                        'Create a repository for this project in github to save version history and connect it'
                    }
                />
            </div>
            <div style={{ textAlign: 'center' }}>
                {ghRepos.status === 0 ? (
                    'loading...'
                ) : ghRepos.status === -1 ? (
                    <>
                        <Button
                            style={{
                                background: '#444444',
                                minWidth: '200px',
                                position: 'relative',
                                padding: '8px 35px',
                            }}
                            disabled={connectionInProgress}
                            onClick={handleStartConnectGithub}>
                            <span
                                style={{
                                    position: 'absolute',
                                    left: '8px',
                                    top: '0',
                                    bottom: '0',
                                    display: 'flex',
                                    alignContent: 'center',
                                    justifyContent: 'center',
                                }}>
                                <Symbol
                                    name="github_icon"
                                    color2={'transparent'}
                                    color={'#fff'}
                                />
                            </span>
                            <Text
                                style={{ color: '#fff' }}
                                value={
                                    connectionInProgress
                                        ? 'Connecting...'
                                        : 'Connect with GitHub'
                                }
                            />
                        </Button>
                    </>
                ) : ghRepos.status === 1 ? (
                    <>
                        <table>
                            {ghRepos.data &&
                                ghRepos.data.map((repo, i) => {
                                    return (
                                        <tr key={repo.id}>
                                            <span>{repo.name}</span>
                                            <button
                                                onClick={handleConnectRepo(
                                                    repo,
                                                    i
                                                )}>
                                                {'Connect'}
                                            </button>
                                        </tr>
                                    );
                                })}
                        </table>
                    </>
                ) : ghRepos.status === 2 ? (
                    <>
                        <h1>Unknown error.</h1>
                    </>
                ) : (
                    ''
                )}
            </div>
        </>
    );
};
*/

function NavigationLinksEditor({
    fieldset,
    field,
    logoUrl = '',
    colors = '{}',
}) {
    const brCtx = useBarelayout();
    const [links, setLinks] = useState([]);

    //const [updateLinkPopupIsOpen, setUpdateLinkPopupIsOpen] = useState(false);
    //const [linkIndex, setLinkIndex] = useState(-1);

    const [cellset, setCellset] = useState(null);
    const [cell, setCell] = useState(null);
    const [newLinkInput, setNewLinkInput] = useState({ lable: '', target: '' });
    const [loading, setLoading] = useState(false);

    const recordset =
        brCtx.recordsets && brCtx.recordsets[recordsetsIdx.PageRecordset];

    const record =
        recordset &&
        brCtx.recordsets[recordsetsIdx.PageRecordset].records &&
        brCtx.recordsets[recordsetsIdx.PageRecordset].records[0];

    const pageColors = JSON.parse(colors || '{}');

    useEffect(() => {
        setCellset(
            (brCtx.recordsets &&
                brCtx.recordsets[recordsetsIdx.PageRecordset] &&
                brCtx.recordsets[recordsetsIdx.PageRecordset].records &&
                brCtx.recordsets[recordsetsIdx.PageRecordset].records[0] &&
                brCtx.recordsets[recordsetsIdx.PageRecordset].records[0]
                    .cellsets[fieldset.id]) ||
                null
        );
    }, [fieldset, brCtx.recordsets]);

    useEffect(() => {
        let cell = cellset && cellset.cells[field.id];
        if (!cell) {
            return;
        }
        setCell(cell);
        setLinks(JSON.parse(cell.value || '[]'));
    }, [field, cellset]);

    const addLink = (lableInput, targetInput) => {
        let linkText = lableInput.value;
        let linkURL = targetInput.value;
        if (!linkText.trim() || !linkURL.trim()) {
            showError('Uncorrect values!');
            return;
        }
        let newLink = { label: linkText, target: linkURL };
        let newValue = JSON.parse(cell.value || '[]');
        newValue.push(newLink);

        setNewLinkInput({ lable: '', target: '' });
        saveUpdates(
            newValue,
            'update',
            () => {},
            () => {
                setNewLinkInput({ lable: linkText, target: linkURL });
            }
        );
    };
    const updateLink = (lableInput, targetInput, index) => {
        let linkText = lableInput.value.trim();
        let linkURL = targetInput.value.trim();
        if (!linkText || !linkURL) {
            showError('Uncorrect values!');
            return;
        }

        let linksList = JSON.parse(cell.value || '[]');

        if (
            linksList[index].label !== linkText ||
            linksList[index].target !== linkURL
        ) {
            linksList[index].label = linkText;
            linksList[index].target = linkURL;
            saveUpdates(linksList, 'update');
        }
    };
    const deleteLink = (index) => {
        let newValue = JSON.parse(cell.value || '[]');
        newValue.splice(index, 1);
        saveUpdates(newValue, 'delete');
    };
    const updateLinksOrder = (from, to) => {
        let linksList = JSON.parse(cell.value || '[]');
        let direction = Math.sign(to - from);
        let newLinksList = [
            ...linksList.slice(0, Math.min(from, to)),
            ...(direction > 0
                ? [...linksList.slice(from + 1, to + 1), linksList[from]]
                : [linksList[from], ...linksList.slice(to, from)]),
            ...linksList.slice(Math.max(from, to) + 1, linksList.length),
        ];
        setLinks([...newLinksList]);
        saveUpdates([...newLinksList], 'update');
    };
    const saveUpdates = (
        newValue,
        method,
        onSuccess = () => {},
        onError = () => {}
    ) => {
        startLoading(method);
        brCtx
            .handleCellUpdate({
                field: field,
                cell: cell,
                record: record,
                fieldset: fieldset,
                recordset: recordset,
                is_calendar: false,
                new_value: JSON.stringify(newValue),
            })
            .then((res) => {
                endLoading(method);
                onSuccess();
            })
            .catch((err) => {
                showError('Error saving link!');
                onError();
            })
            .finally(() => {
                endLoading(method);
            });
    };
    const startLoading = (method) => {
        setLoading(true);
        // if (method === 'update') {
        //     setSaving(true);
        // } else if (method === 'delete') {
        //     setDeleting(true);
        // }
    };
    const endLoading = (method) => {
        setLoading(false);
        // if (method === 'update') {
        //     setSaving(false);
        // } else if (method === 'delete') {
        //     setDeleting(false);
        // }
    };

    return (
        <>
            <div
                className="help-page-navigation-preview"
                style={{
                    background: `${pageColors['primary-clr'] || '#3F4C63'}`,
                }}>
                <img
                    className="help-page-logo-preview"
                    src={getAPIBase() + '/media/' + logoUrl}
                    alt=""
                />
                <nav
                    style={{
                        color: `${pageColors['on-primary-clr'] || '#ffffff'}`,
                    }}>
                    <ul>
                        {links.map((link, i) => {
                            return <li key={'link--00' + i}>{link.label}</li>;
                        })}
                    </ul>
                </nav>
            </div>
            <div className="links-editor-container">
                <div className="links-list-header">
                    <div>
                        <Text value="Lable" />
                    </div>
                    <div>
                        <Text value="Target" />
                    </div>
                </div>
                <VerticalSortableList
                    onDragEnd={({ from, to }) => {
                        updateLinksOrder(from, to);
                    }}
                    itemClassName="links-list-item-container">
                    {links.map((link, i) => {
                        return (
                            <form
                                key={'link-0' + i}
                                className="links-list-item"
                                onSubmit={(evnt) => {
                                    evnt.preventDefault();
                                    updateLink(
                                        evnt.target.children[0].children[0]
                                            .children[1],
                                        evnt.target.children[1].children[0]
                                            .children[1],
                                        i
                                    );
                                }}>
                                <Input
                                    type="text"
                                    value={link.label}
                                    placeholder={'Lable'}
                                    singelLine={true}
                                    disabled={loading}
                                    required
                                />
                                <Input
                                    type="url"
                                    value={link.target}
                                    placeholder={'URL'}
                                    singelLine={true}
                                    disabled={loading}
                                    required
                                />
                                <button style={{ display: 'none' }}></button>
                                <Button
                                    className="remove-link-btn"
                                    type="icon"
                                    isSubmit={false}
                                    disabled={loading}
                                    loading={loading}
                                    onClick={() => {
                                        deleteLink(i);
                                    }}>
                                    <Symbol name="delete_alt1" />
                                </Button>
                            </form>
                        );
                    })}
                </VerticalSortableList>
                <form
                    className="add-new-link-form"
                    onSubmit={(evnt) => {
                        evnt.preventDefault();
                        addLink(
                            evnt.target.children[0].children[0].children[1],
                            evnt.target.children[1].children[0].children[1]
                        );
                    }}>
                    <Input
                        type="text"
                        placeholder={'Lable'}
                        value={newLinkInput.lable}
                        disabled={loading}
                        onChange={(evnt) => {
                            setNewLinkInput((v) => ({
                                ...v,
                                lable: evnt.target.value,
                            }));
                        }}
                        singelLine={true}
                        required
                    />
                    <Input
                        type="url"
                        placeholder={'URL'}
                        value={newLinkInput.target}
                        disabled={loading}
                        onChange={(evnt) => {
                            setNewLinkInput((v) => ({
                                ...v,
                                target: evnt.target.value,
                            }));
                        }}
                        singelLine={true}
                        required
                    />
                    <Button
                        className="add-new-link-btn"
                        type="icon"
                        isSubmit={true}
                        disabled={loading}
                        loading={loading}>
                        <Symbol name="enter" />
                    </Button>
                </form>
            </div>
        </>
    );
}
/*
const PagePreview = ({ visible }) => {
    const dbCtx = useDatabase();
    const pageIframeRef = useRef(null);
    const [isPageLoaded, setIsPageLoaded] = useState(false);
    return (
        <div
            className={
                '--preview-container' +
                (visible ? ' --visible' : ' --no-visible')
            }>
            <div className={'header'}>
                <Text value={'Page preview'} />
                {isPageLoaded && (
                    <Button
                        onClick={() => {
                            setIsPageLoaded(false);
                            let src = pageIframeRef.current.src;
                            pageIframeRef.current.src = '';
                            pageIframeRef.current.src = src;
                        }}
                        className={'reload-page-preview'}
                        type={'icon'}>
                        <Symbol name="reload" />
                    </Button>
                )}
            </div>
            <iframe
                ref={pageIframeRef}
                title="preview"
                onError={() => {
                    setIsPageLoaded(true);
                }}
                onLoad={() => {
                    setIsPageLoaded(true);
                }}
                onLoadStart={() => {
                    setIsPageLoaded(false);
                }}
                //src={'http://localhost:5173/?preview=1'}
                src={`${
                    dbCtx.subdomain.subdomain_name
                        ? dbCtx.subdomain.subdomain_name
                        : 'https://' + dbCtx.subdomain.name + '.faqnation.com'
                }/?preview=1`}></iframe>
        </div>
    );
};
*/
const PageTitleField = ({ field = null, fieldset = null }) => {
    const brCtx = useBarelayout();

    const [cellset, setCellset] = useState(null);

    useEffect(() => {
        setCellset(
            (brCtx.recordsets &&
                brCtx.recordsets[recordsetsIdx.PageRecordset] &&
                brCtx.recordsets[recordsetsIdx.PageRecordset].records &&
                brCtx.recordsets[recordsetsIdx.PageRecordset].records[0] &&
                brCtx.recordsets[recordsetsIdx.PageRecordset].records[0]
                    .cellsets[brCtx.fieldsets[2].id]) ||
                null
        );
    }, [fieldset, brCtx.recordsets]);

    return (
        <Field
            idx={0}
            key={field.id}
            field={field}
            cell={(cellset && cellset.cells[field.id]) || null}
            record={
                brCtx.recordsets &&
                brCtx.recordsets[recordsetsIdx.PageRecordset] &&
                brCtx.recordsets[recordsetsIdx.PageRecordset].records[0]
            }
            fieldset={fieldset}
            recordset={brCtx.recordsets[recordsetsIdx.PageRecordset]}
            is_calendar={false}
            index={-1}
            showTitle={true}
            placeholder={field.label}
            singelLine={true}
        />
    );
};
const IsPagePublicField = () => {
    const dbCtx = useDatabase();
    const handleCheckboxToggle = async (e) => {
        var new_val = !dbCtx.settings.apiEnabled;
        dbCtx.setBasic({
            settings: {
                ...(dbCtx.settings || {}),
                apiEnabled: new_val,
            },
        });
        try {
            const res = await dbCtx.updateDatabase({
                apiEnabled: new_val,
                noReload: true,
            });
            if (res.status !== 200) {
                dbCtx.setBasic({
                    settings: {
                        ...(dbCtx.settings || {}),
                        apiEnabled: !new_val,
                    },
                });
            }
        } catch (err) {
            dbCtx.setBasic({
                settings: {
                    ...(dbCtx.settings || {}),
                    apiEnabled: !new_val,
                },
            });
        }
    };
    return (
        <div className="--field">
            <div className="--field-label">
                <Text value={'Public'} />
            </div>

            <span className={'--checkbox-container'}>
                <Button
                    onClick={handleCheckboxToggle}
                    className={`--checkbox-default ${
                        dbCtx.settings && dbCtx.settings.apiEnabled
                            ? 'on'
                            : 'off'
                    }`}
                    autoFocus={false}
                    type={'icon'}></Button>
            </span>
        </div>
    );
};

const PageLinkField = () => {
    const isInputFocused = useRef(false);
    const isCustomDomainInputFocused = useRef(false);
    const updatingCustomDomainRef = useRef(false);
    const setupCustomDomainPopupRef = useRef(null);
    const subdomainRef = useRef('');

    const settingCtx = useSetting();
    const dbCtx = useDatabase();
    const [subdomain, setSubdomain] = useState('');
    const [domain, setDomain] = useState('faqnation.com');

    const [customDomain, setCustomDomain] = useState('');
    const [updatingCustomDomain, setUpdatingCustomDomain] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');

    useEffect(() => {
        if (!isInputFocused.current) {
            if (dbCtx.subdomain.custom_domain) {
                if (dbCtx.subdomain.custom_domain.split('.').length === 2) {
                    setSubdomain(
                        dbCtx.subdomain.name +
                            '.' +
                            dbCtx.subdomain.custom_domain
                    );
                } else {
                    setSubdomain(dbCtx.subdomain.custom_domain);
                }
            } else {
                setSubdomain(dbCtx.subdomain.name + '.' + domain);
            }
        }
        setDomain(dbCtx.subdomain.custom_domain || 'faqnation.com');
        if (!isCustomDomainInputFocused.current) {
            setCustomDomain(dbCtx.subdomain.custom_domain || '');
        }
        subdomainRef.current = dbCtx.subdomain.custom_domain || '';
    }, [dbCtx.subdomain]);

    useEffect(() => {
        updatingCustomDomainRef.current = updatingCustomDomain;
    }, [updatingCustomDomain]);

    const updateSubdomainName = async () => {
        let newSubdomain = subdomain.trim().toLowerCase();
        try {
            if (dbCtx.subdomain.name !== subdomain.trim() && newSubdomain) {
                let newUrl = '"https://' + newSubdomain + '.' + domain;
                if (isSubdomainValid(newSubdomain)) {
                    let res = await dbCtx.updateDatabase({
                        templateLink: newSubdomain,
                        noReload: true,
                    });
                    if (res.status === 200) {
                        dbCtx.setBasic({
                            subdomain: {
                                ...dbCtx.subdomain,
                                name: newSubdomain,
                            },
                        });
                        return;
                    }
                    if (res.data['message_code'] === 'NAME_NOT_UNIQUE') {
                        throw new Error(
                            'Subdomain name already exist, please try another one!'
                        );
                    } else if (
                        res.data['message_code'] === 'NAME_IS_RESERVED'
                    ) {
                        throw new Error(
                            `You can't use \`${newSubdomain}\`, please try another one!`
                        );
                    }
                    throw new Error('Error changing subdomain name');
                } else {
                    throw new Error(
                        newUrl +
                            " is not a valid as URL\nPlease use just letters a-z or '-' or numbers 0-9"
                    );
                }
            }
            setErrorMsg('');
        } catch (err) {
            setErrorMsg(err.toString());
        } finally {
            setSubdomain(dbCtx.subdomain.name + '.' + domain);
        }
    };

    const updateDomainName = async () => {
        if (updatingCustomDomainRef.current) {
            return;
        }
        if (customDomain.trim() === (dbCtx.subDomain || {})['custom_domain']) {
            return;
        }
        if (!customDomain.trim()) {
            setCustomDomain((dbCtx.subDomain || {})['custom_domain'] || '');
            return;
        }
        setUpdatingCustomDomain(true);
        let newDomain = customDomain.trim().toLowerCase();
        try {
            if (
                (dbCtx.subdomain || {}).custom_domain !== customDomain.trim() &&
                newDomain
            ) {
                let newUrl = '"https://' + newDomain;
                if (isDomainValid(newDomain)) {
                    let res = await dbCtx.updateDatabase({
                        customDomain: newDomain,
                        noReload: false,
                    });
                    setUpdatingCustomDomain(false);
                    if (res.status === 200) {
                        dbCtx.setBasic({
                            subdomain: {
                                ...dbCtx.subdomain,
                                custom_domain: newDomain,
                            },
                        });
                        return;
                    }
                    if (res.data['message_code'] === 'NAME_NOT_UNIQUE') {
                        throw new Error(
                            'Domain name already exist, please try another name!'
                        );
                    }
                    throw new Error('Error changing domain name');
                } else {
                    throw new Error(newUrl + ' is not a valid as URL');
                }
            } else {
                setCustomDomain(subdomainRef.current || '');
                return;
            }
        } catch (err) {
            showError(err.message.toString());
        } finally {
            setUpdatingCustomDomain(false);
            setSubdomain(dbCtx.subdomain.name + '.' + domain);
        }
    };

    const isSubdomainValid = (subDomain) => {
        /* a valid subdomain contains just letters a-z of numbers 0-9 of '-' */
        try {
            let url = new URL('https://' + subDomain + '.faqnation.com');
        } catch (err) {
            console.error(err);
            return false;
        }
        for (let i = subDomain.length - 1; i >= 0; i--) {
            let letter = subDomain.substring(i, i + 1).toString();
            if (
                !(
                    (letter >= 'a' && letter <= 'z') ||
                    (letter >= '0' && letter <= '9') ||
                    letter === '-'
                )
            ) {
                return false;
            }
        }
        return true;
    };

    const isDomainValid = (domain) => {
        if (domain.split('.').length < 2) {
            return false;
        }
        try {
            let url = new URL('https://' + domain);
        } catch (err) {
            console.error(err);
            return false;
        }
        return true;
    };

    return (
        <>
            <div className="--field">
                <div className="--field-label">
                    <Text value={'Page URL'} />
                </div>
                <div>
                    <div className="--subdomain-name-input-wrapper">
                        <Input
                            type="text"
                            className={'--subdomain-name-input'}
                            value={subdomain}
                            disabled={customDomain.split('.').length > 2}
                            onChange={(evnt) => {
                                setSubdomain(evnt.target.value);
                                setErrorMsg('');
                            }}
                            onFocus={() => {
                                isInputFocused.current = true;
                                setSubdomain(dbCtx.subdomain.name);
                            }}
                            onBlur={() => {
                                isInputFocused.current = false;
                                updateSubdomainName();
                            }}
                        />
                        <Button
                            className={'copy-subdomain-btn'}
                            onClick={(e) => {
                                copy_text(
                                    'https://' + subdomain
                                    /*dbCtx.subdomain.name +
                                        '.faqnation.com'*/
                                );
                            }}>
                            <Symbol name="content_copy" />
                        </Button>
                    </div>
                    {errorMsg && (
                        <div className="update-page-subdomain-error-msg">
                            {errorMsg}
                        </div>
                    )}
                </div>
            </div>
            <>
                <div className="--field">
                    <div className="--field-label">
                        <Text
                            value={'Custom Domain'}
                            title={
                                'Use your custom domain instead of "faqnation.com"'
                            }
                        />
                    </div>
                    <div>
                        {(dbCtx.limits || {}).is_pro ? (
                            <PopupMenu
                                ref={setupCustomDomainPopupRef}
                                className={'setup-custom-domain-popup'}
                                modal={true}
                                trigger={
                                    <Button
                                        type={'primary'}
                                        style={{
                                            paddingRight: '1.2rem',
                                            paddingLeft: '1.2rem',
                                        }}>
                                        Setup
                                    </Button>
                                }
                                nested={true}>
                                <Button
                                    style={{
                                        position: 'absolute',
                                        top: '10px',
                                        right: '10px',
                                    }}
                                    type="icon"
                                    onClick={() => {
                                        setupCustomDomainPopupRef.current.close();
                                    }}>
                                    <Symbol name="close" />
                                </Button>
                                <h3>Setup custom domain</h3>
                                <div className="--subdomain-name-input-wrapper --custom-domain">
                                    <Input
                                        type="text"
                                        className={'--subdomain-name-input'}
                                        value={customDomain}
                                        placeholder={'Enter your domain'}
                                        onChange={(evnt) => {
                                            setCustomDomain(evnt.target.value);
                                        }}
                                        onFocus={() => {
                                            isCustomDomainInputFocused.current = true;
                                        }}
                                        onBlur={() => {
                                            isCustomDomainInputFocused.current = false;
                                            //updateDomainName();
                                        }}
                                    />
                                    <Button
                                        type={'primary'}
                                        onClick={() => {
                                            updateDomainName();
                                        }}
                                        className="--change-domain-btn"
                                        loading={updatingCustomDomain}>
                                        save
                                    </Button>
                                </div>
                                <div
                                    style={{
                                        display: updatingCustomDomain
                                            ? ''
                                            : 'none',
                                    }}
                                    className="updatingCustomDomain-loading"></div>
                                {(dbCtx.custom_domain_hint || [])[0] && (
                                    <>
                                        <div className="--cnam-info-container">
                                            <Text
                                                value={
                                                    "Add the folowing CNAME and TXT records to your domain's DNS records :"
                                                }
                                            />
                                            <br />
                                            <br />
                                            {dbCtx.custom_domain_hint.map(
                                                (record, n) => (
                                                    <div key={'rec' + n}>
                                                        <div>
                                                            <Text
                                                                value={
                                                                    <u>
                                                                        <b>
                                                                            {n +
                                                                                1}
                                                                            .&nbsp;
                                                                            {
                                                                                record.type
                                                                            }{' '}
                                                                            record:
                                                                        </b>
                                                                    </u>
                                                                }
                                                            />
                                                        </div>
                                                        {Object.keys(
                                                            record
                                                        ).map((attr, i) => {
                                                            return (
                                                                <div
                                                                    key={
                                                                        'attr' +
                                                                        i
                                                                    }
                                                                    className={
                                                                        'cnam-record-info-item'
                                                                    }>
                                                                    <Text
                                                                        value={
                                                                            <b>
                                                                                {attr
                                                                                    .substring(
                                                                                        0,
                                                                                        1
                                                                                    )
                                                                                    .toUpperCase() +
                                                                                    attr
                                                                                        .substring(
                                                                                            1
                                                                                        )
                                                                                        .toLowerCase() +
                                                                                    ' : '}
                                                                            </b>
                                                                        }
                                                                    />
                                                                    <Text
                                                                        className={
                                                                            'cnam-record-info-item-value'
                                                                        }
                                                                        value={
                                                                            record[
                                                                                attr
                                                                            ]
                                                                        }
                                                                    />
                                                                </div>
                                                            );
                                                        })}
                                                        <br />
                                                    </div>
                                                )
                                            )}
                                        </div>
                                        <div
                                            style={{
                                                opacity: '.5',
                                                margin: '4px',
                                            }}>
                                            <Text
                                                value={`Setup your custom domain may take up to 24 hours to get ready. It is a fully automated process, We have no control on how long this will take.`}
                                            />
                                        </div>
                                    </>
                                )}
                            </PopupMenu>
                        ) : (
                            <Button
                                type={'primary'}
                                onClick={() => {
                                    settingCtx.setUpgradeProjectDialog({
                                        visible: true,
                                        project_id: dbCtx.database.uid,
                                        msg: 'You need to upgrade your project to setup a custom domain!',
                                    });
                                }}
                                style={{
                                    paddingRight: '1.2rem',
                                    paddingLeft: '1.2rem',
                                }}>
                                Setup
                            </Button>
                        )}
                    </div>
                </div>
            </>
        </>
    );
};

const SelectSortingRule = ({ brCtx, fieldset, cellset, option, label }) => {
    const popupMenuRef = useRef(null);
    const options = useRef({
        most_viewed: { label: 'Most viewed' },
        newest: { label: 'Newest' },
        oldest: { label: 'Oldest' },
        /*by_order: { label: 'By order' },*/
    });
    const [selectedOptionValue, setSelectedOptionValue] =
        useState('most_viewed');
    const field = fieldset.fields[option.idx];

    useEffect(() => {
        let cell = (cellset && cellset.cells[field.id]) || null;
        if (!cell) {
            return;
        }
        setSelectedOptionValue(cell.value || 'most_viewed');
    }, [cellset, field]);

    const saveChanges = (newValue) => {
        brCtx.handleCellUpdate({
            field: field,
            cell: (cellset && cellset.cells[field.id]) || null,
            record:
                brCtx.recordsets &&
                brCtx.recordsets[recordsetsIdx.PageRecordset] &&
                brCtx.recordsets[recordsetsIdx.PageRecordset].records[0],
            fieldset: fieldset,
            recordset:
                brCtx.recordsets &&
                brCtx.recordsets[recordsetsIdx.PageRecordset],
            new_value: newValue,
        });
    };

    return (
        <>
            <div className="--field" style={{ alignItems: 'center' }}>
                <div className="--field-label">
                    <Text value={label} />
                </div>
                <div style={{ padding: '8px 0' }}>
                    <div style={{ width: '100%' }}></div>
                    <PopupMenu
                        ref={popupMenuRef}
                        nested={true}
                        trigger={
                            <Button
                                style={{
                                    width: '100%',
                                    minWidth: '200px',
                                    maxWidth: '300px',
                                }}
                                className={'--select-box-custom'}>
                                <Text
                                    value={
                                        options.current[selectedOptionValue]
                                            .label
                                    }
                                />
                                <Symbol
                                    className={'ml-8 --select-box-arrow'}
                                    name={'expand_icon'}
                                />
                            </Button>
                        }>
                        {Object.keys(options.current).map((option) => {
                            return (
                                <Button
                                    onClick={() => {
                                        setSelectedOptionValue(option);
                                        saveChanges(option);
                                        popupMenuRef.current.close();
                                    }}
                                    key={option}
                                    type={'popup'}>
                                    {options.current[option].label}
                                </Button>
                            );
                        })}
                    </PopupMenu>
                </div>
            </div>
        </>
    );
};

const Option = ({ brCtx, fieldset, cellset, option, label }) => {
    return (
        <Field
            idx={0}
            key={fieldset.fields[option.idx].id}
            field={fieldset.fields[option.idx]}
            cell={
                (cellset && cellset.cells[fieldset.fields[option.idx].id]) ||
                null
            }
            record={
                brCtx.recordsets &&
                brCtx.recordsets[recordsetsIdx.PageRecordset] &&
                brCtx.recordsets[recordsetsIdx.PageRecordset].records[0]
            }
            fieldset={fieldset}
            recordset={brCtx.recordsets[recordsetsIdx.PageRecordset]}
            is_calendar={false}
            index={-10}
            showTitle={true}
            placeholder={fieldset.fields[option.idx].label}
            singelLine={option.name === 'Mail to'}
            label={<Lable text={label} help={option.tooltip} />}
        />
    );
};

const OptionImage = ({
    brCtx,
    fieldset,
    cellset,
    option,
    label,
    accept,
    validate,
}) => {
    return (
        <Field
            idx={0}
            key={fieldset.fields[option.idx].id}
            field={fieldset.fields[option.idx]}
            cell={
                (cellset && cellset.cells[fieldset.fields[option.idx].id]) ||
                null
            }
            record={
                brCtx.recordsets &&
                brCtx.recordsets[recordsetsIdx.PageRecordset] &&
                brCtx.recordsets[recordsetsIdx.PageRecordset].records[0]
            }
            fieldset={fieldset}
            recordset={brCtx.recordsets[recordsetsIdx.PageRecordset]}
            is_calendar={false}
            index={-10}
            showTitle={true}
            validate={validate}
            accept={accept}
            placeholder={fieldset.fields[option.idx].label}
            label={
                <Lable
                    text={label || fieldset.fields[option.idx].label}
                    help={option.tooltip}
                />
            }
        />
    );
};

const PageColors = ({ brCtx, fieldset, cellset, field }) => {
    const saveTimeoutRef = useRef(null);

    const [primaryClr, setPrimaryClr] = useState('#3F4C63');
    const [onPrimaryClr, setOnPrimaryClr] = useState('#ffffff');
    const [secondaryClr, setSecondaryClr] = useState('#ffffff');
    const [onSecondaryClr, setOnSecondaryClr] = useState('#3F4C63');
    const [triggerUpdate, setTriggerUpdate] = useState(0);

    useEffect(() => {
        let cell = (cellset && cellset.cells[field.id]) || null;
        if (!cell) {
            return;
        }
        let colorsValue = JSON.parse(cell.value || '{}');
        setPrimaryClr(colorsValue['primary-clr'] || '#3F4C63');
        setOnPrimaryClr(colorsValue['on-primary-clr'] || '#ffffff');
        setSecondaryClr(colorsValue['secondary-clr'] || '#ffffff');
        setOnSecondaryClr(colorsValue['on-secondary-clr'] || '#3F4C63');
    }, [cellset]);
    useEffect(() => {
        if (triggerUpdate === 0) {
            return;
        }
        saveChanges();
    }, [triggerUpdate]);

    const saveChanges = () => {
        try {
            clearTimeout(saveTimeoutRef.current);
        } catch (err) {}
        saveTimeoutRef.current = setTimeout(() => {
            let newValue = `{"primary-clr": "${primaryClr}","on-primary-clr": "${onPrimaryClr}","secondary-clr": "${secondaryClr}","on-secondary-clr": "${onSecondaryClr}"}`;
            brCtx.handleCellUpdate({
                field: field,
                cell: (cellset && cellset.cells[field.id]) || null,
                record:
                    brCtx.recordsets &&
                    brCtx.recordsets[recordsetsIdx.PageRecordset] &&
                    brCtx.recordsets[recordsetsIdx.PageRecordset].records[0],
                fieldset: fieldset,
                recordset:
                    brCtx.recordsets &&
                    brCtx.recordsets[recordsetsIdx.PageRecordset],
                new_value: newValue,
            });
        }, 800);
    };

    const autoUpadateOnPrimary = (clr) => {
        let clrRGBComponents = parseRBGClr(clr);
        let clrHSLComponents = RGBToHSL(
            clrRGBComponents[0],
            clrRGBComponents[1],
            clrRGBComponents[2]
        );
        setOnPrimaryClr(clrHSLComponents[2] > 65 ? '#333333' : '#ffffff');
        //setSecondaryClr(clrHSLComponents[2] > 65 ? '#333333' : '#ffffff');
        auroUpdateSecondary(clrHSLComponents, clrRGBComponents);
    };
    const auroUpdateSecondary = (primaryClrHSLComponents, rgbClrComponents) => {
        let brightness = -100;
        /*let rgbClrComponents = HSLToRGB(
            primaryClrHSLComponents[0],
            primaryClrHSLComponents[1],
            brightness
        );*/
        setSecondaryClr(
            primaryClrHSLComponents[2] > 65
                ? `#${format(
                      Math.max(
                          0,
                          Math.floor(rgbClrComponents[0] + brightness)
                      ).toString(16)
                  )}${format(
                      Math.max(
                          0,
                          Math.floor(rgbClrComponents[1] + brightness)
                      ).toString(16)
                  )}${format(
                      Math.max(
                          0,
                          Math.floor(rgbClrComponents[2] + brightness)
                      ).toString(16)
                  )}`
                : '#ffffff'
        );
        autoUpadateOnSecondary(
            primaryClrHSLComponents[2] > 65 ? '#333333' : '#ffffff'
        );
    };
    const autoUpadateOnSecondary = (clr) => {
        let clrRGBComponents = parseRBGClr(clr);
        let clrHSLComponents = RGBToHSL(
            clrRGBComponents[0],
            clrRGBComponents[1],
            clrRGBComponents[2]
        );
        setOnSecondaryClr(clrHSLComponents[2] > 45 ? '#333333' : '#ffffff');
    };
    function format(txt) {
        return txt.length === 1 ? `0${txt}` : txt;
    }

    return (
        <div>
            <ColorSelector
                id={'primary-clr-selector'}
                label={'Theme color'}
                value={primaryClr}
                onChange={(value) => {
                    autoUpadateOnPrimary(value);
                    setPrimaryClr(value);
                    setTriggerUpdate(new Date().getTime());
                }}
            />
            {/*
                <div style={{ display: '' }}>
                    <ColorSelector
                        label={'On Primary color'}
                        id={'on-primary-clr-selector'}
                        value={onPrimaryClr}
                        onChange={(value) => {
                            setOnPrimaryClr(value);
                            setTriggerUpdate(new Date().getTime());
                        }}
                    />
                    <ColorSelector
                        label={'Secondary color'}
                        id={'secondary-clr-selector'}
                        value={secondaryClr}
                        onChange={(value) => {
                            autoUpadateOnSecondary(value);
                            setSecondaryClr(value);
                            setTriggerUpdate(new Date().getTime());
                        }}
                    />
                    <ColorSelector
                        label={'On Secondary color'}
                        id={'on-secondary-clr-selector'}
                        value={onSecondaryClr}
                        onChange={(value) => {
                            setOnSecondaryClr(value);
                            setTriggerUpdate(new Date().getTime());
                        }}
                    />
                </div>
            */}
        </div>
    );
};

function EditPageFooter({ brCtx, field, cellset, fieldset, option }) {
    const saveTimeoutRef = useRef(null);
    const dbCtx = useDatabase();
    const [waitingForPaymentLink, setWaitingForPaymentLink] = useState(false);
    const [footerText, setFooterText] = useState('');
    const [footerTarget, setFooterTarget] = useState('');
    const [triggerUpdate, setTriggerUpdate] = useState(0);

    useEffect(() => {
        if (triggerUpdate === 0) {
            return;
        }
        saveChanges();
    }, [triggerUpdate]);

    useEffect(() => {
        let cell = (cellset && cellset.cells[field.id]) || null;
        if (!cell) {
            return;
        }
        try {
            let footerContent = JSON.parse(cell.value || '{}');
            setFooterText(footerContent['text'] || '');
            setFooterTarget(footerContent['target'] || '');
        } catch (err) {
            setFooterText(cell.value);
        }
    }, [cellset]);

    const saveChanges = () => {
        try {
            clearTimeout(saveTimeoutRef.current);
        } catch (err) {}
        saveTimeoutRef.current = setTimeout(() => {
            let newValue = `{"text": "${footerText}","target": "${footerTarget}"}`;
            brCtx.handleCellUpdate({
                field: field,
                cell: (cellset && cellset.cells[field.id]) || null,
                record:
                    brCtx.recordsets &&
                    brCtx.recordsets[recordsetsIdx.PageRecordset] &&
                    brCtx.recordsets[recordsetsIdx.PageRecordset].records[0],
                fieldset: fieldset,
                recordset:
                    brCtx.recordsets &&
                    brCtx.recordsets[recordsetsIdx.PageRecordset],
                new_value: newValue,
            });
        }, 800);
    };

    return (dbCtx.limits['limits'] || {})['removeBranding'] ? (
        <>
            {/* <Option
                brCtx={brCtx}
                cellset={cellset}
                fieldset={fieldset}
                label={'Footer text'}
                option={option}
            /> */}
            <>
                <div className="--field">
                    <div className="--field-label">
                        <Text value="Footer text" help={option.tooltip} />
                    </div>
                    <div>
                        <Input
                            type={'text'}
                            placeholder={'© 2024 FAQ Nation'}
                            size={'wide-mobile'}
                            value={footerText}
                            onChange={(evnt) => {
                                setFooterText(evnt.target.value);
                                setTriggerUpdate(new Date().getTime());
                            }}
                        />
                    </div>
                </div>
                <div className="--field">
                    <div className="--field-label">
                        <Lable text="Footer target" />
                    </div>
                    <div>
                        <Input
                            type={'text'}
                            placeholder={'https://faqnation.com'}
                            size={'wide-mobile'}
                            value={footerTarget}
                            onChange={(evnt) => {
                                setFooterTarget(evnt.target.value);
                                setTriggerUpdate(new Date().getTime());
                            }}
                        />
                    </div>
                </div>
            </>
        </>
    ) : (
        <div className="--field">
            <div className="--field-label">
                <Lable text="Footer" />
            </div>
            <div>
                <Button
                    loading={waitingForPaymentLink}
                    type="primary"
                    size={'full'}
                    style={{ fontWeight: '600', fontSize: '1rem' }}
                    onClick={async (evnt) => {
                        setWaitingForPaymentLink(true);
                        try {
                            let res = await callApi({
                                path: apiRoutes.plans.getBuyAddonLink,
                                method: 'post',
                                payload: {
                                    planId: plans.addons.removeBrandingLifetime
                                        .name,
                                    projectId: dbCtx.database.uid,
                                },
                            });
                            if (res.status !== 200) {
                                throw new Error('Unknown Error');
                            }
                            document.location.href = res.data.checkoutUrl;
                        } catch (err) {
                            showError('Error, please try again!');
                        }
                        setWaitingForPaymentLink(false);
                    }}>
                    Remove Footer
                </Button>
                <p style={{ fontSize: '1rem', marginTop: '10px' }}>
                    You can adapt the "Powerd by FAQ Nation" description from
                    the public page and widget for a one-time fee.
                </p>
                <p style={{ fontSize: '1rem', marginTop: '10px' }}>
                    <strong>Checkout for 20€</strong>
                </p>
            </div>
        </div>
    );
}

function EditPageTextStyle({ brCtx, fieldset, cellset, field }) {
    const saveTimeoutRef = useRef(null);
    const popupMenuRef = useRef(null);

    const fontsList = useRef([
        {
            name: 'Roboto',
            url: 'https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;600;700;900&display=swap',
        },
        {
            name: 'Open Sans',
            url: 'https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700;800&display=swap',
        },
        {
            name: 'Lato',
            url: 'https://fonts.googleapis.com/css2?family=Lato:wght@300;400;500;600;700;900&display=swap',
        },
        {
            name: 'Montserrat',
            url: 'https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;900&display=swap',
        },
        {
            name: 'Merriweather',
            url: 'https://fonts.googleapis.com/css2?family=Merriweather:wght@300;400;700;900&display=swap',
        },
        {
            name: 'PT Sans',
            url: 'https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;700&display=swap',
        },
        {
            name: 'Raleway',
            url: 'https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;700;900&display=swap',
        },
        {
            name: 'Noto Sans',
            url: 'https://fonts.googleapis.com/css2?family=Noto+Sans:wght@300;400;700;900&display=swap',
        },
    ]);
    const defaultTextFont = useRef(fontsList.current[0]);
    const defaultTextClr = useRef('#5B5B5B');
    const defaultTextSize = useRef('16'); //px

    const [textFont, setTextFont] = useState(defaultTextFont.current);
    const [textClr, setTextCtr] = useState(defaultTextClr.current);
    const [textSize, setTextSize] = useState(defaultTextSize.current);
    const [isTextSizeInputFocused, setIsTextSizeInputFocused] = useState(false);
    const [triggerUpdate, setTriggerUpdate] = useState(0);

    useEffect(() => {
        if (triggerUpdate === 0) {
            return;
        }
        saveChanges();
    }, [triggerUpdate]);

    useEffect(() => {
        let cell = (cellset && cellset.cells[field.id]) || null;
        if (!cell) {
            return;
        }
        let textStyleValue = JSON.parse(cell.value || '{}');
        setTextFont({
            url: textStyleValue['text-font-url'] || defaultTextFont.current.url,
            name:
                textStyleValue['text-font-name'] ||
                defaultTextFont.current.name,
        });
        setTextCtr(textStyleValue['text-color'] || defaultTextClr.current);
        setTextSize(
            (textStyleValue['text-size'] || defaultTextSize.current).replace(
                'px',
                ''
            )
        );
    }, [cellset]);

    const saveChanges = () => {
        try {
            clearTimeout(saveTimeoutRef.current);
        } catch (err) {}
        saveTimeoutRef.current = setTimeout(() => {
            let newValue = `{"text-color": "${textClr}","text-size": "${textSize}px","text-font-name": "${textFont.name}","text-font-url": "${textFont.url}"}`;
            brCtx.handleCellUpdate({
                field: field,
                cell: (cellset && cellset.cells[field.id]) || null,
                record:
                    brCtx.recordsets &&
                    brCtx.recordsets[recordsetsIdx.PageRecordset] &&
                    brCtx.recordsets[recordsetsIdx.PageRecordset].records[0],
                fieldset: fieldset,
                recordset:
                    brCtx.recordsets &&
                    brCtx.recordsets[recordsetsIdx.PageRecordset],
                new_value: newValue,
            });
        }, 800);
    };
    return (
        <>
            <ColorSelector
                id={'page-text-clr-selector'}
                label={'Text color'}
                help={{ txt: 'Help page content text color', img: undefined }}
                value={textClr}
                onChange={(value) => {
                    setTextCtr(value);
                    setTriggerUpdate(new Date().getTime());
                }}
            />
            <div className="--field">
                <div className="--field-label">
                    <Text value={'Font'} />
                </div>
                <div style={{ padding: '8px 0' }}>
                    <div style={{ width: '100%' }}></div>

                    <PopupMenu
                        ref={popupMenuRef}
                        nested={true}
                        trigger={
                            <Button
                                style={{
                                    width: '100%',
                                }}
                                className={'--select-box-custom'}>
                                <Text value={textFont.name} />
                                <Symbol
                                    className={'ml-8 --select-box-arrow'}
                                    name={'expand_icon'}
                                />
                            </Button>
                        }>
                        {(fontsList.current || []).map((font) => {
                            return (
                                <Button
                                    onClick={() => {
                                        setTextFont(font);
                                        setTriggerUpdate(new Date().getTime());
                                        popupMenuRef.current.close();
                                    }}
                                    key={font.name.replace(' ', '-')}
                                    type={'popup'}>
                                    {font.name}
                                </Button>
                            );
                        })}
                    </PopupMenu>
                </div>
            </div>
            <div className="--field">
                <div className="--field-label">
                    <Text value={'Text size'} />
                </div>
                <div style={{ padding: '8px 0' }}>
                    <div style={{ width: '100%' }}></div>
                    <Input
                        onFocus={(evnt) => {
                            setIsTextSizeInputFocused(true);
                        }}
                        onBlur={(evnt) => {
                            setIsTextSizeInputFocused(false);
                        }}
                        onChange={(evnt) => {
                            let fontSize = parseInt(evnt.target.value);
                            if (!fontSize || fontSize < 1) {
                                fontSize = defaultTextSize.current;
                            }
                            setTextSize(fontSize);
                            setTriggerUpdate(new Date().getTime());
                        }}
                        size="wide-mobile"
                        type={'text'}
                        value={textSize + (isTextSizeInputFocused ? '' : 'px')}
                    />
                </div>
            </div>
        </>
    );
}

export const Lable = ({ text, help = { txt: undefined, img: undefined } }) => {
    return (
        <Tooltip
            position={'left top'}
            offsetX={10}
            offsetY={-10}
            noArrow={true}
            trigger={
                <span style={{ whiteSpace: 'nowrap' }}>
                    <Text
                        style={{
                            cursor: help.txt || help.img ? 'help' : '',
                        }}
                        value={text}
                    />
                    {help.txt || help.img ? (
                        <span className="tooltip-help-indicator-icon">?</span>
                    ) : (
                        <></>
                    )}
                </span>
            }
            text={<div>{help.txt}</div>}
            imgSrc={help.img}
        />
    );
};

function ProjectMetaKeysEditor({
    brCtx,
    field,
    cellset,
    fieldset,
    option,
    label,
}) {
    const saveTimeoutRef = useRef(null);
    const inputSizeCalculatorRef = useRef(null);
    const tagInputRef = useRef(null);

    const [tagsList, setTagsList] = useState([]);
    const [newTagValue, setNewTagValue] = useState('');
    const [newTagInputSize, setNewTagInputSize] = useState(0);
    const [triggerUpdate, setTriggerUpdate] = useState(0);

    useEffect(() => {
        if (triggerUpdate === 0) {
            return;
        }
        let newValue = tagsList.join(',');
        saveChanges(newValue);
    }, [triggerUpdate]);

    useEffect(() => {
        let cell = (cellset && cellset.cells[field.id]) || null;
        if (!cell) {
            return;
        }
        try {
            console.log(cell.value);
            let tags = cell.value ? (cell.value || '').split(',') : [];
            setTagsList(tags || []);
        } catch (err) {
            setTagsList([]);
        }
    }, [cellset]);

    function saveChanges(newValue) {
        try {
            clearTimeout(saveTimeoutRef.current);
        } catch (err) {}
        saveTimeoutRef.current = setTimeout(() => {
            brCtx.handleCellUpdate({
                field: field,
                cell: (cellset && cellset.cells[field.id]) || null,
                record:
                    brCtx.recordsets &&
                    brCtx.recordsets[recordsetsIdx.PageRecordset] &&
                    brCtx.recordsets[recordsetsIdx.PageRecordset].records[0],
                fieldset: fieldset,
                recordset:
                    brCtx.recordsets &&
                    brCtx.recordsets[recordsetsIdx.PageRecordset],
                new_value: newValue,
            });
        }, 800);
    }

    function deleteTag(tagIndex) {
        if (tagIndex === null || tagIndex === undefined) {
            return;
        }
        let newTagsList = tagsList.filter((tag, i) => i !== tagIndex);
        setTagsList([...newTagsList]);
        setTriggerUpdate((v) => v + 1);
    }

    function addTags(tags) {
        setTagsList((oldTags) => [
            ...oldTags,
            ...tags.filter((tag) => tag.trim()),
        ]);
        setTriggerUpdate((v) => v + 1);
    }

    function handleAddTagInputChange(evnt) {
        if (
            evnt.target.value.indexOf(',') > 0 ||
            evnt.target.value.indexOf('\t') > 0 ||
            evnt.target.value.indexOf('\n') > 0
        ) {
            let tags = evnt.target.value
                .trim()
                .replaceAll('\t', ',')
                .replaceAll('\n', ',')
                .split(',');
            addTags(tags);
            setNewTagValue('');
            return;
        }
        setNewTagValue(evnt.target.value);
    }

    return (
        <div className="--field">
            <div className="--field-label">
                <Text value={label} help={option.tooltip} />
            </div>
            <div>
                <div className="tags-editor-container">
                    {tagsList.map((tag, i) => (
                        <div
                            className="tag-elment"
                            key={'keyword-' + i}
                            title={tag}>
                            <span className="tag-elment-value">{tag}</span>
                            <span
                                className={`delete-tag-elment`}
                                tabIndex={-1}
                                onClick={() => {
                                    deleteTag(i);
                                }}>
                                <Symbol name="close" />
                            </span>
                        </div>
                    ))}
                    <form
                        className="add-new-tag-input-container"
                        onSubmit={(evnt) => {
                            evnt.preventDefault();
                            let newTag = tagInputRef.current.value;
                            if (newTag.trim().length > 0) {
                                let tags = newTag
                                    .trim()
                                    .replaceAll('\t', ',')
                                    .replaceAll('\n', ',')
                                    .split(',');
                                addTags(tags);
                                setNewTagValue('');
                            }
                        }}>
                        <label
                            ref={inputSizeCalculatorRef}
                            className="add-new-tag-input-size-calculator">
                            {newTagValue}
                        </label>
                        {
                            <input
                                ref={tagInputRef}
                                className="add-new-tag-input"
                                placeholder="Add"
                                value={newTagValue}
                                style={{
                                    width: `${newTagInputSize}px`,
                                }}
                                onChange={(evnt) => {
                                    flushSync(() => {
                                        handleAddTagInputChange(evnt);
                                    });
                                    setNewTagInputSize(
                                        inputSizeCalculatorRef.current
                                            .offsetWidth + 8
                                    );
                                }}
                                onKeyDown={(evnt) => {
                                    if (evnt.key.toLowerCase() === 'tab') {
                                        evnt.preventDefault();
                                        let newTag = evnt.target.value;
                                        console.log(newTag);
                                        if (newTag.trim().length > 0) {
                                            let tags = newTag
                                                .trim()
                                                .replaceAll('\t', ',')
                                                .replaceAll('\n', ',')
                                                .split(',');
                                            addTags(tags);
                                            setNewTagValue('');
                                        }
                                    }
                                }}
                            />
                        }
                    </form>
                </div>
            </div>
        </div>
    );
}

export const projectSettingsPanel = {
    open: (category = undefined) => {
        window.dispatchEvent(
            new CustomEvent(faqnationEvents.open_project_settings_panel, {
                detail: { category: category },
            })
        );
    },
    close: () => {
        window.dispatchEvent(
            new CustomEvent(faqnationEvents.close_project_settings_panel, {
                detail: {},
            })
        );
    },
};
