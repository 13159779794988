import { createContext, useState } from 'react';

export const helpPagePreviewViews = {
    desktop: 1,
    mobile: 2,
};

const initialCtxState = { view: helpPagePreviewViews.desktop };
const initialCtxMethods = {
    setView: ({ view }) => {},
};

const HelpPagePreviewCtx = createContext({
    state: initialCtxState,
    methods: initialCtxMethods,
});

function HelpPagePreviewCtxProvider({ children }) {
    const [state, setState] = useState(initialCtxState);
    const methods = {
        setView: ({ view }) => {
            if (!view) {
                return;
            }
            setState((state) => {
                return {
                    ...state,
                    view: view,
                };
            });
        },
    };

    return (
        <HelpPagePreviewCtx.Provider value={{ state: state, methods: methods }}>
            {children}
        </HelpPagePreviewCtx.Provider>
    );
}

export { HelpPagePreviewCtx, HelpPagePreviewCtxProvider };
