import { Text } from '../Text';
import { PopupMenu } from './Popup';
import './tooltip.scss';

export function Tooltip({
    trigger,
    imgSrc,
    text,
    position = 'bottom center',
    offsetX = 0,
    offsetY = 6,
    noArrow = false,
}) {
    return (
        <>
            {imgSrc || text ? (
                <PopupMenu
                    className={`tooltip ${noArrow ? 'noArrowTooltip' : ''}`}
                    on={['hover']}
                    arrow={false}
                    position={position}
                    offsetX={offsetX}
                    offsetY={offsetY}
                    trigger={trigger}>
                    {imgSrc && <img width="100%" src={imgSrc} alt="" />}
                    {text && typeof text === 'string' ? (
                        <Text value={text} />
                    ) : (
                        text
                    )}
                </PopupMenu>
            ) : (
                trigger
            )}
        </>
    );
}
