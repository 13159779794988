import { useEffect, useRef, useState } from 'react';
import useSetting from '../../../hooks/useSetting';
import { Resizable } from 'react-quick-resizable';
import Button from '../../../components/Button';
import { Symbol } from '../../../components/elements/Icon';
import { Text } from '../../../components/Text';
import useBarelayout from '../../../layouts/barelayout/hook';
import PopupMenu, { PositionTypes } from '../../../components/Popup';
import useDatabase from '../../../hooks/useDatabase';
import { createPortal } from 'react-dom';
import { RightPanelTabs } from '../main';
import { recordsetsIdx } from '../../../layouts/barelayout/ctx';
import { rightPanelViewStyles } from '../editFaqPanel/FaqEditorPanel';
import { showError } from '../../../components/towsts/Toasts';

export const UsersQuestionsPanel = () => {
    const visible_ = useRef(false);
    const rightUsersQuestionsPanelWrapper = useRef(null);

    const settingCtx = useSetting();
    const brCtx = useBarelayout();
    const dbCtx = useDatabase();

    const [visible, setVisible] = useState(false);
    const [addingQuestion, setAddingQuestion] = useState(false);
    const [userQuestions, setUserQuestions] = useState([]);

    const [removeAllState, setRemoveAllState] = useState(0);

    const [addRecordWasTriggered, setAddRecordWasTriggered] = useState({
        state: false,
        recordId: null,
    });

    useEffect(() => {
        visible_.current = settingCtx.right_users_questions_panel.visible;
        if (visible !== settingCtx.right_users_questions_panel.visible) {
            setVisible(settingCtx.right_users_questions_panel.visible);
        }
    }, [settingCtx.right_users_questions_panel.visible]);

    useEffect(() => {
        setRemoveAllState(0);
        if (visible) {
            try {
                let records =
                    dbCtx.recordsets[recordsetsIdx.InboxsRecordset].records;
            } catch (err) {
                return;
            }
            let questionId, emailId, dateId;
            let m = 3;
            for (let i = dbCtx.fieldsets.length - 1; m > 0 && i > 0; i--) {
                for (
                    let n = 0;
                    m > 0 && n < dbCtx.fieldsets[i].fields.length;
                    n++
                ) {
                    let field = dbCtx.fieldsets[i].fields[n];
                    if (field.label === 'Question') {
                        m--;
                        questionId = field.uid;
                    } else if (field.label === 'User email') {
                        m--;
                        emailId = field.uid;
                    } else if (field.label === 'Date') {
                        m--;
                        dateId = field.uid;
                    }
                }
            }
            setUserQuestions(
                dbCtx.recordsets[recordsetsIdx.InboxsRecordset].records.map(
                    (record, i) => {
                        if (record.cells.length === 0) {
                            return null;
                        }
                        let question = { id: record.uid };
                        let m = 3;
                        for (
                            let n = record.cells.length - 1;
                            m > 0 && n > 0;
                            n--
                        ) {
                            let cell = record.cells[n];
                            if (cell.fielduid === questionId) {
                                question['question'] = cell.value;
                                m--;
                            } else if (cell.fielduid === emailId) {
                                question['email'] = cell.value;
                                m--;
                            } else if (cell.fielduid === dateId) {
                                let date = new Date(cell.value);
                                question['date'] = `${date.getDate()}.${
                                    date.getMonth() + 1
                                }.${date.getFullYear()}`;
                                m--;
                            }
                        }
                        return { ...question };
                    }
                )
            );
        }
    }, [visible]);

    useEffect(() => {
        let target;
        let clickTimeout;
        const clickListener = (e) => {
            target = e.target;
            try {
                clearTimeout(clickTimeout);
            } catch (err) {}
            clickTimeout = setTimeout(() => {
                window.removeEventListener('mouseup', listener);
            }, 300);
            window.addEventListener('mouseup', listener);
        };
        const listener = (e) => {
            try {
                clearTimeout(clickTimeout);
                window.removeEventListener('mouseup', listener);
            } catch (e) {}
            if (!rightUsersQuestionsPanelWrapper.current) {
                return;
            }
            let parent = target;
            while (
                parent !== document.body &&
                parent !== document.body.parentElement
            ) {
                if (
                    !parent ||
                    (parent.classList &&
                        parent.classList.contains('--right-panel-wrapper')) ||
                    parent.classList.contains('popups-container') ||
                    parent.id === 'hero-popup-layer'
                ) {
                    return;
                }
                parent = parent.parentElement;
            }
            if (visible_.current) hideRightUsersQuestionsPanel();
        };
        document.addEventListener('mousedown', clickListener);
        return () => {
            document.removeEventListener('mousedown', clickListener);
            window.removeEventListener('mouseup', listener);
        };
    }, []);

    useEffect(() => {
        if (brCtx.recordsets && addRecordWasTriggered.state) {
            brCtx.setBasic({
                active_cell: {
                    cellidx: null,
                    e: null,
                    fieldidx: null,
                    recordid: addRecordWasTriggered.recordId,
                    recordsetidx: 0,
                    is_calendar: false /** false */,
                },
            });
            settingCtx.setRightPanel({
                visible: true,
                active: RightPanelTabs.Recordview,
                force_view_style: rightPanelViewStyles.POPUP
            });
            setAddRecordWasTriggered({ state: false, recordId: null });
        }
    }, [brCtx.recordsets, addRecordWasTriggered]);

    const hideRightUsersQuestionsPanel = () => {
        settingCtx.setBasic({
            right_users_questions_panel: {
                visible: false,
            },
        });
    };

    const remove = async (id) => {
        let res = await dbCtx.deleteRecord({
            recordsetuid: dbCtx.recordsets[recordsetsIdx.InboxsRecordset].uid,
            uid: id,
        });
        setUserQuestions((data) => {
            data.filter((elm) => elm.id !== id);
        });
    };

    const addQuestion = async (id, question) => {
        let faqTileId;
        let m = 1;
        for (let i = 0; m > 0 && i < dbCtx.fieldsets.length; i++) {
            for (
                let n = 0;
                m > 0 && n < dbCtx.fieldsets[i].fields.length;
                n++
            ) {
                let field = dbCtx.fieldsets[i].fields[n];
                if (field.label === 'FAQ') {
                    faqTileId = field.uid;
                    m--;
                    break;
                }
            }
        }
        let recordValues = {};
        recordValues[faqTileId] = question;
        try {
            setAddingQuestion(true);
            let res = await dbCtx.addRecord({
                aliasuid: dbCtx.database.aliasuid,
                dbId: dbCtx.database.uid,
                recordsetuid: dbCtx.recordsets[recordsetsIdx.FAQsRecordset].uid,
                attributes: null,
                values: recordValues,
                noReload: false,
            });
            if (res.status === 201 && res.data.record) {
                remove(id);
                setAddRecordWasTriggered({
                    state: true,
                    recordId: res.data.record.uid,
                });
                hideRightUsersQuestionsPanel();
            } else if (res.status === 400) {
                if (res.data.messageCode === 'LIMIT_REACHED') {
                    settingCtx.setUpgradeProjectDialog({
                        visible: true,
                        project_id: dbCtx.database.uid,
                        msg: 'You are currently on a free project, upgrade it and get unlimited Questions!',
                    });
                    /*alert(
                        'Upgrade to the PRO plan to be able to add more than 8 FAQs!'
                    );*/
                }
            } else {
                showError('Error creating a new FAQ, please try again!');
            }
        } catch (err) {
            showError('Error adding FAQ, please try again!');
        }
        setAddingQuestion(false);
    };

    const removeAll = () => {
        if (removeAllState === 0) {
            setRemoveAllState(1);
            return;
        }
        dbCtx.deleteRecords({
            recordsetuid: dbCtx.recordsets[recordsetsIdx.InboxsRecordset].uid,
            uids: dbCtx.recordsets[recordsetsIdx.InboxsRecordset].records.map(
                (record) => record.uid
            ),
        });
        let updatedRecordSets = dbCtx.recordsets.slice(0);
        updatedRecordSets[recordsetsIdx.InboxsRecordset] = {
            ...updatedRecordSets[recordsetsIdx.InboxsRecordset],
            records: [],
        };
        dbCtx.setBasic({
            recordsets: updatedRecordSets.slice(0),
        });
        setUserQuestions([]);
        hideRightUsersQuestionsPanel();
    };

    return (
        <>
            {/*settingCtx.right_panel.visible && <div className={'right-panel-bg'} onClick={hideRightPanel}></div>*/}
            <Resizable
                ref={rightUsersQuestionsPanelWrapper}
                defaultWidth={400}
                defaultHeight={'100%'}
                height={'100%'}
                directions={{
                    left: true,
                }}
                className={
                    '--right-panel-wrapper' +
                    (visible ? ' --visible' : ' --no-visible')
                }>
                <div className="panel-right">
                    <div className={'--inner scroll-container'}>
                        <div className="--section --align-start --fixed-to-top">
                            <div className="--tabs-container">
                                <Button onClick={removeAll}>
                                    <Symbol
                                        name="delete_alt1"
                                        className={'mr-4'}
                                        color={
                                            removeAllState === 1
                                                ? '#f00'
                                                : undefined
                                        }
                                    />
                                    <Text
                                        style={
                                            removeAllState === 1
                                                ? { color: '#f00' }
                                                : {}
                                        }
                                        value={
                                            removeAllState === 1
                                                ? 'Are you sure?'
                                                : 'Remove All'
                                        }
                                    />
                                </Button>
                                <Button
                                    className={'--tab'}
                                    type={'icon'}
                                    style={{
                                        marginLeft: 'auto',
                                        marginTop: '-8px',
                                    }}
                                    onClick={hideRightUsersQuestionsPanel}>
                                    <Symbol name={'close'} />
                                </Button>
                            </div>
                        </div>

                        <div className="--section --align-end">
                            {brCtx.fieldsets && brCtx.fieldsets[3] && (
                                <div className="users-questions-container">
                                    {(userQuestions || []).map((elm, i) => {
                                        if (!elm) {
                                            return null;
                                        }
                                        return (
                                            <UserQuestion
                                                key={i}
                                                question={elm.question}
                                                date={elm.date}
                                                email={elm.email}
                                                remove={remove}
                                                addQuestion={addQuestion}
                                                rid={elm.id}
                                            />
                                        );
                                    })}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </Resizable>
            {addingQuestion &&
                createPortal(
                    <div
                        style={{
                            position: 'fixed',
                            width: '100%',
                            height: '100%',
                            background: 'rgba(0,0,0,.2)',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            zIndex: 999999999,
                            top: '0',
                            left: '0',
                        }}>
                        <Text
                            style={{ color: '#555', fontSize: '16px' }}
                            value={'Adding new FAQ...'}
                        />
                    </div>,
                    document.body
                )}
        </>
    );
};

const UserQuestion = ({ question, email, date, rid, remove, addQuestion }) => {
    const [isActive, setIsActive] = useState(false);
    const [removeState, setRemoveState] = useState(0);

    useEffect(() => {
        setRemoveState(0);
    }, [isActive]);

    const confirmRemove = () => {
        if (removeState === 0) {
            setRemoveState(1);
            return;
        }
        setIsActive(false);
        remove(rid);
    };

    return (
        <div
            className="user-question"
            onClick={(e) => {
                let target = e.target;
                if (
                    document.getSelection().toString().length === 0 &&
                    ((target.classList || []).contains('user-question') ||
                        (target.classList || []).contains('--date') ||
                        (target.classList || []).contains('--question'))
                ) {
                    addQuestion(rid, question);
                }
            }}>
            <div className="--date">{date}</div>
            <div className="--question">{question}</div>
            <PopupMenu
                className={'popout-box'}
                offsetX={0}
                offsetY={0}
                nested={true}
                open={isActive}
                onClose={() => {
                    setIsActive(false);
                }}
                onOpen={() => {
                    setIsActive(true);
                }}
                trigger={
                    <div className="--options">
                        <Symbol name="options_menu" color={'#4D4D4D'} />
                    </div>
                }
                contentStyle={{
                    width: 200,
                }}
                position={PositionTypes.BottomRight}>
                <div
                    className="user-suestion-options-item"
                    onClick={() => {
                        confirmRemove();
                    }}>
                    <span className="icon">
                        <Symbol
                            name="delete_alt1"
                            color={removeState === 0 ? '#6E6E6E' : '#f00'}
                            className={'icon'}
                        />
                    </span>
                    <Text
                        style={removeState === 1 ? { color: '#f00' } : {}}
                        value={removeState === 1 ? 'Are you sure?' : 'Remove'}
                    />
                </div>
                {/*<div className='user-suestion-options-item'>
                    <span className='icon'><Symbol color={'#6E6E6E'} className={'icon'} /></span>
                    <Text value={'Private Reply'} />
                </div>*/}
                <div
                    className="user-suestion-options-item"
                    onClick={() => {
                        setIsActive(false);
                        addQuestion(rid, question);
                    }}>
                    <span className="icon">
                        <Symbol
                            name="add"
                            color={'#6E6E6E'}
                            className={'icon'}
                        />
                    </span>
                    <Text value={'Answer'} />
                </div>
            </PopupMenu>
        </div>
    );
};
