import React, { useEffect } from 'react';
import useSetting from '../../hooks/useSetting';
import BareDatabaseLayout from '../../layouts/barelayout/main';
import { ProjectSettingsPanel } from '../projectSettingsPanel/ProjectSettingsPanel';
import { UsersQuestionsPanel } from '../right/usersQuestionsPanel/usersQuestionsPanel';
import { AccountOptionsPanel } from '../right/accountOptionsPanel/accountOptionsPanel';
import { AddRecordBtn } from '../../components/add-record-button/AddRecordBtn';
import FaqEditorPanel from '../right/editFaqPanel/FaqEditorPanel';
import useDatabase from '../../hooks/useDatabase';
import { recordsetsIdx } from '../../layouts/barelayout/ctx';
import useQuestionsNotifs from '../../hooks/useQuestionsNotifs';
import RightPanel from '../right/main'

const FieldTypes = {
    string: 0,
    integer: 1,
    date: 2,
    select: 3,
};

const fieldsets = [
    /** basic */
    {
        uid: 'e3443',
        fields: [
            {
                uid: 'wed43f43',
                label: 'Name',
                type: FieldTypes.string,
                attributes: null,
            },
            {
                uid: 'wed43f43',
                label: 'Status',
                type: FieldTypes.select,
                attributes: null,
            },
        ],
        attributes: null,
    },

    /** ranks */
    {
        uid: 'e344e3',
        fields: [
            {
                uid: 'wed43f43',
                label: 'In Study',
                type: FieldTypes.integer,
                attributes: null,
            },
            {
                uid: 'wed43f43',
                label: 'In Work',
                type: FieldTypes.integer,
                attributes: null,
            },
        ],
        attributes: null,
    },
];

export default function DatabaseviewFullSubBodyPanel() {
    // const settingCtx = useSetting();
    // const [width, setWidth] = useState(0);
    // const [visible, setVisible] = useState(false);

    // useEffect(() => {
    //     setVisible(visible);
    // }, [settingCtx.right_panel.visible]);

    // const onResize = (e) => {
    //     // setWidth(e.width);
    //     // console.log(e)
    // }

    return (
        <>
            <div
                className="--subbody-wrapper"
                style={{
                    borderTopLeftRadius: `${
                        /*settingCtx.left_panel.visible ? 8 : */ 0
                    }px`,
                    position: 'relative',
                }}>
                <div
                    className="--table-wrapper scroll-container"
                    style={
                        {
                            // width: `calc(100% - ${visible ? width+'px' : '0px'})`
                        }
                    }>
                    <BareDatabaseLayout database_full={true} />
                </div>

                {/*right panels*/}

                <FaqEditorPanel />

                {/*<RightPanel />*/}
                <ProjectSettingsPanel />
                <UsersQuestionsPanel />
                <AccountOptionsPanel />
                <AddRecordBtn />
                <QuestionCountUpdater />
            </div>
        </>
    );
}

function QuestionCountUpdater() {
    const dbCtx = useDatabase();
    const dbQuestionsCtx = useQuestionsNotifs();

    useEffect(() => {
        if (dbCtx.loading) {
            return;
        }
        if (dbCtx.database && dbCtx.database.uid) {
            const dbId = dbCtx.database.uid;
            const dbQuestionsCount =
                (((dbCtx.recordsets || [])[recordsetsIdx.InboxsRecordset] || {}).records || []).length;
                
            if (
                dbQuestionsCount !==
                dbQuestionsCtx.state.databaseQuestionsCount[dbId]
            ) {
                dbQuestionsCtx.methods.updateDatabaseQuestionsCount({
                    databaseId: dbId,
                    newQuestionsCount: dbQuestionsCount,
                });
            }
        }
    }, [dbCtx]);
    return <></>;
}
