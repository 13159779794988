export const AddIcon = ({ className = null, color = undefined }) => {
    return (
        <svg
            className={'ui-sym path' + (className ? ' ' + className : '')}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M19 13H13V19H11V13H5V11H11V5H13V11H19V13Z"
                style={{ fill: color }}
                className="app-icon-fill"
            />
        </svg>
    );
};

export const AddIconCircle = ({ className = null, color = undefined }) => {
    return (
        <svg
            className={'ui-sym path' + (className ? ' ' + className : '')}
            width="25"
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <circle
                cx="12.2519"
                cy="12.9169"
                r="8.7372"
                style={{ fill: color }}
                className="app-icon-fill-2"
            />
            <path
                d="M11.2503 17.9275H13.2547V13.9187H17.2635V11.9143H13.2547V7.90553H11.2503V11.9143H7.24147V13.9187H11.2503V17.9275ZM12.2525 22.9385C10.8661 22.9385 9.56324 22.6755 8.34389 22.1493C7.12455 21.6232 6.06389 20.9091 5.16191 20.0071C4.25992 19.1051 3.54586 18.0445 3.0197 16.8251C2.49355 15.6058 2.23047 14.3029 2.23047 12.9165C2.23047 11.5302 2.49355 10.2273 3.0197 9.00795C3.54586 7.78861 4.25992 6.72795 5.16191 5.82597C6.06389 4.92399 7.12455 4.20992 8.34389 3.68376C9.56324 3.15761 10.8661 2.89453 12.2525 2.89453C13.6389 2.89453 14.9417 3.15761 16.1611 3.68376C17.3804 4.20992 18.4411 4.92399 19.343 5.82597C20.245 6.72795 20.9591 7.78861 21.4852 9.00795C22.0114 10.2273 22.2745 11.5302 22.2745 12.9165C22.2745 14.3029 22.0114 15.6058 21.4852 16.8251C20.9591 18.0445 20.245 19.1051 19.343 20.0071C18.4411 20.9091 17.3804 21.6232 16.1611 22.1493C14.9417 22.6755 13.6389 22.9385 12.2525 22.9385ZM12.2525 20.9341C14.4907 20.9341 16.3866 20.1574 17.94 18.604C19.4934 17.0506 20.2701 15.1548 20.2701 12.9165C20.2701 10.6783 19.4934 8.78246 17.94 7.22905C16.3866 5.67564 14.4907 4.89893 12.2525 4.89893C10.0142 4.89893 8.1184 5.67564 6.56499 7.22905C5.01158 8.78246 4.23487 10.6783 4.23487 12.9165C4.23487 15.1548 5.01158 17.0506 6.56499 18.604C8.1184 20.1574 10.0142 20.9341 12.2525 20.9341Z"
                style={{ fill: color }}
                className="app-icon-fill"
            />
        </svg>
    );
};
