import { useEffect, useRef, useState } from 'react';
import './authentication-page.scss';
import faqnationLogo from '../../assets/images/lg-header-white.svg';
import Button from '../../components/Button';
import { Symbol } from '../../components/elements/Icon';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import { callApi } from '../../functions/api';
import { apiRoutes } from '../../helpers/api';
import { SelectPlan } from '../../components/Popup/Plans';
import PopupMenu from '../../components/Popup';
import { Text } from '../../components/Text';
import Account from '../../functions/Account';
import { UseLicenseKey } from '../../components/use-license-key-dialog/UseLicenseKeyDialog';
import { Toasts, showError } from '../../components/towsts/Toasts';

const pageActions = {
    LOGIN: 0,
    SIGNUP: 1,
};

export function AuthenticationPage() {
    const authCtx = useAuth();
    const pageLocation = useLocation();

    const [pageAction, setPageAction] = useState(pageActions.LOGIN);

    const [waitingForSubscriptionURL, setWaitingForSubscriptionURL] =
        useState(false);
    const [waitingForProjectGeneration, setWaitingForProjectGeneration] =
        useState(false);
    const [isNewUser, setIsNewUser] = useState(false);

    const [notification, setNotification] = useState({
        visible: false,
        msg: '',
        error: false,
    });

    useEffect(() => {
        let pathname = pageLocation.pathname;
        if (pathname === '/signup') {
            document.title = 'FAQ Nation - create a new account';
            setPageAction(pageActions.SIGNUP);
        } else {
            document.title = 'FAQ Nation - login';
            setPageAction(pageAction.LOGIN);
        }
    }, [pageLocation]);

    async function getSelectedPlanSubscriptionLink(planId) {
        return callApi({
            method: 'post',
            path: apiRoutes.plans.getSubscribeLink,
            payload: {
                planId: planId,
            },
        });
    }
    return (
        <>
            {waitingForSubscriptionURL || waitingForProjectGeneration ? (
                <Button
                    style={{
                        position: 'fixed',
                        top: '0',
                        left: '0',
                        right: '0',
                        bottom: '0',
                        margin: 'auto',
                    }}
                    loading={true}
                    disabled={true}></Button>
            ) : !authCtx.initialized ? (
                <></>
            ) : authCtx.authenticated ? (
                <CreateNewProject authCtx={authCtx} newUser={isNewUser} />
            ) : (
                <main className="auth_page">
                    <FAQNationLogo />

                    {pageAction === pageActions.SIGNUP ? (
                        <SignupFormContent
                            setNotification={setNotification}
                            setIsNewUser={setIsNewUser}
                        />
                    ) : (
                        <LoginFormContent setNotification={setNotification} />
                    )}

                    {notification.visible && (
                        <ErrorSendResetPasswordEmail
                            close={() => {
                                setNotification({
                                    visible: false,
                                    msg: '',
                                    error: false,
                                });
                            }}
                            msg={notification.msg}
                        />
                    )}
                </main>
            )}
            <Toasts />
        </>
    );
}

export function LoginFormContent({ setNotification = () => {} }) {
    const emailInput = useRef(null);
    const passwordInput = useRef(null);
    const wrongPasswordRef = useRef(false);

    const authCtx = useAuth();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [forgetPassword, setForgetPassword] = useState(false);
    const [wrongPassword, setWrongPassword] = useState(false);
    const [rememberMe, setRememberMe] = useState(false);
    const [waiting, setWaiting] = useState(false);

    useEffect(() => {
        try {
            if (sessionStorage.getItem('user_email')) {
                setEmail(sessionStorage.getItem('user_email'));
            }
        } catch (err) {}
        try {
            sessionStorage.removeItem('faqn_new_user');
        } catch (err) {}
    }, []);

    useEffect(() => {
        wrongPasswordRef.current = wrongPassword;
    }, [wrongPassword]);

    async function handleFormSubmit(evnt) {
        evnt.preventDefault();
        setWaiting(true);
        try {
            const signupResult = await authCtx.login({
                email: email,
                password: password,
                rememberMe: rememberMe,
            });
            if ((signupResult || {})['error']) {
                if (signupResult.status === 404) {
                    showError('Incorrect Email or password, please try again!');
                    setWrongPassword(true);
                } else {
                    showError('Error login, please try again!');
                }
            }
        } catch (err) {
            showError('Error login, please try again!');
        }
        setWaiting(false);
    }

    return (
        <>
            {forgetPassword ? (
                <ForgetPasswordForm setNotification={setNotification} />
            ) : (
                <>
                    <form onSubmit={handleFormSubmit} className="login_form">
                        <div>
                            <Input
                                ref={emailInput}
                                value={email}
                                onChange={(evnt) => {
                                    setEmail(evnt.target.value);
                                }}
                                type="email"
                                autoComplete={'email'}
                                autocapitalize={'none'}
                                spellcheck={'false'}
                                placeholder_="Email"
                                autoFocus={true}
                                required
                                onErrorMsg={undefined}
                            />
                        </div>
                        <div>
                            <Input
                                ref={passwordInput}
                                value={password}
                                onChange={(evnt) => {
                                    if (wrongPasswordRef) {
                                        setWrongPassword(false);
                                    }
                                    setPassword(evnt.target.value);
                                }}
                                type="password"
                                placeholder_="Password"
                                required
                                onErrorMsg={
                                    wrongPassword ? (
                                        <span>{'Wrong password'}</span>
                                    ) : undefined
                                }
                            />
                        </div>
                        <div className="login_options">
                            <span>
                                <input
                                    className="checkbox-input"
                                    id="remember-me"
                                    type={'checkbox'}
                                    value={rememberMe}
                                    onChange={(evnt) => {
                                        setRememberMe(evnt.target.checked);
                                    }}
                                />
                                <label
                                    className="checkbox-label"
                                    htmlFor={'remember-me'}>
                                    Remember me
                                </label>
                            </span>
                            <span
                                role="button"
                                className="forget_password_action"
                                onClick={(evnt) => {
                                    setForgetPassword(true);
                                }}>
                                Forgot password?
                            </span>
                        </div>
                        <Button color="primary" size="full" loading={waiting}>
                            {'Log in'}
                        </Button>
                    </form>
                    {/*<div className="form_extra_info_container">*/}
                    {/*    <p>*/}
                    {/*        {"Don't have an account? "}*/}
                    {/*        <Link to="/signup">{'Sign up'}</Link>*/}
                    {/*    </p>*/}
                    {/*</div>*/}
                </>
            )}
        </>
    );
}
export function SignupFormContent({
    setNotification = () => {},
    setIsNewUser = () => {},
}) {
    const emailInput = useRef(null);
    const passwordInput = useRef(null);
    const confirmNewPasswordInput = useRef(null);

    const authCtx = useAuth();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [waiting, setWaiting] = useState(false);

    const passwordsNotMatch =
        confirmNewPassword.length > 0 && password !== confirmNewPassword;

    useEffect(() => {
        try {
            sessionStorage.setItem('faqn_new_user', 'true');
        } catch (err) {}
    }, []);

    async function handleFormSubmit(evnt) {
        evnt.preventDefault();
        try {
            setWaiting(true);
            setIsNewUser(true);
            const signupResult = await authCtx.signup({
                email: email,
                password: password,
            });
            if ((signupResult || {})['error']) {
                if (signupResult.error_msg === 'email_already_exists') {
                    showError('Account already exists!');
                } else {
                    showError('Error signup, please try again');
                }
                setWaiting(false);
            }
            return;
        } catch (err) {
            showError('Something went wrong, please try again');
            setWaiting(false);
        }
    }

    return (
        <>
            <form onSubmit={handleFormSubmit} className="signup_form">
                <div>
                    <Input
                        ref={emailInput}
                        value={email}
                        onChange={(evnt) => {
                            setEmail(evnt.target.value);
                        }}
                        type="email"
                        placeholder_="Email"
                        autoComplete={'off'}
                        autocapitalize={'none'}
                        spellcheck={'false'}
                        autoFocus={true}
                        required
                        onErrorMsg={undefined}
                    />
                </div>
                <div>
                    <Input
                        ref={passwordInput}
                        value={password}
                        onChange={(evnt) => {
                            setPassword(evnt.target.value);
                        }}
                        autoComplete="new-password"
                        type="password"
                        placeholder_="Password"
                        autocapitalize={'none'}
                        spellcheck={'false'}
                        required
                        onErrorMsg={
                            password.length < 8 && password.length > 0 ? (
                                <></>
                            ) : undefined
                        }
                        extraInfo={
                            password.length > 0 ? (
                                <PasswordStrengthIndicator
                                    password={password}
                                />
                            ) : undefined
                        }
                    />
                </div>
                <div>
                    <Input
                        ref={confirmNewPasswordInput}
                        value={confirmNewPassword}
                        onChange={(evnt) => {
                            setConfirmNewPassword(evnt.target.value);
                        }}
                        type="password"
                        placeholder_="Confirm Password"
                        autocapitalize={'none'}
                        spellcheck={'false'}
                        required
                        onErrorMsg={
                            passwordsNotMatch ? (
                                <span>{"Passwords doesn't match!"}</span>
                            ) : undefined
                        }
                    />
                </div>
                <Button color="primary" size="full" loading={waiting}>
                    {'Sign up'}
                </Button>
            </form>
            <div className="form_extra_info_container">
                <p>
                    {'Already have an account? '}
                    <Link to="/login">{'Log in'}</Link>
                </p>
            </div>
        </>
    );
}

function ForgetPasswordForm({ setNotification = () => {} }) {
    const emailInput = useRef(null);

    const authCtx = useAuth();

    const [email, setEmail] = useState('');
    const [emailSent, setEmailSent] = useState(false);
    const [waiting, setWaiting] = useState(false);

    async function handleFormSubmit(evnt) {
        evnt.preventDefault();
        setWaiting(true);
        let msg = (
            <span>
                {'Error sending email to '}
                <b>{email}</b>
                {' Please try again!'}
            </span>
        );
        try {
            const resetPasswordResponse =
                await authCtx.sendResetPasswordRequest({
                    email: email,
                });
            if (resetPasswordResponse.err) {
                switch (resetPasswordResponse.msg) {
                    case 'no_account_found':
                        msg = (
                            <span>
                                {'No account found with this email '}
                                <b>{email}</b>
                                <br />
                                {'Please check your email and try again'}
                            </span>
                        );
                        break;
                    case 'limit_reached':
                        msg = `You have exceeded the limit of tries, you can try after 24 hours`;
                        break;
                    default:
                        break;
                }
                setNotification({ visible: true, msg: msg, error: true });
            } else {
                setEmailSent(true);
            }
        } catch (err) {
            setNotification({ visible: true, msg: msg, error: true });
        }
        setWaiting(false);
    }

    if (emailSent) {
        return (
            <div className="reset_password_info_container">
                {waiting ? (
                    <Button loading={waiting}></Button>
                ) : (
                    <>
                        <p>
                            We have sent you a confirmation link, check your
                            mailbox
                        </p>
                        <br />
                        <p>
                            <strong>{email}</strong>
                        </p>
                    </>
                )}
            </div>
        );
    }

    return (
        <>
            <form onSubmit={handleFormSubmit} className="login_form">
                <div>
                    <Input
                        ref={emailInput}
                        value={email}
                        onChange={(evnt) => {
                            setEmail(evnt.target.value);
                        }}
                        type="email"
                        placeholder_="Email"
                        autoComplete={'email'}
                        autocapitalize={'none'}
                        spellcheck={'false'}
                        autoFocus={true}
                        required
                        onErrorMsg={undefined}
                    />
                </div>
                <Button color="primary" size="full" loading={waiting}>
                    {'Reset Password'}
                </Button>
            </form>
            <div className="form_extra_info_container">
                <p>
                    {'Back to '}
                    <Link to="/login">{'Login'}</Link>
                    {' or '}
                    <Link to="/signup">{'create a new account'}</Link>
                </p>
            </div>
        </>
    );
}

export function ResetPasswordPage() {
    const authCtx = useAuth();

    const newPasswordInput = useRef(null);
    const confirmNewPasswordInput = useRef(null);

    const navigate = useNavigate();
    const params = useParams();
    const [initialized, setInitialized] = useState(false);
    const [waiting, setWaiting] = useState(false);
    const [requestKey, setRequestKey] = useState(false);
    const [confirmKey, setConfirmKey] = useState(false);
    const [email, setEmail] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');

    const passwordsNotMatch =
        confirmNewPassword.length > 0 && newPassword !== confirmNewPassword;
    const weekPassword =
        newPassword.length > 0
            ? validatePassword(newPassword)
            : { week: false, msg: undefined };

    useEffect(() => {
        let key = params.key;
        if (!key) {
            navigate('/home');
            return;
        }
        setRequestKey(key);
    }, [params]);

    useEffect(() => {
        (async () => {
            try {
                if (requestKey) {
                    const checkResetPasswordResponse =
                        await authCtx.confirmResetPasswordRequest({
                            key: requestKey,
                        });
                    if (checkResetPasswordResponse.err) {
                        if (
                            checkResetPasswordResponse.msg === 'request_expired'
                        ) {
                            showError('Request expired!');
                        } else {
                            showError(
                                'Error Invalid reset password link\n' +
                                    document.location.href
                            );
                        }
                        navigate('/home');
                        return;
                    }
                    setConfirmKey(checkResetPasswordResponse.key);
                    setEmail(checkResetPasswordResponse.email);
                    setInitialized(true);
                }
            } catch (err) {}
        })();
    }, [requestKey]);

    async function handleFormSubmit(evnt) {
        evnt.preventDefault();
        if (validatePassword.week || passwordsNotMatch) {
            return;
        }
        setWaiting(true);
        try {
            const resetPasswordResponse = await authCtx.resetPassword({
                confirmKey: confirmKey,
                newPassword: newPassword,
            });
            if (resetPasswordResponse.err) {
                if (resetPasswordResponse.msg === 'request_expired') {
                    showError(
                        'Request expired!\nPlease request a new password reset'
                    );
                    throw new Error('Error reset password request expired');
                }
                showError('Error reset password\nPlease try again!');
                throw new Error('Error reset password');
            } else {
                sessionStorage.setItem('user_email', email);
            }
        } catch (error) {}
        navigate('/login');
        setWaiting(false);
    }

    return (
        <main className="auth_page">
            <FAQNationLogo />
            {!initialized ? (
                <div className="reset_password_info_container">
                    <Button loading={true}></Button>
                </div>
            ) : (
                <form onSubmit={handleFormSubmit} className="login_form">
                    <div>
                        <Input
                            ref={newPasswordInput}
                            value={newPassword}
                            onChange={(evnt) => {
                                setNewPassword(evnt.target.value);
                            }}
                            type="password"
                            placeholder_="Password"
                            autocapitalize={'none'}
                            spellcheck={'false'}
                            required
                            onErrorMsg={
                                newPassword.length < 8 &&
                                newPassword.length > 0 ? (
                                    <></>
                                ) : undefined
                            }
                            extraInfo={
                                newPassword.length > 0 ? (
                                    <PasswordStrengthIndicator
                                        password={newPassword}
                                    />
                                ) : undefined
                            }
                        />
                    </div>
                    <div>
                        <Input
                            ref={confirmNewPasswordInput}
                            value={confirmNewPassword}
                            onChange={(evnt) => {
                                setConfirmNewPassword(evnt.target.value);
                            }}
                            type="password"
                            placeholder_="Confirm Password"
                            autocapitalize={'none'}
                            spellcheck={'false'}
                            required
                            onErrorMsg={
                                passwordsNotMatch ? (
                                    <span>{"Passwords doesn't match!"}</span>
                                ) : undefined
                            }
                        />
                    </div>
                    <Button color="primary" size="full" loading={waiting}>
                        {'Reset Password'}
                    </Button>
                </form>
            )}
        </main>
    );
}

function CreateNewProject({ authCtx }) {
    const nOfTries = useRef(1);
    const navigate = useNavigate();
    const [initialized, setInitialized] = useState(false);
    const [isNewUser, setIsNewUser] = useState(false);
    const [loading, setLoading] = useState(false);
    const [availableProjects, setAvailableProjects] = useState({
        payed: -1,
        free: -1,
    });
    const [addProjectDialogVisible, setAddProjectDialogVisible] =
        useState(false);
    const [userExtraInfoSaved, setUserExtraInfoSaved] = useState(false);
    const [useLicenseKey, setUseLicenseKey] = useState(false);

    useEffect(() => {
        try {
            let planId = sessionStorage.getItem('faqn_selected_plan');
            let projectDescription = sessionStorage.getItem(
                'generate_with_ai_description'
            );
            let newUser = sessionStorage.getItem('faqn_new_user') === 'true';
            if (!planId && !projectDescription && !newUser) {
                navigate('/');
                return;
            }
            setIsNewUser(true);
            setInitialized(true);
        } catch (err) {}
    }, []);

    useEffect(() => {
        if (!authCtx.initialized || !authCtx.authenticated) {
            return;
        }
        let isUserExtraInfoSaved;
        try {
            isUserExtraInfoSaved =
                sessionStorage.getItem('faqnation_user_extra_info_saved') ===
                'true';
        } catch (err) {}
        if (isUserExtraInfoSaved) {
            setUserExtraInfoSaved(true);
        } else {
            setUserExtraInfoSaved(!!authCtx.account.survey);
        }

        (async () => {
            if ((authCtx.aliases || []).length === 0) {
                document.location.reload();
                return;
            }
            let res = await callApi({
                method: 'get',
                path: apiRoutes.plans.getUserLimitations,
            });
            if (res.status === 200 && res.data) {
                setAvailableProjects({
                    payed: res.data['limits']['paidProjectLeft'],
                    free: res.data['limits']['freeProjectLeft'],
                });
                try {
                    let planId = sessionStorage.getItem('faqn_selected_plan');
                    sessionStorage.removeItem('faqn_selected_plan');
                    if (planId) {
                        selectPlan(planId);
                    }
                } catch (err) {}
            }
        })();
    }, [authCtx]);

    useEffect(() => {
        if (addProjectDialogVisible || availableProjects.payed > 0) {
            try {
                localStorage.setItem('faqnation_last_open_prj_id', 'no_db');
                localStorage.setItem('faqnation_create_new_project', 'true');
            } catch (err) {}
            navigate('/');
        }
    }, [addProjectDialogVisible, availableProjects]);

    async function selectPlan(planId) {
        if (planId === 'free') {
            addNewProject();
            return;
        }
        setLoading(true);
        try {
            let res = await callApi({
                path: apiRoutes.plans.getSubscribeLink,
                method: 'post',
                payload: { planId: planId },
            });
            if (res.status !== 200) {
                if (res.status === 403 && nOfTries.current < 3) {
                    nOfTries.current += 1;
                    selectPlan(planId);
                }
                throw new Error('Unknown Error');
            }
            try {
                localStorage.setItem('faqnation_redirect_to', '/signup');
            } catch (err) {}
            document.location.href = res.data.checkoutUrl;
        } catch (err) {
            showError('Error, please try again!');
        }
        setTimeout(() => {
            try {
                setLoading(false);
            } catch (err) {}
        }, 1000);
    }

    function addNewProject() {
        setAddProjectDialogVisible(true);
    }

    if (availableProjects.payed === -1 || loading || !initialized) {
        return <Loading />;
    }

    if (isNewUser && !userExtraInfoSaved) {
        return (
            <UserExtraInfoForm setUserExtraInfoSaved={setUserExtraInfoSaved} />
        );
    }

    if (availableProjects.payed === 0) {
        return (
            <>
                <main className="auth_page_select_plan_container">
                    <h1 className="auth_page_select_plan_title">
                        {'Choice a plan'}
                    </h1>
                    <div>
                        <SelectPlan
                            theme={'light'}
                            action={selectPlan}
                            freePlanActionLable="Continue"
                            oneProjectLable="Purchase 1 Project"
                            threeProjectLable="Purchase 3 Project"
                            unlimitedProjectsLable="Purchase Limitless"
                            noFreeOption={availableProjects.free === 0}
                        />
                    </div>
                    <div style={{ textAlign: 'center' }}>
                        <a
                            href="#l-k"
                            title="yf"
                            onClick={(evnt) => {
                                evnt.preventDefault();
                                setUseLicenseKey(true);
                            }}
                            className="auth_page_select_plan_use_key">
                            {'You have a license key?'}
                        </a>
                    </div>
                </main>
                {useLicenseKey && (
                    <UseLicenseKey
                        onCancel={() => {
                            setUseLicenseKey(false);
                        }}
                        onSuccess={() => {
                            try {
                                localStorage.setItem(
                                    'faqnation_last_open_prj_id',
                                    'no_db'
                                );
                                localStorage.setItem(
                                    'faqnation_create_new_project',
                                    'true'
                                );
                            } catch (err) {}
                            navigate('/');
                        }}
                    />
                )}
            </>
        );
    }

    return (
        <>
            <main></main>
        </>
    );
}

function UserExtraInfoForm({ setUserExtraInfoSaved = (v) => {} }) {
    const [name, setName] = useState('');
    const [goal, setGoal] = useState('');
    const [profession, setProfession] = useState('');
    const [howYouFindUs, setHowYouFindUs] = useState('');
    const [savingData, setSavingData] = useState(false);

    async function saveData() {
        setSavingData(true);
        const res = await Account.updateAccount({
            survey: JSON.stringify({
                name: name,
                goal: goal,
                profession: profession,
                howYouFindUs: howYouFindUs,
            }),
        });
        if (res.status === 200) {
            setUserExtraInfoSaved(true);
        }
        try {
            sessionStorage.setItem('faqnation_user_extra_info_saved', 'true');
        } catch (err) {}
        setSavingData(false);
    }

    return (
        <PopupMenu open={true} closeOnDocumentClick={false}>
            <form
                onSubmit={(evnt) => {
                    evnt.preventDefault();
                    saveData();
                }}
                style={{ maxWidth: '400px' }}
                className="--popup-content">
                <label className="t-ext" htmlFor="labelForNameInput">
                    Your name
                </label>
                <Input
                    id={'labelForNameInput'}
                    onChange={(evnt) => {
                        setName(evnt.target.value);
                    }}
                    value={name}
                    placeholder={'John Smith'}
                    autocapitalize={'none'}
                    spellcheck={'false'}
                    theme={'light'}
                />
                <label className="t-ext" htmlFor="labelForGoalInput">
                    Your main goal with FAQ Nation
                </label>
                <Input
                    id={'labelForGoalInput'}
                    onChange={(evnt) => {
                        setGoal(evnt.target.value);
                    }}
                    value={goal}
                    autocapitalize={'none'}
                    spellcheck={'false'}
                    placeholder={'Offer support, FAQ for landingpage '}
                    theme={'light'}
                />
                <label className="t-ext" htmlFor="labelForProfessionInput">
                    What is your profession
                </label>
                <Input
                    id={'labelForProfessionInput'}
                    onChange={(evnt) => {
                        setProfession(evnt.target.value);
                    }}
                    value={profession}
                    autocapitalize={'none'}
                    spellcheck={'false'}
                    placeholder={'Entrepreneur, Developer, Designer,'}
                    theme={'light'}
                />
                <label className="t-ext" htmlFor="labelForHowYouFindUsInput">
                    How you heard about FAQ Nation
                </label>
                <Input
                    id={'labelForHowYouFindUsInput'}
                    onChange={(evnt) => {
                        setHowYouFindUs(evnt.target.value);
                    }}
                    value={howYouFindUs}
                    autocapitalize={'none'}
                    spellcheck={'false'}
                    placeholder={'X, Reddit, Google, Product Hunt'}
                    theme={'light'}
                />
            </form>
            <div className="--popup-actions">
                <Button
                    loading={savingData}
                    onClick={saveData}
                    style={{ minWidth: '120px' }}
                    type={'primary'}>
                    <Text value={"Let's start"} />
                </Button>
            </div>
        </PopupMenu>
    );
}

function Input({
    onErrorMsg,
    extraInfo,
    placeholder_ /**special placeholder */,
    theme = 'dark',
    ...props
}) {
    return (
        <div
            className={`input_elm_container ${
                onErrorMsg ? 'invalid_input' : ''
            } ${theme === 'light' ? 'input_light' : ''}`}>
            <input {...props} />
            <span
                className={`input-placeholder ${
                    (props.value || '').length > 0 ? 'not-empty' : ''
                }`}>
                {placeholder_}
            </span>
            {onErrorMsg && (
                <div className="error_msg_container">{onErrorMsg}</div>
            )}
            {extraInfo && (
                <div className="extra_info_container">{extraInfo}</div>
            )}
        </div>
    );
}

function FAQNationLogo() {
    return (
        <div className="login_page_logo_container">
            <Link to="/home">
                <img height={80} src={faqnationLogo} alt="" />
            </Link>
        </div>
    );
}

function ErrorSendResetPasswordEmail({ msg, close }) {
    const notificationRef = useRef(null);

    /*useEffect(() => {
        let timeoutRef;
        function closeNotification(evnt) {
            setTimeout(() => {
                close();
            }, 3000);
        }
        let notificationWrapper = notificationRef.current;
        notificationWrapper.addEventListener('animationEnd', closeNotification);
        return () => {
            try {
                clearTimeout(timeoutRef);
                notificationWrapper.removeEventListener(
                    'animationEnd',
                    closeNotification
                );
            } catch (err) {}
        };
    }, []);*/

    return (
        <div ref={notificationRef} className="notification notification_error">
            <div className="notification_msg_container">
                <div className={'notification_msg'}>{msg}</div>
                <span className={'notification_close_btn'}>
                    <Button type={'icon'} onClick={close}>
                        <Symbol name="close" />
                    </Button>
                </span>
            </div>
        </div>
    );
}

function validatePassword(password) {
    const minLength = 8;
    const invalidPassword = password.length < minLength;
    const noLetters = password.search(/[a-z]/i) < 0;
    const noNumbers = password.search(/[0-9]/i) < 0;
    const noSpecialChars = password.search(/[^0-9a-z]/i) < 0;
    const weekPassword =
        noLetters || noNumbers || noSpecialChars || password.length < minLength;

    return {
        invalid: invalidPassword,
        week: weekPassword,
        color: invalidPassword
            ? '#FF1514'
            : weekPassword
            ? '#FF8B1D'
            : '#00BC50',
        msg: invalidPassword
            ? `Password must be at least ${minLength} characters long`
            : weekPassword
            ? 'Password is weak'
            : 'Password is strong',
        /*<ul className="password-rules" style={{ marginLeft: '12px' }}>
                {
                    <>
                        <li
                            className={
                                password.length < minLength ? '' : 'satisfied'
                            }>{`At least ${minLength} characters`}</li>
                    </>
                }
                {
                    <>
                        <li className={noLetters ? '' : 'satisfied'}>
                            {'Contains at least one letter'}
                        </li>
                    </>
                }
                {
                    <>
                        <li className={noNumbers ? '' : 'satisfied'}>
                            {'Contains at least one number'}
                        </li>
                    </>
                }
                {
                    <>
                        <li className={noSpecialChars ? '' : 'satisfied'}>
                            {'Contains at least one symbol'}
                        </li>
                    </>
                }
            </ul>*/
    };
}

function PasswordStrengthIndicator({ password = '' }) {
    const passwordTestResult = validatePassword(password);

    if (password.length === 0) {
        return <></>;
    }

    return (
        <>
            <div
                className="password_strength_indicator"
                data-msg={passwordTestResult.msg}
                style={{ color: passwordTestResult.color }}>
                <span
                    style={{
                        backgroundColor: `${passwordTestResult.color}`,
                    }}></span>
                <span
                    style={{
                        backgroundColor: `${
                            !passwordTestResult.invalid
                                ? passwordTestResult.color
                                : ''
                        }`,
                    }}></span>
                <span
                    style={{
                        backgroundColor: `${
                            !passwordTestResult.invalid &&
                            !passwordTestResult.week
                                ? passwordTestResult.color
                                : ''
                        }`,
                    }}></span>
            </div>
        </>
    );
}

function Loading() {
    return (
        <div
            style={{
                position: 'absolute',
                top: '0',
                left: '0',
                width: '100%',
                minWidth: '100%',
                height: '100%',
                display: 'flex',
                alignContent: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
            }}>
            <Button style={{ margin: 'auto' }} loading={true} />
        </div>
    );
}
