import './splashscreen.scss';

export default function SplashScreen() {
    return (
        <div className="splashscreen-container">
            <span className="splashscreen-logo">
                <img
                    width="82"
                    src="/favicon/android-chrome-192x192.png"
                    alt=""
                />
            </span>
        </div>
    );
}
