import { useEffect } from 'react';
import { connectAdvanced } from 'react-redux';
import useDatabase from '../../hooks/useDatabase';
import { GridView, TimelineView } from '../fullview/main';
import useBarelayout from './hook';
import BarelayoutBareView from './layout';
import { applyFilter } from '../../panels/left/filterPanel/filter';
import { applySearch, applySortRules } from '../../panels/top/database_full';
import { Loading } from '../../components/Loading/Loading';
import { useSearchParams } from 'react-router-dom';
import useSetting from '../../hooks/useSetting';
import { GDialogTabs } from '../../panels/dialog/main';
import { fieldsetsIdx } from './ctx';
import { HelpPagePreview } from '../../components/help-page-preview/HelpPagePreview';

export const selectorFieldset = {
    id: 'selector',
    fields: [
        {
            id: 'selector_field',
            type: 'selector',
            width: {
                normal: 20,
            },
        },
    ],
};

/** Calendar only */
export default function BareDatabaseLayout({ database_full = false }) {
    const dbCtx = useDatabase();
    const brCtx = useBarelayout();
    const urlParams = useSearchParams();

    const settingCtx = useSetting();
    useEffect(() => {
        try {
            if (
                localStorage.getItem('faqnation_create_new_project') ===
                    'true' ||
                sessionStorage.getItem('generate_with_ai_description')
            ) {
                settingCtx.setGDialog({
                    visible: true,
                    active: GDialogTabs.database,
                });
            }
        } catch (err) {}
    }, []);

    useEffect(() => {
        if (!dbCtx.loading && urlParams[0].get('answer')) {
            brCtx.openInbox();
        }
    }, [dbCtx.loading]);

    useEffect(() => {
        if (!dbCtx.loading && urlParams[0].get('answer')) {
            let answerId = urlParams[0].get('answer');
            let answerQuestionButtonId = 'answer-question-button_' + answerId;
            if (document.getElementById(answerQuestionButtonId)) {
                document
                    .getElementById(answerQuestionButtonId)
                    .parentElement.click();
                try {
                    window.history.replaceState(
                        {},
                        '',
                        document.location.origin + document.location.pathname
                    );
                } catch (err) {}
            }
        }
    }, [brCtx.recordsets, dbCtx.loading]);

    useEffect(() => {
        brCtx.setBasic({
            /** filter only main cells */
            active_field: 0,
            recordsets: [],
            original_recordsets: [],
        });

        var calendar_fieldsets = toAkraFieldsets(dbCtx.calendar_fieldsets);

        // console.log(calendar_fieldsets);

        brCtx.setBasic({
            /** filter only main cells */
            fieldsets: [selectorFieldset, ...toAkraFieldsets(dbCtx.fieldsets)],
            calendar_fieldsets: calendar_fieldsets,
            active_field: 0,
            recordsets: [],
            original_recordsets: [],
        });

        // console.log('database initialized');

        dbCtx.getRecordsets();
    }, [dbCtx.database]);

    useEffect(() => {
        // console.log('fieldsets updated.');
        brCtx.setBasic({
            /** filter only main cells */
            fieldsets: [selectorFieldset, ...toAkraFieldsets(dbCtx.fieldsets)],
        });
    }, [dbCtx.fieldsets]);

    useEffect(() => {
        // console.log('calendar_fieldsets updated.');
        brCtx.setBasic({
            /** filter only main cells */
            calendar_fieldsets: toAkraFieldsets(dbCtx.calendar_fieldsets),
        });
    }, [dbCtx.calendar_fieldsets]);

    useEffect(() => {
        if (dbCtx.settings) {
            brCtx.setBasic({
                features: {
                    qr_search: {
                        enabled: dbCtx.settings.qrsearch,
                    },
                },
            });
        } else {
            brCtx.setBasic({
                features: {
                    qr_search: {
                        enabled: false,
                    },
                },
            });
        }
    }, [dbCtx.settings]);

    useEffect(() => {
        let ors =
            toAkraRecordsets(
                dbCtx.recordsets,
                dbCtx.fieldsets,
                dbCtx.calendar_fieldsets
            ) || [];
        let rs = ors.slice(0);
        // console.log('recordsets updated.');
        // console.log(rs)

        if (rs.length > 0) {
            /**apply filter*/
            if (brCtx.selectedFilters.length > 0) {
                rs[0].records = rs[0].records
                    .filter((record) =>
                        applyFilter(record, brCtx.selectedFilters, brCtx)
                    )
                    .slice(0);
            }
            /**apply search*/
            if (
                brCtx.searchOptions.query !== null &&
                brCtx.searchOptions.query.trim().length > 0
            ) {
                rs = applySearch(brCtx.searchOptions, rs, brCtx).slice(0);
            }
            /*apply sort*/
            rs = applySortRules(dbCtx, brCtx, brCtx.sortRulesList, rs).slice(0);
        }

        brCtx.setBasic({
            recordsets: rs,
            original_recordsets: ors,
        });
    }, [dbCtx.recordsets]);

    const toAkraConnectedSets = (connected_sets) => {
        var new_connected_sets = [];

        connected_sets &&
            connected_sets.map((connected_set, x) => {
                var new_records = {};

                Object.keys(connected_set.records).map((key, y) => {
                    // console.log('-------');
                    // console.log('Record key: ', key);

                    new_records[key] = {
                        cellsets: toAkraCellsets(
                            connected_set.records[key].cells,
                            dbCtx.fieldsets,
                            false
                        ),
                        calendar_cellsets: toAkraCalendarCellsets(
                            connected_set.records[key].calendar_cells,
                            dbCtx.calendar_fieldsets
                        ),
                    };
                });

                new_connected_sets.push({
                    ...connected_set,
                    records: new_records,
                });
            });

        return new_connected_sets;
    };

    useEffect(() => {
        if (!dbCtx.connected_sets) {
            return;
        }

        // console.log('--------------------------------');
        // console.log('Start formatting.');
        // console.log(dbCtx.connected_sets);
        // console.log('--------------------------------');

        var build_connected_sets = toAkraConnectedSets(dbCtx.connected_sets);

        // console.log('________________________________');
        // console.log(build_connected_sets);

        brCtx.setBasic({
            connected_records: build_connected_sets,
        });

        // console.log("INE")
        // console.log(toAkraConnectedSets(dbCtx.connected_sets))
    }, [dbCtx.connected_sets]);

    if (database_full) {
        if (dbCtx.database && brCtx.initialized && dbCtx.recordsets) {
            if (brCtx.current_table.fieldsetIdx === fieldsetsIdx.PageFieldset) {
                return <HelpPagePreview />;
            }
            return (
                <>
                    <GridView />
                </>
            );
            /*if (dbCtx.database.layout === 'timeline') {
                return <TimelineView />;
            } else if (dbCtx.database.layout === 'grid') {
                return <GridView />;
            }*/
        }

        return (
            <>
                {dbCtx.database ? (
                    <Loading />
                ) : (
                    <>
                        <div className="--no-db-selected">
                            {'No project selected!'}
                        </div>
                    </>
                )}
            </>
        );
    } else {
        return <>{brCtx.initialized && <BarelayoutBareView />}</>;
    }
}

export const toAkraField = (field) => {
    /**
     * akraField {
        label,
        id,
        attributes,
        type,
        width 
    }
    must be ordered
    */

    let automatic_value = false;

    if (
        field.attributes &&
        (field.attributes['auditsviewer'] || field.attributes['withaudits'])
    ) {
        automatic_value = true;
    }

    return {
        id: field.uid,
        label: field.label,
        attributes: field.attributes,
        type: field.type,
        value: field.defaultValue,
        width: {
            normal: field.width,
        },
        automatic_value: automatic_value,
    };
};

export const toAkraFieldset = (fieldset) => {
    var result = {};
    var fields = [];

    var raw_fields_ordered =
        (fieldset.fields &&
            fieldset.fields.sort((a, b) => a.orderNumber - b.orderNumber)) ||
        [];

    raw_fields_ordered.map((raw) => {
        fields.push(toAkraField(raw));
    });

    result = {
        id: fieldset.uid,
        label: null,
        attributes: fieldset.attributes,
        fields: fields,
    };

    return result;
};

export const toAkraFieldsets = (fieldsets) => {
    var result = [];

    var raw_fieldsets_ordered =
        (fieldsets &&
            fieldsets.sort((a, b) => a.orderNumber - b.orderNumber)) ||
        [];

    raw_fieldsets_ordered &&
        raw_fieldsets_ordered.map((fset) => {
            result.push(toAkraFieldset(fset));
        });

    return result;
};

export const toAkraCell = (cell, field) => {
    var _cell = {
        id: cell.uid,
        value: cell.value,
        attributes: cell.attributes,
    };

    return _cell;
};

export const toAkraCellsets = (cells = [], fieldsets, referTo = false) => {
    var raw_fieldsets_ordered =
        (fieldsets &&
            fieldsets.sort((a, b) => a.orderNumber - b.orderNumber)) ||
        [];

    var cellsets = {};

    raw_fieldsets_ordered.map((fieldset, x) => {
        var cellset = {
            id: fieldset.uid,
            cells: {},
        };

        var cellsetCells = {};

        /** order fieldset.fields */

        fieldset.fields.map((field, y) => {
            /** find in all cells */
            let z = cells.length;

            let refer_attr =
                (field.attributes &&
                    field.attributes['auditsviewer'] &&
                    field.attributes['auditsviewer'].referTo) ||
                null;

            while (z--) {
                if (referTo) {
                    if (refer_attr.type === 'field') {
                        console.log('reference to field');

                        if (refer_attr.to === cells[z].fielduid) {
                            cellsetCells[field.uid] = toAkraCell(
                                cells[z],
                                field
                            );
                        }

                        break;
                    } else if (refer_attr.type === 'audit') {
                        console.log('reference to audit');

                        /** it have no any cells, so it will have no any */

                        if (refer_attr.param === 'audit.time') {
                            cellsetCells[field.uid] = {
                                id: Math.floor(Math.random() * 100),
                                value: 'TIME', // cells[main_field_that_is_submitted_as_refer].createdAt
                            };
                        } else if (refer_attr.param === 'audit.subuser') {
                            cellsetCells[field.uid] = {
                                id: Math.floor(Math.random() * 100),
                                value: 'SUBUSER', // cells[main_field_that_is_submitted_as_refer].createdAt
                            };
                        }

                        break;
                    } else if (refer_attr.type === 'datefield') {
                        console.log('reference to datefield');

                        cellsetCells[field.uid] = {
                            id: Math.floor(Math.random() * 100),
                            value: 'DATEFIELD', // cells[main_field_that_is_submitted_as_refer].createdAt
                        };

                        break;
                    }
                } else {
                    // console.log(field.uid)
                    // console.log(cells[z].fielduid)

                    if (field.uid === cells[z].fielduid) {
                        // console.log("Field: ", field.uid)
                        // console.log("Cell: ", cells[z].fielduid)

                        cellsetCells[field.uid] = toAkraCell(cells[z], field);

                        break;
                    }
                }
            }
        });

        // console.log(cellsetCells)
        cellset['cells'] = cellsetCells;
        // console.log(cellset)

        cellsets[fieldset.uid] = cellset;
    });

    return cellsets;
};

export const toAkraCalendarCellsets = (cells = [], fieldsets) => {
    var raw_fieldsets_ordered =
        (fieldsets &&
            fieldsets.sort((a, b) => a.orderNumber - b.orderNumber)) ||
        [];

    var cellsuperset = {};
    let cellgroups = []; // by datecode

    cells.map((cell, y) => {
        // let new_cell = toAkraCell(cell);

        if (cellgroups[cell.datecode]) {
            cellgroups[cell.datecode].push(cell);
        } else {
            cellgroups[cell.datecode] = [];
            cellgroups[cell.datecode].push(cell);
        }
    });

    raw_fieldsets_ordered.map((fieldset, x) => {
        // var cellset = {
        //     id: fieldset.uid,
        //     cells: {}
        // };

        // let cellsetCells = {};

        Object.keys(cellgroups).map((key) => {
            let cells_array = cellgroups[key];
            let cellsetCells = {};

            fieldset.fields.map((field, n) => {
                let z = cells_array.length;

                while (z--) {
                    if (field.uid === cells_array[z].fielduid) {
                        cellsetCells[field.uid] = toAkraCell(
                            cells_array[z],
                            field
                        );
                    }
                }
            });

            // cellsuperset[key] = {}

            if (cellsuperset[key]) {
                cellsuperset[key][fieldset.uid] = {
                    cellset: 1,
                };
            } else {
                cellsuperset[key] = {};
                cellsuperset[key][fieldset.uid] = {
                    id: fieldset.uid,
                    cells: cellsetCells,
                };
            }
        });
    });

    return cellsuperset;
};

export const toAkraRecord = (
    record,
    fieldsets,
    calendar_fieldsets = [],
    referTo = false
) => {
    /** @todo calendar_cells */

    return {
        id: record.uid,
        height: {
            normal: record.height || 32,
        },
        attributes: record.attributes,
        cellsets: toAkraCellsets(record.cells, fieldsets, referTo),
        calendar_cellsets: toAkraCalendarCellsets(
            record.calendar_cells,
            calendar_fieldsets
        ),
    };
};

export const toAkraRecordset = (
    recordset,
    fieldsets,
    calendar_fieldsets = [],
    referTo = false
) => {
    var records = [];
    var _records_ordered =
        (recordset.records &&
            recordset.records.sort((a, b) => a.orderNumber - b.orderNumber)) ||
        [];

    _records_ordered.map((record, x) => {
        records.push(
            toAkraRecord(record, fieldsets, calendar_fieldsets, referTo)
        );
    });

    return {
        id: recordset.uid,
        attributes: recordset.attributes,
        label: (recordset.attributes && recordset.attributes.label) || null,
        // styledcell
        records: records,
    };
};

export const toAkraRecordsets = (
    recordsets,
    fieldsets,
    calendar_fieldsets,
    referTo = false
) => {
    var new_recordsets = [];
    var recordsets_ordered =
        (recordsets &&
            recordsets.sort((a, b) => a.orderNumber - b.orderNumber)) ||
        [];

    recordsets_ordered.map((recordset, x) => {
        new_recordsets.push(
            toAkraRecordset(recordset, fieldsets, calendar_fieldsets, referTo)
        );
    });

    // console.log(new_recordsets)

    return new_recordsets;
};
