import { useParams } from 'react-router';
import './editfaqpage.scss';
import { useEffect, useState } from 'react';
import useAuth from '../../hooks/useAuth';
import useAlias from '../../hooks/useAlias';
import { DatabaseProvider } from '../../contexts/DatabaseCtx';
import { BarelayoutProvider } from '../../layouts/barelayout/ctx';
import useDatabase from '../../hooks/useDatabase';
import useBarelayout from '../../layouts/barelayout/hook';
import {
    selectorFieldset,
    toAkraFieldsets,
    toAkraRecordsets,
} from '../../layouts/barelayout/main';
import { applyFilter } from '../../panels/left/filterPanel/filter';
import { applySearch, applySortRules } from '../../panels/top/database_full';
import RecordviewTab from '../../panels/right/recordview';
import { TogglePublic } from '../../panels/right/main';
import SplashScreen from '../../components/splashscreen/SplashScreen';
import { useNavigate } from 'react-router-dom';
import { showError } from '../../components/towsts/Toasts';

export default function EditFaqPage() {
    const navigate = useNavigate();
    const auth = useAuth();
    const { alias, database, recordset, record } = useParams();
    const aliasCtx = useAlias();
    const [db, setDb] = useState(null);

    useEffect(() => {
        if (!auth.authenticated || !auth.initialized) {
            return;
        }
        const findDatabaseInAlias = (uid) => {
            var _database = null;

            aliasCtx.databases &&
                aliasCtx.databases.map((db) => {
                    if (db.uid === uid) {
                        _database = db;
                        return;
                    }
                });

            return _database;
        };

        setDb(findDatabaseInAlias(database));

        return () => {
            console.log('[database.page] unmounting.');
        };
    }, [database, aliasCtx.databases]);

    useEffect(() => {
        if (!auth.authenticated && auth.initialized) {
            showError('You are not authorized to see this page!');
            navigate('/home');
        }
        if (!auth.authenticated || !auth.initialized) {
            return;
        }
        (async () => {
            /*await callApi({
                method: 'get',
                path: `template/record/${alias}/${database}/${recordset}/${record}`,
            });*/
        })();
    }, [record, auth]);

    return (
        <>
            {auth.initialized ? (
                auth.authenticated && (
                    <DatabaseProvider earlydatabase={db}>
                        <BarelayoutProvider>
                            <EditFaqPopup
                                recordsetuid={recordset}
                                recorduid={record}
                            />
                        </BarelayoutProvider>
                    </DatabaseProvider>
                )
            ) : (
                <SplashScreen />
            )}
        </>
    );
}

const EditFaqPopup = ({ recordsetuid, recorduid }) => {
    const dbCtx = useDatabase();
    const brCtx = useBarelayout();
    const [deleteActive, setDeleteActive] = useState(false);

    useEffect(() => {
        setDeleteActive(false);
    }, [brCtx.active_cell]);

    useEffect(() => {
        if (!dbCtx.database) {
            return;
        }
        (async () => {
            await dbCtx.getOneRecord(recordsetuid, recorduid);
        })();
    }, [dbCtx.database]);

    useEffect(() => {
        if ((dbCtx.recordsets || []).length === 0) {
            return;
        }
        let ors =
            toAkraRecordsets(
                dbCtx.recordsets,
                dbCtx.fieldsets,
                dbCtx.calendar_fieldsets
            ) || [];
        let rs = ors.slice(0);

        if (rs.length > 0) {
            /**apply filter*/
            if (brCtx.selectedFilters.length > 0) {
                rs[0].records = rs[0].records
                    .filter((record) =>
                        applyFilter(record, brCtx.selectedFilters, brCtx)
                    )
                    .slice(0);
            }
            /**apply search*/
            if (
                brCtx.searchOptions.query !== null &&
                brCtx.searchOptions.query.trim().length > 0
            ) {
                rs = applySearch(brCtx.searchOptions, rs, brCtx).slice(0);
            }
            /*apply sort*/
            rs = applySortRules(dbCtx, brCtx, brCtx.sortRulesList, rs).slice(0);
        }

        brCtx.setBasic({
            recordsets: rs,
            original_recordsets: ors,
            active_cell: {
                cellid: 0,
                fieldidx: 0,
                is_calendar: false,
                recordid: rs[0].records[0].id,
                recordsetidx: 0,
            },
        });
    }, [dbCtx.recordsets]);

    useEffect(() => {
        brCtx.setBasic({
            /** filter only main cells */
            fieldsets: [selectorFieldset, ...toAkraFieldsets(dbCtx.fieldsets)],
        });
    }, [dbCtx.fieldsets]);

    return (
        <div className="editFaqPage">
            <div className="editFaqPageContainer">
                <div className="--section --align-start --fixed-to-top">
                    <div className="--tabs-container">
                        {brCtx.active_cell && (
                            <>
                                {/*<Button
                                onClick={() => {
                                    if (!deleteActive) {
                                        setDeleteActive(true);
                                        return;
                                    }
                                    let recordsetId =
                                        brCtx.recordsets[
                                            brCtx.active_cell.recordsetidx
                                        ].id;
                                    let recordId = brCtx.active_cell.recordid;
                                    (async () => {
                                        try {
                                            let b = await brCtx.deleteRecord({
                                                recordsetuid: recordsetId,
                                                uid: recordId,
                                            });
                                        } catch (err) {
                                            alert(err);
                                        }
                                        window.close();
                                    })();
                                }}>
                                <Symbol
                                    color={deleteActive ? 'red' : ''}
                                    name="delete_alt1"
                                    className={'mr-8'}
                                />
                                <Text
                                    style={{
                                        color: deleteActive ? 'red' : '',
                                    }}
                                    value={
                                        deleteActive
                                            ? 'Are you sure?'
                                            : 'Remove'
                                    }
                                />
                            </Button>*/}
                                <TogglePublic />
                            </>
                        )}
                    </div>
                </div>

                {/* <div className="--section --align-end"></div> */}

                {brCtx.active_cell && (
                    <>
                        <RecordviewTab />
                    </>
                )}
            </div>
        </div>
    );
};
