import React, { useEffect } from 'react';
import useAlias from '../../hooks/useAlias';
import useSetting from '../../hooks/useSetting';
import AliasOverview from './overview';

export const AliasViewsList = {
    FullControl: 'oide',
    SimpleControl: 'r43',
};

export default function AliasHomepage() {
    const aliasCtx = useAlias();
    const settingCtx = useSetting();

    useEffect(() => {
        if (aliasCtx.initialized && aliasCtx.alias && aliasCtx.member) {
            // console.log('Do those');

            if (aliasCtx.member.scope.includes('owner')) {
                // console.log('owner.');
                settingCtx.setViewmode(AliasViewsList.FullControl);
            } else {
                // console.log('The guy is not owner');

                if (aliasCtx.subuser) {
                    // console.log('subuser is already loaded.');

                    if (aliasCtx.subuser.scope.cell) {
                        if (
                            aliasCtx.subuser.scope.cell.scopes.includes(
                                'manager'
                            )
                        ) {
                            // console.log('the subuser is manager.');
                            settingCtx.setViewmode(AliasViewsList.FullControl);
                        } else {
                            // console.log('the subuser is not even a manager.');
                            settingCtx.setViewmode(
                                AliasViewsList.SimpleControl
                            );
                        }
                    } else {
                        // console.log('subuser have no scopes.');
                        /** @todo switch tab to error page */
                    }
                } else {
                    // console.log('subuser is not loaded.');
                    /** means not unlocked */

                    settingCtx.setViewmode(AliasViewsList.SimpleControl);
                }
            }
        } else {
            // console.log('Loading till now.');
        }

        // if(aliasCtx.initialized && aliasCtx.alias && aliasCtx.member)
        // {

        //     /** check if it is owner of alias then that view, or if signed subuser have "manager" scope, then that view */
        //     if(aliasCtx.member.scope.includes('owner'))
        //     {
        //         setViewmode(AliasViewsList.FullControl);
        //         settingCtx.setViewmode(AliasViewsList.FullControl);
        //     }
        //     else if(aliasCtx.subuser)
        //     {
        //         /** check subuser */
        //         if(aliasCtx.subuser.scope.cell.scopes.includes('manager'))
        //         {
        //             setViewmode(AliasViewsList.FullControl);
        //             settingCtx.setViewmode(AliasViewsList.FullControl);
        //         }
        //         else
        //         {
        //             setViewmode(AliasViewsList.SimpleControl);
        //             settingCtx.setViewmode(AliasViewsList.SimpleControl);
        //         }
        //     }

        // }
        // else
        // {
        //     /** till doesn't alias loaded or unlocked use simple. */
        //     setViewmode(AliasViewsList.SimpleControl);
        //     settingCtx.setViewmode(AliasViewsList.SimpleControl);
        // }
    }, [aliasCtx.alias, aliasCtx.initialized, aliasCtx.subuser]);

    return (
        <>
            {/* {viewmode === AliasViewsList.FullControl ?
                <>
                    {/* <h1>FULL CONTROL</h1> *
                    <AliasOverview />
                </>
            : viewmode === AliasViewsList.SimpleControl ?
                <>
                    <AliasOverview />
                </>
            : <></> */}

            <AliasOverview />
        </>
    );
}
