import { createContext, useEffect, useRef, useState } from 'react';
import useAlias from '../hooks/useAlias';
import { callApi } from '../functions/api';
import { apiRoutes } from '../helpers/api';

const initialCtxState = { databaseQuestionsCount: {} };
const initialCtxMethods = {
    updateDatabaseQuestionsCount: ({
        databaseId = undefined,
        newQuestionsCount = undefined,
    }) => {},
};

const QuestionsNotifCtx = createContext({
    state: initialCtxState,
    methods: initialCtxMethods,
});

function QuestionsNotifCtxProvider({ children }) {
    const currentAlias = useRef(null);

    const aliasCtx = useAlias();

    const [state, setState] = useState(initialCtxState);
    const methods = {
        updateDatabaseQuestionsCount: ({
            databaseId = undefined,
            newQuestionsCount = undefined,
        }) => {
            if (!databaseId || !('' + newQuestionsCount)) {
                return;
            }
            setState((state) => {
                return {
                    ...state,
                    databaseQuestionsCount: {
                        ...state.databaseQuestionsCount,
                        [databaseId]: newQuestionsCount,
                    },
                };
            });
        },
    };

    useEffect(() => {
        if (aliasCtx.alias && aliasCtx.alias.uid !== currentAlias.current) {
            currentAlias.current = aliasCtx.alias.uid;
            (async () => {
                const res = await callApi({
                    path: apiRoutes.account.questionsNotifications,
                    method: 'post',
                    payload: {},
                });
                console.log(res);
                if (res.status === 200 && res.data && res.data.projects) {
                    setState((state) => {
                        return {
                            ...state,
                            databaseQuestionsCount: { ...res.data.projects },
                        };
                    });
                }
            })();
        }
    }, [aliasCtx.alias]);

    return (
        <QuestionsNotifCtx.Provider value={{ state: state, methods: methods }}>
            {children}
        </QuestionsNotifCtx.Provider>
    );
}

export { QuestionsNotifCtx, QuestionsNotifCtxProvider };
