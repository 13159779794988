import { useCallback, useRef, useState } from 'react';
import Webcam from 'react-webcam';
import Button from '../../components/Button';
import { Symbol } from '../../components/elements/Icon';
import { Text } from '../../components/Text';
import useBarelayout from './hook';

const videoConstraints = {
    width: 360,
    height: 640,
    facingMode: 'environment',
};

const BrCameraDialog = () => {
    const webcamRef = useRef(null);
    const brCtx = useBarelayout();

    const [picture, setPicture] = useState('');
    const [loading, setLoading] = useState(false);

    const [captured, setCaptured] = useState(false);

    const capture = useCallback(() => {
        const pictureSrc = webcamRef.current.getScreenshot();
        setPicture(pictureSrc);
        // setLoading(true);
        setCaptured(true);

        // akraCtx.setDialogs({
        //     ...(akraCtx.dialogs || {}),
        //     camera: {
        //         ...(akraCtx.dialogs.camera || {}),
        //         captured_image_data: pictureSrc,
        //     },
        // });
    });

    const handleClose = (e, picture_taken = false) => {
        // if(!picture_taken)
        // {
        //   brCtx.changeWatchingCamera(null);
        // }
        brCtx.setBasic({
            dialog: {
                ...(brCtx.dialog || {}),
                visible: false,
            },
        });
    };

    const handleDeleteImageClick = (e) => {
        setPicture(null);
        setCaptured(false);
    };

    const handleSaveImageClick = (e) => {
        brCtx.setBasic({
            dialog: {
                ...(brCtx.dialog || {}),
                camera: {
                    ...(brCtx.dialog.camera || {}),
                    captured_image_data: picture,
                },
            },
        });
        brCtx.updateWithCamera(picture);
        setLoading(true);
        handleClose(null, true);
    };

    if (!captured) {
        return (
            <>
                <div className="br-dialog-close">
                    <Button
                        onClick={handleClose}
                        type={'icon'}>
                        <Symbol name={'close'} />
                    </Button>
                </div>

                <Webcam
                    audio={false}
                    height={360}
                    ref={webcamRef}
                    width={640}
                    screenshotFormat="image/jpeg"
                    videoConstraints={videoConstraints}
                />

                <div className="br-dialog-cam">
                    <Button
                        className={'--cam-shot'}
                        onClick={capture}
                        type={'icon'}></Button>
                </div>
            </>
        );
    } else {
        return (
            <>
                <div className="br-dialog-close">
                    <Button
                        onClick={(e) => handleClose(e, false)}
                        type={'icon'}>
                        <Symbol name={'close'} />
                    </Button>
                </div>

                <img
                    className="--camera-img"
                    src={picture}
                />

                <div className="br-dialog-cam-after">
                    <Button onClick={handleDeleteImageClick}>
                        <Symbol
                            name={'delete'}
                            className={'mr-8'}
                        />
                        <Text value={'Löschen'} />
                    </Button>
                    <Button onClick={handleSaveImageClick}>
                        <Symbol
                            name={'check'}
                            className={'mr-8'}
                        />
                        <Text value={'Senden'} />
                    </Button>
                </div>
            </>
        );
    }
};

export default BrCameraDialog;
