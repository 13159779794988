export const Person2Icon = ({ className = null, color = undefined }) => {
    return (
        <svg
            className={'ui-sym path' + (className ? ' ' + className : '')}
            width="24"
            height="24"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M18.45 24q-1.35 0-2.275-1.025-.925-1.025-.725-2.375l.8-6q.4-2.85 2.6-4.725Q21.05 8 24 8t5.15 1.875q2.2 1.875 2.6 4.725l.8 6q.2 1.35-.725 2.375T29.55 24Zm-.05-3h11.2l-.8-5.95q-.25-1.75-1.625-2.9T24 11q-1.8 0-3.175 1.15-1.375 1.15-1.625 2.9Zm5.6 0ZM8 40v-4.7q0-1.9.95-3.25T11.4 30q3.35-1.5 6.425-2.25Q20.9 27 24 27q3.1 0 6.15.775 3.05.775 6.4 2.225 1.55.7 2.5 2.05.95 1.35.95 3.25V40Zm3-3h26v-1.7q0-.8-.475-1.525-.475-.725-1.175-1.075-3.2-1.55-5.85-2.125Q26.85 30 24 30t-5.55.575q-2.7.575-5.85 2.125-.7.35-1.15 1.075Q11 34.5 11 35.3Zm13 0Z" />
        </svg>
    );
};

/*** IT IS EQUIVALENT TO : RESTAURANT FOR NOW  **/
export const RestaurantIcon = ({ className = null, color = undefined }) => {
    return (
        <svg
            className={'ui-sym path' + (className ? ' ' + className : '')}
            width="24"
            height="24"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M14.25 44V25.6q-2.6-.55-4.425-2.625Q8 20.9 8 18V4h3v14h3.25V4h3v14h3.25V4h3v14q0 2.9-1.825 4.975Q19.85 25.05 17.25 25.6V44ZM35 44V28h-5.75V12.75q0-3.95 2.4-6.35Q34.05 4 38 4v40Z"
                style={{ fill: color }}
                className="app-icon-fill"
            />
        </svg>
    );
};

export const StoreIcon = ({ className = null, color = undefined }) => {
    return (
        <svg
            className={'ui-sym path' + (className ? ' ' + className : '')}
            width="24"
            height="24"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M8 11V8h32.1v3Zm.25 29V27.1H5.8v-3L8 14h32.05l2.2 10.1v3H39.8V40h-3V27.1h-9.45V40Zm3-3h13.1v-9.9h-13.1Zm-2.5-12.9H39.3Zm0 0H39.3L37.75 17H10.3Z"
                style={{ fill: color }}
                className="app-icon-fill"
            />
        </svg>
    );
};

export const AppsIcon = ({ className = null, color = undefined }) => {
    return (
        <svg
            className={'ui-sym path' + (className ? ' ' + className : '')}
            width="24"
            height="24"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M11.3 40q-1.4 0-2.35-.95Q8 38.1 8 36.7q0-1.4.95-2.35.95-.95 2.35-.95 1.4 0 2.35.95.95.95.95 2.35 0 1.4-.95 2.35-.95.95-2.35.95ZM24 40q-1.4 0-2.35-.95-.95-.95-.95-2.35 0-1.4.95-2.35.95-.95 2.35-.95 1.4 0 2.35.95.95.95.95 2.35 0 1.4-.95 2.35Q25.4 40 24 40Zm12.7 0q-1.4 0-2.35-.95-.95-.95-.95-2.35 0-1.4.95-2.35.95-.95 2.35-.95 1.4 0 2.35.95.95.95.95 2.35 0 1.4-.95 2.35-.95.95-2.35.95ZM11.3 27.3q-1.4 0-2.35-.95Q8 25.4 8 24q0-1.4.95-2.35.95-.95 2.35-.95 1.4 0 2.35.95.95.95.95 2.35 0 1.4-.95 2.35-.95.95-2.35.95Zm12.7 0q-1.4 0-2.35-.95-.95-.95-.95-2.35 0-1.4.95-2.35.95-.95 2.35-.95 1.4 0 2.35.95.95.95.95 2.35 0 1.4-.95 2.35-.95.95-2.35.95Zm12.7 0q-1.4 0-2.35-.95-.95-.95-.95-2.35 0-1.4.95-2.35.95-.95 2.35-.95 1.4 0 2.35.95.95.95.95 2.35 0 1.4-.95 2.35-.95.95-2.35.95ZM11.3 14.6q-1.4 0-2.35-.95Q8 12.7 8 11.3q0-1.4.95-2.35Q9.9 8 11.3 8q1.4 0 2.35.95.95.95.95 2.35 0 1.4-.95 2.35-.95.95-2.35.95Zm12.7 0q-1.4 0-2.35-.95-.95-.95-.95-2.35 0-1.4.95-2.35Q22.6 8 24 8q1.4 0 2.35.95.95.95.95 2.35 0 1.4-.95 2.35-.95.95-2.35.95Zm12.7 0q-1.4 0-2.35-.95-.95-.95-.95-2.35 0-1.4.95-2.35Q35.3 8 36.7 8q1.4 0 2.35.95.95.95.95 2.35 0 1.4-.95 2.35-.95.95-2.35.95Z"
                style={{ fill: color }}
                className="app-icon-fill"
            />
        </svg>
    );
};

export const LooksOneIcon = ({ className = null, color = undefined }) => {
    return (
        <svg
            className={'ui-sym path' + (className ? ' ' + className : '')}
            width="24"
            height="24"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M24.6 34.15h3v-20.3h-7.15v3h4.15ZM9 42q-1.2 0-2.1-.9Q6 40.2 6 39V9q0-1.2.9-2.1Q7.8 6 9 6h30q1.2 0 2.1.9.9.9.9 2.1v30q0 1.2-.9 2.1-.9.9-2.1.9Zm0-3h30V9H9v30ZM9 9v30V9Z"
                style={{ fill: color }}
                className="app-icon-fill"
            />
        </svg>
    );
};

export const LanguageIcon = ({ className = null, color = undefined }) => {
    return (
        <svg
            className={'ui-sym path' + (className ? ' ' + className : '')}
            width="24"
            height="24"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M24 44q-4.2 0-7.85-1.575Q12.5 40.85 9.8 38.15q-2.7-2.7-4.25-6.375Q4 28.1 4 23.9t1.55-7.825Q7.1 12.45 9.8 9.75t6.35-4.225Q19.8 4 24 4q4.2 0 7.85 1.525Q35.5 7.05 38.2 9.75q2.7 2.7 4.25 6.325Q44 19.7 44 23.9t-1.55 7.875Q40.9 35.45 38.2 38.15t-6.35 4.275Q28.2 44 24 44Zm0-2.9q1.75-1.8 2.925-4.125Q28.1 34.65 28.85 31.45H19.2q.7 3 1.875 5.4Q22.25 39.25 24 41.1Zm-4.25-.6q-1.25-1.9-2.15-4.1-.9-2.2-1.5-4.95H8.6Q10.5 35 13 37.025q2.5 2.025 6.75 3.475Zm8.55-.05q3.6-1.15 6.475-3.45 2.875-2.3 4.625-5.55h-7.45q-.65 2.7-1.525 4.9-.875 2.2-2.125 4.1Zm-20.7-12h7.95q-.15-1.35-.175-2.425-.025-1.075-.025-2.125 0-1.25.05-2.225.05-.975.2-2.175h-8q-.35 1.2-.475 2.15T7 23.9q0 1.3.125 2.325.125 1.025.475 2.225Zm11.05 0H29.4q.2-1.55.25-2.525.05-.975.05-2.025 0-1-.05-1.925T29.4 19.5H18.65q-.2 1.55-.25 2.475-.05.925-.05 1.925 0 1.05.05 2.025.05.975.25 2.525Zm13.75 0h8q.35-1.2.475-2.225Q41 25.2 41 23.9q0-1.3-.125-2.25T40.4 19.5h-7.95q.15 1.75.2 2.675.05.925.05 1.725 0 1.1-.075 2.075-.075.975-.225 2.475Zm-.5-11.95h7.5q-1.65-3.45-4.525-5.75Q32 8.45 28.25 7.5q1.25 1.85 2.125 4t1.525 5Zm-12.7 0h9.7q-.55-2.65-1.85-5.125T24 7q-1.6 1.35-2.7 3.55-1.1 2.2-2.1 5.95Zm-10.6 0h7.55q.55-2.7 1.4-4.825.85-2.125 2.15-4.125-3.75.95-6.55 3.2T8.6 16.5Z"
                style={{ fill: color }}
                className="app-icon-fill"
            />
        </svg>
    );
};

export const ToggleOnIcon = ({ className = null, color = undefined }) => {
    return (
        <svg
            className={'ui-sym path' + (className ? ' ' + className : '')}
            width="24"
            height="24"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M14 36q-5 0-8.5-3.5T2 24q0-5 3.5-8.5T14 12h20q5 0 8.5 3.5T46 24q0 5-3.5 8.5T34 36Zm0-3h20q3.75 0 6.375-2.625T43 24q0-3.75-2.625-6.375T34 15H14q-3.75 0-6.375 2.625T5 24q0 3.75 2.625 6.375T14 33Zm20.05-3.95q2.1 0 3.575-1.475T39.1 24q0-2.1-1.475-3.575T34.05 18.95q-2.1 0-3.575 1.475T29 24q0 2.1 1.475 3.575t3.575 1.475ZM24 24Z"
                style={{ fill: color }}
                className="app-icon-fill"
            />
        </svg>
    );
};

export const RadioButtonCheckedIcon = ({
    className = null,
    color = undefined,
}) => {
    return (
        <svg
            className={'ui-sym path' + (className ? ' ' + className : '')}
            width="24"
            height="24"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M24 33.3q3.9 0 6.6-2.7 2.7-2.7 2.7-6.6 0-3.9-2.7-6.6-2.7-2.7-6.6-2.7-3.9 0-6.6 2.7-2.7 2.7-2.7 6.6 0 3.9 2.7 6.6 2.7 2.7 6.6 2.7ZM24 44q-4.1 0-7.75-1.575-3.65-1.575-6.375-4.3-2.725-2.725-4.3-6.375Q4 28.1 4 24q0-4.15 1.575-7.8 1.575-3.65 4.3-6.35 2.725-2.7 6.375-4.275Q19.9 4 24 4q4.15 0 7.8 1.575 3.65 1.575 6.35 4.275 2.7 2.7 4.275 6.35Q44 19.85 44 24q0 4.1-1.575 7.75-1.575 3.65-4.275 6.375t-6.35 4.3Q28.15 44 24 44Zm0-3q7.1 0 12.05-4.975Q41 31.05 41 24q0-7.1-4.95-12.05Q31.1 7 24 7q-7.05 0-12.025 4.95Q7 16.9 7 24q0 7.05 4.975 12.025Q16.95 41 24 41Zm0-17Z"
                style={{ fill: color }}
                className="app-icon-fill"
            />
        </svg>
    );
};

export const CalendarTodayIcon = ({ className = null, color = undefined }) => {
    return (
        <svg
            className={'ui-sym path' + (className ? ' ' + className : '')}
            width="24"
            height="24"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M9 44q-1.2 0-2.1-.9Q6 42.2 6 41V10q0-1.2.9-2.1Q7.8 7 9 7h3.25V4h3.25v3h17V4h3.25v3H39q1.2 0 2.1.9.9.9.9 2.1v31q0 1.2-.9 2.1-.9.9-2.1.9Zm0-3h30V19.5H9V41Zm0-24.5h30V10H9Zm0 0V10v6.5Z"
                style={{ fill: color }}
                className="app-icon-fill"
            />
        </svg>
    );
};

export const LogoutIcon = ({ className = null, color = undefined }) => {
    return (
        <svg
            className={'ui-sym path' + (className ? ' ' + className : '')}
            width="24"
            height="24"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M9 42q-1.2 0-2.1-.9Q6 40.2 6 39V9q0-1.2.9-2.1Q7.8 6 9 6h14.55v3H9v30h14.55v3Zm24.3-9.25-2.15-2.15 5.1-5.1h-17.5v-3h17.4l-5.1-5.1 2.15-2.15 8.8 8.8Z"
                style={{ fill: color }}
                className="app-icon-fill"
            />
        </svg>
    );
};

export const DnsIcon = ({ className = null, color = undefined }) => {
    return (
        <svg
            className={'ui-sym path' + (className ? ' ' + className : '')}
            width="24"
            height="24"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M14.35 12.15q-1.05 0-1.775.725-.725.725-.725 1.775 0 1.05.725 1.775.725.725 1.775.725 1.05 0 1.775-.725.725-.725.725-1.775 0-1.05-.725-1.775-.725-.725-1.775-.725Zm0 20.7q-1.05 0-1.775.725-.725.725-.725 1.775 0 1.05.725 1.775.725.725 1.775.725 1.05 0 1.775-.725.725-.725.725-1.775 0-1.05-.725-1.775-.725-.725-1.775-.725ZM7.7 6.05h32.55q.8 0 1.275.475Q42 7 42 7.8v13.45q0 .85-.475 1.45t-1.275.6H7.7q-.75 0-1.225-.6Q6 22.1 6 21.25V7.8q0-.8.475-1.275Q6.95 6.05 7.7 6.05Zm1.3 3V20.3h30V9.05ZM7.7 26.7h32.35q.75 0 1.35.625.6.625.6 1.425v13.2q0 1-.6 1.525-.6.525-1.35.525H7.95q-.8 0-1.375-.525T6 41.95v-13.2q0-.8.475-1.425T7.7 26.7Zm1.3 3V41h30V29.7ZM9 9.05V20.3ZM9 29.7V41Z"
                style={{ fill: color }}
                className="app-icon-fill"
            />
        </svg>
    );
};

export const ImageIcon = ({ className = null, color = undefined }) => {
    return (
        <svg
            className={'ui-sym path' + (className ? ' ' + className : '')}
            width="24"
            height="24"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M9 42q-1.2 0-2.1-.9Q6 40.2 6 39V9q0-1.2.9-2.1Q7.8 6 9 6h30q1.2 0 2.1.9.9.9.9 2.1v30q0 1.2-.9 2.1-.9.9-2.1.9Zm0-3h30V9H9v30Zm2.8-4.85h24.45l-7.35-9.8-6.6 8.55-4.65-6.35ZM9 39V9v30Z"
                style={{ fill: color }}
                className="app-icon-fill"
            />
        </svg>
    );
};

export const ReportIcon = ({ className = null, color = undefined }) => {
    return (
        <svg
            className={'ui-sym path' + (className ? ' ' + className : '')}
            width="24"
            height="24"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M24 33.95q.7 0 1.225-.525.525-.525.525-1.225 0-.7-.525-1.225Q24.7 30.45 24 30.45q-.7 0-1.225.525-.525.525-.525 1.225 0 .7.525 1.225.525.525 1.225.525Zm-1.5-7.2h3V13.6h-3ZM16.5 42 6 31.5v-15L16.5 6h15L42 16.5v15L31.5 42Zm1.25-3h12.5L39 30.25v-12.5L30.25 9h-12.5L9 17.75v12.5ZM24 24Z"
                style={{ fill: color }}
                className="app-icon-fill"
            />
        </svg>
    );
};

export const DriveFileRenameIcon = ({
    className = null,
    color = undefined,
}) => {
    return (
        <svg
            className={'ui-sym path' + (className ? ' ' + className : '')}
            width="24"
            height="24"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="m19.7 42 7-7H44v7ZM7 39h2.2l22.15-22.15-2.2-2.2L7 36.8Zm30.7-24.3-6.4-6.4 2.1-2.1q.85-.85 2.125-.825 1.275.025 2.125.875L39.8 8.4q.85.85.85 2.1t-.85 2.1Zm-2.1 2.1L10.4 42H4v-6.4l25.2-25.2Zm-5.35-1.05-1.1-1.1 2.2 2.2Z"
                style={{ fill: color }}
                className="app-icon-fill"
            />
        </svg>
    );
};

export const ClearAllIcon = ({ className = null, color = undefined }) => {
    return (
        <svg
            className={'ui-sym path' + (className ? ' ' + className : '')}
            width="24"
            height="24"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M6 34v-3h28v3Zm4-8.5v-3h28v3Zm4-8.5v-3h28v3Z"
                style={{ fill: color }}
                className="app-icon-fill"
            />
        </svg>
    );
};

export const UploadIcon = ({ className = null, color = undefined }) => {
    return (
        <svg
            className={'ui-sym path' + (className ? ' ' + className : '')}
            width="24"
            height="24"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M11 40q-1.2 0-2.1-.9Q8 38.2 8 37v-7.15h3V37h26v-7.15h3V37q0 1.2-.9 2.1-.9.9-2.1.9Zm11.5-7.65V13.8l-6 6-2.15-2.15L24 8l9.65 9.65-2.15 2.15-6-6v18.55Z"
                style={{ fill: color }}
                className="app-icon-fill"
            />
        </svg>
    );
};

export const LockIcon = ({ className = null, color = undefined }) => {
    return (
        <svg
            className={'ui-sym path' + (className ? ' ' + className : '')}
            width="24"
            height="24"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M11 44q-1.25 0-2.125-.875T8 41V19.3q0-1.25.875-2.125T11 16.3h3.5v-4.8q0-3.95 2.775-6.725Q20.05 2 24 2q3.95 0 6.725 2.775Q33.5 7.55 33.5 11.5v4.8H37q1.25 0 2.125.875T40 19.3V41q0 1.25-.875 2.125T37 44Zm0-3h26V19.3H11V41Zm13-7q1.6 0 2.725-1.1t1.125-2.65q0-1.5-1.125-2.725T24 26.3q-1.6 0-2.725 1.225T20.15 30.25q0 1.55 1.125 2.65Q22.4 34 24 34Zm-6.5-17.7h13v-4.8q0-2.7-1.9-4.6Q26.7 5 24 5q-2.7 0-4.6 1.9-1.9 1.9-1.9 4.6ZM11 41V19.3 41Z"
                style={{ fill: color }}
                className="app-icon-fill"
            />
        </svg>
    );
};

export const BadgeIcon = ({ className = null, color = undefined }) => {
    return (
        <svg
            className={'ui-sym path' + (className ? ' ' + className : '')}
            width="24"
            height="24"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M7 44q-1.2 0-2.1-.9Q4 42.2 4 41V17q0-1.2.9-2.1.9-.9 2.1-.9h11.85V7q0-1.2.9-2.1.9-.9 2.1-.9h4.35q1.2 0 2.1.9.9.9.9 2.1v7H41q1.2 0 2.1.9.9.9.9 2.1v24q0 1.2-.9 2.1-.9.9-2.1.9Zm0-3h34V17H29.2q0 1.4-.925 2.2-.925.8-2.325.8h-3.9q-1.35 0-2.275-.8-.925-.8-.925-2.2H7v24Zm4.6-5.35h11.95v-.7q0-.9-.45-1.6-.45-.7-1.15-.95-1.6-.55-2.5-.725-.9-.175-1.75-.175-.95 0-2.025.225t-2.425.675q-.75.25-1.2.95-.45.7-.45 1.6Zm16.8-3.35h8.5v-2.5h-8.5Zm-10.7-2.5q1.15 0 1.925-.8.775-.8.775-1.9 0-1.15-.775-1.925-.775-.775-1.925-.775-1.1 0-1.9.775-.8.775-.8 1.925 0 1.1.8 1.9.8.8 1.9.8Zm10.7-3.15h8.5v-2.5h-8.5ZM21.85 17h4.35V7h-4.35ZM24 29Z"
                style={{ fill: color }}
                className="app-icon-fill"
            />
        </svg>
    );
};

export const FmtBadIcon = ({ className = null, color = undefined }) => {
    return (
        <svg
            className={'ui-sym path' + (className ? ' ' + className : '')}
            width="24"
            height="24"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M22.5 22h3V12h-3Zm1.5 7q.6 0 1.05-.45.45-.45.45-1.05 0-.6-.45-1.05Q24.6 26 24 26q-.6 0-1.05.45-.45.45-.45 1.05 0 .6.45 1.05.45.45 1.05.45Zm0 11.05q6.65-6.05 9.825-10.975Q37 24.15 37 20.4q0-5.9-3.775-9.65T24 7q-5.45 0-9.225 3.75Q11 14.5 11 20.4q0 3.75 3.25 8.675Q17.5 34 24 40.05ZM24 44q-8.05-6.85-12.025-12.725Q8 25.4 8 20.4q0-7.5 4.825-11.95Q17.65 4 24 4q6.35 0 11.175 4.45Q40 12.9 40 20.4q0 5-3.975 10.875T24 44Zm0-23.6Z"
                style={{ fill: color }}
                className="app-icon-fill"
            />
        </svg>
    );
};

export const WorkspacesIcon = ({ className = null, color = undefined }) => {
    return (
        <svg
            className={'ui-sym path' + (className ? ' ' + className : '')}
            width="24"
            height="24"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M11.5 42q-3.15 0-5.325-2.175Q4 37.65 4 34.5q0-3.15 2.175-5.325Q8.35 27 11.5 27q3.15 0 5.325 2.175Q19 31.35 19 34.5q0 3.15-2.175 5.325Q14.65 42 11.5 42Zm25.05 0q-3.15 0-5.325-2.175Q29.05 37.65 29.05 34.5q0-3.15 2.175-5.325Q33.4 27 36.55 27q3.15 0 5.325 2.175Q44.05 31.35 44.05 34.5q0 3.15-2.175 5.325Q39.7 42 36.55 42ZM11.5 39q1.85 0 3.175-1.325Q16 36.35 16 34.5q0-1.85-1.325-3.175Q13.35 30 11.5 30q-1.85 0-3.175 1.325Q7 32.65 7 34.5q0 1.85 1.325 3.175Q9.65 39 11.5 39Zm25.05 0q1.85 0 3.175-1.325Q41.05 36.35 41.05 34.5q0-1.85-1.325-3.175Q38.4 30 36.55 30q-1.85 0-3.175 1.325Q32.05 32.65 32.05 34.5q0 1.85 1.325 3.175Q34.7 39 36.55 39Zm-12.5-18.05q-3.15 0-5.325-2.175Q16.55 16.6 16.55 13.45q0-3.15 2.175-5.325Q20.9 5.95 24.05 5.95q3.15 0 5.325 2.175Q31.55 10.3 31.55 13.45q0 3.15-2.175 5.325Q27.2 20.95 24.05 20.95Zm0-3q1.85 0 3.175-1.325Q28.55 15.3 28.55 13.45q0-1.85-1.325-3.175Q25.9 8.95 24.05 8.95q-1.85 0-3.175 1.325Q19.55 11.6 19.55 13.45q0 1.85 1.325 3.175Q22.2 17.95 24.05 17.95Zm0-4.5Zm12.5 21.05Zm-25.05 0Z"
                style={{ fill: color }}
                className="app-icon-fill"
            />
        </svg>
    );
};

export const TitleIcon = ({ className = null, color = undefined }) => {
    return (
        <svg
            className={'ui-sym path' + (className ? ' ' + className : '')}
            width="24"
            height="24"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M21.5 40V13H10V8h28v5H26.5v27Z"
                style={{ fill: color }}
                className="app-icon-fill"
            />
        </svg>
    );
};

export const WebIcon = ({ className = null, color = undefined }) => {
    return (
        <svg
            className={'ui-sym path' + (className ? ' ' + className : '')}
            width="24"
            height="24"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M7 40q-1.2 0-2.1-.9Q4 38.2 4 37V11q0-1.2.9-2.1Q5.8 8 7 8h34q1.2 0 2.1.9.9.9.9 2.1v26q0 1.2-.9 2.1-.9.9-2.1.9Zm0-3h23.05v-8.15H7V37Zm26.05 0H41V17.7h-7.95ZM7 25.85h23.05V17.7H7Z"
                style={{ fill: color }}
                className="app-icon-fill"
            />
        </svg>
    );
};

export const DownloadIcon = ({ className = null, color = undefined }) => {
    return (
        <svg
            className={'ui-sym path' + (className ? ' ' + className : '')}
            width="24"
            height="24"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M11 40q-1.2 0-2.1-.9Q8 38.2 8 37v-7.15h3V37h26v-7.15h3V37q0 1.2-.9 2.1-.9.9-2.1.9Zm13-7.65-9.65-9.65 2.15-2.15 6 6V8h3v18.55l6-6 2.15 2.15Z"
                style={{ fill: color }}
                className="app-icon-fill"
            />
        </svg>
    );
};

/*** IT IS EQUIVALENT TO : KETTLE FOR NOW  **/
export const KettleIcon = ({ className = null, color = undefined }) => {
    return (
        <svg
            className={'ui-sym path' + (className ? ' ' + className : '')}
            width="24"
            height="24"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12.5 38V11.35L7 4h29v6.5h5q1.25 0 2.125.875T44 13.5v11q0 1.25-.875 2.125T41 27.5h-5V38Zm3-3H33V7H13l2.5 3.25ZM36 24.5h5v-11h-5ZM25 32h5V10h-5ZM6 44v-3h36v3Zm9.5-9H33Z"
                style={{ fill: color }}
                className="app-icon-fill"
            />
        </svg>
    );
};

export const QrScannerIcon = ({ className = null, color = undefined }) => {
    return (
        <svg
            className={'ui-sym path' + (className ? ' ' + className : '')}
            width="24"
            height="24"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M4 12.65V4h8.65v3H7v5.65ZM4 44v-8.65h3V41h5.65v3Zm31.35 0v-3H41v-5.65h3V44ZM41 12.65V7h-5.65V4H44v8.65Zm-5.6 22.8h3.15v3.15H35.4Zm0-6.3h3.15v3.15H35.4Zm-3.15 3.15h3.15v3.15h-3.15Zm-3.15 3.15h3.15v3.15H29.1Zm-3.15-3.15h3.15v3.15h-3.15Zm6.3-6.3h3.15v3.15h-3.15Zm-3.15 3.15h3.15v3.15H29.1ZM25.95 26h3.15v3.15h-3.15Zm12.6-16.6V22h-12.6V9.4ZM22 26v12.6H9.4V26Zm0-16.6V22H9.4V9.4Zm-2.5 26.7v-7.6h-7.6v7.6Zm0-16.6v-7.6h-7.6v7.6Zm16.55 0v-7.6h-7.6v7.6Z"
                style={{ fill: color }}
                className="app-icon-fill"
            />
        </svg>
    );
};

export const BackspaceIcon = ({ className = null, color = undefined }) => {
    return (
        <svg
            className={'ui-sym path' + (className ? ' ' + className : '')}
            width="24"
            height="24"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="m22.4 31.7 5.6-5.6 5.6 5.6 2.15-2.15L30.1 24l5.55-5.55-2.15-2.15-5.5 5.6-5.6-5.6-2.15 2.15L25.9 24l-5.65 5.55ZM6 24l8.45-11.95q.65-.9 1.55-1.475.9-.575 2-.575h21q1.25 0 2.125.875T42 13v22q0 1.25-.875 2.125T39 38H18q-1.1 0-2-.575-.9-.575-1.55-1.475Zm3.75 0 7.7 11H39V13H17.45ZM39 24V13v22Z"
                style={{ fill: color }}
                className="app-icon-fill"
            />
        </svg>
    );
};

export const TodayIcon = ({ className = null, color = undefined }) => {
    return (
        <svg
            className={'ui-sym path' + (className ? ' ' + className : '')}
            width="24"
            height="24"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M17.65 32.5q-2.05 0-3.45-1.4-1.4-1.4-1.4-3.45 0-2.05 1.4-3.45 1.4-1.4 3.45-1.4 2.05 0 3.45 1.4 1.4 1.4 1.4 3.45 0 2.05-1.4 3.45-1.4 1.4-3.45 1.4ZM9 44q-1.2 0-2.1-.9Q6 42.2 6 41V10q0-1.2.9-2.1Q7.8 7 9 7h3.25V4h3.25v3h17V4h3.25v3H39q1.2 0 2.1.9.9.9.9 2.1v31q0 1.2-.9 2.1-.9.9-2.1.9Zm0-3h30V19.5H9V41Zm0-24.5h30V10H9Zm0 0V10v6.5Z"
                style={{ fill: color }}
                className="app-icon-fill"
            />
        </svg>
    );
};

export const CalendarMonthIcon = ({ className = null, color = undefined }) => {
    return (
        <svg
            className={'ui-sym path' + (className ? ' ' + className : '')}
            width="24"
            height="24"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M9 44q-1.2 0-2.1-.9Q6 42.2 6 41V10q0-1.2.9-2.1Q7.8 7 9 7h3.25V4h3.25v3h17V4h3.25v3H39q1.2 0 2.1.9.9.9.9 2.1v31q0 1.2-.9 2.1-.9.9-2.1.9Zm0-3h30V19.5H9V41Zm0-24.5h30V10H9Zm0 0V10v6.5ZM24 28q-.85 0-1.425-.575Q22 26.85 22 26q0-.85.575-1.425Q23.15 24 24 24q.85 0 1.425.575Q26 25.15 26 26q0 .85-.575 1.425Q24.85 28 24 28Zm-8 0q-.85 0-1.425-.575Q14 26.85 14 26q0-.85.575-1.425Q15.15 24 16 24q.85 0 1.425.575Q18 25.15 18 26q0 .85-.575 1.425Q16.85 28 16 28Zm16 0q-.85 0-1.425-.575Q30 26.85 30 26q0-.85.575-1.425Q31.15 24 32 24q.85 0 1.425.575Q34 25.15 34 26q0 .85-.575 1.425Q32.85 28 32 28Zm-8 8q-.85 0-1.425-.575Q22 34.85 22 34q0-.85.575-1.425Q23.15 32 24 32q.85 0 1.425.575Q26 33.15 26 34q0 .85-.575 1.425Q24.85 36 24 36Zm-8 0q-.85 0-1.425-.575Q14 34.85 14 34q0-.85.575-1.425Q15.15 32 16 32q.85 0 1.425.575Q18 33.15 18 34q0 .85-.575 1.425Q16.85 36 16 36Zm16 0q-.85 0-1.425-.575Q30 34.85 30 34q0-.85.575-1.425Q31.15 32 32 32q.85 0 1.425.575Q34 33.15 34 34q0 .85-.575 1.425Q32.85 36 32 36Z"
                style={{ fill: color }}
                className="app-icon-fill"
            />
        </svg>
    );
};

export const AcUnitIcon = ({ className = null, color = undefined }) => {
    return (
        <svg
            className={'ui-sym path' + (className ? ' ' + className : '')}
            width="24"
            height="24"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M22.5 44v-9.75l-7.45 7.45-2.15-2.1 9.6-9.6v-4.5H18l-9.4 9.4-2.2-2.05 7.35-7.35H4v-3h9.75L6.25 15l2.15-2.15L18 22.5h4.5v-4.55l-9.4-9.4 2.1-2.2 7.3 7.35V4h3v9.7L33 6.2l2.1 2.15-9.6 9.6v4.55h4.55l9.45-9.45 2.15 2.1-7.35 7.35H44v3h-9.7l7.4 7.45-2.05 2.15-9.6-9.6H25.5V30l9.6 9.65-2.05 2.15-7.55-7.55V44Z"
                style={{ fill: color }}
                className="app-icon-fill"
            />
        </svg>
    );
};

export const LunchDiningIcon = ({ className = null, color = undefined }) => {
    return (
        <svg
            className={'ui-sym path' + (className ? ' ' + className : '')}
            width="24"
            height="24"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M4 20.1v-2q0-5.45 5.25-8.775T24 6q9.5 0 14.75 3.325T44 18.1v2Zm3.1-3h33.8q-.15-3.45-4.825-5.775T24 9q-7.4 0-12.125 2.325T7.1 17.1ZM4 28.9v-3q1.65 0 2.875-1.1t3.925-1.1q2.7 0 3.575 1.1t2.925 1.1q2.05 0 3.025-1.1.975-1.1 3.675-1.1 2.7 0 3.675 1.1.975 1.1 3.025 1.1t2.925-1.1q.875-1.1 3.575-1.1t3.925 1.1Q42.35 25.9 44 25.9v3q-2.7 0-3.725-1.1T37.2 26.7q-2.05 0-2.925 1.1T30.7 28.9q-2.7 0-3.675-1.1-.975-1.1-3.025-1.1t-3.025 1.1Q20 28.9 17.3 28.9q-2.7 0-3.575-1.1T10.8 26.7q-2.05 0-3.075 1.1Q6.7 28.9 4 28.9ZM7 42q-1.2 0-2.1-.9Q4 40.2 4 39v-6.4h40V39q0 1.2-.9 2.1-.9.9-2.1.9Zm0-3h34v-3.4H7V39Zm0-3.4h34Zm.1-18.5h33.8Z"
                style={{ fill: color }}
                className="app-icon-fill"
            />
        </svg>
    );
};

export const BlenderIcon = ({ className = null, color = undefined }) => {
    return (
        <svg
            className={'ui-sym path' + (className ? ' ' + className : '')}
            width="24"
            height="24"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M15 44q-1.2 0-2.1-.9-.9-.9-.9-2.1v-1.95q0-2.25 1.125-4.275Q14.25 32.75 16.4 31.35l-2.05-10H9q-1.2 0-2.1-.9-.9-.9-.9-2.1V9.5q0-1.2.9-2.1.9-.9 2.1-.9h11.6V4h6.3v2.5H36l-4.4 24.85q2.15 1.4 3.275 3.425T36 39.05V41q0 1.2-.9 2.1-.9.9-2.1.9Zm-1.2-25.65L12.05 9.5H9v8.85ZM24 38.85q.85 0 1.425-.575Q26 37.7 26 36.85q0-.85-.575-1.425-.575-.575-1.425-.575-.85 0-1.425.575Q22 36 22 36.85q0 .85.575 1.425.575.575 1.425.575Zm-4.9-9.35h9.8l3.5-20H15.1ZM15 41h18v-1.95q0-2.7-2-4.625-2-1.925-4.95-1.925h-4.1Q19 32.5 17 34.425t-2 4.625Zm9-8.5Z"
                style={{ fill: color }}
                className="app-icon-fill"
            />
        </svg>
    );
};
