// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-page {
  height: 100%;
  overflow: auto;
}
.main-page > div {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  min-height: 100%;
}
.main-page > div > * {
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/layouts/mainlayout/index.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,cAAA;AACJ;AAAI;EACI,aAAA;EACA,sBAAA;EACA,2BAAA;EACA,oBAAA;EACA,gBAAA;AAER;AAAQ;EACI,WAAA;AAEZ","sourcesContent":[".main-page {\n    height: 100%;\n    overflow: auto;\n    & > div {\n        display: flex;\n        flex-direction: column;\n        justify-content: flex-start;\n        align-items: stretch;\n        min-height: 100%;\n\n        & > * {\n            width: 100%;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
