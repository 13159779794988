import Quill from 'quill';
import {
    EmbedCode,
    quillEditorEmbedCode,
} from './plugins/embed-code/embedCode';
import 'quill/dist/quill.snow.css';
import './quill-editor.scss';
import { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import Account from '../../functions/Account';
import { getAPIBase } from '../../env';
import { callApi } from '../../functions/api';
import { apiRoutes } from '../../helpers/api';
import { Delta } from 'quill/core';
import { generateRandomeId } from '../../functions/Utilities';

export function QuillEditor({
    data = '',
    autofocus = false,
    placeholder = '',
    key = null,
    projectId = undefined,
    projectLimits = {},
    onChangeHandler = (value) => {},
    handleLimitsRiched = (msg = '') => {},
}) {
    const quillEditor = useRef(null);
    const noChanges = useRef(true);
    const editorHolderId = useRef(generateRandomeId('quilleditor'));
    const editorFocusStateRef = useRef(false);
    const projectLimitsRef = useRef({ ...projectLimits });

    const [editorFocused, setEditorFocused] = useState(false);
    const [newImageDialogVisible, setNewImageDialogVisible] = useState(false);
    const [newVideoDialogVisible, setNewVideoDialogVisible] = useState(false);
    const [selectImageFromUserSpace, setSelectImageFromUserSpace] =
        useState(false);

    const [selectionPosition, setSelectionPosition] = useState({
        x: 0,
        y: 0,
        visible: false,
    });
    const [quillFormatsExtraVisible, setQuillFormatsExtraVisible] =
        useState(false);

    const [uploadingImage, setUploadingImage] = useState({
        uploading: false,
        progress: '0',
        error: false,
    });

    useEffect(() => {
        projectLimitsRef.current = { ...projectLimits };
    }, [projectLimits]);

    useEffect(() => {
        if (!document.getElementById(editorHolderId.current)) {
            return;
        }
        document.getElementById(editorHolderId.current).innerHTML = '' + data;

        const icons = Quill.import('ui/icons');
        icons['link'] = '<i class="fa fa-bold" aria-hidden="true"></i>';
        Object.keys(custom_icons).map((format) => {
            icons[format] = custom_icons[format];
            return null;
        });
        Quill.register(EmbedCode);
        quillEditor.current = new Quill(`#${editorHolderId.current}`, {
            placeholder: placeholder,
            theme: 'snow',
            formats: [
                'bold',
                'italic',
                'underline',
                'link',
                'list',
                'blockquote',
                'image',
                'indent',
                'align',
                'code-block',
                'strike',
                'direction',
                'formula',
                'code',
                'embed-code',
            ],
            modules: {
                syntax: true,
                toolbar: {
                    container: `#${editorHolderId.current}-toolbar-container`,
                    handlers: {
                        image: function (value) {
                            if (
                                !(
                                    projectLimitsRef.current &&
                                    projectLimitsRef.current.limits &&
                                    projectLimitsRef.current.limits[
                                        'imagesAllowed'
                                    ]
                                )
                            ) {
                                handleLimitsRiched(
                                    'You need to upgrade your project to upload images!'
                                );
                                return;
                            }
                            if (value) {
                                setNewImageDialogVisible(true);
                            }
                        },
                        video: function (value) {
                            if (value) {
                                setNewVideoDialogVisible(true);
                            }
                        },
                        'embed-code': function (value) {
                            if (
                                !(
                                    projectLimitsRef.current &&
                                    projectLimitsRef.current.limits &&
                                    projectLimitsRef.current.limits[
                                        'embedAllowed'
                                    ]
                                )
                            ) {
                                handleLimitsRiched(
                                    'You need to upgrade your project to embed html code!'
                                );
                                return;
                            }
                            quillEditorEmbedCode.showEmbedCodePopup({
                                quillEditor: quillEditor.current,
                            });
                        },
                    },
                },
            },
        });
        quillEditor.current.clipboard.addMatcher(
            ['img', 'svg'],
            (node, delta) => {
                return new Delta().insert('\n');
            }
        );
        quillEditor.current.keyboard.addBinding({
            key: 'E',
            shortKey: true,
            shiftKey: true,
            handler: function (range, context) {
                quillEditor.current.format('align', 'center');
            },
        });
        quillEditor.current.keyboard.addBinding({
            key: 'R',
            shortKey: true,
            shiftKey: true,
            handler: function (range, context) {
                quillEditor.current.format('align', 'right');
            },
        });
        quillEditor.current.keyboard.addBinding({
            key: 'L',
            shortKey: true,
            shiftKey: true,
            handler: function (range, context) {
                quillEditor.current.format('align', '');
            },
        });
        quillEditor.current.keyboard.addBinding({
            key: 'J',
            shortKey: true,
            shiftKey: true,
            handler: function (range, context) {
                quillEditor.current.format('align', 'justify');
            },
        });
        quillEditor.current.on('text-change', handleEditorChange);
        quillEditor.current.on('selection-change', handleEditorSelectionChange);
        return () => {
            try {
                if (!noChanges.current) {
                    noChanges.current = true;
                    onChangeHandler(quillEditor.current.getSemanticHTML(0));
                }
            } catch (err) {}
            try {
                quillEditor.current.off('text-change', handleEditorChange);
                quillEditor.current.off(
                    'selection-change',
                    handleEditorSelectionChange
                );
                quillEditor.current = null;
            } catch (err) {}
        };
    }, [editorHolderId.current]);

    useEffect(() => {
        editorFocusStateRef.current = editorFocused;
    }, [editorFocused]);

    useEffect(() => {
        document.addEventListener('mousedown', handleEditorBlur);
        return () => {
            document.removeEventListener('mousedown', handleEditorBlur);
        };
    }, []);

    function handleEditorChange(delta, oldDelta, source) {
        noChanges.current = false;
    }

    function handleEditorSelectionChange(range, oldRange, source) {
        if (range) {
            let editorElm = document.getElementById(editorHolderId.current);
            let editorToolbarElm = document.getElementById(
                `${editorHolderId.current}-toolbar-container`
            );
            let selectionPos = quillEditor.current.getBounds(
                range.index,
                range.length
            );
            let toolbarWidth = editorToolbarElm.getBoundingClientRect().width;
            let toolbarPosX =
                selectionPos.left +
                editorElm.offsetLeft +
                (selectionPos.width >> 1) -
                (toolbarWidth >> 1);
            setSelectionPosition({
                x: Math.min(
                    Math.max(0, toolbarPosX),
                    editorElm.offsetWidth - toolbarWidth
                ),
                y: selectionPos.top + editorElm.offsetTop,
                visible: range.length > 0,
            });
            if (!editorFocusStateRef.current) {
                setEditorFocused(true);
            }
            return;
        } else {
            setSelectionPosition((oldV) => ({
                ...oldV,
                visible: false,
            }));
        }
    }

    function handleEditorBlur(evnt) {
        let parent = evnt.target;
        while (
            parent &&
            parent !== document.body &&
            parent !== document.body.parentElement
        ) {
            if (
                parent.id === editorHolderId.current ||
                parent.id === `${editorHolderId.current}-toolbar-container` ||
                (parent.classList &&
                    (parent.classList.contains('quill-editor-popup-overlay') ||
                        parent.classList.contains('quill-editor-popup') ||
                        parent.classList.contains(
                            'ql-extra-formats-container'
                        )))
            ) {
                if (!editorFocusStateRef.current) {
                    setEditorFocused(true);
                }
                return;
            }
            parent = parent.parentElement;
        }
        if (editorFocusStateRef.current) {
            if (!noChanges.current) {
                noChanges.current = true;
                onChangeHandler(quillEditor.current.getSemanticHTML(0));
            }
            setEditorFocused(false);
            quillEditor.current.blur();
        }
    }

    function addImageToEditor(imageUrl) {
        if (!imageUrl) {
            return;
        }
        let index = 0;
        try {
            index = quillEditor.current.selection.savedRange.index ?? 0;
        } catch (err) {}
        quillEditor.current.insertEmbed(index, 'image', imageUrl);
    }

    async function uploadImage(file) {
        if (
            !(
                projectLimitsRef.current &&
                projectLimitsRef.current.limits &&
                projectLimitsRef.current.limits['imagesAllowed']
            )
        ) {
            handleLimitsRiched(
                'You need to upgrade your project to add images!'
            );
            return;
        }
        if (!file) {
            return undefined;
        }
        let imageUrl = null;
        setUploadingImage({ uploading: true, progress: 0, error: false });

        let res = await Account.uploadFileToUserSpace({
            file: file,
            projectId: projectId,
            onUploadProgress: (e) => {
                const { loaded, total } = e;
                let percent = Math.floor((loaded * 100) / total);
                setUploadingImage({
                    uploading: true,
                    progress: percent,
                    error: false,
                });
            },
        });

        if (res.messageCode === 'LIMIT_REACHED') {
            setUploadingImage({
                uploading: false,
                progress: 0,
                error: res.id ? false : true,
            });
            handleLimitsRiched(
                'You need to upgrade your project to upload images!'
            );
            return;
        }
        if (res.id) {
            imageUrl = getAPIBase() + '/space/' + res.id;
        }
        setUploadingImage({
            uploading: false,
            progress: 0,
            error: res.id ? false : true,
        });
        return imageUrl;
    }

    return (
        <div key={key} style={{ marginBottom: '60px' }}>
            <div className="ql-extra-formats-container">
                <div
                    class={`ql-formats-extra-overlay ${
                        quillFormatsExtraVisible ? 'visible' : ''
                    }`}
                    onClick={() => {
                        setQuillFormatsExtraVisible(false);
                    }}></div>
                <div
                    class={`ql-formats extra ${
                        quillFormatsExtraVisible ? 'visible' : ''
                    }`}>
                    <button
                        onClick={() => {
                            let value = !document
                                .getElementById(
                                    editorHolderId.current + '-ql-code-block'
                                )
                                .classList.contains('ql-active');
                            quillEditor.current.format('code-block', value);
                        }}
                        class="ql-code-block"
                        title="Add code"
                        dangerouslySetInnerHTML={{
                            __html: custom_icons['code-block'],
                        }}></button>
                    <button
                        onClick={() => {
                            if (
                                !(
                                    projectLimitsRef.current &&
                                    projectLimitsRef.current.limits &&
                                    projectLimitsRef.current.limits[
                                        'imagesAllowed'
                                    ]
                                )
                            ) {
                                handleLimitsRiched(
                                    'You need to upgrade your project to upload images!'
                                );
                                return;
                            }
                            setNewImageDialogVisible(true);
                            setQuillFormatsExtraVisible(false);
                        }}
                        class="ql-image"
                        title="Add image"
                        dangerouslySetInnerHTML={{
                            __html: custom_icons['image'],
                        }}></button>
                    <button
                        onClick={() => {
                            if (
                                !(
                                    projectLimitsRef.current &&
                                    projectLimitsRef.current.limits &&
                                    projectLimitsRef.current.limits[
                                        'embedAllowed'
                                    ]
                                )
                            ) {
                                handleLimitsRiched(
                                    'You need to upgrade your project to embed html code!'
                                );
                                return;
                            }
                            quillEditorEmbedCode.showEmbedCodePopup({
                                quillEditor: quillEditor.current,
                            });
                            setQuillFormatsExtraVisible(false);
                        }}
                        className="ql-embed-code"
                        title="Embed code"
                        dangerouslySetInnerHTML={{
                            __html: custom_icons['embed-code'],
                        }}></button>
                </div>
                <button
                    class={`open-ql-extra-formats ${
                        quillFormatsExtraVisible ? 'open' : ''
                    } ${editorFocused ? 'visible' : ''}`}
                    onClick={() => {
                        setQuillFormatsExtraVisible((v) => !v);
                    }}>
                    <svg
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path d="M8.25 14.25V9.75H3.75V8.25H8.25V3.75H9.75V8.25H14.25V9.75H9.75V14.25H8.25Z" />
                    </svg>
                </button>
            </div>
            <div
                className={`quill-toolbar-container ${
                    selectionPosition.visible ? '' : 'disabled'
                }`}
                style={{
                    top: `${selectionPosition.y}px`,
                    left: `${selectionPosition.x}px`,
                }}>
                <div
                    className="quill-toolbar"
                    id={`${editorHolderId.current}-toolbar-container`}>
                    {/* <span class="ql-formats">
                    <select class="ql-font"></select>
                    <select class="ql-size"></select>
                </span> */}
                    <span class="ql-formats">
                        <button class="ql-bold" title="Bold"></button>
                        <button class="ql-italic" title="Italic"></button>
                        <button class="ql-underline"></button>
                    </span>
                    <span class="ql-formats">
                        <button
                            class="ql-blockquote"
                            title="add a quote"></button>
                        <button class="ql-link" title="add a link"></button>
                    </span>
                    <span class="ql-formats">
                        <button
                            class="ql-list"
                            value="ordered"
                            title="add an ordered list"></button>
                        <button
                            class="ql-list"
                            value="bullet"
                            title="add an unordered list"></button>
                    </span>
                    <span className="ql-formats" style={{ display: 'none' }}>
                        <button
                            onClick={() => {}}
                            class="ql-code-block"
                            id={editorHolderId.current + '-ql-code-block'}
                            title="Add code"></button>
                    </span>
                    {/* <span class="ql-formats">
                        <select class="ql-align" title="align content"></select>
                    </span> */}

                    <QuillEditorPopup
                        onClose={() => {
                            setNewImageDialogVisible(false);
                        }}
                        open={newImageDialogVisible}>
                        <div>
                            <form
                                onSubmit={(evnt) => {
                                    evnt.preventDefault();
                                    const imageUrl =
                                        evnt.target.children[0].value;
                                    addImageToEditor(imageUrl);
                                    setNewImageDialogVisible(false);
                                }}
                                className={'add-image-from-link-form'}>
                                <input
                                    type="url"
                                    placeholder={'Enter the URL of the Image'}
                                    className={'add-image-from-link-input'}
                                    required
                                />
                                <button className={'add-image-from-link-btn'}>
                                    {'Add'}
                                </button>
                            </form>
                            <div style={{ textAlign: 'center', opacity: '.6' }}>
                                OR
                            </div>

                            <div
                                className={`upload-file-container ${
                                    uploadingImage.uploading ? 'uploading' : ''
                                }`}
                                data-upload-progress={`${uploadingImage.progress}%`}
                                onChange={async (evnt) => {
                                    if (!evnt.target.files[0]) {
                                        return;
                                    }
                                    const imageUrl = await uploadImage(
                                        evnt.target.files[0]
                                    );
                                    addImageToEditor(imageUrl);
                                    evnt.target.value = '';
                                    setNewImageDialogVisible(false);
                                }}>
                                <label>Upload Image</label>
                                <input type="file" accept="image/*" />
                            </div>
                            <div style={{ textAlign: 'center' }}>
                                <button
                                    onClick={() => {
                                        setSelectImageFromUserSpace(true);
                                    }}
                                    className={'add-image-from-user-space-btn'}>
                                    {'Your Images'}
                                </button>
                            </div>
                        </div>
                    </QuillEditorPopup>
                    <SelectImageFromUserSpace
                        open={selectImageFromUserSpace}
                        onCancel={() => {
                            setSelectImageFromUserSpace(false);
                        }}
                        onSelectImage={(imageUrl) => {
                            setSelectImageFromUserSpace(false);
                            setNewImageDialogVisible(false);
                            addImageToEditor(imageUrl);
                        }}
                    />
                    <QuillEditorPopup
                        onClose={() => {
                            setNewVideoDialogVisible(false);
                        }}
                        open={newVideoDialogVisible}>
                        <div>
                            <form
                                onSubmit={(evnt) => {
                                    evnt.preventDefault();
                                    const videoUrl =
                                        evnt.target.children[0].value;
                                    let index = 0;
                                    try {
                                        index =
                                            quillEditor.current.selection
                                                .savedRange.index ?? 0;
                                    } catch (err) {}
                                    quillEditor.current.insertEmbed(
                                        index,
                                        'video',
                                        videoUrl
                                    );
                                    setNewVideoDialogVisible(false);
                                }}
                                className={'add-video-from-link-form'}>
                                <input
                                    type="url"
                                    placeholder={'Enter the URL of the video'}
                                    className={'add-video-from-link-input'}
                                    required
                                />
                                <button className={'add-video-from-link-btn'}>
                                    {'Add'}
                                </button>
                            </form>
                        </div>
                    </QuillEditorPopup>
                </div>
            </div>
            {/* Create the editor container  */}
            <div
                className="quill-editor-container"
                id={editorHolderId.current}></div>
        </div>
    );
}

function SelectImageFromUserSpace({
    open,
    onCancel = () => {},
    onSelectImage = (imageUrl) => {},
}) {
    const [loading, setLoading] = useState(true);
    const [images, setImages] = useState([]);

    useEffect(() => {
        (async () => {
            if (!open) {
                return;
            }
            setLoading(true);
            const res = await callApi({
                method: 'get',
                path: apiRoutes.account.space,
            });
            if (res.data) {
                setImages(res.data);
            } else {
                setImages([]);
            }
            setLoading(false);
        })();
    }, [open]);

    return (
        <QuillEditorPopup
            onClose={() => {
                onCancel();
            }}
            open={open}
            className="select-from-user-space-images-popup">
            <div className="images-container">
                {(images || []).map((image, i) => {
                    return (
                        <div className="usr-image" key={'usr-image--0' + i}>
                            <a
                                href="/#"
                                style={{
                                    backgroundImage: `url(${getAPIBase()}/space/${image})`,
                                }}
                                onClick={(evnt) => {
                                    evnt.preventDefault();
                                    onSelectImage(
                                        `${getAPIBase()}/space/${image}`
                                    );
                                }}>
                                &nbsp;
                            </a>
                        </div>
                    );
                })}
                {images.length === 0 && !loading && (
                    <p
                        style={{
                            width: '100%',
                            textAlign: 'center',
                            fontSize: '1rem',
                            opacity: '.5',
                        }}>
                        You havn't upload any images yet!
                    </p>
                )}
            </div>
        </QuillEditorPopup>
    );
}

function QuillEditorPopup({
    onClose = () => {},
    open = false,
    className = '',
    children = <></>,
}) {
    if (!open) {
        return <></>;
    }
    return (
        <>
            {createPortal(
                <div className="popups-container">
                    <div
                        className="quill-editor-popup-overlay"
                        onClick={onClose}></div>
                    <div className={`quill-editor-popup ${className}`}>
                        {children}
                    </div>
                </div>,
                document.body
            )}
        </>
    );
}

const custom_icons = {
    italic: `
            <svg
                width="20"
                height="19"
                viewBox="0 0 20 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                    class="ql-fill"
                    d="M4.65137 14.6927V12.7596H7.74438L10.0641 5.80032H6.97113V3.86719H14.7037V5.80032H11.9973L9.67752 12.7596H12.3839V14.6927H4.65137Z"
                    fill="black"
                />
            </svg>
        `,
    bold: `
            <svg
                width="19"
                height="19"
                viewBox="0 0 19 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                    class="ql-fill"
                    d="M5.25781 14.6927V3.86719H9.53004C10.3677 3.86719 11.141 4.12494 11.8498 4.64044C12.5586 5.15594 12.913 5.8712 12.913 6.78622C12.913 7.44349 12.7648 7.94932 12.4684 8.30373C12.172 8.65814 11.8949 8.91267 11.6372 9.06732C11.9593 9.20908 12.317 9.47328 12.71 9.8599C13.1031 10.2465 13.2996 10.8265 13.2996 11.5997C13.2996 12.7467 12.8808 13.549 12.0431 14.0065C11.2054 14.464 10.4193 14.6927 9.68469 14.6927H5.25781ZM7.5969 12.5276H9.60736C10.226 12.5276 10.6029 12.3698 10.7382 12.054C10.8736 11.7383 10.9412 11.5095 10.9412 11.3677C10.9412 11.226 10.8736 10.9972 10.7382 10.6815C10.6029 10.3657 10.2066 10.2079 9.54937 10.2079H7.5969V12.5276ZM7.5969 8.12008H9.39472C9.82001 8.12008 10.1293 8.01054 10.3226 7.79145C10.5159 7.57236 10.6126 7.3275 10.6126 7.05686C10.6126 6.74756 10.503 6.49625 10.284 6.30294C10.0649 6.10962 9.78135 6.01297 9.43338 6.01297H7.5969V8.12008Z"
                    fill="black"
                />
            </svg>
    `,
    underline: `
            <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                    class="ql-fill"
                    d="M4.66309 16.7838V15.2373H15.4886V16.7838H4.66309ZM10.0759 13.6908C8.77422 13.6908 7.76254 13.2848 7.04084 12.4729C6.31914 11.661 5.95829 10.5849 5.95829 9.24458V2.86523H7.94941V9.36056C7.94941 10.0823 8.12984 10.6687 8.49069 11.1197C8.85154 11.5708 9.37993 11.7963 10.0759 11.7963C10.7718 11.7963 11.3002 11.5708 11.661 11.1197C12.0219 10.6687 12.2023 10.0823 12.2023 9.36056V2.86523H14.1934V9.24458C14.1934 10.5849 13.8326 11.661 13.1109 12.4729C12.3892 13.2848 11.3775 13.6908 10.0759 13.6908Z"
                    fill="black"
                />
            </svg>
    `,
    blockquote: `
            <svg
                width="19"
                height="19"
                viewBox="0 0 19 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                    class="ql-fill"
                    d="M4.7618 13.9177L6.54028 10.8247C5.6897 10.8247 4.96155 10.5218 4.35584 9.91613C3.75012 9.31041 3.44727 8.58227 3.44727 7.73169C3.44727 6.88111 3.75012 6.15296 4.35584 5.54724C4.96155 4.94153 5.6897 4.63867 6.54028 4.63867C7.39086 4.63867 8.11901 4.94153 8.72472 5.54724C9.33044 6.15296 9.63329 6.88111 9.63329 7.73169C9.63329 8.0281 9.59785 8.30196 9.52697 8.55327C9.45609 8.80458 9.34977 9.04622 9.208 9.27819L6.54028 13.9177H4.7618ZM11.7211 13.9177L13.4996 10.8247C12.649 10.8247 11.9208 10.5218 11.3151 9.91613C10.7094 9.31041 10.4065 8.58227 10.4065 7.73169C10.4065 6.88111 10.7094 6.15296 11.3151 5.54724C11.9208 4.94153 12.649 4.63867 13.4996 4.63867C14.3501 4.63867 15.0783 4.94153 15.684 5.54724C16.2897 6.15296 16.5926 6.88111 16.5926 7.73169C16.5926 8.0281 16.5571 8.30196 16.4863 8.55327C16.4154 8.80458 16.3091 9.04622 16.1673 9.27819L13.4996 13.9177H11.7211Z"
                    fill="black"
                />
            </svg>
    `,
    link: `
            <svg
                width="20"
                height="19"
                viewBox="0 0 20 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                    class="ql-fill"
                    d="M9.28448 13.1446H6.19146C5.1218 13.1446 4.21 12.7677 3.45608 12.0138C2.70216 11.2598 2.3252 10.348 2.3252 9.27838C2.3252 8.20871 2.70216 7.29691 3.45608 6.54299C4.21 5.78907 5.1218 5.41211 6.19146 5.41211H9.28448V6.95862H6.19146C5.54709 6.95862 4.99936 7.18415 4.5483 7.63521C4.09723 8.08628 3.8717 8.634 3.8717 9.27838C3.8717 9.92276 4.09723 10.4705 4.5483 10.9215C4.99936 11.3726 5.54709 11.5981 6.19146 11.5981H9.28448V13.1446ZM6.96472 10.0516V8.50512H13.1507V10.0516H6.96472ZM10.831 13.1446V11.5981H13.924C14.5684 11.5981 15.1161 11.3726 15.5672 10.9215C16.0182 10.4705 16.2438 9.92276 16.2438 9.27838C16.2438 8.634 16.0182 8.08628 15.5672 7.63521C15.1161 7.18415 14.5684 6.95862 13.924 6.95862H10.831V5.41211H13.924C14.9937 5.41211 15.9055 5.78907 16.6594 6.54299C17.4133 7.29691 17.7903 8.20871 17.7903 9.27838C17.7903 10.348 17.4133 11.2598 16.6594 12.0138C15.9055 12.7677 14.9937 13.1446 13.924 13.1446H10.831Z"
                    fill="black"
                />
            </svg>
    `,
    list: {
        bullet: `
                <svg
                    width="17"
                    height="17"
                    viewBox="0 0 17 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                        class="ql-fill"
                        d="M6.85157 13.4009V12.0477H14.9707V13.4009H6.85157ZM6.85157 9.34132V7.98813H14.9707V9.34132H6.85157ZM6.85157 5.28174V3.92855H14.9707V5.28174H6.85157ZM4.14519 14.0775C3.77306 14.0775 3.45449 13.945 3.18949 13.68C2.92449 13.415 2.79199 13.0964 2.79199 12.7243C2.79199 12.3522 2.92449 12.0336 3.18949 11.7686C3.45449 11.5036 3.77306 11.3711 4.14519 11.3711C4.51731 11.3711 4.83588 11.5036 5.10088 11.7686C5.36588 12.0336 5.49838 12.3522 5.49838 12.7243C5.49838 13.0964 5.36588 13.415 5.10088 13.68C4.83588 13.945 4.51731 14.0775 4.14519 14.0775ZM4.14519 10.0179C3.77306 10.0179 3.45449 9.88542 3.18949 9.62042C2.92449 9.35542 2.79199 9.03686 2.79199 8.66473C2.79199 8.2926 2.92449 7.97404 3.18949 7.70903C3.45449 7.44403 3.77306 7.31153 4.14519 7.31153C4.51731 7.31153 4.83588 7.44403 5.10088 7.70903C5.36588 7.97404 5.49838 8.2926 5.49838 8.66473C5.49838 9.03686 5.36588 9.35542 5.10088 9.62042C4.83588 9.88542 4.51731 10.0179 4.14519 10.0179ZM4.14519 5.95834C3.77306 5.95834 3.45449 5.82584 3.18949 5.56084C2.92449 5.29584 2.79199 4.97728 2.79199 4.60515C2.79199 4.23302 2.92449 3.91445 3.18949 3.64945C3.45449 3.38445 3.77306 3.25195 4.14519 3.25195C4.51731 3.25195 4.83588 3.38445 5.10088 3.64945C5.36588 3.91445 5.49838 4.23302 5.49838 4.60515C5.49838 4.97728 5.36588 5.29584 5.10088 5.56084C4.83588 5.82584 4.51731 5.95834 4.14519 5.95834Z"
                        fill="black"
                    />
                </svg>
        `,
        ordered: `
                <svg
                    width="17"
                    height="17"
                    viewBox="0 0 17 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                        class="ql-fill"
                        d="M2.45996 15.4304V14.4155H4.15145V13.908H3.13656V12.8931H4.15145V12.3857H2.45996V11.3708H4.48975C4.68145 11.3708 4.84215 11.4356 4.97183 11.5653C5.10151 11.695 5.16635 11.8557 5.16635 12.0474V12.724C5.16635 12.9157 5.10151 13.0764 4.97183 13.2061C4.84215 13.3357 4.68145 13.4006 4.48975 13.4006C4.68145 13.4006 4.84215 13.4654 4.97183 13.5951C5.10151 13.7248 5.16635 13.8855 5.16635 14.0772V14.7538C5.16635 14.9455 5.10151 15.1062 4.97183 15.2359C4.84215 15.3655 4.68145 15.4304 4.48975 15.4304H2.45996ZM2.45996 10.6942V8.83356C2.45996 8.64185 2.5248 8.48116 2.65448 8.35148C2.78416 8.2218 2.94486 8.15696 3.13656 8.15696H4.15145V7.64951H2.45996V6.63462H4.48975C4.68145 6.63462 4.84215 6.69946 4.97183 6.82914C5.10151 6.95882 5.16635 7.11951 5.16635 7.31121V8.49526C5.16635 8.68696 5.10151 8.84765 4.97183 8.97733C4.84215 9.10701 4.68145 9.17185 4.48975 9.17185H3.47486V9.6793H5.16635V10.6942H2.45996ZM3.47486 5.95802V2.91333H2.45996V1.89844H4.48975V5.95802H3.47486ZM6.51954 13.4006V12.0474H14.6387V13.4006H6.51954ZM6.51954 9.341V7.98781H14.6387V9.341H6.51954ZM6.51954 5.28142V3.92823H14.6387V5.28142H6.51954Z"
                        fill="black"
                    />
                </svg>
        `,
    },
    'embed-code': `
            <svg
                width="17"
                height="17"
                viewBox="-1 -1 19 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <rect
                    x="0.75"
                    y="0.75"
                    width="15.5"
                    height="15.5"
                    rx="2.25"
                    class="ql-stroke app-icon-stroke app-icon-fill-2"
                    stroke-width="2"
                />
                <rect x="5" y="6" width="7" height="5" rx="1" class="ql-fill app-icon-fill" />
            </svg>
    `,
    'code-block': `
            <svg
                width="17"
                height="17"
                viewBox="-1 -1 19 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <rect
                    x="0.75"
                    y="0.75"
                    width="15.5"
                    height="15.5"
                    rx="2.25"
                    class="ql-stroke app-icon-stroke app-icon-fill-2"
                    stroke-width="2"
                />
                <rect x="3" y="4" width="6" height="1" class="ql-fill app-icon-fill" />
                <rect x="3" y="6" width="8" height="1" class="ql-fill app-icon-fill" />
                <rect x="3" y="8" width="5" height="1" class="ql-fill app-icon-fill" />
                <rect x="3" y="10" width="3" height="1" class="ql-fill app-icon-fill" />
            </svg>
    `,
    image: `
            <svg
                width="17"
                height="17"
                viewBox="-1 -1 19 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <rect
                    x="0.75"
                    y="0.75"
                    width="15.5"
                    height="15.5"
                    rx="2.25"
                    class="ql-stroke app-icon-stroke app-icon-fill-2"
                    stroke-width="2"
                />
                <path d="M10.5996 8L14.0637 13.25H7.13551L10.5996 8Z" class="ql-fill app-icon-fill" />
                <path d="M6 9.5L8.59808 13.25H3.40192L6 9.5Z" class="ql-fill app-icon-fill" />
            </svg>
    `,
};
