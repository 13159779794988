import React, {
    cloneElement,
    useEffect,
    useImperativeHandle,
    useRef,
    useState,
} from 'react';
import { forwardRef } from 'react';
import { createPortal } from 'react-dom';
import useIsomorphicLayoutEffect from '../../hooks/useIsomorphicLayoutEffect';
import useLambdaOnResize from '../../hooks/useLambdaOnResize';
import { useOnClickOutside } from '../../hooks/useOnClickOutside';
import useOnEscape from '../../hooks/useOnEscape';
import { GetPositionTypesValueArray } from './Specs';
import styles from './styles';
import './popup.css';

var popup_instances = 0;

const getPopupRoot = () => {
    let PopupRoot = document.getElementById('hero-popup-layer');
    if (PopupRoot === null) {
        PopupRoot = document.createElement('div');
        PopupRoot.setAttribute('id', 'hero-popup-layer');
        document.body.appendChild(PopupRoot);
    }

    return PopupRoot;
};

const getCoordsForPosition = (
    triggerBounding,
    contentBounding,
    position,
    arrow,
    { offsetX, offsetY }
) => {
    const margin = arrow ? 8 : 0;
    const args = position.split(' ');

    /** Step 1 */
    ///// of trigger element
    // vertical center
    const CenterTop = triggerBounding.top + triggerBounding.height / 2;
    // horizontal center
    const CenterLeft = triggerBounding.left + triggerBounding.width / 2;

    const { height, width } = contentBounding;
    let top = CenterTop - height / 2;
    let left = CenterLeft - width / 2;
    let transform = '';
    let arrowTop = '0%';
    let arrowLeft = '0%';

    /** Step 2 */
    switch (args[0]) {
        case 'top':
            top -= height / 2 + triggerBounding.height / 2 + margin;
            transform = 'rotate(180deg)  translateX(50%)';
            arrowTop = '100%';
            arrowLeft = '50%';
            break;
        case 'bottom':
            top += height / 2 + triggerBounding.height / 2 + margin;
            transform = 'rotate(0deg) translateY(-100%) translateX(-50%)';
            arrowLeft = '50%';
            break;
        case 'left':
            left -= width / 2 + triggerBounding.width / 2 + margin;
            transform = ' rotate(90deg)  translateY(50%) translateX(-25%)';
            arrowLeft = '100%';
            arrowTop = '50%';
            break;
        case 'right':
            left += width / 2 + triggerBounding.width / 2 + margin;
            transform = 'rotate(-90deg)  translateY(-150%) translateX(25%)';
            arrowTop = '50%';
            break;
        default:
    }
    switch (args[1]) {
        case 'top':
            top = triggerBounding.top;
            arrowTop = `${triggerBounding.height / 2}px`;
            break;
        case 'bottom':
            top = triggerBounding.top - height + triggerBounding.height;
            arrowTop = `${height - triggerBounding.height / 2}px`;
            break;
        case 'left':
            left = triggerBounding.left;
            arrowLeft = `${triggerBounding.width / 2}px`;
            break;
        case 'right':
            left = triggerBounding.left - width + triggerBounding.width;
            arrowLeft = `${width - triggerBounding.width / 2}px`;
            break;
        default:
    }

    top = args[0] === 'top' ? top - offsetY : top + offsetY;
    left = args[0] === 'left' ? left - offsetX : left + offsetX;

    return { top, left, transform, arrowLeft, arrowTop };
};

export const getTooltipBoundary = (keepTooltipInside) => {
    // add viewport
    let boundingBox = {
        top: 0,
        left: 0,
        /* eslint-disable-next-line no-undef */
        width: window.innerWidth,
        /* eslint-disable-next-line no-undef */
        height: window.innerHeight,
    };
    if (typeof keepTooltipInside === 'string') {
        /* eslint-disable-next-line no-undef */
        const selector = document.querySelector(keepTooltipInside);
        if (process.env.NODE_ENV !== 'production') {
            if (selector === null)
                throw new Error(
                    `${keepTooltipInside} selector does not exist : keepTooltipInside must be a valid html selector 'class' or 'Id'  or a boolean value`
                );
        }
        if (selector !== null) boundingBox = selector.getBoundingClientRect();
    }

    return boundingBox;
};

export const calculatePosition = (
    triggerBounding,
    contentBounding,
    position,
    arrow,
    { offsetX, offsetY },
    keepTooltipInside
) => {
    let bestCoords = {};
    bestCoords.arrowLeft = '0%';
    bestCoords.arrowTop = '0%';
    bestCoords.left = 0;
    bestCoords.top = 0;
    bestCoords.transform = 'rotate(135deg)';

    let i = 0;
    const wrapperBox = getTooltipBoundary(keepTooltipInside);
    let positions = Array.isArray(position) ? position : [position];

    if (keepTooltipInside || Array.isArray(position))
        positions = [...positions, ...GetPositionTypesValueArray()];

    while (i < positions.length) {
        bestCoords = getCoordsForPosition(
            triggerBounding,
            contentBounding,
            positions[i],
            arrow,
            { offsetX, offsetY }
        );

        const contentBox = {
            top: bestCoords.top,
            left: bestCoords.left,
            width: contentBounding.width,
            height: contentBounding.height,
        };

        // check if the content is going out of wrapper, try next
        if (
            contentBox.top <= wrapperBox.top ||
            contentBox.left <= wrapperBox.left ||
            contentBox.top + contentBox.height >=
                wrapperBox.top + wrapperBox.height ||
            contentBox.left + contentBox.width >=
                wrapperBox.left + wrapperBox.width
        ) {
            i++;
        } else {
            break;
        }
    }

    return bestCoords;
};

export const PopupMenu = forwardRef(
    (
        {
            trigger = null,
            onOpen = () => {},
            onClose = () => {},
            defaultOpen = false,
            open = undefined,
            disabled = false,
            nested = false,
            closeOnDocumentClick = true,
            repositionOnResize = true,
            closeOnEscape = true,
            on = ['click'],
            contentStyle = {},
            arrowStyle = {},
            overlayStyle = {},
            className = '',
            position = 'bottom center',
            modal = false,
            lockScroll = false,
            arrow = true,
            offsetX = -20,
            offsetY = -30,
            mouseEnterDelay = 100,
            mouseLeaveDelay = 100,
            keepTooltipInside = false,
            children,
            use_triggerRect = false,
        },
        ref
    ) => {
        const isOpenRef = useRef(open || false);
        const closeOnDocumentClickRef = useRef(closeOnDocumentClick || false);
        const [isOpen, setIsOpen] = useState(open || false);
        const focusedElBeforeOpen = useRef(null);
        var triggerRef = useRef(null);
        const contentRef = useRef(null);
        const arrowRef = useRef(null);
        const isTouchScreen = useRef(false);
        const longPressTimeout = useRef(null);

        const [trRect, setTrRect] = useState(null);

        const localPopupRef = useRef(null);
        const popupId = useRef(`hp-${++popup_instances}`);
        const isModal = modal ? true : !trigger;
        const timeOut = useRef(0);

        useIsomorphicLayoutEffect(() => {
            if (isOpen) {
                focusedElBeforeOpen.current = document.activeElement;
                setPosition(use_triggerRect);
                focusContentOnOpen(); // for accessibility
                lockScrolll();
            } else {
                resetScroll();
            }

            return () => {
                clearTimeout(timeOut.current);
            };
        }, [isOpen]);

        useEffect(() => {
            if (typeof open === 'boolean') {
                if (open) openPopup();
                else closePopup();
            }
        }, [open, disabled]);

        useEffect(() => {
            isOpenRef.current = isOpen;
        }, [isOpen]);
        useEffect(() => {
            closeOnDocumentClickRef.current = closeOnDocumentClick;
        }, [closeOnDocumentClick]);

        const openPopup = (e) => {
            if (isOpen || disabled) return;

            setIsOpen(true);
            setTimeout(() => onOpen(e), 0);
        };

        const closePopup = (event) => {
            if (!isOpenRef.current || disabled) return;

            setIsOpen(false);
            if (isModal && focusedElBeforeOpen.current) {
                focusedElBeforeOpen.current.focus();
            }
            setTimeout(() => {
                onClose(event);
            }, 0);
        };

        const togglePopup = (e) => {
            e?.stopPropagation();
            if (!isOpen) openPopup(e);
            else closePopup(e);
        };

        const onMouseEnter = (e) => {
            clearTimeout(timeOut.current);
            let mouseEnterDelay = 300;
            timeOut.current = setTimeout(() => openPopup(e), mouseEnterDelay);
        };

        const onContextMenu = (e) => {
            e.preventDefault();
            if (isTouchScreen.current) {
                return;
            }
            togglePopup();
        };

        const handelLongPress = (e) => {
            e.preventDefault();
            isTouchScreen.current = true;
            longPressTimeout.current = setTimeout(() => {
                togglePopup();
            }, 300);
            window.addEventListener('touchend', cancelLongPress);
            window.addEventListener('touchmove', cancelLongPress);
        };

        const cancelLongPress = () => {
            isTouchScreen.current = false;
            try {
                clearTimeout(longPressTimeout.current);
            } catch (err) {}
            window.removeEventListener('touchend', cancelLongPress);
            window.removeEventListener('touchmove', cancelLongPress);
        };

        const onMouseLeave = (e) => {
            clearTimeout(timeOut.current);
            timeOut.current = setTimeout(() => closePopup(e), mouseLeaveDelay);
        };

        const lockScrolll = () => {
            if (isModal && lockScroll)
                document.getElementsByTagName('body')[0].style.overflow =
                    'hidden'; // migrate to document.body
        };

        const resetScroll = () => {
            if (isModal && lockScroll)
                document.getElementsByTagName('body')[0].style.overflow =
                    'auto';
        };

        const focusContentOnOpen = () => {
            const focusableEls = contentRef?.current?.querySelectorAll(
                'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), [tabindex="0"]'
            );
            const firstEl = Array.prototype.slice.call(focusableEls)[0];
            firstEl?.focus();
        };

        useImperativeHandle(ref, () => ({
            open: () => {
                openPopup();
            },
            close: () => {
                closePopup();
            },
            toggle: () => {
                togglePopup();
            },
            type: 'popup',
            pContains: (el) => {
                if (contentRef.current && contentRef.current.contains(el))
                    return true;
                return false;
            },
            setTriggerRect: (trigg) => {
                setTrRect(trigg);
            },
        }));

        const setPosition = (use_trigger_r) => {
            if (!isOpen || isModal) return;

            var trigger = null;
            const content = contentRef.current.getBoundingClientRect();

            if (!use_trigger_r) {
                if (
                    !triggerRef?.current ||
                    !triggerRef?.current ||
                    !contentRef?.current
                )
                    return; /// show error as one of ref is undefined
                trigger = triggerRef.current.getBoundingClientRect();
            } else {
                trigger = trRect;
            }

            const cords = calculatePosition(
                trigger,
                content,
                position,
                arrow,
                {
                    offsetX,
                    offsetY,
                },
                keepTooltipInside
            );
            contentRef.current.style.top = `${cords.top + window.scrollY}px`;
            contentRef.current.style.left = `${cords.left + window.scrollX}px`;
            if (
                contentRef.current.offsetWidth + cords.left >
                window.innerWidth
            ) {
                contentRef.current.style.left = `${
                    window.innerWidth - contentRef.current.offsetWidth
                }px`;
            } else if (cords.left + window.scrollX < 0) {
                contentRef.current.style.left = '4px';
            }
            if (
                cords.top + window.scrollY + contentRef.current.offsetHeight >
                window.innerHeight
            ) {
                if (contentRef.current.offsetHeight < window.innerHeight) {
                    cords.top =
                        window.innerHeight - contentRef.current.offsetHeight;
                    contentRef.current.style.top = `${
                        cords.top + window.scrollY
                    }px`;
                } else {
                    let newHeight = 500;
                    if (newHeight < window.innerHeight) {
                        contentRef.current.style.height = `${newHeight}px`;
                        contentRef.current.style.top = `${Math.min(
                            cords.top + window.scrollY,
                            window.innerHeight - newHeight + window.scrollY
                        )}px`;
                    } else {
                        cords.top = 0;
                        contentRef.current.style.top = `${
                            cords.top + window.scrollY
                        }px`;
                    }
                }
            }
            if (arrow && !!arrowRef.current) {
                arrowRef.current.style.transform = cords.transform;
                arrowRef.current.style.setProperty(
                    '-ms-transform',
                    cords.transform
                );
                arrowRef.current.style.setProperty(
                    '-webkit-transform',
                    cords.transform
                );
                arrowRef.current.style.top =
                    arrowStyle.top?.toString() || cords.arrowTop;
                arrowRef.current.style.left =
                    arrowStyle.left?.toString() || cords.arrowLeft;
            }
        };

        useOnEscape(closePopup, closeOnEscape);
        useLambdaOnResize(setPosition, repositionOnResize);

        /*useOnClickOutside(
            trigger ? [contentRef, triggerRef] : [contentRef],
            closePopup,
            (e) => {},
            closeOnDocumentClick && !nested
        );*/

        useEffect(() => {
            if (on.indexOf('hover') >= 0) {
                return;
            }
            const listener = (e) => {
                let parent = e.target;
                while (
                    parent &&
                    (parent !== document.body ||
                        parent !== document.body.parentElement)
                ) {
                    if (
                        (parent.classList &&
                            parent.classList.contains('popup-content')) ||
                        parent.classList.contains('popups-container') ||
                        parent.id === 'hero-popup-layer'
                    ) {
                        return;
                    }
                    parent = parent.parentElement;
                }
                if (closeOnDocumentClickRef.current && isOpenRef.current)
                    closePopup(e);
            };
            if (closeOnDocumentClickRef.current && !nested) {
                document.addEventListener('mousedown', listener);
                document.addEventListener('touchstart', listener);
            }
            return () => {
                if (closeOnDocumentClickRef.current && !nested) {
                    document.removeEventListener('mousedown', listener);
                    document.removeEventListener('touchstart', listener);
                }
            };
        }, []);

        const renderTrigger = () => {
            const triggerProps = {
                key: 'T',
                ref: triggerRef,
                'aria-describedby': popupId.current,
            };
            const onAsArray = Array.isArray(on) ? on : [on];
            for (let i = 0, len = onAsArray.length; i < len; i++) {
                switch (onAsArray[i]) {
                    case 'click':
                        triggerProps.onClick = togglePopup;
                        break;
                    case 'right-click':
                        triggerProps.onContextMenu = onContextMenu;
                        triggerProps.onTouchStart = handelLongPress;
                        break;
                    case 'hover':
                        triggerProps.onMouseEnter = onMouseEnter;
                        triggerProps.onMouseLeave = onMouseLeave;
                        break;
                    case 'focus':
                        triggerProps.onFocus = onMouseEnter;
                        triggerProps.onBlur = onMouseLeave;
                        break;
                    default:
                }
            }

            if (typeof trigger === 'function') {
                const comp = trigger(isOpen);
                return !!trigger && cloneElement(comp, triggerProps);
            }

            return !!trigger && cloneElement(trigger, triggerProps);
        };

        const addWarperAction = () => {
            const popupContentStyle = isModal
                ? styles.popupContent.modal
                : styles.popupContent.tooltip;

            const childrenElementProps = {
                className: `popup-content scroll-container ${
                    className !== ''
                        ? className
                              .split(' ')
                              .map((c) => `${c}-content`)
                              .join(' ')
                        : ''
                }`,
                style: {
                    ...popupContentStyle,
                    ...contentStyle,
                    pointerEvents: 'auto', //closeOnDocumentClick && nested ? 'auto' : 'none',
                },
                ref: contentRef,
                onClick: (e) => {
                    e.stopPropagation();
                },
                onMouseDown: (e) => {
                    e.stopPropagation();
                },
            };
            if (!modal && on.indexOf('hover') >= 0) {
                childrenElementProps.onMouseEnter = onMouseEnter;
                childrenElementProps.onMouseLeave = onMouseLeave;
            }
            return childrenElementProps;
        };

        const renderContent = () => {
            return (
                <div
                    {...addWarperAction()}
                    key="C"
                    role={isModal ? 'dialog' : 'tooltip'}
                    id={popupId.current}>
                    {arrow && !isModal && (
                        <div ref={arrowRef} style={styles.popupArrow}>
                            <svg
                                data-testid="arrow"
                                className={`popup-arrow ${
                                    className !== ''
                                        ? className
                                              .split(' ')
                                              .map((c) => `${c}-arrow`)
                                              .join(' ')
                                        : ''
                                }`}
                                viewBox="0 0 32 16"
                                style={{
                                    position: 'absolute',
                                    ...arrowStyle,
                                }}>
                                <path d="M16 0l16 16H0z" fill="currentcolor" />
                            </svg>
                        </div>
                    )}
                    {children && typeof children === 'function'
                        ? children(closePopup, isOpen)
                        : children}
                </div>
            );
        };

        const overlay = !(on.indexOf('hover') >= 0);
        const ovStyle = isModal ? styles.overlay.modal : styles.overlay.tooltip;

        const content = [
            overlay && (
                <div
                    key="O"
                    data-testid="overlay"
                    data-popup={isModal ? 'modal' : 'tooltip'}
                    className={`popup-overlay ${
                        className !== ''
                            ? className
                                  .split(' ')
                                  .map((c) => `${c}-overlay`)
                                  .join(' ')
                            : ''
                    }`}
                    style={{
                        ...ovStyle,
                        ...overlayStyle,
                        pointerEvents:
                            (closeOnDocumentClick && nested) || isModal
                                ? 'auto'
                                : 'none',
                    }}
                    onMouseDown={() => {
                        if (!closeOnDocumentClickRef.current) {
                            return;
                        }
                        /*closeOnDocumentClick && nested ? closePopup : undefined*/
                        closePopup();
                    }}
                    tabIndex={-1}>
                    {isModal && renderContent()}
                </div>
            ),

            !isModal && renderContent(),
        ];

        return (
            <>
                {renderTrigger()}
                {isOpen && createPortal(content, getPopupRoot())}
            </>
        );
    }
);
