export function ArrowDown({ className = null, color = undefined }) {
    return (
        <svg
            className={'ui-sym path' + (className ? ' ' + className : '')}
            width="21"
            height="21"
            viewBox="0 0 72 72"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M31.8742 47.5832V14.7832C31.8742 13.6499 32.2576 12.6999 33.0242 11.9332C33.7909 11.1665 34.7409 10.7832 35.8742 10.7832C37.0076 10.7832 37.9576 11.1665 38.7242 11.9332C39.4909 12.6999 39.8742 13.6499 39.8742 14.7832V47.5832L51.4742 35.9832C52.2076 35.2499 53.1409 34.8832 54.2742 34.8832C55.4076 34.8832 56.3409 35.2499 57.0742 35.9832C57.8076 36.7165 58.1742 37.6499 58.1742 38.7832C58.1742 39.9165 57.8076 40.8499 57.0742 41.5832L38.6742 59.9832C37.8742 60.7832 36.9409 61.1832 35.8742 61.1832C34.8076 61.1832 33.8742 60.7832 33.0742 59.9832L14.6742 41.5832C13.9409 40.8499 13.5742 39.9165 13.5742 38.7832C13.5742 37.6499 13.9409 36.7165 14.6742 35.9832C15.4076 35.2499 16.3409 34.8832 17.4742 34.8832C18.6076 34.8832 19.5409 35.2499 20.2742 35.9832L31.8742 47.5832Z"
                style={{ fill: color }}
                className="app-icon-fill"
            />
        </svg>
    );
}
