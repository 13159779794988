import { html } from '@codemirror/lang-html';
import { EditorState, Compartment } from '@codemirror/state';
import { keymap } from '@codemirror/view';
import { insertTab } from '@codemirror/commands';
import { basicSetup, EditorView } from 'codemirror';
import Quill from 'quill';

const BlockEmbed = Quill.import('blots/block/embed');
const Container = Quill.import('blots/container');

class EmbedCodeContainer extends Container {
    static blotName = 'embed-code-container';
    static className = 'zk-embed-code-container';
    static tagName = 'DIV';

    static create(value) {
        const domNode = super.create(value);
        return domNode;
    }

    html(index, length) {
        let content = this.domNode.innerHTML;
        let container = this.domNode.children[0];
        if (container) {
            for (let i = 0; i < container.children.length; i++) {
                if (
                    container.children[i].classList.contains(
                        'zk-embeded-code-content'
                    )
                ) {
                    content = container.children[i].innerHTML;
                }
            }
        }
        return `<div class="zk-embeded-code">${content}</div>`;
    }
}

export class EmbedCode extends BlockEmbed {
    static blotName = 'embed-code';
    static tagName = 'div';
    static className = 'zk-embeded-code';

    static register() {
        Quill.register(EmbedCodeContainer);
    }

    static create(htmlCode) {
        let node = super.create();
        node.setAttribute('contenteditable', false);
        let controls = document.createElement('div');
        let editBtn = document.createElement('button');
        let nodeContent = document.createElement('div');
        controls.setAttribute('class', 'zk-embeded-code-controls-container');
        editBtn.setAttribute('class', 'zk-embeded-code-edit-btn');
        editBtn.innerHTML = editIcon;
        editBtn.onclick = (evnt) => {
            showUpdatePopup(nodeContent);
        };
        controls.appendChild(editBtn);
        nodeContent.innerHTML = htmlCode || '';
        nodeContent.setAttribute('class', 'zk-embeded-code-content');
        node.innerHTML = '';
        node.appendChild(controls);
        node.appendChild(nodeContent);
        return node;
    }

    static sanitize(htmlCode) {
        return htmlCode;
    }

    static value(domNode) {
        for (let i = 0; i < domNode.children.length; i++) {
            if (
                domNode.children[i].classList.contains(
                    'zk-embeded-code-content'
                )
            ) {
                return domNode.children[i].innerHTML;
            }
        }
        return domNode.innerHTML;
    }
}

EmbedCodeContainer.allowedChildren = [EmbedCode];
EmbedCode.requiredContainer = EmbedCodeContainer;

function showEmbedCodePopup({
    quillEditor = undefined,
    nodeContent = undefined,
}) {
    let htmlCode = nodeContent ? nodeContent.innerHTML : '';
    let popupContainer = document.createElement('div');
    popupContainer.setAttribute('class', 'popups-container');

    let popupOverlay = document.createElement('div');
    popupOverlay.setAttribute('class', 'quill-editor-popup-overlay');
    popupOverlay.onclick = (evnt) => {
        document.body.removeChild(popupContainer);
    };
    popupContainer.appendChild(popupOverlay);

    let popup = document.createElement('div');
    popup.setAttribute('class', 'quill-editor-popup larg');
    popupContainer.appendChild(popup);

    let form = document.createElement('form');
    form.setAttribute('class', 'add-embeded-code-form');
    form.onsubmit = (evnt) => {
        evnt.preventDefault();
        let newHtmlCode = mirrorEditor.state.doc.text.join('\n');
        if (nodeContent) {
            updateCode(nodeContent, newHtmlCode);
        } else {
            insertCode(newHtmlCode);
        }
    };

    function updateCode(nodeContent, newHtmlCode) {
        nodeContent.innerHTML = newHtmlCode;
        document.body.removeChild(popupContainer);
    }

    function insertCode(newHtmlCode) {
        let index = 0;
        try {
            index = quillEditor.selection.savedRange.index ?? 0;
        } catch (err) {}
        quillEditor.insertEmbed(index, 'embed-code', newHtmlCode);
        document.body.removeChild(popupContainer);
    }

    let codeParent = document.createElement('div');
    codeParent.setAttribute('class', 'mirror-code-edito-container');
    form.appendChild(codeParent);
    let mirrorEditor = initMirrorCodeEditor({
        code: htmlCode,
        editorParent: codeParent,
    });

    let submitButton = document.createElement('button');
    submitButton.setAttribute('class', 'add-embeded-code-btn');
    submitButton.textContent = nodeContent ? 'Update' : 'Add';
    form.appendChild(submitButton);

    popup.append(form);

    document.body.appendChild(popupContainer);
}

function showUpdatePopup(nodeContent) {
    showEmbedCodePopup({ nodeContent: nodeContent });
}

function initMirrorCodeEditor({
    code = '',
    editorParent,
    onCodeChange = undefined,
}) {
    const language = new Compartment();
    const customKeymap = [
        {
            key: 'Tab',
            run: (cm) => {
                if (cm.state.selection.main.empty) {
                    insertTab(cm);
                }
                return true; /*prevent default*/
            },
        },
    ];
    let state = EditorState.create({
        doc: code,
        extensions: [basicSetup, language.of(html()), keymap.of(customKeymap)],
        compartments: [],
    });
    const editor = new EditorView({
        state,
        parent: editorParent,
        dispatchTransactions: (trs, view) => {
            if (!onCodeChange) {
                view.update(trs);
                return;
            }
            for (let i = 0; i < trs.length; i++) {
                if (!trs[i].changes.empty) {
                    onCodeChange(trs[trs.length - 1].state.doc.text.join('\n'));
                    break;
                }
            }
            view.update(trs);
        },
    });
    return editor;
}

const editIcon = `<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.56055 22.7617H9.96055L18.5855 14.1367L17.1855 12.7367L8.56055 21.3617V22.7617ZM22.8605 12.6867L18.6105 8.48672L20.0105 7.08672C20.3939 6.70339 20.8649 6.51172 21.4235 6.51172C21.9815 6.51172 22.4522 6.70339 22.8355 7.08672L24.2355 8.48672C24.6189 8.87005 24.8189 9.33272 24.8355 9.87472C24.8522 10.4161 24.6689 10.8784 24.2855 11.2617L22.8605 12.6867ZM7.56055 24.7617C7.27721 24.7617 7.03988 24.6657 6.84855 24.4737C6.65655 24.2824 6.56055 24.0451 6.56055 23.7617V20.9367C6.56055 20.8034 6.58555 20.6744 6.63555 20.5497C6.68555 20.4244 6.76055 20.3117 6.86055 20.2117L17.1605 9.91172L21.4105 14.1617L11.1105 24.4617C11.0105 24.5617 10.8982 24.6367 10.7735 24.6867C10.6482 24.7367 10.5189 24.7617 10.3855 24.7617H7.56055ZM17.8855 13.4367L17.1855 12.7367L18.5855 14.1367L17.8855 13.4367Z" fill="#3C3C41"/></svg>`;

export const quillEditorEmbedCode = {
    showEmbedCodePopup,
};
