import { Link } from 'react-router-dom';
import '../assets/scss/pages/paymentResult.scss';

export const PaySuccess = () => {
    return (
        <div className="payment-result-page-container">
            <h2 className="title">Payment Successful!</h2>
            <span className="icon">
                <svg
                    width="97"
                    viewBox="0 0 97 97"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <circle
                        cx="48.7193"
                        cy="48.4578"
                        r="48"
                        fill="#9EFF00"
                        fill-opacity="0.29"
                    />
                    <circle
                        cx="48.7193"
                        cy="48.4578"
                        r="33.4456"
                        fill="white"
                    />
                    <path
                        d="M48.7193 10.4578C27.7433 10.4578 10.7193 27.4818 10.7193 48.4578C10.7193 69.4338 27.7433 86.4578 48.7193 86.4578C69.6953 86.4578 86.7193 69.4338 86.7193 48.4578C86.7193 27.4818 69.6953 10.4578 48.7193 10.4578ZM38.4213 64.7598L24.7793 51.1178C23.2973 49.6358 23.2973 47.2418 24.7793 45.7598C26.2613 44.2778 28.6553 44.2778 30.1373 45.7598L41.1193 56.7038L67.2633 30.5598C68.7453 29.0778 71.1393 29.0778 72.6213 30.5598C74.1033 32.0418 74.1033 34.4358 72.6213 35.9178L43.7793 64.7598C42.3353 66.2418 39.9033 66.2418 38.4213 64.7598Z"
                        fill="#67D723"
                    />
                </svg>
            </span>
            <Link
                className="back-btn --success"
                to="/">
                Continue
            </Link>
        </div>
    );
};
export const PayFaild = () => {
    return (
        <div className="payment-result-page-container">
            <h2 className="title">Payment Failed!</h2>
            <span className="icon">
                <svg
                    width="97"
                    viewBox="0 0 97 97"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <circle
                        cx="48.274"
                        cy="48.8732"
                        r="48"
                        fill="#FFD600"
                        fill-opacity="0.11"
                    />
                    <circle
                        cx="48.274"
                        cy="48.8732"
                        r="38.2735"
                        fill="#FFEA7E"
                    />
                    <rect
                        x="41.6095"
                        y="40.0801"
                        width="13.329"
                        height="21.2788"
                        fill="white"
                    />
                    <path
                        d="M31.546 66.349H65.002C68.4231 66.349 70.5558 62.6391 68.8452 59.6844L52.1172 30.7826C50.4067 27.828 46.1414 27.828 44.4308 30.7826L27.7028 59.6844C25.9923 62.6391 28.1249 66.349 31.546 66.349ZM48.274 50.7984C47.0522 50.7984 46.0525 49.7987 46.0525 48.5769V44.1339C46.0525 42.912 47.0522 41.9123 48.274 41.9123C49.4959 41.9123 50.4955 42.912 50.4955 44.1339V48.5769C50.4955 49.7987 49.4959 50.7984 48.274 50.7984ZM50.4955 59.6844H46.0525V55.2414H50.4955V59.6844Z"
                        fill="#FFA621"
                    />
                </svg>
            </span>
            <p className="msg">
                Something goes wrong, <br />
                please go back and try again.
            </p>
            <Link
                className="back-btn --fail"
                to="/">
                Go Back
            </Link>
        </div>
    );
};
