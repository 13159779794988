import { useEffect, useState } from 'react';
import useSetting from '../hooks/useSetting';
import AliasviewBodyPanel, { AliaspinBodyPanel } from './body/alias';
import AliasviewFullBodyPanel from './body/alias_full';
import DatabaseviewBodyPanel from './body/database';
import DatabaseviewFullBodyPanel from './body/database_full';
import { ErrorPagePanel } from './body/error';

export const BodyPanelTabs = {
    AliasDashboard: 'DDEe3e',

    AliasDashboardFull: 'iId33',

    AliasUnlock: 'DUi3d',

    ErrorPage: 'eer4',

    Databaseview: 'uiiwdhwd',
    DatabaseviewFull: 'iedo3wd3',
};

export default function BodyPanel() {
    const settingCtx = useSetting();

    const [tab, setTab] = useState(null);

    useEffect(() => {
        setTab(settingCtx.body_panel.active);
    }, [settingCtx.body_panel.active]);

    return (
        <div
            className={
                'panel --panel-body' +
                (tab === BodyPanelTabs.AliasUnlock ||
                tab === BodyPanelTabs.ErrorPage
                    ? ' --no-panel-top'
                    : '') +
                (tab === BodyPanelTabs.Databaseview ||
                tab === BodyPanelTabs.AliasDashboard ||
                tab === BodyPanelTabs.AliasDashboardFull
                    ? ' scroll-container'
                    : '') +
                (tab === BodyPanelTabs.DatabaseviewFull
                    ? ' --dbfull-view  --no-panel-top'
                    : '') +
                (tab === BodyPanelTabs.AliasDashboard ? ' --overview' : '') +
                (tab === BodyPanelTabs.AliasDashboardFull ? ' --gray' : '')
            }>
            <div className="--inner">
                {tab === BodyPanelTabs.AliasDashboard ? (
                    <AliasviewBodyPanel />
                ) : tab === BodyPanelTabs.AliasDashboardFull ? (
                    <AliasviewFullBodyPanel />
                ) : tab === BodyPanelTabs.AliasUnlock ? (
                    <AliaspinBodyPanel />
                ) : tab === BodyPanelTabs.Databaseview ? (
                    <DatabaseviewBodyPanel />
                ) : tab === BodyPanelTabs.DatabaseviewFull ? (
                    <DatabaseviewFullBodyPanel />
                ) : tab === BodyPanelTabs.ErrorPage ? (
                    <ErrorPagePanel />
                ) : tab === null ? (
                    <></>
                ) : (
                    <></>
                )}
            </div>
        </div>
    );
}
