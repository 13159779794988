import { apiRoutes } from '../helpers/api';
import { callApi } from './api';

/**
 * alias fetch
 */
const fetchAlias = async ({
    aliasuid = null,
    signal = undefined,
    cancelToken = undefined,
}) => {
    return await callApi({
        headers: {
            'alias-authorization':
                'Bearer ' + localStorage.getItem(aliasuid) || undefined,
        },
        method: 'get',
        cancelToken: cancelToken,
        signal: signal,
        path: apiRoutes.alias.getAlias.replace('{aliasuid}', `${aliasuid}`),
    });
};

/**
 *
 * logo
 */
const fetchAliasBranding = async ({ aliasuid = null }) => {
    return await callApi({
        method: 'get',
        path: 'alias/branding/{aliasuid}'.replace('{aliasuid}', `${aliasuid}`),
    });
};

/**
 * alias login
 */
const loginAlias = async ({ aliasuid = null, pin = null }) => {
    return await callApi({
        method: 'post',
        path: apiRoutes.alias.loginAlias.replace('{aliasuid}', `${aliasuid}`),
        payload: {
            pin: pin,
        },
    });
};

export default {
    fetchAlias,
    fetchAliasBranding,
    loginAlias,
};
